import { useSelector } from "react-redux";
import { loginDataType } from "./UtilTypes";

/**
 * Custom React hook that retrieves the login token from the Redux store.
 * @returns {string} The login token.
 */
export const useToken = () => {
  return useSelector((state: loginDataType) => state.login.token);
};

export const AdminData = () => {
  return useSelector((state: loginDataType) => state.login.adminData);
};

export const GetLoaderStatus = () => {
  return useSelector((state: loginDataType) => state.loader.loading);
};
