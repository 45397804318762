import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import * as Yup from "yup";
import { useFormik } from "formik";

import {
  createOrganization,
  getServerDropdown,
  viewOrganization,
  updateOrganization,
  userSubscriptionDropdownSevice,
  userSubscriptionDropdown,
} from "../Services/Services";
import { getCatchMsg } from "../Utility/GeneralUtils";
import {
  EMAIL_REGEX,
  INDIAN_MOBILE_REGEX,
  muiStyles,
} from "../Utility/Constants";
import {
  Loader,
  TextInputBox,
  CustomButton,
  CustomDropDown,
} from "../Components/index";
import {
  CreateOrganizationModalProps,
  ListServerDataProps,
  OrganizationDetailsType,
  UserSubscriptionDropdownProps,
} from "./Types";
import ModalHeader from "./ModalHeader";
import classes from "./Modal.module.css";
import UpdateDeleteActions from "../Components/UpdateDeleteActions";
import { openLoader } from "../Store/Reducers/LoaderSlice";
import { useDispatch } from "react-redux";
import { Autocomplete, Paper, TextField } from "@mui/material";

function CreateorganizationModal({
  onClose,
  listOrganizationApicall,
  modalClose,
  getview,
  getviewid,
  editData,
  projectList,
}: CreateOrganizationModalProps) {
  const dispatch = useDispatch();
  const muistyle = muiStyles();

  const [listServer, setListServer] = useState<ListServerDataProps | null>(
    null
  );
  const [userSubscriptionList, setUserSubscriptionList] = useState<
    UserSubscriptionDropdownProps[]
  >([]);
  const [userSubscriptionAlert, setuserSubscriptionAlert] = useState(false);
  const validationSchema = Yup.object({
    Company_Name: Yup.string()
      .required("* Company Name is required")
      .trim("Remove leading and trailing spaces")
      .strict(true),
    Email: Yup.string()
      .required("* Email is required")
      .matches(EMAIL_REGEX, "Enter valid email address")
      .trim("Remove leading and trailing spaces")
      .strict(true),
    server_name: Yup.string()
      .required("* Server Name is required")
      .trim("Remove leading and trailing spaces")
      .strict(true),
    Mobile_No: Yup.string()
      .required("* Mobile Number is required")
      .matches(INDIAN_MOBILE_REGEX, "Enter a valid mobile number")
      .trim("Remove leading and trailing spaces")
      .strict(true),
    Alternative_Mobile_No: Yup.string()
      .matches(INDIAN_MOBILE_REGEX, "Enter a valid mobile number")
      .trim("Remove leading and trailing spaces")
      .strict(true),
    Password: Yup.string()
      .trim("Remove leading and trailing spaces")
      .strict(true)
      .test("password valid", "* Password is required", function (value) {
        if (!editData) {
          if (!value) {
            return false;
          }

          return true;
        }
        return true;
      }),
    Emp_Code: Yup.string()
      .required("* Emp Code is required")
      .trim("Remove leading and trailing spaces")
      .strict(true),
    Contact_Person: Yup.string()
      .trim("Remove leading and trailing spaces")
      .strict(true),
    Organization_Code: Yup.string()
      .trim("Remove leading and trailing spaces")
      .strict(true),
    project: Yup.mixed()
      .transform((val) => (val ? val : undefined))
      .required("* Project is required"),
    selected_subscription: Yup.mixed().required("* Subscription is required"),
  });
  const {
    handleChange,
    values,
    setFieldValue,
    handleSubmit,
    setFieldTouched,
    setFieldError,
    errors,
    touched,
    handleBlur,
  } = useFormik({
    initialValues: {
      Company_Name: editData?.company_name || "",
      Alternative_Mobile_No: editData?.alternative_mobile || "",
      Email: editData?.email || "",
      Contact_Person: editData?.contact_person || "",
      server_name: editData?.server_name || "",
      server_id: "",
      Mobile_No: editData?.contact_no || "",
      Password: "",
      Emp_Code: editData?.emp_code || "",
      Organization_Code: editData?.organization_code || "",
      Organization_id: "",
      selected_subscription: editData?.userSubscriptionName || "",
      selected_subScriptionId: editData?.userSubscription_id || null,
      project: editData?.projectId
        ? projectList?.find((ele) => ele?.id === editData?.projectId)
        : null,
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      if (editData) {
        handleUpdateOrganization();
      } else {
        handleAddOrganization();
      }
    },
  });

  /**
   * Handles the process of adding a new organization.
   * Sets the loader to true to indicate that the process is ongoing.
   * Creates a new FormData object and appends the necessary data for the organization.
   * Calls the createOrganization function with the FormData object.
   * If the response status is 1, calls the listOrganizationApicall function to update the organization list,
   * closes the modal, and displays a success toast message.
   * If the response status is not 1, displays an error toast message.
   * If there is an error during the process, displays an error toast message with the error message.
   * Finally, sets the loader to false to indicate that the process is complete.
   * @returns None
   */
  const handleAddOrganization = () => {
    dispatch(openLoader(true));
    let formData = new FormData();

    formData.append("company_name", values.Company_Name);
    formData.append("alternative_mobile_no", values.Alternative_Mobile_No);
    formData.append("email", values.Email);
    formData.append("contact_person", values.Contact_Person);
    formData.append("assigned_server", values.server_id);
    formData.append("mobile_no", values.Mobile_No);
    formData.append("password", values.Password);
    formData.append("emp_code", values.Emp_Code);
    formData.append("organization_code", values.Organization_Code);
    // @ts-ignore

    formData.append("projectId", values.project?.id?.toString());
    if (values.selected_subScriptionId) {
      formData.append(
        "userSubscription",
        // @ts-ignore
        values.selected_subScriptionId
      );
    }

    createOrganization(formData)
      .then((response) => {
        if (response?.data?.status === 1) {
          listOrganizationApicall("add");
          modalClose();
          toast.success(response?.data?.msg);
        } else {
          toast.error(response?.data?.msg);
        }
      })
      .catch((err) => getCatchMsg(err))
      .finally(() => {
        dispatch(openLoader(false));
      });
  };

  /**
   * Retrieves the server list from the server dropdown API endpoint and updates the state
   * with the received data.
   * @returns None
   */
  const handlegetserverlist = () => {
    const formdata = new FormData();

    // @ts-ignore
    formdata.append("project_id", values.project ? values.project?.id : "");

    getServerDropdown(formdata)
      .then((response) => {
        if (response?.data?.status === 1) {
          setListServer(response?.data?.data);
        }
      })
      .catch((err) => getCatchMsg(err));
  };

  /**
   * useEffect hook that runs once when the component mounts. It checks if the 'getview' variable is false,
   * and if so, it calls the 'handlegetserverlist' function.
   * @returns None
   */
  useEffect(() => {
    if (!getview) {
      handlegetserverlist();
    }
    if (values.project && !editData) {
      setFieldValue("server_id", "");
      setFieldValue("server_name", "");
    }
  }, [values.project]);

  /**
   * Handles the view organization functionality by making an API call to retrieve
   * organization details and updating the form fields with the retrieved data.
   * @returns None
   */
  const handleViewOrganization = () => {
    dispatch(openLoader(true));
    let formData = new FormData();
    //@ts-ignore
    formData.append("org_id", getviewid);
    viewOrganization(formData)
      .then((response) => {
        if (response?.data?.status === 1) {
          response?.data?.data?.map((details: OrganizationDetailsType) => {
            return (
              setFieldValue("Company_Name", details?.company_name),
              setFieldValue(
                "Alternative_Mobile_No",
                details?.alternative_mobile
              ),
              setFieldValue("Email", details?.email),
              setFieldValue("Contact_Person", details?.contact_person),
              setFieldValue("server_name", details?.server_name),
              setFieldValue("Mobile_No", details?.contact_no),
              setFieldValue("Emp_Code", details?.emp_code),
              setFieldValue("Organization_Code", details?.organization_code)
            );
          });
        }
      })
      .catch((err) => getCatchMsg(err))
      .finally(() => {
        dispatch(openLoader(false));
      });
  };

  /**
   * useEffect hook that triggers the handleViewOrganization function when the value of getview is true.
   * @param {function} handleViewOrganization - The function to be executed when getview is true.
   * @param {boolean} getview - The value that triggers the execution of handleViewOrganization.
   * @returns None
   */
  useEffect(() => {
    if (getview === true) {
      handleViewOrganization();
    }
  }, [getview]);
  const getUserSubscriptionList = (projectId: any) => {
    let formData = new FormData();
    formData.append("project_id", projectId);
    userSubscriptionDropdown(formData)
      .then((res) => {
        if (res?.data?.status) {
          setUserSubscriptionList(res?.data?.data || []);
        }
      })
      .catch((err) => getCatchMsg(err));
  };
  const handleUpdateOrganization = () => {
    const formData = new FormData();
    formData.append("org_id", editData?.org_id);
    formData.append("company_name", values.Company_Name);
    formData.append("alternative_mobile_no", values.Alternative_Mobile_No);
    formData.append("emailId", values.Email);
    formData.append("contact_person", values.Contact_Person);
    formData.append("mobile_no", values.Mobile_No);
    formData.append("emp_code", values.Emp_Code);
    formData.append("assigned_server", editData?.server_id);
    formData.append("organization_code", values.Organization_Code);
    formData.append(
      "projectId",
      // @ts-ignore
      values.project ? values.project?.id?.toString() : ""
    );
    if (values.selected_subScriptionId) {
      formData.append(
        "userSubscription",
        // @ts-ignore
        values.selected_subScriptionId
      );
    }

    updateOrganization(formData)
      .then((response) => {
        if (response?.data?.status === 1) {
          toast.success(response?.data?.msg);
          listOrganizationApicall("update");
          modalClose();
        } else {
          toast.error(response?.data?.msg);
        }
      })
      .catch((err) => getCatchMsg(err));
  };
  useEffect(() => {
    if (editData?.projectId) {
      getUserSubscriptionList(editData?.projectId);
    }
  }, []);
  return (
    <>
      <ModalHeader
        onClose={onClose}
        heading={
          editData
            ? "Edit Organization"
            : getview
            ? "Organization"
            : "Add Organization"
        }
      />
      <div className={classes.add_device}>
        {!getview ? (
          <div className="row">
            <div className="col-md-12 col-xl-4 form-group mb-3">
              <TextInputBox
                requiredText={getview ? null : "*"}
                textLength={50}
                readOnly={getview}
                name="Company_Name"
                title="Company Name"
                value={values.Company_Name}
                placeHolder="Enter Company Name"
                onChangeText={handleChange("Company_Name")}
                onBlur={() => {
                  try {
                    validationSchema.validateSyncAt(
                      "Company_Name",
                      values.Company_Name
                    );
                  } catch (error) {
                    if (error instanceof Error) {
                      setFieldTouched("Company_Name", true);
                      setFieldError("Company_Name", error.message);
                    }
                  }
                }}
                errorText={
                  !getview && touched.Company_Name && errors.Company_Name
                    ? errors.Company_Name
                    : ""
                }
              />
            </div>

            <div className="col-md-12 col-xl-4 form-group mb-3">
              <TextInputBox
                name="Organization_Code"
                textLength={50}
                readOnly={getview}
                title="Organization Code"
                value={values.Organization_Code}
                placeHolder="Enter Organization Code"
                onChangeText={handleChange("Organization_Code")}
                onBlur={handleBlur("Organization_Code")}
                errorText={
                  touched.Organization_Code && errors.Organization_Code
                    ? errors.Organization_Code
                    : ""
                }
              />
            </div>
            <div className="col-md-12 col-xl-4 form-group mb-3">
              <TextInputBox
                requiredText={getview ? null : "*"}
                textLength={50}
                name="Emp_Code"
                title="Emp Code"
                readOnly={getview}
                value={values.Emp_Code}
                placeHolder="Enter Emp Code"
                onChangeText={(text) => {
                  const numericText = text.replace(/[^0-9]/g, "");
                  handleChange("Emp_Code")(numericText);
                }}
                onBlur={() => {
                  try {
                    validationSchema.validateSyncAt(
                      "Emp_Code",
                      values.Emp_Code
                    );
                  } catch (error) {
                    if (error instanceof Error) {
                      setFieldTouched("Emp_Code", true);
                      setFieldError("Emp_Code", error.message);
                    }
                  }
                }}
                errorText={
                  touched.Emp_Code && errors.Emp_Code ? errors.Emp_Code : ""
                }
              />
            </div>

            <div className="fieldBr8 placeHolder col-md-12 col-xl-4 form-group mb-3">
              <TextInputBox
                title="Project"
                requiredText={"*"}
                onChangeText={() => {}}
                textOnly
              />
              <Autocomplete
                // className={styles.placeHolder}
                options={projectList}
                value={values.project}
                onChange={(e, val) => {
                  getUserSubscriptionList(val?.id);
                  // setuserSubscriptionAlert(false);
                  setFieldValue("project", val);
                }}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => {
                  return (
                    <TextField
                      placeholder={values?.project ? "" : "none"}
                      {...params}
                    />
                  );
                }}
                readOnly={editData}
                PaperComponent={(props) => {
                  return <Paper {...props} className={muistyle.customSelect} />;
                }}
              />
              <TextInputBox
                title=""
                onChangeText={() => {}}
                textOnly
                errorText={
                  errors?.project && touched.project ? errors?.project : ""
                }
              />
            </div>
            <div className="col-md-12 col-xl-4 form-group mb-3">
              <CustomDropDown
                required={getview ? null : "*"}
                editName={
                  values.selected_subscription
                    ? values.selected_subscription
                    : "none"
                }
                //@ts-ignore
                items={userSubscriptionList}
                // disabled={editData ? true : false}
                title="User Subscription"
                onSelectedItem={(val, value) => {
                  setFieldValue("selected_subscription", value.label);
                  setFieldValue("selected_subScriptionId", value.id);
                }}
                fieldName="subscriptionName"
                anotherFieldName="subscriptionId"
                value={values.selected_subscription}
                onBlur={() => {
                  try {
                    validationSchema.validateSyncAt(
                      "selected_subscription",
                      values.selected_subscription
                    );
                  } catch (error) {
                    if (error instanceof Error) {
                      setFieldTouched("selected_subscription", true);
                      setFieldError("selected_subScriptionId", error.message);
                    }
                  }
                }}
                errorText={
                  userSubscriptionAlert
                    ? "Please Select Project"
                    : errors.selected_subscription &&
                      touched.selected_subscription
                    ? errors?.selected_subscription
                    : ""
                }
                // errorText={
                //   userSubscriptionAlert
                //     ? "select Project First"
                //     : errors.selected_subscription
                //     ? errors.selected_subscription
                //     : ""
                // }
              />
              {/* <TextInputBox
                textOnly
                title="User Subscription"
                onChangeText={() => {}}
              />
              <Autocomplete
                options={userSubscriptionList}
                value={values.selected_subscription}
                getOptionLabel={(option) => option.subscriptionName}
                isOptionEqualToValue={(option, value) =>
                  option.subscriptionId === value.subscriptionId
                }
                renderInput={(params) => {
                  return (
                    <TextField
                      placeholder="Select user subscription"
                      {...params}
                    />
                  );
                }}
                // onKeyDown={() => {

                //   if (!values.project) {
                //     setuserSubscriptionAlert(true);
                //     setUserSubscriptionList([]);
                //   } else {
                //     setuserSubscriptionAlert(false);
                //   }
                // }}
                onClick={() => {

                  if (!values.project) {
                    setuserSubscriptionAlert(true);
                    setUserSubscriptionList([]);
                  } else {
                    setuserSubscriptionAlert(false);
                  }
                }}
                onChange={(e, val) => {
                  setFieldValue("selected_subScriptionId", val.subscriptionId);
                  setFieldValue("selected_subscription", val.subscriptionName);
                }}
              />
              <TextInputBox
                title=""
                onChangeText={() => {}}
                textOnly
                errorText={
                  userSubscriptionAlert
                    ? "Please Select Project "
                    : errors.selected_subscription &&
                      touched.selected_subscription
                    ? errors.selected_subscription
                    : ""
                }
              /> */}
            </div>
            <div className="col-md-12 col-xl-4 form-group mb-3">
              <CustomDropDown
                required={getview ? null : "*"}
                editName={values.server_name ? values.server_name : "none"}
                //@ts-ignore
                items={listServer}
                disabled={editData ? true : false}
                title="Server"
                onSelectedItem={(val, value) => {
                  setFieldValue("server_name", value.label);
                  setFieldValue("server_id", value.id);
                }}
                fieldName="server_name"
                anotherFieldName="server_id"
                value={values.server_id}
                onBlur={() => {
                  try {
                    validationSchema.validateSyncAt(
                      "server_name",
                      values.server_name
                    );
                  } catch (error) {
                    if (error instanceof Error) {
                      setFieldTouched("server_name", true);
                      setFieldError("server_name", error.message);
                    }
                  }
                }}
                errorText={
                  touched.server_name && errors.server_name
                    ? errors.server_name
                    : ""
                }
              />
            </div>
            <div className="col-md-12 col-xl-4 form-group mb-3">
              <TextInputBox
                name="Contact_Person"
                textLength={50}
                readOnly={getview}
                title="Contact Person"
                value={values.Contact_Person}
                placeHolder="Enter Contact Person"
                onChangeText={handleChange("Contact_Person")}
                onBlur={handleBlur("Contact_Person")}
                errorText={
                  !getview && touched.Contact_Person && errors.Contact_Person
                    ? errors.Contact_Person
                    : ""
                }
              />
            </div>

            <div className="col-md-12 col-xl-4 form-group mb-3">
              <TextInputBox
                textLength={10}
                requiredText={getview ? null : "*"}
                infotxt={
                  getview
                    ? null
                    : errors.Mobile_No && touched.Mobile_No
                    ? ""
                    : "number only"
                }
                name="Mobile_No"
                title="Mobile No"
                readOnly={getview}
                value={values.Mobile_No}
                placeHolder="Enter Mobile No"
                onChangeText={(text) => {
                  const numericText = text.replace(/[^0-9]/g, "");
                  handleChange("Mobile_No")(numericText);
                }}
                onBlur={() => {
                  try {
                    validationSchema.validateSyncAt(
                      "Mobile_No",
                      values.Mobile_No
                    );
                  } catch (error) {
                    if (error instanceof Error) {
                      setFieldTouched("Mobile_No", true);
                      setFieldError("Mobile_No", error.message);
                    }
                  }
                }}
                errorText={
                  touched.Mobile_No && errors.Mobile_No ? errors.Mobile_No : ""
                }
              />
            </div>
            <div className="col-md-12 col-xl-4 form-group mb-3">
              <TextInputBox
                name="Alternative_Mobile_No"
                textLength={10}
                readOnly={getview}
                infotxt={
                  getview
                    ? null
                    : errors.Alternative_Mobile_No &&
                      touched.Alternative_Mobile_No
                    ? ""
                    : "number only"
                }
                title="Alternative Mobile No"
                value={values.Alternative_Mobile_No}
                placeHolder="Enter Alternative Mobile No"
                onChangeText={(text) => {
                  const numericText = text.replace(/[^0-9]/g, "");
                  handleChange("Alternative_Mobile_No")(numericText);
                }}
                onBlur={handleBlur("Alternative_Mobile_No")}
                errorText={
                  !getview &&
                  errors.Alternative_Mobile_No &&
                  touched.Alternative_Mobile_No
                    ? errors.Alternative_Mobile_No
                    : ""
                }
              />
            </div>
            <div className="col-md-12 col-xl-4 form-group mb-3">
              <TextInputBox
                requiredText={getview ? null : "*"}
                textLength={50}
                name="Email"
                readOnly={getview}
                title="Email"
                value={values.Email}
                placeHolder="Enter Email"
                onChangeText={handleChange("Email")}
                onBlur={() => {
                  try {
                    validationSchema.validateSyncAt("Email", values.Email);
                  } catch (error) {
                    if (error instanceof Error) {
                      setFieldTouched("Email", true);
                      setFieldError("Email", error.message);
                    }
                  }
                }}
                errorText={
                  !getview && touched.Email && errors.Email ? errors.Email : ""
                }
              />
            </div>

            {!getview && !editData ? (
              <div className="col-md-12 col-xl-4 form-group mb-3">
                <TextInputBox
                  textLength={50}
                  requiredText={getview ? null : "*"}
                  readOnly={getview}
                  isSecure={true}
                  name="Password"
                  title="Password"
                  value={values.Password}
                  placeHolder="Enter Password"
                  onChangeText={handleChange("Password")}
                  onBlur={() => {
                    try {
                      validationSchema.validateSyncAt(
                        "Password",
                        values.Password
                      );
                    } catch (error) {
                      if (error instanceof Error) {
                        setFieldTouched("Password", true);
                        setFieldError("Password", error.message);
                      }
                    }
                  }}
                  errorText={
                    touched.Password && errors.Password ? errors.Password : ""
                  }
                />
              </div>
            ) : null}
          </div>
        ) : (
          <div className={classes.vieworg}>
            <ul>
              <div className={classes.heading}>
                <p>Company name</p>:
                <span>{values.Company_Name ? values.Company_Name : "-"}</span>
              </div>
              <div className={classes.heading}>
                <p>Alternative mobile no</p>:
                <span>
                  {values.Alternative_Mobile_No
                    ? values.Alternative_Mobile_No
                    : "-"}
                </span>
              </div>
              <div className={classes.heading}>
                <p>Email</p>:<span>{values.Email ? values.Email : "-"}</span>
              </div>
              <div className={classes.heading}>
                <p>Contact person</p>:
                <span>
                  {values.Contact_Person ? values.Contact_Person : "-"}
                </span>
              </div>
              <div className={classes.heading}>
                <p>Server name</p>:
                <span>{values.server_name ? values.server_name : "-"}</span>
              </div>
              <div className={classes.heading}>
                <p>Mobile no</p>:
                <span>{values.Mobile_No ? values.Mobile_No : "-"}</span>
              </div>
              <div className={classes.heading}>
                <p>Emp code</p>:
                <span>{values.Emp_Code ? values.Emp_Code : "-"}</span>
              </div>
              <div className={classes.heading}>
                <p>Organization code</p>:
                <span>
                  {values.Organization_Code ? values.Organization_Code : "-"}
                </span>
              </div>
            </ul>
          </div>
        )}

        <div className={classes.modalbtn}>
          {getview ? null : (
            <>
              <div className={classes.modalbtn}>
                {editData ? (
                  <UpdateDeleteActions
                    onCancelPress={() => modalClose()}
                    onUpdatePress={handleSubmit}
                  />
                ) : (
                  <div className="col-lg-3">
                    <CustomButton title="Submit" onButtonPress={handleSubmit} />
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default CreateorganizationModal;
