import React, { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import { useFormik } from "formik";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import toast from "react-hot-toast";
import TableRow from "@mui/material/TableRow";
import { Box } from "@mui/material";

import delete_icon from "../../Assets/Icons/Svg/delete.svg";
import view from "../../Assets/Icons/Png/view.png";
import edit from "../../Assets/Icons/Svg/edit.svg";
import Inbox from "../../Assets/Images/Png/noData.png";
import PageHeader from "../MangementLayoutHeader/PageHeader";
import classes from "./Management/Management.module.css";
import {
  CustomPaginationwithdropdownpagination,
  GlobalModal,
  Loader,
  NodataLoader,
  TableRowsLoader,
  TextInputBox,
} from "../../Components";
import {
  SupplierShowProps,
  SuppliersListProps,
} from "./Management/managementTypes";
import AddSuppliers from "../../Modals/AddSuppliers";
import { getCatchMsg, isFormDirty } from "../../Utility/GeneralUtils";
import { deleteSupplier, listSuppliers } from "../../Services/Services";
import { useToken } from "../../Utility/StoreData";
import { ConfirmationModal } from "../../Modals";
import SearchAndClearbutton from "../../Components/SearchAndClearbutton";
import { Grid } from "@material-ui/core";
import { openLoader } from "../../Store/Reducers/LoaderSlice";
import { useDispatch } from "react-redux";
import AccordionContent from "../../Components/AccordionContent";

function Suppliers() {
  const token = useToken();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [noDataLoader, setnoDataLoader] = useState(false);
  const [showFilterOption, setShowFilterOption] = useState(false);
  const [suppliersList, setsuppliersList] = useState<SuppliersListProps>();
  const [isShowModal, setIsShowModal] = useState<SupplierShowProps>({
    status: false,
    data: null,
    viewstatus: false,
  });
  const [deleteModal, setdeleteModal] = useState({
    modal: false,
    id: "",
  });
  const { values, handleChange, resetForm, initialValues } = useFormik({
    initialValues: {
      name: "",
      organizationName: "",
      mobile_no: "",
      gstNo: "",
    },
    onSubmit: () => {},
  });

  const handleTableSearch = (data: string) => {
    if (data === "Enter") {
      setPage(1);
      handleGetsuppliersList();
    }
  };

  const handleTableSearchClear = () => {
    setPage(1);
    resetForm();
    handleGetsuppliersList(undefined, undefined, true);
  };

  const handleGetsuppliersList = (
    page = suppliersList?.data?.page === undefined
      ? 1
      : suppliersList?.data?.page,
    size = 10,
    resetValue?: boolean
  ) => {
    let formData = new FormData();
    setnoDataLoader(true);
    formData.append("name", resetValue ? "" : values.name);
    formData.append(
      "organizationName",
      resetValue ? "" : values.organizationName
    );
    formData.append("mobile_no", resetValue ? "" : values.mobile_no);
    formData.append("gstNo", resetValue ? "" : values.gstNo);
    listSuppliers(page, size, formData)
      .then((response) => {
        setPage(response?.data?.data?.page);
        if (response?.data?.status === 1) {
          setTotalCount(response?.data?.data?.totalCount);

          setsuppliersList(response?.data);
          //   handleActiveStatus(response?.data?.data?.items);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        setnoDataLoader(false);
      });
  };

  const handleDeleteSupplier = () => {
    let formData = new FormData();
    dispatch(openLoader(true));
    formData.append("supplierId", deleteModal.id);
    deleteSupplier(formData)
      .then((response) => {
        if (response?.data?.status === 1) {
          if (totalCount % 10 === 1) {
            handleGetsuppliersList(page - 1);
          } else {
            handleGetsuppliersList(page);
          }
          toast.success(response?.data?.msg);
        }
      })
      .catch((err) => getCatchMsg(err))
      .finally(() => {
        dispatch(openLoader(false));
        setdeleteModal((prev) => {
          return {
            ...prev,
            modal: false,
          };
        });
      });
  };

  useEffect(() => {
    if (token) {
      handleGetsuppliersList();
    }
  }, [token]);

  return (
    <>
      <div className={classes.suppliers}>
        {isShowModal?.status && (
          <GlobalModal
            size="lg"
            modalStyle="globalModalmaxWidth logoutmodalminWidth"
            isVisible={isShowModal.status}
            setIsVisible={() => {
              setIsShowModal((prev) => {
                return {
                  ...prev,
                  status: true,
                };
              });
            }}
          >
            <AddSuppliers
              viewstatus={isShowModal?.viewstatus || false}
              editData={isShowModal?.data || null}
              listSuppliersApicall={(value) => {
                if (value === "update") {
                  handleGetsuppliersList(page);
                  return;
                }
                handleGetsuppliersList();
              }}
              onClose={() => {
                setIsShowModal((prev) => {
                  return {
                    ...prev,
                    status: false,
                  };
                });
              }}
              modalClose={() => {
                setIsShowModal((prev) => {
                  return {
                    ...prev,
                    status: false,
                  };
                });
              }}
            />
          </GlobalModal>
        )}
        <GlobalModal
          isVisible={deleteModal.modal}
          setIsVisible={() => {
            setdeleteModal((prev) => {
              return {
                ...prev,
                modal: false,
              };
            });
          }}
        >
          <ConfirmationModal
            msg={
              "Are you sure that you want to permanently delete this supplier"
            }
            onClose={() => {
              setdeleteModal((prev) => {
                return {
                  ...prev,
                  modal: false,
                };
              });
            }}
            onPositiveButtonPressed={handleDeleteSupplier}
            onNegativeButtonPressed={() => {
              setdeleteModal((prev) => {
                return {
                  ...prev,
                  modal: false,
                };
              });
            }}
          />
        </GlobalModal>
        <PageHeader
          heading="Suppliers"
          Btntitle="Add Suppliers"
          modal={() => {
            setIsShowModal((prev) => {
              return {
                ...prev,
                status: true,
                data: null,
                viewstatus: false,
              };
            });
          }}
          onFilterbutton={() => {
            setShowFilterOption((pre) => !pre);
            //
          }}
        />
        {/* {showFilterOption ? (
         
        ) : null} */}
        <AccordionContent filterStat={showFilterOption}>
          <div className={classes.serachContainer}>
            <div className="row mb-3">
              <Grid item lg={2} md={4} sm={6} xs={12}>
                <TextInputBox
                  title="Name"
                  customInputProps={{
                    maxLength: 50,
                    onKeyDown: (e) => handleTableSearch(e.key),
                  }}
                  name="name"
                  placeHolder="Search name"
                  value={values.name}
                  onChangeText={handleChange("name")}
                />
              </Grid>
              <Grid item lg={2} md={4} sm={6} xs={12}>
                <TextInputBox
                  title="Organization Name"
                  customInputProps={{
                    maxLength: 50,
                    onKeyDown: (e) => handleTableSearch(e.key),
                  }}
                  name="organizationName"
                  placeHolder="Search organization name"
                  value={values.organizationName}
                  onChangeText={handleChange("organizationName")}
                />
              </Grid>
              <Grid item lg={2} md={4} sm={6} xs={12}>
                <TextInputBox
                  title="Mobile Number"
                  customInputProps={{
                    maxLength: 50,
                    onKeyDown: (e) => handleTableSearch(e.key),
                  }}
                  name="mobile_no"
                  placeHolder="Search mobile number"
                  value={values.mobile_no}
                  onChangeText={(text) => {
                    const numericText = text.replace(/[^0-9]/g, "");
                    handleChange("mobile_no")(numericText);
                  }}
                />
              </Grid>
              <Grid item lg={2} md={4} sm={6} xs={12}>
                <TextInputBox
                  title="GST Number"
                  customInputProps={{
                    maxLength: 50,
                    onKeyDown: (e) => handleTableSearch(e.key),
                  }}
                  name="gstNo"
                  value={values.gstNo}
                  placeHolder="Search GST number"
                  onChangeText={handleChange("gstNo")}
                />
              </Grid>
            </div>
            <SearchAndClearbutton
              handleTableSearch={handleTableSearch}
              handleTableSearchClear={handleTableSearchClear}
              disabled={!isFormDirty(initialValues, values)}
            />
          </div>
        </AccordionContent>

        <div className={`table-responsive ${classes.AllTable}`}>
          <Box>
            <TableContainer>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>S.No</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Organization Name</TableCell>
                    <TableCell>Mobile Number </TableCell>
                    <TableCell>GST Number</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {suppliersList?.data?.items?.length === 0 ? (
                    <>
                      {noDataLoader ? (
                        <TableRow>
                          <TableCell className={classes.noData} colSpan={9}>
                            <NodataLoader />
                          </TableCell>
                        </TableRow>
                      ) : (
                        <TableRow>
                          <TableCell colSpan={9}>
                            <div className={classes.nodatafound}>
                              <img
                                src={Inbox}
                                alt="inbox"
                                width={100}
                                height={100}
                              />
                              <p>No data found</p>
                            </div>
                          </TableCell>
                        </TableRow>
                      )}
                    </>
                  ) : (
                    <>
                      {noDataLoader ? (
                        <TableRowsLoader columnNum={6} rowsNum={10} />
                      ) : suppliersList?.data?.items?.length === 0 ||
                        !suppliersList ? (
                        <TableRow>
                          <TableCell colSpan={9}>
                            <div className={classes.nodatafound}>
                              <img
                                src={Inbox}
                                alt="inbox"
                                width={100}
                                height={100}
                              />
                              <p>No data found</p>
                            </div>
                          </TableCell>
                        </TableRow>
                      ) : (
                        suppliersList?.data?.items?.map(
                          (device: any, index: number) => (
                            <TableRow key={index}>
                              <TableCell>
                                {(suppliersList?.data?.page - 1) *
                                  suppliersList?.data?.size +
                                  index +
                                  1}
                              </TableCell>
                              <TableCell>
                                {device?.name === null ? "-" : device?.name}
                              </TableCell>
                              <TableCell>
                                {device?.organizationName === null
                                  ? "-"
                                  : device?.organizationName}
                              </TableCell>
                              <TableCell>
                                {device?.mobile_no === null
                                  ? "-"
                                  : device?.mobile_no}
                              </TableCell>
                              <TableCell>
                                {device?.gstNo === null ? "-" : device?.gstNo}
                              </TableCell>
                              <TableCell>
                                <div className={classes.icons}>
                                  <Tooltip title="View" placement="top">
                                    <img
                                      src={view}
                                      alt="View"
                                      onClick={() =>
                                        setIsShowModal((prev) => {
                                          return {
                                            ...prev,
                                            status: true,
                                            data: device,
                                            viewid: device?.supplierId,
                                            viewstatus: true,
                                          };
                                        })
                                      }
                                    />
                                  </Tooltip>
                                  <Tooltip title="Edit" placement="top">
                                    <img
                                      src={edit}
                                      onClick={() => {
                                        setIsShowModal({
                                          status: true,
                                          data: device,
                                        });
                                      }}
                                      alt="Edit"
                                    />
                                  </Tooltip>
                                  <Tooltip title="Delete" placement="top">
                                    <img
                                      src={delete_icon}
                                      alt="Delete"
                                      onClick={() =>
                                        setdeleteModal((prev) => {
                                          return {
                                            ...prev,
                                            modal: true,
                                            id: device?.supplierId,
                                          };
                                        })
                                      }
                                    />
                                  </Tooltip>
                                </div>
                              </TableCell>
                            </TableRow>
                          )
                        )
                      )}
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </div>
      </div>
      {suppliersList?.data?.items?.length ? (
        <CustomPaginationwithdropdownpagination
          onChangePage={(page: number) => {
            handleGetsuppliersList(page, pageSize);
            setPage(page);
          }}
          onChangePagerow={(PageSize: number) => {
            if (page * PageSize > suppliersList?.data?.totalCount) {
              handleGetsuppliersList(1, PageSize);
              setPageSize(PageSize);
              return;
            }
            handleGetsuppliersList(page, PageSize);
            setPageSize(PageSize);
          }}
          count={suppliersList?.data?.totalCount}
          pages={page}
          pageCount={suppliersList?.data?.total_page}
          handleFunction={(selected: number) => {
            setPage(selected);
            handleGetsuppliersList(selected, pageSize);
          }}
          labelRowsPerPage={"Devices Per Page"}
          rowsperpage={pageSize}
          totalData={suppliersList?.data}
        />
      ) : null}
    </>
  );
}

export default Suppliers;
