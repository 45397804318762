import React, { useEffect, useState } from "react";
import PageHeader from "../../MangementLayoutHeader/PageHeader";
import {
  setIsShowModalType,
  setdeleteModalType,
  submodelListPropsType,
  submodelListTypeDataProps,
} from "../Management/managementTypes";
import { useFormik } from "formik";
import {
  CustomDropDown,
  CustomPagination,
  CustomPaginationDropdown,
  GlobalModal,
  Loader,
  NodataLoader,
  TableRowsLoader,
  TextInputBox,
} from "../../../Components";
import { getCatchMsg, isFormDirty } from "../../../Utility/GeneralUtils";
import {
  deletesubmodel,
  getmainModelDropdown,
  submodelList,
} from "../../../Services/Services";
import toast from "react-hot-toast";
import { Tooltip } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Box } from "@mui/material";
import classes from "../Management/Management.module.css";
import view from "../../../Assets/Icons/Png/view.png";
import edit from "../../../Assets/Icons/Svg/edit.svg";
import delete_icon from "../../../Assets/Icons/Svg/delete.svg";
import Inbox from "../../../Assets/Images/Png/noData.png";
import closeIcon from "../../../Assets/Icons/Png/texboxClose.png";
import { useToken } from "../../../Utility/StoreData";
import { ConfirmationModal, CreateSubModel } from "../../../Modals";
import { Grid } from "@material-ui/core";
import SearchAndClearbutton from "../../../Components/SearchAndClearbutton";
import { openLoader } from "../../../Store/Reducers/LoaderSlice";
import { useDispatch } from "react-redux";
import AccordionContent from "../../../Components/AccordionContent";

function SubModel() {
  const token = useToken();
  const dispatch = useDispatch();
  const [isShowModal, setIsShowModal] = useState<setIsShowModalType>({
    status: false,
    data: null,
    viewStatus: false,
    viewId: "",
  });
  const [showFilterOption, setShowFilterOption] = useState(false);
  const [deleteModal, setdeleteModal] = useState<setdeleteModalType>({
    modal: false,
    id: "",
  });
  const [searchOption, setSearchOption] = useState(false);
  const [subModelList, setSubModelList] =
    useState<submodelListTypeDataProps | null>(null);
  const [noDataLoader, setnoDataLoader] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [mainmodeldropdown, setMainModeldropdown] = useState();

  const { values, handleChange, resetForm, setFieldValue, initialValues } =
    useFormik({
      initialValues: {
        name: "",
        submodelname: "",
        description: "",
        mainModelName: "",
        mainModelId: "",
      },
      onSubmit: () => {},
    });
  const handleTableSearch = (data: string) => {
    if (data === "Enter") {
      setPage(1);
      handleSubmodelList();
    }
  };
  const handlegetmainModeldropdown = () => {
    getmainModelDropdown()
      .then((res) => {
        if (res?.data?.status === 1) {
          setMainModeldropdown(res?.data?.data);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      });
  };

  const handleTableSearchClear = () => {
    setPage(1);
    resetForm();
    handleSubmodelList(undefined, undefined, true);
  };

  const handleSubmodelList = (
    page = 1,
    size = 10,
    resetForm?: boolean,
    id = null
  ) => {
    setnoDataLoader(true);
    let formData = new FormData();
    formData.append("name", resetForm ? "" : values.submodelname);
    if (id === null) {
      formData.append("mainModelId", "");
    } else {
      formData.append("mainModelId", id);
    }
    submodelList(page, size, formData)
      .then((response) => {
        setPage(response?.data?.data?.page);
        setTotalCount(response?.data?.data?.totalCount);
        if (response?.data?.data?.items?.length > 0) {
          setSearchOption(true);
        } else if (values.submodelname || values.mainModelId) {
          setSearchOption(true);
        } else {
          setSearchOption(false);
        }
        setSubModelList(response?.data);
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        setnoDataLoader(false);
      });
  };

  const handledeleteSubmodel = () => {
    dispatch(openLoader(true));
    let formData = new FormData();
    formData.append("subModelId", deleteModal.id);
    deletesubmodel(formData)
      .then((res) => {
        if (res?.data?.status === 1) {
          if (totalCount % 10 === 1) {
            handleSubmodelList(page - 1);
          } else {
            handleSubmodelList(page);
          }
          toast.success(res?.data?.msg);
        } else if (res?.data?.status === 0) {
          toast.error(res?.data?.msg);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        dispatch(openLoader(false));
        setdeleteModal((prev) => {
          return {
            ...prev,
            modal: false,
          };
        });
      });
  };

  useEffect(() => {
    if (token) {
      handleSubmodelList();
      handlegetmainModeldropdown();
    }
  }, [token]);

  return (
    <>
      {" "}
      <PageHeader
        heading="Sub Model"
        Btntitle="Add Model"
        modal={() => {
          setIsShowModal((prev) => {
            return {
              ...prev,
              status: true,
              data: null,
              viewStatus: false,
            };
          });
        }}
        onFilterbutton={() => {
          setShowFilterOption((pre) => !pre);
        }}
      />
      {isShowModal?.status && (
        <GlobalModal
          size="sm"
          modalStyle="logoutmodalminWidth"
          isVisible={isShowModal.status}
          setIsVisible={() => {
            setIsShowModal((prev) => {
              return {
                ...prev,
                status: true,
              };
            });
          }}
        >
          <CreateSubModel
            getView={isShowModal.viewStatus}
            onClose={() => {
              setIsShowModal((prev) => {
                return {
                  ...prev,
                  status: false,
                };
              });
            }}
            /* @ts-ignore */
            editData={isShowModal?.data || null}
            listsubmodelapicall={(value) => {
              if (value === "update") {
                handleSubmodelList(page);
                return;
              }
              handleSubmodelList();
            }}
            modalClose={() => {
              setIsShowModal((prev) => {
                return {
                  ...prev,
                  status: false,
                };
              });
            }}
          />
        </GlobalModal>
      )}
      {deleteModal?.modal && (
        <GlobalModal
          isVisible={deleteModal.modal}
          setIsVisible={() => {
            setdeleteModal((prev) => {
              return {
                ...prev,
                modal: false,
              };
            });
          }}
        >
          <ConfirmationModal
            msg={`Are you sure that you want to permanently delete this sub model.
            `}
            onClose={() => {
              setdeleteModal((prev) => {
                return {
                  ...prev,
                  modal: false,
                };
              });
            }}
            onPositiveButtonPressed={handledeleteSubmodel}
            onNegativeButtonPressed={() => {
              setdeleteModal((prev) => {
                return {
                  ...prev,
                  modal: false,
                };
              });
            }}
          />
        </GlobalModal>
      )}
      {/* {showFilterOption ? (
       
      ) : null} */}
      <AccordionContent filterStat={showFilterOption}>
        <div className={classes.serachContainer}>
          <div className="row mb-3">
            <Grid item lg={2} md={4} sm={6} xs={12}>
              <TextInputBox
                title="Sub Model Name"
                textLength={50}
                customInputProps={{
                  onKeyDown: (e) => handleTableSearch(e.key),
                }}
                name="name"
                placeHolder="Search name"
                value={values.name}
                onChangeText={handleChange("name")}
              />
            </Grid>
            <Grid item lg={2} md={4} sm={6} xs={12}>
              <CustomDropDown
                title="Model Name"
                editName={
                  values.mainModelName ? values.mainModelName : "--None--"
                }
                //@ts-ignore
                items={mainmodeldropdown}
                onSelectedItem={(val, value) => {
                  handleSubmodelList(page, pageSize, false, value?.id);
                  setFieldValue("mainModelName", value.label);
                  setFieldValue("mainModelId", value.id);
                }}
                fieldName="mainModelName"
                anotherFieldName="mainModelId"
                value={values.mainModelId}
              />
            </Grid>
          </div>
          <SearchAndClearbutton
            handleTableSearch={handleTableSearch}
            handleTableSearchClear={handleTableSearchClear}
            disabled={!isFormDirty(initialValues, values)}
          />
        </div>
      </AccordionContent>
      <div className={`table-responsive ${classes.AllTable}`}>
        <Box>
          <TableContainer>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>S.No</TableCell>
                  <TableCell>Sub model name</TableCell>
                  <TableCell>Model name</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {subModelList?.data?.items?.length === 0 ? (
                  <>
                    {noDataLoader ? (
                      <TableRow>
                        <TableCell className={classes.noData} colSpan={9}>
                          <NodataLoader />
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableRow>
                        <TableCell colSpan={9}>
                          <div className={classes.nodatafound}>
                            <img
                              src={Inbox}
                              alt="inbox"
                              width={100}
                              height={100}
                            />
                            <p>No data found</p>
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                ) : (
                  <>
                    {noDataLoader ? (
                      <TableRowsLoader columnNum={3} rowsNum={10} />
                    ) : subModelList?.data?.items?.length === 0 ||
                      !subModelList ? (
                      <TableRow>
                        <TableCell colSpan={9}>
                          <div className={classes.nodatafound}>
                            <img
                              src={Inbox}
                              alt="inbox"
                              width={100}
                              height={100}
                            />
                            <p>No data found</p>
                          </div>
                        </TableCell>
                      </TableRow>
                    ) : (
                      subModelList?.data?.items?.map(
                        (submodel: submodelListPropsType, index: number) => (
                          <TableRow key={index}>
                            <TableCell>
                              {(subModelList?.data?.page - 1) *
                                subModelList?.data?.size +
                                index +
                                1}
                            </TableCell>
                            <TableCell>
                              {submodel.name === null ? "-" : submodel.name}
                            </TableCell>
                            <TableCell>
                              {submodel.modelName === null
                                ? "-"
                                : submodel.modelName}
                            </TableCell>
                            <TableCell>
                              <div className={classes.icons}>
                                <Tooltip title="View" placement="top">
                                  <img
                                    src={view}
                                    alt="View"
                                    onClick={() =>
                                      setIsShowModal((prev: any) => {
                                        return {
                                          ...prev,
                                          status: true,
                                          viewStatus: true,
                                          data: submodel,
                                        };
                                      })
                                    }
                                  />
                                </Tooltip>
                                <Tooltip title="Edit" placement="top">
                                  <img
                                    src={edit}
                                    onClick={() => {
                                      setIsShowModal((prev) => {
                                        return {
                                          ...prev,
                                          status: true,
                                          data: submodel,
                                          viewStatus: false,
                                        };
                                      });
                                    }}
                                    alt="Edit"
                                  />
                                </Tooltip>
                                <Tooltip title="Delete" placement="top">
                                  <img
                                    src={delete_icon}
                                    alt="Delete"
                                    onClick={() =>
                                      setdeleteModal((prev: any) => {
                                        return {
                                          ...prev,
                                          modal: true,
                                          id: submodel.id,
                                        };
                                      })
                                    }
                                  />
                                </Tooltip>
                              </div>
                            </TableCell>
                          </TableRow>
                        )
                      )
                    )}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </div>
      {subModelList && (
        <CustomPagination
          page={page}
          pageCount={subModelList?.data?.total_page}
          handleFunction={(selected) => {
            setPage(selected);
            handleSubmodelList(selected);
          }}
        />
      )}
    </>
  );
}

export default SubModel;
