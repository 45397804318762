import React, { useEffect, useState } from "react";
import { AddCategorizationProps, catgarizationViewListProps } from "./Types";
import { CustomButton, Loader, TextInputBox } from "../Components";
import ModalHeader from "./ModalHeader";
import { useFormik } from "formik";
import * as Yup from "yup";
import classes from "./Modal.module.css";
import {
  addCategorization,
  updateCategorization,
  viewCategorization,
} from "../Services/Services";
import toast from "react-hot-toast";
import { getCatchMsg } from "../Utility/GeneralUtils";
import { useDispatch } from "react-redux";
import { openLoader } from "../Store/Reducers/LoaderSlice";

const validation = Yup.object({
  name: Yup.string()
    .required("* Name is required")
    .trim("Remove leading and trailing spaces")
    .strict(true),
  data: Yup.array().of(
    Yup.object().shape({
      fieldName: Yup.string()
        .required("* Fieldname is required")
        .trim("Remove leading and trailing spaces")
        .strict(true),
    })
  ),
});

function AddCategorization({
  listApiCall,
  editData,
  onClose,
  modalClose,
  viewStatus,
  viewId,
}: AddCategorizationProps) {
  const dispatch = useDispatch();

  const [AddValidation, setAddValidation] = useState(false);
  const [catgarizationViewList, setCatgarizationViewList] =
    useState<catgarizationViewListProps | null>(null);
  const {
    values,
    handleChange,
    handleSubmit,
    setValues,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      mainId: "",
      name: "",
      data: [
        {
          fieldName: "",
        },
      ],
    },
    validationSchema: validation,
    onSubmit: () => {
      if (AddValidation) {
        setValues({
          ...values,
          data: [
            ...values.data,
            {
              fieldName: "",
            },
          ],
        });
      } else if (!editData) {
        handleAddCategarization(values);
      } else {
        handleAddUpdategarization(values);
      }
    },
  });

  useEffect(() => {
    if (catgarizationViewList) {
      setFieldValue("name", catgarizationViewList?.categorizationName);
      setFieldValue("data", catgarizationViewList?.fields);
      setFieldValue("mainId", viewId);
    }
  }, [catgarizationViewList]);

  const handleAddCategarization = (data: any) => {
    dispatch(openLoader(true));
    let tempData = [...data?.data];
    const finalData = tempData.map((ele, index) => {
      return {
        fieldName: ele?.fieldName,
      };
    });
    let sendData = {
      name: data?.name,
      data: finalData,
    };
    addCategorization(sendData)
      .then((response) => {
        if (response?.data?.status === 1) {
          modalClose();
          listApiCall("add");
          toast.success(response?.data?.msg);
        } else if (response?.data?.status === 0) {
          toast.error(response?.data?.msg);
        }
      })
      .catch((error) => {
        getCatchMsg(error);
      })
      .finally(() => {
        dispatch(openLoader(false));
      });
  };

  const handleAddUpdategarization = (data: any) => {
    dispatch(openLoader(true));
    let tempData = [...data?.data];
    const finalData = tempData.map((ele, index) => {
      return {
        fieldName: ele?.fieldName,
        fieldId: ele?.fieldId,
      };
    });
    let sendData = {
      mainId: data?.mainId,
      name: data?.name,
      data: finalData,
    };
    updateCategorization(sendData)
      .then((response) => {
        if (response?.data?.status === 1) {
          modalClose();
          listApiCall("update");
          toast.success(response?.data?.msg);
        } else if (response?.data?.status === 0) {
          toast.error(response?.data?.msg);
        }
      })
      .catch((error) => {
        getCatchMsg(error);
      })
      .finally(() => {
        dispatch(openLoader(false));
      });
  };

  const handleGetCatigarization = () => {
    dispatch(openLoader(true));
    let formData = new FormData();
    if (viewId) formData.append("categoriesId", viewId?.toString());
    viewCategorization(formData)
      .then((response) => {
        if (response?.data?.status === 1) {
          setCatgarizationViewList(response?.data?.data);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        dispatch(openLoader(false));
      });
  };

  useEffect(() => {
    if (viewStatus || editData) {
      handleGetCatigarization();
    }
  }, [viewStatus, editData]);

  return (
    <>
      <ModalHeader
        onClose={onClose}
        heading={
          viewStatus
            ? "View Answer Set"
            : editData === null
              ? "Add  Answer Set"
              : "Edit  Answer Set"
        }
      />
      <div className={classes.Categarization}>
        {viewStatus ? (
          <div className={classes.viewContainer}>
            <div className={classes.viewContent}>
              <p
                style={{
                  minWidth: "160px",
                }}
              >
                Answer Set
              </p>
              <strong>:</strong>
              <span>
                {catgarizationViewList?.categorizationName
                  ? catgarizationViewList?.categorizationName
                  : "-"}
              </span>
            </div>
            <div
              style={{
                marginTop: "10px",
              }}
            >
              <p className={classes.catgarizationFields}>
                Options <strong>:</strong>
              </p>
              <div className={classes.catgarizationFieldsList}>
                {catgarizationViewList?.fields.map((ele, index) => (
                  <span>{`${index + 1})${ele?.fieldName}`}</span>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className={classes.AddBoardSpecification}>
              <div className={classes.catgoryDropDown}>
                <TextInputBox
                  requiredText={"*"}
                  textLength={50}
                  placeHolder="Enter answer set name"
                  title="Answer Set"
                  name="name"
                  onChangeText={handleChange("name")}
                  value={values.name}
                  errorText={errors?.name && touched.name ? errors?.name : ""}
                />
              </div>
              <div className={classes.AddDeviceMulitpleContent}>
                <div className={classes.multipleContent}>
                  {[...values.data].map((category, index) => (
                    <div className="col-12 form-group mb-3">
                      <TextInputBox
                        onPressCategarizationDelete={() => {
                          let tempData = [...values?.data];
                          let tempFildNames = tempData;
                          tempFildNames = tempData.filter(
                            (ele, tempIndex) => tempIndex != index
                          );
                          setFieldValue("data", tempFildNames);
                        }}
                        textLength={25}
                        categarization={
                          [...values?.data].length > 1 ? true : false
                        }
                        requiredText={"*"}
                        placeHolder="Enter option name"
                        value={values?.data[index]?.fieldName}
                        title="Option Name"
                        name={`data[${index}].fieldName`}
                        onChangeText={handleChange(`data[${index}].fieldName`)}
                        errorText={
                          //@ts-ignore
                          errors?.data?.[index]?.fieldName &&
                          touched?.data?.[index]?.fieldName
                            ? //@ts-ignore
                              errors?.data?.[index]?.fieldName
                            : ""
                        }
                      />
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-lg-2 mx-auto  mt-1">
                <CustomButton
                  title="+"
                  onButtonPress={() => {
                    handleSubmit();
                    setAddValidation(true);
                  }}
                  customButtonStyle={{
                    backgroundColor: "rgba(0, 0, 0, 0.08)",
                    color: "black",
                  }}
                />
              </div>
              <div className="col-lg-4 my-4">
                <CustomButton
                  onButtonPress={() => {
                    setAddValidation(false);
                    handleSubmit();
                  }}
                  title="Submit"
                />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default AddCategorization;
