import {
  Grid,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { createContext, useEffect, useState } from "react";
import PageHeader from "../MangementLayoutHeader/PageHeader";
import {
  CustomDropDown,
  CustomPaginationwithdropdownpagination,
  GlobalModal,
  TableRowsLoader,
  TextInputBox,
} from "../../Components";
import edit from "../../Assets/Icons/Svg/edit.svg";
import delete_icon from "../../Assets/Icons/Svg/delete.svg";
import view from "../../Assets/Icons/Png/view.png";
import Inbox from "../../Assets/Images/Png/noData.png";
import managementStyles from "../HomeScreens/Management/Management.module.css";
import AddServiceHistory from "../../Modals/AddServiceHistory";
import {
  deleteServiceHistory,
  getDeviceDrodownService,
  listServiceHistoryService,
} from "../../Services/Services";
import {
  downloadUrl,
  getCatchMsg,
  isFormDirty,
} from "../../Utility/GeneralUtils";
import {
  ServiceHistoryDataProps,
  ServiceHistoryProps,
  showHistoryProps,
} from "./Types";
import { DeviceDropdownProps } from "../../Modals/Types";
import { ConfirmationModal } from "../../Modals";
import toast from "react-hot-toast";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useFormik } from "formik";
import dayjs from "dayjs";
import SearchAndClearbutton from "../../Components/SearchAndClearbutton";
import { Close } from "@mui/icons-material";
import AccordionContent from "../../Components/AccordionContent";

export const ServiceHistoryContext = createContext({
  disableScroll: false,
  hasNext: () => {},
  loading: false,
});
export default function ServiceHistoryApp() {
  const {
    handleChange,
    handleSubmit,
    setFieldTouched,
    values,
    setFieldError,
    touched,
    errors,
    setFieldValue,
    handleBlur,
    resetForm,
    initialValues,
  } = useFormik({
    initialValues: {
      device_name: "",
      device_id: "",
      fromdate: "",
      todate: "",
      service_type: "-1",
      technician_name: "",
      device_status: "-1",
    },
    onSubmit: () => {},
  });

  const [serviceHistoryList, SetserviceHistoryList] =
    useState<ServiceHistoryProps | null>(null);
  const [showAddHistory, setShowAddHistory] = useState<showHistoryProps>({
    status: false,
    view: false,
    data: null,
  });

  const [deleteModal, setdeleteModal] = useState({
    status: false,
    id: "",
  });
  const [ListDevice, setListDevice] = useState<DeviceDropdownProps | null>(
    null
  );
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [deviceDropPage, setDeviceDropPage] = useState({
    page: 1,
    size: 30,
  });
  const [droploading, setdroploading] = useState(false);
  const [showFilters, setshowFilters] = useState(false);
  const [noDataLoader, setnoDataLoader] = useState(false);

  const getServiceHistoryList = (
    page: number = 1,
    size: number = 10,
    isDownload?: boolean,
    reset?: boolean
  ) => {
    const formdata = new FormData();
    if (isDownload) {
      formdata.append("isDownload", "1");
    }
    if (!isDownload) {
      setnoDataLoader(true);
    }

    formdata.append("deviceId", reset ? "" : values.device_id);
    formdata.append("technician", reset ? "" : values.technician_name);
    formdata.append(
      "serviceType",
      reset || values.service_type === "-1" ? "" : values.service_type
    );
    formdata.append(
      "deviceStatus",
      reset || values.device_status === "-1" ? "" : values.device_status
    );
    formdata.append("fromDate", reset ? "" : values.fromdate);
    formdata.append("todate", reset ? "" : values.todate);

    listServiceHistoryService(page, size, formdata)
      .then((res) => {
        if (res?.data?.status) {
          if (isDownload) {
            const { data } = res?.data;
            if (data) {
              downloadUrl(data);
            }
          } else {
            SetserviceHistoryList(res?.data?.data);
            setPage(res?.data?.data?.page || 1);
            setPageSize(res?.data?.data?.size || 10);
          }
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        setnoDataLoader(false);
      });
  };

  const getDeviceDropdown = (page = 1, size = 30) => {
    setdroploading(true);
    const formdata = new FormData();
    formdata.append("deviceCode", "");
    getDeviceDrodownService(page, size, formdata)
      .then((res) => {
        if (res?.data?.status) {
          if (ListDevice) {
            // @ts-ignore
            setListDevice((pre) => ({
              ...pre,
              // @ts-ignore
              items: [...pre?.items, ...res?.data?.data?.items],
              page: res?.data?.data?.page,
              size: res?.data?.data?.size,
            }));
          } else {
            setListDevice(res?.data?.data);
          }
          setDeviceDropPage({
            page: res?.data?.data?.page,
            size: 30,
          });
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        setdroploading(false);
      });
  };

  const hanldeDeleteServiceHistory = () => {
    const formData = new FormData();
    formData.append("serviceId", deleteModal.id);
    deleteServiceHistory(formData)
      .then((res) => {
        if (res.data?.status) {
          toast.success(res?.data?.msg);
          // @ts-ignore

          if (
            serviceHistoryList &&
            (serviceHistoryList?.items?.length - 1) % 10 === 0
          ) {
            getServiceHistoryList(page - 1, pageSize);
          } else {
            getServiceHistoryList(page, pageSize);
          }
        } else {
          toast.error(res?.data?.msg);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        setdeleteModal({
          status: false,
          id: "",
        });
      });
  };

  /**
   * Handles the table search functionality.
   * If the data parameter is equal to "Enter", it resets the page to 0 and calls the
   * handleListofOrganization function to fetch the list of organizations.
   * @param {string | number} data - The data to be searched.
   * @returns None
   */

  const handleTableSearch = (data: string | number) => {
    if (data === "Enter") {
      getServiceHistoryList(1, pageSize);
    }
  };

  /**
   * Clears the table search by resetting the page number, resetting the form, and
   * triggering a new search for the list of organizations.
   * @returns None
   */
  const handleTableSearchClear = () => {
    resetForm();
    getServiceHistoryList(undefined, undefined, false, true);
  };

  useEffect(() => {
    getServiceHistoryList();
  }, [values.device_id, values.device_status, values.service_type]);

  useEffect(() => {
    getDeviceDropdown();
  }, []);
  return (
    <ServiceHistoryContext.Provider
      value={{
        disableScroll:
          ListDevice && ListDevice?.items?.length >= ListDevice?.totalCount
            ? true
            : false,
        hasNext: () => {
          getDeviceDropdown(deviceDropPage.page + 1, 30);
        },

        loading: droploading,
      }}
    >
      <Grid
        style={{
          maxWidth: "1900px",
          margin: "auto",
        }}
        className="py-4"
      >
        <Grid>
          <PageHeader
            heading="Service History"
            Btntitle="Add Service History"
            modal={() => {
              setShowAddHistory({
                status: true,
                view: false,
                data: null,
              });
            }}
            downloadBtn={
              serviceHistoryList && serviceHistoryList?.items?.length > 0
                ? true
                : false
            }
            onDownloadPress={() => {
              getServiceHistoryList(1, 10, true);
            }}
            onFilterbutton={() => {
              setshowFilters((pre) => !pre);
            }}
          />

          {showAddHistory?.status && (
            <GlobalModal
              modalStyle={
                showAddHistory.view ? "logoutmodalminWidth" : "globalModallg"
              }
              isVisible={showAddHistory?.status}
              setIsVisible={() => {
                setShowAddHistory((pre) => ({
                  ...pre,
                  status: false,
                  view: false,
                }));
              }}
            >
              <AddServiceHistory
                editdata={showAddHistory.data}
                view={showAddHistory.view}
                onClose={() => {
                  setShowAddHistory((pre) => ({
                    ...pre,
                    status: false,
                    view: false,
                  }));
                }}
                listapi={(value) => {
                  if (value === "update") {
                    getServiceHistoryList(page);
                    return;
                  }
                  getServiceHistoryList();
                }}
                ListDevice={ListDevice}
              />
            </GlobalModal>
          )}

          {deleteModal.status && (
            <GlobalModal
              isVisible={deleteModal.status}
              setIsVisible={() => {
                setdeleteModal((prev) => {
                  return {
                    ...prev,
                    status: false,
                  };
                });
              }}
            >
              <ConfirmationModal
                msg={
                  "Are you sure that you want to permanently delete this service history"
                }
                onClose={() => {
                  setdeleteModal((prev) => {
                    return {
                      ...prev,
                      status: false,
                    };
                  });
                }}
                onPositiveButtonPressed={hanldeDeleteServiceHistory}
                onNegativeButtonPressed={() => {
                  setdeleteModal((prev) => {
                    return {
                      ...prev,
                      status: false,
                    };
                  });
                }}
              />
            </GlobalModal>
          )}

          {/* {showFilters && (
            
          )} */}
          <AccordionContent filterStat={showFilters}>
            <Grid>
              <Grid className={managementStyles.serachContainer}>
                <Grid container spacing={2}>
                  <Grid className="fieldBr8" item lg={2} md={4} sm={6} xs={12}>
                    <CustomDropDown
                      required="*"
                      editName={
                        values.device_name ? values.device_name : "none"
                      }
                      title="Device"
                      //@ts-ignore
                      items={ListDevice?.items}
                      onSelectedItem={(val, value) => {
                        setFieldValue(
                          "device_name",
                          value?.label ? value?.label : ""
                        );
                        setFieldValue("device_id", value?.id ? value?.id : "");
                      }}
                      fieldName="deviceCode"
                      // AddnewOption={true}
                      anotherFieldName="id"
                      value={values.device_id}
                      pagination={true}
                      disableClearable={!values?.device_id}
                    />
                  </Grid>
                  <Grid className="fieldBr8" item lg={2} md={4} sm={6} xs={12}>
                    <TextInputBox
                      title="From date"
                      textOnly
                      onChangeText={() => {}}
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        maxDate={dayjs(values.todate, "YYYY-MM-DD").subtract(
                          1,
                          "day"
                        )}
                        onChange={(val) => {
                          // @ts-ignore

                          handleChange("fromdate")(
                            dayjs(val).format("YYYY-MM-DD")
                          );
                        }}
                        format="YYYY-MM-DD"
                        slotProps={{
                          textField: {
                            size: "small",
                            fullWidth: true,
                          },
                        }}
                        value={
                          values.fromdate
                            ? dayjs(values.fromdate, "YYYY-MM-DD")
                            : null
                        }
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid className="fieldBr8" item lg={2} md={4} sm={6} xs={12}>
                    <TextInputBox
                      title="To date"
                      textOnly
                      onChangeText={() => {}}
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        minDate={dayjs(values.fromdate, "YYYY-MM-DD").add(
                          1,
                          "day"
                        )}
                        onChange={(val) => {
                          // @ts-ignore

                          handleChange("todate")(
                            dayjs(val).format("YYYY-MM-DD")
                          );
                        }}
                        format="YYYY-MM-DD"
                        slotProps={{
                          textField: {
                            size: "small",
                            fullWidth: true,
                          },
                        }}
                        value={
                          values.todate
                            ? dayjs(values.todate, "YYYY-MM-DD")
                            : null
                        }
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item lg={2} md={4} sm={6} xs={12}>
                    <TextInputBox
                      title="Technician Name"
                      placeHolder="Search technician"
                      onChangeText={(e) => {
                        setFieldValue("technician_name", e);
                      }}
                      onKeyDown={(e) => {
                        handleTableSearch(e.key);
                      }}
                      value={values.technician_name}
                    />
                  </Grid>
                  <Grid className="fieldBr8" item lg={2} md={4} sm={6} xs={12}>
                    <TextInputBox
                      onChangeText={() => {}}
                      title="Service Type"
                      textOnly
                      requiredText={"*"}
                    />

                    <TextField
                      fullWidth
                      size="small"
                      select
                      value={values.service_type}
                      onChange={(e) => {
                        handleChange("service_type")(e.target.value);
                      }}
                      defaultValue={"-1"}
                      placeholder="Service Type"
                      sx={{
                        "& .MuiSelect-select": {
                          color:
                            values.service_type === "-1"
                              ? "rgba(0,0,0,0.5)"
                              : "",
                        },
                      }}
                      InputProps={{
                        endAdornment: (
                          <Close
                            style={{
                              marginRight: "17px",
                              fontSize: "16px",
                              cursor: "pointer",
                              display:
                                values.service_type === "-1" ? "none" : "block",
                            }}
                            onClick={() => {
                              setFieldValue("service_type", "-1");
                            }}
                          />
                        ),
                      }}
                    >
                      <MenuItem
                        value={"-1"}
                        style={{ visibility: "hidden", height: 0, padding: 0 }}
                      >
                        Select Service Type
                      </MenuItem>
                      <MenuItem value={"1"}>Maintenance</MenuItem>
                      <MenuItem value={"2"}>Repair</MenuItem>
                      <MenuItem value={"3"}>Upgrade</MenuItem>
                      <MenuItem value={"4"}>Installation</MenuItem>
                    </TextField>
                  </Grid>

                  <Grid className="fieldBr8" item lg={2} md={4} sm={6} xs={12}>
                    <TextInputBox
                      onChangeText={() => {}}
                      title="Device Status"
                      textOnly
                      requiredText={"*"}
                    />

                    <TextField
                      fullWidth
                      size="small"
                      select
                      value={values.device_status}
                      onChange={(e) => {
                        handleChange("device_status")(e.target.value);
                      }}
                      defaultValue={"-1"}
                      sx={{
                        "& .MuiSelect-select": {
                          color:
                            values.device_status === "-1"
                              ? "rgba(0,0,0,0.5)"
                              : "",
                        },
                      }}
                      placeholder="Device Status"
                      InputProps={{
                        endAdornment: (
                          <Close
                            style={{
                              marginRight: "17px",
                              fontSize: "16px",
                              cursor: "pointer",
                              display:
                                values.device_status === "-1"
                                  ? "none"
                                  : "block",
                            }}
                            onClick={() => {
                              setFieldValue("device_status", "-1");
                            }}
                          />
                        ),
                      }}
                    >
                      <MenuItem
                        value={"-1"}
                        style={{ visibility: "hidden", height: 0, padding: 0 }}
                      >
                        Select Device Status
                      </MenuItem>
                      <MenuItem value={"1"}>Operational</MenuItem>
                      <MenuItem value={"2"}>Partially Operational</MenuItem>
                      <MenuItem value={"3"}>Dead</MenuItem>
                    </TextField>
                  </Grid>
                </Grid>
                <Grid paddingTop={1}>
                  <SearchAndClearbutton
                    handleTableSearch={handleTableSearch}
                    handleTableSearchClear={handleTableSearchClear}
                    disabled={!isFormDirty(initialValues, values)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </AccordionContent>

          <Grid>
            <div className={`table-responsive py-2 px-3`}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>S.No</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Device Code</TableCell>

                      <TableCell>Technician Name</TableCell>
                      <TableCell>Service Type</TableCell>
                      <TableCell>Device Status</TableCell>

                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <>
                      {noDataLoader ? (
                        <TableRowsLoader columnNum={7} rowsNum={10} />
                      ) : serviceHistoryList?.items?.length === 0 ||
                        !serviceHistoryList ? (
                        <TableRow>
                          <TableCell colSpan={7}>
                            <div className={managementStyles.nodatafound}>
                              <img
                                src={Inbox}
                                alt="inbox"
                                width={100}
                                height={100}
                              />
                              <p>No data found</p>
                            </div>
                          </TableCell>
                        </TableRow>
                      ) : (
                        serviceHistoryList?.items?.map(
                          (history: ServiceHistoryDataProps, index: number) => (
                            <TableRow key={index}>
                              <TableCell>
                                {(serviceHistoryList?.page - 1) *
                                  serviceHistoryList?.size +
                                  index +
                                  1}
                              </TableCell>

                              <TableCell>
                                {history.serviceDate
                                  ? history.serviceDate
                                  : "-"}
                              </TableCell>
                              <TableCell>
                                {history.deviceCode ? history.deviceCode : "-"}
                              </TableCell>
                              <TableCell>
                                {history?.technician
                                  ? history?.technician
                                  : "-"}
                              </TableCell>
                              <TableCell>
                                {history.serviceType
                                  ? history.serviceType
                                  : "-"}
                              </TableCell>
                              <TableCell>
                                {history?.deviceStatus
                                  ? history?.deviceStatus
                                  : "-"}
                              </TableCell>

                              <TableCell>
                                <div className={managementStyles.icons}>
                                  <Tooltip title="View" placement="top">
                                    <img
                                      src={view}
                                      alt="View"
                                      onClick={() => {
                                        setShowAddHistory({
                                          status: true,
                                          view: true,
                                          data: history,
                                        });
                                      }}
                                    />
                                  </Tooltip>
                                  <Tooltip title="Edit" placement="top">
                                    <img
                                      src={edit}
                                      onClick={() => {
                                        setShowAddHistory({
                                          status: true,
                                          view: false,
                                          data: history,
                                        });
                                      }}
                                      alt="Edit"
                                    />
                                  </Tooltip>

                                  <Tooltip title="Delete" placement="top">
                                    <img
                                      src={delete_icon}
                                      alt="Delete"
                                      onClick={() => {
                                        setdeleteModal({
                                          status: true,
                                          id: history?.serviceId,
                                        });
                                      }}
                                    />
                                  </Tooltip>
                                </div>
                              </TableCell>
                            </TableRow>
                          )
                        )
                      )}
                    </>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Grid>
        </Grid>
        {serviceHistoryList?.items?.length ? (
          <CustomPaginationwithdropdownpagination
            onChangePage={(page: number) => {
              getServiceHistoryList(page, pageSize);
              setPage(page);
            }}
            onChangePagerow={(PageSize: number) => {
              if (page * PageSize > serviceHistoryList?.total_count) {
                getServiceHistoryList(1, PageSize);
                setPageSize(PageSize);
                return;
              }
              getServiceHistoryList(page, PageSize);
              setPageSize(PageSize);
            }}
            count={serviceHistoryList?.total_count}
            pages={page}
            pageCount={serviceHistoryList?.total_page}
            handleFunction={(selected: number) => {
              setPage(selected);
              getServiceHistoryList(selected, pageSize);
            }}
            labelRowsPerPage={"History Per Page"}
            rowsperpage={pageSize}
            totalData={{
              totalCount: serviceHistoryList.total_count,
              size: serviceHistoryList.size,
              page: serviceHistoryList.page,
            }}
          />
        ) : null}
      </Grid>
    </ServiceHistoryContext.Provider>
  );
}
