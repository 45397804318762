import { Grid } from "@mui/material";
import React from "react";
import ReactApexChart from "react-apexcharts";
import { muiColors } from "../Utility/Constants";
import { year } from "../Utility/GeneralUtils";

export default function LineChart({
  series,
  labels,
  lineValue,
  count,
  ismax,
}: {
  series: number[];
  labels: string[];
  lineValue: number;
  count?: number[];
  ismax?: number[];
}) {
  const mtbfOptions = [
    {
      showAlways: true,
      axisBorder: {
        show: true,
      },
      labels: {
        show: true,
      },
      title: {
        text: "Actual Hours",
        offsetX: -10,
        style: {
          fontSize: "18px",
        },
      },
      floating: false,
      decimalsInFloat: 0,
    },

    {
      seriesName: "Machine Count",
      opposite: true,
      showAlways: true,
      axisBorder: {
        show: true,
      },
      labels: {
        show: true,
      },
      title: {
        text: "Machine Count",
        offsetX: 10,

        style: {
          fontSize: "18px",
        },
      },
      floating: false,
      decimalsInFloat: 0,
    },
    {
      seriesName: "Line Value",
      labels: {
        show: false,
      },
      reversed: true,
    },
  ];

  const mttrOptions = {
    showAlways: true,
    axisBorder: {
      show: true,
    },
    labels: {
      show: true,
    },
    title: {
      text: "Actual Hours",
      offsetX: -10,
      style: {
        fontSize: "18px",
      },
    },
    floating: false,
    decimalsInFloat: 0,
  };

  const CustomTooltip = ({
    series,
    seriesIndex,
    dataPointIndex,
    w,
  }: {
    series: any;
    seriesIndex: number;
    dataPointIndex: number;
    w: any;
  }) => {
    const labels = w.globals.categoryLabels;
    const isMax = ismax ? ismax[dataPointIndex] : 0;
    const getmarkerColor = (index: number) => {
      return w.globals.colors[index];
    };
    const getseriesName = (index: number) => {
      return w.config.series[index]?.name;
    };

    return count
      ? `<div class="custom-tooltip">
              <div class="tooltip-header">${
                labels[dataPointIndex]
              }&ensp;<span>${isMax ? "(max)" : ""}</span></div>
              <div class="tooltip-text"><div class="tooltip-marker" style="background-color:${
                isMax ? "green" : getmarkerColor(0)
              };" ></div><div>${getseriesName(0)} <span>${
                series[0][dataPointIndex]
              }</span></div></div>
              <div class="tooltip-text"><div class="tooltip-marker" style="background-color:${getmarkerColor(
                1
              )};" ></div><div>${getseriesName(1)} <span>${
                series[1][dataPointIndex]
              }</span></div></div>
              <div class="tooltip-text"><div class="tooltip-marker" style="background-color:${getmarkerColor(
                2
              )};" ></div><div>${getseriesName(2)} <span>${
                series[2][dataPointIndex]
              }</span></div></div></div>
            </div>`
      : `<div class="custom-tooltip">
            <div class="tooltip-header">${labels[dataPointIndex]}&ensp;<span>${
              isMax ? "(max)" : ""
            }</span></div>
            <div class="tooltip-text"><div class="tooltip-marker" style="background-color:${
              isMax ? "green" : getmarkerColor(0)
            };" ></div><div>${getseriesName(0)} <span>${
              series[0][dataPointIndex]
            }</span></div></div>
            <div class="tooltip-text"><div class="tooltip-marker" style="background-color:${getmarkerColor(
              1
            )};" ></div><div>${getseriesName(1)} <span>${
              series[1][dataPointIndex]
            }</span></div></div>
          </div>
          </div>`;
  };

  return (
    <Grid>
      <ReactApexChart
        height={400}
        options={{
          labels: labels?.map((ele) => year[parseInt(ele) - 1]),
          yaxis: count ? mtbfOptions : mttrOptions,

          stroke: {
            dashArray: [0, 5],
          },
          markers: {
            size: 5,

            shape: "circle",
            hover: {
              size: 7,
            },
          },

          noData: {
            text: "No Data Found",

            style: {
              color: "#5d5d5d",
              fontSize: "14px",
            },
          },
          tooltip: {
            custom({ series, seriesIndex, dataPointIndex, w }) {
              return CustomTooltip({ series, seriesIndex, dataPointIndex, w });
            },
            shared: false,
            marker: {
              fillColors: ["#bd1550", "#FF0000", "#f89f1d"],
            },
          },
        }}
        series={
          count
            ? [
                {
                  name: "Average Hours",
                  data: series?.map((ele, index) => {
                    return {
                      x: year[parseInt(labels[index]) - 1],
                      y: ele,
                      fillColor: ismax && ismax[index] ? "green" : muiColors[0],
                    };
                  }),
                },

                {
                  name: "Machine Count",
                  data: series?.map((ele, index) => {
                    return {
                      x: labels[index],
                      y: count[index],
                    };
                  }),
                },
                {
                  name: "Line Value",
                  data: series?.map((ele, index) => {
                    return {
                      x: labels[index],
                      y: lineValue,
                    };
                  }),
                },
              ]
            : [
                {
                  name: "Average Hours",
                  data: series,
                },
                {
                  name: "Line Value",
                  data: [...Array(series?.length)]?.fill(lineValue),
                },
              ]
        }
        type="line"
      />
    </Grid>
  );
}
