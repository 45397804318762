import React from "react";
import {
  FormControl,
  makeStyles,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { Select } from "@mui/material";

import classes from "./CustomStyle.module.css";
import { CustomPaginationDropdownPropsType } from "./Types";

const useStyles = makeStyles((theme) => ({
  itemperpage: {
    marginTop: "3px",
  },
  background: {
    backgroundColor: "transparent",
  },
  showingtext: {
    marginTop: "25px",
    marginLeft: "30px",
  },
  pageSelect: {
    height: "35px !important",
    borderRadius: "4px",
    "& fieldset:hover": {
      borderColor: "inherit !important",
      border: "none  !important",
    },

    "& fieldset": {
      borderColor: "inherit !important",
      border: "none  !important",
    },
  },
}));
export default function CustomPaginationDropdown({
  itemperpage,
  totalData,
  title,
  value = 10,
  dashboard = false,
  totalItem,
}: CustomPaginationDropdownPropsType) {
  const styles = useStyles();
  return (
    <div className={classes.paginationsizedropdown}>
      <div className={classes.paginationtxt}>
        <div>
          <Typography>
            Showing:
            <strong>
              {" "}
              {!totalData?.totalCount
                ? 0
                : totalData?.size &&
                  totalData?.page &&
                  totalData?.size * (totalData?.page - 1) + 1}
            </strong>{" "}
            to{" "}
            <strong>
              {totalData?.size &&
              totalData?.page &&
              totalData?.totalCount &&
              totalData?.size * totalData?.page <= totalData?.totalCount
                ? totalData?.size * totalData?.page
                : totalData?.totalCount ?? 0}
            </strong>
            &nbsp;of{" "}
            <strong>{totalData?.totalCount ? totalData?.totalCount : 0}</strong>{" "}
            {title}
          </Typography>
        </div>
        {totalItem > 0 ? (
          <div className={classes.dropdowndisplay}>
            <p>{title} Per Page : </p>
            {dashboard ? (
              <Select
                size="small"
                value={value}
                // page={page}
                className={styles.pageSelect}
                onChange={(e) => itemperpage(e)}
              >
                <MenuItem value={6}>6</MenuItem>
                <MenuItem value={12}>12</MenuItem>
              </Select>
            ) : (
              <Select
                size="small"
                value={value}
                defaultValue={10}
                id="demo-simple-select"
                className={styles.pageSelect}
                onChange={(e) => itemperpage(e)}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </Select>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
}
