import { useEffect } from "react";
import { useDispatch } from "react-redux";

import instance from "../Services/Axios";
import { getCookie, setCookie } from "../Utility/Cookies";
import {
  handleStoreToken,
  handleStoreadminData,
} from "../Store/Reducers/LoginReducer";
import { useToken } from "../Utility/StoreData";
import { ViewProfile } from "../Services/Services";
import { AdminData } from "../Utility/StoreData";
import { getCatchMsg } from "../Utility/GeneralUtils";

export default function Instance() {
  const adminDatas = AdminData();
  const dispatch = useDispatch();
  const token = useToken();

  const handleProfile = () => {
    ViewProfile()
      .then((res) => {
        if (res?.data?.status === 1) {
          dispatch(handleStoreadminData(res.data.data));
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      });
  };
  useEffect(() => {
    if (token) {
      handleProfile();
    }
  }, [token]);

  useEffect(() => {
    if (token == null) {
      handleCheckAdminData();
    }
  }, []);

  instance.interceptors.request.use(
    (request) => {
      return request;
    },
    (error) => Promise.reject(error)
  );

  instance.interceptors.response.use(
    (response) => {
      if (response?.data?.status === -1) {
        setCookie("mconnect_admin_data", "");
        // setCookie("mconnectLoginData", null);
        dispatch(handleStoreadminData(null));
        document.cookie =
          "mconnect_admin_data" + "=; expires=Thu, 01-Jan-70 00:00:01 GMT;";
        window.location.replace("/");
      }
      return response;
    },
    function (error) {
      getCatchMsg(error);
    }
  );

  const handleCheckAdminData = async () => {
    try {
      const cookieadminData = getCookie("mconnect_admin_data");

      let parsedData = JSON.parse(cookieadminData);
      if (cookieadminData) {
        dispatch(handleStoreadminData(parsedData));
        dispatch(handleStoreToken(parsedData?.access_token));
      }
    } catch (error) {}
  };
  return <></>;
}
