import React, { memo } from "react";
import { Grid } from "@mui/material";
import LineChart from "../../../Charts/LineChart";
import { MTTRProps } from "./report";

function MttrReportApp({ reportData }: { reportData: MTTRProps | null }) {
  return (
    <Grid className="px-5 py-4">
      {reportData && (
        <LineChart
          series={reportData?.data?.map((ele) => ele?.average) || []}
          labels={reportData?.data?.map((ele) => ele?.month) || []}
          lineValue={reportData?.line_value || 0}
        />
      )}
    </Grid>
  );
}

export default memo(MttrReportApp);
