import { CustomButton } from "../Components";
import { UpdateDeleteActionsProps } from "./Types";
import classes from "./CustomStyle.module.css";
function UpdateDeleteActions({
  onUpdatePress,
  onCancelPress,
}: UpdateDeleteActionsProps) {
  return (
    <>
      <div className="row my-3">
        <div className="col-lg-3 col-12">
          <CustomButton title="Update" onButtonPress={onUpdatePress} />
        </div>
        <div className={`col-lg-3 col-12 ${classes.cancelBtn}`}>
          <CustomButton
            title="Cancel"
            customButtonStyle={{
              backgroundColor: "rgba(0, 0, 0, 0.08)",
              color: "black",
            }}
            onButtonPress={onCancelPress}
          />
        </div>
      </div>
    </>
  );
}

export default UpdateDeleteActions;
