import React, { useEffect, useState } from "react";
import {
  CustomButton,
  CustomDropDown,
  Loader,
  TextInputBox,
} from "../Components";
import ModalHeader from "./ModalHeader";
import { AddModelProps } from "./Types";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  UpdateNewModel,
  createNewModel,
  getlistCategory,
  questionDropDown,
} from "../Services/Services";
import { getCatchMsg } from "../Utility/GeneralUtils";
import classes from "./Modal.module.css";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { openLoader } from "../Store/Reducers/LoaderSlice";

function AddModel({
  onClose,
  modalClose,
  listApiCall,
  editData,
  modelStatus,
}: AddModelProps) {
  const dispatch = useDispatch();
  const [question, setQuestion] = useState();
  const validation = Yup.object({
    modelName: Yup.string().required("* Model name is required"),
    questionId: Yup.string().required("* Question is required"),
  });
  const { values, handleChange, errors, touched, handleSubmit, setFieldValue } =
    useFormik({
      initialValues: {
        modelName: editData?.modelName ? editData?.modelName : "",
        subModelName: editData?.subModelName ? editData?.subModelName : "",
        id: editData?.id ? editData?.id : "",
        questionId: editData?.questionId ? editData?.questionId : "",
        questionName: editData?.questionMasterName
          ? editData?.questionMasterName
          : "",
      },
      validationSchema: validation,
      onSubmit: () => {
        if (editData) {
          handleUpdateModel(values);
        } else {
          handleAddModel(values);
        }
      },
    });

  const handleUpdateModel = (data: any) => {
    dispatch(openLoader(true));
    let formData = new FormData();
    formData.append("modelId", data?.id);
    formData.append("modelNumber", data.modelName);
    formData.append("subModelName", data?.subModelName);
    formData.append("QuestionId", data.questionId);
    UpdateNewModel(formData)
      .then((response) => {
        if (response?.data?.status === 1) {
          toast.success(response?.data?.msg);
          listApiCall("update");
          modalClose();
        } else if (response?.data?.status === 0) {
          toast.error(response?.data?.msg);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        dispatch(openLoader(false));
      });
  };

  const handleAddModel = (data: any) => {
    dispatch(openLoader(true));
    let formData = new FormData();
    formData.append("modelName", data.modelName);
    formData.append("subModelName", data?.subModelName);
    formData.append("question_master_id", data.questionId);
    createNewModel(formData)
      .then((response) => {
        if (response?.data?.status === 1) {
          toast.success(response?.data?.msg);
          listApiCall("add");
          modalClose();
        } else if (response?.data?.status === 0) {
          toast.error(response?.data?.msg);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        dispatch(openLoader(false));
      });
  };

  const handlegetQuestionlist = () => {
    questionDropDown()
      .then((res) => {
        if (res.data.status === 1) {
          setQuestion(res?.data?.data);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      });
  };
  useEffect(() => {
    handlegetQuestionlist();
  }, []);

  return (
    <div>
      <ModalHeader
        onClose={onClose}
        heading={`${editData ? "Edit" : "Add"} ${
          // modelStatus ? "Model" : "Part"
          "Model"
        }`}
      />
      <div className={`row ${classes.AddPartNumber}`}>
        <div className="col-12 mb-3">
          <TextInputBox
            requiredText={"*"}
            textLength={50}
            title="Model Name"
            placeHolder={"Enter model name"}
            name="modelName"
            value={values.modelName}
            onChangeText={handleChange("modelName")}
            errorText={
              touched.modelName && errors.modelName ? errors.modelName : ""
            }
          />
        </div>
        <div className="col-12 mb-3">
          <TextInputBox
            // requiredText={"*"}
            textLength={50}
            title="Sub Model Name"
            placeHolder={"Enter sub model name"}
            name="subModelName"
            value={values.subModelName}
            onChangeText={handleChange("subModelName")}
          />
        </div>
        <div className="col-12 mb-3">
          <CustomDropDown
            required={"*"}
            editName={values.questionName}
            title="Question"
            //@ts-ignore
            items={question}
            onSelectedItem={(val, value) => {
              setFieldValue("questionId", value?.id);
              setFieldValue("questionName", value?.label);
            }}
            fieldName="questionName"
            anotherFieldName="questionId"
            value={values.questionId}
            errorText={
              errors?.questionId && touched?.questionId
                ? errors?.questionId
                : ""
            }
          />
        </div>
        <div className="col-lg-6 my-4">
          <CustomButton
            title={editData ? "Update" : "Submit"}
            onButtonPress={handleSubmit}
          />
        </div>
        {editData ? (
          <div className="col-lg-6 my-4">
            <CustomButton
              title="Cancel"
              customButtonStyle={{
                backgroundColor: "rgba(0, 0, 0, 0.08)",
                color: "black",
              }}
              onButtonPress={() => modalClose()}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default AddModel;
