import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import classes from "./Accord.module.css";

export default function AccordionContent({
  children,
  filterStat,
  istab,
}: {
  children: JSX.Element;
  filterStat?: boolean;
  istab?: string | boolean;
}) {
  const [initialstate, setInitialState] = useState(false);
  useEffect(() => {
    setInitialState(false);

    return () => {
      setInitialState(false);
    };
  }, []);
  useEffect(() => {
    if (filterStat) {
      document.getElementById("accord")?.click();
      setInitialState(true);
    }
    if (initialstate && !filterStat) {
      document.getElementById("accord")?.click();
    }
  }, [filterStat, istab]);
  const fireCollapse = useCallback(() => {}, [filterStat]);

  return (
    <Accordion
      sx={{
        background: "transparent !important",
        boxShadow: "none !important",
        margin: "8px 0 !important",
        "& .MuiAccordionDetails-root": {
          padding: 0,
        },
        "&::before": {
          background: "transparent !important",
        },
      }}
    >
      <AccordionSummary
        style={{
          display: "none",
        }}
        id="accord"
      />
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
}
