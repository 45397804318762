import {
  Autocomplete,
  Box,
  Grid,
  Paper,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PageHeader from "../../MangementLayoutHeader/PageHeader";
import { TabContext, TabList } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import classes from "../Management/Management.module.css";
import { muiStyles } from "../../../Utility/Constants";
import MttrReportApp from "./MttrReportApp";
import { getCatchMsg, monthOrder } from "../../../Utility/GeneralUtils";
import MtbfReportApp from "./MtbfReportApp";
import { useFormik } from "formik";
import dayjs, { Dayjs } from "dayjs";
import { OrganizationDropdownProps } from "../Types";
import {
  getMtbfReportServices,
  getMttrReportServices,
  organizationDropDown,
} from "../../../Services/Services";
import { TextInputBox } from "../../../Components";
import managementStyles from "../Management/Management.module.css";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AccordionContent from "../../../Components/AccordionContent";
import { MTTRProps } from "./report";

export default function ReportApp() {
  const styles = muiStyles();
  const navigate = useNavigate();
  const defaultValues: {
    organization: OrganizationDropdownProps | null;
    year: Dayjs | null;
  } = {
    organization: null,
    year: dayjs(new Date()),
  };
  const { values, setFieldValue, handleChange, resetForm, handleSubmit } =
    useFormik({
      initialValues: defaultValues,
      onSubmit: () => {},
    });
  const [tabData, setTabData] = useState([
    {
      name: "MTTR Report",
      value: "0",
    },
    { name: "MTBF Report", value: "1" },
  ]);
  const [showFilters, setShowFilters] = useState(false);
  const [activeTab, setActiveTab] = useState("0");
  const [organizationList, setOrganizationList] = useState<
    OrganizationDropdownProps[]
  >([]);
  const [mtpfreportData, setmtpfReportData] = useState<MTTRProps | null>(null);
  const [reportData, setReportData] = useState<MTTRProps | null>(null);

  const handleTabChange = (e: any, value: string) => {
    setActiveTab(value);
  };

  const getOrganizations = () => {
    organizationDropDown()
      .then((res) => {
        if (res?.data?.status === 1) {
          setOrganizationList(res?.data?.data);
          if (res?.data?.data?.length > 0) {
            setFieldValue("organization", res?.data?.data[0]);
          }
          setFieldValue("year", dayjs(new Date()));
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      });
  };
  useEffect(() => {
    getOrganizations();
  }, []);

  // useEffect(() => {
  //   if (organizationList?.length > 0) {
  //     setFieldValue("organization", organizationList[0]);
  //   }
  //   setFieldValue("year", dayjs(new Date()));
  // }, [activeTab, organizationList]);

  const getmtpfReportData = (orgId: string, year: any) => {
    const formdata = new FormData();
    formdata.append("organizationId", orgId);
    formdata.append("year", year ? dayjs(year).get("year")?.toString() : "");
    getMtbfReportServices(formdata)
      .then((res) => {
        if (res?.data?.status) {
          setmtpfReportData(res?.data);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      }); /*  */
  };

  const getReportData = (orgId: string, year: any) => {
    const formdata = new FormData();
    formdata.append("organizationId", orgId);
    formdata.append("year", year ? dayjs(year).get("year")?.toString() : "");
    getMttrReportServices(formdata)
      .then((res) => {
        if (res?.data?.status) {
          setReportData(res?.data);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      });
  };

  useEffect(() => {
    if (values?.organization && values?.year) {
      if (activeTab === "1") {
        getmtpfReportData(values?.organization?.orgId.toString(), values?.year);
      } else if (activeTab === "0") {
        getReportData(values?.organization?.orgId.toString(), values?.year);
      }
    }
  }, [values, activeTab]);

  return (
    <Grid className="appcontainer">
      <Grid className="py-3">
        <PageHeader
          heading="Report"
          addbtn={false}
          onFilterbutton={() => {
            setShowFilters((pre) => !pre);
          }}
        />
        {/* {showFilters ? (
          
        ) : null} */}
        <AccordionContent filterStat={showFilters}>
          <Grid>
            <Grid className={managementStyles.serachContainer}>
              <Grid container spacing={2}>
                <Grid className="fieldBr8" item lg={2} md={4} sm={6} xs={12}>
                  <TextInputBox
                    title="Organization"
                    onChangeText={() => {}}
                    textOnly
                  />
                  <Autocomplete
                    // disableCloseOnSelect
                    options={organizationList}
                    size="small"
                    value={values.organization}
                    // className={styles.placeHolder}
                    onChange={(e, val) => {
                      setFieldValue("organization", val);
                    }}
                    getOptionLabel={(option) => option.orgName}
                    isOptionEqualToValue={(option, value) =>
                      option.orgId === value.orgId
                    }
                    // disableClearable={activeTab === "1"}
                    renderInput={(params) => {
                      return (
                        <TextField
                          placeholder={values?.organization ? "" : "none"}
                          {...params}
                        />
                      );
                    }}
                    PaperComponent={(props) => {
                      return (
                        <Paper {...props} className={styles.customSelect} />
                      );
                    }}
                  />
                </Grid>
                <Grid className="fieldBr8" item lg={2} md={4} sm={6} xs={12}>
                  <TextInputBox title="Year" onChangeText={() => {}} textOnly />
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      view="year"
                      views={["year"]}
                      onChange={(val) => {
                        setFieldValue("year", val);
                      }}
                      slotProps={{
                        textField: {
                          size: "small",
                        },
                      }}
                      value={values.year}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </AccordionContent>
        <Grid className="pt-3">
          <Grid className={classes.tabContainer}>
            <div className={classes.tableChange}>
              {tabData.map((ele) => {
                return (
                  <>
                    <TabContext value={activeTab}>
                      <Box>
                        <TabList
                          aria-label="lab API tabs example"
                          className={
                            ele.value === activeTab
                              ? `${styles.activeTab} ${styles.tabColor}`
                              : `${styles.tabColor}`
                          }
                        >
                          <Tab
                            label={ele.name}
                            value={ele.value}
                            disableRipple={true}
                            className={`${classes.tabContent} ${
                              ele.value === activeTab
                                ? `${classes.activeTab} activeTab`
                                : ""
                            }`}
                            onClick={(e) => {
                              setShowFilters(false);
                              if (organizationList?.length > 0) {
                                setFieldValue(
                                  "organization",
                                  organizationList[0]
                                );
                              }
                              setFieldValue("year", dayjs(new Date()));

                              handleTabChange(e, ele?.value);
                            }}
                          />
                        </TabList>
                      </Box>
                    </TabContext>
                  </>
                );
              })}
            </div>
          </Grid>

          {activeTab === "0" && <MttrReportApp reportData={reportData} />}
          {activeTab === "1" && <MtbfReportApp reportData={mtpfreportData} />}
        </Grid>
      </Grid>
    </Grid>
  );
}
