import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import * as Yup from "yup";
import { useFormik } from "formik";

import {
  getlistSupplier,
  getlistCategory,
  createBoardBatch,
  UpdateBoardBatch,
  listPartnumberdropdowm,
} from "../Services/Services";
import { getCatchMsg } from "../Utility/GeneralUtils";
import {
  Loader,
  TextInputBox,
  CustomButton,
  CustomDropDown,
} from "../Components/index";
import classes from "./Modal.module.css";
import ModalHeader from "./ModalHeader";
import { makeStyles } from "@material-ui/core";
import UpdateDeleteActions from "../Components/UpdateDeleteActions";
import { AddBoardBatchtype } from "./Types";
import { openLoader } from "../Store/Reducers/LoaderSlice";
import { useDispatch } from "react-redux";

const useStyle = makeStyles({
  Datepickercontainer: {
    "& .MuiInputBase": {
      paddingRight: "0px !important",
    },
  },
  ".css-nxo287-MuiInputBase-input-MuiOutlinedInput-input": {
    height: "10px !important",
  },
});

export default function AddBoardBatch({
  onClose,
  editData,
  listBoardBatchApicall,
  modalClose,
}: AddBoardBatchtype) {
  const dispatch = useDispatch();
  const validationSchema = Yup.object({
    batchName: Yup.string()
      .trim("Remove leading and trailing spaces")
      .strict(true),
    categoryName: Yup.string().required("* Category name is required"),
    quantity: Yup.number()
      .required("* Quantity is required")
      .min(1, "Quantity must be greater than or equal to 0")
      .max(200, "Quantity must be less than or equal to 200"),
    part_name:
      editData === null
        ? Yup.string().required("* Part number is required")
        : Yup.string(),
  });
  const style = useStyle();
  const { handleChange, values, setFieldValue, handleSubmit, errors, touched } =
    useFormik({
      initialValues: {
        boardBatchId: editData?.boardBatchId ? editData?.boardBatchId : "",
        batchName: editData?.batchName ? editData?.batchName : "",
        categoryId: editData?.categoryId ? editData?.categoryId : "",
        categoryName: editData?.categoryName ? editData?.categoryName : "",
        quantity: editData?.quantity ? editData?.quantity : "",
        pcbManufacturerId: editData?.pcbManufacturerId
          ? editData?.pcbManufacturerId
          : "",
        pcbManufacturerName: editData?.pcbManufacturerName
          ? editData?.pcbManufacturerName
          : "",
        pcbASsemblerId: editData?.pcbASsemblerId
          ? editData?.pcbASsemblerId
          : "",
        pcbASsemblerName: editData?.pcbASsemblerName
          ? editData?.pcbASsemblerName
          : "",
        pcbDesignerId: editData?.pcbDesignerId ? editData?.pcbDesignerId : "",
        pcbDesignerName: editData?.pcbDesignerName
          ? editData?.pcbDesignerName
          : "",
        hardwareDesignerId: editData?.hardwareDesignerId
          ? editData?.hardwareDesignerId
          : "",
        hardwareDesignerName: editData?.hardwareDesignerName
          ? editData?.hardwareDesignerName
          : "",
        serialNumberCharacter: "",
        part_name: editData?.partNumberName ? editData?.partNumberName : "",
        part_name_id: editData?.partNumberId ? editData?.partNumberId : "",
      },
      validationSchema: validationSchema,
      onSubmit: () => {
        if (editData) {
          handleUpdateboardbatch(values);
        } else {
          handleCreateboardbatch(values);
        }
      },
    });

  const [supplierlist, setSupplierlist] = useState();
  const [categorylist, setCategorylist] = useState();
  const [partNumberdropdownList, setpartNumberdropdownList] = useState();

  useEffect(() => {
    handlegetsupplierlist();
    handlegetcategorylist();
  }, []);

  const handlegetsupplierlist = () => {
    getlistSupplier()
      .then((res) => {
        if (res.data.status === 1) {
          setSupplierlist(res?.data?.data);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      });
  };
  const handlePartNumberList = (id: string) => {
    let formdata = new FormData();
    formdata.append("categoryTypeId", id);
    listPartnumberdropdowm(formdata)
      .then((res) => {
        if (res.data.status === 1) {
          setpartNumberdropdownList(res?.data?.data);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      });
  };
  const handlegetcategorylist = () => {
    getlistCategory()
      .then((res) => {
        if (res.data.status === 1) {
          setCategorylist(res?.data?.data);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      });
  };

  const handleCreateboardbatch = (data: any) => {
    dispatch(openLoader(true));
    let formData = new FormData();
    formData.append("batchName", data.batchName);
    formData.append("categoryId", data?.categoryId.toString());
    formData.append("quantity", data?.quantity.toString());
    formData.append("pcbManufacturerId", data?.pcbManufacturerId.toString());
    formData.append("pcbASsemblerId", data?.pcbASsemblerId.toString());
    formData.append("pcbDesignerId", data?.pcbDesignerId.toString());
    formData.append("hardwareDesignerId", data?.hardwareDesignerId.toString());
    formData.append("partNumberId", data?.part_name_id);
    formData.append(
      "serialNumberCharacter",
      data?.serialNumberCharacter.toString()
    );
    createBoardBatch(formData)
      .then((response) => {
        if (response?.data?.status === 1) {
          toast.success(response?.data?.msg);
          listBoardBatchApicall("add");
          modalClose();
        } else if (response?.data?.status === 0) {
          toast.error(response?.data?.msg);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        dispatch(openLoader(false));
      });
  };

  const handleUpdateboardbatch = (data: any) => {
    dispatch(openLoader(true));
    let formData = new FormData();
    formData.append("boardBatchId", data?.boardBatchId.toString());
    formData.append("batchName", data.batchName);
    formData.append("categoryId", data?.categoryId.toString());
    formData.append("quantity", data?.quantity.toString());
    formData.append("pcbManufacturerId", data?.pcbManufacturerId.toString());
    formData.append("pcbASsemblerId", data?.pcbASsemblerId.toString());
    formData.append("pcbDesignerId", data?.pcbDesignerId.toString());
    formData.append("hardwareDesignerId", data?.hardwareDesignerId.toString());
    formData.append(
      "serialNumberCharacter",
      data?.serialNumberCharacter.toString()
    );
    UpdateBoardBatch(formData)
      .then((response) => {
        if (response?.data?.status === 1) {
          toast.success(response?.data?.msg);
          listBoardBatchApicall("update");
          modalClose();
        } else if (response?.data?.status === 0) {
          toast.error(response?.data?.msg);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        dispatch(openLoader(false));
      });
  };

  return (
    <>
      <ModalHeader
        onClose={onClose}
        heading={`${editData === null ? "Add" : "Edit"} board batch`}
      />
      <div className={classes.add_device}>
        <div className="row">
          <div className="col-md-12 col-xl-6 form-group mb-3">
            <TextInputBox
              textLength={15}
              placeHolder="Batch name"
              title="Batch name"
              name="batchName"
              onChangeText={handleChange("batchName")}
              value={values.batchName}
            />
          </div>
          <div className="col-md-12 col-xl-6 form-group mb-3">
            <CustomDropDown
              disabled={editData ? true : false}
              required="*"
              editName={values.categoryName}
              title="Part Category"
              //@ts-ignore
              items={categorylist}
              onSelectedItem={(val, value) => {
                if (value?.id) {
                  handlePartNumberList(value?.id);
                }
                setFieldValue("part_name_id", "");
                setFieldValue("part_name", "");
                setFieldValue("categoryId", value?.id);
                setFieldValue("categoryName", value?.label);
              }}
              fieldName="categoryName"
              anotherFieldName="categoryId"
              value={values.categoryId}
              errorText={
                errors.categoryName && touched.categoryName
                  ? errors?.categoryName
                  : ""
              }
            />
          </div>
          {editData ? null : (
            <div className="col-md-12 col-xl-6 form-group mb-3">
              <CustomDropDown
                required="*"
                editName={values.part_name}
                title="Part Number"
                //@ts-ignore
                items={partNumberdropdownList}
                onSelectedItem={(val, value) => {
                  setFieldValue("part_name_id", value?.id);
                  setFieldValue("part_name", value?.label);
                }}
                fieldName="part_name"
                anotherFieldName="id"
                value={values.part_name}
                errorText={
                  errors?.part_name && touched?.part_name
                    ? errors?.part_name
                    : ""
                }
              />
            </div>
          )}
          <div className="col-md-12 col-xl-6 form-group mb-3">
            <TextInputBox
              requiredText="*"
              textLength={3}
              placeHolder="Quantity"
              title="Quantity"
              name="quantity"
              disabled={editData ? true : false}
              onChangeText={(text) => {
                const numericText = text.replace(/[^0-9]/g, "");
                handleChange("quantity")(numericText);
              }}
              value={values.quantity}
              errorText={
                touched.quantity && errors.quantity ? errors.quantity : ""
              }
              inputBoxStyle={{
                color: touched.quantity && errors.quantity ? "red" : "",
              }}
            />
          </div>
          <div className="col-md-12 col-xl-6 form-group mb-3">
            <CustomDropDown
              editName={values.pcbManufacturerName}
              title="Pcb Manufacture"
              //@ts-ignore
              items={supplierlist}
              onSelectedItem={(val, value) => {
                setFieldValue("pcbManufacturerId", value?.id);
                setFieldValue("pcbManufacturerName", value?.label);
              }}
              fieldName="supplierName"
              anotherFieldName="supplierId"
              value={values.pcbManufacturerName}
            />
          </div>

          <div className="col-md-12 col-xl-6 form-group mb-3">
            <CustomDropDown
              title="Pcb Designer"
              editName={values.pcbDesignerName}
              //@ts-ignore
              items={supplierlist}
              onSelectedItem={(val, value) => {
                setFieldValue("pcbDesignerId", value?.id);
                setFieldValue("pcbDesignerName", value?.label);
              }}
              fieldName="supplierName"
              anotherFieldName="supplierId"
              value={values.pcbDesignerName}
            />
          </div>
          <div className="col-md-12 col-xl-6 form-group mb-3">
            <CustomDropDown
              editName={values.pcbASsemblerName}
              title="Assembler"
              //@ts-ignore
              items={supplierlist}
              onSelectedItem={(val, value) => {
                setFieldValue("pcbASsemblerId", value?.id);
                setFieldValue("pcbASsemblerName", value?.label);
              }}
              fieldName="supplierName"
              anotherFieldName="supplierId"
              value={values.pcbASsemblerName}
            />
          </div>
          <div className="col-md-12 col-xl-6 form-group mb-3">
            <CustomDropDown
              editName={values.hardwareDesignerName}
              title="Hardware designer"
              //@ts-ignore
              items={supplierlist}
              onSelectedItem={(val, value) => {
                setFieldValue("hardwareDesignerId", value?.id);
                setFieldValue("hardwareDesignerName", value?.label);
              }}
              fieldName="supplierName"
              anotherFieldName="supplierId"
              value={values.hardwareDesignerId}
            />
          </div>
          {editData ? null : (
            <div className="col-md-12 col-xl-6 form-group mb-3">
              <TextInputBox
                textLength={5}
                placeHolder="Serial character"
                title="Serial character"
                name="serialNumberCharacter"
                onChangeText={(text) => {
                  const alphaText = text.replace(/[^A-Za-z]/g, "");
                  handleChange("serialNumberCharacter")(alphaText);
                }}
                value={values.serialNumberCharacter}
              />
            </div>
          )}
        </div>

        <div className={classes.modalbtn}>
          {editData ? (
            <UpdateDeleteActions
              onCancelPress={() => modalClose()}
              onUpdatePress={handleSubmit}
            />
          ) : (
            <div className="col-lg-3">
              <CustomButton title="Submit" onButtonPress={handleSubmit} />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
