import React from "react";
import { Dialog } from "@mui/material";
import { DialogContent } from "@material-ui/core";

import { GlobalModalProps } from "./Types";

export default function GlobalModal({
  isVisible,
  setIsVisible,
  children,
  size = "md",
  customStyle,
  modalStyle,
}: GlobalModalProps) {
  return isVisible ? (
    // <Modal
    //   show={isVisible}
    //   // @ts-ignore
    //   size={size}
    //   onHide={() => {
    //     if (setIsVisible) setIsVisible(false);
    //   }}
    //   centered={centered}
    //   style={customStyle}
    // >
    //   <Modal.Body style={customStyle}>{children}</Modal.Body>
    // </Modal>
    <Dialog
      sx={{
        "& .MuiDialog-paper": {
          // maxHeight: "400px",
          // overflowY: "none",
        },
      }}
      open={isVisible}
      // @ts-ignore
      maxWidth={size}
      onClose={() => {
        if (setIsVisible) setIsVisible(false);
      }}
      // style={customStyle}
      className={modalStyle || ""}
    >
      <DialogContent style={customStyle}>{children}</DialogContent>
    </Dialog>
  ) : null;
}
