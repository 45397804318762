import React, { useState } from "react";
import { CustomButton, Loader } from "../Components/index";
import confirm_delete from "../Assets/Icons/Svg/confirmDelete.svg";
import classes from "./Modal.module.css";
import ModalHeader from "./ModalHeader";
import { useDispatch } from "react-redux";

interface ConfirmationModalProps {
  msg: string;
  msg2?: string;
  msg3?: string;
  onClose?: () => void;
  onPositiveButtonPressed?: () => void;
  onNegativeButtonPressed?: () => void;
  positiveButtonText?: string;
  negativeButtonText?: string;
  deviceInactive?: boolean;
}
function ConfirmationModal({
  msg,
  onClose,
  onPositiveButtonPressed,
  onNegativeButtonPressed,
  positiveButtonText = "Delete",
  negativeButtonText = "Cancel",
  deviceInactive = false,
  msg2,
  msg3,
}: ConfirmationModalProps) {
  const dispatch = useDispatch();

  return (
    <>
      <ModalHeader
        onClose={() => {
          if (onClose) {
            onClose();
          }
        }}
      />
      <div className={classes.confirmModal}>
        <div onClick={onClose} className={classes.deleteIcon}>
          <img src={confirm_delete} alt="Delete" />
        </div>
        {deviceInactive ? (
          <div
            style={{
              textAlign: "center",
            }}
          >
            <p>
              {msg}{" "}
              <span
                style={{
                  fontFamily: "var(--fontSemibold)",
                }}
              >
                {msg2}
              </span>
            </p>

            <p>{msg3}</p>
          </div>
        ) : (
          <p className={classes.text}>{msg}</p>
        )}
        <div className={classes.confirm_Btns}>
          <CustomButton
            customButtonStyle={{
              padding: "10px 20px",
            }}
            onButtonPress={onPositiveButtonPressed}
            title={deviceInactive ? "Confirm" : positiveButtonText}
          />
          <CustomButton
            customButtonStyle={{
              padding: "10px 20px",
              backgroundColor: "rgba(0, 0, 0, 0.08)",
              color: "black",
            }}
            onButtonPress={onNegativeButtonPressed}
            title={negativeButtonText}
          />
        </div>
      </div>
    </>
  );
}

export default ConfirmationModal;
