import { useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";

import Profileimg from "../../../Assets/Images/Png/dummy.png";
import CloseIcon from "../../../Assets/Icons/Svg/close.svg";
import classes from "../UserProfile/AdminProfile.module.css";
import ImageCropModal from "../../../Components/ImageCropModal";
import { CustomButton, TextInputBox, Loader } from "../../../Components";
import uploadicon from "../../../Assets/Icons/Png/upload_icon.png";
import { EditProfile, ViewProfile } from "../../../Services/Services";
import { getCatchMsg } from "../../../Utility/GeneralUtils";
import { AdminData } from "../../../Utility/StoreData";
import { EMAIL_REGEX, INDIAN_MOBILE_REGEX } from "../../../Utility/Constants";
import { handleStoreadminData } from "../../../Store/Reducers/LoginReducer";
import { openLoader } from "../../../Store/Reducers/LoaderSlice";

interface setEditoptionType {
  setEditoption: (val: boolean) => void;
}
export type handleSaveDataType = {
  userName: string;
  Designation: any;
  mobile_no: string;
  email: string;
  Organization_name: string;
  alternative_mobile_no: string;
  image: string;
};

const validationSchema = Yup.object({
  userName: Yup.string().required("userName is required"),
  email: Yup.string()
    .matches(EMAIL_REGEX, "Invalid email address")
    .trim("Remove leading and trailing spaces")
    .strict(true),
  mobile_no: Yup.string()
    .matches(INDIAN_MOBILE_REGEX, "Enter valid mobile number")
    .trim("Remove leading and trailing spaces")
    .strict(true)
    .required("* Mobile number is required")
    .min(10, "Mobile number must have 10 digits")
    .max(10, "Mobile number must have 10 digits"),
  alternative_mobile_no: Yup.string()
    .matches(INDIAN_MOBILE_REGEX, "Enter valid mobile number")
    .min(10, "Alternative number must have 10 digits")
    .nullable()
    .trim("Remove leading and trailing spaces")
    .strict(true),
});
export default function Profile({ setEditoption }: setEditoptionType) {
  const AdminDatas = AdminData();
  const dispatch = useDispatch();
  const checkImages = ["png", "jpeg", "jpg"];
  const [cropImage, setCropImage] = useState<any>(null);
  const {
    handleChange,
    handleSubmit,
    errors,
    touched,
    values,
    setFieldValue,
    setFieldError,
    setFieldTouched,
  } = useFormik<handleSaveDataType>({
    initialValues: {
      userName: AdminDatas.name || "",
      Designation: AdminDatas.designation || "",
      mobile_no: AdminDatas.mobileNo || "",
      Organization_name: AdminDatas.OrganizationName || "",
      email: AdminDatas.email || "",
      alternative_mobile_no: AdminDatas.alternative_mobile_no || "",
      image: AdminDatas.image || "",
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      handleSave(values);
    },
  });
  const handleEditProfile = () => {
    ViewProfile()
      .then((res) => {
        if (res.data.status === 1) {
          dispatch(handleStoreadminData(res.data.data));
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      });
  };
  const handleSave = (data: handleSaveDataType) => {
    dispatch(openLoader(true));
    let formData = new FormData();
    formData.append("userName", data.userName);
    formData.append("alternative_mobile_no", data.alternative_mobile_no);
    formData.append("email", data.email);
    formData.append("mobile_no", data.mobile_no);
    if (data.image !== null && typeof data.image !== "string")
      formData.append("image", data.image);
    EditProfile(formData)
      .then((res) => {
        if (res.data.status === 1) {
          setEditoption(false);
          handleEditProfile();
          toast.success(res.data.msg);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        dispatch(openLoader(false));
      });
  };
  return (
    <>
      <div
        style={{
          width: "100%",
        }}
        className={classes.container}
      >
        <div className="container-fluid">
          <div className={classes.mainContainer}>
            <div className="row">
              <div className={`col-md-4 col-12 ${classes.leftcontainer}`}>
                <div className={classes.profileImg}>
                  <img
                    onError={({ currentTarget }) => {
                      currentTarget.src = Profileimg;
                    }}
                    src={
                      typeof values.image === "string"
                        ? values.image
                        : values.image && URL.createObjectURL(values.image)
                    }
                  ></img>
                </div>
                <div
                  className={`col-lg-10 col-md-12 col-12 ${classes.upload_image_container}`}
                >
                  <div
                    onClick={() =>
                      document.getElementById("file_open")?.click()
                    }
                    className={classes.upload_image}
                  >
                    <div className={classes.upload_icon}>
                      <input
                        accept="image/*"
                        type="file"
                        style={{ display: "none" }}
                        id="file_open"
                        name="image"
                        onChange={(event) => {
                          let data = event.target.files?.[0].name.split(".")[1];
                          if (
                            data &&
                            checkImages.includes(data?.toLocaleLowerCase())
                          ) {
                            if (event.target.files?.[0]) {
                              setCropImage(event.target.files?.[0]);
                            }
                          } else {
                            toast.error("Please select valid image format");
                          }
                        }}
                      />
                      <img src={uploadicon} alt="uploadImage_icon" />
                    </div>
                    <p>Upload Image</p>
                  </div>
                  <div>
                    <p className={classes.infotext}>
                      <span className="material-symbols-outlined">info</span>
                      png/jpg/jpeg
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-8 col-12">
                <div className={classes.closed_sec}>
                  <div className={classes.child}>
                    <img
                      src={CloseIcon}
                      style={{ cursor: "pointer", width: "15px" }}
                      onClick={() => setEditoption(false)}
                    ></img>
                  </div>
                </div>
                <div className="clearfix"></div>
                <div className="row">
                  <div
                    className={`col-lg-6 col-md-6 col-12  ${classes.child2}`}
                  >
                    <div className={`pb-2`}>
                      <TextInputBox
                        requiredText="*"
                        title="userName"
                        placeHolder="userName"
                        value={values.userName}
                        textLength={50}
                        onChangeText={handleChange("userName")}
                        errorText={
                          touched.userName && errors.userName
                            ? errors.userName
                            : ""
                        }
                        onBlur={() => {
                          try {
                            validationSchema.validateSyncAt(
                              "name",
                              values.userName
                            );
                          } catch (error) {
                            if (error instanceof Error) {
                              setFieldTouched("userName", true);
                              setFieldError("userName", error.message);
                            }
                          }
                        }}
                      ></TextInputBox>
                    </div>

                    <div className={`pb-2`}>
                      <TextInputBox
                        title="Designation"
                        placeHolder="Designation"
                        value={values.Designation}
                        textLength={50}
                        onChangeText={handleChange("Designation")}
                      ></TextInputBox>
                    </div>
                    <div className={`pb-2`}>
                      <TextInputBox
                        requiredText="*"
                        title="Mobile number"
                        placeHolder="Mobile number"
                        value={values.mobile_no}
                        onChangeText={handleChange("mobile_no")}
                        textLength={10}
                        errorText={
                          touched.mobile_no && errors.mobile_no
                            ? errors.mobile_no
                            : ""
                        }
                        onBlur={() => {
                          try {
                            validationSchema.validateSyncAt(
                              "mobile_no",
                              values.mobile_no
                            );
                          } catch (error) {
                            if (error instanceof Error) {
                              setFieldTouched("mobile_no", true);
                              setFieldError("mobile_no", error.message);
                            }
                          }
                        }}
                      ></TextInputBox>
                    </div>
                  </div>
                  <div className={`col-lg-6 col-md-6 col-12 ${classes.child2}`}>
                    <div className={`pb-2`}>
                      <TextInputBox
                        title="Organization name"
                        placeHolder="Organization name"
                        value={values.Organization_name}
                        textLength={50}
                        onChangeText={handleChange("Organization_name")}
                      ></TextInputBox>
                    </div>
                    <div className={`pb-2`}>
                      <TextInputBox
                        title="Email id"
                        placeHolder="Email Id"
                        value={values.email}
                        onChangeText={handleChange("email")}
                        textLength={50}
                        errorText={
                          touched.email && errors.email ? errors.email : ""
                        }
                      ></TextInputBox>
                    </div>

                    <div className={`pb-2`}>
                      <TextInputBox
                        title="Alternative number"
                        placeHolder="Alternative number"
                        value={values.alternative_mobile_no}
                        onChangeText={handleChange("alternative_mobile_no")}
                        textLength={10}
                        errorText={
                          touched.alternative_mobile_no &&
                          errors.alternative_mobile_no
                            ? errors.alternative_mobile_no
                            : ""
                        }
                      ></TextInputBox>
                    </div>
                  </div>
                  <div className="row">
                    <div className={`col-lg-2 mb-3 ${classes.savebtn}`}>
                      <CustomButton title="Save" onButtonPress={handleSubmit} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {cropImage && (
          // @ts-ignore
          <ImageCropModal
            setCropImage={setCropImage}
            src={URL.createObjectURL(cropImage)}
            setFieldValue={setFieldValue}
          />
        )}
      </div>
    </>
  );
}
