import React, { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import { useFormik } from "formik";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import toast from "react-hot-toast";
import TableRow from "@mui/material/TableRow";
import { Box } from "@mui/material";

import delete_icon from "../../Assets/Icons/Svg/delete.svg";
import view from "../../Assets/Icons/Png/view.png";
import edit from "../../Assets/Icons/Svg/edit.svg";
import Inbox from "../../Assets/Images/Png/noData.png";
import PageHeader from "../MangementLayoutHeader/PageHeader";
import classes from "./Management/Management.module.css";
import {
  CustomPaginationwithdropdownpagination,
  GlobalModal,
  Loader,
  NodataLoader,
  TableRowsLoader,
  TextInputBox,
} from "../../Components";
import {
  SupplierShowProps,
  SuppliersListProps,
} from "./Management/managementTypes";
import AddSuppliers from "../../Modals/AddSuppliers";
import { getCatchMsg, isFormDirty } from "../../Utility/GeneralUtils";
import {
  deleteSupplier,
  listSuppliers,
  organizanitionHistoryReport,
} from "../../Services/Services";
import { useToken } from "../../Utility/StoreData";
import { ConfirmationModal } from "../../Modals";
import SearchAndClearbutton from "../../Components/SearchAndClearbutton";
import { Grid, Tab } from "@material-ui/core";
import { openLoader } from "../../Store/Reducers/LoaderSlice";
import { useDispatch } from "react-redux";
import AccordionContent from "../../Components/AccordionContent";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import {
  SubScriptionHistory,
  UpgradeSubScription,
  muiStyles,
} from "../../Utility/Constants";
import { TabContext, TabList } from "@mui/lab";

function OrganizationHistory() {
  const token = useToken();
  const styles = muiStyles();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [noDataLoader, setnoDataLoader] = useState(false);
  const navigate = useNavigate();
  const [showFilterOption, setShowFilterOption] = useState(false);
  const [suppliersList, setsuppliersList] = useState<SuppliersListProps>();
  const [isShowModal, setIsShowModal] = useState<SupplierShowProps>({
    status: false,
    data: null,
    viewstatus: false,
  });
  const [tableHead, setTableHead] = useState(SubScriptionHistory);
  const QuestionSubHeading = [
    {
      id: 27,
      name: SubScriptionHistory,
      pathname: "active",
    },
    {
      id: 28,
      name: UpgradeSubScription,
      pathname: "previos",
    },
  ];
  const [deleteModal, setdeleteModal] = useState({
    modal: false,
    id: "",
  });
  const { values, handleChange, resetForm, initialValues } = useFormik({
    initialValues: {
      name: "",
      organizationName: "",
      mobile_no: "",
      gstNo: "",
    },
    onSubmit: () => {},
  });

  const handleTableSearch = (data: string) => {
    if (data === "Enter") {
      setPage(1);
      handleGetHistoryList();
    }
  };

  const handleTableSearchClear = () => {
    setPage(1);
    resetForm();
    handleGetHistoryList(undefined, undefined, true);
  };

  const handleGetHistoryList = (
    page = suppliersList?.data?.page === undefined
      ? 1
      : suppliersList?.data?.page,
    size = 10,
    resetValue?: boolean
  ) => {
    let formData = new FormData();
    setnoDataLoader(true);
    formData.append("org_id", state.org_id);
    if (tableHead === UpgradeSubScription) {
      formData.append("is_upgrade", "1");
    }
    organizanitionHistoryReport(page, size, formData)
      .then((response) => {
        setPage(response?.data?.data?.page);
        if (response?.data?.status === 1) {
          setTotalCount(response?.data?.data?.totalCount);

          setsuppliersList(response?.data);
          //   handleActiveStatus(response?.data?.data?.items);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        setnoDataLoader(false);
      });
  };
  const handleClick = (param: string) => {
    setShowFilterOption(false);
    resetForm();
    setTableHead(param);
  };
  const handleDeleteSupplier = () => {
    let formData = new FormData();
    dispatch(openLoader(true));
    formData.append("supplierId", deleteModal.id);
    deleteSupplier(formData)
      .then((response) => {
        if (response?.data?.status === 1) {
          if (totalCount % 10 === 1) {
            handleGetHistoryList(page - 1);
          } else {
            handleGetHistoryList(page);
          }
          toast.success(response?.data?.msg);
        }
      })
      .catch((err) => getCatchMsg(err))
      .finally(() => {
        dispatch(openLoader(false));
        setdeleteModal((prev) => {
          return {
            ...prev,
            modal: false,
          };
        });
      });
  };

  useEffect(() => {
    if (token) {
      handleGetHistoryList();
    }
  }, [token, tableHead]);

  return (
    <>
      <div className={classes.suppliers}>
        <GlobalModal
          isVisible={deleteModal.modal}
          setIsVisible={() => {
            setdeleteModal((prev) => {
              return {
                ...prev,
                modal: false,
              };
            });
          }}
        >
          <ConfirmationModal
            msg={
              "Are you sure that you want to permanently delete this supplier"
            }
            onClose={() => {
              setdeleteModal((prev) => {
                return {
                  ...prev,
                  modal: false,
                };
              });
            }}
            onPositiveButtonPressed={handleDeleteSupplier}
            onNegativeButtonPressed={() => {
              setdeleteModal((prev) => {
                return {
                  ...prev,
                  modal: false,
                };
              });
            }}
          />
        </GlobalModal>

        <Grid className={classes.tabContainer}>
          <div className={classes.tableChange}>
            {QuestionSubHeading.map((ele) => {
              return (
                <>
                  <TabContext value={tableHead}>
                    <Box>
                      <TabList
                        aria-label="lab API tabs example"
                        className={
                          ele.name === tableHead
                            ? `${styles.activeTab} ${styles.tabColor}`
                            : `${styles.tabColor}`
                        }
                      >
                        <Tab
                          label={ele.name}
                          value={ele.name}
                          disableRipple={true}
                          className={`${classes.tabContent} ${
                            ele.name === tableHead
                              ? `${classes.activeTab} activeTab`
                              : ""
                          }`}
                          onClick={() => {
                            handleClick(ele?.name);
                          }}
                        />
                      </TabList>
                    </Box>
                  </TabContext>
                </>
              );
            })}
          </div>
        </Grid>
        <PageHeader
          heading={
            tableHead === SubScriptionHistory
              ? "SubScription History"
              : "Upgrade Subscription Request"
          }
          Btntitle="Back to Organization"
          //   addbtn={false}
          BtnTrue={true}
          modal={() => {
            navigate("/organization");
          }}
          onFilterbutton={() => {
            setShowFilterOption((pre) => !pre);
            //
          }}
        />
        {/* {showFilterOption ? (
         
        ) : null} */}
        <AccordionContent filterStat={showFilterOption}>
          <div className={classes.serachContainer}>
            <div className="row mb-3">
              <Grid item lg={2} md={4} sm={6} xs={12}>
                <TextInputBox
                  title="Name"
                  customInputProps={{
                    maxLength: 50,
                    onKeyDown: (e) => handleTableSearch(e.key),
                  }}
                  name="name"
                  placeHolder="Search name"
                  value={values.name}
                  onChangeText={handleChange("name")}
                />
              </Grid>
              <Grid item lg={2} md={4} sm={6} xs={12}>
                <TextInputBox
                  title="Organization Name"
                  customInputProps={{
                    maxLength: 50,
                    onKeyDown: (e) => handleTableSearch(e.key),
                  }}
                  name="organizationName"
                  placeHolder="Search organization name"
                  value={values.organizationName}
                  onChangeText={handleChange("organizationName")}
                />
              </Grid>
              <Grid item lg={2} md={4} sm={6} xs={12}>
                <TextInputBox
                  title="Mobile Number"
                  customInputProps={{
                    maxLength: 50,
                    onKeyDown: (e) => handleTableSearch(e.key),
                  }}
                  name="mobile_no"
                  placeHolder="Search mobile number"
                  value={values.mobile_no}
                  onChangeText={(text) => {
                    const numericText = text.replace(/[^0-9]/g, "");
                    handleChange("mobile_no")(numericText);
                  }}
                />
              </Grid>
              <Grid item lg={2} md={4} sm={6} xs={12}>
                <TextInputBox
                  title="GST Number"
                  customInputProps={{
                    maxLength: 50,
                    onKeyDown: (e) => handleTableSearch(e.key),
                  }}
                  name="gstNo"
                  value={values.gstNo}
                  placeHolder="Search GST number"
                  onChangeText={handleChange("gstNo")}
                />
              </Grid>
            </div>
            <SearchAndClearbutton
              handleTableSearch={handleTableSearch}
              handleTableSearchClear={handleTableSearchClear}
              disabled={!isFormDirty(initialValues, values)}
            />
          </div>
        </AccordionContent>

        <div className={`table-responsive ${classes.AllTable}`}>
          <Box>
            <TableContainer>
              {tableHead === SubScriptionHistory ? (
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell>S.No</TableCell>
                      <TableCell>Subscription Name</TableCell>
                      <TableCell>Subscription Validity(In Month)</TableCell>

                      <TableCell>Start Date</TableCell>
                      <TableCell>End Date</TableCell>
                      <TableCell>Machine Count</TableCell>
                      <TableCell>Cost</TableCell>
                      <TableCell>Meter Id</TableCell>
                      <TableCell>Action Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {suppliersList?.data?.items?.length === 0 ? (
                      <>
                        {noDataLoader ? (
                          <TableRow>
                            <TableCell className={classes.noData} colSpan={9}>
                              <NodataLoader />
                            </TableCell>
                          </TableRow>
                        ) : (
                          <TableRow>
                            <TableCell colSpan={9}>
                              <div className={classes.nodatafound}>
                                <img
                                  src={Inbox}
                                  alt="inbox"
                                  width={100}
                                  height={100}
                                />
                                <p>No data found</p>
                              </div>
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    ) : (
                      <>
                        {noDataLoader ? (
                          <TableRowsLoader columnNum={6} rowsNum={10} />
                        ) : suppliersList?.data?.items?.length === 0 ||
                          !suppliersList ? (
                          <TableRow>
                            <TableCell colSpan={9}>
                              <div className={classes.nodatafound}>
                                <img
                                  src={Inbox}
                                  alt="inbox"
                                  width={100}
                                  height={100}
                                />
                                <p>No data found</p>
                              </div>
                            </TableCell>
                          </TableRow>
                        ) : (
                          suppliersList?.data?.items?.map(
                            (device: any, index: number) => (
                              <TableRow key={index}>
                                <TableCell>
                                  {(suppliersList?.data?.page - 1) *
                                    suppliersList?.data?.size +
                                    index +
                                    1}
                                </TableCell>
                                <TableCell>
                                  {device?.subscription_name === null
                                    ? "-"
                                    : device?.subscription_name}
                                </TableCell>
                                <TableCell>
                                  {device?.subscription_validity === null
                                    ? "-"
                                    : `${device?.subscription_validity} Month`}
                                </TableCell>

                                <TableCell>
                                  {device?.start_at === null
                                    ? "-"
                                    : moment(device?.start_at).format(
                                        "YYYY-MM-DD"
                                      )}
                                </TableCell>
                                <TableCell>
                                  {device?.end_at === null
                                    ? "-"
                                    : moment(device?.end_at).format(
                                        "YYYY-MM-DD"
                                      )}
                                </TableCell>

                                <TableCell>
                                  {device?.machineCount === null
                                    ? "-"
                                    : device?.machineCount}
                                </TableCell>
                                <TableCell>
                                  {device?.cost === null ? "-" : device?.cost}
                                </TableCell>
                                <TableCell>
                                  {device?.meter_id === null
                                    ? "-"
                                    : device?.meter_id}
                                </TableCell>
                                <TableCell>
                                  {device?.action_taken === 1 ? (
                                    <p style={{ color: "green" }}>Accept</p>
                                  ) : (
                                    <p style={{ color: "red" }}>Decline</p>
                                  )}
                                </TableCell>
                              </TableRow>
                            )
                          )
                        )}
                      </>
                    )}
                  </TableBody>
                </Table>
              ) : (
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell>S.No</TableCell>
                      <TableCell>Accepted By</TableCell>
                      <TableCell>Reason</TableCell>
                      <TableCell>Cost</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Action Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {suppliersList?.data?.items?.length === 0 ? (
                      <>
                        {noDataLoader ? (
                          <TableRow>
                            <TableCell className={classes.noData} colSpan={9}>
                              <NodataLoader />
                            </TableCell>
                          </TableRow>
                        ) : (
                          <TableRow>
                            <TableCell colSpan={9}>
                              <div className={classes.nodatafound}>
                                <img
                                  src={Inbox}
                                  alt="inbox"
                                  width={100}
                                  height={100}
                                />
                                <p>No data found</p>
                              </div>
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    ) : (
                      <>
                        {noDataLoader ? (
                          <TableRowsLoader columnNum={6} rowsNum={10} />
                        ) : suppliersList?.data?.items?.length === 0 ||
                          !suppliersList ? (
                          <TableRow>
                            <TableCell colSpan={9}>
                              <div className={classes.nodatafound}>
                                <img
                                  src={Inbox}
                                  alt="inbox"
                                  width={100}
                                  height={100}
                                />
                                <p>No data found</p>
                              </div>
                            </TableCell>
                          </TableRow>
                        ) : (
                          suppliersList?.data?.items?.map(
                            (device: any, index: number) => (
                              <TableRow key={index}>
                                <TableCell>
                                  {(suppliersList?.data?.page - 1) *
                                    suppliersList?.data?.size +
                                    index +
                                    1}
                                </TableCell>
                                <TableCell>
                                  {device?.accepted_by === null
                                    ? "-"
                                    : device?.accepted_by}
                                </TableCell>

                                <TableCell>
                                  {device?.comment === null
                                    ? "-"
                                    : device?.comment}
                                </TableCell>
                                <TableCell>
                                  {device?.cost === null ? "-" : device?.cost}
                                </TableCell>
                                <TableCell>
                                  {device?.date === null
                                    ? "-"
                                    : moment(device?.date).format("DD-MM-YYYY")}
                                </TableCell>

                                <TableCell>
                                  {device?.action_taken === 1 ? (
                                    <p style={{ color: "green" }}>Accept</p>
                                  ) : (
                                    <p style={{ color: "red" }}>Decline</p>
                                  )}
                                </TableCell>
                              </TableRow>
                            )
                          )
                        )}
                      </>
                    )}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
          </Box>
        </div>
      </div>
      {suppliersList?.data?.items?.length ? (
        <CustomPaginationwithdropdownpagination
          onChangePage={(page: number) => {
            handleGetHistoryList(page, pageSize);
            setPage(page);
          }}
          onChangePagerow={(PageSize: number) => {
            if (page * PageSize > suppliersList?.data?.totalCount) {
              handleGetHistoryList(1, PageSize);
              setPageSize(PageSize);
              return;
            }
            handleGetHistoryList(page, PageSize);
            setPageSize(PageSize);
          }}
          count={suppliersList?.data?.totalCount}
          pages={page}
          pageCount={suppliersList?.data?.total_page}
          handleFunction={(selected: number) => {
            setPage(selected);
            handleGetHistoryList(selected, pageSize);
          }}
          labelRowsPerPage={"Devices Per Page"}
          rowsperpage={pageSize}
          totalData={suppliersList?.data}
        />
      ) : null}
    </>
  );
}

export default OrganizationHistory;
