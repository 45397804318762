import { createContext, Dispatch, SetStateAction, useContext } from "react";
import { isShowModalFirmWareProps } from "../Views/HomeScreens/Management/managementTypes";
import { isShowAssignFirmWareModalProps } from "../Views/HomeScreens/Types";
import { filterStatusProps } from "../Views/HomeScreens/Firmware/FirmwareLayout";

export type FirmwareContextProps = {
  isShowModal: isShowModalFirmWareProps;
  setIsShowModal: Dispatch<SetStateAction<isShowModalFirmWareProps>>;
  isShowAssignFirmWareModal: isShowAssignFirmWareModalProps;
  setisShowAssignFirmWareModal: Dispatch<
    SetStateAction<isShowAssignFirmWareModalProps>
  >;
  filterStatus: filterStatusProps;
  setfilterStatus: Dispatch<SetStateAction<filterStatusProps>>;
  setdownloadBtnStatus: Dispatch<SetStateAction<boolean>>;
};
export const FirmwareContext = createContext<FirmwareContextProps | undefined>(
  undefined
);
export const useFirmwareContext = () => {
  return useContext(FirmwareContext) as FirmwareContextProps;
};
