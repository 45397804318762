import { useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import uploadicon from "../Assets/Icons/Png/upload_icon.png";
import { CustomButton, TextInputBox } from "../Components";
import classes from "./Modal.module.css";
import { EMAIL_REGEX, INDIAN_MOBILE_REGEX } from "../Utility/Constants";
import { AdminData } from "../Utility/StoreData";
import { EditProfile, ViewProfile } from "../Services/Services";
import { handleStoreadminData } from "../Store/Reducers/LoginReducer";
import { getCatchMsg } from "../Utility/GeneralUtils";
import { openLoader } from "../Store/Reducers/LoaderSlice";
import Profileimg from "../Assets/Icons/Png/man.png";
import CloseIcon from "../Assets/Icons/Svg/close.svg";
import ImageCropModal from "../Components/ImageCropModal";
import { Button, Grid } from "@mui/material";
import ModalHeader from "./ModalHeader";
import {
  InfoOutlined,
  UpdateOutlined,
  UploadFileOutlined,
  UploadOutlined,
} from "@mui/icons-material";

interface setEditoptionType {
  setEditoption: (val: boolean) => void;
}
export type handleSaveDataType = {
  userName: string;
  Designation: any;
  mobile_no: string;
  email: string;
  Organization_name: string;
  alternative_mobile_no: string;
  image: string;
};

const validationSchema = Yup.object({
  userName: Yup.string().required("userName is required"),
  email: Yup.string()
    .matches(EMAIL_REGEX, "Invalid email address")
    .trim("Remove leading and trailing spaces")
    .strict(true),
  mobile_no: Yup.string()
    .matches(INDIAN_MOBILE_REGEX, "Enter valid mobile number")
    .trim("Remove leading and trailing spaces")
    .strict(true)
    .required("* Mobile number is required")
    .min(10, "Mobile number must have 10 digits")
    .max(10, "Mobile number must have 10 digits"),
  alternative_mobile_no: Yup.string()
    .matches(INDIAN_MOBILE_REGEX, "Enter valid mobile number")
    .min(10, "Alternative number must have 10 digits")
    .nullable()
    .trim("Remove leading and trailing spaces")
    .strict(true),
});
export default function EditMyProfile({ setEditoption }: setEditoptionType) {
  const AdminDatas = AdminData();
  const dispatch = useDispatch();
  const checkImages = ["png", "jpeg", "jpg"];
  const [cropImage, setCropImage] = useState<any>(null);
  const {
    handleChange,
    handleSubmit,
    errors,
    touched,
    values,
    setFieldValue,
    setFieldError,
    setFieldTouched,
  } = useFormik<handleSaveDataType>({
    initialValues: {
      userName: AdminDatas.name || "",
      Designation: AdminDatas.designation || "",
      mobile_no: AdminDatas.mobileNo || "",
      Organization_name: AdminDatas.OrganizationName || "",
      email: AdminDatas.email || "",
      alternative_mobile_no: AdminDatas.alternative_mobile_no || "",
      image: AdminDatas.image || "",
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      handleSave(values);
    },
  });
  const handleEditProfile = () => {
    ViewProfile()
      .then((res) => {
        if (res.data.status === 1) {
          dispatch(handleStoreadminData(res.data.data));
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      });
  };
  const handleSave = (data: handleSaveDataType) => {
    dispatch(openLoader(true));
    let formData = new FormData();
    formData.append("userName", data.userName);
    formData.append("alternative_mobile_no", data.alternative_mobile_no);
    formData.append("email", data.email);
    formData.append("mobile_no", data.mobile_no);
    if (data.image !== null && typeof data.image !== "string")
      formData.append("image", data.image);
    EditProfile(formData)
      .then((res) => {
        if (res.data.status === 1) {
          setEditoption(false);
          handleEditProfile();
          toast.success(res.data.msg);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        dispatch(openLoader(false));
      });
  };
  return (
    <Grid
      style={{
        width: "100%",
      }}
      className={classes.container}
    >
      <ModalHeader
        heading={"Edit Profile"}
        onClose={() => {
          setEditoption(false);
        }}
      />
      <Grid className="row px-4">
        <Grid className="row">
          <Grid className={`col-lg-6 col-md-6 col-12  ${classes.child2}`}>
            <Grid className={`pb-2`}>
              <TextInputBox
                requiredText="*"
                title="userName"
                placeHolder="userName"
                value={values.userName}
                textLength={50}
                onChangeText={handleChange("userName")}
                errorText={
                  touched.userName && errors.userName ? errors.userName : ""
                }
                onBlur={() => {
                  try {
                    validationSchema.validateSyncAt("name", values.userName);
                  } catch (error) {
                    if (error instanceof Error) {
                      setFieldTouched("userName", true);
                      setFieldError("userName", error.message);
                    }
                  }
                }}
              />
            </Grid>

            <Grid className={`pb-2`}>
              <TextInputBox
                title="Designation"
                placeHolder="Designation"
                value={values.Designation}
                textLength={50}
                onChangeText={handleChange("Designation")}
              />
            </Grid>
            <Grid className={`pb-2`}>
              <TextInputBox
                requiredText="*"
                title="Mobile number"
                placeHolder="Mobile number"
                value={values.mobile_no}
                onChangeText={handleChange("mobile_no")}
                textLength={10}
                errorText={
                  touched.mobile_no && errors.mobile_no ? errors.mobile_no : ""
                }
                onBlur={() => {
                  try {
                    validationSchema.validateSyncAt(
                      "mobile_no",
                      values.mobile_no
                    );
                  } catch (error) {
                    if (error instanceof Error) {
                      setFieldTouched("mobile_no", true);
                      setFieldError("mobile_no", error.message);
                    }
                  }
                }}
              />
            </Grid>
          </Grid>
          <Grid className={`col-lg-6 col-md-6 col-12 ${classes.child2}`}>
            <Grid className={`pb-2`}>
              <TextInputBox
                title="Organization name"
                placeHolder="Organization name"
                value={values.Organization_name}
                textLength={50}
                onChangeText={handleChange("Organization_name")}
              />
            </Grid>
            <Grid className={`pb-2`}>
              <TextInputBox
                title="Email id"
                placeHolder="Email Id"
                value={values.email}
                onChangeText={handleChange("email")}
                textLength={50}
                errorText={touched.email && errors.email ? errors.email : ""}
              />
            </Grid>

            <Grid className={`pb-2`}>
              <TextInputBox
                title="Alternative number"
                placeHolder="Alternative number"
                value={values.alternative_mobile_no}
                onChangeText={handleChange("alternative_mobile_no")}
                textLength={10}
                errorText={
                  touched.alternative_mobile_no && errors.alternative_mobile_no
                    ? errors.alternative_mobile_no
                    : ""
                }
              />
            </Grid>
          </Grid>
          <Grid marginBottom={2} display={"flex"} alignItems={"center"}>
            <Grid marginRight={2} className={classes.profileImg}>
              <img
                style={{
                  width: 100,
                  maxWidth: "100%",
                }}
                onError={({ currentTarget }) => {
                  currentTarget.src = Profileimg;
                }}
                src={
                  typeof values.image === "string"
                    ? values.image
                    : values.image && URL.createObjectURL(values.image)
                }
              />
            </Grid>
            <Grid>
              <Grid
                onClick={() => document.getElementById("file_open")?.click()}
                className={classes.upload_image}
              >
                <input
                  accept="image/*"
                  type="file"
                  style={{ display: "none" }}
                  id="file_open"
                  name="image"
                  onChange={(event) => {
                    let data = event.target.files?.[0].name.split(".")[1];
                    if (
                      data &&
                      checkImages.includes(data?.toLocaleLowerCase())
                    ) {
                      if (event.target.files?.[0]) {
                        setCropImage(event.target.files?.[0]);
                      }
                    } else {
                      toast.error("Please select valid image format");
                    }
                  }}
                />

                <Button
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor: "var(--btnClr)",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "var(--btnClr)",
                      color: "white",
                    },
                  }}
                >
                  <UploadOutlined /> Upload Image
                </Button>
              </Grid>
              <Grid>
                <p
                  style={{
                    fontSize: 13,
                  }}
                >
                  <InfoOutlined
                    style={{
                      width: 18,
                      marginRight: 5,
                    }}
                  />
                  png/jpg/jpeg
                </p>
              </Grid>
            </Grid>
          </Grid>
          <Grid className="row">
            <Grid className={`col-lg-2 mb-3 ${classes.savebtn}`}>
              <CustomButton title="Save" onButtonPress={handleSubmit} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {cropImage && (
        // @ts-ignore
        <ImageCropModal
          setCropImage={setCropImage}
          src={URL.createObjectURL(cropImage)}
          setFieldValue={setFieldValue}
        />
      )}
    </Grid>
  );
}
