import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  CustomButton,
  CustomPaginationwithdropdownpagination,
  GlobalModal,
  TableRowsLoader,
} from "../../Components";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import {
  deleteServiceRequestServices,
  getserviceTaskListService,
} from "../../Services/Services";
import { getCatchMsg } from "../../Utility/GeneralUtils";
import {
  ServiceTaskDataProps,
  ServiceTaskProps,
  ShowTaskProps,
  showHistoryProps,
} from "./Types";
import managementStyles from "../HomeScreens/Management/Management.module.css";
import edit from "../../Assets/Icons/Svg/edit.svg";
import delete_icon from "../../Assets/Icons/Svg/delete.svg";
import view from "../../Assets/Icons/Png/view.png";
import reloadIcon from "../../Assets/Icons/Svg/reload.svg";
import Inbox from "../../Assets/Images/Png/noData.png";
import toast from "react-hot-toast";
import { ConfirmationModal } from "../../Modals";
import AddServiceTaskModal from "../../Modals/AddServiceTaskModal";

export default function ServiceRequestTaskApp({
  org_id,
  viewStatus,
}: {
  org_id: string;
  viewStatus: boolean;
}) {
  const navigate = useNavigate();
  const [noDataLoader, setNoDataLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [serviceTaskList, setServiceTaskList] =
    useState<ServiceTaskProps | null>(null);
  const [deleteModal, setdeleteModal] = useState({
    status: false,
    id: "",
  });

  const [showAddTask, setShowAddTask] = useState<ShowTaskProps>({
    status: false,
    view: false,
    data: null,
  });

  const getServiceTaskList = (page = 1, size = 10) => {
    const formdata = new FormData();
    formdata.append("serviceRequestId", org_id);
    getserviceTaskListService(page, size, formdata)
      .then((res) => {
        if (res?.data?.status) {
          setServiceTaskList(res?.data?.data);
          setPage(res?.data?.data?.page || 1);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      });
  };

  const deleteServiceRequestTask = () => {
    // const formdata = new FormData();
    // formdata.append("serviceRequestId", deleteModal.id);
    // deleteServiceRequestServices(formdata)
    //   .then((res) => {
    //     if (res?.data?.status) {
    //       toast.success(res?.data?.msg);
    //       setdeleteModal({
    //         status: false,
    //         id: "",
    //       });
    //       if (
    //         serviceTaskList &&
    //         (serviceTaskList?.items?.length - 1) % 10 === 0
    //       ) {
    //         getServiceTaskList(page - 1, pageSize);
    //       } else {
    //         getServiceTaskList(page, pageSize);
    //       }
    //     } else {
    //       toast.error(res?.data?.msg);
    //     }
    //   })
    //   .catch((err) => {
    //     getCatchMsg(err);
    //   });
  };

  useEffect(() => {
    getServiceTaskList();
  }, []);
  return (
    <Grid>
      {showAddTask?.status && (
        <GlobalModal
          modalStyle={
            showAddTask.view ? "logoutmodalminWidth" : "globalModallg"
          }
          isVisible={showAddTask?.status}
          setIsVisible={() => {
            setShowAddTask((pre) => ({
              ...pre,
              status: false,
              view: false,
            }));
          }}
        >
          <AddServiceTaskModal
            editdata={showAddTask.data}
            view={showAddTask.view}
            onClose={() => {
              setShowAddTask((pre) => ({
                ...pre,
                status: false,
                view: false,
              }));
            }}
            listapi={(value) => {
              if (value === "update") {
                getServiceTaskList(page);
                return;
              }
              getServiceTaskList();
            }}
            seviceId={org_id}
          />
        </GlobalModal>
      )}
      {deleteModal.status && (
        <GlobalModal
          isVisible={deleteModal.status}
          setIsVisible={() => {
            setdeleteModal((prev) => {
              return {
                ...prev,
                status: false,
              };
            });
          }}
        >
          <ConfirmationModal
            msg={
              "Are you sure that you want to permanently delete this service task"
            }
            onClose={() => {
              setdeleteModal((prev) => {
                return {
                  ...prev,
                  status: false,
                };
              });
            }}
            onPositiveButtonPressed={deleteServiceRequestTask}
            onNegativeButtonPressed={() => {
              setdeleteModal((prev) => {
                return {
                  ...prev,
                  status: false,
                };
              });
            }}
          />
        </GlobalModal>
      )}
      {!viewStatus && (
        <Grid className="px-3">
          <CustomButton
            title="Add Service Task"
            customButtonStyle={{
              backgroundColor: "#262626",
              fontSize: "13px",
              fontWeight: "500",
              padding: "10px 15px",
              boxShadow: "0px 1.89921px 3.79843px rgba(0, 0, 0, 0.06)",
              maxWidth: "150px",
              display: "block",
              marginLeft: "auto",
            }}
            onButtonPress={() => {
              setShowAddTask({
                data: null,
                status: true,
                view: false,
              });
            }}
          />
        </Grid>
      )}
      <Grid className={`table-responsive py-2 px-3`}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>S.No</TableCell>
                <TableCell>Start Date</TableCell>
                <TableCell>End Date</TableCell>
                <TableCell>Device Status Before</TableCell>
                <TableCell>Device Status After</TableCell>
                <TableCell>Total Hours</TableCell>
                <TableCell>Technician</TableCell>

                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <>
                {noDataLoader ? (
                  <TableRowsLoader columnNum={8} rowsNum={10} />
                ) : serviceTaskList?.items?.length === 0 || !serviceTaskList ? (
                  <TableRow>
                    <TableCell colSpan={8}>
                      <Grid className={managementStyles.nodatafound}>
                        <img src={Inbox} alt="inbox" width={100} height={100} />
                        <p>No data found</p>
                      </Grid>
                    </TableCell>
                  </TableRow>
                ) : (
                  serviceTaskList?.items?.map(
                    (task: ServiceTaskDataProps, index: number) => (
                      <TableRow key={index}>
                        <TableCell>
                          {(serviceTaskList?.page - 1) * serviceTaskList?.size +
                            index +
                            1}
                        </TableCell>

                        <TableCell>
                          {task.startDatetime
                            ? moment(task?.startDatetime).format(
                                "YYYY-MM-DD hh:mm A"
                              )
                            : "-"}
                        </TableCell>
                        <TableCell>
                          {task.endDatetime
                            ? moment(task?.endDatetime).format(
                                "YYYY-MM-DD hh:mm A"
                              )
                            : "-"}
                        </TableCell>
                        <TableCell>
                          {task?.deviceStatusBefore
                            ? task?.deviceStatusBefore
                            : "-"}
                        </TableCell>
                        <TableCell>
                          {task?.deviceStatusAfter
                            ? task?.deviceStatusAfter
                            : "-"}
                        </TableCell>

                        <TableCell>
                          {task?.totalDuration ? task?.totalDuration : "-"}
                        </TableCell>
                        <TableCell>
                          {task?.technicianName ? task?.technicianName : "-"}
                        </TableCell>

                        <TableCell>
                          <Grid className={managementStyles.icons}>
                            <Tooltip title="View" placement="top">
                              <img
                                onClick={() => {
                                  setShowAddTask({
                                    data: task,
                                    status: true,
                                    view: true,
                                  });
                                }}
                                src={view}
                                alt="View"
                              />
                            </Tooltip>

                            {!viewStatus && (
                              <Tooltip title="Edit" placement="top">
                                <img
                                  onClick={() => {
                                    setShowAddTask({
                                      data: task,
                                      status: true,
                                      view: false,
                                    });
                                  }}
                                  src={edit}
                                  alt="Edit"
                                />
                              </Tooltip>
                            )}

                            {!viewStatus && (
                              <Tooltip title="Delete" placement="top">
                                <img
                                  onClick={() => {
                                    setdeleteModal((pre) => ({
                                      ...pre,
                                      status: true,
                                      id: task.taskId?.toString(),
                                    }));
                                  }}
                                  src={delete_icon}
                                  alt="Delete"
                                />
                              </Tooltip>
                            )}
                          </Grid>
                        </TableCell>
                      </TableRow>
                    )
                  )
                )}
              </>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      {serviceTaskList?.items?.length ? (
        <CustomPaginationwithdropdownpagination
          onChangePage={(page: number) => {
            getServiceTaskList(page, pageSize);
            setPage(page);
          }}
          onChangePagerow={(PageSize: number) => {
            if (page * PageSize > serviceTaskList?.total_count) {
              getServiceTaskList(1, PageSize);
              setPageSize(PageSize);
              return;
            }
            getServiceTaskList(page, PageSize);
            setPageSize(PageSize);
          }}
          count={serviceTaskList?.total_count}
          pages={page}
          pageCount={serviceTaskList?.total_page}
          handleFunction={(selected: number) => {
            setPage(selected);
            getServiceTaskList(selected, pageSize);
          }}
          labelRowsPerPage={"Tasks Per Page"}
          rowsperpage={pageSize}
          totalData={{
            totalCount: serviceTaskList.total_count,
            size: serviceTaskList.size,
            page: serviceTaskList.page,
          }}
        />
      ) : null}
    </Grid>
  );
}
