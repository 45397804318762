import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  CustomDropDown,
  CustomPaginationwithdropdownpagination,
  GlobalModal,
} from "../../../Components";
import AddProjectModal from "../../../Modals/AddProjectModal";
import {
  deleteProjectManagement,
  getlistCategory,
  getprojectmanagementlist,
  projectdropdownlist,
} from "../../../Services/Services";
import PageHeader from "../../MangementLayoutHeader/PageHeader";
import classes from "./Management.module.css";
import {
  setdeleteServerModalType,
  setIsShowServerModalType,
} from "./managementTypes";
import delete_icon from "../../../Assets/Icons/Svg/delete.svg";
import view from "../../../Assets/Icons/Png/view.png";
// import Inbox from "../../../Assets/Images/Png/noData.png";
import edit from "../../../Assets/Icons/Svg/edit.svg";
import { ConfirmationModal } from "../../../Modals";
import toast from "react-hot-toast";
import { useFormik } from "formik";
import { getCatchMsg } from "../../../Utility/GeneralUtils";
import SearchAndClearbutton from "../../../Components/SearchAndClearbutton";
import AccordionContent from "../../../Components/AccordionContent";
function Projectmanage() {
  const navigate = useNavigate();
  const [showFilterOption, setShowFilterOption] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState<number>(1);
  const [categoryList, setCategoryList] = useState();
  const [isShowModal, setIsShowModal] = useState<setIsShowServerModalType>({
    status: false,
    data: null,
    viewid: "",
    viewstatus: false,
  });
  const [deleteModal, setdeleteModal] = useState({
    modal: false,
    id: "",
  });
  const { values, resetForm, handleChange, setFieldValue } = useFormik({
    initialValues: {
      categoryId: "",
      categoryName: "",
    },
    onSubmit: () => {},
  });
  const [projectmanageList, setProjectManageList] = useState([]);
  const handleGetProjectManageList = (
    page = 1,
    size = 10,
    resetValue?: boolean
  ) => {
    let formData = new FormData();
    formData.append("projectId", values.categoryId ? values.categoryId : "");
    getprojectmanagementlist(page, size, formData)
      .then((res) => {
        setProjectManageList(res?.data?.data);
        setPage(res?.data?.data?.page || 1);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    handleGetProjectManageList();
  }, [values.categoryId]);
  const handleDeleteProject = () => {
    let formData = new FormData();
    formData.append("projectId", deleteModal.id);
    deleteProjectManagement(formData)
      .then((res) => {
        handleGetProjectManageList();
        toast.success(res.data.msg);
        setdeleteModal((prev) => {
          return {
            ...prev,
            modal: false,
          };
        });
      })
      .catch((err) => {});
  };
  const handleTableSearch = (data: string) => {
    if (data === "Enter") {
      setPage(1);

      handleGetProjectManageList();
    }
  };
  const handleTableSearchClear = () => {
    setPage(1);
    resetForm();

    handleGetProjectManageList(undefined, undefined, true);
  };
  const handleGetListCategory = () => {
    projectdropdownlist()
      .then((response) => {
        if (response?.data?.status === 1) {
          setCategoryList(response?.data?.data);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      });
  };
  useEffect(() => {
    handleGetListCategory();
  }, []);
  return (
    <>
      {/* <PageHeader
        serachIconStatus={false}
        modal={() => navigate(-1)}
        Btntitle="Back"
        heading="Project Management "
      /> */}
      <PageHeader
        modal={() => {
          setIsShowModal((prev) => {
            return {
              ...prev,
              status: true,
              data: null,
              viewstatus: false,
            };
          });
        }}
        Btntitle="Add Project"
        heading="Project Management "
        onFilterbutton={() => {
          setShowFilterOption((pre) => !pre);
        }}
      />
      {/* @ts-ignore */}
      <GlobalModal
        size="lg"
        modalStyle="globalModalmaxWidth"
        isVisible={isShowModal.status}
        setIsVisible={() => {
          setIsShowModal((prev) => {
            return {
              ...prev,
              status: true,
            };
          });
        }}
      >
        <AddProjectModal
          onClose={() => {
            setIsShowModal((prev) => {
              return {
                ...prev,
                status: false,
              };
            });
          }}
          // @ts-ignore
          editData={isShowModal.data}
          listServerApicall={(value) => {
            if (value === "update") {
              handleGetProjectManageList(page);
              return;
            }
            handleGetProjectManageList();
          }}
          modalClose={() => {
            setIsShowModal((prev) => {
              return {
                ...prev,
                status: false,
              };
            });
          }}
          viewStatus={isShowModal.viewstatus}
        />
      </GlobalModal>
      <GlobalModal
        isVisible={deleteModal.modal}
        setIsVisible={() => {
          setdeleteModal((prev) => {
            return {
              ...prev,
              modal: false,
            };
          });
        }}
      >
        <ConfirmationModal
          msg={"Are you sure that you want to permanently delete this Project"}
          onClose={() => {
            setdeleteModal((prev) => {
              return {
                ...prev,
                modal: false,
              };
            });
          }}
          onPositiveButtonPressed={handleDeleteProject}
          onNegativeButtonPressed={() => {
            setdeleteModal((prev) => {
              return {
                ...prev,
                modal: false,
              };
            });
          }}
        />
      </GlobalModal>
      {/* {showFilterOption ? (
      
      ) : null} */}
      <AccordionContent filterStat={showFilterOption}>
        <div className={classes.serachContainer}>
          <div className="row mb-3">
            <Grid item lg={2} md={4} sm={6} xs={12}>
              <CustomDropDown
                title="Project Name"
                editName={
                  values.categoryName ? values.categoryName : "--None--"
                }
                items={categoryList}
                onSelectedItem={(val, value) => {
                  // handleListOfPartNumbersList(
                  //   page,
                  //   pageSize,
                  //   false,
                  //   value?.id
                  // );
                  setFieldValue(
                    "categoryName",
                    value?.label ? value?.label : ""
                  );
                  setFieldValue("categoryId", value?.id ? value?.id : "");
                }}
                fieldName="name"
                anotherFieldName="id"
                value={values.categoryId}
                disableClearable={!values.categoryId}
              />
            </Grid>
          </div>
          {/* <SearchAndClearbutton
            handleTableSearch={handleTableSearch}
            handleTableSearchClear={handleTableSearchClear}
          /> */}
        </div>
      </AccordionContent>
      <div className={`table-responsive ${classes.AllTable}`}>
        <Box>
          <TableContainer>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>S.No</TableCell>
                  <TableCell>Project Name</TableCell>
                  <TableCell>Project Number</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* @ts-ignore */}
                {projectmanageList?.items?.map((ele: any, index) => {
                  return (
                    <TableRow>
                      <TableCell>
                        {" "}
                        {/* @ts-ignore */}
                        {(projectmanageList?.page - 1) *
                          // @ts-ignore
                          projectmanageList?.size +
                          // @ts-ignore
                          index +
                          1}
                      </TableCell>
                      <TableCell>{ele.projectName}</TableCell>
                      <TableCell>
                        {ele?.projectNumber ? ele?.projectNumber : "-"}
                      </TableCell>

                      <TableCell>
                        <div className={classes.icons}>
                          <Tooltip title="View" placement="top">
                            <img
                              src={view}
                              alt="View"
                              onClick={() =>
                                setIsShowModal((prev: any) => {
                                  return {
                                    ...prev,
                                    status: true,
                                    data: ele,
                                    viewstatus: true,
                                  };
                                })
                              }
                            />
                          </Tooltip>
                          <Tooltip title="Edit" placement="top">
                            <img
                              src={edit}
                              onClick={() => {
                                setIsShowModal({
                                  status: true,
                                  data: ele,
                                  viewstatus: false,
                                });
                              }}
                              alt="Edit"
                            />
                          </Tooltip>
                          <Tooltip title="Delete" placement="top">
                            <img
                              src={delete_icon}
                              alt="Delete"
                              onClick={() =>
                                setdeleteModal(
                                  (
                                    prev: setdeleteServerModalType
                                  ): setdeleteServerModalType => {
                                    return {
                                      ...prev,
                                      modal: true,
                                      id: ele.id.toString(),
                                    };
                                  }
                                )
                              }
                            />
                          </Tooltip>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </div>
      <CustomPaginationwithdropdownpagination
        onChangePage={(page: number) => {
          handleGetProjectManageList(page, pageSize);
          setPage(page);
        }}
        onChangePagerow={(PageSize: number) => {
          // @ts-ignore
          if (page * PageSize > projectmanageList?.totalCount) {
            handleGetProjectManageList(1, PageSize);
            setPageSize(PageSize);
            return;
          }
          handleGetProjectManageList(page, PageSize);
          setPageSize(PageSize);
        }}
        // @ts-ignore
        count={projectmanageList?.totalCount}
        pages={page}
        // @ts-ignore
        pageCount={projectmanageList?.total_page}
        handleFunction={(selected: number) => {
          setPage(selected);
          handleGetProjectManageList(selected, pageSize);
        }}
        labelRowsPerPage={"Devices Per Page"}
        rowsperpage={pageSize}
        // @ts-ignore
        totalData={projectmanageList}
      />
    </>
  );
}

export default Projectmanage;
