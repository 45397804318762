import {
  AuthPrivateRoute,
  DashboardPrivateRoute,
} from "../Views/AuthScreens/PrivateRoute";
import DashboardLayout from "../Layout/DashboardLayout/index";
import ProfileLayout from "../Views/HomeScreens/UserProfile/Profile";
import Dashboard from "../Views/HomeScreens/Dashboard";
import ManagementLayout from "../Layout/ManagementLayout";
import Organization from "../Views/HomeScreens/Organization";
import OrganizationDetail from "../Views/HomeScreens/OrganizationDetail";
import ErrorElement from "../Error/ErrorElement";
import { ManagementRoute } from "../Routing/ManagementRoute";
import { ProfileRoute } from "../Routing/ProfileRoute";
import { AuthRoute } from "../Routing/AuthRoute";
import Suppliers from "../Views/HomeScreens/Suppliers";
import ServiceHistoryApp from "../Views/HomeScreens/ServiceHistoryApp";
import ServiceRequest from "../Views/HomeScreens/ServiceRequest";
import CreateServiceRequest from "../Views/HomeScreens/CreateServiceRequest";
import ReportApp from "../Views/HomeScreens/Reports/ReportApp";
import OrganizationHistory from "../Views/HomeScreens/History";
import UpgradeSubscription from "../Views/HomeScreens/UpgradeSubscription";

export const Indexroute = [
  {
    element: <AuthPrivateRoute />,
    children: AuthRoute,
  },
  {
    element: <DashboardPrivateRoute />,
    children: [
      {
        path: "organization",
        element: <DashboardLayout />,
        children: [
          {
            index: true,
            element: <Organization serverid={0} />,
          },
          {
            path: "organization_detail_list",
            element: <OrganizationDetail />,
          },
          {
            path: "organization_history",
            element: <OrganizationHistory />,
          },
          {
            path: "upgrade_subscription",
            element: <UpgradeSubscription />,
          },
        ],
      },
      // {
      //   path: "service_history",
      //   element: <DashboardLayout />,
      //   children: [
      //     {
      //       index: true,
      //       element: <ServiceHistoryApp />,
      //     },
      //   ],
      // },
      {
        path: "service_request",
        element: <DashboardLayout />,
        children: [
          {
            index: true,
            element: <ServiceRequest />,
          },
          {
            path: "create_service_request",
            element: <CreateServiceRequest />,
          },
        ],
      },
      {
        path: "report",
        element: <DashboardLayout />,
        children: [
          {
            index: true,
            element: <ReportApp />,
          },
        ],
      },
      {
        path: "/dashboard",
        element: <DashboardLayout />,
        children: [
          {
            index: true,
            element: <Dashboard />,
          },
          {
            path: "management",
            element: <ManagementLayout />,
            children: ManagementRoute,
          },
        ],
      },
      {
        path: "/suppliers",
        element: <DashboardLayout />,
        children: [
          {
            index: true,
            element: <Suppliers />,
          },
        ],
      },
      {
        path: "/dashboard",
        element: <DashboardLayout />,
        children: [
          {
            index: true,
            element: <ProfileLayout />,
          },
          {
            path: "profile",
            children: ProfileRoute,
          },
        ],
      },
    ],
  },
  {
    path: "*",
    element: <ErrorElement />,
  },
];
