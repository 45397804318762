import React, { useEffect, useState } from "react";
import ModalHeader from "./ModalHeader";
import classes from "./Modal.module.css";
import { CustomButton, CustomDropDown, TextInputBox } from "../Components";
import { useFormik } from "formik";
import * as Yup from "yup";
import { createproject, updateSubscription } from "./Types";
import {
  addProjectManagement,
  deviceSubscriptionDropdown,
  projectdropdownlist,
  subscriptionResponse,
  updateProjectManagement,
  updateSubscriptionRequest,
} from "../Services/Services";
import toast from "react-hot-toast";
import UpdateDeleteActions from "../Components/UpdateDeleteActions";
import { getCatchMsg } from "../Utility/GeneralUtils";
import { Autocomplete, TextField } from "@mui/material";
const validationSchema = Yup.object({
  subscriptionDuration: Yup.string().required(
    "* Subscription Duration is required"
  ),
  subscriptionCost: Yup.string().required("* Subscription Cost required"),
  MachineCount: Yup.string().required("* Machine Count required"),
  // MeterId: Yup.string().required("*MeterId required"),
});
function ModifySubscriptionModal({
  onClose,
  modalClose,
  listServerApicall,
  editData,
  viewStatus,
}: updateSubscription) {
  const {
    handleChange,
    values,
    setFieldValue,
    handleSubmit,
    setFieldTouched,
    setFieldError,
    errors,
    touched,
    handleBlur,
    resetForm,
    setValues,
  } = useFormik({
    initialValues: {
      // @ts-ignore

      subscriptionName: editData?.subscriptionName
        ? editData?.subscriptionName
        : "",
      subscriptionId: editData?.subscription_id
        ? editData?.subscription_id
        : "",
      subscriptionDuration: editData?.subscriptionDuration
        ? editData?.subscriptionDuration
        : "",
      subscriptionCost: editData?.subscriptionCost
        ? editData?.subscriptionCost
        : "",
      MachineCount: editData?.machine_count ? editData?.machine_count : "",
      MeterId: editData?.meter_id ? editData?.meter_id : "",
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      handleUpdateSubscripton();
      // handleSubScriptionResponse();
    },
  });
  const [subScriptionList, setSubscriptionList] = useState([]);
  const [noDataLoader, setnoDataLoader] = useState(false);
  const [updateSubscriptionStatus, setUpdateSubscriptionStatus] =
    useState(false);
  const handleDeviceSubscriptonDropdown = () => {
    let formData = new FormData();
    formData.append(
      "subscriptionId",
      editData?.organizationSubscriptionID
        ? editData?.organizationSubscriptionID
        : editData?.project_id
    );
    if (!editData?.organizationSubscriptionID) {
      formData.append("is_project", "1");
    }
    deviceSubscriptionDropdown(formData)
      .then((res) => {
        if (res.data.status === 1) {
          setSubscriptionList(res?.data?.data);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      });
  };
  const handleUpdateSubscripton = () => {
    let formData = new FormData();
    formData.append("requestId", editData?.req_id);
    formData.append("subscription_id", values.subscriptionId);
    formData.append("subscriptionName", values.subscriptionName);
    formData.append("subscriptionDuration", values.subscriptionDuration);
    formData.append("subscriptionCost", values.subscriptionCost);
    formData.append("machine_count", values.MachineCount);
    formData.append(
      "total_price",
      // @ts-ignore
      values.subscriptionCost * values.MachineCount
    );
    if (
      values.MachineCount !== editData.machine_count ||
      values.subscriptionCost !== editData?.subscriptionCost ||
      values.subscriptionDuration !== editData?.subscriptionDuration ||
      values.subscriptionId !== editData?.subscription_id ||
      values.subscriptionName !== editData?.subscriptionName
    ) {
      formData.append("is_change", "1");
    }
    updateSubscriptionRequest(formData)
      .then((res) => {
        if (res.data.status === 1) {
          toast.success(res.data.msg);
          // handleSubScriptionResponse();
          listServerApicall();
          onClose();
        } else {
          toast.error(res.data.msg);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      });
  };
  const handleSubScriptionResponse = () => {
    setnoDataLoader(true);
    let formData = new FormData();
    formData.append("req_id", editData?.req_id);
    formData.append("action_status", "1");
    subscriptionResponse(formData)
      .then((res) => {
        if (res.data.status === 1) {
          toast.success(res.data.msg);
          listServerApicall();
        } else {
          toast.error(res.data.msg);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        setnoDataLoader(false);
      });
  };
  useEffect(() => {
    if (editData) {
      handleDeviceSubscriptonDropdown();
    }
  }, [editData]);
  useEffect(() => {
    if (!updateSubscriptionStatus) {
      resetForm();
    }
  }, [updateSubscriptionStatus]);
  return (
    <>
      {/* @ts-ignore */}
      <ModalHeader
        onClose={onClose}
        heading={`${editData?.organization_name} (${editData?.organization_code})`}
      />

      <div className={classes.add_device}>
        <div className="row">
          <div className="col-md-12  col-lg-6 form-group mb-3">
            <CustomDropDown
              title="Subscription Name"
              required={"*"}
              editName={values.subscriptionName}
              disabled={updateSubscriptionStatus ? false : true}
              //@ts-ignore
              items={subScriptionList}
              onSelectedItem={(val, value) => {
                // if (value?.id) {
                //   handlePartNumberList(value?.id);
                // }
                // setFieldValue("subscriptionName", value.label);
                // setFieldValue("subscriptionId", value?.id);
                setValues((prev) => {
                  return {
                    ...prev,
                    subscriptionName: value.label,
                    subscriptionId: value.id,
                    subscriptionCost: value.cost,
                    subscriptionDuration: value.duration,
                  };
                });
                // setFieldValue("categoryId", value?.id);
                // setFieldValue("categoryName", value?.label);
              }}
              fieldName="subscriptionName"
              anotherFieldName="subscriptionID"
              cost="cost"
              duration="duration"
              value={values.subscriptionId}
              errorText={
                errors.subscriptionName && touched.subscriptionName
                  ? errors?.subscriptionName
                  : ""
              }
            />
          </div>
          <div className="col-md-12  col-lg-6 form-group mb-3">
            <TextInputBox
              title="Subscription Duration"
              placeHolder="Enter Subscription Duration "
              textLength={10}
              disabled={updateSubscriptionStatus ? false : true}
              value={values.subscriptionDuration}
              onChangeText={(e) => {
                const numericValue = e?.replace(/[^0-9]/g, "");
                handleChange("subscriptionDuration")(numericValue);
              }}
              errorText={
                errors.subscriptionDuration ? errors.subscriptionDuration : ""
              }
            />
          </div>

          <div className="col-md-12  col-lg-6 form-group mb-3">
            <TextInputBox
              title="Subscription Cost"
              value={values.subscriptionCost}
              textLength={10}
              disabled={updateSubscriptionStatus ? false : true}
              placeHolder="Enter Subscription Cost"
              // onChangeText={handleChange(
              //   "subscriptionCost"
              // )}
              onChangeText={(e) => {
                const numericValue = e?.replace(/[^0-9]/g, "");
                handleChange("subscriptionCost")(numericValue);
              }}
              errorText={errors.subscriptionCost ? errors.subscriptionCost : ""}
            />
          </div>
          <div className="col-md-12  col-lg-6 form-group mb-3">
            <TextInputBox
              title="Machine Count"
              textLength={10}
              disabled={updateSubscriptionStatus ? false : true}
              value={values.MachineCount}
              placeholder="Enter Machine Count"
              onChangeText={(e) => {
                const numericValue = e?.replace(/[^0-9]/g, "");
                handleChange("MachineCount")(numericValue);
              }}
              // onChangeText={handleChange("MachineCount")}
              errorText={errors.MachineCount ? errors.MachineCount : ""}
            />
          </div>
          <div className="col-md-12  col-lg-6 form-group mb-3">
            <TextInputBox
              title="Meter Id"
              value={values.MeterId}
              disabled={true}
              onChangeText={handleChange("MeterId")}
              placeHolder="MeterId"
              // errorText={
              //   errors.MeterId ? errors.MeterId : ""
              // }
            />
          </div>
          <h6>Total Amount:{values.subscriptionCost * values.MachineCount}</h6>
          <>
            <div className={classes.modalbtn}>
              <div className={classes.subscriptonUpdate}>
                <div className={`col-lg-3 ${classes.buttonMargin}`}>
                  <CustomButton
                    customButtonStyle={{
                      backgroundColor: "gray",
                    }}
                    title={!updateSubscriptionStatus ? "Update" : "Cancel"}
                    onButtonPress={() => {
                      setUpdateSubscriptionStatus(!updateSubscriptionStatus);
                    }}
                  />
                </div>
                <div className="col-lg-3">
                  <CustomButton title="Accept" onButtonPress={handleSubmit} />
                </div>
              </div>
            </div>
          </>
        </div>
      </div>
    </>
  );
}

export default ModifySubscriptionModal;
