import React, { useState } from "react";
import ModalHeader from "./ModalHeader";
import { CustomButton, Loader, TextInputBox } from "../Components";
import { useFormik } from "formik";
import classes from "./Modal.module.css";
import { Typography } from "@material-ui/core";
import { AddSuppliersModalTypes } from "./Types";
import toast from "react-hot-toast";
import { getCatchMsg } from "../Utility/GeneralUtils";
import { createSupplier, updateSupplier } from "../Services/Services";
import * as Yup from "yup";
import {
  ALPHA_NUM,
  EMAIL_REGEX,
  INDIAN_MOBILE_REGEX,
} from "../Utility/Constants";
import UpdateDeleteActions from "../Components/UpdateDeleteActions";
import { useDispatch } from "react-redux";
import { openLoader } from "../Store/Reducers/LoaderSlice";

const validationSchema = Yup.object({
  name: Yup.string()
    .required("* Name is required")
    .matches(ALPHA_NUM, "Enter valid name")
    .trim("Remove leading and trailing spaces")
    .strict(true),
  mobile_no: Yup.string()
    .required("* Mobile number is required")
    .matches(INDIAN_MOBILE_REGEX, "Enter valid mobile number")
    .trim("Remove leading and trailing spaces")
    .strict(true),
  alternative_no: Yup.string()
    .nullable()
    .matches(INDIAN_MOBILE_REGEX, "Enter valid mobile number")
    .trim("Remove leading and trailing spaces")
    .strict(true),
  organizationName: Yup.string()
    .nullable()
    .required("* Organization name is required")
    .trim("Remove leading and trailing spaces")
    .strict(true),
  email: Yup.string()
    .nullable()
    .matches(EMAIL_REGEX, "Enter valid email")
    .trim("Remove leading and trailing spaces")
    .strict(true),
  gstNo: Yup.string()
    .matches(ALPHA_NUM, "Enter valid GST number")
    .trim("Remove leading and trailing spaces")
    .nullable()
    .strict(true),
});

function AddSuppliers({
  onClose,
  viewstatus,
  modalClose,
  listSuppliersApicall,
  editData,
}: AddSuppliersModalTypes) {
  const dispatch = useDispatch();
  const { values, handleChange, handleSubmit, handleBlur, errors, touched } =
    useFormik({
      initialValues: {
        supplierId: editData?.supplierId ? editData?.supplierId : "",
        name: editData?.name ? editData?.name : "",
        mobile_no: editData?.mobile_no ? editData?.mobile_no : "",
        organizationName: editData?.organizationName
          ? editData?.organizationName
          : "",
        alternative_no: editData?.alternative_no
          ? editData?.alternative_no
          : "",
        email: editData?.email ? editData?.email : "",
        address: editData?.address ? editData?.address : "",
        gstNo: editData?.gstNo ? editData?.gstNo : "",
      },
      validationSchema: validationSchema,
      onSubmit: () => {
        if (editData === null) {
          handleAddSupplier();
        } else {
          handleUpdateSupplier();
        }
      },
    });

  const handleAddSupplier = () => {
    dispatch(openLoader(true));
    let formData = new FormData();
    formData.append("name", values.name);
    formData.append("mobile_no", values.mobile_no);
    formData.append("organizationName", values.organizationName);
    formData.append("alternative_no", values.alternative_no);
    formData.append("email", values.email);
    formData.append("address", values.address);
    formData.append("gstNo", values.gstNo);
    createSupplier(formData)
      .then((response) => {
        if (response.data.status === 1) {
          listSuppliersApicall("add");
          modalClose();
          toast.success(response.data.msg);
        } else {
          toast.error(response.data.msg);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        dispatch(openLoader(false));
      });
  };

  const handleUpdateSupplier = () => {
    dispatch(openLoader(true));
    let formData = new FormData();
    formData.append("supplierId", values.supplierId.toString());
    formData.append("name", values.name);
    formData.append("mobile_no", values.mobile_no);
    formData.append("organizationName", values.organizationName);
    formData.append("alternative_no", values.alternative_no);
    formData.append("email", values.email);
    formData.append("address", values.address);
    formData.append("gstNo", values.gstNo);
    updateSupplier(formData)
      .then((response) => {
        if (response.data.status === 1) {
          listSuppliersApicall("update");
          modalClose();
          toast.success(response.data.msg);
        } else {
          toast.error(response.data.msg);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        dispatch(openLoader(false));
      });
  };

  return (
    <>
      <ModalHeader
        onClose={onClose}
        heading={
          viewstatus
            ? "View Suppliers"
            : editData === null
            ? "Add Suppliers"
            : "Edit Suppliers"
        }
      />
      <div className={`px-md-4 px-1 ${classes.vieworg}`}>
        {viewstatus ? (
          <div className={classes.add_mainmodel}>
            <ul>
              <div className={classes.heading}>
                <p>Name</p>:<span>{values.name ? values.name : "-"}</span>
              </div>
              <div className={classes.heading}>
                <p>Organization name</p>:
                <span>
                  {values.organizationName ? values.organizationName : "-"}
                </span>
              </div>
              <div className={classes.heading}>
                <p>Mobile no</p>:
                <span>{values.mobile_no ? values.mobile_no : "-"}</span>
              </div>
              <div className={classes.heading}>
                <p>Alterbative mobile no</p>:
                <span>
                  {values.alternative_no ? values.alternative_no : "-"}
                </span>
              </div>
              <div className={classes.heading}>
                <p>Email</p>:<span>{values.email ? values.email : "-"}</span>
              </div>
              <div className={classes.heading}>
                <p>GST number</p>:
                <span>{values.gstNo ? values.gstNo : "-"}</span>
              </div>
              <div className={classes.heading}>
                <p>Address</p>:
                <span>{values.address ? values.address : "-"}</span>
              </div>
            </ul>
          </div>
        ) : (
          <div className="row">
            <div className="col-md-12 col-xl-6 form-group mb-3">
              <TextInputBox
                requiredText={"*"}
                title="Name"
                textLength={50}
                name="name"
                placeHolder="Enter name"
                onChangeText={(text) => {
                  const alphanumericText = text.replace(
                    /[^A-Za-z0-9-_() ]/g,
                    ""
                  );
                  handleChange("name")(alphanumericText);
                }}
                value={values.name}
                onBlur={handleBlur("name")}
                inputBoxStyle={{
                  backgroundColor: viewstatus ? "silver" : "white",
                  cursor: viewstatus ? "not-allowed" : "",
                }}
                errorText={touched.name && errors.name ? errors.name : ""}
              />
            </div>
            <div className="col-md-12 col-xl-6 form-group mb-3">
              <TextInputBox
                requiredText={"*"}
                name="name"
                textLength={50}
                title="Organization Name"
                placeHolder="Enter organization name"
                onChangeText={handleChange("organizationName")}
                value={values.organizationName}
                onBlur={handleBlur("organizationName")}
                inputBoxStyle={{
                  backgroundColor: viewstatus ? "silver" : "white",
                  cursor: viewstatus ? "not-allowed" : "",
                }}
                errorText={
                  touched.organizationName && errors.organizationName
                    ? errors.organizationName
                    : ""
                }
              />
            </div>
            <div className="col-md-12 col-xl-6 form-group mb-3">
              <TextInputBox
                requiredText={"*"}
                name="mobile_no"
                title="Mobile number"
                textLength={10}
                onChangeText={(text) => {
                  const alphanumericText = text.replace(/[^0-9]/g, "");
                  handleChange("mobile_no")(alphanumericText);
                }}
                placeHolder="Enter mobile number"
                value={values.mobile_no}
                onBlur={handleBlur("mobile_no")}
                inputBoxStyle={{
                  backgroundColor: viewstatus ? "silver" : "white",
                  cursor: viewstatus ? "not-allowed" : "",
                }}
                errorText={
                  touched.mobile_no && errors.mobile_no ? errors.mobile_no : ""
                }
              />
            </div>
            <div className="col-md-12 col-xl-6 form-group mb-3">
              <TextInputBox
                textLength={10}
                name="alternative_no"
                title="Alternative mobile number"
                placeHolder="Enter alternate mobile number"
                onChangeText={(text) => {
                  const alphanumericText = text.replace(/[^0-9]/g, "");
                  handleChange("alternative_no")(alphanumericText);
                }}
                value={values.alternative_no}
                onBlur={handleBlur("alternative_no")}
                inputBoxStyle={{
                  backgroundColor: viewstatus ? "silver" : "white",
                  cursor: viewstatus ? "not-allowed" : "",
                }}
                errorText={
                  touched.alternative_no && errors.alternative_no
                    ? errors.alternative_no
                    : ""
                }
              />
            </div>
            <div className="col-md-12 col-xl-6 form-group mb-3">
              <TextInputBox
                name="email"
                title="Email"
                textLength={50}
                placeHolder="Enter email"
                onChangeText={handleChange("email")}
                value={values.email}
                onBlur={handleBlur("email")}
                inputBoxStyle={{
                  backgroundColor: viewstatus ? "silver" : "white",
                  cursor: viewstatus ? "not-allowed" : "",
                }}
                errorText={touched.email && errors.email ? errors.email : ""}
              />
            </div>
            <div className="col-md-12 col-xl-6 form-group mb-3">
              <TextInputBox
                name="gstNo"
                textLength={50}
                title="GST Number"
                placeHolder="Enter GST number"
                onChangeText={handleChange("gstNo")}
                value={values.gstNo}
                onBlur={handleBlur("gstNo")}
                inputBoxStyle={{
                  backgroundColor: viewstatus ? "silver" : "white",
                  cursor: viewstatus ? "not-allowed" : "",
                }}
                errorText={touched.gstNo && errors.gstNo ? errors.gstNo : ""}
              />
            </div>
            <div className="col-md-12 col-xl-6 form-group mb-3">
              <Typography className={classes.title}>Address</Typography>
              <textarea
                placeholder="Enter the address"
                onChange={handleChange("address")}
                value={values.address}
                className={classes.text_area}
                maxLength={255}
                onBlur={handleBlur("address")}
                style={{
                  backgroundColor: viewstatus ? "silver" : "white",
                  padding: "5px",
                  cursor: viewstatus ? "not-allowed" : "",
                }}
              ></textarea>
            </div>
            {viewstatus ? null : (
              <div className={classes.modalbtn}>
                {editData ? (
                  <>
                    <UpdateDeleteActions
                      onCancelPress={() => modalClose()}
                      onUpdatePress={handleSubmit}
                    />
                  </>
                ) : (
                  <div className="col-lg-3">
                    <CustomButton title="Submit" onButtonPress={handleSubmit} />
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default AddSuppliers;
