import { useLocation, useNavigate } from "react-router-dom";

import view from "../../Assets/Icons/Png/view.png";
import classes from "./Management/Management.module.css";
import PageHeader from "../MangementLayoutHeader/PageHeader";
import { Tooltip } from "@mui/material";
import DeviceBatch from "./Management/DeviceBatch";
import { useState } from "react";

export default function OrganizationDetail() {
  const navigate = useNavigate();
  const [showFilterOption, setShowFilterOption] = useState(false);
  const { state } = useLocation();
  return (
    <>
      <div>
        <PageHeader
          onFilterbutton={() => {
            setShowFilterOption((pre) => !pre);
          }}
          heading={state.company_name}
          Btntitle="Back"
          modal={() => navigate("/organization")}
        ></PageHeader>
      </div>
      <div className={classes.ServerHeading}></div>
      <div className={`row ${classes.server_details}`}>
        <div className={`col-lg-4 col-md-6 ${classes.details}`}>
          <p> Server Name</p>
          <strong>:</strong>
          <span>{state.server_name ? state.server_name : "-"}</span>
          <div className={classes.serverviewicon}>
            <Tooltip title="View" placement="top">
              <img
                style={{ cursor: "pointer" }}
                src={view}
                alt="View"
                onClick={() =>
                  navigate(
                    {
                      pathname:
                        "/dashboard/management/server_management/server_detail_list",
                    },
                    { state: state }
                  )
                }
              />
            </Tooltip>
          </div>
        </div>
      </div>
      <div className={`row ${classes.server_details}`}>
        <div className={`col-lg-4 col-md-6 ${classes.details}`}>
          <p>User Subscription Name</p>
          <strong>:</strong>
          <span>
            {state.userSubscriptionName ? state.userSubscriptionName : "-"}
          </span>
        </div>
        <div className={`col-lg-4 col-md-6 ${classes.details}`}>
          <p> Organizanition Code</p>
          <strong>:</strong>
          <span>{state.organization_code ? state.organization_code : "-"}</span>
        </div>
        <div className={`col-lg-4 col-md-6 ${classes.details}`}>
          <p> Device Count</p>
          <strong>:</strong>
          <span>{state.deviceCount ? state.deviceCount : "-"}</span>
        </div>
      </div>
      <div className={`row ${classes.server_details}`}>
        <div className={`col-lg-4 col-md-6 ${classes.details}`}>
          <p> Mobile Number</p>
          <strong>:</strong>
          <span>{state.contact_no ? state.contact_no : "-"}</span>
        </div>
        <div className={`col-lg-4 col-md-6 ${classes.details}`}>
          <p>Project Name</p>
          <strong>:</strong>
          <span>{state.projectName ? state.projectName : "-"}</span>
        </div>
        <div className={`col-lg-4 col-md-6 ${classes.details}`}>
          <p> Email</p>
          <strong>:</strong>
          <span>{state.email ? state.email : "-"}</span>
        </div>
      </div>
      <DeviceBatch
        head=""
        orgid={state.org_id}
        FilterOption={showFilterOption}
      />
    </>
  );
}
