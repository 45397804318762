import { ChangeEvent, useState } from "react";
import ModalHeader from "./ModalHeader";
import { AddFirmWareProps, DeviceModelProps } from "./Types";
import classes from "./Modal.module.css";
import toast from "react-hot-toast";
import { useFormik } from "formik";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {
  createFirmWareMaster,
  updateFirmWareManageMent,
} from "../Services/Services";
import { getCatchMsg, getTrimString } from "../Utility/GeneralUtils";
import uploadImage_icon from "../Assets/Icons/Png/upload_icon.png";
import { CustomButton, CustomDropDown, TextInputBox } from "../Components";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { openLoader } from "../Store/Reducers/LoaderSlice";
import { muiStyles } from "../Utility/Constants";
import { Button, Link, Paper } from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

export type DefaultValueProps = {
  firmwareName: string;
  firmwareUpload: string;
  fileName: string;
  firmwareType: string;
  firmwareTypeId: string;
  description: string;
  model: DeviceModelProps | null;
};
function AddFirmWare({
  onClose,
  modalClose,
  listapiCall,
  viewId,
  editData,
  modelDropdownList,
}: AddFirmWareProps) {
  const defaultValues: DefaultValueProps = {
    firmwareName: "",
    fileName: "",
    firmwareUpload: "",
    firmwareType: "",
    firmwareTypeId: "",
    description: "",
    model: null,
  };
  const dispatch = useDispatch();

  const muistyle = muiStyles();

  const checkImages = ["bin"];
  const [firmware, setfirmware] = useState([
    {
      id: 1,
      name: "Major Change",
    },
    {
      id: 2,
      name: "Minor Change",
    },
    {
      id: 3,
      name: "Bug Fixes",
    },
  ]);

  const validationSchema = Yup.object({
    firmwareName: Yup.string().required("Firmware name is required"),
    model: Yup.mixed()
      .transform((val) => (val && Object?.keys(val)?.length === 0 ? null : val))
      .required("* Model is required"),
    firmwareType: Yup.string()
      .required("* Firmware type is required")
      .test("test", "Firmware type is required", function (value) {
        if (value === "-- None --") {
          return false;
        }
        return true;
      }),
    firmwareUpload: editData
      ? Yup.string()
      : Yup.string().required("* File is required"),
  });

  const {
    setFieldValue,
    values,
    handleChange,
    handleSubmit,
    errors,
    touched,
    resetForm,
  } = useFormik({
    initialValues: defaultValues,
    validationSchema: validationSchema,
    onSubmit: () => {
      if (viewId && editData) {
        handleUpdateFirmWare(values);
      } else {
        handleAddFirmWare(values);
      }
    },
  });
  const handleAddFirmWare = (data: any) => {
    dispatch(openLoader(true));
    let formData = new FormData();
    formData.append("description", data?.description);
    formData.append("firmwareUpload", data?.firmwareUpload);
    formData.append("firmwareType", data?.firmwareTypeId);
    if (values.model) {
      formData.append("modelId", values?.model?.deviceModelId?.toString());
    }
    formData.append("firmwareName", data?.firmwareName);
    createFirmWareMaster(formData)
      .then((response) => {
        if (response?.data?.status === 1) {
          toast.success(response?.data?.msg);
          modalClose();
          listapiCall("add");
        } else if (response?.data?.status === 0) {
          toast?.error(response?.data?.msg);
        }
      })
      .catch((error) => {
        getCatchMsg(error);
      })
      .finally(() => {
        dispatch(openLoader(false));
      });
  };

  const handleUpdateFirmWare = (data: any) => {
    dispatch(openLoader(true));
    let formData = new FormData();
    if (viewId) formData.append("firmwareId", viewId.toString());
    formData.append("firmwareUpload", data?.firmwareUpload);
    formData.append("description", data?.description);

    if (values?.model) {
      formData.append("modelId", values?.model?.deviceModelId?.toString());
    }
    updateFirmWareManageMent(formData)
      .then((response) => {
        if (response?.data?.status === 1) {
          toast.success(response?.data?.msg);
          modalClose();
          listapiCall("update");
        } else if (response?.data?.status === 0) {
          toast?.error(response?.data?.msg);
        }
      })
      .catch((error) => {
        getCatchMsg(error);
      })
      .finally(() => {
        dispatch(openLoader(false));
      });
  };

  return (
    <>
      <ModalHeader
        heading={`${viewId ? "View" : "Add"} Firmware`}
        onClose={() => {
          onClose();
          resetForm();
        }}
      />
      <div className={classes.AddFirmWare}>
        {viewId ? (
          <div className={`${classes.viewContainer} mb-4`}>
            <div className={classes.viewContent}>
              <p>Firmware Name</p>
              <strong>:</strong>
              <span>
                {editData?.firmwareName ? editData?.firmwareName : "-"}
              </span>
            </div>
            <div className={classes.viewContent}>
              <p>Model Name</p>
              <strong>:</strong>
              <span>
                {editData?.deviceModelName ? editData?.deviceModelName : "-"}
              </span>
            </div>
            <div className={classes.viewContent}>
              <p>Firmware Type</p>
              <strong>:</strong>
              <span>
                {editData?.firmware_type
                  ? firmware.find((ele) => editData?.firmware_type === ele?.id)
                      ?.name
                  : "-"}
              </span>
            </div>
            <div className={classes.viewContent}>
              <p>Version</p>
              <strong>:</strong>
              <span>{editData?.version ? editData?.version : "-"}</span>
            </div>
            <div className="text-center my-3">
              <Button
                style={{
                  backgroundColor: "var(--menuBg)",
                }}
                variant="contained"
                onClick={() => {
                  window.open(editData?.firmwarePath);
                }}
                endIcon={<CloudDownloadIcon />}
              >
                Firmware File
              </Button>
            </div>
          </div>
        ) : (
          <>
            <div className="row">
              <div className="col-md-6 col-lg-4 form-group mb-3">
                <TextInputBox
                  title="Firmware Name"
                  placeHolder="Enter firmware name"
                  requiredText={"*"}
                  textLength={50}
                  onChangeText={handleChange("firmwareName")}
                  errorText={
                    errors?.firmwareName && touched?.firmwareName
                      ? errors?.firmwareName
                      : ""
                  }
                />
              </div>
              <div
                className="col-md-6 col-lg-4 form-group mb-3"
                onClick={() => document.getElementById("file_open")?.click()}
              >
                <p className={classes.title}>
                  File <span>*</span>
                </p>
                <div className={classes.upload_file}>
                  {viewId ? (
                    <p className={classes.firmwareName}>
                      {values?.firmwareName
                        ? values?.firmwareName
                        : "--Not given--"}
                    </p>
                  ) : (
                    <>
                      <div className={classes.upload_icon}>
                        <img src={uploadImage_icon} alt="uploadImage_icon" />
                      </div>
                      <p>
                        {values?.firmwareUpload
                          ? values?.fileName
                          : "Upload File"}
                      </p>
                      <input
                        accept=".bin"
                        type="file"
                        id="file_open"
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          if (
                            event.target.files &&
                            event.target.files.length > 0
                          ) {
                            let data = event.target.files[0].name.split(".")[1];
                            if (checkImages.includes(data?.toLowerCase())) {
                              setFieldValue(
                                "fileName",
                                event.target.files[0].name.split(".")[0]
                              );
                              setFieldValue(
                                "firmwareUpload",
                                event.target.files[0]
                              );
                            } else {
                              toast.error("Please select valid firmware");
                            }
                          }
                        }}
                      />
                    </>
                  )}
                </div>
                <p className={classes.infotext}>
                  <span className="material-symbols-outlined">info</span>
                  bin
                </p>
                {errors?.firmwareUpload && touched?.firmwareUpload ? (
                  <p className={classes.errorTxt}>{errors?.firmwareUpload}</p>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-6 col-lg-4 form-group mb-3">
                <CustomDropDown
                  disabled={viewId || editData ? true : false}
                  required={"*"}
                  editName={values?.firmwareType}
                  fieldName={"name"}
                  anotherFieldName={"id"}
                  title="Firmware Type"
                  items={firmware}
                  onSelectedItem={(val, value) => {
                    setFieldValue("firmwareType", value.label);
                    setFieldValue("firmwareTypeId", value.id);
                  }}
                  value={values?.firmwareTypeId}
                  errorText={
                    errors?.firmwareType && touched?.firmwareType
                      ? errors?.firmwareType
                      : ""
                  }
                />
              </div>
              <div className="col-md-6 col-lg-4 form-group mb-3">
                <p className={classes.title}>
                  Select Model <span>*</span>
                </p>
                <Autocomplete
                  className="dropbr8"
                  size="small"
                  disableClearable
                  id="multiple-limit-tags"
                  onChange={(event, value) => {
                    setFieldValue("model", value);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option?.deviceModelId === value?.deviceModelId
                  }
                  // @ts-ignore
                  value={values?.model}
                  options={modelDropdownList || []}
                  getOptionLabel={(option) => option.deviceModelName}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Select Model" />
                  )}
                  PaperComponent={(props) => {
                    return (
                      <Paper {...props} className={muistyle.customSelect} />
                    );
                  }}
                />
                {errors?.model && touched?.model ? (
                  <p className={classes.errorTxt}>{errors?.model}</p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row">
              <div
                className={
                  !editData
                    ? "col-md-6 col-lg-6 form-group mb-3"
                    : "col-md-6 col-lg-6 form-group"
                }
              >
                <p className={classes.title}>Description</p>
                <textarea
                  disabled={editData || viewId ? true : false}
                  value={values?.description}
                  className={classes.text_area}
                  name="description"
                  onChange={handleChange("description")}
                ></textarea>
              </div>
            </div>
          </>
        )}
        {viewId ? null : (
          <div className={classes.modalbtn}>
            <div className="col-lg-3 childbtnMt-0">
              <CustomButton title="Submit" onButtonPress={handleSubmit} />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default AddFirmWare;
