import React, { Fragment, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import * as Yup from "yup";
import { useFormik } from "formik";

import {
  createUser,
  getDesignationDropdown,
  updateUser,
  viewUser,
  edituserpassword,
} from "../Services/Services";
import { getCatchMsg } from "../Utility/GeneralUtils";
import {
  EMAIL_REGEX,
  INDIAN_MOBILE_REGEX,
  NAMES,
  NUMBER,
} from "../Utility/Constants";
import {
  Loader,
  TextInputBox,
  CustomButton,
  CustomDropDown,
} from "../Components/index";
import { EmployeeListItemProps } from "../Views/HomeScreens/Management/managementTypes";
import ModalHeader from "./ModalHeader";
import classes from "./Modal.module.css";
import { AddUserModalProps } from "./Types";
import UpdateDeleteActions from "../Components/UpdateDeleteActions";
import { openLoader } from "../Store/Reducers/LoaderSlice";
import { useDispatch } from "react-redux";

export type DesignationDropProps = {
  designation: string;
  designation_id: number;
};
function AddUserModal({
  onClose,
  editData,
  listUserApicall,
  modalClose,
  getView,
  getViewId,
  geteditpwdId,
  geteditpwdstatus,
}: AddUserModalProps) {
  const dispatch = useDispatch();

  const validationSchema = Yup.object({
    username: geteditpwdstatus
      ? Yup.string()
      : Yup.string()
          .required("*First name is required")
          .matches(NAMES, "enter valid first number")
          .test(
            "trim spaces",
            "Remove leading and trailing spaces",
            function (value) {
              if (value?.trim()?.length === 0) {
                return false;
              }
              return true;
            }
          ),

    password: editData
      ? Yup.string()
      : Yup.string()
          .required("*Password is required")
          .trim("Remove leading and trailing spaces")
          .strict(true),
    confirm_password: editData
      ? Yup.string()
      : Yup.string()
          .required("Confirm password is required")
          .oneOf([Yup.ref("password")], "Passwords must match")
          .trim("Remove leading and trailing spaces")
          .strict(true),

    designation: geteditpwdstatus
      ? Yup.string()
      : Yup.string().required("*Designation is required"),
    user_code: Yup.string()
      ? Yup.string()
      : Yup.string()
          .required("*User code is required")
          .test(
            "trim spaces",
            "Remove leading and trailing spaces",
            function (value) {
              if (value?.trim()?.length === 0) {
                return false;
              }
              return true;
            }
          ),

    email: Yup.string().matches(EMAIL_REGEX, "enter valid email address"),
    mobile_no: Yup.string().matches(
      INDIAN_MOBILE_REGEX,
      "Enter a valid mobile number"
    ),
    alternativeMobileNo: Yup.string().matches(
      INDIAN_MOBILE_REGEX,
      "Enter a valid Alternative number"
    ),
  });

  const [designation, setlistDesigantion] = useState();
  const {
    handleChange,
    handleSubmit,
    values,
    setFieldValue,
    setFieldTouched,
    setFieldError,
    errors,
    touched,
    handleBlur,
  } = useFormik({
    initialValues: {
      user_id: getView ? "-" : editData?.user_id || "",
      username: getView ? "-" : editData?.user_name || "",
      mobile_no: getView ? "-" : editData?.mobile_no || "",
      alternativeMobileNo: getView ? "-" : editData?.alternativeMobileNo || "",
      user_code: getView ? "-" : editData?.user_code || "",
      password: getView ? "-" : "",
      confirm_password: getView ? "-" : "",
      designation: getView ? "-" : editData?.designation || "",
      email: getView ? "-" : editData?.email || "",
      designation_id: getView ? "-" : editData?.designation_id || "",
      desigantionInput: getView ? "-" : editData?.designation || "",
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      if (editData) {
        handleUpdateUser();
      } else if (geteditpwdstatus) {
        handleedituserpassword();
      } else {
        handleAddUser();
      }
    },
  });

  /**
   * Handles the process of editing a user's password.
   * @returns None
   */
  const handleedituserpassword = () => {
    dispatch(openLoader(true));
    const formData = new FormData();
    if (geteditpwdId) {
      formData.append("userId", geteditpwdId.toString());
    }
    formData.append("password", values.password);
    edituserpassword(formData)
      .then((response) => {
        if (response?.data?.status === 1) {
          toast.success(response?.data?.msg);
          listUserApicall();
          modalClose();
        }
      })
      .catch((err) => getCatchMsg(err))
      .finally(() => {
        dispatch(openLoader(false));
      });
  };
  /**
   * Retrieves the designation dropdown options from the server and updates the state
   * with the received data.
   * @returns None
   */
  const handleGetDesignation = () => {
    getDesignationDropdown()
      .then((response) => {
        if (response?.data?.status === 1) {
          setlistDesigantion(response?.data?.data);
        }
      })
      .catch((err) => getCatchMsg(err));
  };
  /**
   * useEffect hook that triggers the handleGetDesignation function if getView is falsy.
   * @param {function} handleGetDesignation - The function to handle getting the designation.
   * @param {boolean} getView - The value that triggers the effect.
   * @returns None
   */
  useEffect(() => {
    if (!getView && !geteditpwdstatus) {
      handleGetDesignation();
    }
  }, [getView, geteditpwdstatus]);

  /**
   * Handles the process of adding a new user.
   * - Creates a new FormData object.
   * - Sets the loader state to true.
   * - Appends the necessary form data values to the FormData object.
   * - Calls the createUser API function with the FormData object.
   * - Handles the response from the API:
   *   - If the response status is 1, displays a success toast message, updates the user list, and closes the modal.
   *   - If the response status is 0, displays an error toast message.
   * - Handles any errors that occur during the API call and displays an error toast message.
   * - Finally, sets the loader state to false.
   * @returns None
   */
  const handleAddUser = () => {
    let formData = new FormData();
    dispatch(openLoader(true));
    formData.append("username", values.username);
    formData.append("mobile_no", values.mobile_no);
    formData.append("user_code", values.user_code);
    formData.append("password", values.password);
    formData.append("alternativeMobileNo", values.alternativeMobileNo);
    formData.append("position", values.desigantionInput);
    formData.append("designationId", values.designation_id?.toString());
    formData.append("email", values.email);
    createUser(formData)
      .then((response) => {
        if (response?.data?.status === 1) {
          toast.success(response?.data?.msg);
          listUserApicall("add");
          modalClose();
        } else if (response?.data?.status === 0) {
          toast.error(response?.data?.msg);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        dispatch(openLoader(false));
      });
  };

  /**
   * Handles the update of a user by sending a request to the server with the updated user data.
   * @returns None
   */
  const handleUpdateUser = () => {
    let formData = new FormData();
    dispatch(openLoader(true));
    formData.append("user_id", values.user_id?.toString());
    formData.append("user_name", values.username);
    formData.append("mobile_no", values.mobile_no);
    formData.append("alternativeMobileNo", values.alternativeMobileNo);
    formData.append("user_code", values.user_code);
    formData.append("password", values.password);
    formData.append("position", values.desigantionInput);
    formData.append("designationId", values.designation_id?.toString());
    formData.append("email", values.email);
    updateUser(formData)
      .then((response) => {
        if (response?.data?.status === 1) {
          toast.success(response?.data?.msg);
          listUserApicall("update");
          modalClose();
        } else if (response?.data?.status === 0) {
          toast.error(response?.data?.msg);
        }
      })
      .catch((err) => getCatchMsg(err))
      .finally(() => {
        dispatch(openLoader(false));
      });
  };

  /**
   * Handles the retrieval of user view data from the server.
   * @returns None
   */
  const hadleGetView = () => {
    let formData = new FormData();
    dispatch(openLoader(true));
    if (getViewId) {
      formData.append("user_id", getViewId?.toString());
    }
    viewUser(formData)
      .then((response) => {
        if (response?.data?.status === 1) {
          const FieldData = response?.data?.data?.map(
            (details: EmployeeListItemProps) => {
              setFieldValue("username", details?.user_name);
              setFieldValue("designation", details?.designation);
              setFieldValue("email", details?.email);
              setFieldValue("user_code", details?.user_code);
              setFieldValue("mobile_no", details?.mobile_no);
              setFieldValue(
                "alternativeMobileNo",
                details?.alternativeMobileNo
              );
              return details;
            }
          );
        }
      })
      .catch((err) => getCatchMsg(err))
      .finally(() => {
        dispatch(openLoader(false));
      });
  };
  /**
   * Executes a callback function when the value of getView changes.
   * @param {Function} getView - The function that returns the value to monitor for changes.
   * @returns None
   */
  useEffect(() => {
    if (getView) {
      hadleGetView();
    }
  }, [getView]);

  return (
    <>
      <ModalHeader
        onClose={onClose}
        // @ts-ignore
        heading={
          geteditpwdstatus
            ? "Edit Password"
            : getView
              ? values?.username
              : editData === null
                ? "Add User"
                : "Edit User"
        }
      />
      {geteditpwdstatus ? (
        <div className={classes.addModel}>
          <div className="row">
            <div className="col-lg-12 mb-3">
              <TextInputBox
                requiredText="*"
                title="Password"
                isSecure={true}
                textLength={50}
                placeHolder="Enter the password"
                name="Password"
                onChangeText={handleChange("password")}
                value={values.password}
                onBlur={handleBlur("fpassword")}
                errorText={
                  touched.password && errors.password ? errors.password : ""
                }
                inputBoxStyle={{
                  backgroundColor: getView ? "silver" : "white",
                  cursor: getView ? "not-allowed" : "",
                }}
              />
            </div>
            <div className="col-lg-12">
              <TextInputBox
                requiredText="*"
                title="Confirm Password"
                isSecure={true}
                textLength={50}
                placeHolder="Enter the confirm password"
                name="confirm_password"
                onChangeText={handleChange("confirm_password")}
                value={values.confirm_password}
                onBlur={handleBlur("confirm_password")}
                errorText={
                  touched.confirm_password && errors.confirm_password
                    ? errors.confirm_password
                    : ""
                }
                inputBoxStyle={{
                  backgroundColor: getView ? "silver" : "white",
                  cursor: getView ? "not-allowed" : "",
                }}
              />
            </div>
          </div>
        </div>
      ) : getView ? (
        <div className={classes.vieworg}>
          <ul>
            <div className={classes.heading}>
              <p>Name</p>:<span>{values.username ? values.username : "-"}</span>
            </div>
            <div className={classes.heading}>
              <p>Designation</p>:
              <span>{values.designation ? values.designation : "-"}</span>
            </div>
            <div className={classes.heading}>
              <p>User code</p>:
              <span>{values.user_code ? values.user_code : "-"}</span>
            </div>
            <div className={classes.heading}>
              <p>Mobile no</p>:
              <span>{values.mobile_no ? values.mobile_no : "-"}</span>
            </div>
            <div className={classes.heading}>
              <p>Alternative mobile no</p>:
              <span>
                {values.alternativeMobileNo ? values.alternativeMobileNo : "-"}
              </span>
            </div>
            <div className={classes.heading}>
              <p>Email</p>:<span>{values.email ? values.email : "-"}</span>
            </div>
          </ul>
        </div>
      ) : (
        <div className={classes.addModel}>
          <div className="row">
            <div className="col-lg-6 mt-3">
              <TextInputBox
                requiredText={getView ? "" : "*"}
                readOnly={getView}
                title="User Name"
                textLength={50}
                placeHolder="Enter the first name"
                name="username"
                onChangeText={handleChange("username")}
                value={values.username}
                onBlur={handleBlur("username")}
                errorText={
                  !getView && touched.username && errors.username
                    ? errors.username
                    : ""
                }
                inputBoxStyle={{
                  backgroundColor: getView ? "silver" : "white",
                  cursor: getView ? "not-allowed" : "",
                }}
              />
            </div>

            <div className="col-lg-6 mt-3" style={{ position: "relative" }}>
              {getView ? (
                <TextInputBox
                  name="designation"
                  title="Designation"
                  readOnly={getView}
                  value={values.designation}
                  onChangeText={(e) => {}}
                  inputBoxStyle={{
                    backgroundColor: getView ? "silver" : "white",
                    cursor: getView ? "not-allowed" : "",
                  }}
                />
              ) : (
                <CustomDropDown
                  required="*"
                  editName={
                    values.designation ? values.designation : "--None--"
                  }
                  title="Designation"
                  items={designation}
                  onSelectedItem={(val, value) => {
                    setFieldValue("designation", value.label);
                    setFieldValue("designation_id", value.id);
                  }}
                  fieldName="designation_name"
                  anotherFieldName="designation_id"
                  value={values.designation_id}
                  onBlur={() => {
                    try {
                      validationSchema.validateSyncAt(
                        "designation",
                        values.designation
                      );
                    } catch (error) {
                      if (error instanceof Error) {
                        setFieldTouched("designation", true);
                        setFieldError("designation", error.message);
                      }
                    }
                  }}
                  errorText={
                    touched.designation && errors.designation
                      ? errors.designation
                      : ""
                  }
                  isEnableInput={true}
                  additionalInputValue={values.desigantionInput}
                  setAddtionalInputValue={handleChange("desigantionInput")}
                />
              )}
            </div>

            <div className="col-lg-6 mt-3">
              <TextInputBox
                requiredText={getView ? "" : "*"}
                readOnly={getView}
                title="User Code"
                textLength={50}
                placeHolder="Enter the user code"
                name="user_code"
                onChangeText={handleChange("user_code")}
                value={values.user_code}
                onBlur={() => {
                  try {
                    validationSchema.validateSyncAt(
                      "user_code",
                      values.user_code
                    );
                  } catch (error) {
                    if (error instanceof Error) {
                      setFieldTouched("user_code", true);
                      setFieldError("user_code", error.message);
                    }
                  }
                }}
                errorText={
                  !getView && touched.user_code && errors.user_code
                    ? errors.user_code
                    : ""
                }
                inputBoxStyle={{
                  backgroundColor: getView ? "silver" : "white",
                  cursor: getView ? "not-allowed" : "",
                }}
              />
            </div>

            <div className="col-lg-6 mt-3">
              <TextInputBox
                readOnly={getView}
                infotxt={
                  getView
                    ? null
                    : touched.mobile_no && errors.mobile_no
                      ? ""
                      : "number only"
                }
                title="Mobile Number"
                textLength={10}
                placeHolder="Enter the mobile number"
                name="mobile_no"
                onChangeText={(text) => {
                  const numericText = text.replace(/[^0-9]/g, "");
                  handleChange("mobile_no")(numericText);
                }}
                onBlur={handleBlur("mobile_no")}
                value={values.mobile_no}
                errorText={
                  !getView && touched.mobile_no && errors.mobile_no
                    ? errors.mobile_no
                    : ""
                }
                inputBoxStyle={{
                  backgroundColor: getView ? "silver" : "white",
                  cursor: getView ? "not-allowed" : "",
                }}
              />
            </div>
            <div className="col-lg-6 mt-3">
              <TextInputBox
                readOnly={getView}
                title="Alternative Number"
                textLength={10}
                placeHolder="Enter the Alternative number"
                name="alternativeMobileNo"
                onChangeText={(text) => {
                  const numericText = text.replace(/[^0-9]/g, "");
                  handleChange("alternativeMobileNo")(numericText);
                }}
                onBlur={handleBlur("alternativeMobileNo")}
                value={values.alternativeMobileNo}
                errorText={
                  !getView &&
                  touched.alternativeMobileNo &&
                  errors.alternativeMobileNo
                    ? errors.alternativeMobileNo
                    : ""
                }
                inputBoxStyle={{
                  backgroundColor: getView ? "silver" : "white",
                  cursor: getView ? "not-allowed" : "",
                }}
              />
            </div>

            <div className="col-lg-6 mt-3">
              <TextInputBox
                readOnly={getView}
                title="Email"
                textLength={50}
                placeHolder="Enter the email"
                name="email"
                onChangeText={handleChange("email")}
                value={values.email}
                onBlur={() => {
                  try {
                    validationSchema.validateSyncAt("email", values.email);
                  } catch (error) {
                    if (error instanceof Error) {
                      setFieldTouched("email", true);
                      setFieldError("email", error.message);
                    }
                  }
                }}
                errorText={
                  !getView && touched.email && errors.email ? errors.email : ""
                }
                inputBoxStyle={{
                  backgroundColor: getView ? "silver" : "white",
                  cursor: getView ? "not-allowed" : "",
                }}
              />
            </div>

            {!editData && !getView && (
              <Fragment>
                <div className="col-lg-6 mt-3">
                  <TextInputBox
                    isSecure={true}
                    requiredText={getView ? "" : "*"}
                    readOnly={getView}
                    title="Password"
                    textLength={50}
                    placeHolder="Enter the password"
                    name="password"
                    onChangeText={handleChange("password")}
                    value={values.password}
                    onBlur={() => {
                      try {
                        validationSchema.validateSyncAt(
                          "password",
                          values.password
                        );
                      } catch (error) {
                        if (error instanceof Error) {
                          setFieldTouched("password", true);
                          setFieldError("password", error.message);
                        }
                      }
                    }}
                    errorText={
                      touched.password && errors.password ? errors.password : ""
                    }
                    inputBoxStyle={{
                      backgroundColor: getView ? "silver" : "white",
                      cursor: getView ? "not-allowed" : "",
                    }}
                  />
                </div>
                <div className="col-lg-6 mt-3">
                  <TextInputBox
                    requiredText="*"
                    title="Confirm Password"
                    isSecure={true}
                    textLength={50}
                    placeHolder="Enter the confirm password"
                    name="confirm_password"
                    onChangeText={handleChange("confirm_password")}
                    value={values.confirm_password}
                    onBlur={handleBlur("confirm_password")}
                    errorText={
                      touched.confirm_password && errors.confirm_password
                        ? errors.confirm_password
                        : ""
                    }
                    inputBoxStyle={{
                      backgroundColor: getView ? "silver" : "white",
                      cursor: getView ? "not-allowed" : "",
                    }}
                  />
                </div>
              </Fragment>
            )}
          </div>
        </div>
      )}
      <div className={classes.AddEditCancelBtns}>
        {getView ? null : (
          <>
            <div className={classes.modalbtn}>
              {editData || geteditpwdstatus ? (
                <UpdateDeleteActions
                  onCancelPress={() => modalClose()}
                  onUpdatePress={handleSubmit}
                />
              ) : (
                <div className="col-lg-3">
                  <CustomButton title="Submit" onButtonPress={handleSubmit} />
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default AddUserModal;
