import React, { useEffect, useState } from "react";
import { ProjectDrodownProps } from "../../Modals/Types";
import { useToken } from "../../Utility/StoreData";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import {
  isShowModalSubcriptionProps,
  subcriptionListDataItemsProps,
  subcriptionListProps,
} from "./Management/managementTypes";
import { DeclineConfirmation, OrganizationDropdownProps } from "./Types";
import {
  organizationDropDown,
  projectdropdownlist,
  subscriptionRequest,
  subscriptionResponse,
} from "../../Services/Services";
import { getCatchMsg, isFormDirty } from "../../Utility/GeneralUtils";
import toast from "react-hot-toast";
import PageHeader from "../MangementLayoutHeader/PageHeader";
import {
  CustomButton,
  CustomPaginationwithdropdownpagination,
  GlobalModal,
  NodataLoader,
  TableRowsLoader,
  TextInputBox,
} from "../../Components";
import { AddSubcription } from "../../Modals";
import AccordionContent from "../../Components/AccordionContent";
import {
  Box,
  // Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  // TextField,
  Tooltip,
} from "@material-ui/core";
import { Autocomplete, Grid, Paper, TextField } from "@mui/material";
// import { Autocomplete, Paper } from "@mui/material";
import classes from "../HomeScreens/Management/Management.module.css";
import Inbox from "../../Assets/Images/Png/noData.png";
import moment from "moment";
import AddSubscriptionRateConfirmation from "../../Modals/AddSubscriptionRateConfirmation";
import SearchAndClearbutton from "../../Components/SearchAndClearbutton";
import { muiStyles } from "../../Utility/Constants";
import PlanConfirmationModal from "../../Modals/PlanConfirmationModal";
import AcceptIcon from "../../Assets/Icons/Png/accepticon.png";
import CancelIcon from "../../Assets/Icons/Png/cancelicon.png";
export default function UpgradeSubscriptionRequest() {
  const defaultValues: {
    name: string;
    selected_project: ProjectDrodownProps | null;
    organization: any;
    Company_Name: string;
    Email: any;
    Mobile_No: any;
    project: any;
  } = {
    name: "",
    selected_project: null,
    organization: null,
    Company_Name: "",
    Mobile_No: "",
    Email: "",
    project: null,
  };
  const token = useToken();
  const dispatch = useDispatch();
  const muistyle = muiStyles();
  const [EditCost, setEditCost] = useState({
    statue: false,
  });
  const {
    values,
    resetForm,
    handleChange,
    setFieldValue,
    handleBlur,
    errors,
    initialValues,
  } = useFormik({
    initialValues: defaultValues,
    onSubmit: () => {},
  });
  const [isShowModal, setIsShowModal] = useState<isShowModalSubcriptionProps>({
    status: false,
    data: null,
    viewStatus: false,
  });
  const [deleteModal, setdeleteModal] = useState({
    modal: false,
    id: "",
  });
  const [subScriptionUpdateModal, setSubscriptionUpdateModal] = useState({
    status: false,
    data: null,
  });
  const [noDataLoader, setnoDataLoader] = useState(false);
  const [showFilterOption, setShowFilterOption] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [searchOption, setSearchOption] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [organizationList, setOrganizationList] = useState<
    OrganizationDropdownProps[]
  >([]);
  const [declineConfirmationModal, setDeclineConfirmationModal] =
    useState<DeclineConfirmation>({
      status: false,
      data: null,
    });
  const [subcriptionList, setSubcriptionList] =
    useState<subcriptionListProps | null>(null);
  const [projectList, setProjectList] = useState<ProjectDrodownProps[]>([]);

  const getProjectList = () => {
    projectdropdownlist()
      .then((res) => {
        if (res?.data?.status) {
          setProjectList(res?.data?.data || []);
        }
      })
      .catch((err) => getCatchMsg(err));
  };
  const handleGetSubcriptionList = (
    page = 1,
    size = 10,
    resetValue?: boolean
  ) => {
    setnoDataLoader(true);
    let formData = new FormData();
    formData.append("name", resetValue ? "" : values?.name);
    if (values.organization) {
      formData.append("organization", values.organization);
    }
    if (values.Company_Name) {
      formData.append("companyName", values.Company_Name);
    }
    if (values.Email) {
      formData.append("email", values.Email);
    }
    if (values.Mobile_No) {
      formData.append("phone", values.Mobile_No);
    }
    formData.append(
      "projectID",
      resetValue || !values.project ? "" : values?.project?.id?.toString()
    );
    formData.append("is_upgrade", "1");
    subscriptionRequest(page, size, formData)
      .then((response) => {
        setPage(response?.data?.data?.page);
        setTotalCount(response?.data?.data?.totalCount);
        if (response?.data?.data?.items?.length > 0) {
          setSearchOption(true);
        } else if (values.name) {
          setSearchOption(true);
        } else {
          setSearchOption(false);
        }
        setSubcriptionList(response?.data);
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        setnoDataLoader(false);
      });
  };

  const handleSubScriptionResponse = (ReqId: any, Status: any) => {
    setnoDataLoader(true);
    let formData = new FormData();
    formData.append("req_id", ReqId);
    formData.append("action_status", Status);
    subscriptionResponse(formData)
      .then((res) => {
        if (res.data.status === 1) {
          toast.success(res.data.msg);
          setDeclineConfirmationModal((prev) => {
            return {
              ...prev,
              status: false,
              data: null,
            };
          });
          handleGetSubcriptionList();
        } else {
          toast.error(res.data.msg);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        setnoDataLoader(false);
      });
  };
  const getOrganizations = () => {
    organizationDropDown()
      .then((res) => {
        if (res?.data?.status === 1) {
          setOrganizationList(res?.data?.data);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      });
  };
  const handleTableSearch = (data: string) => {
    if (data === "Enter") {
      setPage(1);
      handleGetSubcriptionList();
    }
  };

  const handleTableSearchClear = () => {
    setPage(1);
    resetForm();
    handleGetSubcriptionList(undefined, undefined, true);
  };

  useEffect(() => {
    if (token) {
      getOrganizations();
      getProjectList();
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      handleGetSubcriptionList();
    }
  }, [token, values.organization]);
  return (
    <>
      <GlobalModal
        size="xl"
        modalStyle="globalModalmaxWidth logoutmodalminWidth"
        isVisible={subScriptionUpdateModal.status}
        setIsVisible={() => {
          setSubscriptionUpdateModal((prev) => {
            return {
              ...prev,
              status: true,
            };
          });
        }}
      >
        <AddSubscriptionRateConfirmation
          onClose={() => {
            setSubscriptionUpdateModal((prev: any) => {
              return {
                ...prev,
                status: false,
              };
            });
          }}
          editData={subScriptionUpdateModal.data}
          listApiCall={() => {
            handleGetSubcriptionList();
          }}
        />
      </GlobalModal>
      <GlobalModal
        size="lg"
        modalStyle="logoutmodalminWidth"
        isVisible={declineConfirmationModal.status}
        setIsVisible={() => {
          setDeclineConfirmationModal((prev: any) => {
            return {
              ...prev,
              status: true,
            };
          });
        }}
      >
        <PlanConfirmationModal
          onClose={() => {
            handleGetSubcriptionList(page);
            setDeclineConfirmationModal((prev: any) => {
              return {
                ...prev,
                status: false,
                data: null,
              };
            });
          }}
          // deviceInactive={true}
          positiveButtonText={"Decline"}
          msg={"Would you like to decline this subscription request?"}
          // msg2={moment(SubscribeUnSubscribeModal?.date).format(
          //   "YYYY-MM-DD hh:mm:ss."
          // )}
          // msg3="Do you want Inactive?."
          onPositiveButtonPressed={() => {
            handleSubScriptionResponse(
              declineConfirmationModal?.data?.req_id,
              "2"
            );
          }}
          onNegativeButtonPressed={() => {
            handleGetSubcriptionList();
            setDeclineConfirmationModal((prev: any) => {
              return {
                ...prev,
                status: false,
                data: false,
              };
            });
          }}
        />
      </GlobalModal>
      <PageHeader
        addbtn={false}
        heading="Upgrade Subscription Request"
        Btntitle="Add User Subscription"
        modal={() => {
          setIsShowModal((prev) => {
            return {
              ...prev,
              status: true,
              data: null,
              viewStatus: false,
            };
          });
        }}
        onFilterbutton={() => {
          setShowFilterOption((pre) => !pre);
        }}
      />
      {isShowModal?.status && (
        <GlobalModal
          size="lg"
          modalStyle="globalModalmaxWidth"
          isVisible={isShowModal.status}
          setIsVisible={() => {
            setIsShowModal((prev) => {
              return {
                ...prev,
                status: true,
              };
            });
          }}
        >
          <AddSubcription
            viewStatus={isShowModal?.viewStatus}
            listApiCall={(value) => {
              if (value === "update") {
                handleGetSubcriptionList(page);
                return;
              }
              handleGetSubcriptionList();
            }}
            editData={isShowModal?.data || null}
            onClose={() => {
              setIsShowModal((prev) => {
                return {
                  ...prev,
                  status: false,
                };
              });
            }}
            modalClose={() => {
              setIsShowModal((prev) => {
                return {
                  ...prev,
                  status: false,
                };
              });
            }}
            projectList={projectList}
            type="user"
          />
        </GlobalModal>
      )}

      {/* {showFilterOption ? (
       
      ) : null} */}
      <AccordionContent filterStat={showFilterOption}>
        <Grid>
          <Grid className={classes.serachContainer}>
            <Grid container spacing={2}>
              <Grid className="fieldBr8" item lg={2} md={4} sm={6} xs={12}>
                <TextInputBox
                  // requiredText={getview ? null : "*"}
                  textLength={50}
                  // readOnly={getview}
                  name="Company_Name"
                  title="Company Name"
                  value={values.Company_Name}
                  placeHolder="Enter Company Name"
                  onChangeText={handleChange("Company_Name")}
                  // onBlur={() => {
                  //   try {
                  //     validationSchema.validateSyncAt(
                  //       "Company_Name",
                  //       values.Company_Name
                  //     );
                  //   } catch (error) {
                  //     if (error instanceof Error) {
                  //       setFieldTouched("Company_Name", true);
                  //       setFieldError("Company_Name", error.message);
                  //     }
                  //   }
                  // }}
                  // errorText={
                  //   !getview && touched.Company_Name && errors.Company_Name
                  //     ? errors.Company_Name
                  //     : ""
                  // }
                />
              </Grid>
              <Grid className="fieldBr8" item lg={2} md={4} sm={6} xs={12}>
                <TextInputBox
                  name="Organization"
                  textLength={50}
                  title="Organization"
                  value={values.organization}
                  placeHolder="Enter Organization"
                  onChangeText={handleChange("organization")}
                  onBlur={handleBlur("organization")}
                />
                {/* <TextInputBox
                  title="Organization"
                  onChangeText={() => {}}
                  textOnly
                />
                <Autocomplete
                  // disableCloseOnSelect
                  options={organizationList}
                  value={values.organization}
                  // className={styles.placeHolder}
                  onChange={(e, val) => {
                    setFieldValue("organization", val);
                  }}
                  getOptionLabel={(option) => option.orgName}
                  isOptionEqualToValue={(option, value) =>
                    option.orgId === value.orgId
                  }
                  renderInput={(params) => {
                    return (
                      <TextField
                        placeholder={values?.organization ? "" : "none"}
                        {...params}
                      />
                    );
                  }}
                /> */}
              </Grid>
              <Grid item lg={2} md={4} sm={6} xs={12} className="fieldBr8 ">
                <TextInputBox
                  title="Project"
                  // requiredText={"*"}
                  onChangeText={() => {}}
                  textOnly
                />
                <Autocomplete
                  // className={styles.placeHolder}
                  options={projectList}
                  value={values.project}
                  onChange={(e, val) => {
                    setFieldValue("project", val);
                  }}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => {
                    return (
                      <TextField
                        placeholder={values?.project ? "" : "none"}
                        {...params}
                      />
                    );
                  }}
                  PaperComponent={(props) => {
                    return (
                      <Paper {...props} className={muistyle.customSelect} />
                    );
                  }}
                />
              </Grid>
              <Grid className="fieldBr8" item lg={2} md={4} sm={6} xs={12}>
                <TextInputBox
                  textLength={10}
                  // infotxt={"number only"}
                  name="Mobile_No"
                  title="Mobile No"
                  value={values.Mobile_No}
                  placeHolder="Enter Mobile No"
                  onChangeText={(text) => {
                    const numericText = text.replace(/[^0-9]/g, "");
                    handleChange("Mobile_No")(numericText);
                  }}
                />
              </Grid>
              <Grid className="fieldBr8" item lg={2} md={4} sm={6} xs={12}>
                <TextInputBox
                  textLength={50}
                  name="Email"
                  title="Email"
                  value={values.Email}
                  placeHolder="Enter Email"
                  onChangeText={handleChange("Email")}
                />
              </Grid>
            </Grid>
            <SearchAndClearbutton
              handleTableSearch={handleTableSearch}
              handleTableSearchClear={handleTableSearchClear}
              disabled={!isFormDirty(initialValues, values)}
            />
            {/* <Grid paddingTop={1}>
                <SearchAndClearbutton
                  handleTableSearch={handleTableSearch}
                  handleTableSearchClear={handleTableSearchClear}
                />
              </Grid> */}
          </Grid>
        </Grid>
      </AccordionContent>
      <div className={`table-responsive ${classes.AllTable}`}>
        <Box>
          <TableContainer>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>S.No</TableCell>
                  <TableCell>Requested Date</TableCell>
                  <TableCell>Organization Name</TableCell>
                  <TableCell>Organization Code</TableCell>
                  <TableCell>Machine Count</TableCell>
                  <TableCell>Existing License</TableCell>
                  <TableCell>Organization Phone</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {subcriptionList?.data?.items?.length === 0 ? (
                  <>
                    {noDataLoader ? (
                      <TableRow>
                        <TableCell className={classes.noData} colSpan={5}>
                          <NodataLoader />
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableRow>
                        <TableCell colSpan={8}>
                          <div className={classes.nodatafound}>
                            <img
                              src={Inbox}
                              alt="inbox"
                              width={100}
                              height={100}
                            />
                            <p>No data found</p>
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                ) : (
                  <>
                    {noDataLoader ? (
                      <TableRowsLoader columnNum={6} rowsNum={10} />
                    ) : subcriptionList?.data?.items?.length === 0 ||
                      !subcriptionList ? (
                      <TableRow>
                        <TableCell colSpan={9}>
                          <div className={classes.nodatafound}>
                            <img
                              src={Inbox}
                              alt="inbox"
                              width={100}
                              height={100}
                            />
                            <p>No data found</p>
                          </div>
                        </TableCell>
                      </TableRow>
                    ) : (
                      subcriptionList?.data?.items?.map(
                        (
                          subcription: subcriptionListDataItemsProps,
                          index: number
                        ) => (
                          <TableRow key={index}>
                            <TableCell>
                              {(subcriptionList?.data?.page - 1) *
                                subcriptionList?.data?.size +
                                index +
                                1}
                            </TableCell>
                            <TableCell>
                              {subcription?.requested_date === null
                                ? "-"
                                : moment(subcription?.requested_date).format(
                                    "YYYY-MM-DD"
                                  )}
                            </TableCell>
                            <TableCell>
                              {subcription?.organization_name === null
                                ? "-"
                                : subcription?.organization_name}
                            </TableCell>
                            <TableCell>
                              {subcription?.organization_code === null
                                ? "-"
                                : subcription?.organization_code}
                            </TableCell>
                            <TableCell>
                              {subcription?.machine_count === null
                                ? "-"
                                : subcription?.machine_count}
                            </TableCell>
                            <TableCell>
                              {subcription?.licence_remaining === null
                                ? "-"
                                : subcription?.licence_remaining}
                            </TableCell>
                            {/* <TableCell>
                              {subcription?.duration === null
                                ? "-"
                                : subcription?.duration}
                            </TableCell> */}
                            <TableCell>
                              {subcription?.organization_phone
                                ? subcription?.organization_phone
                                : "-"}
                            </TableCell>

                            <TableCell>
                              <div className={classes.icons}>
                                {/* <CustomButton
                                  title="Edit"
                                  onButtonPress={() => {
                                    setEditCost({
                                      statue: true,
                                    });
                                    // handleSubScriptionResponse(
                                    //   subcription.req_id,
                                    //   2
                                    // );
                                  }}
                                  customButtonStyle={{
                                    // width: "20px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontSize: "13px",
                                    height: "25px",
                                    width: "88px",
                                    backgroundColor: "Gray",
                                  }}
                                /> */}
                                <Tooltip title="Accept" placement="top">
                                  <img
                                    src={AcceptIcon}
                                    onClick={() => {
                                      setSubscriptionUpdateModal({
                                        status: true,
                                        // @ts-ignore
                                        data: subcription,
                                      });
                                    }}
                                  />
                                </Tooltip>
                                <Tooltip title="Decline" placement="top">
                                  <img
                                    src={CancelIcon}
                                    onClick={() => {
                                      setDeclineConfirmationModal(
                                        (prev: any) => {
                                          return {
                                            ...prev,
                                            status: true,
                                            data: subcription,
                                          };
                                        }
                                      );
                                      // handleSubScriptionResponse(
                                      //   subcription.req_id,
                                      //   2
                                      // );
                                    }}
                                  />
                                </Tooltip>
                                {/* <CustomButton
                                  title="Accept"
                                  onButtonPress={() => {
                                    setSubscriptionUpdateModal({
                                      status: true,
                                      // @ts-ignore
                                      data: subcription.req_id,
                                    });
                                    // handleSubScriptionResponse(
                                    //   subcription.req_id,
                                    //   1
                                    // );
                                  }}
                                  customButtonStyle={{
                                    // width: "20px",

                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontSize: "13px",
                                    height: "25px",
                                    width: "88px",
                                    backgroundColor: "green",
                                  }}
                                /> */}
                                {/* <CustomButton
                                  title="Decline"
                                  onButtonPress={() => {
                                    handleSubScriptionResponse(
                                      subcription.req_id,
                                      2
                                    );
                                  }}
                                  customButtonStyle={{
                                    // width: "20px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontSize: "13px",
                                    height: "25px",
                                    width: "88px",
                                    backgroundColor: "red",
                                  }}
                                /> */}

                                {/* <Tooltip title="View" placement="top">
                                  <img
                                    src={view}
                                    alt="View"
                                    onClick={() =>
                                      setIsShowModal((prev) => {
                                        return {
                                          ...prev,
                                          status: true,
                                          data: subcription,
                                          viewStatus: true,
                                        };
                                      })
                                    }
                                  />
                                </Tooltip>
                                <Tooltip title="Edit" placement="top">
                                  <img
                                    src={edit}
                                    onClick={() => {
                                      setIsShowModal((prev) => {
                                        return {
                                          ...prev,
                                          status: true,
                                          data: subcription,
                                          viewStatus: false,
                                        };
                                      });
                                    }}
                                    alt="Edit"
                                  />
                                </Tooltip>
                                <Tooltip title="Delete" placement="top">
                                  <img
                                    src={delete_icon}
                                    alt="Delete"
                                    onClick={() =>
                                      setdeleteModal((prev) => {
                                        return {
                                          ...prev,
                                          modal: true,
                                          id: subcription.id.toString(),
                                        };
                                      })
                                    }
                                  />
                                </Tooltip> */}
                              </div>
                            </TableCell>
                          </TableRow>
                        )
                      )
                    )}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </div>
      {subcriptionList?.data?.items?.length ? (
        <CustomPaginationwithdropdownpagination
          onChangePage={(page: number) => {
            handleGetSubcriptionList(page, pageSize);
            setPage(page);
          }}
          onChangePagerow={(PageSize: number) => {
            if (page * PageSize > subcriptionList?.data?.totalCount) {
              handleGetSubcriptionList(1, PageSize);
              setPageSize(PageSize);
              return;
            }
            handleGetSubcriptionList(page, PageSize);
            setPageSize(PageSize);
          }}
          count={subcriptionList?.data?.totalCount}
          pages={page}
          pageCount={subcriptionList?.data?.total_page}
          handleFunction={(selected: number) => {
            setPage(selected);
            handleGetSubcriptionList(selected, pageSize);
          }}
          labelRowsPerPage={"Devices Per Page"}
          rowsperpage={pageSize}
          totalData={subcriptionList?.data}
        />
      ) : null}
    </>
  );
}
