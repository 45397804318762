import { RouterProvider, createHashRouter } from "react-router-dom";
import { Toaster } from "react-hot-toast";

import Instance from "./Routing/Instance";
import { Indexroute } from "./Routing/index";
import { createTheme, ThemeProvider } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#F25922",
    },
    secondary: {
      main: "#1A2C41",
    },
  },
});

function App() {
  const router = createHashRouter(Indexroute, { basename: "/" });
  const { REACT_APP_BASEURL } = process.env;

  return (
    <>
      <Toaster />
      <ThemeProvider theme={theme}>
        <RouterProvider router={router} />
      </ThemeProvider>
      <Instance />
    </>
  );
}

export default App;
