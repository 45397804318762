import React, { useState } from "react";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { useFormik } from "formik";

import { createmainmodel, updatemainmodel } from "../Services/Services";
import { getCatchMsg } from "../Utility/GeneralUtils";
import { Loader, TextInputBox, CustomButton } from "../Components/index";
import ModalHeader from "./ModalHeader";
import classes from "./Modal.module.css";
import { addModelType } from "./Types";
import UpdateDeleteActions from "../Components/UpdateDeleteActions";
import { useDispatch } from "react-redux";
import { openLoader } from "../Store/Reducers/LoaderSlice";

const validationSchema = Yup.object({
  model_name: Yup.string()
    .required("name is required")
    .trim("Remove leading and trailing spaces")
    .strict(true),
  description: Yup.string()
    .trim("Remove leading and trailing spaces")
    .strict(true),
});

function Createmainmodel({
  onClose,
  editData,
  listmainmodelapicall,
  modalClose,
  getView,
}: addModelType) {
  const dispatch = useDispatch();
  const { handleChange, handleSubmit, handleBlur, values, errors, touched } =
    useFormik({
      initialValues: {
        model_name: editData?.name || "",
        description: editData?.description || "",
      },
      validationSchema: validationSchema,
      onSubmit: () => {
        if (editData) {
          handleupdatemainmodel();
        } else {
          handleCreateMainModel();
        }
      },
    });

  const handleCreateMainModel = () => {
    dispatch(openLoader(true));
    let formData = new FormData();
    formData.append("name", values.model_name);
    formData.append("description", values.description);
    createmainmodel(formData)
      .then((res) => {
        if (res?.data?.status === 1) {
          modalClose();
          listmainmodelapicall("add");
          toast.success(res?.data?.msg);
        } else if (res?.data?.status === 0) {
          toast.error(res?.data?.msg);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        dispatch(openLoader(false));
      });
  };

  const handleupdatemainmodel = () => {
    dispatch(openLoader(true));
    let formdata = new FormData();
    if (editData) {
      formdata.append("mainModelId", editData.id.toString());
    }
    formdata.append("name", values?.model_name);
    formdata.append("description", values?.description);
    updatemainmodel(formdata)
      .then((res) => {
        if (res?.data?.status === 1) {
          modalClose();
          listmainmodelapicall("update");
          toast.success(res?.data?.msg);
        } else if (res?.data?.status === 0) {
          toast.error(res?.data?.msg);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        dispatch(openLoader(false));
      });
  };

  return (
    <>
      <ModalHeader
        onClose={onClose}
        heading={
          getView
            ? "View Model"
            : editData === null
              ? "Add Model"
              : "Edit Model"
        }
      />
      <div className={classes.addModel}>
        {!getView ? (
          <div className="row">
            <div className="col-lg-12">
              <TextInputBox
                readOnly={getView}
                requiredText="*"
                title="Model name"
                textLength={50}
                placeHolder="Enter the model name"
                name="model_name"
                onChangeText={handleChange("model_name")}
                value={values.model_name}
                errorText={
                  !getView && touched.model_name && errors.model_name
                    ? errors.model_name
                    : ""
                }
                onBlur={handleBlur("model_title")}
                inputBoxStyle={{
                  backgroundColor: getView ? "silver" : "white",
                  cursor: getView ? "not-allowed" : "",
                }}
              />
            </div>

            <div className={`col-lg-12 mt-3 ${classes.AddModelDesc}`}>
              <p>Description</p>
              <textarea
                readOnly={getView}
                className={`${classes.text_area}`}
                value={values.description}
                onChange={handleChange("description")}
                name="description"
                maxLength={300}
                placeholder="Enter description"
                onBlur={handleBlur("description")}
                style={{
                  backgroundColor: getView ? "silver" : "white",
                  cursor: getView ? "not-allowed" : "",
                }}
              ></textarea>
              <p className={classes.errorTxt}>
                {!getView && touched.description && errors.description
                  ? errors.description
                  : ""}
              </p>
            </div>
          </div>
        ) : (
          <div className={classes.add_mainmodel}>
            <ul>
              <div className={classes.heading}>
                <p>Name</p>:
                <span>{editData && editData?.name ? editData?.name : "-"}</span>
              </div>
              <div className={classes.heading}>
                <p>Decription</p>:
                <span>
                  {editData && editData?.description
                    ? editData?.description
                    : "-"}
                </span>
              </div>
            </ul>
          </div>
        )}

        {getView ? null : (
          <>
            <div className={classes.modalbtn}>
              {editData ? (
                <UpdateDeleteActions
                  onCancelPress={() => modalClose()}
                  onUpdatePress={handleSubmit}
                />
              ) : (
                <div className="col-lg-3">
                  <CustomButton title="Submit" onButtonPress={handleSubmit} />
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default Createmainmodel;
