import React, { useContext, useEffect, useRef, useState } from "react";
import { Autocomplete, Button, Paper, Popper, TextField } from "@mui/material";

import { CustomDropDownProps } from "./Types";
import classes from "./CustomStyle.module.css";
import { useNavigate } from "react-router-dom";
import { muiStyles } from "../Utility/Constants";
import { ServiceHistoryContext } from "../Views/HomeScreens/ServiceHistoryApp";
function CustomDropDown({
  title = "",
  //@ts-ignore
  items = [],
  onSelectedItem,
  value = "",
  fieldName,
  anotherFieldName,
  editName = "",
  errorText = "",
  onBlur,
  required = "",
  isEnableInput = false,
  additionalInputValue = "",
  setAddtionalInputValue,
  otherDesigantion,
  addBtn = false,
  onClick,
  disabled = false,
  disableClearable = true,
  errStyle,
  readOnly = false,
  scrollPosition,
  pagination = false,
  cost,
  duration,
  monthCost,
  yearCost,
  disableNoneOption = false,
  placeholder,
}: CustomDropDownProps) {
  const styles = muiStyles();
  const scrollRef = useRef(null);
  const navigate = useNavigate();
  const { disableScroll, hasNext, loading } = useContext(ServiceHistoryContext);
  const [scrollElementTop, setscrollElementTop] = useState(0);
  const getItem = () => {
    if (items?.length > 0) {
      let values = items?.map((ele: any) => ({
        id: ele?.[anotherFieldName],
        label: ele?.[fieldName],
        cost: ele?.[cost],
        duration: ele?.[duration],
        monthcost: ele?.[monthCost],
        yearcost: ele?.[yearCost],
      }));
      return disableNoneOption
        ? [...values]
        : [{ id: "", label: "-- None --" }, ...values];
    }
    return [];
  };
  useEffect(() => {
    if (scrollElementTop && pagination) {
      document.getElementById("scrollable")?.scrollTo({
        top: scrollElementTop,
      });
    }
  }, [items]);

  return (
    <div>
      <div className={classes.dropdown}>
        <p className={classes.title}>
          {title} <span className={classes.star}>{required}</span>
        </p>
        <Autocomplete
          disabled={disabled}
          // disablePortal
          id="combo-box-demo"
          size="small"
          disableClearable={disableClearable}
          onBlur={onBlur}
          // onClick={onClick}
          className={`${classes.dropdownbtn} dropdownbtn`}
          filterSelectedOptions
          // @ts-ignore
          value={
            disableNoneOption
              ? { label: editName ? editName : "" }
              : { id: "", label: editName !== "" ? editName : "-- None --" }
          }
          options={getItem()}
          onChange={onSelectedItem}
          renderInput={(params) => (
            <TextField
              {...params}
              className={styles.datepickerRadius}
              placeholder={placeholder}
            />
          )}
          ListboxProps={{
            style: {
              wordBreak: "break-word",
            },

            ref: scrollRef,
            id: "scrollable",
            onScroll: (e) => {
              const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;
              if (
                scrollTop + clientHeight === scrollHeight &&
                hasNext &&
                pagination
              ) {
                if (!disableScroll) {
                  setscrollElementTop(scrollTop);
                  hasNext();
                }
              }
            },
          }}
          readOnly={readOnly}
          isOptionEqualToValue={(option, value) => {
            return option.label === value?.label;
          }}
          PaperComponent={({ children }) => {
            return (
              <Paper className={styles.customSelect}>
                {children}
                {loading && (
                  <p
                    style={{
                      padding: "0px 15px",
                    }}
                  >
                    Loading...
                  </p>
                )}
              </Paper>
            );
          }}
          renderOption={(props, option) => {
            return (
              <li {...props} className={classes.addBtn}>
                {addBtn && !option.id ? (
                  <button
                    onClick={() =>
                      navigate("/dashboard/management/model_management", {
                        state: {
                          showmodel: true,
                        },
                      })
                    }
                  >
                    Add Model
                  </button>
                ) : (
                  option.label
                )}
              </li>
            );
          }}
        />
      </div>
      {editName === "Other" && isEnableInput && (
        <div style={{ marginTop: "20px" }} className={classes.inputBox}>
          <input
            type="text"
            id="additionalInput"
            placeholder="Please enter your designation"
            value={additionalInputValue}
            onChange={(event) => {
              if (setAddtionalInputValue)
                setAddtionalInputValue(event.target.value);
            }}
          />
        </div>
      )}
      {errorText ? (
        <p style={errStyle} className={classes.errorTxt}>
          {errorText.toString()}
        </p>
      ) : null}
    </div>
  );
}

export default CustomDropDown;
