import axios from "./Axios";

// Login Services
export const loginService = (data: FormData) => {
  return axios.post("/login/access-token", data);
};

// Device Management
export const addDevice = (data: FormData) => {
  return axios.post("/masters/add_device", data);
};
export const deviceProtocol = (data: FormData) => {
  return axios.post("/masters/device_mqtt_change", data);
};
export const deleteDevice = (data: FormData) => {
  return axios.post("/masters/delete_device", data);
};
export const activeInactiveDevice = (data: FormData) => {
  return axios.post("/masters/activeInactiveDevice", data);
};
export const viewDeviceBoardMap = (data: FormData) => {
  return axios.post("/deviceBoardMap/viewDeviceBoardMap", data);
};
export const updateDevice = (data: FormData) => {
  return axios.post("/masters/update_device", data);
};
export const getSubcriptiondropdown = () => {
  return axios.post("/dropdown/subscriptionDropdown");
};
export const getComponentdropdown = (data: FormData) => {
  return axios.post("/dropdown/componentsDropdown", data);
};
export const createBoardMapComponent = (data: any) => {
  return axios.post("/deviceBoardMap/createDeviceBoardMap", data);
};
export const getDevicebatchdropdown = () => {
  return axios.post("/dropdown/deviceBatchDropDown");
};
export const listDeviceMachine = (
  page: number,
  size: number,
  data: FormData
) => {
  return axios.post(`/masters/list_device?page=${page}&size=${size}`, data);
};
export const viewDevice = (data: FormData) => {
  return axios.post("/mastersview_device", data);
};

//model mamagement
export const listModelMangement = (
  page: number,
  size: number,
  data: FormData
) => {
  return axios.post(`/masters/list_model?page=${page}&size=${size}`, data);
};
export const deleteModel = (data: FormData) => {
  return axios.post("/masters/delete_model", data);
};
export const createModel = (data: FormData) => {
  return axios.post("/masters/create_model", data);
};
export const updateModel = (data: FormData) => {
  return axios.post("/masters/update_model", data);
};
export const viewModel = (data: FormData) => {
  return axios.post("/masters/view_model", data);
};

//batch mamagement
export const listBatchMangement = (
  page: number,
  size: number,
  data: FormData
) => {
  return axios.post(`/masters/list_batch?page=${page}&size=${size}`, data);
};
export const deleteBatch = (data: FormData) => {
  return axios.post("/masters/delete_batch", data);
};
export const createBatch = (data: FormData) => {
  return axios.post("/masters/create_batch", data);
};
export const updateBatch = (data: FormData) => {
  return axios.post("/masters/update_batch", data);
};
export const viewBatch = (data: FormData) => {
  return axios.post("/masters/view_batch", data);
};
export const getModelDropDown = () => {
  return axios.post("/dropdown/model_dropdown");
};
export const activeInactiveBatch = (data: FormData) => {
  return axios.post("/masters/active_inactive_batch", data);
};

// server mamagement
export const listServerMangement = (
  page: number,
  size: number,
  data: FormData
) => {
  return axios.post(`/masters/list_server?page=${page}&size=${size}`, data);
};

export const deleteServer = (data: FormData) => {
  return axios.post("/masters/delete_server", data);
};
export const createServer = (data: FormData) => {
  return axios.post("/masters/create_server", data);
};
export const updateServer = (data: FormData) => {
  return axios.post("/masters/update_server", data);
};
export const viewServer = (data: FormData) => {
  return axios.post("/masters/view_server", data);
};
export const activeInactiveServer = (data: FormData) => {
  return axios.post("/masters/active_inactive_server", data);
};

// users
export const listUsers = (page: number, size: number, data: FormData) => {
  return axios.post(`/user/list_user?page=${page}&size=${size}`, data);
};
export const deleteUser = (data: FormData) => {
  return axios.post("/user/delete_user", data);
};
export const createUser = (data: FormData) => {
  return axios.post("/user/create_user", data);
};
export const updateUser = (data: FormData) => {
  return axios.post("/user/update_user", data);
};
export const viewUser = (data: FormData) => {
  return axios.post("/user/view_user", data);
};
export const getDesignationDropdown = () => {
  return axios.post("/dropdown/designation_dropdown");
};
export const edituserpassword = (data: FormData) => {
  return axios.post("/user/editPassword", data);
};

// Organization
export const listOrganization = (
  page: number,
  size: number,
  data: FormData
) => {
  return axios.post(
    `/masters/list_organization?page=${page}&size=${size}`,
    data
  );
};
export const createOrganization = (data: FormData) => {
  return axios.post("/masters/create_organization", data);
};

export const OrganizationTImerChange = (data: FormData) => {
  return axios.post("/masters/org_timer_change", data);
};

export const DeleteOrganization = (data: FormData) => {
  return axios.post("/masters/delete_organization", data);
};
export const viewOrganization = (data: FormData) => {
  return axios.post("/masters/view_organization", data);
};
export const updateOrganization = (data: FormData) => {
  return axios.post("/masters/update_organization", data);
};

export const getServerDropdown = (data: FormData) => {
  return axios.post("/dropdown/server_dropdown", data);
};
export const activeInactiveOrganization = (data: FormData) => {
  return axios.post("/masters/activeInactiveOrganization", data);
};

//Dashboard
export const getPieChart = (page: number, size: number, data: FormData) => {
  return axios.post(`/dashboard/pieChart?page=${page}&size=${size}`, data);
};
export const getBarChart = (data: FormData) => {
  return axios.post("/dashboard/barChart", data);
};
export const getProjectWiseReportServices = (data: FormData) => {
  return axios.post("/dashboard/barChartProjectWise", data);
};
export const getCountsDashboard = () => {
  return axios.post("/dashboard/dashboard");
};

//View Profile

export const ViewProfile = () => {
  return axios.post("/masters/viewProfile");
};

export const EditProfile = (data: FormData) => {
  return axios.post("/masterseditProfile", data);
};

//Board Category

export const CreateCategory = (data: FormData) => {
  return axios.post("/category_type/createCategory", data);
};
export const UpdateCategory = (data: FormData) => {
  return axios.post("/category_type/updateCategory", data);
};
export const ListBoardCategory = (
  page: number,
  size: number,
  data: FormData
) => {
  return axios.post(
    `/category_type/listCategoryType?page=${page}&size=${size}`,
    data
  );
};
export const DeleteCategory = (data: FormData) => {
  return axios.post("/category_type/deleteCategory", data);
};

//Board Batch

export const getlistSupplier = () => {
  return axios.post("/dropdown/listSupplier");
};
export const getlistCategory = () => {
  return axios.post("/dropdown/listCategory");
};

export const ListBoardBatch = (page: number, size: number, data: FormData) => {
  return axios.post(
    `/boardBatch/listBoardBatch?page=${page}&size=${size}`,
    data
  );
};

export const createBoardBatch = (data: FormData) => {
  return axios.post("/boardBatch/createBoardBatch", data);
};
export const UpdateBoardBatch = (data: FormData) => {
  return axios.post("/boardBatch/updateBoardBatch", data);
};
export const BoardBatchDetails = (data: FormData) => {
  return axios.post("/boardBatch/viewBoardBatch", data);
};

export const BoardBatchComponentsList = (
  page: number,
  size: number,
  data: FormData
) => {
  return axios.post(
    `/boardBatch/componentsList?page=${page}&size=${size}`,
    data
  );
};

export const DeleteComponentsList = (data: FormData) => {
  return axios.post("/boardBatch/deleteComponent", data);
};
export const mainModelDropdownList = () => {
  return axios.post("/dropdown/mainModelDropDown");
};
export const subModelDropdownList = (data: FormData) => {
  return axios.post("/dropdown/subModelDropDown", data);
};
export const UpdateComponentsList = (data: FormData) => {
  return axios.post("/boardBatch/updateComponents", data);
};

//batch management device
export const ListDeviceBoardBatch = (
  page: number,
  size: number,
  data: FormData
) => {
  return axios.post(
    `/device_batch/listDeviceBatch?page=${page}&size=${size}`,
    data
  );
};
export const getDeviceModelDropdown = () => {
  return axios.post("/dropdown/DeviceModelDropdown");
};
export const activeInactiveDeviceBoardBatch = (data: FormData) => {
  return axios.post("/device_batch/activeInactiveBatch", data);
};

export const DeleteDeviceBatch = (data: FormData) => {
  return axios.post("/device_batch/deleteDeviceBatch", data);
};
export const printBatchComponent = (data: FormData) => {
  return axios.post("/boardBatch/printLabel", data);
};
//device borad batch
export const listBoardBatchDropDown = (data: FormData) => {
  return axios.post("/dropdown/listBoardBatch", data);
};
export const addDeviceBoradBatch = (data: any) => {
  return axios.post("/device_batch/createDeviceBatch", data);
};
export const viewDeviceBoradBatch = (data: FormData) => {
  return axios.post("/device_batch/viewDeviceBatch", data);
};
export const firmWareDropDown = (data?: FormData) => {
  return axios.post("/dropdown/firmwareDropDown", data);
};
export const questionDropDown = () => {
  return axios.post("/dropdown/QuestionDropDown");
};
export const listPartnumberdropdowm = (data: FormData) => {
  return axios.post("/dropdown/partNumberDropDown", data);
};
//firmwaremanagement
export const organizationDropDown = (data?: FormData) => {
  return axios.post("/dropdown/organizationDropDown", data);
};
export const getDeviceModelDropDown = () => {
  return axios.post("/dropdown/DeviceModelDropdown");
};

export const createFirmWareMaster = (data: FormData) => {
  return axios.post("firmwaremangement/add_frimware", data);
};

export const createFirmwareUpload = (data: FormData) => {
  return axios.post("firmware_data/firmware_upload", data);
};

export const viewFirmWareManageMent = (data: FormData) => {
  return axios.post("/firmwaremangement/viewFirmware", data);
};
export const updateFirmWareManageMent = (data: FormData) => {
  return axios.post("/firmwaremangement/editUploadFirmWare", data);
};
export const deleteFirmWareManageMent = (data: FormData) => {
  return axios.post("/firmwaremangement/deleteFirmware", data);
};
export const listFirmWare = (page: number, size: number, data: FormData) => {
  return axios.post(
    `/firmware_data/list_FirmwareData?page=${page}&size=${size}`,
    data
  );
};

export const listFirmWareUpload = (
  page: number,
  size: number,
  data: FormData
) => {
  return axios.post(
    `/firmware_data/list_firmware_upload?page=${page}&size=${size}`,
    data
  );
};

//suppliers

export const listSuppliers = (page: number, size: number, data: FormData) => {
  return axios.post(`/suppliers/listSupplier?page=${page}&size=${size}`, data);
};
export const deleteSupplier = (data: FormData) => {
  return axios.post("/suppliers/deleteSupplier", data);
};
export const createSupplier = (data: FormData) => {
  return axios.post("/suppliers/createSuppliers", data);
};
export const updateSupplier = (data: FormData) => {
  return axios.post("/suppliers/updateSupplier", data);
};

//main model

export const mainmodellist = (page: number, size: number, data: FormData) => {
  return axios.post(`/modeldata/listMainModel?page=${page}&size=${size}`, data);
};

export const deletemainmodel = (data: FormData) => {
  return axios.post("/modeldata/deleteMainModel", data);
};

export const createmainmodel = (data: FormData) => {
  return axios.post("/modeldata/createMainModel", data);
};
export const updatemainmodel = (data: FormData) => {
  return axios.post("/modeldata/updateMainModel", data);
};

//sub Model

export const submodelList = (page: number, size: number, data: FormData) => {
  return axios.post(`/modeldata/listSubModel?page=${page}&size=${size}`, data);
};
export const deletesubmodel = (data: FormData) => {
  return axios.post("/modeldata/deleteSubModel", data);
};
export const getmainModelDropdown = () => {
  return axios.post("/dropdown/mainModelDropDown");
};
export const createSubModal = (data: FormData) => {
  return axios.post("/modeldata/createSubModel", data);
};
export const EditSubModal = (data: FormData) => {
  return axios.post("/modeldata/editSubModel", data);
};
//subcriptions
export const subcriptionsList = (
  page: number,
  size: number,
  data: FormData
) => {
  return axios.post(
    `/subscription/listSubscription?page=${page}&size=${size}`,
    data
  );
};
export const createSubcription = (data: FormData) => {
  return axios.post("/subscription/createSubscription", data);
};
export const updateSubcription = (data: FormData) => {
  return axios.post("/subscription/updateSubscription", data);
};
export const deleteSubcription = (data: FormData) => {
  return axios.post("/subscription/deleteSubscription", data);
};

//Categorization
export const CategorizationList = (
  page: number,
  size: number,
  data: FormData
) => {
  return axios.post(
    `/categorization/listCategorization?page=${page}&size=${size}`,
    data
  );
};
export const addCategorization = (data: any) => {
  return axios.post("/categorization/createCategorization", data);
};
export const viewCategorization = (data: FormData) => {
  return axios.post("/categorization/viewCategorization", data);
};
export const updateCategorization = (data: any) => {
  return axios.post("/categorization/updateCategorization", data);
};
export const deleteCategorization = (data: FormData) => {
  return axios.post("/categorization/deletedCategorization", data);
};
//board specification defginition

export const BoardSpecifiacationList = (
  page: number,
  size: number,
  data: FormData
) => {
  return axios.post(
    `/categoryQuery/listCategoryQuestion?page=${page}&size=${size}`,
    data
  );
};

export const createBoardSpecification = (data: any) => {
  return axios.post("/categoryQuery/createCategoryQuery", data);
};
export const deleteBoardSpecification = (data: FormData) => {
  return axios.post("/categoryQuery/deleteQuery", data);
};
export const ViewBoardSpecifiacationList = (
  page: number,
  size: number,
  data: FormData
) => {
  return axios.post(
    `/categoryQuery/viewCategoryQuery?page=${page}&size=${size}`,
    data
  );
};
export const updateInnerQuestion = (data: FormData) => {
  return axios.post("/categoryQuery/updateInnerQuery", data);
};
export const deleteInnerQuestion = (data: FormData) => {
  return axios.post("/categoryQuery/deleteInnerQuery", data);
};
// part management

export const PartNumberTableList = (
  page: number,
  size: number,
  data: FormData
) => {
  return axios.post(
    `/part_management/listPartNumber?page=${page}&size=${size}`,
    data
  );
};
export const createPartNumber = (data: any) => {
  return axios.post("/part_management/createPartNumber", data);
};
export const PartNumberListQueryData = (
  page: number,
  size: number,
  data: FormData
) => {
  return axios.post(
    `/part_management/listQuestionSet?page=${page}&size=${size}`,
    data
  );
};
export const categarizationDropDown = () => {
  return axios.post("/dropdown/categorizationDropDown");
};
export const categarizationFieldDropDown = (data: FormData) => {
  return axios.post("/dropdown/categorizationFieldDropDown", data);
};
export const addnewPartNumberQuery = (data: FormData) => {
  return axios.post("/part_management/addNewQueryData", data);
};
export const deletePart = (data: FormData) => {
  return axios.post("/part_management/DeletePartManagement", data);
};
export const updatePartName = (data: FormData) => {
  return axios.post("/part_management/updatePartManagement", data);
};
export const activeInactiveComponent = (data: FormData) => {
  return axios.post("/boardBatch/activeInactiveComponent", data);
};
//designation
export const desigantionsList = (
  page: number,
  size: number,
  data: FormData
) => {
  return axios.post(`/user/listDesignation?page=${page}&size=${size}`, data);
};
export const createDesiganation = (data: FormData) => {
  return axios.post("/user/createDesignation", data);
};
export const updateDesignation = (data: FormData) => {
  return axios.post("/user/editDesignation", data);
};
export const deleteDesignation = (data: FormData) => {
  return axios.post("/user/designationDelete", data);
};

// model managemnet

export const modelManagementTableList = (
  page: number,
  size: number,
  data: FormData
) => {
  return axios.post(`/model/listModel?page=${page}&size=${size}`, data);
};
export const createNewModel = (data: FormData) => {
  return axios.post("/model/createDeviceModel", data);
};
export const UpdateNewModel = (data: FormData) => {
  return axios.post("/model/updateModelNumber", data);
};
export const DeleteNewModel = (data: FormData) => {
  return axios.post("/model/DeleteModel", data);
};

// question managemnet

export const questionList = (page: number, size: number, data: FormData) => {
  return axios.post(
    `/question/listQuestionSet?page=${page}&size=${size}`,
    data
  );
};
export const createQuestion = (data: any) => {
  return axios.post("/question/createQuestion", data);
};
export const updateQuestionMaster = (data: FormData) => {
  return axios.post("/question/editQuestionMaster", data);
};
export const deleteQuestionMaster = (data: FormData) => {
  return axios.post("/question/deleteQuestionSet", data);
};
export const masterInnerquestionList = (
  page: number,
  size: number,
  data: FormData
) => {
  return axios.post(
    `/questionlistInnerQuestionSet?page=${page}&size=${size}`,
    data
  );
};
export const createInnerQuestion = (data: any) => {
  return axios.post("/question/AddNeWQuestions", data);
};
export const updateInnerQuestionMaster = (data: FormData) => {
  return axios.post("/question/editInnerQuestion", data);
};
export const deleteMasterInnerQuestion = (data: FormData) => {
  return axios.post("/question/deleteInnerQuestionSet", data);
};
//project management
export const getprojectmanagementlist = (
  page: number,
  size: number,
  data: FormData
) => {
  return axios.post(
    `/project_mater/listProject?page=${page}&size=${size}`,
    data
  );
};
export const addProjectManagement = (data: any) => {
  return axios.post("/project_mater/createProject", data);
};
export const updateProjectManagement = (data: any) => {
  return axios.post("/project_mater/updateProject", data);
};
export const deleteProjectManagement = (data: any) => {
  return axios.post("/project_mater/deleteProject", data);
};
export const projectdropdownlist = () => {
  return axios.post("/dropdown/dropDownProject");
};

// Service History

export const getDeviceDrodownService = (
  page: number,
  size: number,
  data: FormData
) => {
  return axios.post(`/dropdown/dropDownDevice?page=${page}&size=${size}`, data);
};

export const listServiceHistoryService = (
  page: number,
  size: number,
  data: FormData
) => {
  return axios.post(
    `service_history/listServiceHistory?page=${page}&size=${size}`,
    data
  );
};

export const addServiceHistory = (data: FormData) => {
  return axios.post("service_history/createService", data);
};
export const updateServiceHistory = (data: FormData) => {
  return axios.post("service_history/updateServiceHistory", data);
};
export const viewServiceHistory = (data: FormData) => {
  return axios.post("service_history/viewServiceHistory", data);
};
export const deleteServiceHistory = (data: FormData) => {
  return axios.post("service_history/deleteHistory", data);
};

// Service Request

export const getserviceRequestListService = (
  page: number,
  size: number,
  data: FormData
) => {
  return axios.post(
    `service_request/listServiceRequest?page=${page}&size=${size}`,
    data
  );
};

export const getUserDropdownServices = () => {
  return axios.post("/dropdown/userDropDown");
};

export const getOrganizationDetailsServices = (data: FormData) => {
  return axios.post("/service_request/getOrganizationDetails", data);
};

export const createServiceRequestServices = (data: FormData) => {
  return axios.post("/service_request/createServiceRequest", data);
};

export const fileUploadServices = (data: FormData) => {
  return axios.post("/service_request/file_upload", data);
};

export const deleteServiceRequestServices = (data: FormData) => {
  return axios.post("/service_request/deleteServiceRequest", data);
};

export const viewServiceRequestServices = (data: FormData) => {
  return axios.post("/service_request/viewServiceRequest", data);
};

export const startServiceServices = (data: FormData) => {
  return axios.post("/service_request/startService", data);
};
export const closeServiceRequestServices = (data: FormData) => {
  return axios.post("/service_request/requestClose", data);
};
// Service Task Details

export const getserviceTaskListService = (
  page: number,
  size: number,
  data: FormData
) => {
  return axios.post(
    `service_request/listServiceTask?page=${page}&size=${size}`,
    data
  );
};

export const createServiceTaskServices = (data: FormData) => {
  return axios.post("/service_request/serviceTaskCreate", data);
};
export const updateServiceTaskServices = (data: FormData) => {
  return axios.post("/service_request/editServiceTask", data);
};

// export const deletServiceTaskServices = (data: FormData) => {
//   return axios.post("/service_request/serviceTaskCreate", data);
// };

export const listAttachmentServices = (
  page: number,
  size: number,
  data: FormData
) => {
  return axios.post(
    `/service_request/listAttachments?page=${page}&size=${size}`,
    data
  );
};

export const deleteAttachmentServices = (data: FormData) => {
  return axios.post(`/service_request/deleteAttachments?`, data);
};

// Report Services

export const getMttrReportServices = (data: FormData) => {
  return axios.post("/mttrReport", data);
};

export const getMtbfReportServices = (data: FormData) => {
  return axios.post("/mtbfReport", data);
};

//  User Subscription

export const userSubscriptionListService = (
  page: number,
  size: number,
  data: FormData
) => {
  return axios.post(
    `/subscription/list_user_subscription?page=${page}&size=${size}`,
    data
  );
};

export const createUserSubscriptionService = (data: FormData) => {
  return axios.post("/subscriptioncreate_userSubscription", data);
};

export const updateUserSubscriptionService = (data: FormData) => {
  return axios.post("/subscription/update_user_subscription", data);
};

export const deleteUserSubscriptionService = (data: FormData) => {
  return axios.post("/subscription/delete_user_subscription", data);
};

export const userSubscriptionDropdownSevice = () => {
  return axios.post("/dropdown/userSubscription");
};
export const subscriptionRequest = (
  page: number,
  size: number,
  data: FormData
) => {
  return axios.post(
    `/subscription/list_SubscriptionRequest?page=${page}&size=${size}`,
    data
  );
};
export const subscriptionResponse = (data: FormData) => {
  return axios.post("subscription/subscription_response", data);
};
export const organizanitionHistoryReport = (
  page: number,
  size: number,
  data: FormData
) => {
  return axios.post(
    `/subscription/list_subscription_history?page=${page}&size=${size}`,
    data
  );
};

//MQTT
export const mqttDatalist = (page: number, size: number, data: FormData) => {
  return axios.post(
    `/mqtt_management/list_mqtt?page=${page}&size=${size}`,
    data
  );
};
export const createMqttservices = (data: FormData) => {
  return axios.post("/mqtt_management/create_mqtt", data);
};
export const updateMqttServices = (data: FormData) => {
  return axios.post("/mqtt_management/update_mqtt", data);
};
export const deleteMqttServices = (data: FormData) => {
  return axios.post("mqtt_management/delete_mqtt", data);
};
export const mqttactiveInactiveStatus = (data: FormData) => {
  return axios.post("/mqtt_management/active_inactive_mqtt", data);
};
export const viewMqttServices = (data: FormData) => {
  return axios.post("/mqtt_management/view_mqtt", data);
};
export const userSubscriptionDropdown = (data: FormData) => {
  return axios.post("/dropdown/userSubscription", data);
};
export const removeOrganzation = (data: FormData) => {
  return axios.post("/masters/remove_organization", data);
};
export const updateSubscriptionRequest = (data: FormData) => {
  return axios.post("/subscription/subscription_request_edit", data);
};
export const deviceSubscriptionDropdown = (data: FormData) => {
  return axios.post("/dropdownDeviceSubscriptionDropDown", data);
};
export const updateUserSubscriptionRequest = (data: FormData) => {
  return axios.post("/subscription/user_subscription_request_edit", data);
};

export const DevicedropdownOrg = (data?: FormData) => {
  return axios.post("/dropdown/dropdownDeviceMultiOrg", data);
};
