import { Button } from "@mui/material";
import React from "react";

export default function SearchAndClearbutton({
  handleTableSearchClear,
  handleTableSearch,
  isSearchEnable = true,
  disabled = false,
}) {
  return (
    <div style={{ display: "flex", marginBottom: "10px" }}>
      {isSearchEnable && (
        <Button
          style={{ marginRight: "10px" }}
          variant="contained"
          color="success"
          onClick={() => handleTableSearch("Enter")}
          disabled={disabled}
        >
          Search
        </Button>
      )}
      <Button
        onClick={() => handleTableSearchClear()}
        variant="contained"
        color="error"
        disabled={disabled}
      >
        Reset
      </Button>
    </div>
  );
}
