import { Close } from "@mui/icons-material";
import { Dialog, Grid } from "@mui/material";
import React from "react";

export default function ImageView({
  src,
  onclose,
}: {
  src: string;
  onclose: () => void;
}) {
  return (
    <Dialog
      open
      onClose={() => {
        onclose();
      }}
      maxWidth="lg"
    >
      <Grid className="p-3">
        <Grid
          style={{
            contain: "content",
          }}
          className="pb-2"
        >
          <Close
            style={{
              float: "right",
              cursor: "pointer",
            }}
            onClick={() => {
              onclose();
            }}
          />
        </Grid>
        <img
          style={{
            maxWidth: "100%",
          }}
          src={src}
        />
      </Grid>
    </Dialog>
  );
}
