import React, { useState, useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  Autocomplete,
  Box,
  FormControl,
  Grid,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import { makeStyles, MenuItem } from "@material-ui/core";
import { toast } from "react-hot-toast";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";

import stopwatch from "../../Assets/Icons/Png/stopwatch.png";
import classes from "../HomeScreens/Management/Management.module.css";
import edit from "../../Assets/Icons/Svg/edit.svg";
import delete_icon from "../../Assets/Icons/Svg/delete.svg";
import view from "../../Assets/Icons/Png/view.png";
import Inbox from "../../Assets/Images/Png/noData.png";
import { getCatchMsg, isFormDirty } from "../../Utility/GeneralUtils";
import PageHeader from "../MangementLayoutHeader/PageHeader";
import { useToken } from "../../Utility/StoreData";
import {
  listOrganization,
  DeleteOrganization,
  activeInactiveOrganization,
  projectdropdownlist,
  OrganizationTImerChange,
} from "../../Services/Services";
import { CreateOrganizationModal, ConfirmationModal } from "../../Modals/index";
import {
  Loader,
  TextInputBox,
  GlobalModal,
  TableRowsLoader,
  CustomSwitch,
  CustomPaginationwithdropdownpagination,
  CustomButton,
} from "../../Components/index";
import {
  OrganizationdeleteModalProps,
  ProjectDrodownProps,
} from "../../Modals/Types";
import {
  OrganizationListapiProps,
  Organizationitemprops,
  isTimeProps,
} from "./Types";
import SearchAndClearbutton from "../../Components/SearchAndClearbutton";
import PlanConfirmationModal from "../../Modals/PlanConfirmationModal";
import { openLoader } from "../../Store/Reducers/LoaderSlice";
import { useDispatch } from "react-redux";
import AccordionContent from "../../Components/AccordionContent";
import historyicon from "../../Assets/Icons/Png/clock.png";
import { muiStyles } from "../../Utility/Constants";
import ModalHeader from "../../Modals/ModalHeader";
import * as Yup from "yup";

const useStyles = makeStyles(() => ({
  switchContainer: {
    marginRight: "10px",
    display: "flex",
    alignItems: "center",
    minWidth: "90px",
    justifyContent: "center",
  },
}));

function Organization({
  serverid,
  mqtt_id,
  activestatus,
  FilterOption,
  serverScreenStatus = false,
}: {
  FilterOption?: boolean;
  serverid?: number;
  mqtt_id?: number;
  activestatus?: string;
  serverScreenStatus?: boolean;
}) {
  const muistyle = muiStyles();
  const [isShowModal, setIsShowModal] = useState<any>({
    status: false,
    data: null,
    viewstatus: false,
    viewid: "",
  });
  const [deleteModal, setdeleteModal] = useState<OrganizationdeleteModalProps>({
    modal: false,
    id: "",
  });
  const [PlanConfirmation, setPlanConfirmation] =
    useState<OrganizationdeleteModalProps>({
      modal: false,
      id: "",
    });
  const [isTime, setisTime] = useState<isTimeProps>({
    status: false,
    id: null,
  });
  const style = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showFilterOption, setShowFilterOption] = useState(false);
  const [Switches, setSwitches] = useState<boolean[]>([]);
  const [organizationList, setOrganizationList] =
    useState<OrganizationListapiProps | null>(null);
  const [noDataLoader, setnoDataLoader] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState<string>("-2");
  const [confirm, setconfirm] = useState(false);
  const [projectList, setProjectList] = useState<ProjectDrodownProps[]>([]);

  const token = useToken();
  const { state } = useLocation();

  const {
    values,
    handleChange,
    resetForm,
    setFieldValue,
    initialValues,
    errors,
    touched,
    handleSubmit,
  } = useFormik({
    initialValues: {
      Company_Name: "",
      Server_Name: "",
      Organization_Code: "",
      email: "",
      contact_no: "",
      project: null,
      time: "-1",
    },
    validationSchema: Yup.object({
      time: isTime?.status
        ? Yup.string().test("chek_timing", "Time is required", function (val) {
            if (val === "-1") {
              return false;
            }
            return true;
          })
        : Yup.string(),
    }),
    onSubmit: () => {
      hanleTimerChange();
    },
  });

  /**
   * Handles the table search functionality.
   * If the data parameter is equal to "Enter", it resets the page to 0 and calls the
   * handleListofOrganization function to fetch the list of organizations.
   * @param {string | number} data - The data to be searched.
   * @returns None
   */

  const handleTableSearch = (data: string | number) => {
    if (data === "Enter") {
      setPage(1);
      handleListofOrganization(1, pageSize, false);
    }
  };

  /**
   * Clears the table search by resetting the page number, resetting the form, and
   * triggering a new search for the list of organizations.
   * @returns None
   */
  const handleTableSearchClear = () => {
    setPage(1);
    resetForm();
    handleListofOrganization(undefined, undefined, true);
  };

  /**
   * Handles the retrieval and processing of a list of organizations based on the provided parameters.
   * @param {number} [page=1] - The page number of the list to retrieve.
   * @param {number} [size=10] - The number of items per page.
   * @param {boolean} [resetValue] - Indicates whether to reset the search values.
   * @returns None
   */

  const handleListofOrganization = (
    page: number = 1,
    size: number = 10,
    resetValue?: boolean,
    verfiedStatus: any = null
  ) => {
    setnoDataLoader(true);
    let formData = new FormData();
    if (activestatus && !activestatus?.includes("-")) {
      formData.append("inactiveOrg", activestatus.toString());
    }
    if (serverid) {
      formData.append("serverId", serverid.toString());
    }
    if (mqtt_id) {
      formData.append("mqtt_id", mqtt_id.toString());
    }
    formData.append("company_name", resetValue ? "" : values.Company_Name);
    formData.append("server_name", resetValue ? "" : values.Server_Name);
    formData.append(
      "organization_code",
      resetValue ? "" : values.Organization_Code
    );
    formData.append("email", resetValue ? "" : values.email);
    formData.append("contact_no", resetValue ? "" : values.contact_no);
    if (verfiedStatus || state?.device || state?.inactiveOrg) {
      formData.append(
        "inactiveOrg",
        state?.device
          ? state?.device
          : verfiedStatus
          ? verfiedStatus
          : state?.inactiveOrg
      );
    }
    if (state?.serverId) {
      formData.append("serverId", state?.serverId);
    }

    if (state?.stacked) {
      formData.append("projectId", state?.stacked?.projectId);
      formData.append("yearData", state?.stacked?.year);
      formData.append("monthData", state?.stacked?.month);
    }

    if (values.project) {
      // @ts-ignore
      formData.append("projectId", values.project?.id);
    }
    listOrganization(page, size, formData)
      .then((response) => {
        if (response?.data?.status === 1) {
          setOrganizationList(response?.data);
          handleActiveStatus(response?.data?.data?.items);
          setPage(response?.data?.data?.page || 1);
        }
      })
      .catch((err) => getCatchMsg(err))
      .finally(() => {
        setnoDataLoader(false);
      });
  };
  /**
   * useEffect hook that triggers the handleListofOrganization function when the token value changes.
   * @param {string} token - The token value.
   * @returns None
   */
  useEffect(() => {
    if (token) {
      handleListofOrganization();
    }
  }, [token, state, values.project]);

  /**
   * Handles the deletion of an organization.
   * Sets the loader to true to indicate that the deletion process is in progress.
   * Creates a new FormData object and appends the organization ID to it.
   * Calls the DeleteOrganization API function with the FormData.
   * If the response status is 1, updates the list of organizations by calling handleListofOrganization
   * with the current page, pageSize, and false to indicate that the list should not be refreshed.
   * Displays a success toast message with the response message.
   * If the response status is 0, displays an error toast message with the response message.
   * If there is an error during the API call, displays an error toast message with the catch message.
   * Finally, sets the loader to
   */
  const handleOrganizationDelete = () => {
    dispatch(openLoader(true));
    let formData = new FormData();
    formData.append("org_id", deleteModal.id.toString());
    DeleteOrganization(formData)
      .then((response) => {
        if (response?.data?.status === 1) {
          handleListofOrganization(organizationList?.data?.page);
          toast.success(response.data.msg);
        }
      })
      .catch((err) => getCatchMsg(err))
      .finally(() => {
        dispatch(openLoader(false));
        setdeleteModal((prev) => {
          return {
            ...prev,
            modal: false,
          };
        });
      });
  };

  /**
   * Handles the active status of organization items.
   * @param {Organizationitemprops[]} data - An array of organization item props.
   * @returns None
   */
  const handleActiveStatus = (data: Organizationitemprops[]) => {
    if (data && data?.length > 0) {
      const statusData = data?.map((ele, index: number) => {
        setSwitches((pre) => {
          let temp = [...pre];
          temp[index] = ele?.verified_status?.toString() === "1" ? true : false;
          return temp;
        });
        return ele;
      });
    }
  };
  // @ts-ignore
  // const ActivateNewplan = (org_id,true,true) => {
  //   // @ts-ignore
  //    handleActiveAndInactiveServer(org_id,true,true)
  // };

  /**
   * Handles the activation and deactivation of a server for a specific organization.
   * @param {number} organizationid - The ID of the organization.
   * @param {boolean} e - The status of the server (true for active, false for inactive).
   * @returns None
   */

  const ActivateNewplan = () => {
    setPlanConfirmation((prev) => {
      return {
        ...prev,
        modal: false,
      };
    });
    // @ts-ignore
    handleActiveAndInactiveServer(PlanConfirmation.id, true, true);
  };
  const handleActiveAndInactiveServer = (
    organizationid: number,
    e: boolean,
    api?: boolean
  ) => {
    let formData = new FormData();
    if (organizationid) {
      formData.append("organizationId", organizationid?.toString());
      if (e == true) {
        formData.append("statusId", "1");
      }
      if (e == false) {
        formData.append("statusId", "2");
      }

      activeInactiveOrganization(formData)
        .then((res) => {
          if (res?.data?.status) {
            if (api) {
              handleListofOrganization(page);
            }
            toast.success(res?.data?.msg);
          } else {
            toast.error(res?.data?.msg);
          }
        })
        .catch((err) => {
          getCatchMsg(err);
        });
    }
  };

  useEffect(() => {
    if (state?.inactiveOrg) {
      setFilter(state?.inactiveOrg);
    }
  }, [state]);

  const getProjects = () => {
    const formData = new FormData();
    projectdropdownlist()
      .then((res) => {
        if (res?.data?.status) {
          setProjectList(res?.data?.data);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      });
  };

  useEffect(() => {
    getProjects();
  }, []);

  const hanleTimerChange = () => {
    let formData = new FormData();
    if (isTime?.id) {
      formData.append("org_id", isTime?.id?.toString());
    }
    formData.append("time_data", values?.time);
    OrganizationTImerChange(formData)
      .then((response) => {
        if (response?.data?.status === 1) {
          toast.success(response?.data?.msg);
          handleListofOrganization(page, pageSize);
        } else {
          toast.error(response?.data?.msg);
        }
      })
      .catch((err) => getCatchMsg(err))
      .finally(() =>
        setisTime((prev) => {
          return {
            ...prev,
            id: null,
            status: false,
          };
        })
      );
  };

  return (
    <>
      <div className="pt-4">
        {!serverid && (
          <PageHeader
            modal={() => {
              setIsShowModal((prev: any) => {
                return {
                  ...prev,
                  status: true,
                  data: null,
                  viewstatus: false,
                };
              });
            }}
            SubscriptionUpgarde={organizationList?.data.subscription_alertCount}
            onWarningIcon={() => {
              navigate("/organization/upgrade_subscription");
            }}
            Btntitle="Create Organization"
            heading="Organization "
            dropdown
            dropdownValue={filter || ""}
            dropdownChange={(e) => {
              const type: any = e;
              setFilter(type);
              if (typeof e === "string" && e !== "-2") {
                setPage(1);
                handleListofOrganization(1, pageSize, false, e.toString());
              } else {
                setPage(1);
                handleListofOrganization(1, pageSize, false, "");
              }
            }}
            onFilterbutton={() => {
              setShowFilterOption((pre) => !pre);
            }}
          />
        )}

        <GlobalModal
          size="xl"
          modalStyle=" logoutmodalminWidth"
          isVisible={isTime.status}
          setIsVisible={() => {
            setisTime((prev: any) => {
              return {
                ...prev,
                status: true,
              };
            });
          }}
        >
          <>
            <ModalHeader
              heading={"Reboot Time"}
              onClose={() => {
                setFieldValue("time", "-1");
                setisTime((prev) => {
                  return {
                    ...prev,
                    id: null,
                    status: false,
                  };
                });
              }}
            />
            <div
              style={{
                width: "90%",
                margin: "10px auto",
              }}
            >
              <div>
                <FormControl
                  fullWidth
                  sx={{
                    "& .MuiSelect-select": {
                      color: values.time === "-1" ? "rgba(0,0,0,0.5)" : "",
                    },
                  }}
                >
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    size="small"
                    defaultValue={"-1"}
                    value={values.time}
                    onChange={(e) => {
                      handleChange("time")(e.target.value);
                    }}
                  >
                    <MenuItem
                      value={"-1"}
                      style={{ visibility: "hidden", height: 0, padding: 0 }}
                    >
                      Select Time
                    </MenuItem>
                    <MenuItem value={"24"}>24</MenuItem>
                    <MenuItem value={"48"}>48</MenuItem>
                    <MenuItem value={"72"}>72</MenuItem>
                    <MenuItem value={"96"}>96</MenuItem>
                  </Select>
                </FormControl>
                {errors?.time && touched?.time ? (
                  <p className="errTxt">{errors?.time}</p>
                ) : null}
              </div>
              <div className="mb-3 mt-3">
                <CustomButton
                  onButtonPress={handleSubmit}
                  title="Submit"
                  customButtonStyle={{
                    width: "max-content",
                    padding: "8px 12px",
                  }}
                />
              </div>
            </div>
          </>
        </GlobalModal>

        {isShowModal?.status && (
          <GlobalModal
            size="lg"
            modalStyle="globalModalmaxWidth logoutmodalminWidth"
            isVisible={isShowModal.status}
            setIsVisible={() => {
              setIsShowModal((prev: any) => {
                return {
                  ...prev,
                  status: true,
                };
              });
            }}
          >
            <CreateOrganizationModal
              getview={isShowModal.viewstatus}
              getviewid={isShowModal.viewid}
              onClose={() => {
                setIsShowModal((prev: any) => {
                  return {
                    ...prev,
                    status: false,
                  };
                });
              }}
              //@ts-ignore
              editData={isShowModal.data || null}
              listOrganizationApicall={(value) => {
                if (value === "update") {
                  handleListofOrganization(page);
                  return;
                }
                handleListofOrganization();
              }}
              modalClose={() => {
                setIsShowModal((prev: any) => {
                  return {
                    ...prev,
                    status: false,
                  };
                });
              }}
              projectList={projectList}
            />
          </GlobalModal>
        )}
        <GlobalModal
          isVisible={deleteModal.modal}
          setIsVisible={() => {
            setdeleteModal((prev) => {
              return {
                ...prev,
                modal: false,
              };
            });
          }}
        >
          <ConfirmationModal
            msg={
              "Are you sure that you want to permanently delete this Organization"
            }
            onClose={() => {
              setdeleteModal((prev) => {
                return {
                  ...prev,
                  modal: false,
                };
              });
            }}
            onPositiveButtonPressed={handleOrganizationDelete}
            onNegativeButtonPressed={() => {
              setdeleteModal((prev) => {
                return {
                  ...prev,
                  modal: false,
                };
              });
            }}
          />
        </GlobalModal>

        <GlobalModal
          isVisible={PlanConfirmation.modal}
          setIsVisible={() => {
            setPlanConfirmation((prev) => {
              return {
                ...prev,
                modal: false,
              };
            });
          }}
        >
          <PlanConfirmationModal
            msg={"Are you sure that you want to activate this Organization"}
            onClose={() => {
              // setconfirm(false);
              setPlanConfirmation((prev) => {
                return {
                  ...prev,
                  modal: false,
                };
              });
            }}
            // @ts-ignore
            onPositiveButtonPressed={ActivateNewplan}
            onNegativeButtonPressed={() => {
              // setconfirm(false);
              setPlanConfirmation((prev) => {
                return {
                  ...prev,
                  modal: false,
                };
              });
            }}
          />
        </GlobalModal>
        <AccordionContent filterStat={showFilterOption || FilterOption}>
          <div className={classes.serachContainer}>
            <Grid className="row mb-3">
              <Grid className="col-lg-2 col-md-4 col-sm-6">
                <TextInputBox
                  title="Company Name"
                  textLength={50}
                  customInputProps={{
                    onKeyDown: (e) => handleTableSearch(e.key),
                  }}
                  name="Company_Name"
                  placeHolder="Search company Name"
                  value={values.Company_Name}
                  onChangeText={handleChange("Company_Name")}
                />
              </Grid>
              <Grid className="col-lg-2 col-md-4 col-sm-6">
                <TextInputBox
                  title="	Server Name"
                  textLength={50}
                  customInputProps={{
                    onKeyDown: (e) => handleTableSearch(e.key),
                  }}
                  name="devicecode"
                  value={values.Server_Name}
                  placeHolder="Search server Name"
                  onChangeText={handleChange("Server_Name")}
                />
              </Grid>
              <Grid className="col-lg-2 col-md-4 col-sm-6">
                <TextInputBox
                  title="Organizanition Code"
                  textLength={50}
                  customInputProps={{
                    onKeyDown: (e) => handleTableSearch(e.key),
                  }}
                  name="Organizationname"
                  value={values.Organization_Code}
                  placeHolder="Search organizanition Code"
                  onChangeText={handleChange("Organization_Code")}
                />
              </Grid>
              <Grid className="col-lg-2 col-md-4 col-sm-6">
                <TextInputBox
                  title="Mobile Number"
                  textLength={50}
                  customInputProps={{
                    onKeyDown: (e) => handleTableSearch(e.key),
                  }}
                  name="contact_no"
                  value={values.contact_no}
                  placeHolder="Search mobile number"
                  onChangeText={handleChange("contact_no")}
                />
              </Grid>
              <Grid className="col-lg-2 col-md-4 col-sm-6">
                <TextInputBox
                  title="Email"
                  textLength={50}
                  customInputProps={{
                    onKeyDown: (e) => handleTableSearch(e.key),
                  }}
                  name="email"
                  value={values.email}
                  placeHolder="Search Email"
                  onChangeText={handleChange("email")}
                />
              </Grid>
              <Grid className="col-lg-2 col-md-4 col-sm-6 fieldBr8 placeHolder">
                <TextInputBox
                  title="Project"
                  // requiredText={"*"}
                  onChangeText={() => {}}
                  textOnly
                />
                <Autocomplete
                  // className={styles.placeHolder}
                  options={projectList}
                  value={values.project}
                  onChange={(e, val) => {
                    setFieldValue("project", val);
                  }}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => {
                    return (
                      <TextField
                        placeholder={values?.project ? "" : "none"}
                        {...params}
                      />
                    );
                  }}
                  PaperComponent={(props) => {
                    return (
                      <Paper {...props} className={muistyle.customSelect} />
                    );
                  }}
                />
              </Grid>
            </Grid>
            <SearchAndClearbutton
              handleTableSearch={handleTableSearch}
              handleTableSearchClear={handleTableSearchClear}
              disabled={!isFormDirty(initialValues, values)}
            />
          </div>
        </AccordionContent>
        <div className={`table-responsive ${classes.AllTable}`}>
          <Box>
            <TableContainer>
              <Table className={classes.orgtable}>
                <TableHead>
                  <TableRow>
                    <TableCell>S.No</TableCell>
                    <TableCell className={classes.orgheader}>
                      Company Name
                    </TableCell>
                    <TableCell className={classes.orgheader}>
                      Server Name
                    </TableCell>
                    <TableCell className={classes.orgheader}>
                      Organizanition Code
                    </TableCell>
                    <TableCell className={classes.orgheader}>
                      Mobile Number
                    </TableCell>
                    <TableCell className={classes.orgheader}>Email</TableCell>
                    <TableCell className={classes.orgheader}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <>
                    {noDataLoader ? (
                      <TableRowsLoader columnNum={7} rowsNum={10} />
                    ) : organizationList?.data?.items?.length === 0 ||
                      !organizationList ? (
                      <TableRow>
                        <TableCell colSpan={6}>
                          <div className={classes.nodatafound}>
                            <img
                              src={Inbox}
                              alt="inbox"
                              width={100}
                              height={100}
                            />
                            <p>No data found</p>
                          </div>
                        </TableCell>
                      </TableRow>
                    ) : (
                      organizationList?.data?.items?.map(
                        (
                          Organization: Organizationitemprops,
                          index: number
                        ) => (
                          <TableRow key={index}>
                            <TableCell>
                              {(organizationList?.data?.page - 1) *
                                organizationList?.data?.size +
                                index +
                                1}
                            </TableCell>
                            <TableCell>
                              {Organization.company_name === null
                                ? "-"
                                : Organization.company_name}
                            </TableCell>
                            <TableCell>
                              {Organization.server_name === null
                                ? "-"
                                : Organization.server_name}
                            </TableCell>
                            <TableCell>
                              {Organization.organization_code === null
                                ? "-"
                                : Organization.organization_code}
                            </TableCell>
                            <TableCell>
                              {Organization.contact_no === null
                                ? "-"
                                : Organization.contact_no}
                            </TableCell>
                            <TableCell>
                              {Organization.email === null
                                ? "-"
                                : Organization.email}
                            </TableCell>
                            <TableCell>
                              <div className={classes.icons}>
                                <Grid className={style.switchContainer}>
                                  {Organization?.verified_status?.toString() !==
                                  "0" ? (
                                    <CustomSwitch
                                      title1="Active"
                                      title2="Inactive"
                                      status={Switches[index]}
                                      setCheck={(state) => {
                                        setSwitches((pre) => {
                                          let temp = [...pre];
                                          temp[index] = state;
                                          return temp;
                                        });
                                        handleActiveAndInactiveServer(
                                          Organization?.org_id,
                                          state
                                        );
                                      }}
                                    />
                                  ) : (
                                    <Tooltip
                                      title="This organization is not active click to activate"
                                      placement="top"
                                    >
                                      <Link
                                        to=""
                                        onClick={() => {
                                          // setSwitches((pre) => {
                                          //   let temp = [...pre];
                                          //   temp[index] = true;
                                          //   return temp;
                                          // });
                                          setconfirm(!confirm);
                                          setPlanConfirmation((prev) => {
                                            return {
                                              ...prev,
                                              modal: true,
                                              id: Organization.org_id,
                                            };
                                          });
                                          // @ts-ignore
                                          // ActivateNewplan(Organization?.org_id);
                                          // handleActiveAndInactiveServer(
                                          //   Organization?.org_id,
                                          //   true,
                                          //   true
                                          // );
                                        }}
                                        style={{
                                          color: "red",
                                        }}
                                      >
                                        Activate
                                      </Link>
                                    </Tooltip>
                                  )}
                                </Grid>
                                <Tooltip title="Time" placement="top">
                                  <img
                                    src={stopwatch}
                                    alt="Time"
                                    style={{
                                      padding: "4px",
                                    }}
                                    onClick={() => {
                                      setFieldValue(
                                        "time",
                                        Organization?.restart_timer?.toString() ??
                                          "-1"
                                      );
                                      setisTime((prev) => {
                                        return {
                                          ...prev,
                                          id: Organization?.org_id,
                                          status: true,
                                        };
                                      });
                                    }}
                                  />
                                </Tooltip>
                                <Tooltip title="View" placement="top">
                                  <img
                                    src={view}
                                    alt="View"
                                    onClick={() => {
                                      if (serverid) {
                                        setIsShowModal((prev: any) => {
                                          return {
                                            ...prev,
                                            status: true,
                                            viewid: Organization.org_id,
                                            viewstatus: true,
                                          };
                                        });
                                      } else {
                                        navigate(
                                          {
                                            pathname:
                                              "organization_detail_list",
                                          },
                                          { state: Organization }
                                        );
                                      }
                                    }}
                                  />
                                </Tooltip>
                                <Tooltip title="Edit" placement="top">
                                  <img
                                    src={edit}
                                    onClick={() => {
                                      setIsShowModal({
                                        status: true,
                                        data: Organization,
                                      });
                                    }}
                                    alt="Edit"
                                  />
                                </Tooltip>
                                {!serverid && (
                                  <Tooltip title="Delete" placement="top">
                                    <img
                                      src={delete_icon}
                                      alt="Delete"
                                      onClick={() =>
                                        setdeleteModal((prev) => {
                                          return {
                                            ...prev,
                                            modal: true,
                                            id: Organization.org_id,
                                          };
                                        })
                                      }
                                    />
                                  </Tooltip>
                                )}
                                {serverScreenStatus && (
                                  <Tooltip title="History" placement="top">
                                    <img
                                      src={historyicon}
                                      alt="History"
                                      onClick={() => {
                                        navigate(
                                          {
                                            pathname: "organization_history",
                                          },
                                          { state: Organization }
                                        );
                                      }}
                                    />
                                  </Tooltip>
                                )}
                              </div>
                            </TableCell>
                          </TableRow>
                        )
                      )
                    )}
                  </>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </div>
      </div>
      {organizationList?.data?.items?.length ? (
        <CustomPaginationwithdropdownpagination
          onChangePage={(page: number) => {
            handleListofOrganization(page, pageSize, false, filter);
            setPage(page);
          }}
          onChangePagerow={(PageSize: number) => {
            if (page * PageSize > organizationList?.data?.totalCount) {
              handleListofOrganization(1, PageSize, false, filter);
              setPageSize(PageSize);
              return;
            }
            handleListofOrganization(page, PageSize, false, filter);
            setPageSize(PageSize);
          }}
          count={organizationList?.data?.totalCount}
          pages={page}
          pageCount={organizationList?.data?.total_page}
          handleFunction={(selected: number) => {
            setPage(selected);
            handleListofOrganization(selected, pageSize, false, filter);
          }}
          labelRowsPerPage={"Devices Per Page"}
          rowsperpage={pageSize}
          totalData={organizationList?.data}
        />
      ) : null}
    </>
  );
}

export default Organization;
