import React, { useEffect, useState } from "react";
import {
  CustomButton,
  CustomDropDown,
  Loader,
  TextInputBox,
} from "../Components";
import ModalHeader from "./ModalHeader";
import { AddPartNumberProps } from "./Types";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  createPartNumber,
  getlistCategory,
  questionDropDown,
  updatePartName,
} from "../Services/Services";
import { getCatchMsg } from "../Utility/GeneralUtils";
import classes from "./Modal.module.css";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { openLoader } from "../Store/Reducers/LoaderSlice";

function AddPartNumber({
  onClose,
  modalClose,
  listApiCall,
  editData,
  modelStatus,
}: AddPartNumberProps) {
  const dispatch = useDispatch();

  const [categorylist, setCategorylist] = useState();
  const [question, setQuestion] = useState();
  const validation = Yup.object({
    partNumber: Yup.string().required("* Part number is required"),
    categoryName:
      editData === null
        ? Yup.string().required("* Category is required")
        : Yup.string(),
  });
  const { values, handleChange, errors, touched, handleSubmit, setFieldValue } =
    useFormik({
      initialValues: {
        partId: editData?.id ? editData?.id : "",
        partNumber: editData?.partName ? editData?.partName : "",
        categoryId: editData?.categoryTypeId ? editData?.categoryTypeId : "",
        categoryName: editData?.categoryTypeName
          ? editData?.categoryTypeName
          : "",
        questionId: editData?.questionId ? editData?.questionId : "",
        questionName: editData?.questionMasterName
          ? editData?.questionMasterName
          : "",
      },
      validationSchema: validation,
      onSubmit: () => {
        if (editData) {
          handleUpdatePartNumber(values);
        } else {
          handleAddBatch();
        }
      },
    });

  const handleUpdatePartNumber = (data: any) => {
    dispatch(openLoader(true));
    let formData = new FormData();
    formData.append("partId", data?.partId);
    formData.append("categoryId", values.categoryId?.toString());
    formData.append("QuestionId", values?.questionId);
    formData.append("partNumber", data?.partNumber);
    updatePartName(formData)
      .then((response) => {
        if (response?.data?.status === 1) {
          toast.success(response?.data?.msg);
          listApiCall("update");
          modalClose();
        } else if (response?.data?.status === 0) {
          toast.error(response?.data?.msg);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        dispatch(openLoader(false));
      });
  };

  const handleAddBatch = () => {
    dispatch(openLoader(true));
    let formData = new FormData();
    if (modelStatus) {
      formData.append("modelStatus", "1");
    }
    formData.append("partNumber", values.partNumber);
    formData.append("QuestionId", values?.questionId);
    formData.append("categoryId", values.categoryId?.toString());
    createPartNumber(formData)
      .then((response) => {
        if (response?.data?.status === 1) {
          toast.success(response?.data?.msg);
          listApiCall("add");
          modalClose();
        } else if (response?.data?.status === 0) {
          toast.error(response?.data?.msg);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        dispatch(openLoader(false));
      });
  };

  const handlegetcategorylist = () => {
    getlistCategory()
      .then((res) => {
        if (res.data.status === 1) {
          setCategorylist(res?.data?.data);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      });
  };

  const handlegetQuestionlist = () => {
    questionDropDown()
      .then((res) => {
        if (res.data.status === 1) {
          setQuestion(res?.data?.data);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      });
  };
  useEffect(() => {
    handlegetQuestionlist();
    handlegetcategorylist();
  }, []);

  return (
    <div>
      <ModalHeader
        onClose={onClose}
        heading={`${editData ? "Edit" : "Add"} ${
          modelStatus ? "Model" : "Part"
        }`}
      />
      <div className={`row ${classes.AddPartNumber}`}>
        <div className="col-12 mb-3">
          <TextInputBox
            requiredText={"*"}
            textLength={50}
            title={`${modelStatus ? "Model" : "Part"} Number`}
            placeHolder={`Enter ${modelStatus ? "model" : "part"} number`}
            name="partNumber"
            value={values.partNumber}
            onChangeText={handleChange("partNumber")}
            errorText={
              touched.partNumber && errors.partNumber ? errors.partNumber : ""
            }
          />
        </div>
        <div className="col-12 mb-3">
          <CustomDropDown
            required="*"
            editName={values.categoryName}
            title="Part Category"
            //@ts-ignore
            items={categorylist}
            onSelectedItem={(val, value) => {
              setFieldValue("categoryId", value?.id);
              setFieldValue("categoryName", value?.label);
            }}
            fieldName="categoryName"
            anotherFieldName="categoryId"
            value={values.categoryId}
            errorText={
              errors.categoryName && touched.categoryName
                ? errors?.categoryName
                : ""
            }
          />
        </div>
        <div className="col-12 mb-3">
          <CustomDropDown
            editName={values.questionName}
            title="Question Set"
            //@ts-ignore
            items={question}
            onSelectedItem={(val, value) => {
              setFieldValue("questionId", value?.id);
              setFieldValue("questionName", value?.label);
            }}
            fieldName="questionName"
            anotherFieldName="questionId"
            value={values.questionId}
          />
        </div>
        <div className="col-lg-4 my-4">
          <CustomButton
            title={editData ? "Update" : "Submit"}
            onButtonPress={handleSubmit}
          />
        </div>
        {editData ? (
          <div className="col-lg-4 my-4">
            <CustomButton
              title="Cancel"
              customButtonStyle={{
                backgroundColor: "rgba(0, 0, 0, 0.08)",
                color: "black",
              }}
              onButtonPress={() => modalClose()}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default AddPartNumber;
