// export const baseUrl = "http://192.168.1.23:8001";

import { makeStyles } from "@material-ui/core";

// export const baseUrl = "http://192.168.1.117:8001";
// export const baseUrl = "http://cbe.themaestro.in:8021/root_mconnect";
export const baseUrl = "https://mcroot.themaestro.in/api";

export const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const MOBILE_REGEX =
  /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/gm;

export const NUMBER = /^[0-9]*$/;

export const SPECIAL_CHARACTER_REGEX = /^[A-Za-z0-9\s]+$/;
export const ALPHA_NUM = /^[A-Za-z0-9-_\s]+$/;

export const NAMES = /^[A-Za-z\s]+$/;

export const INDIAN_MOBILE_REGEX = /^[6-9]{1}\d{9}$/;
export const MACADDRESS_REGEX = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;
export const IMEI_NUMBER_REGEX = /^\d{15}$/;
export const board_batch = "Board Batch";
export const device_batch = "Device Batch";
export const question = "Question Set";
export const answer = "Answer Set";
export const board_category = "Part Category";
export const parts = "Parts";
export const model = "Model";
export const device = "Device";
export const SubScriptionHistory = "Subscription Request";
export const UpgradeSubScription = "Upgrade Subscription";
export const Firmware_master = "Firmware Master";
export const Firmware_managemnet = "Firmware Management";
export const muiColors = [
  "#008FFB",
  "#00E396",
  "#FEB019",
  "#FF4560",
  "#775DD0",
];
export const muiStyles = makeStyles((theme: any) => ({
  activeTab: {
    "& .MuiTabs-indicator": {
      backgroundColor: "rgb(242, 89, 34)",
      width: "60px !important",
      // left: "15px !important",
      transform: "translateX(17px) !important",
      bottom: "0 !important",
    },
    "& button": {
      padding: "5px 17px !important",
      minHeight: "0px !important",
    },
  },
  datePicker: {
    "&>div:nth-child(2)>div>div:nth-child(1)": {
      border: "none !important",
    },
  },
  dateStyles: {
    "& .MuiPaper-root": {
      maxHeight: "200px",
      overflowY: "auto",
    },
  },
  datepickerRadius: {
    "&>div": {
      width: "100%",
      borderRadius: "8px",
      backgroundColor: "white !important",
      border: "none !important",
      height: "42px !important",
    },
  },
  activeTabButton: {
    color: "black !important",
  },
  tabColor: {
    "& button": {
      padding: "5px 16px !important",
      minHeight: "0px !important",
    },
  },
  customSelect: {
    borderRadius: "25px !important",
    boxShadow: "1px 1px 10px 1px rgba(0,0,0,0.1)",

    "& li": {
      margin: "8px !important",
      borderRadius: "20px !important",
    },
  },
}));
