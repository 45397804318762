import React, { useState } from "react";
import { AddBoradBatchComponentsProps } from "./Types";
import ModalHeader from "./ModalHeader";
import { Loader, TextInputBox } from "../Components";
import { UpdateComponentsList } from "../Services/Services";
import { getCatchMsg } from "../Utility/GeneralUtils";
import { useFormik } from "formik";
import classes from "./Modal.module.css";
import UpdateDeleteActions from "../Components/UpdateDeleteActions";
import toast from "react-hot-toast";
import { openLoader } from "../Store/Reducers/LoaderSlice";
import { useDispatch } from "react-redux";

function AddBoradBatchComponents({
  onClose,
  editData,
  modalClose,
  listModelApicall,
}: AddBoradBatchComponentsProps) {
  const dispatch = useDispatch();

  const { handleChange, handleSubmit, values } = useFormik({
    initialValues: {
      componentId: editData?.componentId ? editData?.componentId : "",
      part_no: editData?.part_no ? editData?.part_no : "",
      serial_no: editData?.serial_no ? editData?.serial_no : "",
      categoryName: editData?.categoryName ? editData?.categoryName : "",
    },
    onSubmit: () => {
      handleUpdateBoradBatchComponents(values);
    },
  });

  const handleUpdateBoradBatchComponents = (data: any) => {
    dispatch(openLoader(true));
    let formData = new FormData();
    formData.append(
      "componentId",
      //@ts-ignore
      data?.componentId ? parseInt(data?.componentId) : ""
    );

    formData.append("part_no", data?.part_no);
    formData.append("serial_no", data?.serial_no);
    UpdateComponentsList(formData)
      .then((response) => {
        if (response?.data?.status === 1) {
          toast.success(response?.data?.msg);
          listModelApicall("update");
          modalClose();
        } else if (response?.data?.status === 0) {
          toast.error(response?.data?.msg);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        dispatch(openLoader(false));
      });
  };

  return (
    <>
      <ModalHeader onClose={onClose} heading={"Edit Component"} />
      <div className={classes.component}>
        <div className="row">
          <div className="col-md-6 col-xl-6 form-group mb-3">
            <TextInputBox
              disabled={true}
              title="Serial number"
              name="serial_no"
              value={values.serial_no}
              onChangeText={handleChange("serial_no")}
            />
          </div>
          <div className="col-md-6 col-xl-6 form-group mb-3">
            <TextInputBox
              disabled={true}
              title="Category name"
              name="categoryName"
              value={values.categoryName}
              onChangeText={handleChange("categoryName")}
            />
          </div>
          <div className="col-md-6 col-xl-6 form-group mb-3">
            <TextInputBox
              placeHolder="Enter part number"
              title="Part number"
              name="part_no"
              value={values.part_no}
              textLength={15}
              onChangeText={handleChange("part_no")}
            />
          </div>
        </div>
        <div className={classes.modalbtn}>
          <UpdateDeleteActions
            onCancelPress={() => modalClose()}
            onUpdatePress={handleSubmit}
          />
        </div>
      </div>
    </>
  );
}

export default AddBoradBatchComponents;
