import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { useFormik } from "formik";

import { getCatchMsg } from "../Utility/GeneralUtils";
import {
  Loader,
  TextInputBox,
  CustomButton,
  CustomDropDown,
} from "../Components/index";
import ModalHeader from "./ModalHeader";
import classes from "./Modal.module.css";
import { useToken } from "../Utility/StoreData";
import {
  createSubModal,
  EditSubModal,
  getmainModelDropdown,
} from "../Services/Services";
import { CreatemainmodelType } from "../Modals/Types";
import UpdateDeleteActions from "../Components/UpdateDeleteActions";
import { useDispatch } from "react-redux";
import { openLoader } from "../Store/Reducers/LoaderSlice";

const validationSchema = Yup.object({
  subModelName: Yup.string()
    .required("name is required")
    .trim("Remove leading and trailing spaces")
    .strict(true),
  mainModelName: Yup.string()
    .required("Main model id is required")
    .trim("Remove leading and trailing spaces")
    .strict(true),
});

function Createmainmodel({
  onClose,
  editData,
  listsubmodelapicall,
  modalClose,
  getView,
}: CreatemainmodelType) {
  const dispatch = useDispatch();
  const [mainModelDropdown, setMainModelDropdown] = useState([]);
  let token = useToken();
  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    errors,
    setFieldValue,
    setFieldError,
    setFieldTouched,
    touched,
  } = useFormik({
    initialValues: {
      subModelName: editData?.name || "",
      mainModelId: editData?.modelId || "",
      mainModelName: editData?.modelName || "",
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      if (editData) {
        handleupdateSubModel();
      } else {
        handlecraeteSubModel();
      }
    },
  });

  useEffect(() => {
    if (token && !getView) {
      handlegetmainModeldropdown();
    }
  }, [token]);

  const handlegetmainModeldropdown = () => {
    getmainModelDropdown().then((res) => {
      if (res?.data?.status === 1) {
        setMainModelDropdown(res?.data?.data);
      }
    });
  };

  const handlecraeteSubModel = () => {
    let formData = new FormData();
    formData.append("subModelName", values?.subModelName);
    formData.append("mainModelId", values?.mainModelId.toString());
    createSubModal(formData)
      .then((res) => {
        if (res?.data?.status === 1) {
          modalClose();
          listsubmodelapicall("add");
          toast.success(res?.data?.msg);
        } else if (res?.data?.status === 0) {
          toast.error(res?.data?.msg);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        dispatch(openLoader(false));
      });
  };

  const handleupdateSubModel = () => {
    let formData = new FormData();
    formData.append("subModelId", editData?.id.toString());
    formData.append("subModelName", values?.subModelName);
    formData.append("modelId", values?.mainModelId.toString());
    EditSubModal(formData)
      .then((res) => {
        if (res?.data?.status === 1) {
          modalClose();
          listsubmodelapicall("update");
          toast.success(res?.data?.msg);
        } else if (res?.data?.status === 0) {
          toast.error(res?.data?.msg);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        dispatch(openLoader(false));
      });
  };

  return (
    <>
      <ModalHeader
        onClose={onClose}
        heading={
          getView
            ? "View Sub Model"
            : editData === null
              ? "Add Sub Model"
              : "Edit Sub Model"
        }
      />
      <div className={classes.addModel}>
        {!getView ? (
          <div className="row">
            <div className="col-lg-12">
              <TextInputBox
                requiredText="*"
                title="Sub Model Name"
                textLength={50}
                placeHolder="Enter Sub Model Name"
                name="subModelName"
                onChangeText={handleChange("subModelName")}
                value={values.subModelName}
                errorText={
                  !getView && touched.subModelName && errors.subModelName
                    ? errors.subModelName
                    : ""
                }
                onBlur={handleBlur("subModelName")}
              />
            </div>
            <div className="col-lg-12 mt-3">
              <CustomDropDown
                required="*"
                editName={
                  values.mainModelName ? values.mainModelName : "--None--"
                }
                title="Main model id"
                //@ts-ignore
                items={mainModelDropdown}
                onSelectedItem={(val, value) => {
                  setFieldValue("mainModelName", value.label);
                  setFieldValue("mainModelId", value.id);
                }}
                fieldName="mainModelName"
                anotherFieldName="mainModelId"
                value={values.mainModelId}
                onBlur={() => {
                  try {
                    validationSchema.validateSyncAt(
                      "model_no",
                      values.mainModelId
                    );
                  } catch (error) {
                    if (error instanceof Error) {
                      setFieldTouched("mainModelName", true);
                      setFieldError("mainModelName", error.message);
                    }
                  }
                }}
                errorText={
                  !getView && touched.mainModelName && errors.mainModelName
                    ? errors.mainModelName
                    : ""
                }
              />
            </div>
          </div>
        ) : (
          <div className={classes.add_mainmodel}>
            <ul>
              <div className={classes.heading}>
                <p>Sub model name</p>:<span>{editData && editData.name}</span>
              </div>
              <div className={classes.heading}>
                <p>Model name</p>:<span>{editData && editData.modelName}</span>
              </div>
            </ul>
          </div>
        )}

        {getView ? null : (
          <>
            <div className={classes.modalbtn}>
              {editData ? (
                <UpdateDeleteActions
                  onCancelPress={() => modalClose()}
                  onUpdatePress={handleSubmit}
                />
              ) : (
                <div className="col-lg-3">
                  <CustomButton title="Submit" onButtonPress={handleSubmit} />
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default Createmainmodel;
