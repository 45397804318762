import * as React from "react";
import TablePagination from "@mui/material/TablePagination";
import { Pagination, Typography } from "@mui/material";
import { Theme, makeStyles } from "@material-ui/core";
import classes from "./CustomStyle.module.css";
import { CustomPaginationwithdropdownpaginationType } from "./Types";
import { muiStyles } from "../Utility/Constants";

const useStyles = makeStyles((theme: Theme) => ({
  paginate: {
    padding: "10px 0px",
    "& ul": {
      justifyContent: "flex-end",
      flexWrap: "nowrap !important",
    },
  },
  pablepaginationcontainer: {
    "& .MuiInputBase-root": {
      padding: "0 !important",
      height: "35px",
    },

    "&>div>div": {
      position: "absolute",
      right: "0",
    },
    "&>div:nth-child(1)": {
      position: "relative",
      paddingRight: "125px",
      justifyContent: "flex-end",
      minWidth: "max-content",
    },
  },
}));

export default function CustomPaginationwithdropdownpagination({
  onChangePage,
  onChangePagerow,
  count,
  pages,
  pageCount,
  handleFunction,
  rowsperpage,
  totalData,
}: CustomPaginationwithdropdownpaginationType) {
  const muiStyle = useStyles();
  const styles = muiStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
    onChangePage(newPage);
  };

  React.useEffect(() => {
    setRowsPerPage(rowsperpage);
  }, [rowsperpage]);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    // setRowsPerPage(parseInt(event.target.value, 10));
    onChangePagerow(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TablePagination
      className={muiStyle.pablepaginationcontainer}
      sx={{
        "& .MuiInputBase-root": {
          borderRadius: "10px",
          boxShadow: "1px 1px 10px 1px rgba(0,0,0,0.1)",
          border: "0.5px solid rgba(0, 0, 0, 0.15)",
        },
        "& .Mui-selected": {
          background: "rgb(38, 38, 38) !important",
        },

        "& .MuiTablePagination-displayedRows p": {
          fontSize: "14px",
        },
      }}
      SelectProps={{
        MenuProps: {
          PaperProps: {
            className: styles.customSelect,
          },
        },
      }}
      component="div"
      count={count}
      page={page}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      labelDisplayedRows={({ from, to, count, page }) => {
        // return `${from > to ? 1 : from}–${to} of ${
        //   count !== -1 ? count : `more than ${to}}`
        // }`;
        return (
          <Typography className={classes.totalcount}>
            <span>
              {" "}
              {!totalData?.totalCount
                ? 0
                : totalData?.size &&
                  totalData?.page &&
                  totalData?.size * (totalData?.page - 1) + 1}
            </span>{" "}
            -{" "}
            <span>
              {totalData?.size &&
              totalData?.page &&
              totalData?.totalCount &&
              totalData?.size * totalData?.page <= totalData?.totalCount
                ? totalData?.size * totalData?.page
                : totalData?.totalCount ?? 0}
            </span>
            &nbsp;<span>of</span>{" "}
            <span>{totalData?.totalCount ? totalData?.totalCount : 0}</span>{" "}
          </Typography>
        );
      }}
      labelRowsPerPage={""}
      rowsPerPageOptions={[
        {
          label: "10 / page",
          value: 10,
        },
        { label: "25 / page", value: 25 },
        { label: "50 / page", value: 50 },
        { label: "100 / page", value: 100 },
      ]}
      ActionsComponent={(e) => (
        <Pagination
          className={muiStyle.paginate}
          count={pageCount}
          page={pages}
          onChange={(e, selectedPage) => {
            handleFunction(selectedPage);
            setPage(selectedPage - 1);
            // onChangePage(selectedPage);
          }}
          color="primary"
          boundaryCount={1}
          siblingCount={1}
        />
      )}
    />
  );
}
