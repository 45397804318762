import React, { useEffect, useState } from "react";
import ModalHeader from "./ModalHeader";
import classes from "./Modal.module.css";
import { CustomButton, TextInputBox } from "../Components";
import { useFormik } from "formik";
import * as Yup from "yup";
import { createproject } from "./Types";
import {
  addProjectManagement,
  projectdropdownlist,
  updateProjectManagement,
} from "../Services/Services";
import toast from "react-hot-toast";
import UpdateDeleteActions from "../Components/UpdateDeleteActions";
import { getCatchMsg } from "../Utility/GeneralUtils";
import { Autocomplete, TextField } from "@mui/material";
const validationSchema = Yup.object({
  name: Yup.string()
    .required("* Project Name is required")
    .test(
      "trim spaces",
      "Remove leading and trailing spaces",
      function (value) {
        if (value?.trim()?.length === 0) {
          return false;
        }
        return true;
      }
    ),
  number: Yup.string()
    .required("* Project Number is required")
    .test(
      "trim spaces",
      "Remove leading and trailing spaces",
      function (value) {
        if (value?.trim()?.length === 0) {
          return false;
        }
        return true;
      }
    ),

  Description: Yup.string().test(
    "trim spaces",
    "Remove leading and trailing spaces",
    function (value) {
      if (value?.trim()?.length === 0) {
        return false;
      }
      return true;
    }
  ),
});
function AddProjectModal({
  onClose,
  modalClose,
  listServerApicall,
  editData,
  viewStatus,
}: createproject) {
  const {
    handleChange,
    values,
    setFieldValue,
    handleSubmit,
    setFieldTouched,
    setFieldError,
    errors,
    touched,
    handleBlur,
  } = useFormik({
    initialValues: {
      // @ts-ignore
      name: editData?.projectName ? editData?.projectName : "",
      // @ts-ignore
      Description: editData?.description ? editData?.description : "",
      project: null,
      number: editData?.projectNumber ? editData?.projectNumber : "",
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      if (editData) {
        handleUpdateProject();
      } else {
        handleaddProject();
      }
    },
  });

  const handleaddProject = () => {
    let formData = new FormData();
    formData.append("name", values.name);
    formData.append("projectNumber", values.number);
    formData.append("description", values.Description);
    addProjectManagement(formData)
      .then((res) => {
        if (res?.data?.status) {
          listServerApicall("add");
          modalClose();
          toast.success(res?.data?.msg);
        } else {
          toast.error(res?.data?.msg);
        }
      })
      .catch((err) => getCatchMsg(err));
  };
  const handleUpdateProject = () => {
    let formData = new FormData();
    // @ts-ignore
    formData.append("projectId", editData.id);
    formData.append("name", values.name);
    formData.append("description", values.Description);
    formData.append("projectNumber", values.number);
    updateProjectManagement(formData)
      .then((res) => {
        listServerApicall("update");
        modalClose();
        toast.success(res?.data?.msg);
      })
      .catch((err) => {});
  };

  return (
    <>
      {/* @ts-ignore */}
      <ModalHeader
        onClose={onClose}
        heading={
          viewStatus
            ? "View Project"
            : editData
              ? "Edit Project"
              : "Add Project"
        }
      />
      {viewStatus ? (
        <div className={classes.add_mainmodel}>
          <ul>
            <div className={classes.heading}>
              <p>Project Name</p>:<span>{values.name ? values.name : "-"}</span>
            </div>
            <div className={classes.heading}>
              <p>Project Number</p>:
              <span>{values.number ? values.number : "-"}</span>
            </div>
            <div className={classes.heading}>
              <p>Description</p>:
              <span>{values.Description ? values.Description : "-"}</span>
            </div>
          </ul>
        </div>
      ) : (
        <div className={classes.add_device}>
          <div className="row">
            <div className="col-md-12  col-lg-6 form-group mb-3">
              <TextInputBox
                requiredText={"*"}
                textLength={50}
                title="Name"
                name="name"
                value={values.name}
                //   readOnly={getview}
                placeHolder="Enter Project Name"
                onChangeText={handleChange("name")}
                onBlur={() => {
                  try {
                    validationSchema.validateSyncAt("name", values.name);
                  } catch (error) {
                    if (error instanceof Error) {
                      setFieldTouched("name", true);
                      setFieldError("name", error.message);
                    }
                  }
                }}
                errorText={touched.name && errors.name ? errors.name : ""}
              />
            </div>
            <div className="col-md-12  col-lg-6 form-group mb-3">
              <TextInputBox
                requiredText={"*"}
                textLength={50}
                title="Project Number"
                name="number"
                value={values.number}
                //   readOnly={getview}
                placeHolder="Enter Project Number"
                onChangeText={handleChange("number")}
                onBlur={() => {
                  try {
                    validationSchema.validateSyncAt("number", values.number);
                  } catch (error) {
                    if (error instanceof Error) {
                      setFieldTouched("number", true);
                      setFieldError("number", error.message);
                    }
                  }
                }}
                errorText={touched.number && errors.number ? errors.number : ""}
              />
            </div>

            <div
              style={{ position: "relative" }}
              className={`col-md-12 col-xl-12 form-group mb-3 ${classes.textareacontainer}`}
            >
              <p>Description</p>
              <textarea
                style={{ padding: "5px" }}
                name="Description"
                className={`${classes.text_area}`}
                maxLength={50}
                onChange={handleChange("Description")}
                value={values.Description}
                placeholder="Enter the description"
                //   readOnly={getview}
                onBlur={handleBlur("Description")}
              ></textarea>
              {errors?.Description && touched.Description && (
                <p className={classes.text_areaError}>{errors?.Description}</p>
              )}
            </div>
            <>
              <div className={classes.modalbtn}>
                {editData ? (
                  <UpdateDeleteActions
                    onCancelPress={() => modalClose()}
                    onUpdatePress={handleSubmit}
                  />
                ) : (
                  <div className="col-lg-3">
                    <CustomButton title="Submit" onButtonPress={handleSubmit} />
                  </div>
                )}
              </div>
            </>
          </div>
        </div>
      )}
    </>
  );
}

export default AddProjectModal;
