import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import {
  ProjectWiseDeviceData,
  ProjectWiseOrganizationData,
  ProjectWiseType,
} from "./Types";
import { year } from "../Utility/GeneralUtils";
import { useNavigate } from "react-router-dom";

export default function StackedBarchart({
  data,
  // type,
  showing,
  chartLabels,
  years,
}: {
  data: { name: string; id: number; data: number[] }[] | null;
  type: string;
  showing: string;
  chartLabels: string[];
  years: string;
}) {
  const navigate = useNavigate();
  const [reportData, setreportData] = useState<
    | {
        name: string;
        data: number[];
      }[]
    | null
  >(null);

  // const [chartLabels, setChartLabels] = useState<string[]>([]);
  // const [ChartData, setChartData] = useState<any[]>([]);

  useEffect(() => {
    // handleData();
    // if (type === "data1") {
    //   setChartData(data?.data1);
    // } else {
    //   setChartData(data?.data2);
    // }
    // const finalArray = projects?.map((ele, index) => {
    //   const projectData = tempdata?.reduce(
    //     (prevItem: ProjectWiseOrganizationData[], currentItem) => {
    //       let tempItem = [...prevItem];
    //       if (currentItem.projectName === ele) {
    //         tempItem.push(currentItem);
    //       } else {
    //         tempItem?.push({
    //           orgCount: 0,
    //           deviceCount: 0,
    //           month: 0,
    //           projectId: "",
    //           projectName: "",
    //         });
    //       }
    //       return tempItem;
    //     },
    //     []
    //   );
    //   return {
    //     name: ele?.toString(),
    //     data: projectData?.map((ele) => ele?.orgCount),
    //   };
    // });
    // setOrganizationData(finalArray);
  }, [data]);

  // const handleData = () => {
  //   const data = [...ChartData]?.map((ele) => year[ele.month]);
  //   const getLabels = Array.from(new Set(data));

  //   const monthWiseData = getLabels?.map((ele) => {
  //     const temp = [...ChartData]?.filter((item) => year[item?.month] === ele);
  //     return { month: ele, data: temp };
  //   });

  //   setChartLabels(getLabels || []);

  //   const tempdata = [...ChartData];
  //   const tempprojects = new Set([...tempdata?.map((ele) => ele?.projectName)]);
  //   const projects = Array.from(tempprojects);

  //   const FormattedData = projects?.map((ele, index) => {
  //     const data = monthWiseData?.map((item, itemindex) => {
  //       return item?.data?.filter((dataItem) => {
  //         if (dataItem?.projectName === ele) {
  //           return dataItem;
  //         }
  //       });
  //     });
  //     if (type === "data1") {
  //       return {
  //         name: ele,
  //         data: data?.map(
  //           (item) =>
  //             item?.find((findItem) => findItem?.orgCount)?.orgCount || 0
  //         ),
  //       };
  //     }
  //     return {
  //       name: ele,
  //       data: data?.map(
  //         (item) =>
  //           item?.find((findItem) => findItem?.deviceCount)?.deviceCount || 0
  //       ),
  //     };
  //   });

  //   setreportData(FormattedData);
  // };

  return (
    <Grid className="cardShadows p-3">
      <ReactApexChart
        type="bar"
        options={{
          chart: {
            stacked: true,
            events: {
              dataPointSelection: (event, chartContext, config) => {
                const { seriesIndex } = config;

                if (showing === "Device") {
                  navigate("/dashboard/management/device_management", {
                    state: {
                      stacked: {
                        projectId: data?.[seriesIndex]?.id,
                        year: years,
                        month:
                          year?.findIndex(
                            (ele) =>
                              ele ===
                              config.w.config.labels[config.dataPointIndex]
                          ) + 1,
                      },
                    },
                  });
                } else {
                  navigate("/organization", {
                    state: {
                      stacked: {
                        projectId: data?.[seriesIndex]?.id,
                        year: years,
                        month:
                          year?.findIndex(
                            (ele) =>
                              ele ===
                              config.w.config.labels[config.dataPointIndex]
                          ) + 1,
                      },
                    },
                  });
                }
              },
            },
          },
          legend: {
            position: "bottom",
          },
          noData: {
            text: "No data found !",
            align: "center",
            verticalAlign: "middle",
            offsetX: 0,
            offsetY: 0,
            style: {
              color: "grey",
              fontSize: "14px",
            },
          },
          plotOptions: {
            bar: {
              columnWidth: data
                ? data?.[0]?.data?.length < 4 && window.innerWidth > 1199
                  ? `15%`
                  : data?.[0]?.data?.length < 6 && window.innerWidth > 1199
                  ? "45%"
                  : "75%"
                : "75%",
              dataLabels: {
                total: {
                  enabled: true,
                  style: {
                    fontSize: "14px",
                    fontWeight: 900,
                  },
                },
              },
            },
          },

          // dataLabels: {
          //   formatter: (val) => {
          //     return `${val} ${showing}`;
          //   },
          // },
          tooltip: {
            y: {
              formatter: (val) => {
                return `${val} ${showing}${val === 1 ? "" : "s"}`;
              },
            },
          },
          labels: chartLabels,
        }}
        height={400}
        series={
          data
            ? data
            : [
                {
                  name: "",
                  data: [],
                },
              ]
        }
      />
    </Grid>
  );
}
