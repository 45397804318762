import {
  Autocomplete,
  Button,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./ServiceRequest.module.css";
import {
  CustomButton,
  CustomDropDown,
  CustomPaginationwithdropdownpagination,
  GlobalModal,
  Loader,
  TextInputBox,
} from "../../Components";
import { useFormik } from "formik";
import {
  closeServiceRequestServices,
  createServiceRequestServices,
  deleteAttachmentServices,
  fileUploadServices,
  getDeviceDrodownService,
  getOrganizationDetailsServices,
  getUserDropdownServices,
  getserviceTaskListService,
  listAttachmentServices,
  organizationDropDown,
  startServiceServices,
  viewServiceRequestServices,
} from "../../Services/Services";
import { getCatchMsg } from "../../Utility/GeneralUtils";
import {
  DeviceDropdownDataProps,
  EmployeeDropdownDataProps,
  ViewServiceRequestProps,
} from "../../Modals/Types";
import { Close } from "@mui/icons-material";
import CustomDatePicker from "../../Components/CustomDatePicker";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import PageHeader from "../MangementLayoutHeader/PageHeader";
import delete_icon from "../../Assets/Icons/Svg/image_del.svg";
import view from "../../Assets/Icons/Svg/image_view.svg";
import ImageItem from "../../Components/ImageItem";
import ImageView from "../../Components/ImageView";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { ServiceTaskProps } from "./Types";
import ServiceRequestTaskApp from "./ServiceRequestTaskApp";
import dayjs from "dayjs";
import { ConfirmationModal } from "../../Modals";
import file from "../../Assets/Icons/Svg/file.svg";
import { useDispatch } from "react-redux";
import { openLoader } from "../../Store/Reducers/LoaderSlice";
import nodata from "../../Assets/Images/Svg/nodata.svg";
import { muiStyles } from "../../Utility/Constants";

export default function CreateServiceRequest() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const muistyle = muiStyles();

  const ValidationSchema = Yup.object().shape({
    org_id: Yup.string().required("* Organization is required"),
    device: Yup.mixed()
      .transform((val) => (val?.length === 0 ? undefined : val))
      .required("* Device is required"),
    device_status: Yup.string()
      .transform((val) => (val === "-1" ? undefined : val))
      .required("* Device status is required"),
    service_status: Yup.string()
      .transform((val) => (val === "-1" ? undefined : val))
      .required("* Service status is required"),
    priority: Yup.string()
      .transform((val) => (val === "-1" ? undefined : val))
      .required("* Priority is required"),
    error_date: Yup.mixed()
      .transform((val) => (val ? val : undefined))
      .required("* Error occured date is required"),
  });
  const {
    values,
    setFieldValue,
    handleChange,
    errors,
    touched,
    resetForm,
    handleSubmit,
  } = useFormik({
    initialValues: {
      org_name: "",
      org_id: "",
      device: [],
      device_status: "-1",
      error_date: null,
      schedule_date: null,
      assigned_person: null,
      service_status: "-1",
      estimate_amount: "0",
      priority: "-1",
      contact_person: "",
      phone_number: "",
      location: "",
      display_msg: "",
      comments: "",
      choosenfile: "",
      requested_date: dayjs(new Date()),
      service_start: null,
      service_complete: null,
    },
    validationSchema: ValidationSchema,
    onSubmit: () => {
      if (!state?.event) {
        createServiceRequest();
      } else if (
        imageFilesonUpdate?.filter((ele) => !ele?.id)?.length > 0 &&
        viewData?.requestStatusId
      ) {
        uploadFiles(
          viewData?.requestStatusId?.toString(),
          "1",
          imageFilesonUpdate?.filter((ele) => !ele?.id),
          true
        );
      } else {
        toast.success("Service request updated successfully");
        navigate(-1);
      }
    },
  });

  const [organizationList, setOrganizationList] = useState([]);
  const [deviceList, setDeviceList] = useState<DeviceDropdownDataProps[]>([]);
  const [employeeList, setEmployeeList] = useState<EmployeeDropdownDataProps[]>(
    []
  );

  const [imageFiles, setImageFiles] = useState<
    { filename: string; file: Blob | string; id: "" }[]
  >([]);
  const [imageFilesonUpdate, setImageFilesonUpdate] = useState<
    { filename: string; file: Blob | string; id: "" }[]
  >([]);

  const [viewImage, setViewImage] = useState({ status: false, file: "" });
  const [viewData, setViewData] = useState<ViewServiceRequestProps | null>(
    null
  );
  const [ServiceStarted, setServiceStarted] = useState(false);
  const [imagePageDetails, setimagePageDetails] = useState({
    page: 1,
    pageSize: 10,
    total: 0,
    totalPage: 1,
  });
  const [imageUpdatePageDetails, setimageUpdatePageDetails] = useState({
    page: 1,
    pageSize: 10,
    total: 0,
    totalPage: 1,
  });

  const [closeService, setCloseService] = useState(false);

  const getOrganizations = () => {
    organizationDropDown()
      .then((res) => {
        if (res?.data?.status === 1) {
          setOrganizationList(res?.data?.data);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      });
  };

  const getDeviceDropdown = (page = 1, size = 30) => {
    const formdata = new FormData();
    formdata.append("org_id", values.org_id ? values?.org_id : "");
    getDeviceDrodownService(page, size, formdata)
      .then((res) => {
        if (res?.data?.status) {
          setDeviceList(res?.data?.data?.items);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      });
  };

  const getEmployees = () => {
    getUserDropdownServices()
      .then((res) => {
        if (res?.data?.status === 1) {
          setEmployeeList(res?.data?.data);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      });
  };
  const getOrganizationDetails = () => {
    const formdata = new FormData();
    formdata.append("org_id", values.org_id);
    getOrganizationDetailsServices(formdata)
      .then((res) => {
        if (res?.data?.status) {
          const { data } = res?.data;
          setFieldValue(
            "contact_person",
            data?.ContactPerson ? data?.ContactPerson : ""
          );
          setFieldValue(
            "phone_number",
            data?.phoneNumber ? data?.phoneNumber : ""
          );
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      });
  };

  const createServiceRequest = () => {
    dispatch(openLoader(true));
    const formdata = new FormData();
    formdata.append("organization_id", values.org_id);
    formdata.append("contact_person", values.contact_person);
    formdata.append("location", values.location);
    formdata.append("phone_no", values.phone_number);
    formdata.append("service_type", values.service_status);
    formdata.append("estimated_amount", values.estimate_amount);
    formdata.append(
      "device_id",
      values.device
        .map((ele: { deviceCode: string }) => ele?.deviceCode)
        ?.toString()
    );
    formdata.append("deviceStatus", values.device_status);
    formdata.append(
      "requested_date",
      dayjs(values.requested_date).format("YYYY-MM-DD HH:mm")
    );
    formdata.append(
      "error_occurred",
      values.error_date
        ? dayjs(values.error_date).format("YYYY-MM-DD HH:mm")
        : ""
    );
    formdata.append("priority_level", values.priority);
    formdata.append("issue_comment", values.comments);
    formdata.append(
      "assigned_technician",
      // @ts-ignore

      values.assigned_person ? values.assigned_person?.userId : ""
    );
    formdata.append(
      "estimated_resolution_time",
      values.schedule_date
        ? dayjs(values.schedule_date).format("YYYY-MM-DD HH:mm")
        : ""
    );
    formdata.append("issue_description", values.display_msg);

    createServiceRequestServices(formdata)
      .then((res) => {
        if (res?.data?.status) {
          uploadFiles(
            res?.data?.req_id,
            "1",
            [...imageFilesonUpdate]?.filter((ele) => !ele?.id),
            true
          );
        } else {
          dispatch(openLoader(false));

          toast.error(res?.data?.msg);
        }
      })
      .catch((err) => {
        dispatch(openLoader(false));

        getCatchMsg(err);
      });
  };

  const uploadFiles = (
    id: string,
    type: string,
    data: any,
    fromCreate?: boolean
  ) => {
    const formdata = new FormData();
    formdata.append("request_id", id);
    formdata.append("attachmentType", type);
    formdata.append(
      "file_title",
      data?.map((ele: any) => ele?.filename)?.toString()
    );
    data?.map((ele: any) => formdata.append("upload_file", ele?.file));
    fileUploadServices(formdata)
      .then((res) => {
        if (res?.data?.status) {
          if (type === "1" && fromCreate) {
            toast.success("Service request created successfully");
          } else if (fromCreate) {
            toast.success("Service request updated successfully");
          } else {
            toast.success(res?.data?.msg);
          }
          if (fromCreate) {
            navigate("/service_request");
          }

          if (!fromCreate) {
            listAttachments(1, 10, type);
          }
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        dispatch(openLoader(false));
      });
  };

  const viewServiceRequest = () => {
    const formdata = new FormData();
    formdata.append("serviceRequestId", state?.id);
    viewServiceRequestServices(formdata)
      .then((res) => {
        if (res?.data?.status) {
          setViewData(res?.data?.data);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      });
  };

  const updateData = (data: ViewServiceRequestProps) => {
    const organization = organizationList?.find(
      (ele: { orgName: string; orgId: number }) =>
        ele?.orgId === data?.organizationId
    );
    const getDevice = deviceList?.filter((ele) =>
      data?.deviceIds?.includes(ele?.deviceCode?.toString())
    );
    const assignedTo = employeeList?.find(
      (ele) => ele?.userId?.toString() === data?.AssignedTechId
    );
    // @ts-ignore
    setFieldValue("org_name", organization ? organization?.orgName : "");
    // @ts-ignore

    setFieldValue("org_id", organization ? organization?.orgId : "");
    setFieldValue("device", getDevice);
    setFieldValue("device_status", data?.deviceStatusId);
    setFieldValue(
      "error_date",
      data?.errorOccurred ? dayjs(data?.errorOccurred) : null
    );
    setFieldValue(
      "schedule_date",
      data?.scheduledTime ? dayjs(data?.scheduledTime) : null
    );
    setFieldValue("assigned_person", assignedTo ? assignedTo : null);
    setFieldValue("service_status", data?.serviceTypeId);
    setFieldValue(
      "estimate_amount",
      data?.estimatedAmount ? data?.estimatedAmount : "0"
    );
    setFieldValue("priority", data?.priorityId);
    setFieldValue(
      "contact_person",
      data?.contactPerson ? data?.contactPerson : ""
    );
    setFieldValue("phone_number", data?.phNo ? data?.phNo : "");
    setFieldValue("location", data?.location ? data?.location : "");
    setFieldValue(
      "display_msg",
      data?.issueDescription ? data?.issueDescription : ""
    );
    setFieldValue("comments", data?.issueComment ? data?.issueComment : "");
    // setFieldValue("choosenfile" , data?.location);
    setFieldValue(
      "requested_date",
      data?.requestReceived ? dayjs(data?.requestReceived) : null
    );

    setFieldValue(
      "service_start",
      data?.started_datetime ? dayjs(data?.started_datetime) : null
    );
    setFieldValue(
      "service_complete",
      data?.completedDate ? dayjs(data?.completedDate) : null
    );
  };

  const startService = () => {
    const formdata = new FormData();
    formdata.append("serviceRequestId", state?.id);
    startServiceServices(formdata)
      .then((res) => {
        if (res?.data?.status) {
          toast.success(res?.data?.msg);
          setServiceStarted(true);
          viewServiceRequest();
        } else {
          toast.error(res?.data?.msg);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      });
  };

  const listAttachments = (page = 1, size = 10, type: string) => {
    const formdata = new FormData();
    formdata.append("serviceRequestId", state?.id);
    formdata.append("attachmentType", type);

    listAttachmentServices(page, size, formdata)
      .then((res) => {
        if (res?.data?.status) {
          const tempData = res?.data?.data?.items;
          if (type === "1") {
            setImageFilesonUpdate(
              tempData?.map((ele: any) => ({
                filename: ele?.attachmentName,
                file: ele?.attachmentPath,
                id: ele?.attachmentId,
              }))
            );
            setimageUpdatePageDetails({
              page: res?.data?.data?.page,
              pageSize: res?.data?.data?.size,
              total: res?.data?.data?.total_count,
              totalPage: res?.data?.data?.total_page,
            });
            return;
          }
          setImageFiles(
            tempData?.map((ele: any) => ({
              filename: ele?.attachmentName,
              file: ele?.attachmentPath,
              id: ele?.attachmentId,
            }))
          );
          setimagePageDetails({
            page: res?.data?.data?.page,
            pageSize: res?.data?.data?.size,
            total: res?.data?.data?.total_count,
            totalPage: res?.data?.data?.total_page,
          });
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      });
  };
  const deleteAttachments = (id: string, index: number, type: string) => {
    const formdata = new FormData();
    formdata.append("attachmentId", id);
    deleteAttachmentServices(formdata)
      .then((res) => {
        if (res?.data?.status) {
          toast.success(res?.data?.msg);
          if (type === "1") {
            let tempfiles = [...imageFilesonUpdate];
            setImageFilesonUpdate(
              tempfiles?.filter((ele, ind) => index !== ind)
            );
            if ((imageUpdatePageDetails?.total - 1) % 10 === 0) {
              listAttachments(
                imageUpdatePageDetails.page - 1,
                imageUpdatePageDetails.pageSize,
                "1"
              );
            } else {
              listAttachments(
                imageUpdatePageDetails.page,
                imageUpdatePageDetails.pageSize,
                "1"
              );
            }
            return;
          }
          let tempfiles = [...imageFiles];
          setImageFiles(tempfiles?.filter((ele, ind) => index !== ind));
          if ((imagePageDetails?.total - 1) % 10 === 0) {
            listAttachments(
              imagePageDetails.page - 1,
              imagePageDetails.pageSize,
              "2"
            );
          } else {
            listAttachments(
              imagePageDetails.page,
              imagePageDetails.pageSize,
              "2"
            );
          }
        } else {
          toast.error(res?.data?.msg);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      });
  };

  const getImages = () => {
    if (state?.event) {
      return imageFilesonUpdate;
    }
    return imageFiles;
  };

  useEffect(() => {
    getOrganizations();
    getEmployees();
  }, []);
  useEffect(() => {
    if (values.org_id) {
      getDeviceDropdown();
    }
  }, [values.org_id]);

  useEffect(() => {
    if (values.org_id) {
      getOrganizationDetails();
    }
  }, [values.org_id]);

  useEffect(() => {
    if (state?.event) {
      viewServiceRequest();
      listAttachments(1, 10, "1");
      listAttachments(1, 10, "2");
    }
  }, [state]);

  useEffect(() => {
    if (viewData) {
      updateData(viewData);
    }
  }, [organizationList, employeeList, viewData]);

  useEffect(() => {
    if (viewData) {
      const getDevice = deviceList?.filter((ele) =>
        viewData?.deviceIds?.includes(ele?.deviceCode?.toString())
      );
      setFieldValue("device", getDevice);
    }
  }, [deviceList]);

  const closeServiceRequest = () => {
    const formdata = new FormData();
    if (viewData) {
      formdata.append("serviceRequestId", viewData?.serviceId?.toString());
      formdata.append("amount", values.estimate_amount);

      closeServiceRequestServices(formdata)
        .then((res) => {
          if (res?.data?.status) {
            toast.success(res?.data?.msg);
            navigate(-1);
          } else {
            toast.error(res?.data?.msg);
          }
        })
        .catch((err) => {
          getCatchMsg(err);
        });
    }
  };

  useEffect(() => {
    if (dayjs(values.error_date).isAfter(values.schedule_date)) {
      setFieldValue("schedule_date", null);
    }
  }, [values.error_date]);

  return (
    <Grid className="appcontainer">
      {closeService && (
        <GlobalModal
          isVisible={closeService}
          setIsVisible={() => {
            setCloseService(false);
          }}
        >
          <ConfirmationModal
            msg={
              "Are you sure that you want to permanently close this service request"
            }
            onClose={() => {
              setCloseService(false);
            }}
            onPositiveButtonPressed={closeServiceRequest}
            onNegativeButtonPressed={() => {
              setCloseService(false);
            }}
            positiveButtonText="Confirm"
          />
        </GlobalModal>
      )}
      <Grid className="py-3">
        <Grid className="pb-3">
          <PageHeader
            heading={`${
              state?.event === "view"
                ? "View"
                : state?.event === "edit"
                ? "Edit"
                : state?.event === "update"
                ? "Update"
                : "Create"
            } Service Request`}
            Btntitle="Back"
            serachIconStatus={false}
            modal={() => {
              navigate(-1);
            }}
          />
        </Grid>

        {/* Device Failure Information */}
        <Grid className="primarycard mb-3">
          <Typography className={styles.headerlabel}>
            Device Failure Information
          </Typography>

          <Grid container spacing={2}>
            <Grid item lg={2} md={4} sm={6} xs={12}>
              <CustomDropDown
                required="*"
                editName={values.org_name ? values.org_name : "None"}
                title="Organization"
                items={organizationList}
                onSelectedItem={(val, value) => {
                  setFieldValue("org_name", value?.label ? value?.label : "");
                  setFieldValue("org_id", value?.id ? value?.id : "");
                }}
                fieldName="orgName"
                // AddnewOption={true}
                anotherFieldName="orgId"
                value={values.org_id}
                pagination={true}
                disableClearable={!values?.org_id}
                errorText={errors.org_id && touched.org_id ? errors.org_id : ""}
                readOnly={
                  ["update", "view"]?.includes(state?.event) ||
                  viewData?.requestStatusId === 3
                }
              />
            </Grid>
            <Grid className="fieldBr8" item lg={2} md={4} sm={6} xs={12}>
              <TextInputBox title="Device" onChangeText={() => {}} textOnly />
              <Autocomplete
                disableCloseOnSelect
                multiple
                options={deviceList}
                value={values.device}
                className={styles.placeHolder}
                onChange={(e, val) => {
                  setFieldValue("device", val);
                }}
                getOptionLabel={(option) => option.deviceCode}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => {
                  return (
                    <TextField
                      placeholder={values?.device?.length > 0 ? "" : "None"}
                      {...params}
                    />
                  );
                }}
                readOnly={
                  ["update", "view"]?.includes(state?.event) ||
                  viewData?.requestStatusId === 3
                }
                PaperComponent={(props) => {
                  return <Paper {...props} className={muistyle.customSelect} />;
                }}
              />
              <TextInputBox
                title=""
                onChangeText={() => {}}
                textOnly
                errorText={errors.device && touched.device ? errors.device : ""}
              />
            </Grid>
            <Grid className="fieldBr8" item lg={2} md={4} sm={6} xs={12}>
              <TextInputBox
                onChangeText={() => {}}
                title="Device Status"
                textOnly
                requiredText={"*"}
              />

              <TextField
                fullWidth
                size="small"
                select
                value={values.device_status}
                onChange={(e) => {
                  handleChange("device_status")(e.target.value);
                }}
                defaultValue={"-1"}
                sx={{
                  "& .MuiSelect-select": {
                    color:
                      values.device_status === "-1" ? "rgba(0,0,0,0.5)" : "",
                  },
                }}
                placeholder="Device Status"
                InputProps={{
                  readOnly:
                    ["update", "view"]?.includes(state?.event) ||
                    viewData?.requestStatusId === 3,
                  endAdornment: (
                    <Close
                      style={{
                        marginRight: "17px",
                        fontSize: "16px",
                        cursor: "pointer",
                        display:
                          values.device_status === "-1" ||
                          ["update", "view"]?.includes(state?.event) ||
                          viewData?.requestStatusId === 3
                            ? "None"
                            : "block",
                      }}
                      onClick={() => {
                        setFieldValue("device_status", "-1");
                      }}
                    />
                  ),
                }}
              >
                <MenuItem
                  value={"-1"}
                  style={{ visibility: "hidden", height: 0, padding: 0 }}
                >
                  Select Device Status
                </MenuItem>
                <MenuItem value={"1"}>Operational</MenuItem>
                <MenuItem value={"2"}>Partially Operational</MenuItem>
                <MenuItem value={"3"}>Not Operational</MenuItem>
              </TextField>
              <TextInputBox
                title=""
                onChangeText={() => {}}
                textOnly
                errorText={
                  errors.device_status && touched.device_status
                    ? errors.device_status
                    : ""
                }
              />
            </Grid>
            <Grid className="fieldBr8" item lg={2} md={4} sm={6} xs={12}>
              <TextInputBox
                onChangeText={() => {}}
                title="Date of error occured"
                textOnly
                requiredText={"*"}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  disableFuture
                  slotProps={{
                    textField: {
                      fullWidth: true,
                    },
                  }}
                  value={values.error_date}
                  onChange={(value) => {
                    setFieldValue("error_date", value);
                  }}
                  readOnly={
                    ["update", "view"]?.includes(state?.event) ||
                    viewData?.requestStatusId === 3
                  }
                />
                <TextInputBox
                  onChangeText={() => {}}
                  title=""
                  textOnly
                  errorText={
                    errors.error_date && touched.error_date
                      ? errors.error_date
                      : ""
                  }
                  // requiredText={"*"}
                />
              </LocalizationProvider>
            </Grid>
            <Grid className="fieldBr8" item lg={2} md={4} sm={6} xs={12}>
              <TextInputBox
                onChangeText={() => {}}
                title="Date of request"
                textOnly
                // requiredText={"*"}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  readOnly
                  slotProps={{
                    textField: {
                      fullWidth: true,
                    },
                  }}
                  value={values.requested_date}
                  //   onChange={(value) => {
                  //     setFieldValue("error_date", value);
                  //   }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid className="fieldBr8" item lg={2} md={4} sm={6} xs={12}>
              <TextInputBox
                onChangeText={() => {}}
                title="Schedule Date"
                textOnly
                // requiredText={"*"}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  minDate={values.error_date}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                    },
                  }}
                  value={values.schedule_date}
                  onChange={(value) => {
                    setFieldValue("schedule_date", value);
                  }}
                  readOnly={
                    ["update", "view"]?.includes(state?.event) ||
                    viewData?.requestStatusId === 3
                  }
                />
              </LocalizationProvider>
            </Grid>
            <Grid className="fieldBr8" item lg={2} md={4} sm={6} xs={12}>
              <TextInputBox
                title="Assigned to"
                onChangeText={() => {}}
                textOnly
              />
              <Autocomplete
                className={styles.placeHolder}
                options={employeeList}
                value={values.assigned_person}
                onChange={(e, val) => {
                  setFieldValue("assigned_person", val);
                }}
                getOptionLabel={(option) => option.userCode}
                isOptionEqualToValue={(option, value) =>
                  option.userId === value.userId
                }
                renderInput={(params) => {
                  return (
                    <TextField
                      placeholder={values?.assigned_person ? "" : "None"}
                      {...params}
                    />
                  );
                }}
                readOnly={
                  ["update", "view"]?.includes(state?.event) ||
                  viewData?.requestStatusId === 3
                }
                PaperComponent={(props) => {
                  return <Paper {...props} className={muistyle.customSelect} />;
                }}
              />
            </Grid>
            <Grid className="fieldBr8" item lg={2} md={4} sm={6} xs={12}>
              <TextInputBox
                onChangeText={() => {}}
                title="Service Status"
                textOnly
                requiredText={"*"}
              />

              <TextField
                fullWidth
                size="small"
                select
                value={values.service_status}
                onChange={(e) => {
                  handleChange("service_status")(e.target.value);
                }}
                defaultValue={"-1"}
                sx={{
                  "& .MuiSelect-select": {
                    color:
                      values.service_status === "-1" ? "rgba(0,0,0,0.5)" : "",
                  },
                }}
                placeholder="Service Status"
                InputProps={{
                  readOnly:
                    ["update", "view"]?.includes(state?.event) ||
                    viewData?.requestStatusId === 3,
                  endAdornment: (
                    <Close
                      style={{
                        marginRight: "17px",
                        fontSize: "16px",
                        cursor: "pointer",
                        display:
                          values.service_status === "-1" ||
                          ["update", "view"]?.includes(state?.event) ||
                          viewData?.requestStatusId === 3
                            ? "None"
                            : "block",
                      }}
                      onClick={() => {
                        setFieldValue("service_status", "-1");
                      }}
                    />
                  ),
                }}
              >
                <MenuItem
                  value={"-1"}
                  style={{ visibility: "hidden", height: 0, padding: 0 }}
                >
                  Select Service Status
                </MenuItem>
                <MenuItem value={"1"}>Free</MenuItem>
                <MenuItem value={"2"}>Chargable</MenuItem>
              </TextField>
              <TextInputBox
                title=""
                onChangeText={() => {}}
                textOnly
                errorText={
                  errors.service_status && touched.service_status
                    ? errors.service_status
                    : ""
                }
              />
            </Grid>

            {values.service_status === "2" && (
              <Grid item lg={2} md={4} sm={6} xs={12}>
                <TextInputBox
                  title="Estimated Amount"
                  onChangeText={(e) => {
                    const numericValue = e?.replace(/[^0-9]/g, "");
                    handleChange("estimate_amount")(numericValue);
                  }}
                  customInputProps={{
                    type: "number",
                    step: 100,
                    min: 0,
                    onKeyDown: (e) => {
                      if (e.key === "e") {
                        e.preventDefault();
                      }
                    },
                  }}
                  value={values.estimate_amount}
                  readOnly={
                    ["update", "view"]?.includes(state?.event) ||
                    viewData?.requestStatusId === 3
                  }
                />
              </Grid>
            )}

            {viewData?.started_datetime && (
              <Grid className="fieldBr8" item lg={2} md={4} sm={6} xs={12}>
                <TextInputBox
                  onChangeText={() => {}}
                  title="Service Started Date"
                  textOnly
                  // requiredText={"*"}
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    slotProps={{
                      textField: {
                        fullWidth: true,
                      },
                    }}
                    value={values.service_start}
                    onChange={(value) => {
                      setFieldValue("service_start", value);
                    }}
                    readOnly
                  />
                </LocalizationProvider>
              </Grid>
            )}

            {viewData?.completedDate && (
              <Grid className="fieldBr8" item lg={2} md={4} sm={6} xs={12}>
                <TextInputBox
                  onChangeText={() => {}}
                  title="Service Completed Date"
                  textOnly
                  // requiredText={"*"}
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    slotProps={{
                      textField: {
                        fullWidth: true,
                      },
                    }}
                    value={values.service_complete}
                    onChange={(value) => {
                      setFieldValue("service_complete", value);
                    }}
                    readOnly
                  />
                </LocalizationProvider>
              </Grid>
            )}
            <Grid className="fieldBr8" item lg={2} md={4} sm={6} xs={12}>
              <TextInputBox
                onChangeText={() => {}}
                title="Priority Level"
                textOnly
                requiredText={"*"}
              />

              <TextField
                fullWidth
                size="small"
                select
                value={values.priority}
                onChange={(e) => {
                  handleChange("priority")(e.target.value);
                }}
                defaultValue={"-1"}
                sx={{
                  "& .MuiSelect-select": {
                    color: values.priority === "-1" ? "rgba(0,0,0,0.5)" : "",
                  },
                }}
                placeholder="Priority Level"
                InputProps={{
                  readOnly:
                    ["update", "view"]?.includes(state?.event) ||
                    viewData?.requestStatusId === 3,
                  endAdornment: (
                    <Close
                      style={{
                        marginRight: "17px",
                        fontSize: "16px",
                        cursor: "pointer",
                        display:
                          values.priority === "-1" ||
                          ["update", "view"]?.includes(state?.event) ||
                          viewData?.requestStatusId === 3
                            ? "None"
                            : "block",
                      }}
                      onClick={() => {
                        setFieldValue("priority", "-1");
                      }}
                    />
                  ),
                }}
              >
                <MenuItem
                  value={"-1"}
                  style={{ visibility: "hidden", height: 0, padding: 0 }}
                >
                  Select Priority Level
                </MenuItem>
                <MenuItem value={"1"}>Low</MenuItem>
                <MenuItem value={"2"}>Medium</MenuItem>
                <MenuItem value={"3"}>High</MenuItem>
              </TextField>
              <TextInputBox
                title=""
                onChangeText={() => {}}
                textOnly
                errorText={
                  errors.priority && touched.priority ? errors.priority : ""
                }
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Contact Details */}
        <Grid className="mb-3">
          <Grid container spacing={2}>
            <Grid item lg={6} xs={12}>
              <Grid className="primarycard h-100">
                <Typography className={styles.headerlabel}>
                  Contact Details
                </Typography>

                <Grid container spacing={2}>
                  <Grid item lg={4} md={6} xs={12}>
                    <TextInputBox
                      title="Contact Person"
                      placeHolder="Contact Person"
                      value={values.contact_person}
                      onChangeText={(e) => {
                        setFieldValue("contact_person", e);
                      }}
                      readOnly={
                        ["update", "view"]?.includes(state?.event) ||
                        viewData?.requestStatusId === 3
                      }
                    />
                  </Grid>
                  <Grid item lg={4} md={6} xs={12}>
                    <TextInputBox
                      title="Phone Number"
                      placeHolder="Phone Number"
                      onChangeText={(e) => {
                        const numericValue = e?.replace(/[^0-9]/g, "");
                        handleChange("phone_number")(numericValue);
                      }}
                      // customInputProps={{
                      //   maxLength: 10,
                      // }}
                      textLength={10}
                      value={values.phone_number}
                      readOnly={
                        ["update", "view"]?.includes(state?.event) ||
                        viewData?.requestStatusId === 3
                      }
                    />
                  </Grid>
                  <Grid item lg={4} md={6} xs={12}>
                    <TextInputBox
                      title="Location"
                      placeHolder="Location"
                      value={values.location}
                      onChangeText={(e) => {
                        setFieldValue("location", e);
                      }}
                      readOnly={
                        ["update", "view"]?.includes(state?.event) ||
                        viewData?.requestStatusId === 3
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={6} xs={12}>
              <Grid className="primarycard h-100">
                <Typography className={styles.headerlabel}>
                  Problem Details
                </Typography>
                <Grid container spacing={2}>
                  <Grid item lg={6} xs={12}>
                    <TextInputBox
                      title="Message On Display"
                      placeHolder="Message On Display"
                      value={values.display_msg}
                      onChangeText={(e) => {
                        setFieldValue("display_msg", e);
                      }}
                      readOnly={
                        ["update", "view"]?.includes(state?.event) ||
                        viewData?.requestStatusId === 3
                      }
                    />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <TextInputBox
                      title="Comments"
                      onChangeText={() => {}}
                      textOnly
                    />
                    <textarea
                      className="textarea"
                      placeholder="Comments"
                      value={values.comments}
                      onChange={(e) => {
                        setFieldValue("comments", e.target.value);
                      }}
                      readOnly={
                        ["update", "view"]?.includes(state?.event) ||
                        viewData?.requestStatusId === 3
                      }
                    ></textarea>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* File Upload */}

        <Grid>
          <Grid className="primarycard mb-3">
            <Typography className={styles.headerlabel}>File Upload</Typography>

            {state?.event !== "view" &&
              state?.event !== "update" &&
              viewData?.requestStatusId !== 3 && (
                <Grid container spacing={2}>
                  <Grid item lg={2} md={4} sm={6} xs={12}>
                    <TextInputBox
                      title="File Name"
                      placeHolder="Enter your file name"
                      onChangeText={(e) => {
                        setFieldValue("choosenfile", e);
                      }}
                      value={values.choosenfile}
                      readOnly={["update", "view"]?.includes(state?.event)}
                    />
                  </Grid>
                  <Grid item lg={2} md={4} sm={6} xs={12}>
                    <TextInputBox
                      title="Choose your file"
                      onChangeText={() => {}}
                      textOnly
                    />
                    <input
                      onChange={(e) => {
                        if (e?.target?.files?.[0]) {
                          if (
                            imageFilesonUpdate
                              ?.map((ele) => ele?.filename)
                              ?.includes(values.choosenfile)
                          ) {
                            toast.error("File name already exists");
                            return;
                          }
                          setImageFilesonUpdate((pre) => [
                            ...pre,
                            {
                              filename: values.choosenfile
                                ? values.choosenfile
                                : e?.target?.files?.[0]?.name || "",
                              file: e?.target?.files?.[0] || "",
                              id: "",
                            },
                          ]);
                          setFieldValue("choosenfile", "");
                        }
                      }}
                      readOnly={["update", "view"]?.includes(state?.event)}
                      style={{
                        display: "None",
                      }}
                      id="inputElement"
                      //   multiple
                      type="file"
                      // accept="image/*"
                    />
                    <Grid
                      onClick={() => {
                        if (!["update", "view"]?.includes(state?.event)) {
                          document.getElementById("inputElement")?.click();
                        }
                      }}
                      className={styles.choosefile}
                    >
                      Choose File
                    </Grid>
                  </Grid>
                </Grid>
              )}

            <Grid className="d-flex flex-wrap pt-2">
              {[...imageFilesonUpdate?.filter((ele) => !ele?.id)]?.map(
                (element, index) => {
                  return (
                    <ImageItem
                      onDelete={() => {
                        let tempfiles = [...imageFilesonUpdate];
                        setImageFilesonUpdate(
                          tempfiles?.filter(
                            (ele, ind) => ele?.filename !== element?.filename
                          )
                        );
                      }}
                      onView={() => {
                        setViewImage({
                          status: true,
                          file:
                            typeof element?.file === "string"
                              ? element?.file
                              : element?.file.type?.includes("image")
                              ? URL.createObjectURL(element?.file)
                              : file,
                        });
                      }}
                      ele={element}
                      key={index}
                      disable={
                        !state?.event ||
                        (state?.event === "edit" &&
                          viewData?.requestStatusId !== 3)
                          ? false
                          : true
                      }
                      height={50}
                    />
                  );
                }
              )}
              {[...imageFilesonUpdate?.filter((ele) => !ele?.id)]?.length > 0 &&
                viewData?.serviceId && (
                  <Button
                    style={{
                      fontSize: "14px",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      marginTop: "auto",
                      marginBottom: "auto",
                      background: "rgba(0,0,0,0.2)",
                      textTransform: "capitalize",
                      color: "black",
                    }}
                    onClick={() => {
                      if (viewData?.serviceId) {
                        uploadFiles(viewData?.serviceId?.toString(), "1", [
                          ...imageFilesonUpdate?.filter((ele) => !ele?.id),
                        ]);
                      }
                    }}
                  >
                    Upload Files
                  </Button>
                )}
            </Grid>

            <Grid className="d-flex flex-wrap pt-2">
              {[...imageFilesonUpdate?.filter((ele) => ele?.id)]?.map(
                (ele, index) => {
                  return (
                    <ImageItem
                      onDelete={() => {
                        if (state?.event && ele?.id) {
                          deleteAttachments(ele?.id, index, "1");
                        } else {
                          let tempfiles = [...imageFilesonUpdate];
                          setImageFilesonUpdate(
                            tempfiles?.filter((ele, ind) => index !== ind)
                          );
                        }
                      }}
                      onView={() => {
                        setViewImage({
                          status: true,
                          file:
                            typeof ele?.file === "string"
                              ? ele?.file
                              : URL.createObjectURL(ele?.file),
                        });
                      }}
                      ele={ele}
                      key={index}
                      disable={
                        state?.event === "update" ||
                        viewData?.requestStatusId === 3 ||
                        state?.event === "view"
                          ? true
                          : false
                      }
                    />
                  );
                }
              )}
            </Grid>
            {imageFilesonUpdate?.length && imageUpdatePageDetails?.total ? (
              <CustomPaginationwithdropdownpagination
                onChangePage={(page: number) => {
                  listAttachments(page, imageUpdatePageDetails.pageSize, "1");
                  setimageUpdatePageDetails((pre) => ({
                    ...pre,
                    page: page,
                  }));
                }}
                onChangePagerow={(PageSize: number) => {
                  if (
                    imageUpdatePageDetails.page * PageSize >
                    imageUpdatePageDetails.total
                  ) {
                    listAttachments(1, PageSize, "1");
                    setimageUpdatePageDetails((pre) => ({
                      ...pre,
                      pageSize: PageSize,
                    }));
                    return;
                  }
                  listAttachments(imageUpdatePageDetails.page, PageSize, "1");
                  setimageUpdatePageDetails((pre) => ({
                    ...pre,
                    pageSize: PageSize,
                  }));
                }}
                count={imageUpdatePageDetails?.total}
                pages={imageUpdatePageDetails.page}
                pageCount={imageUpdatePageDetails?.totalPage}
                handleFunction={(selected: number) => {
                  setimageUpdatePageDetails((pre) => ({
                    ...pre,
                    page: selected,
                  }));

                  listAttachments(
                    selected,
                    imageUpdatePageDetails.pageSize,
                    "1"
                  );
                }}
                labelRowsPerPage={"Images Per Page"}
                rowsperpage={imageUpdatePageDetails.pageSize}
                totalData={{
                  totalCount: imageUpdatePageDetails.total,
                  size: imageUpdatePageDetails.pageSize,
                  page: imageUpdatePageDetails.page,
                }}
              />
            ) : null}
            {viewImage?.status && (
              <ImageView
                src={viewImage?.file}
                onclose={() => {
                  setViewImage((pre) => ({ ...pre, status: false }));
                }}
              />
            )}

            {(state?.event === "view" ||
              viewData?.requestStatusId === 3 ||
              state?.event === "update") &&
              imageFilesonUpdate.length === 0 && (
                <Grid>
                  <img className={styles.nodata} src={nodata} />
                  <Typography className={styles.nodataText}>
                    No images found !
                  </Typography>
                </Grid>
              )}
          </Grid>
        </Grid>

        {/* submit */}

        {(!viewData?.requestStatusId || state?.event === "edit") &&
          viewData?.requestStatusId !== 3 && (
            <Grid>
              <CustomButton
                title="Save"
                customButtonStyle={{
                  maxWidth: "130px",
                  display: "block",
                  margin: "auto",
                }}
                onButtonPress={() => {
                  handleSubmit();
                }}
              />
            </Grid>
          )}

        {viewData?.requestStatusId === 1 && state?.event === "update" && (
          <Grid className="pb-3">
            <CustomButton
              title={!ServiceStarted ? "Start Service" : "Service Started"}
              customButtonStyle={{
                maxWidth: "180px",
                display: "block",
                margin: "auto",
              }}
              onButtonPress={() => {
                if (!ServiceStarted) {
                  startService();
                }
              }}
            />
          </Grid>
        )}

        {/* Task Details */}

        {viewData?.requestStatusId !== 1 &&
          state?.event !== "edit" &&
          viewData && (
            <Grid className="primarycard mb-3">
              <Typography className={styles.headerlabel}>
                Task Details
              </Typography>

              {state?.id && (
                <ServiceRequestTaskApp
                  org_id={state?.id}
                  viewStatus={
                    state?.event === "view" || viewData?.requestStatusId === 3
                      ? true
                      : false
                  }
                />
              )}
            </Grid>
          )}

        {/* File Upload on Updates */}

        {viewData?.requestStatusId !== 1 &&
          state?.event !== "edit" &&
          viewData && (
            <Grid>
              <Grid className="primarycard mb-3">
                <Typography className={styles.headerlabel}>
                  File Upload
                </Typography>

                {viewData?.requestStatusId !== 3 && state?.event !== "view" && (
                  <Grid container spacing={2}>
                    <Grid item lg={2} md={4} sm={6} xs={12}>
                      <TextInputBox
                        title="File Name"
                        placeHolder="Enter your file name"
                        onChangeText={(e) => {
                          setFieldValue("choosenfile", e);
                        }}
                        value={values.choosenfile}
                        readOnly={["view"]?.includes(state?.event)}
                      />
                    </Grid>
                    <Grid item lg={2} md={4} sm={6} xs={12}>
                      <TextInputBox
                        title="Choose your file"
                        onChangeText={() => {}}
                        textOnly
                      />
                      <input
                        onChange={(e) => {
                          if (e?.target?.files?.[0]) {
                            if (
                              imageFiles
                                ?.map((ele) => ele?.filename)
                                ?.includes(values.choosenfile)
                            ) {
                              toast.error("File name already exists");
                              return;
                            }
                            setImageFiles((pre) => [
                              ...pre,
                              {
                                filename: values.choosenfile
                                  ? values.choosenfile
                                  : e?.target?.files?.[0]?.name || "",
                                file: e?.target?.files?.[0] || "",
                                id: "",
                              },
                            ]);
                            setFieldValue("choosenfile", "");
                          }
                        }}
                        readOnly={["view"]?.includes(state?.event)}
                        style={{
                          display: "None",
                        }}
                        id="inputElement"
                        //   multiple
                        type="file"
                        accept="image/*"
                      />
                      <Grid
                        onClick={() => {
                          if (!["view"]?.includes(state?.event)) {
                            document.getElementById("inputElement")?.click();
                          }
                        }}
                        className={styles.choosefile}
                      >
                        Choose File
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                <Grid className="d-flex flex-wrap pt-2">
                  {[...imageFiles?.filter((ele) => !ele?.id)]?.map(
                    (ele, index) => {
                      return (
                        <ImageItem
                          onDelete={() => {
                            let tempfiles = [...imageFiles];
                            setImageFiles(
                              tempfiles?.filter((ele, ind) => index !== ind)
                            );
                          }}
                          onView={() => {
                            setViewImage({
                              status: true,
                              file:
                                typeof ele?.file === "string"
                                  ? ele?.file
                                  : URL.createObjectURL(ele?.file),
                            });
                          }}
                          ele={ele}
                          key={index}
                          disable={state?.event === "update" ? true : false}
                          height={50}
                        />
                      );
                    }
                  )}
                  {[...imageFiles?.filter((ele) => !ele?.id)]?.length > 0 && (
                    <Button
                      style={{
                        fontSize: "14px",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        marginTop: "auto",
                        marginBottom: "auto",
                        background: "rgba(0,0,0,0.2)",
                        textTransform: "capitalize",
                        color: "black",
                      }}
                      onClick={() => {
                        if (viewData?.serviceId) {
                          uploadFiles(viewData?.serviceId?.toString(), "2", [
                            ...imageFiles?.filter((ele) => !ele?.id),
                          ]);
                        }
                      }}
                    >
                      Upload Files
                    </Button>
                  )}
                </Grid>

                <Grid className="d-flex flex-wrap pt-2">
                  {[...imageFiles]
                    ?.filter((ele) => ele?.id)
                    ?.map((ele, index) => {
                      return (
                        <ImageItem
                          onDelete={() => {
                            if (imagePageDetails?.total && ele?.id) {
                              deleteAttachments(ele?.id, index, "2");
                            } else {
                              let tempfiles = [...imageFiles];
                              setImageFiles(
                                tempfiles?.filter((ele, ind) => index !== ind)
                              );
                            }
                            let tempfiles = [...imageFiles];
                            setImageFiles(
                              tempfiles?.filter((ele, ind) => index !== ind)
                            );
                          }}
                          onView={() => {
                            setViewImage({
                              status: true,
                              file:
                                typeof ele?.file === "string"
                                  ? ele?.file
                                  : URL.createObjectURL(ele?.file),
                            });
                          }}
                          ele={ele}
                          key={index}
                          disable={
                            state?.event !== "update" ||
                            viewData?.requestStatusId === 3
                              ? true
                              : false
                          }
                        />
                      );
                    })}
                </Grid>
                {viewImage?.status && (
                  <ImageView
                    src={viewImage?.file}
                    onclose={() => {
                      setViewImage((pre) => ({ ...pre, status: false }));
                    }}
                  />
                )}
                {imageFiles?.length && imagePageDetails?.total ? (
                  <CustomPaginationwithdropdownpagination
                    onChangePage={(page: number) => {
                      listAttachments(page, imagePageDetails.pageSize, "2");
                      setimagePageDetails((pre) => ({
                        ...pre,
                        page: page,
                      }));
                    }}
                    onChangePagerow={(PageSize: number) => {
                      if (
                        imagePageDetails.page * PageSize >
                        imagePageDetails.total
                      ) {
                        listAttachments(1, PageSize, "2");
                        setimagePageDetails((pre) => ({
                          ...pre,
                          pageSize: PageSize,
                        }));
                        return;
                      }
                      listAttachments(imagePageDetails.page, PageSize, "2");
                      setimagePageDetails((pre) => ({
                        ...pre,
                        pageSize: PageSize,
                      }));
                    }}
                    count={imagePageDetails?.total}
                    pages={imagePageDetails.page}
                    pageCount={imagePageDetails?.totalPage}
                    handleFunction={(selected: number) => {
                      setimagePageDetails((pre) => ({
                        ...pre,
                        page: selected,
                      }));

                      listAttachments(selected, imagePageDetails.pageSize, "1");
                    }}
                    labelRowsPerPage={"Images Per Page"}
                    rowsperpage={imagePageDetails.pageSize}
                    totalData={{
                      totalCount: imagePageDetails.total,
                      size: imagePageDetails.pageSize,
                      page: imagePageDetails.page,
                    }}
                  />
                ) : null}

                {(state?.event === "view" || viewData?.requestStatusId === 3) &&
                  imageFiles.length === 0 && (
                    <Grid>
                      <img className={styles.nodata} src={nodata} />
                      <Typography className={styles.nodataText}>
                        No images found !
                      </Typography>
                    </Grid>
                  )}
              </Grid>
            </Grid>
          )}

        {/* Update */}

        {state?.event === "update" &&
          viewData &&
          viewData?.requestStatusId === 2 && (
            <Grid className="pt-4 d-flex justify-content-center">
              <CustomButton
                title="Close Service"
                customButtonStyle={{
                  maxWidth: "130px",
                  display: "block",
                  margin: "auto",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }}
                onButtonPress={() => {
                  // uploadFiles(
                  //   viewData?.serviceId?.toString(),
                  //   "2",
                  //   [...imageFiles]?.filter((ele) => !ele?.id)
                  // );
                  setCloseService(true);
                }}
              />
              <CustomButton
                title="Exit"
                customButtonStyle={{
                  maxWidth: "130px",
                  display: "block",
                  margin: "auto",
                  minWidth: "80px",
                  backgroundColor: "rgba(0,0,0,0.2)",
                  color: "black",
                  marginLeft: "10px",
                }}
                onButtonPress={() => {
                  // uploadFiles(
                  //   viewData?.serviceId?.toString(),
                  //   "2",
                  //   [...imageFiles]?.filter((ele) => !ele?.id)
                  // );
                  navigate(-1);
                }}
              />
            </Grid>
          )}
      </Grid>
    </Grid>
  );
}
