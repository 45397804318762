import classes from "./Modal.module.css";
import close from "../Assets/Icons/Svg/close.svg";

interface ModalHeaderType {
  onClose: () => void;
  heading?: string | JSX.Element;
}

export default function ModalHeader({
  onClose,
  heading = "",
}: ModalHeaderType) {
  return (
    <>
      <div className={classes.popup}>
        <div className={classes.popup_head}>
          <div className={classes.popup_head_left}>
            <div className={classes.line}></div>
            <div>
              <p className={classes.Heading}>{heading}</p>
            </div>
          </div>
        </div>
        <img className={classes.close} src={close} alt="" onClick={onClose} />
      </div>
    </>
  );
}
