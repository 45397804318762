import React, { useEffect, useState } from "react";
import PageHeader from "../../MangementLayoutHeader/PageHeader";
import { useLocation, useNavigate } from "react-router-dom";
import {
  deleteMasterInnerQuestion,
  masterInnerquestionList,
} from "../../../Services/Services";
import { getCatchMsg, isFormDirty } from "../../../Utility/GeneralUtils";
import { useFormik } from "formik";
import Tooltip from "@mui/material/Tooltip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Grid } from "@material-ui/core";
import { Box, Tab } from "@mui/material";
import classes from "./Management.module.css";
import {
  CustomPaginationwithdropdownpagination,
  GlobalModal,
  NodataLoader,
  TableRowsLoader,
  TextInputBox,
} from "../../../Components";
import Inbox from "../../../Assets/Images/Png/noData.png";
import edit from "../../../Assets/Icons/Svg/edit.svg";
import toast from "react-hot-toast";
import delete_icon from "../../../Assets/Icons/Svg/delete.svg";
import { questionListTableListDataProps } from "./managementTypes";
import AddInnerQuestion from "../../../Modals/AddInnerQuestion";
import { ConfirmationModal } from "../../../Modals";
import SearchAndClearbutton from "../../../Components/SearchAndClearbutton";
import { openLoader } from "../../../Store/Reducers/LoaderSlice";
import { useDispatch } from "react-redux";
import AccordionContent from "../../../Components/AccordionContent";

function QuestionManagemenDetails() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [showFilterOption, setShowFilterOption] = useState(false);
  const [noDataLoader, setnoDataLoader] = useState(false);
  const { values, resetForm, handleChange, initialValues } = useFormik({
    initialValues: {
      name: "",
    },
    onSubmit: () => {},
  });
  const [isShowModal, setIsShowModal] = useState({
    status: false,
    data: false,
    viewStatus: false,
  });
  const [deleteModal, setdeleteModal] = useState({
    modal: false,
    id: "",
  });
  const [questionList, setQuestionList] =
    useState<questionListTableListDataProps | null>(null);

  const handleTableSearch = (data: string) => {
    if (data === "Enter") {
      setPage(1);
      handleViewserver();
    }
  };
  const handleTableSearchClear = () => {
    setPage(1);
    resetForm();
    handleViewserver(undefined, undefined, true);
  };
  const handleViewserver = (
    page = questionList?.data?.page === undefined
      ? 1
      : questionList?.data?.page,
    size = 10,
    resetValue?: boolean
  ) => {
    setnoDataLoader(true);
    dispatch(openLoader(true));
    let formData = new FormData();
    formData.append("question", resetValue ? "" : values?.name);
    formData.append("questionMasterId", state.questionMasterId);
    masterInnerquestionList(page, size, formData)
      .then((response) => {
        if (response.data.status === 1) {
          setQuestionList(response.data);
          setPage(response?.data?.data?.page || 1);
        }
      })
      .catch((err) => getCatchMsg(err))
      .finally(() => {
        setnoDataLoader(false);
        dispatch(openLoader(false));
      });
  };
  const handleDeleteMasterQuestion = () => {
    dispatch(openLoader(true));
    let formData = new FormData();
    formData.append("questionSetId", deleteModal?.id);
    deleteMasterInnerQuestion(formData)
      .then((response) => {
        if (response?.data?.status === 1) {
          handleViewserver(questionList?.data?.page);
          toast.success(response?.data?.msg);
        } else if (response?.data?.status === 0) {
          toast.error(response?.data?.msg);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        dispatch(openLoader(false));
        setdeleteModal((prev) => {
          return {
            ...prev,
            modal: false,
          };
        });
      });
  };

  useEffect(() => {
    if (state) {
      handleViewserver();
    }
  }, [state]);

  return (
    <div>
      <PageHeader
        heading="Question List"
        Btntitle="Back"
        BtntitleOne="Add Question"
        modal={() => navigate(-1)}
        onPressOvertime={() => {
          setIsShowModal((prev: any) => {
            return {
              ...prev,
              status: true,
              data: null,
              viewStatus: false,
            };
          });
        }}
        onFilterbutton={() => {
          setShowFilterOption((pre) => !pre);
        }}
      />
      {isShowModal?.status && (
        <GlobalModal
          size="lg"
          modalStyle="globalModalmaxWidth logoutmodalminWidth"
          isVisible={isShowModal.status}
          setIsVisible={() => {
            setIsShowModal((prev) => {
              return {
                ...prev,
                status: true,
              };
            });
          }}
        >
          <AddInnerQuestion
            QuestionMasterId={state?.questionMasterId}
            listApiCall={(value) => {
              if (value === "update") {
                handleViewserver(page);
                return;
              }
              handleViewserver();
            }}
            editData={isShowModal?.data || null}
            onClose={() => {
              setIsShowModal((prev) => {
                return {
                  ...prev,
                  status: false,
                };
              });
            }}
            modalClose={() => {
              setIsShowModal((prev) => {
                return {
                  ...prev,
                  status: false,
                };
              });
            }}
          />
        </GlobalModal>
      )}
      {deleteModal?.modal && (
        <GlobalModal
          isVisible={deleteModal.modal}
          setIsVisible={() => {
            setdeleteModal((prev) => {
              return {
                ...prev,
                modal: false,
              };
            });
          }}
        >
          <ConfirmationModal
            msg={`Are you sure that you want to permanently delete this question.`}
            onClose={() => {
              setdeleteModal((prev) => {
                return {
                  ...prev,
                  modal: false,
                };
              });
            }}
            onPositiveButtonPressed={handleDeleteMasterQuestion}
            onNegativeButtonPressed={() => {
              setdeleteModal((prev) => {
                return {
                  ...prev,
                  modal: false,
                };
              });
            }}
          />
        </GlobalModal>
      )}
      {/* {showFilterOption ? (
       
      ) : null} */}
      <AccordionContent filterStat={showFilterOption}>
        <div className={classes.serachContainer}>
          <div className="row mb-3">
            <Grid item lg={2} md={4} sm={6} xs={12}>
              <TextInputBox
                title="Name"
                textLength={50}
                customInputProps={{
                  onKeyDown: (e) => handleTableSearch(e.key),
                }}
                name="name"
                placeHolder="Search question"
                value={values.name}
                onChangeText={handleChange("name")}
              />
            </Grid>
          </div>
          <SearchAndClearbutton
            handleTableSearch={handleTableSearch}
            handleTableSearchClear={handleTableSearchClear}
            disabled={!isFormDirty(initialValues, values)}
          />
        </div>
      </AccordionContent>
      <div className={`table-responsive ${classes.AllTable}`}>
        <Box>
          <TableContainer>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>S.No</TableCell>
                  <TableCell>Question</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {questionList?.data?.items?.length === 0 ? (
                  <>
                    {noDataLoader ? (
                      <TableRow>
                        <TableCell className={classes.noData} colSpan={9}>
                          <NodataLoader />
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableRow>
                        <TableCell colSpan={9}>
                          <div className={classes.nodatafound}>
                            <img
                              src={Inbox}
                              alt="inbox"
                              width={100}
                              height={100}
                            />
                            <p>No data found</p>
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                ) : (
                  <>
                    {noDataLoader ? (
                      <TableRowsLoader columnNum={6} rowsNum={10} />
                    ) : questionList?.data?.items?.length === 0 ||
                      !questionList ? (
                      <TableRow>
                        <TableCell colSpan={9}>
                          <div className={classes.nodatafound}>
                            <img
                              src={Inbox}
                              alt="inbox"
                              width={100}
                              height={100}
                            />
                            <p>No data found</p>
                          </div>
                        </TableCell>
                      </TableRow>
                    ) : (
                      questionList?.data?.items?.map(
                        (categorization: any, index: number) => (
                          <TableRow key={index}>
                            <TableCell>
                              {(questionList?.data?.page - 1) *
                                questionList?.data?.size +
                                index +
                                1}
                            </TableCell>
                            <TableCell>
                              {categorization?.QuestionSetName === null
                                ? "-"
                                : categorization?.QuestionSetName}
                            </TableCell>
                            <TableCell>
                              <div className={classes.icons}>
                                <Tooltip title="Edit" placement="top">
                                  <img
                                    src={edit}
                                    onClick={() => {
                                      setIsShowModal((prev) => {
                                        return {
                                          ...prev,
                                          status: true,
                                          data: categorization,
                                          viewStatus: false,
                                        };
                                      });
                                    }}
                                    alt="Edit"
                                  />
                                </Tooltip>
                                <Tooltip title="Delete" placement="top">
                                  <img
                                    src={delete_icon}
                                    alt="Delete"
                                    onClick={() =>
                                      setdeleteModal((prev) => {
                                        return {
                                          ...prev,
                                          modal: true,
                                          id: categorization.questionSetId.toString(),
                                        };
                                      })
                                    }
                                  />
                                </Tooltip>
                              </div>
                            </TableCell>
                          </TableRow>
                        )
                      )
                    )}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </div>
      {questionList?.data?.items?.length ? (
        <CustomPaginationwithdropdownpagination
          onChangePage={(page: number) => {
            handleViewserver(page, pageSize);
            setPage(page);
          }}
          onChangePagerow={(PageSize: number) => {
            if (page * PageSize > questionList?.data?.totalCount) {
              handleViewserver(1, PageSize);
              setPageSize(PageSize);
              return;
            }
            handleViewserver(page, PageSize);
            setPageSize(PageSize);
          }}
          count={questionList?.data?.totalCount}
          pages={page}
          pageCount={questionList?.data?.total_page}
          handleFunction={(selected: number) => {
            setPage(selected);
            handleViewserver(selected, pageSize);
          }}
          labelRowsPerPage={"Devices Per Page"}
          rowsperpage={pageSize}
          totalData={questionList?.data}
        />
      ) : null}
    </div>
  );
}

export default QuestionManagemenDetails;
