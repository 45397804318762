import { useCallback, useState } from "react";
import Cropper, { Area } from "react-easy-crop";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
} from "@material-ui/core";
import PageHeader from "../Views/MangementLayoutHeader/PageHeader";

const useStyles = makeStyles((theme) => ({
  Dialog: {
    "&>div>div": {
      width: "100%",
      maxWidth: "500px",
      padding: "0px",
      height: "100%",
      maxHeight: "500px",
    },
  },
  CropperContainer: {
    maxWidth: "500px",
    maxHeight: "360px",
    margin: "auto",
    width: "calc(100% - 40px)",
    height: "calc(100% - 40px)",
    display: "none",
  },
  HeaderContainer: {
    "&>div>div": {
      padding: "0px !important",
    },
  },
  OkBtn: {
    background: "#F25922",
    color: "white",
    fontSize: "13px",
    fontWeight: 600,
    "&:hover": {
      background: "#F25922",
    },
  },
}));

export type ImageCropProps = {
  src: string;
  setCropImage: (value?: string) => void;
  setFieldValue: (val1: string, val2: Blob | File) => void;
};
export type CropAreaPixelProps = {
  height: number;
  width: number;
  x: number;
  y: number;
};
export const createImage = (url: string) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

export function getRadianAngle(degreeValue: number) {
  return (degreeValue * Math.PI) / 180;
}

/**
 * Returns the new bounding area of a rotated rectangle.
 */
export function rotateSize(width: number, height: number, rotation: number) {
  const rotRad = getRadianAngle(rotation);

  return {
    width:
      Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
      Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  };
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 */
export default function ImageCropModal({
  src,
  setCropImage,
  setFieldValue,
}: ImageCropProps) {
  const classes = useStyles();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [CroppedAreaPixels, setCroppedAreaPixels] =
    useState<CropAreaPixelProps>();
  const [croppedImage, setcroppedImage] = useState<string | null>(null);

  const onCropComplete = useCallback(
    (croppedArea: Area, croppedAreaPixels: Area) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    []
  );

  async function getCroppedImg(
    imageSrc = src,
    pixelCrop = CroppedAreaPixels,
    rotation = 0,
    flip = { horizontal: false, vertical: false }
  ) {
    const image: any = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    if (!ctx) {
      return null;
    }

    const rotRad = getRadianAngle(rotation);

    // calculate bounding box of the rotated image
    const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
      image.width,
      image.height,
      rotation
    );

    // set canvas size to match the bounding box
    canvas.width = bBoxWidth;
    canvas.height = bBoxHeight;

    // translate canvas context to a central location to allow rotating and flipping around the center
    ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
    ctx.rotate(rotRad);
    ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
    ctx.translate(-image.width / 2, -image.height / 2);

    // draw rotated image
    ctx.drawImage(image, 0, 0);

    const croppedCanvas = document.createElement("canvas");

    const croppedCtx = croppedCanvas.getContext("2d");

    if (!croppedCtx) {
      return null;
    }

    // Set the size of the cropped canvas
    if (pixelCrop) {
      croppedCanvas.width = pixelCrop.width;
      croppedCanvas.height = pixelCrop.height;

      // Draw the cropped image onto the new canvas
      croppedCtx.drawImage(
        canvas,
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height,
        0,
        0,
        pixelCrop.width,
        pixelCrop.height
      );
    }

    // As Base64 string
    // return croppedCanvas.toDataURL('image/jpeg');

    // As a blob
    return new Promise((resolve, reject) => {
      croppedCanvas.toBlob((file: any) => {
        resolve(URL.createObjectURL(file));
        const blob = new File(
          [file],
          `user_image.${file.type?.split("/")?.[1]}`,
          {
            type: file.type,
          }
        );
        setFieldValue("image", blob);
        setCropImage("");
        setcroppedImage(URL.createObjectURL(file));
      }, "image/jpeg");
    });
  }
  return (
    <Dialog
      open={src ? true : false}
      onClose={() => {
        setCropImage("");
      }}
      maxWidth="md"
      className={classes.Dialog}
    >
      <DialogContent>
        <div className={classes.HeaderContainer}>
          <PageHeader
            heading="Crop Image"
            BtnTrue
            closeIcon
            closeClick={() => {
              setCropImage("");
            }}
          />
          <Cropper
            image={src}
            crop={crop}
            zoom={zoom}
            aspect={9 / 9}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            style={{
              containerStyle: {
                maxWidth: "500px",
                maxHeight: "350px",
              },
            }}
            classes={{
              containerClassName: classes.CropperContainer,
            }}
            showGrid
          />
        </div>
      </DialogContent>

      {/* {croppedImage && <img src={croppedImage} />} */}

      <DialogActions>
        <Button
          onClick={() => {
            getCroppedImg();
          }}
          className={classes.OkBtn}
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
