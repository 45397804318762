import Profile from "../Views/HomeScreens/UserProfile/Profile";
import ChangePassword from "../Views/HomeScreens/UserProfile/ChangePassword";
import Myprofile from "../Views/HomeScreens/UserProfile/MyProfile";

export const ProfileRoute = [
  {
    index: true,
    path: "my_profile",
    // element: <Profile />,
    element: <Myprofile />,
  },
  {
    path: "change_password",
    element: <ChangePassword />,
  },
];
