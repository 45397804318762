import React from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

import classes from "../../src/Views/HomeScreens/Management/Management.module.css";
import { CustomPaginationPropsType } from "./Types";
export default function CustomPagination({
  page,
  pageCount,
  handleFunction,
}: CustomPaginationPropsType) {
  return (
    <Stack spacing={2}>
      <Pagination
        className={classes.pagination}
        count={pageCount}
        page={page}
        color="primary"
        onChange={(e, currentpage) => {
          handleFunction(currentpage);
        }}
        boundaryCount={1}
        siblingCount={1}
      />
    </Stack>
  );
}
