import React, { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Autocomplete, Box, Paper, TextField } from "@mui/material";
import { Grid, makeStyles } from "@material-ui/core";
import { toast } from "react-hot-toast";
import { useFormik } from "formik";
import delete_icon from "../../../Assets/Icons/Svg/delete.svg";
import view from "../../../Assets/Icons/Png/view.png";
import Inbox from "../../../Assets/Images/Png/noData.png";
import edit from "../../../Assets/Icons/Svg/edit.svg";
import { useToken } from "../../../Utility/StoreData";
import { getCatchMsg, isFormDirty } from "../../../Utility/GeneralUtils";
import PageHeader from "../../MangementLayoutHeader/PageHeader";
import {
  listServerMangement,
  deleteServer,
  activeInactiveServer,
  projectdropdownlist,
  mqttDatalist,
  deleteMqttServices,
  mqttactiveInactiveStatus,
} from "../../../Services/Services";
import {
  Loader,
  TextInputBox,
  GlobalModal,
  TableRowsLoader,
  CustomSwitch,
  CustomPaginationwithdropdownpagination,
} from "../../../Components/index";
import {
  serverListItemType,
  serverListType,
  setdeleteServerModalType,
  setIsShowServerModalType,
} from "./managementTypes";
import classes from "./Management.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { ConfirmationModal, CreateServerModal } from "../../../Modals";
import SearchAndClearbutton from "../../../Components/SearchAndClearbutton";
import { useDispatch } from "react-redux";
import { openLoader } from "../../../Store/Reducers/LoaderSlice";
import AccordionContent from "../../../Components/AccordionContent";
import CreateMqttModal from "../../../Modals/CreateMqttModal";
import { muiStyles } from "../../../Utility/Constants";

const useStyles = makeStyles(() => ({
  switchContainer: {
    marginRight: "10px",
    display: "flex",
    alignItems: "center",
  },
}));

function MQTTManagement() {
  const style = useStyles();
  const dispatch = useDispatch();
  const muistyle = muiStyles();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [isShowModal, setIsShowModal] = useState<setIsShowServerModalType>({
    status: false,
    data: null,
    viewid: "",
    viewstatus: false,
  });
  const [deleteModal, setdeleteModal] = useState<setdeleteServerModalType>({
    modal: false,
    id: "",
  });
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState<number>(1);
  const [serverList, setServerList] = useState<serverListType>();
  const [noDataLoader, setnoDataLoader] = useState(false);
  const [showFilterOption, setShowFilterOption] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [projectList, setProjectList] = useState<
    { id: number; name: string }[]
  >([]);

  const token = useToken();

  const [Switches, setSwitches] = useState<boolean[]>([]);

  const { values, handleChange, resetForm, setFieldValue, initialValues } =
    useFormik({
      initialValues: {
        Server_name: "",
        Maximum_Devices: "",
        project: null,
      },
      onSubmit: () => {
        handlelistofServer();
      },
    });

  /**
   * Handles the table search functionality.
   * @param {string} data - The search query entered by the user.
   * @returns None
   */
  const handleTableSearch = (data: string) => {
    if (data === "Enter") {
      setPage(1);
      handlelistofServer();
    }
  };

  /**
   * Clears the table search by resetting the page number, resetting the form, and
   * triggering a new server request with empty search parameters.
   * @returns None
   */
  const handleTableSearchClear = () => {
    setPage(1);
    resetForm();
    handlelistofServer(undefined, undefined, true);
  };

  /**
   * Handles the retrieval and management of a list of servers.
   * @param {number} [page=1] - The page number of the server list to retrieve.
   * @param {number} [size=10] - The number of servers to retrieve per page.
   * @param {boolean} [resetValue] - Indicates whether to reset the values of the server name and maximum devices fields.
   * @returns None
   */
  const handlelistofServer = (page = 1, size = 10, resetValue?: boolean) => {
    setnoDataLoader(true);
    let formData = new FormData();
    formData.append("name", resetValue ? "" : values.Server_name);
    formData.append(
      "maximum_devices",
      resetValue ? "" : values.Maximum_Devices
    );
    if (values.project) {
      // @ts-ignore
      formData.append("SeverId", values.project.id);
    }
    if (state?.isActive) {
      formData.append("isActive", state?.isActive);
    }
    mqttDatalist(page, size, formData)
      .then((response) => {
        setPage(response?.data?.data?.page);
        if (response?.data?.status === 1) {
          setTotalCount(response?.data?.data?.totalCount);

          setServerList(response?.data);
          handleActiveStatus(response?.data?.data?.items);
        }
      })
      .catch((err) => getCatchMsg(err))
      .finally(() => {
        setnoDataLoader(false);
      });
  };
  /**
   * Executes the handlelistofServer function when the token value changes.
   * @param {function} handlelistofServer - The function to be executed.
   * @param {string | null} token - The token value.
   * @returns None
   */
  useEffect(() => {
    if (token) {
      handlelistofServer();
    }
  }, [token, state, values.project]);

  /**
   * Handles the active status of server list items.
   * @param {serverListItemType[]} data - An array of server list items.
   * @returns None
   */
  const handleActiveStatus = (data: serverListItemType[]) => {
    if (data && data?.length > 0) {
      const statusData = data?.map((ele, index: number) => {
        setSwitches((pre) => {
          let temp = [...pre];
          temp[index] = ele?.activeStatus === 1 ? true : false;
          return temp;
        });
        return ele;
      });
    }
  };

  /**
   * Handles the activation or deactivation of a server.
   * @param {number} serverid - The ID of the server to activate or deactivate.
   * @param {boolean} e - The boolean value indicating whether to activate or deactivate the server.
   * @returns None
   */
  const handleActiveAndInactiveServer = (serverid: number, e: boolean) => {
    let formData = new FormData();
    if (serverid) {
      formData.append("server_id", serverid?.toString());
      if (e == true) {
        formData.append("status_id", "1");
      }
      if (e == false) {
        formData.append("status_id", "0");
      }

      mqttactiveInactiveStatus(formData)
        .then((response) => {
          // handlelistofServer();
          // toast.success(
          //   e
          //     ? "Server activated successfully"
          //     : "Server deactivated successfully"
          // );
        })
        .catch((err) => getCatchMsg(err));
    }
  };

  /**
   * Handles the deletion of a server. Sets the loader to true to indicate that the deletion process is in progress.
   * Creates a new FormData object and appends the server_id to it.
   * Calls the deleteServer function with the FormData object as a parameter.
   * If the deletion is successful (response data status is 1), it checks if the total count of servers is divisible by 10.
   * If it is, it calls the handlelistofServer function to fetch the updated list of servers.
   * If it's not, it calls the handlelistofServer function with the next page number to fetch the updated list of servers.
   * Displays a toast message with the response data message.
   * If the deletion is not successful (response data
   */
  const handleDeleteServer = () => {
    dispatch(openLoader(true));
    let formData = new FormData();
    formData.append("server_id", deleteModal.id);
    deleteMqttServices(formData)
      .then((response) => {
        if (response?.data?.status === 1) {
          if (totalCount % 10 === 1) {
            handlelistofServer(page - 1);
          } else {
            handlelistofServer(page);
          }

          toast.success(response?.data?.msg);
        }
      })
      .catch((err) => getCatchMsg(err))
      .finally(() => {
        dispatch(openLoader(false));
        setdeleteModal((prev) => {
          return {
            ...prev,
            modal: false,
          };
        });
      });
  };

  const getProjects = () => {
    const formData = new FormData();
    projectdropdownlist()
      .then((res) => {
        if (res?.data?.status) {
          setProjectList(res?.data?.data);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      });
  };

  useEffect(() => {
    getProjects();
  }, []);

  return (
    <>
      <div className={classes.management}>
        {/* @ts-ignore */}
        <PageHeader
          modal={() => {
            setIsShowModal((prev) => {
              return {
                ...prev,
                status: true,
                data: null,
                viewstatus: false,
              };
            });
          }}
          Btntitle="Create MQTT"
          heading="MQTT Management"
          onFilterbutton={() => {
            setShowFilterOption((pre) => !pre);
          }}
        />
        {isShowModal?.status && (
          <GlobalModal
            size="lg"
            modalStyle="globalModalmaxWidth"
            isVisible={isShowModal.status}
            setIsVisible={() => {
              setIsShowModal((prev) => {
                return {
                  ...prev,
                  status: true,
                };
              });
            }}
          >
            <CreateMqttModal
              getview={isShowModal.viewstatus || false}
              getviewid={isShowModal.viewid || ""}
              onClose={() => {
                setIsShowModal((prev) => {
                  return {
                    ...prev,
                    status: false,
                  };
                });
              }}
              editData={isShowModal.data}
              listServerApicall={(value) => {
                if (value === "update") {
                  handlelistofServer(page);
                  return;
                }
                handlelistofServer();
              }}
              modalClose={() => {
                setIsShowModal((prev) => {
                  return {
                    ...prev,
                    status: false,
                  };
                });
              }}
              projectList={projectList}
            />
          </GlobalModal>
        )}
        <GlobalModal
          isVisible={deleteModal.modal}
          setIsVisible={() => {
            setdeleteModal((prev) => {
              return {
                ...prev,
                modal: false,
              };
            });
          }}
        >
          <ConfirmationModal
            msg={"Are you sure that you want to permanently delete this Server"}
            onClose={() => {
              setdeleteModal((prev) => {
                return {
                  ...prev,
                  modal: false,
                };
              });
            }}
            onPositiveButtonPressed={handleDeleteServer}
            onNegativeButtonPressed={() => {
              setdeleteModal((prev) => {
                return {
                  ...prev,
                  modal: false,
                };
              });
            }}
          />
        </GlobalModal>
        {/* {showFilterOption ? (
          
        ) : null} */}
        <AccordionContent filterStat={showFilterOption}>
          <div className={classes.serachContainer}>
            <div className="row mb-3">
              <Grid item lg={2} md={4} sm={6} xs={12}>
                <TextInputBox
                  title="Server Name"
                  textLength={50}
                  customInputProps={{
                    onKeyDown: (e) => handleTableSearch(e.key),
                  }}
                  name="Servername"
                  value={values.Server_name}
                  placeHolder="Search server name "
                  onChangeText={handleChange("Server_name")}
                />
              </Grid>
              <Grid item lg={2} md={4} sm={6} xs={12} className="fieldBr8">
                <TextInputBox
                  title="Project"
                  // requiredText={"*"}
                  onChangeText={() => {}}
                  textOnly
                />
                <Autocomplete
                  // className={styles.placeHolder}
                  options={projectList}
                  value={values.project}
                  onChange={(e, val) => {
                    setFieldValue("project", val);
                  }}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => {
                    return (
                      <TextField
                        placeholder={values?.project ? "" : "none"}
                        {...params}
                      />
                    );
                  }}
                  PaperComponent={(props) => {
                    return (
                      <Paper {...props} className={muistyle.customSelect} />
                    );
                  }}
                />
              </Grid>
            </div>
            <SearchAndClearbutton
              handleTableSearch={handleTableSearch}
              handleTableSearchClear={handleTableSearchClear}
              disabled={!isFormDirty(initialValues, values)}
            />
          </div>
        </AccordionContent>
        <div className={`table-responsive ${classes.AllTable}`}>
          <Box>
            <TableContainer>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>S.No</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Project Name</TableCell>
                    <TableCell>Verified Organization</TableCell>
                    <TableCell>Unverified Organization</TableCell>
                    <TableCell>Deactivated Organization</TableCell>
                    <TableCell>Total Capacity</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <>
                    {noDataLoader ? (
                      <TableRowsLoader columnNum={7} rowsNum={10} />
                    ) : serverList?.data?.items?.length === 0 || !serverList ? (
                      <TableRow>
                        <TableCell colSpan={7}>
                          <div className={classes.nodatafound}>
                            <img
                              src={Inbox}
                              alt="inbox"
                              width={100}
                              height={100}
                            />
                            <p>No data found</p>
                          </div>
                        </TableCell>
                      </TableRow>
                    ) : (
                      serverList?.data?.items?.map((server, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            {(serverList?.data?.page - 1) *
                              serverList?.data?.size +
                              index +
                              1}
                          </TableCell>
                          <TableCell>
                            {server.server_name === null
                              ? "-"
                              : server.server_name}
                          </TableCell>
                          <TableCell>
                            {server?.projectName ? server?.projectName : "-"}
                          </TableCell>

                          <TableCell>
                            {server.activeOrg === null ? "-" : server.activeOrg}
                          </TableCell>
                          <TableCell>
                            {server.inActiveOrg === null
                              ? "-"
                              : server.inActiveOrg}
                          </TableCell>
                          <TableCell>
                            {server.deactivatedCount === null
                              ? "-"
                              : server.deactivatedCount}
                          </TableCell>
                          <TableCell>
                            {server.maximumOrg === null
                              ? "-"
                              : server.maximumOrg}
                          </TableCell>

                          <TableCell>
                            <div className={classes.icons}>
                              <Grid className={style.switchContainer}>
                                <CustomSwitch
                                  title1="Active"
                                  title2="Inactive"
                                  status={Switches[index]}
                                  setCheck={(state) => {
                                    setSwitches((pre) => {
                                      let temp = [...pre];
                                      temp[index] = state;
                                      return temp;
                                    });
                                    handleActiveAndInactiveServer(
                                      server?.server_id,
                                      state
                                    );
                                  }}
                                />
                              </Grid>
                              <Tooltip title="View" placement="top">
                                <img
                                  src={view}
                                  alt="View"
                                  onClick={() =>
                                    navigate(
                                      { pathname: "mqtt_details_list" },
                                      { state: server }
                                    )
                                  }
                                />
                              </Tooltip>
                              <Tooltip title="Edit" placement="top">
                                <img
                                  src={edit}
                                  onClick={() => {
                                    setIsShowModal({
                                      status: true,
                                      data: server,
                                    });
                                  }}
                                  alt="Edit"
                                />
                              </Tooltip>
                              <Tooltip title="Delete" placement="top">
                                <img
                                  src={delete_icon}
                                  alt="Delete"
                                  onClick={() =>
                                    setdeleteModal(
                                      (
                                        prev: setdeleteServerModalType
                                      ): setdeleteServerModalType => {
                                        return {
                                          ...prev,
                                          modal: true,
                                          id: server.server_id.toString(),
                                        };
                                      }
                                    )
                                  }
                                />
                              </Tooltip>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </div>
        {serverList?.data?.items?.length ? (
          <CustomPaginationwithdropdownpagination
            onChangePage={(page: number) => {
              handlelistofServer(page, pageSize);
              setPage(page);
            }}
            onChangePagerow={(PageSize: number) => {
              if (page * PageSize > serverList?.data?.totalCount) {
                handlelistofServer(1, PageSize);
                setPageSize(PageSize);
                return;
              }
              handlelistofServer(page, PageSize);
              setPageSize(PageSize);
            }}
            count={serverList?.data?.totalCount}
            pages={page}
            pageCount={serverList?.data?.total_page}
            handleFunction={(selected: number) => {
              setPage(selected);
              handlelistofServer(selected, pageSize);
            }}
            labelRowsPerPage={"Devices Per Page"}
            rowsperpage={pageSize}
            totalData={serverList?.data}
          />
        ) : null}
      </div>
    </>
  );
}

export default MQTTManagement;
