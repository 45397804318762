import React, { useEffect, useState } from "react";
import { SettingOutlined } from "@ant-design/icons";
import { Cascader, InputNumber, Select, Space } from "antd";
import ModalHeader from "./ModalHeader";
import { AddPriceModalProps, UserSubscriptionDropdownProps } from "./Types";
import { CustomButton, CustomDropDown, TextInputBox } from "../Components";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@material-ui/core";
import { useFormik } from "formik";
import {
  subscriptionResponse,
  updateUserSubscriptionRequest,
  userSubscriptionDropdown,
} from "../Services/Services";
import toast from "react-hot-toast";
import { getCatchMsg } from "../Utility/GeneralUtils";
import classes from "./Modal.module.css";
import * as Yup from "yup";

const validationSchema = Yup.object({
  selected_subScriptionId: Yup.string().required("* Subscription is required"),

  price: Yup.string().required("* Cost required"),
  count: Yup.string().required("* Machine Count required"),
  duration: Yup.string().required("* Duration is required"),
  // MeterId: Yup.string().required("*MeterId required"),
});
export default function AddSubscriptionRateConfirmation({
  onClose,
  listApiCall,
  editData,
}: AddPriceModalProps) {
  const { Option } = Select;
  const [noDataLoader, setnoDataLoader] = useState(false);
  const [userSubscriptionList, setUserSubscriptionList] = useState<
    UserSubscriptionDropdownProps[]
  >([]);
  const {
    values,
    handleChange,
    setValues,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: {
      price: editData?.subscriptionCost || "",
      count: editData?.machine_count || "",
      selected_subscription: editData?.subscriptionName || "",
      duration: editData?.subscriptionDuration || "",
      selected_subScriptionId: editData?.subscription_id || "",
      isMaintenance: editData?.is_maintenance || 0,
      existing_license: editData?.licence_remaining || 0,
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      handleSubScriptionResponse(editData?.req_id, "1");
    },
  });

  const getUserSubscriptionList = (projectId: any) => {
    let formData = new FormData();
    formData.append("project_id", projectId);
    userSubscriptionDropdown(formData)
      .then((res) => {
        if (res?.data?.status) {
          setUserSubscriptionList(res?.data?.data || []);
        }
      })
      .catch((err) => getCatchMsg(err));
  };
  const selectAfter = (
    <Select defaultValue="USD" style={{ width: 60 }}>
      <Option value="USD">$</Option>
      <Option value="EUR">€</Option>
      <Option value="GBP">£</Option>
      <Option value="CNY">¥</Option>
    </Select>
  );
  const handleSubScriptionResponse = (ReqId: any, Status: any) => {
    setnoDataLoader(true);
    let formData = new FormData();
    formData.append("requestId", ReqId);
    formData.append("subscription_id", values.selected_subScriptionId);
    formData.append("subscriptionDuration", values.duration);
    formData.append("subscriptionCost", values.price);
    formData.append("machine_count", values.count);
    // @ts-ignore
    formData.append("total_price", values.count * values.price);

    formData.append("is_maintenance", values.isMaintenance ? "1" : "0");
    // if (values.price) {
    //   formData.append("Rate", values.price);
    // }
    updateUserSubscriptionRequest(formData)
      .then((res) => {
        if (res.data.status === 1) {
          toast.success(res.data.msg);
          onClose();
          listApiCall();
        } else {
          toast.error(res.data.msg);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        setnoDataLoader(false);
      });
  };

  useEffect(() => {
    if (editData) {
      getUserSubscriptionList(editData?.project_id);
    }
  }, []);

  return (
    <>
      <ModalHeader
        onClose={onClose}
        heading={`${editData?.organization_name} (${editData?.organization_code})`}
      />

      <div className={classes.add_device}>
        <div className="row">
          <div className="col-md-12 col-xl-6 form-group mb-3">
            <CustomDropDown
              required={"*"}
              editName={
                values.selected_subscription ? values.selected_subscription : ""
              }
              //@ts-ignore
              items={userSubscriptionList}
              // disabled={editData ? true : false}
              title="User Subscription"
              onSelectedItem={(val, value) => {

                // setFieldValue("selected_subscription", value.label);
                // setFieldValue("selected_subScriptionId", value.id);
                setValues({
                  ...values,
                  selected_subscription: value.label,
                  selected_subScriptionId: value.id,
                  duration: "1",
                  price: value.monthcost,
                  isMaintenance: value.id == 4 ? "1" : "0",
                });
              }}
              fieldName="subscriptionName"
              anotherFieldName="subscriptionId"
              monthCost="monthCost"
              yearCost="yearCost"
              value={values.selected_subscription}
              errorText={
                errors.selected_subScriptionId
                  ? errors.selected_subScriptionId
                  : ""
              }
            />
          </div>
          <div className="col-md-12 col-xl-6 form-group mb-3">
            <TextInputBox
              requiredText={"*"}
              textLength={50}
              title="Duration"
              placeHolder={`Enter Duration`}
              maxLength={15}
              name="duration"
              value={values.duration}
              onChangeText={(text) => {
                const alphanumericText = text.replace(/[^0-9]/g, "");
                handleChange("duration")(alphanumericText);
              }}
              // onChangeText={handleChange("price")}
              errorText={
                touched.duration && errors.duration ? errors.duration : ""
              }
            />
          </div>
          <div className="col-md-12 col-xl-6 form-group mb-3">
            <TextInputBox
              requiredText={"*"}
              textLength={50}
              title="Quoted Price"
              placeHolder={`Enter Amount`}
              maxLength={15}
              name="price"
              value={values.price}
              onChangeText={(text) => {
                const alphanumericText = text.replace(/[^0-9]/g, "");
                handleChange("price")(alphanumericText);
              }}
              // onChangeText={handleChange("price")}
              errorText={touched.price && errors.price ? errors.price : ""}
            />
          </div>

          <div className="col-md-12 col-xl-6 form-group mb-3">
            <TextInputBox
              requiredText={"*"}
              textLength={50}
              title="Machine Count"
              placeHolder={`Enter Machine Count`}
              maxLength={7}
              name="count"
              value={values.count}
              onChangeText={(text) => {
                const alphanumericText = text.replace(/[^0-9]/g, "");
                handleChange("count")(alphanumericText);
              }}
              // onChangeText={handleChange("price")}
              errorText={touched.count && errors.count ? errors.count : ""}
            />
          </div>
          <div className="col-md-12 col-xl-6 form-group mb-3">
            <TextInputBox
              disabled={true}
              // requiredText={"*"}
              textLength={50}
              title="Existing License"
              placeHolder={`Existing License`}
              maxLength={7}
              name="existing_license"
              value={values.existing_license}
              onChangeText={(text) => {
                const alphanumericText = text.replace(/[^0-9]/g, "");
                handleChange("existing_license")(alphanumericText);
              }}
              // onChangeText={handleChange("price")}
            />
          </div>
        </div>
        {values.count && values.price ? (
          <h6>
            Total Price : {values.price} x {values.count} = {/* @ts-ignore */}
            {values.count * values.price}
          </h6>
        ) : null}
        <FormGroup>
          <FormControlLabel
            onChange={(e) => {

              // @ts-ignore
              setFieldValue("isMaintenance", e.target.checked ? "1" : "0");
            }}
            disabled={values.selected_subScriptionId === 4 ? true : false}
            value={values.isMaintenance}
            checked={
              values.selected_subScriptionId === 4
                ? true
                : values.isMaintenance == 1
                ? true
                : false
            }
            // required
            control={
              <Checkbox
                defaultChecked={
                  values.isMaintenance === 1 ||
                  values.selected_subScriptionId === 4
                    ? true
                    : false
                }
                // checked={
                //   values.isMaintenance === 1 ||
                //   values.selected_subScriptionId === 4
                //     ? true
                //     : false
                // }
              />
            }
            label="Maintenance"
          />
        </FormGroup>
        <div className="col-lg-3 m-2">
          <CustomButton title="Submit" onButtonPress={() => handleSubmit()} />
        </div>
      </div>
    </>
  );
}
