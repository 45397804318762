import React, { useEffect, useState } from "react";
import {
  createFirmwareUpload,
  firmWareDropDown,
  getDeviceDrodownService,
  getModelDropDown,
  organizationDropDown,
} from "../Services/Services";
import { getCatchMsg } from "../Utility/GeneralUtils";
import {
  DeviceDropdownDataProps,
  EditBatchModalTypes,
  firmwareListDropDownProps,
  FirmwareUploadProps,
} from "./Types";
import classes from "./Modal.module.css";
import { useFormik } from "formik";
import {
  CustomButton,
  CustomDropDown,
  Loader,
  TextInputBox,
} from "../Components";
import { useToken } from "../Utility/StoreData";
import ModalHeader from "./ModalHeader";
import { OrganizationDropdownProps } from "../Views/HomeScreens/Types";
import { Autocomplete, TextField } from "@mui/material";
import { Paper } from "@material-ui/core";
import { muiStyles } from "../Utility/Constants";
import * as Yup from "yup";
import toast from "react-hot-toast";

const validationSchema = Yup.object({
  model_id: Yup.string().required("Model is required"),
  firmwareId: Yup.string().required("Firmware is required"),
  sharedOptionId: Yup.string().required("Shared is required"),
  selectOrgId: Yup.string().test(
    "Check_Shared_option",
    "Organization is required",
    function (val) {
      const { sharedOptionId } = this.parent;
      if (sharedOptionId === "1" && !val) {
        return false;
      }
      return true;
    }
  ),
  devicePreferenceId: Yup.string().required("Device preference is required"),
  device: Yup.array().test(
    "check_devices",
    "Device is required",
    function (val) {
      const { devicePreferenceId } = this.parent;
      if (devicePreferenceId === "2" && val?.length === 0) {
        return false;
      }
      return true;
    }
  ),
});

export default function FirmwareUpload({
  editData,
  onClose,
  listapiCall,
  modalClose,
}: FirmwareUploadProps) {
  const token = useToken();
  const muistyle = muiStyles();
  const [loader, setloader] = useState(false);
  const [firmwareList, setFirmWareList] = useState<
    firmwareListDropDownProps[] | []
  >([]);
  const [deviceList, setDeviceList] = useState<DeviceDropdownDataProps[]>([]);
  const [modelList, setmodelList] = useState<EditBatchModalTypes[]>([]);
  const [organizationList, setOrganizationList] = useState<
    OrganizationDropdownProps[]
  >([]);
  const CustomOrgList = [
    {
      id: 1,
      name: "Selected Organization",
    },
    {
      id: 2,
      name: "Other",
    },
  ];

  const devicePreference = [
    {
      id: 1,
      name: "All Device",
    },

    {
      id: 2,
      name: "Selected Device",
    },
  ];

  const { values, handleSubmit, errors, touched, setFieldValue, setValues } =
    useFormik({
      initialValues: {
        model_no: "",
        model_id: "",
        firmWareName: "",
        firmwareId: "",
        sharedOptionId: "",
        sharedOptionName: "",
        selectOrgId: "",
        selectOrgName: "",
        devicePreferenceName: "",
        devicePreferenceId: "",
        device: [],
      },
      validationSchema: validationSchema,
      onSubmit(value) {
        handleAddFirmwareUpload(value);
      },
    });

  useEffect(() => {
    if (token) {
      handleGetModelDropDown();
    }
  }, [token]);

  const handleAddFirmwareUpload = (data: any) => {
    setloader(true);
    let formData = new FormData();
    formData.append("firmware_id", data?.firmwareId);
    formData.append("modelId", data?.model_id);
    formData.append(
      "organization_id",
      data?.sharedOptionId === "1" ? data?.selectOrgId : "0"
    );
    if (data?.devicePreferenceId === "1") {
      formData.append("is_all_device", "1");
    } else {
      formData.append(
        "deviceID",
        [...data?.device]?.map((ele) => ele?.id)?.toString()
      );
    }
    createFirmwareUpload(formData)
      .then((response) => {
        if (response?.data?.status === 1) {
          toast.success(response?.data?.msg);
          onClose();
          listapiCall("update");
        } else {
          toast.error(response?.data?.msg);
        }
      })
      .catch((err) => getCatchMsg(err))
      .finally(() => setloader(false));
  };

  const handlegetFirmWarelist = (id: number) => {
    const formdata = new FormData();
    formdata.append("modelId", id?.toString());
    firmWareDropDown(formdata)
      .then((res) => {
        if (res.data.status === 1) {
          setFirmWareList(res?.data?.data);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      });
  };

  const handleGetModelDropDown = () => {
    getModelDropDown()
      .then((response) => {
        if (response?.data?.status === 1) {
          setmodelList([...response?.data?.data]);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      });
  };

  const getDeviceDropdown = (data: { orgId: string; modelId: string }) => {
    const formdata = new FormData();
    formdata.append("org_id", data?.orgId);
    formdata.append("model_id", data?.modelId);
    getDeviceDrodownService(1, 1000, formdata)
      .then((res) => {
        if (res?.data?.status) {
          setDeviceList(res?.data?.data?.items);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      });
  };

  const getOrganizations = (id: string) => {
    let formData = new FormData();
    formData.append("modelIds", id);
    organizationDropDown(formData)
      .then((res) => {
        if (res?.data?.status === 1) {
          setOrganizationList(res?.data?.data);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      });
  };

  return (
    <>
      {loader ? <Loader /> : null}
      <ModalHeader
        onClose={onClose}
        heading={`${editData ? "View" : "Add"} Assign Firmware`}
      />
      <div className={classes.addModel}>
        {editData ? (
          <div className={`${classes.viewContainer} mb-4`}>
            <div className={classes.viewContent}>
              <p>Organization Name</p>
              <strong>:</strong>
              <span>
                {editData?.organization_name
                  ? editData?.organization_name
                  : "-"}
              </span>
            </div>
            <div className={classes.viewContent}>
              <p>Firmware Name</p>
              <strong>:</strong>
              <span>
                {editData?.firmware_name ? editData?.firmware_name : "-"}
              </span>
            </div>
            <div className={classes.viewContent}>
              <p>Model Name</p>
              <strong>:</strong>
              <span>{editData?.model_id ? editData?.model_name : "-"}</span>
            </div>
            <div className={classes.viewContent}>
              <p>Version</p>
              <strong>:</strong>
              <span>{editData?.version ? editData?.version : "-"}</span>
            </div>
            <div>
              <p className={classes.deviceCodeTitle}>Device Code</p>
              {editData?.device_code?.split(",").length ? (
                <div className={classes.deviceListcontanier}>
                  {editData?.device_code?.split(",")?.map((ele) => (
                    <p>{ele}</p>
                  ))}{" "}
                </div>
              ) : (
                <p>!No Devices</p>
              )}
            </div>
          </div>
        ) : (
          <>
            <div className="row">
              <div className="col-md-6 col-lg-4 form-group mb-3">
                <CustomDropDown
                  placeholder={"Select Model"}
                  disableNoneOption={true}
                  required="*"
                  editName={values.model_no}
                  title="Model"
                  items={modelList}
                  onSelectedItem={(val, value) => {
                    setValues({
                      ...values,
                      model_no: value.label,
                      model_id: value.id?.toString(),
                      firmWareName: "",
                      firmwareId: "",
                      sharedOptionName: "",
                      sharedOptionId: "",
                      selectOrgName: "",
                      selectOrgId: "",
                    });
                    if (value?.id) {
                      handlegetFirmWarelist(value?.id);
                    } else {
                      setFirmWareList([]);
                    }
                  }}
                  fieldName="model_no"
                  anotherFieldName="model_id"
                  value={values.model_id}
                  errorText={
                    touched.model_id && errors.model_id ? errors.model_id : ""
                  }
                />
              </div>
              <div className="col-md-6 col-lg-4 form-group mb-3">
                <CustomDropDown
                  placeholder={"Select Firmware"}
                  disableNoneOption={true}
                  required={"*"}
                  title="Firmware"
                  disabled={!values?.model_id}
                  editName={values?.firmWareName}
                  items={firmwareList}
                  onSelectedItem={(val, value) => {
                    setFieldValue("firmWareName", value?.label);
                    setFieldValue("firmwareId", value?.id?.toString());
                  }}
                  fieldName={"name"}
                  anotherFieldName={"id"}
                  value={values?.firmwareId}
                  errorText={
                    touched.firmwareId && errors.firmwareId
                      ? errors.firmwareId
                      : ""
                  }
                />
              </div>
              <div className="col-md-6 col-lg-4 form-group mb-3">
                <CustomDropDown
                  placeholder={"Select Option"}
                  disableNoneOption={true}
                  required={"*"}
                  title="Shared Option"
                  editName={values?.sharedOptionName}
                  items={CustomOrgList}
                  disabled={!values?.model_id}
                  onSelectedItem={(val, value) => {
                    setValues({
                      ...values,
                      sharedOptionName: value?.label,
                      sharedOptionId: value?.id?.toString(),
                      selectOrgId: "",
                      selectOrgName: "",
                    });
                    if (value?.id === 1) {
                      getOrganizations(values?.model_id);
                    }
                  }}
                  fieldName={"name"}
                  anotherFieldName={"id"}
                  value={values?.sharedOptionId}
                  errorText={
                    touched.sharedOptionId && errors.sharedOptionId
                      ? errors.sharedOptionId
                      : ""
                  }
                />
              </div>
              <div className="col-md-6 col-lg-4 form-group mb-3">
                <CustomDropDown
                  placeholder={"Select Organization"}
                  disableNoneOption={true}
                  required={values?.sharedOptionId === "1" ? "*" : ""}
                  title="Organizations"
                  editName={values?.selectOrgName}
                  items={organizationList}
                  disabled={values?.sharedOptionId !== "1"}
                  onSelectedItem={(val, value) => {
                    setValues({
                      ...values,
                      selectOrgName: value?.label,
                      selectOrgId: value?.id?.toString(),
                    });
                  }}
                  fieldName={"orgName"}
                  anotherFieldName={"orgId"}
                  value={values?.selectOrgId}
                  errorText={
                    touched.selectOrgId && errors.selectOrgId
                      ? errors.selectOrgId
                      : ""
                  }
                />
              </div>
              <div className="col-md-6 col-lg-4 form-group mb-3">
                <CustomDropDown
                  placeholder={"Select device preference"}
                  disableNoneOption={true}
                  required={"*"}
                  disabled={!values?.model_id}
                  title="Device Preference"
                  editName={values?.devicePreferenceName}
                  items={devicePreference}
                  onSelectedItem={(val, value) => {
                    setValues({
                      ...values,
                      devicePreferenceName: value?.label,
                      devicePreferenceId: value?.id?.toString(),
                      device: [],
                    });
                    if (value?.id === 2) {
                      getDeviceDropdown({
                        orgId: values?.selectOrgId,
                        modelId: values?.model_id,
                      });
                    }
                  }}
                  fieldName={"name"}
                  anotherFieldName={"id"}
                  value={values?.devicePreferenceId}
                  errorText={
                    touched.devicePreferenceId && errors.devicePreferenceId
                      ? errors.devicePreferenceId
                      : ""
                  }
                />
              </div>
              <div className="col-md-6 col-lg-4 form-group mb-3">
                <TextInputBox
                  title="Device"
                  onChangeText={() => {}}
                  textOnly
                  requiredText={values?.devicePreferenceId === "2" ? "*" : ""}
                />
                <Autocomplete
                  disableCloseOnSelect
                  multiple
                  options={deviceList}
                  value={values.device}
                  onChange={(e, val) => {
                    setFieldValue("device", val);
                  }}
                  sx={{
                    "& .MuiInputBase-root": {
                      borderRadius: "8px !important",
                    },
                  }}
                  getOptionLabel={(option) => option.deviceCode}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => {
                    return (
                      <TextField
                        style={{
                          borderRadius: "10px",
                          border: "1px solid red !important",
                        }}
                        placeholder={
                          values?.device?.length > 0 ? "" : "Select Devices"
                        }
                        {...params}
                      />
                    );
                  }}
                  disabled={values?.devicePreferenceId?.toString() !== "2"}
                  PaperComponent={(props) => {
                    return (
                      <Paper {...props} className={muistyle.customSelect} />
                    );
                  }}
                />
                <TextInputBox
                  title=""
                  onChangeText={() => {}}
                  textOnly
                  errorText={
                    errors.device && touched.device ? errors.device : ""
                  }
                />
              </div>
            </div>
            <div className={classes.modalbtn}>
              <div className="col-lg-3">
                <CustomButton title="Submit" onButtonPress={handleSubmit} />
              </div>
            </div>
          </>
        )}{" "}
      </div>
    </>
  );
}
