import React from "react";

import { CustomButtonProps } from "./Types";
import classes from "./CustomStyle.module.css";
import { DownloadOutlined } from "@mui/icons-material";
export default function CustomButton({
  title = "",
  onButtonPress,
  customButtonStyle,
  buttonProps,
  download,
}: CustomButtonProps) {
  return (
    <div
      style={{
        marginRight: download ? "10px" : "0px",
      }}
      className={classes.btn}
    >
      <button
        type="button"
        {...buttonProps}
        onClick={onButtonPress}
        style={customButtonStyle}
      >
        {download && (
          <DownloadOutlined
            style={{
              marginRight: "5px",
              fontSize: "20px",
            }}
          />
        )}
        {title}
      </button>
    </div>
  );
}
