import React, { useEffect, useState } from "react";
import { Tooltip } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Box } from "@mui/material";
import { toast } from "react-hot-toast";
import { useFormik } from "formik";

import { useToken } from "../../../Utility/StoreData";
import {
  deleteDesignation,
  deleteUser,
  desigantionsList,
  getDesignationDropdown,
  listUsers,
} from "../../../Services/Services";
import { getCatchMsg, isFormDirty } from "../../../Utility/GeneralUtils";
import PageHeader from "../../MangementLayoutHeader/PageHeader";
import classes from "./Management.module.css";
import view from "../../../Assets/Icons/Png/view.png";
import Inbox from "../../../Assets/Images/Png/noData.png";
import delete_icon from "../../../Assets/Icons/Svg/delete.svg";
import edit from "../../../Assets/Icons/Svg/edit.svg";
import {
  AddDesigantion,
  AddUserModal,
  ConfirmationModal,
} from "../../../Modals/index";
import PasswordKey from "../../../Assets/Icons/Svg/passwordkey.svg";
import {
  Loader,
  TextInputBox,
  GlobalModal,
  TableRowsLoader,
  NodataLoader,
  CustomPaginationwithdropdownpagination,
  CustomDropDown,
} from "../../../Components/index";
import {
  EmployeeListItemProps,
  EmployeeListProps,
  designationListDataItemsProps,
  designationListDataProps,
} from "./managementTypes";
import SearchAndClearbutton from "../../../Components/SearchAndClearbutton";
import { Grid } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { openLoader } from "../../../Store/Reducers/LoaderSlice";
import AccordionContent from "../../../Components/AccordionContent";

function EmployeeManagement() {
  const dispatch = useDispatch();
  const token = useToken();
  const [usersList, setUsersList] = useState<EmployeeListProps>();
  const [noDataLoader, setnoDataLoader] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [designationList, setdesgnationList] =
    useState<designationListDataProps | null>(null);
  const [employeeAndDesinationListStatus, setEmployeeAndDesinationListStatus] =
    useState(false);
  const [showFilterOption, setShowFilterOption] = useState(false);
  const [isShowModal, setIsShowModal] = useState<any>({
    status: false,
    data: null,
    viewStatus: false,
    viewId: "",
    userId: 0,
    editpwdstatus: false,
  });
  const [deleteModal, setdeleteModal] = useState({
    modal: false,
    id: "",
  });
  const [designationDropdownList, setdesignationDropdownList] = useState<
    { designation_name: string; designation_id: string }[]
  >([]);
  const { values, handleChange, resetForm, setFieldValue, initialValues } =
    useFormik({
      initialValues: {
        name: "",
        designation: "",
        designationId: "",
        mobile_no: "",
        email: "",
      },
      onSubmit: () => {
        handleGetUsersList();
      },
    });

  const handleTableSearch = (data: string) => {
    if (data === "Enter") {
      setPage(1);
      if (employeeAndDesinationListStatus) {
        handleGetDesgnationList();
      } else {
        handleGetUsersList();
      }
    }
  };

  const handleTableSearchClear = () => {
    setPage(1);
    resetForm();
    if (employeeAndDesinationListStatus) {
      handleGetDesgnationList(undefined, undefined, true);
    } else {
      handleGetUsersList(undefined, undefined, true);
    }
  };

  const handleGetUsersList = (
    page = usersList?.data?.page === undefined ? 1 : usersList?.data?.page,
    size = 10,
    resetValue?: boolean,
    designationID?: string
  ) => {
    let formData = new FormData();
    setnoDataLoader(true);
    formData.append("name", resetValue ? "" : values.name);
    // formData.append("designation", resetValue ? "" : values.designation);
    formData.append(
      "designationId",
      resetValue ? "" : designationID ? designationID : ""
    );
    formData.append("mobile_no", resetValue ? "" : values.mobile_no);
    formData.append("email", resetValue ? "" : values.email);
    listUsers(page, size, formData)
      .then((response) => {
        setPage(response?.data?.data?.page);
        if (response?.data?.status === 1) {
          setUsersList(response?.data);
        }
      })
      .catch((err) => getCatchMsg(err))
      .finally(() => {
        setnoDataLoader(false);
      });
  };

  const handleGetDesgnationList = (
    page = 1,
    size = 10,
    resetValue?: boolean
  ) => {
    setnoDataLoader(true);
    let formData = new FormData();
    formData.append("name", resetValue ? "" : values?.name);
    desigantionsList(page, size, formData)
      .then((response) => {
        setPage(response?.data?.data?.page);
        if (response?.data?.status === 1) {
          setdesgnationList(response?.data);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        setnoDataLoader(false);
      });
  };

  const handleDeleteDesignation = () => {
    dispatch(openLoader(true));
    let formData = new FormData();
    formData.append("designationId", deleteModal?.id);
    deleteDesignation(formData)
      .then((response) => {
        if (response?.data?.status === 1) {
          handleGetDesgnationList(designationList?.data?.page);
          toast.success(response?.data?.msg);
        } else if (response?.data?.status === 0) {
          toast.error(response?.data?.msg);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        dispatch(openLoader(false));
        setdeleteModal((prev) => {
          return {
            ...prev,
            modal: false,
          };
        });
      });
  };
  const handleDeleteUser = () => {
    let formData = new FormData();
    dispatch(openLoader(true));
    formData.append("user_id", deleteModal.id);
    deleteUser(formData)
      .then((response) => {
        if (response?.data?.status === 1) {
          handleGetUsersList(usersList?.data?.page);

          toast.success(response?.data?.msg);
        } else if (response?.data?.status === 0) {
          toast.error(response?.data?.msg);
        }
      })
      .catch((err) => getCatchMsg(err))
      .finally(() => {
        dispatch(openLoader(false));
        setdeleteModal((prev) => {
          return {
            ...prev,
            modal: false,
          };
        });
      });
  };
  const getDesignationList = () => {
    getDesignationDropdown()
      .then((res) => {
        if (res?.data?.status) {
          setdesignationDropdownList(res?.data?.data);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      });
  };

  useEffect(() => {
    if (token) {
      if (employeeAndDesinationListStatus) {
        handleGetDesgnationList();
      } else {
        handleGetUsersList();
      }
    }
  }, [token, employeeAndDesinationListStatus]);

  useEffect(() => {
    getDesignationList();
  }, []);

  return (
    <>
      {/* @ts-ignore */}
      <PageHeader
        modal={() => {
          setShowFilterOption(false);
          resetForm();
          setEmployeeAndDesinationListStatus(!employeeAndDesinationListStatus);
        }}
        BtntitleOne={`Add ${
          employeeAndDesinationListStatus ? "Designation" : "User"
        }`}
        onPressOvertime={() => {
          if (employeeAndDesinationListStatus) {
            setIsShowModal((prev: any) => {
              return {
                ...prev,
                status: true,
                data: null,
              };
            });
          } else {
            setIsShowModal((prev: any) => {
              return {
                ...prev,
                status: true,
                data: null,
                viewStatus: false,
                editpwdstatus: false,
              };
            });
          }
        }}
        Btntitle={`${
          employeeAndDesinationListStatus ? "Employee" : "Designation"
        } List`}
        heading={employeeAndDesinationListStatus ? "Users" : "Employees"}
        onFilterbutton={() => {
          setShowFilterOption((pre) => !pre);
        }}
      />
      {isShowModal?.status && !employeeAndDesinationListStatus && (
        <GlobalModal
          size="lg"
          modalStyle="globalModalmaxWidth logoutmodalminWidth"
          isVisible={isShowModal.status}
          setIsVisible={() => {
            setIsShowModal((prev: any) => {
              return {
                ...prev,
                status: true,
              };
            });
          }}
        >
          <AddUserModal
            getView={isShowModal.viewStatus}
            getViewId={isShowModal.viewId}
            geteditpwdstatus={isShowModal.editpwdstatus}
            geteditpwdId={isShowModal.userId}
            onClose={() => {
              setIsShowModal((prev: any) => {
                return {
                  ...prev,
                  status: false,
                };
              });
            }}
            editData={isShowModal.data || null}
            listUserApicall={(value) => {
              if (value === "update") {
                handleGetUsersList(page);
                return;
              }
              handleGetUsersList();
            }}
            modalClose={() => {
              setIsShowModal((prev: any) => {
                return {
                  ...prev,
                  status: false,
                };
              });
            }}
          />
        </GlobalModal>
      )}
      {isShowModal?.status && employeeAndDesinationListStatus && (
        <GlobalModal
          size="lg"
          modalStyle="globalModalmaxWidth logoutmodalminWidth"
          isVisible={isShowModal.status}
          setIsVisible={() => {
            setIsShowModal((prev: any) => {
              return {
                ...prev,
                status: true,
              };
            });
          }}
        >
          <AddDesigantion
            onClose={() => {
              setIsShowModal((prev: any) => {
                return {
                  ...prev,
                  status: false,
                };
              });
            }}
            editData={isShowModal?.data || null}
            listApiCall={(value) => {
              if (value === "update") {
                handleGetDesgnationList(page);
                return;
              }
              handleGetDesgnationList();
            }}
            modalClose={() => {
              setIsShowModal((prev: any) => {
                return {
                  ...prev,
                  status: false,
                };
              });
            }}
          />
        </GlobalModal>
      )}
      <GlobalModal
        isVisible={deleteModal.modal}
        setIsVisible={() => {
          setdeleteModal((prev) => {
            return {
              ...prev,
              modal: false,
            };
          });
        }}
      >
        <ConfirmationModal
          msg={`Are you sure that you want to permanently delete this ${
            employeeAndDesinationListStatus ? "designation" : "user"
          }.`}
          onClose={() => {
            setdeleteModal((prev) => {
              return {
                ...prev,
                modal: false,
              };
            });
          }}
          onPositiveButtonPressed={() => {
            if (employeeAndDesinationListStatus) {
              handleDeleteDesignation();
            } else {
              handleDeleteUser();
            }
          }}
          onNegativeButtonPressed={() => {
            setdeleteModal((prev) => {
              return {
                ...prev,
                modal: false,
              };
            });
          }}
        />
      </GlobalModal>
      {employeeAndDesinationListStatus ? (
        <>
          {/* {showFilterOption ? (
           
          ) : null} */}
          <AccordionContent filterStat={showFilterOption}>
            <div className={classes.serachContainer}>
              <div className="row mb-3">
                <Grid item lg={2} md={4} sm={6} xs={12}>
                  <TextInputBox
                    title="Designation"
                    textLength={50}
                    customInputProps={{
                      onKeyDown: (e) => handleTableSearch(e.key),
                    }}
                    name="name"
                    placeHolder="Search designation"
                    value={values.name}
                    onChangeText={handleChange("name")}
                  />
                </Grid>
              </div>
              <SearchAndClearbutton
                handleTableSearch={handleTableSearch}
                handleTableSearchClear={handleTableSearchClear}
                disabled={!isFormDirty(initialValues, values)}
              />
            </div>
          </AccordionContent>
          <div className={`table-responsive ${classes.AllTable}`}>
            <Box>
              <TableContainer>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell>S.No</TableCell>
                      <TableCell>Designation</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {designationList?.data?.items?.length === 0 ? (
                      <>
                        {noDataLoader ? (
                          <TableRow>
                            <TableCell className={classes.noData} colSpan={9}>
                              <NodataLoader />
                            </TableCell>
                          </TableRow>
                        ) : (
                          <TableRow>
                            <TableCell colSpan={9}>
                              <div className={classes.nodatafound}>
                                <img
                                  src={Inbox}
                                  alt="inbox"
                                  width={100}
                                  height={100}
                                />
                                <p>No data found</p>
                              </div>
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    ) : (
                      <>
                        {noDataLoader ? (
                          <TableRowsLoader columnNum={6} rowsNum={10} />
                        ) : designationList?.data?.items?.length === 0 ||
                          !designationList ? (
                          <TableRow>
                            <TableCell colSpan={9}>
                              <div className={classes.nodatafound}>
                                <img
                                  src={Inbox}
                                  alt="inbox"
                                  width={100}
                                  height={100}
                                />
                                <p>No data found</p>
                              </div>
                            </TableCell>
                          </TableRow>
                        ) : (
                          designationList?.data?.items?.map(
                            (
                              designation: designationListDataItemsProps,
                              index: number
                            ) => (
                              <TableRow key={index}>
                                <TableCell>
                                  {(designationList?.data?.page - 1) *
                                    designationList?.data?.size +
                                    index +
                                    1}
                                </TableCell>
                                <TableCell>
                                  {designation?.name === null
                                    ? "-"
                                    : designation?.name}
                                </TableCell>
                                <TableCell>
                                  {designation?.id === 1 ? null : (
                                    <div className={classes.icons}>
                                      <Tooltip title="Edit" placement="top">
                                        <img
                                          src={edit}
                                          onClick={() => {
                                            setIsShowModal((prev: any) => {
                                              return {
                                                ...prev,
                                                status: true,
                                                data: designation,
                                              };
                                            });
                                          }}
                                          alt="Edit"
                                        />
                                      </Tooltip>
                                      <Tooltip title="Delete" placement="top">
                                        <img
                                          src={delete_icon}
                                          alt="Delete"
                                          onClick={() =>
                                            setdeleteModal((prev) => {
                                              return {
                                                ...prev,
                                                modal: true,
                                                id: designation.id.toString(),
                                              };
                                            })
                                          }
                                        />
                                      </Tooltip>
                                    </div>
                                  )}
                                </TableCell>
                              </TableRow>
                            )
                          )
                        )}
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </div>
          {designationList?.data?.items?.length ? (
            <CustomPaginationwithdropdownpagination
              onChangePage={(page: number) => {
                handleGetDesgnationList(page, pageSize);
                setPage(page);
              }}
              onChangePagerow={(PageSize: number) => {
                if (page * PageSize > designationList?.data?.totalCount) {
                  handleGetDesgnationList(1, PageSize);
                  setPageSize(PageSize);
                  return;
                }
                handleGetDesgnationList(page, PageSize);
                setPageSize(PageSize);
              }}
              count={designationList?.data?.totalCount}
              pages={page}
              pageCount={designationList?.data?.total_page}
              handleFunction={(selected: number) => {
                setPage(selected);
                handleGetDesgnationList(selected, pageSize);
              }}
              labelRowsPerPage={"Devices Per Page"}
              rowsperpage={pageSize}
              totalData={designationList?.data}
            />
          ) : null}
        </>
      ) : (
        <>
          {/* {showFilterOption ? (
            
          ) : null} */}
          <AccordionContent filterStat={showFilterOption}>
            <div className={classes.serachContainer}>
              <div className="row mb-3">
                <Grid item lg={2} md={4} sm={6} xs={12}>
                  <TextInputBox
                    title="User Name"
                    textLength={50}
                    customInputProps={{
                      onKeyDown: (e) => handleTableSearch(e.key),
                    }}
                    name="name"
                    placeHolder="Search user name"
                    value={values.name}
                    onChangeText={handleChange("name")}
                  />
                </Grid>
                <Grid item lg={2} md={4} sm={6} xs={12}>
                  {/* <TextInputBox
                    title="Designation"
                    textLength={50}
                    customInputProps={{
                      onKeyDown: (e) => handleTableSearch(e.key),
                    }}
                    name="designation"
                    placeHolder="Search designation"
                    value={values.designation}
                    onChangeText={handleChange("designation")}
                  /> */}
                  <CustomDropDown
                    editName={values.designation}
                    title="Designation"
                    //@ts-ignore
                    items={designationDropdownList}
                    onSelectedItem={(val, value) => {
                      handleGetUsersList(1, 10, false, value?.id);
                      setFieldValue("designationId", value?.id);
                      setFieldValue("designation", value?.label);
                    }}
                    fieldName="designation_name"
                    anotherFieldName="designation_id"
                    value={values.designationId}
                    disableClearable={!values.designationId}
                  />
                </Grid>
                <Grid item lg={2} md={4} sm={6} xs={12}>
                  <TextInputBox
                    title="	Mobile Number"
                    textLength={10}
                    customInputProps={{
                      onKeyDown: (e) => handleTableSearch(e.key),
                    }}
                    name="mobile_no"
                    placeHolder="Search mobile number"
                    value={values.mobile_no}
                    onChangeText={(text) => {
                      const numericText = text.replace(/[^0-9]/g, "");
                      handleChange("mobile_no")(numericText);
                    }}
                  />
                </Grid>
                <Grid item lg={2} md={4} sm={6} xs={12}>
                  <TextInputBox
                    title="Email"
                    textLength={50}
                    customInputProps={{
                      onKeyDown: (e) => handleTableSearch(e.key),
                    }}
                    name="email"
                    placeHolder="Search board version"
                    value={values.email}
                    onChangeText={handleChange("email")}
                  />
                </Grid>
              </div>
              <SearchAndClearbutton
                handleTableSearch={handleTableSearch}
                handleTableSearchClear={handleTableSearchClear}
                disabled={!isFormDirty(initialValues, values)}
              />
            </div>
          </AccordionContent>
          <div className={`table-responsive ${classes.AllTable}`}>
            <Box>
              <TableContainer>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell>S.No</TableCell>
                      <TableCell>User Name</TableCell>
                      <TableCell>Designation</TableCell>
                      <TableCell>Mobile Number</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {usersList?.data?.items?.length === 0 ? (
                      <>
                        {noDataLoader ? (
                          <TableRow>
                            <TableCell className={classes.noData} colSpan={9}>
                              <NodataLoader />
                            </TableCell>
                          </TableRow>
                        ) : (
                          <TableRow>
                            <TableCell colSpan={9}>
                              <div className={classes.nodatafound}>
                                <img
                                  src={Inbox}
                                  alt="inbox"
                                  width={100}
                                  height={100}
                                />
                                <p>No data found</p>
                              </div>
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    ) : (
                      <>
                        {noDataLoader ? (
                          <TableRowsLoader columnNum={6} rowsNum={10} />
                        ) : usersList?.data?.items?.length === 0 ||
                          !usersList ? (
                          <TableRow>
                            <TableCell colSpan={9}>
                              <div className={classes.nodatafound}>
                                <img
                                  src={Inbox}
                                  alt="inbox"
                                  width={100}
                                  height={100}
                                />
                                <p>No data found</p>
                              </div>
                            </TableCell>
                          </TableRow>
                        ) : (
                          usersList?.data?.items?.map(
                            (user: EmployeeListItemProps, index: number) => (
                              <TableRow key={index}>
                                <TableCell>
                                  {(usersList?.data?.page - 1) *
                                    usersList?.data?.size +
                                    index +
                                    1}
                                </TableCell>
                                <TableCell>
                                  {user.user_name === null
                                    ? "-"
                                    : user.user_name}
                                </TableCell>
                                <TableCell>
                                  {user.designation === null
                                    ? "-"
                                    : user.designation}
                                </TableCell>
                                <TableCell>
                                  {user.mobile_no === null
                                    ? "-"
                                    : user.mobile_no}
                                </TableCell>
                                <TableCell>
                                  {user.email === null ? "-" : user.email}
                                </TableCell>
                                <TableCell>
                                  <div className={classes.icons}>
                                    <Tooltip title="View" placement="top">
                                      <img
                                        src={view}
                                        alt="View"
                                        onClick={() => {
                                          setIsShowModal({
                                            status: true,
                                            viewId: user.user_id?.toString(),
                                            viewStatus: true,
                                            data: user,
                                          });
                                        }}
                                      />
                                    </Tooltip>
                                    <Tooltip title="Edit" placement="top">
                                      <img
                                        src={edit}
                                        onClick={() => {
                                          setIsShowModal({
                                            status: true,
                                            data: user,
                                          });
                                        }}
                                        alt="Edit"
                                      />
                                    </Tooltip>
                                    <Tooltip title="Delete" placement="top">
                                      <img
                                        src={delete_icon}
                                        alt="Delete"
                                        onClick={() =>
                                          setdeleteModal({
                                            modal: true,
                                            id: user.user_id?.toString(),
                                          })
                                        }
                                      />
                                    </Tooltip>
                                    <Tooltip
                                      title="Change Password"
                                      placement="top"
                                    >
                                      <img
                                        style={{
                                          padding: "6px",
                                        }}
                                        src={PasswordKey}
                                        width="35px"
                                        height="35px"
                                        alt="Delete"
                                        onClick={() =>
                                          setIsShowModal({
                                            status: true,
                                            userId: user.user_id,
                                            editpwdstatus: true,
                                          })
                                        }
                                      />
                                    </Tooltip>
                                  </div>
                                </TableCell>
                              </TableRow>
                            )
                          )
                        )}
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </div>
          {usersList?.data?.items?.length ? (
            <CustomPaginationwithdropdownpagination
              onChangePage={(page: number) => {
                handleGetUsersList(page, pageSize);
                setPage(page);
              }}
              onChangePagerow={(PageSize: number) => {
                if (page * PageSize > usersList?.data?.totalCount) {
                  handleGetUsersList(1, PageSize);
                  setPageSize(PageSize);
                  return;
                }
                handleGetUsersList(page, PageSize);
                setPageSize(PageSize);
              }}
              count={usersList?.data?.totalCount}
              pages={page}
              pageCount={usersList?.data?.total_page}
              handleFunction={(selected: number) => {
                setPage(selected);
                handleGetUsersList(selected, pageSize);
              }}
              labelRowsPerPage={"Devices Per Page"}
              rowsperpage={pageSize}
              totalData={usersList?.data}
            />
          ) : null}
        </>
      )}
    </>
  );
}

export default EmployeeManagement;
