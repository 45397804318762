import { useLocation, useNavigate } from "react-router-dom";

import PageHeader from "../../MangementLayoutHeader/PageHeader";
import Organization from "../Organization";
import { useEffect, useState } from "react";
import { viewServer } from "../../../Services/Services";
import { getCatchMsg } from "../../../Utility/GeneralUtils";
import classes from "./Management.module.css";
import { Loader } from "../../../Components";
import { openLoader } from "../../../Store/Reducers/LoaderSlice";
import { useDispatch } from "react-redux";

type getviewServertype = {
  base_url: string;
  maximumOrg: number;
  activeStatus: number;
  inActiveOrg: number;
  description: string;
  deactivatedCount: number;
  projectName: string;
};
export default function MQTTDetailList() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();

  const [showFilterOption, setShowFilterOption] = useState(false);
  const [Switches, setSwitches] = useState("-2");
  const [getviewServer, setgetviewServer] = useState<any>();

  const handleViewserver = () => {
    dispatch(openLoader(true));
    let formData = new FormData();
    formData.append("server_id", state.server_id);
    viewServer(formData)
      .then((response) => {
        if (response.data.status === 1) {
          setgetviewServer(response.data.data);
        }
      })
      .catch((err) => getCatchMsg(err))
      .finally(() => {
        dispatch(openLoader(false));
      });
  };

  useEffect(() => {
    if (state.org_id) {
      handleViewserver();
    }
  }, [state.org_id]);

  return (
    <>
      <div>
        <PageHeader
          heading={state.server_name}
          Btntitle="Back"
          modal={() => navigate("/dashboard/management/mqtt_management")}
          dropdown
          dropdownValue={Switches || ""}
          dropdownChange={(e) => {
            if (typeof e === "string") {
              setSwitches(e);
            }
          }}
          onFilterbutton={() => {
            setShowFilterOption((pre) => !pre);
          }}
        ></PageHeader>
      </div>
      <div className={classes.ServerHeading}>
        <div className={classes.CustomSwitch}></div>
      </div>
      <div className={`col-lg-9 ${classes.server_details}`}>
        <div className={classes.details}>
          <p>Broker Url</p>
          <strong>:</strong>
          <span>
            {state.broker || getviewServer?.broker
              ? state.broker || getviewServer?.broker
              : "-"}
          </span>
        </div>
        <div className={classes.details}>
          <p> Total Capacity</p>
          <strong>:</strong>
          <span>
            {state.maximumOrg || getviewServer?.maximumOrg
              ? state.maximumOrg || getviewServer?.maximumOrg
              : "-"}
          </span>
        </div>
        <div className={classes.details}>
          <p>Project Name</p>
          <strong>:</strong>
          <span>
            {state.projectName || getviewServer?.projectName
              ? state.projectName || getviewServer?.projectName
              : "-"}
          </span>
        </div>
        <div className={classes.details}>
          <p> Verified Organization</p>
          <strong>:</strong>
          <span>
            {state.activeOrg || getviewServer?.activeStatus
              ? state.activeOrg || getviewServer?.activeStatus
              : state.activeOrg === 0 || getviewServer?.activeStatus === 0
              ? "0"
              : "-"}
          </span>
        </div>
        <div className={classes.details}>
          <p> UnVerified Organization</p>
          <strong>:</strong>
          <span>
            {state.inActiveOrg || getviewServer?.inActiveOrg
              ? state.inActiveOrg || getviewServer?.inActiveOrg
              : state.inActiveOrg === 0 || getviewServer?.inActiveOrg === 0
              ? "0"
              : "-"}
          </span>
        </div>
        <div className={classes.details}>
          <p> Deactivated Organization</p>
          <strong>:</strong>
          <span>
            {state.deactivatedCount || getviewServer?.deactivatedCount
              ? state.deactivatedCount || getviewServer?.deactivatedCount
              : state.deactivatedCount === 0 ||
                getviewServer?.deactivatedCount === 0
              ? "0"
              : "-"}
          </span>
        </div>
        <div className={classes.details}>
          <p> Description</p>
          <strong>:</strong>
          <span>
            {state.description || getviewServer?.description
              ? state.description || getviewServer?.description
              : "-"}
          </span>
        </div>

        <div className={classes.details}>
          <p> MQTT Status</p>
          <strong>:</strong>
          <span>{state.activeStatus === 1 ? "Active" : "Inactive"}</span>
        </div>
      </div>
      <Organization
        FilterOption={showFilterOption}
        activestatus={Switches}
        mqtt_id={state.server_id}
      />
    </>
  );
}
