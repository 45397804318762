import ReactApexChart from "react-apexcharts";

import classes from "./CustomStyle.module.css";
import { useNavigate } from "react-router-dom";
import { BarchartDataPropTypes, barchartDatapropstype } from "./Types";
import { year } from "../Utility/GeneralUtils";
import { useEffect, useState } from "react";
import moment from "moment";
import dayjs from "dayjs";

function BarChart({
  type,
  barchartData,
  title,
  title1,
  date,
}: barchartDatapropstype) {
  const navigate = useNavigate();

  const [reportData, setreportData] = useState<
    { name: string; data: number[] }[]
  >([{ name: "", data: [] }]);

  useEffect(() => {
    if (type === "month") {
      const Data1 = getMonthWiseData(barchartData?.data1);
      const Data2 = getMonthWiseData(barchartData?.data2);

      setreportData([
        { name: title, data: Data1 },
        { name: title1, data: Data2 },
      ]);
    }

    if (type === "week") {
      const Data1 = getWeekWiseData(barchartData?.data1);
      const Data2 = getWeekWiseData(barchartData?.data2);

      setreportData([
        { name: title, data: Data1 },
        { name: title1, data: Data2 },
      ]);
    }
    if (type === "year") {
      const Data1 = getYearWiseData(barchartData?.data1);
      const Data2 = getYearWiseData(barchartData?.data2);

      setreportData([
        { name: title, data: Data1 },
        { name: title1, data: Data2 },
      ]);
    }
    if (type === "date") {
      setreportData([
        {
          name: "Device Count",
          // @ts-ignore
          data: barchartData?.data1?.map(
            (ele: { deviceCount: number }) => ele?.deviceCount
          ),
        },
      ]);
    }

    findNthWeekdayDate(2023, 48, 2);
  }, [barchartData]);

  const getMonthWiseData = (DataItem: BarchartDataPropTypes[]): number[] => {
    const FormattedData = [...Array(31)]?.map((ele, index) => {
      const getDate = DataItem?.find((item, itemIndex) => {
        if (index + 1 === moment(item?.date)?.get("D")) {
          return item?.deviceCount;
        }
      });
      if (getDate) {
        return getDate?.deviceCount;
      }
      return 0;
    });

    return FormattedData;
  };

  const getWeekWiseData = (DataItem: BarchartDataPropTypes[]): number[] => {
    const FormattedData = [...Array(7)]?.map((ele, index) => {
      const getDate = DataItem?.find((item, itemIndex) => {
        if (index === moment(item?.date)?.get("day")) {
          return item?.deviceCount;
        }
      });
      if (getDate) {
        return getDate?.deviceCount;
      }
      return 0;
    });

    return FormattedData;
  };

  const getYearWiseData = (DataItem: BarchartDataPropTypes[]): number[] => {
    const FormattedData = [...Array(12)]?.map((ele, index) => {
      const getDate = DataItem?.find((item, itemIndex) => {
        if (index + 1 === parseInt(item?.date)) {
          return item?.deviceCount;
        }
      });
      if (getDate) {
        return getDate?.deviceCount;
      }
      return 0;
    });

    return FormattedData;
  };

  const findNthWeekdayDate = (year: number, week: number, weekday: number) => {
    // Calculate the first day of the year
    var firstDay = new Date(year, 0, 1);

    // Calculate the offset to the first occurrence of the desired weekday
    var offset = (weekday - firstDay.getDay() + 7) % 7;

    // Calculate the date of the first occurrence of the desired weekday in the first week
    var firstOccurrence = new Date(
      firstDay.getTime() + offset * 24 * 60 * 60 * 1000
    );

    // Calculate the date of the nth occurrence of the desired weekday
    var nthOccurrence = new Date(
      firstOccurrence.getTime() + (week - 1) * 7 * 24 * 60 * 60 * 1000
    );

    return moment(nthOccurrence).format("ll");
  };

  return (
    <div
      className="row"
      // className={classes.barchartdisplay}
    >
      <div className={type === "month" ? "col-md-6 col-12" : "col-12"}>
        <p className={classes.barchartHeader}>
          {title
            ? type === "week"
              ? "Week wise Report"
              : type === "month"
              ? title
              : "Yearwise Report"
            : ""}
        </p>
        <div style={{ width: "100%" }} className={classes.ReactApex}>
          <div style={{ width: "100%" }} className={classes.ReactApexChart}>
            <div
              style={{
                padding: "10px 10px",
                fontWeight: "800",
              }}
            ></div>
            {barchartData?.data1 && (
              <ReactApexChart
                height={400}
                options={{
                  theme: {
                    palette: "palette2",
                  },
                  tooltip: {
                    y: {
                      formatter: (value: number, opts) => {
                        const { dataPointIndex, seriesIndex } = opts;
                        if (date && type === "week") {
                          const getyear = dayjs(date[seriesIndex]).get("year");
                          const getweek = moment(
                            dayjs(date[seriesIndex]).format("YYYY-MM-DD"),
                            "YYYY-MM-DD"
                          ).get("week");

                          const getDate = findNthWeekdayDate(
                            getyear,
                            getweek,
                            dataPointIndex
                          );
                          return value
                            ? `${value?.toString()} ${
                                value === 1 ? "Device" : "Devices"
                              } (${getDate})`
                            : "";
                        }
                        return value
                          ? `${value?.toString()} ${
                              value === 1 ? "Device" : "Devices"
                            }`
                          : "";
                      },
                    },
                  },
                  legend: {
                    position: "bottom",
                  },
                  noData: {
                    text: "No data found !",
                    align: "center",
                    verticalAlign: "middle",
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                      color: "grey",
                      fontSize: "14px",
                    },
                  },

                  // colors: getPieChartData(chart.pinData)?.Colors,
                  // @ts-ignore
                  labels:
                    type === "week"
                      ? [
                          "Sunday",
                          "Monday",
                          "Tuesday",
                          "Wednesday",
                          "Thursday",
                          "Friday",
                          "Saturday",
                        ]
                      : // : type === "month"
                      // ? [...Array(31)]?.map((ele, index) => `Day ${index + 1}`)
                      type === "year"
                      ? year
                      : barchartData?.data1
                      ? barchartData?.data1?.map((ele: any) =>
                          typeof ele?.date === "number"
                            ? year[ele?.date - 1]
                            : ele?.date
                        )
                      : [],

                  chart: {
                    // events: {
                    //   dataPointSelection: (event, chartContext, config) => {
                    //     navigate("/dashboard/management/device_management", {
                    //       state: {
                    //         devicedate:
                    //           config.w.config.labels[config.dataPointIndex],
                    //       },
                    //     });
                    //   },
                    // },
                  },

                  plotOptions: {
                    bar: {
                      columnWidth:
                        type === "month" && window.innerWidth > 1199
                          ? barchartData?.data1?.length < 4
                            ? "20%"
                            : barchartData?.data1?.length < 6
                            ? "45%"
                            : "80%"
                          : "80%",
                    },
                  },
                }}
                series={
                  reportData
                    ? type === "month"
                      ? [
                          {
                            name: "Device Count",
                            // @ts-ignore
                            data: barchartData?.data1?.map(
                              (ele: { deviceCount: number }) => ele?.deviceCount
                            ),
                          },
                        ]
                      : reportData
                    : // [
                      //       {
                      //         name: "Device Count",
                      //         // @ts-ignore
                      //         data: barchartData?.data1?.map(
                      //           (ele: { deviceCount: number }) => ele?.deviceCount
                      //         ),
                      //       },
                      //     ]
                      [{ name: "", data: [] }]
                }
                type="bar"
                // @ts-ignore
              />
            )}
          </div>
        </div>
      </div>
      {type === "month" ? (
        <div className="col-md-6 col-12">
          <p className={classes.barchartHeader}>{title1}</p>
          <div style={{ width: "100%" }} className={classes.ReactApex}>
            <div style={{ width: "100%" }} className={classes.ReactApexChart}>
              <div
                style={{
                  padding: "10px 10px",
                  fontWeight: "800",
                }}
              ></div>
              {barchartData?.data2 && (
                <ReactApexChart
                  height={400}
                  options={{
                    theme: {
                      palette: "palette2",
                    },
                    tooltip: {
                      y: {
                        formatter: (value: number) => {
                          return value ? value?.toString() : "";
                        },
                      },
                    },
                    legend: {
                      position: "bottom",
                    },
                    noData: {
                      text: "No data found !",
                      align: "center",
                      verticalAlign: "middle",
                      offsetX: 0,
                      offsetY: 0,
                      style: {
                        color: "grey",
                        fontSize: "14px",
                      },
                    },

                    // colors: getPieChartData(chart.pinData)?.Colors,
                    // @ts-ignore
                    labels: barchartData?.data2
                      ? barchartData?.data2?.map((ele: any) =>
                          typeof ele?.date === "number"
                            ? year[ele?.date - 1]
                            : ele?.date
                        )
                      : [],
                    // chart: {
                    //   events: {
                    //     dataPointSelection: (event, chartContext, config) => {
                    //       navigate("/dashboard/management/device_management", {
                    //         state: {
                    //           devicedate:
                    //             config.w.config.labels[config.dataPointIndex],
                    //         },
                    //       });
                    //     },
                    //   },
                    // },
                    plotOptions: {
                      bar: {
                        columnWidth:
                          barchartData?.data2?.length < 4 &&
                          window.innerWidth > 1199
                            ? "20%"
                            : barchartData?.data2?.length < 6 &&
                              window.innerWidth > 1199
                            ? "45%"
                            : "80%",
                      },
                    },
                  }}
                  series={
                    barchartData?.data2
                      ? [
                          {
                            name: "Device Count",
                            // @ts-ignore
                            data: barchartData?.data2?.map(
                              (ele: { deviceCount: number }) => ele?.deviceCount
                            ),
                          },
                        ]
                      : [{ name: "", data: [] }]
                  }
                  type="bar"
                  // @ts-ignore
                />
              )}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default BarChart;
