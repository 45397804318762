import { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DatePicker, Radio } from "antd";
import { DatePicker as MUIPicker } from "@mui/x-date-pickers/DatePicker";
import { useFormik } from "formik";
import dayjs from "dayjs";
import { useToken } from "../../Utility/StoreData";
import filterIcon from "../../Assets/Icons/Svg/filter_icon.svg";
import {
  getCountsDashboard,
  getPieChart,
  getBarChart,
  getServerDropdown,
  getProjectWiseReportServices,
} from "../../Services/Services";
import { getCatchMsg, year } from "../../Utility/GeneralUtils";
import PieChart from "../../Components/PieChart";
import BarChart from "../../Components/BarChart";
import {
  PieChartApiResponseProps,
  dashboardApisResponse,
  PickerWithpropsType,
  itemstypeprops,
} from "./DasboardTypes";
import classes from "./Dashboard.module.css";
import SearchAndClearbutton from "../../Components/SearchAndClearbutton";
import {
  CustomDropDown,
  CustomPaginationwithdropdownpagination,
  Loader,
} from "../../Components";
import { ListServerDataProps } from "../../Modals/Types";
import SeemoreIcon from "../../Assets/Icons/Svg/seemoreicon.svg";
import moment from "moment";
import Seemoredouble from "../../Assets/Icons/Png/doublearrow.png";
import SemiDonutChart from "../../Components/SemiDonutChart";
import active from "../../Assets/Icons/Png/active.png";
import inactive from "../../Assets/Icons/Png/inactive.png";
import sale from "../../Assets/Icons/Png/sale.png";
import stock from "../../Assets/Icons/Png/stock.png";
import verified from "../../Assets/Icons/Png/verified.png";
import unverified from "../../Assets/Icons/Png/unverified.png";
import deactivated from "../../Assets/Icons/Png/deactivated.png";

import DashboardCardTextContent from "../../Components/DashboardCardTextContent";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import StackedBarchart from "../../Components/StackedBarchart";
import {
  ProjectWiseDeviceData,
  ProjectWiseOrganizationData,
  ProjectWiseType,
} from "../../Components/Types";
import customStyles from "../../Components/CustomStyle.module.css";
import { openLoader } from "../../Store/Reducers/LoaderSlice";
import { useDispatch } from "react-redux";

function Dashboard({ handledatePicker }: any) {
  const navigate = useNavigate();
  const token = useToken();
  const dispatch = useDispatch();
  const [piechartData, setPieChartData] =
    useState<PieChartApiResponseProps | null>(null);
  const [barchartData, setbarChartData] = useState<any>(null);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [filterOpen, setFilterOpen] = useState<boolean>(false);
  const [barChartfilterOpen, setbarChartFilterOpen] = useState<boolean>(false);
  const [counts, setCounts] = useState<dashboardApisResponse | null>(null);
  const [listServer, setListServer] = useState<ListServerDataProps | null>(
    null
  );
  const [showAll, setShowAll] = useState<boolean>(false);
  const [projectWiseChartOpen, setProjectWiseChartOpen] = useState(false);
  const [projectWiseData, setProjectWiseData] =
    useState<ProjectWiseType | null>(null);
  const [reportLabels, setReportLables] = useState({
    start: "",
    end: "",
    type: "",
  });

  const [chartLabelsOrg, setChartLabelsOrg] = useState<string[]>([]);
  const [chartLabelsDevice, setChartLabelsDevice] = useState<string[]>([]);

  const [ChartDataOrg, setChartDataOrg] = useState<
    ProjectWiseOrganizationData[]
  >([]);
  const [ChartDataDevice, setChartDataDevice] = useState<
    ProjectWiseDeviceData[]
  >([]);
  const [reportData, setreportData] = useState({ data1: null, data2: null });

  const [openDatePicker, setOpenDatePicker] = useState([""]);
  const visibleCharts: any = showAll
    ? piechartData?.items
    : piechartData?.items?.slice(0, 4);

  const { values, setFieldValue, handleSubmit, errors, resetForm, setValues } =
    useFormik({
      initialValues: {
        server_name: "",
        server_id: "",
        day: [dayjs(new Date()), dayjs(new Date()).subtract(1, "month")],
        week: [dayjs(new Date()).subtract(1, "week"), dayjs(new Date())],
        month: [dayjs(new Date()).subtract(1, "month"), dayjs(new Date())],
        year: [dayjs(new Date()).subtract(1, "year"), dayjs(new Date())],
        type: "3",
        projectWiseStart: dayjs(new Date()),
        // fromDate: moment(new Date()).subtract(1, "month").format("YYYY-MM-DD"),
        // toDate: moment(new Date()).format("YYYY-MM-DD"),
      },
      // validationSchema: validationSchema,
      onSubmit: () => {
        setPage(page);
        setPageSize(pageSize);
        handleGetPieChartData(page, pageSize);
        handleGetbarChartData();
      },
    });
  const PickerWithType = ({ type }: PickerWithpropsType) => {
    const { RangePicker } = DatePicker;

    return (
      //@ts-ignore
      <RangePicker
        //@ts-ignore
        picker={type}
        //@ts-ignore
        value={
          type === "date"
            ? values?.day
            : type === "week"
            ? values.week
            : type === "month"
            ? values.month
            : values.year
        }
        onChange={(date: any, value: any) => {
          if (type === "date") {
            setFieldValue("day", date);
          }
          if (type === "week") {
            setFieldValue("week", date);
          }
          if (type === "month") {
            setFieldValue("month", date);
          }
          if (type === "year") {
            setFieldValue("year", date);
          }
          // if (date) {
          //   setFieldValue("day", date);
          // } else {
          //   setFieldValue("day", "");
          // }
        }}
        allowClear={false}
      />
    );
  };
  const [type, setType] = useState("month");
  const handleChange = (e: any) => {
    const typeData =
      e?.target?.value === "date"
        ? "1"
        : e?.target?.value === "week"
        ? "2"
        : e?.target?.value === "month"
        ? "3"
        : "4";
    setType(e?.target?.value);
    setFieldValue("type", typeData);
    handleTableSearch(typeData);
  };

  const handleGetbarChartData = (
    // page: number = 1,
    // size = pageSize ? pageSize : 10
    filtertype?: string
  ) => {
    dispatch(openLoader(true));
    let formdata = new FormData();

    if (filtertype ? filtertype === "1" : type === "date") {
      if (dayjs(values?.day[1])?.isBefore(dayjs(values?.day[0]))) {
        formdata.append(
          "fromDate1",
          dayjs(values?.day[1]).format("YYYY-MM-DD")
        );
        formdata.append("toDate1", dayjs(values?.day[0]).format("YYYY-MM-DD"));
      } else {
        formdata.append(
          "fromDate1",
          dayjs(values?.day[0]).format("YYYY-MM-DD")
        );
        formdata.append("toDate1", dayjs(values?.day[1]).format("YYYY-MM-DD"));
      }
    }
    if (filtertype ? filtertype === "2" : type === "week") {
      const index = dayjs(values?.week[1])
        .startOf("week")
        ?.isBefore(dayjs(values?.week[0]).startOf("week"))
        ? true
        : false;
      formdata.append(
        "fromDate1",
        dayjs(values?.week[index ? 1 : 0])
          .startOf("week")
          .format("YYYY-MM-DD")
      );
      formdata.append(
        "toDate1",
        dayjs(values?.week[index ? 1 : 0])
          .endOf("week")
          .format("YYYY-MM-DD")
      );
      formdata.append(
        "fromDate2",
        dayjs(values?.week[index ? 0 : 1])
          .startOf("week")
          .format("YYYY-MM-DD")
      );
      formdata.append(
        "toDate2",
        dayjs(values?.week[index ? 0 : 1])
          .endOf("week")
          .format("YYYY-MM-DD")
      );
    }

    if (filtertype ? filtertype === "3" : type === "month") {
      const index = dayjs(values?.month[1])
        .startOf("month")
        ?.isBefore(dayjs(values?.month[0]).startOf("month"))
        ? true
        : false;
      formdata.append(
        "fromDate1",
        dayjs(values?.month[index ? 1 : 0])
          .startOf("month")
          .format("YYYY-MM-DD")
      );
      formdata.append(
        "toDate1",
        dayjs(values?.month[index ? 1 : 0])
          .endOf("month")
          .format("YYYY-MM-DD")
      );
      formdata.append(
        "fromDate2",
        dayjs(values?.month[index ? 0 : 1])
          .startOf("month")
          .format("YYYY-MM-DD")
      );
      formdata.append(
        "toDate2",
        dayjs(values?.month[index ? 0 : 1])
          .endOf("month")
          .format("YYYY-MM-DD")
      );
    }
    if (filtertype ? filtertype === "4" : type === "year") {
      const index = dayjs(values?.year[1])
        .startOf("year")
        ?.isBefore(dayjs(values?.year[0]).startOf("year"))
        ? true
        : false;
      formdata.append(
        "fromDate1",
        dayjs(values?.year[index ? 1 : 0])
          .startOf("year")
          .format("YYYY-MM-DD")
      );
      formdata.append(
        "toDate1",
        dayjs(values?.year[index ? 1 : 0])
          .endOf("year")
          .format("YYYY-MM-DD")
      );
      formdata.append(
        "fromDate2",
        dayjs(values?.year[index ? 0 : 1])
          .startOf("year")
          .format("YYYY-MM-DD")
      );
      formdata.append(
        "toDate2",
        dayjs(values?.year[index ? 0 : 1])
          .endOf("year")
          .format("YYYY-MM-DD")
      );
    }

    formdata.append("period", filtertype ? filtertype : values?.type);

    getBarChart(formdata)
      .then((res) => {
        if (res.data.status === 1) {
          setbarChartData(res?.data);
          setReportLables(getReportLabel());
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        dispatch(openLoader(false));
      });
  };

  const getProjectWiseReport = () => {
    const formdata = new FormData();
    formdata.append(
      "fromDate",
      dayjs(values.projectWiseStart).startOf("year").format("YYYY-MM-DD")
    );
    formdata.append(
      "toDate",
      dayjs(values.projectWiseStart).endOf("year").format("YYYY-MM-DD")
    );

    getProjectWiseReportServices(formdata)
      .then((res) => {
        if (res?.data?.status) {
          setProjectWiseData(res?.data?.data);
          handleProjectReportData(res?.data?.data);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      });
  };

  const handleTableSearchClear = () => {
    resetForm();
    handleGetbarChartData();
  };
  const handleTableSearch = (type?: string) => {
    handleGetbarChartData(type);
  };

  useEffect(() => {
    const getType =
      type === "date"
        ? "1"
        : type === "week"
        ? "2"
        : type === "month"
        ? "3"
        : "4";
    setFieldValue("type", getType);
  }, [type]);

  /**
   * Retrieves pie chart data from the server based on the specified parameters.
   * @param {number} [page=1] - The page number to retrieve data from.
   * @param {number} [size=10] - The number of items to retrieve per page.
   * @param {number} [filterBy=1] - The type of filter to apply.
   * @param {filterDataType | null} [filterData=null] - Additional filter data.
   * @returns None
   */
  const handleGetPieChartData = (
    page: number = 1,
    size = pageSize ? pageSize : 10,
    id?: number
  ) => {
    dispatch(openLoader(true));
    let formData = new FormData();

    if (id) {
      formData.append("ServerMasterId", id?.toString());
    } else {
      formData.append("ServerMasterId", "");
    }

    getPieChart(page, size, formData)
      .then((res) => {
        if (res.data.status === 1) {
          setPieChartData(res?.data?.data);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        dispatch(openLoader(false));
      });
  };

  /**
   * Handles the retrieval of counts from the dashboard API.
   * Sets the loader state to true to indicate that data is being fetched.
   * Creates a new FormData object and appends the token to it.
   * Calls the getCountsDashboard function with the FormData object.
   * If the API call is successful and the response status is 1, sets the counts state with the data from the response.
   * Finally, sets the loader state to false to indicate that data fetching is complete.
   * @returns None
   */
  const handleGetCounts = () => {
    dispatch(openLoader(true));
    let formData = new FormData();
    formData.append("token", token);
    getCountsDashboard()
      .then((res) => {
        // const response: dashboardResponseCountsProps = res.data;
        if (res?.data?.status === 1) {
          setCounts(res.data.data);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        dispatch(openLoader(false));
      });
  };

  /**
   * Executes the specified functions when the 'token' dependency changes.
   * @param {function} handleGetCounts - The function to handle getting counts.
   * @param {function} handleGetMachineTypeList - The function to handle getting machine type list.
   * @param {function} handleGetPieChartData - The function to handle getting pie chart data.
   * @param {string} token - The token to check for changes.
   * @returns None
   */

  const handlegetserverlist = () => {
    const formdata = new FormData();
    formdata.append("project_id", "");
    getServerDropdown(formdata)
      .then((response) => {
        if (response?.data?.status === 1) {
          setListServer(response?.data?.data);
        }
      })
      .catch((err) => getCatchMsg(err));
  };
  useEffect(() => {
    handlegetserverlist();
  }, []);
  useEffect(() => {
    if (token) {
      handleGetCounts();
      handleGetPieChartData(page, pageSize);
      handleGetbarChartData();
    }
  }, [token]);
  const getReportLabel = () => {
    const weekNumber1 =
      type === "week" && values?.week
        ? moment(
            dayjs(values?.week[0])?.format("YYYY-MM-DD"),
            "YYYY-MM-DD"
          ).get("week")
        : 0;
    const weekNumber2 =
      values?.type === "2" && values?.week
        ? moment(
            dayjs(values?.week[1])?.format("YYYY-MM-DD"),
            "YYYY-MM-DD"
          ).get("week")
        : 0;
    const monthNumber1 =
      values?.type === "3" && values?.month
        ? moment(
            dayjs(values?.month[0])?.format("YYYY-MM-DD"),
            "YYYY-MM-DD"
          ).get("month")
        : 0;
    const monthNumber2 =
      values?.type === "3" && values?.month
        ? moment(
            dayjs(values?.month[1])?.format("YYYY-MM-DD"),
            "YYYY-MM-DD"
          ).get("month")
        : 0;
    const yearNumber1 =
      values?.type === "4" && values?.year
        ? moment(
            dayjs(values?.year[0])?.format("YYYY-MM-DD"),
            "YYYY-MM-DD"
          ).get("year")
        : 0;
    const yearNumber2 =
      values?.type === "4" && values?.year
        ? moment(
            dayjs(values?.year[1])?.format("YYYY-MM-DD"),
            "YYYY-MM-DD"
          ).get("year")
        : 0;
    if (values?.type === "2") {
      return weekNumber1 > weekNumber2
        ? {
            start:
              weekNumber2 === 1
                ? `${weekNumber2}st Week`
                : weekNumber2 === 2
                ? `${weekNumber2}nd Week`
                : weekNumber2 === 3
                ? `${weekNumber2}rd Week`
                : `${weekNumber2}th Week`,
            end:
              weekNumber1 === 1
                ? `${weekNumber1}st Week`
                : weekNumber1 === 2
                ? `${weekNumber1}nd Week`
                : weekNumber1 === 3
                ? `${weekNumber1}rd Week`
                : `${weekNumber1}th Week`,
            type: "week",
          }
        : {
            start:
              weekNumber1 === 1
                ? `${weekNumber1}st Week`
                : weekNumber1 === 2
                ? `${weekNumber1}nd Week`
                : weekNumber1 === 3
                ? `${weekNumber1}rd Week`
                : `${weekNumber1}th Week`,
            end:
              weekNumber2 === 1
                ? `${weekNumber2}st Week`
                : weekNumber2 === 2
                ? `${weekNumber2}nd Week`
                : weekNumber2 === 3
                ? `${weekNumber2}rd Week`
                : `${weekNumber2}th Week`,
            type: "week",
          };
    } else if (values?.type === "3") {
      return monthNumber1 > monthNumber2
        ? {
            start: `${year[monthNumber2]} Month`,
            end: `${year[monthNumber1]} Month`,
            type: "month",
          }
        : {
            start: `${year[monthNumber1]} Month`,
            end: `${year[monthNumber2]} Month`,
            type: "month",
          };
    } else if (values?.type === "4") {
      return yearNumber1 > yearNumber2
        ? { start: `${yearNumber2}`, end: `${yearNumber1}`, type: "year" }
        : { start: `${yearNumber1}`, end: `${yearNumber2}`, type: "year" };
    }

    return { start: "", end: "", type: "day" };
  };

  useEffect(() => {
    getProjectWiseReport();
  }, [values.projectWiseStart]);

  useEffect(() => {}, []);

  const handleProjectReportData = (ChartData: ProjectWiseType) => {
    const data1 = [...ChartData?.data1]?.map((ele) => year[ele.month - 1]);
    const data2 = [...ChartData?.data2]?.map((ele) => year[ele.month - 1]);

    const getLabels1 = Array.from(new Set(data1));
    const getLabels2 = Array.from(new Set(data2));

    const monthWiseData1 = getLabels1?.map((ele) => {
      const temp = [...ChartData?.data1]?.filter(
        (item) => year[item?.month - 1] === ele
      );
      return { month: ele, data: temp };
    });
    const monthWiseData2 = getLabels2?.map((ele) => {
      const temp = [...ChartData?.data2]?.filter(
        (item) => year[item?.month - 1] === ele
      );
      return { month: ele, data: temp };
    });

    setChartLabelsOrg(getLabels1 || []);
    setChartLabelsDevice(getLabels2 || []);

    const tempdata1 = [...ChartData?.data1];
    const tempdata2 = [...ChartData?.data2];

    const tempprojects1 = new Set([
      ...tempdata1?.map((ele) => ele?.projectName),
    ]);
    const projects1 = Array.from(tempprojects1);
    const tempprojects2 = new Set([
      ...tempdata2?.map((ele) => ele?.projectName),
    ]);
    const projects2 = Array.from(tempprojects2);

    const FormattedData1 = projects1?.map((ele, index) => {
      const data = monthWiseData1?.map((item, itemindex) => {
        return item?.data?.filter((dataItem) => {
          if (dataItem?.projectName === ele) {
            return dataItem;
          }
        });
      });
      return {
        name: ele,
        id: tempdata1?.find((element) => element?.projectName === ele)
          ?.projectId,
        data: data?.map(
          (item) => item?.find((findItem) => findItem?.orgCount)?.orgCount || 0
        ),
      };
    });
    const FormattedData2 = projects2?.map((ele, index) => {
      const data = monthWiseData2?.map((item, itemindex) => {
        return item?.data?.filter((dataItem) => {
          if (dataItem?.projectName === ele) {
            return dataItem;
          }
        });
      });
      return {
        name: ele,
        id: tempdata2?.find((element) => element?.projectName === ele)
          ?.projectId,
        data: data?.map(
          (item) =>
            item?.find((findItem) => findItem?.deviceCount)?.deviceCount || 0
        ),
      };
    });

    // @ts-ignore
    setreportData({ data1: FormattedData1, data2: FormattedData2 });
  };

  return (
    <>
      <div className={classes.dashboard}>
        <div className={classes.header}>
          <div className={classes.header_left}>
            <div className={classes.line}></div>
            <div className={classes.heading}>Dashboard</div>
          </div>
        </div>
        <div className={`row sm-px-35 ${classes.firstChild}`}>
          <div
            className={`
            col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-sm-3 ps-lg-0 +
             ${classes.countboxsize}`}
          >
            <div className={classes.counts}>
              <Grid className={classes.totalcountdisplay}>
                <div style={{ display: "flex" }}>
                  <div className={classes.totalcountdisplayline}></div>
                  <p className={classes.countboxheadingsize}>Server</p>
                </div>
                <Grid
                  onClick={() =>
                    navigate("/dashboard/management/server_management")
                  }
                  style={{
                    background: "#00ADA9",
                  }}
                  className={classes.imageContainer}
                >
                  <Grid className={classes.imageInnerContainer}>
                    <Typography className={classes.countText}>
                      {counts?.serverCount?.totalServer
                        ? counts?.serverCount?.totalServer
                        : "0"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {/* <div className={classes.countbox}>
                <div>
                  <h1 className={classes.ongoingcountboxtext}>
                    {counts?.serverCount?.activeServer
                      ? counts?.serverCount?.activeServer
                      : "0"}
                  </h1>
                  <p className={classes.countboxtextsize}>Active </p>
                </div>

                <div>
                  <h1 className={classes.completecountboxtext}>
                    {counts?.serverCount?.inactiveServer
                      ? counts?.serverCount?.inactiveServer
                      : "0"}
                  </h1>
                  <p className={classes.countboxtextsize}>Inactive</p>
                </div>
              </div> */}
              <Grid className="d-flex align-items-center justify-content-around pt-2">
                <DashboardCardTextContent
                  src={active}
                  color="#00800026"
                  count={
                    counts?.serverCount?.activeServer
                      ? counts?.serverCount?.activeServer
                      : "0"
                  }
                  label="Active"
                  onClick={() => {
                    navigate("/dashboard/management/server_management", {
                      state: {
                        isActive: "1",
                      },
                    });
                  }}
                />

                <DashboardCardTextContent
                  src={inactive}
                  color="rgb(255 0 0 / 15%)"
                  count={
                    counts?.serverCount?.inactiveServer
                      ? counts?.serverCount?.inactiveServer
                      : "0"
                  }
                  label="Inactive"
                  onClick={() => {
                    navigate("/dashboard/management/server_management", {
                      state: {
                        isActive: "2",
                      },
                    });
                  }}
                />
              </Grid>

              {/* <Grid
                className={`d-flex justify-content-between ${classes.cardTextContainer}`}
              >
                <Typography>Server</Typography>
                <Grid>
                  <Typography>
                    {counts?.serverCount?.totalServer
                      ? counts?.serverCount?.totalServer
                      : "0"}
                  </Typography>
                  <Typography>Total</Typography>
                </Grid>
              </Grid>

              <SemiDonutChart
                labels={["Active", "Inactive"]}
                series={[
                  counts?.serverCount?.activeServer
                    ? counts?.serverCount?.activeServer
                    : 0,
                  counts?.serverCount?.inactiveServer
                    ? counts?.serverCount?.inactiveServer
                    : 0,
                ]}
              /> */}
            </div>
          </div>
          <div
            className={`col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-sm-3 ps-lg-0 +
             ${classes.countboxsize}`}
          >
            <div
              className={classes.counts}
              // onClick={() =>
              //   navigate("/dashboard/management/device_management")
              // }
            >
              <Grid className={classes.totalcountdisplay}>
                <div style={{ display: "flex" }}>
                  <div className={classes.totalcountdisplayline}></div>
                  <p className={classes.countboxheadingsize}>Device</p>
                </div>
                <Grid
                  onClick={() =>
                    navigate("/dashboard/management/device_management")
                  }
                  style={{
                    background: "rgb(71 167 209)",
                  }}
                  className={classes.imageContainer}
                >
                  <Grid className={classes.imageInnerContainer}>
                    <Typography className={classes.countText}>
                      {counts?.device?.deviceCount
                        ? counts?.device?.deviceCount
                        : "0"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {/* <div className={classes.countbox}>
                <div>
                  <h1 className={classes.ongoingcountboxtext}>
                    {counts?.device?.AssignedDevice
                      ? counts?.device?.AssignedDevice
                      : "0"}
                  </h1>
                  <p className={classes.countboxtextsize}>Sale </p>
                </div>
                <div>
                  <h1 className={classes.completecountboxtext}>
                    {counts?.device?.deviceRemaining
                      ? counts?.device?.deviceRemaining
                      : "0"}
                  </h1>
                  <p className={classes.countboxtextsize}> Stock</p>
                </div>
              </div> */}

              <Grid className="d-flex align-items-center justify-content-around pt-2">
                <DashboardCardTextContent
                  src={sale}
                  color="rgb(255 175 56 / 20%)"
                  count={
                    counts?.device?.AssignedDevice
                      ? counts?.device?.AssignedDevice
                      : "0"
                  }
                  label="Sale"
                  onClick={() => {
                    navigate("/dashboard/management/device_management", {
                      state: {
                        isStock: "2",
                      },
                    });
                  }}
                />

                <DashboardCardTextContent
                  src={stock}
                  color="rgb(0 173 169 / 20%)"
                  count={
                    counts?.device?.deviceRemaining
                      ? counts?.device?.deviceRemaining
                      : "0"
                  }
                  label="Stock"
                  onClick={() => {
                    navigate("/dashboard/management/device_management", {
                      state: {
                        isStock: "1",
                      },
                    });
                  }}
                />
              </Grid>

              {/* <Grid
                className={`d-flex justify-content-between ${classes.cardTextContainer}`}
              >
                <Typography>Device</Typography>
                <Grid>
                  <Typography>
                    {counts?.device?.deviceCount
                      ? counts?.device?.deviceCount
                      : "0"}
                  </Typography>
                  <Typography>Total</Typography>
                </Grid>
              </Grid>

              <SemiDonutChart
                labels={["Sale", "Stock"]}
                series={[
                  counts?.device?.AssignedDevice
                    ? counts?.device?.AssignedDevice
                    : 0,
                  counts?.device?.deviceRemaining
                    ? counts?.device?.deviceRemaining
                    : 0,
                ]}
              /> */}
            </div>
          </div>
          <div
            className={`col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-sm-3 ps-lg-0 +
             ${classes.countboxsize}`}
          >
            <div
              className={classes.counts}
              style={{
                marginRight: "0px",
              }}
              // onClick={() => navigate("/organization")}
            >
              <Grid className={classes.totalcountdisplay}>
                <div style={{ display: "flex" }}>
                  <div className={classes.totalcountdisplayline}></div>
                  <p className={classes.countboxheadingsize}>Organization</p>
                </div>
                <Grid
                  style={{
                    background: "#ffaf38",
                  }}
                  className={classes.imageContainer}
                >
                  <Grid
                    onClick={() => navigate("/organization")}
                    className={classes.imageInnerContainer}
                  >
                    <div className={classes.circle}></div>
                    <Typography className={classes.countText}>
                      {counts?.organization?.AllOrganization
                        ? counts?.organization?.AllOrganization
                        : "0"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              {/* <div className={classes.countbox}>
                <div>
                  <h1 className={classes.ongoingcountboxtext}>
                    {counts?.organization?.getActiveCount
                      ? counts?.organization?.getActiveCount
                      : "0"}
                  </h1>
                  <p className={classes.countboxtextsize}>Verified</p>
                </div>
                <div>
                  <h1 className={classes.completecountboxtext}>
                    {counts?.organization?.getInactiveCount
                      ? counts?.organization?.getInactiveCount
                      : "0"}
                  </h1>
                  <p className={classes.countboxtextsize}>Unverified</p>
                </div>
                <div>
                  <h1 className={classes.Deactivecountboxtext}>
                    {counts?.organization?.getDeactivatedCount
                      ? counts?.organization?.getDeactivatedCount
                      : "0"}
                  </h1>
                  <p className={classes.countboxtextsize}>Deactivated</p>
                </div>
              </div> */}

              <Grid className="d-flex align-items-center justify-content-around pt-2 flex-wrap flex-xl-nowrap">
                <DashboardCardTextContent
                  src={verified}
                  color="#00800026"
                  count={
                    counts?.organization?.getActiveCount
                      ? counts?.organization?.getActiveCount
                      : "0"
                  }
                  label="Verified"
                  onClick={() => {
                    navigate("/organization", {
                      state: { inactiveOrg: "1" },
                    });
                  }}
                />

                <DashboardCardTextContent
                  src={unverified}
                  color="rgb(255 0 0 / 15%)"
                  count={
                    counts?.organization?.getInactiveCount
                      ? counts?.organization?.getInactiveCount
                      : "0"
                  }
                  label="Unverified"
                  onClick={() => {
                    navigate("/organization", {
                      state: { inactiveOrg: "2" },
                    });
                  }}
                />

                <DashboardCardTextContent
                  src={deactivated}
                  color="rgb(128 128 128 / 20%)"
                  count={
                    counts?.organization?.getDeactivatedCount
                      ? counts?.organization?.getDeactivatedCount
                      : "0"
                  }
                  label="Deactivated"
                  onClick={() => {
                    navigate("/organization", {
                      state: { inactiveOrg: "3" },
                    });
                  }}
                />
              </Grid>

              {/* <Grid
                className={`d-flex justify-content-between ${classes.cardTextContainer}`}
              >
                <Typography>Organization</Typography>
                <Grid>
                  <Typography>
                    {counts?.organization?.AllOrganization
                      ? counts?.organization?.AllOrganization
                      : "0"}
                  </Typography>
                  <Typography>Total</Typography>
                </Grid>
              </Grid>

              <SemiDonutChart
                labels={["Verified", "Unverified", "Deactivated"]}
                series={[
                  counts?.organization?.getActiveCount
                    ? counts?.organization?.getActiveCount
                    : 0,
                  counts?.organization?.getInactiveCount
                    ? counts?.organization?.getInactiveCount
                    : 0,
                  counts?.organization?.getDeactivatedCount
                    ? counts?.organization?.getDeactivatedCount
                    : 0,
                ]}
              /> */}
            </div>
          </div>
          {/* <div
            className={`col-xl-3 col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-sm-3 ps-lg-0 +
             ${classes.countboxsize}`}
          >
            <div
              className={classes.counts}
              // onClick={() =>
              //   navigate("/dashboard/management/employee_management")
              // }
            >
              <Grid className={classes.totalcountdisplay}>
                <p className={classes.countboxheadingsize}>firmware</p>
              </Grid>
              <div className={classes.countbox}>
                <div>
                  <h1 className={classes.ongoingcountboxtext}>
                    {counts?.firmware?.latestVersion
                      ? counts?.firmware?.latestVersion
                      : "0"}
                  </h1>
                  <p className={classes.countboxtextsize}>Version</p>
                </div>
                <div>
                  <h1 className={classes.ongoingcountboxtext}>
                    {counts?.firmware?.latestFirmWare
                      ? counts?.firmware?.latestFirmWare
                      : "0"}
                  </h1>
                  <p className={classes.countboxtextsize}>FirmWare</p>
                </div>
                <div>
                  <h1 className={classes.ongoingcountboxtext}>
                    {counts?.firmware?.colorChanges
                      ? counts?.firmware?.colorChanges
                      : "0"}
                  </h1>
                  <p className={classes.countboxtextsize}>Color</p>
                </div>
              </div>
            </div>
          </div> */}
        </div>

        <Grid
          style={{
            padding: "0px 35px",
            margin: "auto",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <Grid
            marginRight={1}
            marginBottom={2}
            onClick={() =>
              navigate("/dashboard/management/subscription_request")
            }
            // className={`col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12 mb-sm-3 ps-lg-0 +
            //  ${classes.countboxsize}`}
          >
            <Grid className="cardShadows p-2 ">
              <Grid className={classes.totalRequestcountdisplay}>
                <div style={{ display: "flex" }}>
                  <p className={classes.countBoxText}>Subscription Request</p>
                </div>
                <Grid
                  style={{
                    background: "#ffaf38",
                  }}
                  className={classes.CountContainer}
                >
                  <Grid
                    onClick={() => navigate("/organization")}
                    className={classes.CountInnerContainer}
                  >
                    <div className={classes.circle}></div>
                    <Typography className={classes.countText}>
                      {counts?.getSubscriptionRequestCount
                        ?.totalGetSubscriptionRequestCount
                        ? counts?.getSubscriptionRequestCount
                            ?.totalGetSubscriptionRequestCount
                        : "0"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            marginBottom={2}
            marginLeft={"auto"}
            className="d-flex justify-content-end  pb-2"
          >
            <Grid className="cardShadows  p-3 ">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MUIPicker
                  views={["year"]}
                  format="YYYY"
                  onChange={(e) => {
                    if (e) {
                      // @ts-ignore
                      setFieldValue("projectWiseStart", dayjs(e));
                    }
                  }}
                  value={values?.projectWiseStart}
                  label="Select Year"
                  open={openDatePicker?.includes("year")}
                  onClose={() => {
                    setOpenDatePicker([""]);
                  }}
                  onOpen={() => {
                    setOpenDatePicker(["year"]);
                  }}
                  slotProps={{
                    textField: {
                      onClick: () => {
                        setOpenDatePicker(["year"]);
                      },
                    },
                  }}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Grid>

        <Grid className="sm-px-35">
          <Grid className={classes.barchartContainer}>
            {projectWiseData && (
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <Typography className={customStyles.barchartHeader}>
                    Organization Report
                  </Typography>
                  <StackedBarchart
                    data={reportData?.data1}
                    chartLabels={chartLabelsOrg}
                    type={"data1"}
                    showing="Organization"
                    years={dayjs(values?.projectWiseStart).format("YYYY")}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography className={customStyles.barchartHeader}>
                    Device Report
                  </Typography>

                  <StackedBarchart
                    data={reportData?.data2}
                    chartLabels={chartLabelsDevice}
                    type={"data2"}
                    showing="Device"
                    years={dayjs(values?.projectWiseStart).format("YYYY")}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid style={{ padding: "0px 35px", margin: "auto" }} className="py-2">
          {/* <Typography
            style={{
              textAlign: "center",
              fontSize: "20px",
              fontWeight: 600,
            }}
          >
            {reportLabels?.start === "" ? "Pin Duration" : reportLabels?.start}{" "}
            Report
          </Typography> */}
          <div className="pt-1 my-0" style={{ margin: "auto" }}>
            <div className={`my-0 ${classes.filterOptions}`}>
              <p
                className={classes.filtericontxt}
                onClick={() => {
                  resetForm();
                  setbarChartFilterOpen(!barChartfilterOpen);
                }}
              >
                Filter{" "}
                <img
                  src={filterIcon}
                  onClick={() => {
                    resetForm();
                    setbarChartFilterOpen(!barChartfilterOpen);
                  }}
                  alt="filterIcon"
                />
              </p>
            </div>
          </div>
          {barChartfilterOpen ? (
            <div className={`row  mb-3 ${classes.filters2}`}>
              {/* <div className="col-lg-6 col-md-4 col-12">
                <CustomDatePicker
                  title="From date"
                  selectedDate={values.fromDate}
                  onSelectDate={(val) => {
                    setFieldValue("fromDate", moment(val).format("YYYY-MM-DD"));
                  }}
                />
              </div>
              <div className="col-lg-6 col-md-4 col-12">
                <CustomDatePicker
                  title="To date"
                  selectedDate={values.toDate}
                  onSelectDate={(val) => {
                    setFieldValue("toDate", moment(val).format("YYYY-MM-DD"));
                  }}
                />
              </div> */}

              <div className="d-flex align-items-center flex-wrap">
                <Radio.Group
                  className="my-2"
                  value={type}
                  onChange={handleChange}
                >
                  <Radio value="date">Day Wise</Radio>
                  <Radio value="week">Week Wise</Radio>
                  <Radio value="month">Month Wise</Radio>
                  <Radio value="year">Year Wise</Radio>
                </Radio.Group>
                <br></br>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                  className={`my-2 ${classes.filtersdatepicker}`}
                >
                  <div className={classes.pickerxsmb10}>
                    <PickerWithType type={type} />
                  </div>
                  <div className={`mx-4 ${classes.searchClearMb0}`}>
                    <SearchAndClearbutton
                      handleTableSearch={() => {
                        handleTableSearch();
                      }}
                      handleTableSearchClear={handleTableSearchClear}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            false
          )}
        </Grid>
        <div className="sm-px-35">
          <div className={classes.barchartContainer}>
            <Typography className={customStyles.barchartHeader}>
              Sales Comparison Chart
            </Typography>
            {barchartData?.data ? (
              <BarChart
                date={
                  type === "date"
                    ? values.day
                    : type === "week"
                    ? values.week
                    : type === "month"
                    ? values.month
                    : values.year
                }
                title1={
                  type === "week"
                    ? `
                       ${
                         moment(
                           dayjs(values?.week[0])?.format("YYYY-MM-DD"),
                           "YYYY-MM-DD"
                         ).get("week") >
                         moment(
                           dayjs(values?.week[1])?.format("YYYY-MM-DD"),
                           "YYYY-MM-DD"
                         ).get("week")
                           ? moment(
                               dayjs(values?.week[0])?.format("YYYY-MM-DD"),
                               "YYYY-MM-DD"
                             ).get("week")
                           : moment(
                               dayjs(values?.week[1])?.format("YYYY-MM-DD"),
                               "YYYY-MM-DD"
                             ).get("week")
                       }Week`
                    : type === "month"
                    ? `${
                        year[
                          dayjs(values?.month[0])?.get("month") >
                          dayjs(values?.month[1])?.get("month")
                            ? dayjs(values?.month[0])?.get("month")
                            : dayjs(values?.month[1])?.get("month")
                        ]
                      } 
                   
                     `
                    : type === "year"
                    ? `
                    ${
                      dayjs(values?.year[0])?.get("year") >
                      dayjs(values?.year[1])?.get("year")
                        ? dayjs(values?.year[0])?.format("YYYY")
                        : dayjs(values?.year[1])?.format("YYYY")
                    } `
                    : dayjs(values?.day[0])?.format("YYYY-MM-DD")
                }
                type={type}
                title={
                  type === "week"
                    ? `
                       ${
                         moment(
                           dayjs(values?.week[0])?.format("YYYY-MM-DD"),
                           "YYYY-MM-DD"
                         ).get("week") >
                         moment(
                           dayjs(values?.week[1])?.format("YYYY-MM-DD"),
                           "YYYY-MM-DD"
                         ).get("week")
                           ? moment(
                               dayjs(values?.week[1])?.format("YYYY-MM-DD"),
                               "YYYY-MM-DD"
                             ).get("week")
                           : moment(
                               dayjs(values?.week[0])?.format("YYYY-MM-DD"),
                               "YYYY-MM-DD"
                             ).get("week")
                       }Week`
                    : type === "month"
                    ? `${
                        year[
                          dayjs(values?.month[0])?.get("month") >
                          dayjs(values?.month[1])?.get("month")
                            ? dayjs(values?.month[1])?.get("month")
                            : dayjs(values?.month[0])?.get("month")
                        ]
                      } 
                   
                     `
                    : type === "year"
                    ? `
                     ${
                       dayjs(values?.year[0])?.get("year") >
                       dayjs(values?.year[1])?.get("year")
                         ? dayjs(values?.year[1])?.format("YYYY")
                         : dayjs(values?.year[0])?.format("YYYY")
                     } `
                    : ""
                }
                barchartData={barchartData?.data}
              />
            ) : null}
          </div>
        </div>

        {/* <div className="row">
          {piechartData?.items?.map((ele, index) => (
            <div className={`col-lg-3 col-md-4 col-12`}>
              <div>
                <PieChart key={index} piechartData={ele} />
              </div>
            </div>
          ))}
        </div> */}
        <div style={{ padding: "0px 35px", margin: "auto" }}>
          <Grid className="d-flex justify-content-end  py-2">
            <Grid
              className="cardShadows  p-3"
              style={{
                width: "300px",
                maxWidth: "100%",
              }}
            >
              <CustomDropDown
                editName={values.server_name ? values.server_name : "--None--"}
                //@ts-ignore
                items={listServer}
                title="Server Name"
                onSelectedItem={(val, value) => {
                  setFieldValue(
                    "server_name",
                    value?.label ? value?.label : ""
                  );
                  setFieldValue("server_id", value?.id ? value?.id : "");
                  handleGetPieChartData(page, 10, value?.id ? value?.id : "");
                }}
                fieldName="server_name"
                anotherFieldName="server_id"
                value={values.server_id}
                disableClearable={!values?.server_id}
              />
            </Grid>
          </Grid>
          {/* <div className={`${classes.filterOptions} pt-3`}>
            <p
              className={classes.filtericontxt}
              onClick={() => {
                resetForm();
                setFilterOpen(!filterOpen);
              }}
            >
              Filter{" "}
              <img
                src={filterIcon}
                onClick={() => {
                  resetForm();
                  setFilterOpen(!filterOpen);
                }}
                alt="filterIcon"
              />
            </p>
          </div>
          {filterOpen ? (
            <div className={`row   mb-3 col-lg-12 ${classes.filters}`}>
              <div>
                <Grid className="d-flex justify-content-end  py-2">
                  <Grid className="cardShadows  p-3 ">
                    <CustomDropDown
                      editName={
                        values.server_name ? values.server_name : "--None--"
                      }
                      //@ts-ignore
                      items={listServer}
                      title="Server Name"
                      onSelectedItem={(val, value) => {
                        setFieldValue("server_name", value.label);
                        setFieldValue("server_id", value.id);
                        handleGetPieChartData(page, 10, value?.id);
                      }}
                      fieldName="server_name"
                      anotherFieldName="server_id"
                      value={values.server_id}
                    />
                  </Grid>
                </Grid>
              </div>
            </div>
          ) : (
            false
          )} */}
        </div>
        <div>
          <div className={classes.Seemorebtn}>
            {!showAll &&
              piechartData?.items?.length &&
              piechartData?.items?.length > 3 && (
                <p onClick={() => setShowAll(true)}>
                  See More
                  {/* <img src={Seemoredouble} alt="SeemoreIcon" /> */}
                  <KeyboardArrowDown />
                </p>
              )}
            {showAll && (
              <p onClick={() => setShowAll(false)}>
                See Less
                {/* <img src={Seemoredouble} alt="SeemoreIcon" /> */}
                <KeyboardArrowUp />
              </p>
            )}
          </div>

          <div className="row py-3 sm-px-35">
            {visibleCharts?.map((ele: itemstypeprops, index: number) => (
              <div
                className={`col-lg-3 col-md-6 col-12`}
                key={index?.toString()}
              >
                <div>
                  <PieChart piechartData={ele} key={index?.toString()} />
                </div>
              </div>
            ))}
          </div>
          {/* {visibleCharts && (
            <PieChart piechartData={visibleCharts[0]} key={1} />
          )} */}
          <div className={classes.Seemorebtn}>
            {showAll && (
              <p onClick={() => setShowAll(false)}>
                See Less
                {/* <img src={Seemoredouble} alt="SeemoreIcon" /> */}
                <KeyboardArrowUp />
              </p>
            )}
          </div>
        </div>

        {piechartData?.items?.length && showAll ? (
          // <CustomPagination
          //   page={page}
          //   pageCount={piechartData?.total_page}
          //   handleFunction={(selected: number) => {
          //     setPage(selected);
          //     handleGetPieChartData(selected, pageSize, chartFormat, values);
          //   }}
          // />

          <CustomPaginationwithdropdownpagination
            onChangePage={(page: any) => {
              handleGetPieChartData(page, pageSize);
              setPage(page);
            }}
            onChangePagerow={(PageSize: any) => {
              if (page * PageSize > piechartData?.total_count) {
                handleGetPieChartData(1, PageSize);
                setPageSize(PageSize);
                return;
              }
              handleGetPieChartData(page, PageSize);
              setPageSize(PageSize);
            }}
            count={piechartData?.total_count}
            pages={page}
            pageCount={piechartData?.total_page}
            handleFunction={(selected: number) => {
              setPage(selected);
              handleGetPieChartData(selected, pageSize);
            }}
            labelRowsPerPage={"Pie Chart Per Page"}
            rowsperpage={pageSize}
            totalData={piechartData}
          />
        ) : null}
      </div>
    </>
  );
}

export default Dashboard;
