import { useState } from "react";

import Profileimg from "../../../Assets/Images/Png/dummy.png";
import EditIcon from "../../../Assets/Icons/Svg/editWhite.svg";
import classes from "../UserProfile/AdminProfile.module.css";
import { AdminData } from "../../../Utility/StoreData";
import EditProfile from "./EditProfile";

export default function Profile() {
  const adminData = AdminData();
  const [editoption, setEditoption] = useState(false);

  return (
    <>
      <div style={{ padding: "20px 30px" }} className={classes.container}>
        <div className={classes.head}>
          <div className={classes.line}></div>
          <div className={classes.heading}> My Profile</div>
        </div>
        {editoption ? (
          <>
            {" "}
            <EditProfile setEditoption={setEditoption} />
          </>
        ) : (
          <div className="container-fluid">
            <div className={classes.mainContainer}>
              <div className="row">
                <div className="col-xl-3 col-lg-3 col-md-9 col-12">
                  <div className={classes.profileImg}>
                    <img
                      src={adminData?.image}
                      alt="Image"
                      onError={({ currentTarget }) => {
                        currentTarget.src = Profileimg;
                      }}
                    ></img>
                  </div>
                </div>
                <div className="col-xl-9 col-lg-9 col-md-9 col-12">
                  <div className={classes.closed_sec}>
                    <div className={classes.child4}>
                      <img
                        src={EditIcon}
                        style={{ cursor: "pointer" }}
                        onClick={() => setEditoption(!editoption)}
                      ></img>
                    </div>
                  </div>
                  <div className="clearfix"></div>
                  <div className="row">
                    <div
                      className={`
                    
                      col-12 ${classes.child2}`}
                    >
                      <div className={classes.same}>
                        <p>Name : </p>
                        <p>{adminData?.name}</p>
                      </div>
                      <div className={classes.same}>
                        <p>Designation : </p>
                        <p>{adminData?.designationName}</p>
                      </div>
                      <div className={classes.same}>
                        <p>Mobile number : </p>
                        <p>{adminData?.mobileNo}</p>
                      </div>
                      <div className={classes.same}>
                        <p>Organization Name :</p>
                        <p>{adminData?.OrganizationName}</p>
                      </div>
                      <div className={classes.same}>
                        <p>Email id :</p>
                        <p>{adminData?.email}</p>
                      </div>

                      <div className={classes.same}>
                        <p>Alternative number : </p>
                        <p>{adminData?.alternative_mobile_no}</p>
                      </div>
                    </div>
                    {/* <div
                      className={`col-lg-6 col-md-5 col-12 ${classes.child2}`}
                    >
                      <div className={classes.same}>
                        <p>Organization Name :</p>
                        <p>{adminData?.OrganizationName}</p>
                      </div>
                      <div className={classes.same}>
                        <p>Email id :</p>
                        <p>{adminData?.email}</p>
                      </div>

                      <div className={classes.same}>
                        <p>Alternative number : </p>
                        <p>{adminData?.alternative_mobile_no}</p>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
