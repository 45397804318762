import { createSlice } from "@reduxjs/toolkit";

const LoaderSlice = createSlice({
  initialState: {
    loading: false,
  },
  name: "loaderSlice",
  reducers: {
    openLoader: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const { openLoader } = LoaderSlice.actions;
export default LoaderSlice.reducer;
