import ReactApexChart from "react-apexcharts";
import { useNavigate } from "react-router-dom";

import classes from "./CustomStyle.module.css";
import { PieChartProps } from "./Types";

function PieChart({ piechartData, key }: PieChartProps) {
  const navigate = useNavigate();

  const legendData = [
    {
      id: 1,
      colorCode: "#008FFB",
      name: "Active",
    },
    {
      id: 2,
      colorCode: "#00E396",
      name: "Inactive",
    },
    {
      id: 3,
      colorCode: "#FEB019",
      name: "Deactive",
    },
    {
      id: 4,
      colorCode: "#FF4560",
      name: "Vaccant",
    },
  ];
  return (
    <>
      <div className={classes.ReactApex}>
        <div className={classes.ReactApexChart}>
          <div style={{ padding: "10px 20px", fontWeight: "800" }}>
            {piechartData?.serverName}
          </div>
          <ReactApexChart
            options={{
              theme: {
                palette: "palette1",
              },
              tooltip: {
                y: {
                  formatter: (value: number) => {
                    return value.toString();
                  },
                },
              },
              legend: {
                show: false,
                position: "bottom",
              },

              labels: ["Active", "Inactive", "Deactive", "Vaccant"],
              // colors: ["rgb(53, 219, 53)", "#FF0000", "#ffaf38", "#808080"],

              chart: {
                events: {
                  click: function (e, chart, options) {
                    if (e.target.nodeName === "path") {
                      const chartLabels = options.globals.labels;
                      const dataPoint =
                        options.globals.selectedDataPoints?.[0]?.[0];
                      if (
                        (dataPoint && chartLabels[dataPoint] !== "Vaccant") ||
                        (dataPoint === 0 &&
                          chartLabels[dataPoint] !== "Vaccant")
                      ) {
                        navigate("/organization", {
                          state: {
                            device:
                              chartLabels[dataPoint] === "Deactive"
                                ? 3
                                : chartLabels[dataPoint] === "Active"
                                ? 1
                                : chartLabels[dataPoint] === "Inactive"
                                ? 2
                                : null,
                            serverId: piechartData?.ServerId,
                          },
                        });
                      } else {
                        navigate("/dashboard/management/server_management", {
                          state: {
                            device:
                              chartLabels[dataPoint] === "Deactive"
                                ? 3
                                : chartLabels[dataPoint] === "Active"
                                ? 1
                                : chartLabels[dataPoint] === "Inactive"
                                ? 2
                                : null,
                            serverId: piechartData?.ServerId,
                          },
                        });
                      }
                    }
                  },
                },
              },
            }}
            series={[
              piechartData?.datas?.active,
              piechartData?.datas?.inactive,
              piechartData?.datas?.deactivated,
              piechartData?.datas?.remainingSpace,
            ]}
            type="pie"
            // @ts-ignore
            key={key}
          />
          <div className={classes.legendContainer}>
            {legendData?.map((ele) => (
              <div key={ele?.id}>
                <p
                  style={{
                    backgroundColor: ele?.colorCode,
                    width: "10px",
                    height: "10px",
                    borderRadius: "50px",
                  }}
                ></p>
                <p className={classes.name}>{ele?.name}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default PieChart;
