import { Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import customStyle from "./CustomStyle.module.css";

export default function DashboardCardTextContent({
  src,
  count,
  label,
  color,
  onClick,
}: {
  src: string;
  count: number | string;
  label: string;
  color: string;
  onClick?: () => void;
}) {
  return (
    <Grid className={customStyle.cardTextContainer}>
      <IconButton onClick={onClick} style={{ background: color }}>
        <img src={src} />
      </IconButton>
      <Grid className={customStyle.textContainer}>
        <Typography>{count}</Typography>

        <Typography>{label}</Typography>
      </Grid>
    </Grid>
  );
}
