import React from "react";
import { Outlet } from "react-router-dom";

import Header from "../../SharedComponents/Header";
import classes from "./DashboardLayout.module.css";
import { Loader } from "../../Components";
import { GetLoaderStatus } from "../../Utility/StoreData";
import { Grid } from "@mui/material";

export default function DashboardLayout() {
  const loader = GetLoaderStatus();
  return (
    <>
      <div className={classes.container}>
        <Header />
        {loader ? <Loader /> : null}
        <div className={`${classes.bg_img}`}>
          <Grid className="appcontainer">
            <Outlet />
          </Grid>
        </div>
      </div>
    </>
  );
}
