import axios from "axios";
import { baseUrl } from "../Utility/Constants";
import { getCookie, setCookie } from "../Utility/Cookies";

const { REACT_APP_BASEURL } = process.env;

const instance = axios.create({
  // baseURL: baseUrl,
  baseURL: REACT_APP_BASEURL,
});

/**
 * Interceptor function that adds an Authorization header to the request if the user is logged in.
 * @param {Object} request - The request object.
 * @returns The modified request object with the Authorization header added.
 */
instance.interceptors.request.use(
  (request) => {
    const userlog = getCookie("mconnect_admin_data")
      ? JSON.parse(getCookie("mconnect_admin_data"))
      : null;
    if (userlog) {
      request.headers["Authorization"] = `Bearer ${userlog.access_token}`;
    }
    return request;
  },
  (error) => Promise.reject(error)
);

/**
 * Interceptor function for handling responses from API calls.
 * @param {Object} response - The response object from the API call.
 * @returns The response object if the status is not 403 (Forbidden), otherwise it clears the "mconnect_admin_data" cookie and redirects the user to the homepage.
 * @throws {Error} If the status is not 403 and an error occurs.
 */
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 403) {
      setCookie("mconnect_admin_data", null, 0);
      window.location.replace("/");
    } else {
      return Promise.reject(error);
    }
  }
);
export default instance;
