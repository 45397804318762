import PartAndPcbManagement from "../Views/HomeScreens/Management/PartAndPcbManagement";
import QuestionManagemenDetails from "../Views/HomeScreens/Management/QuestionManagemenDetails";
import QuestionManagement from "../Views/HomeScreens/Management/QuestionManagement";
import {
  DeviceManagement,
  EmployeeManagement,
  ServerManagement,
  BoardBatchDetailsList,
  PartManagementDetails,
  ModelManagementDetails,
  FirmwareManagement,
  ServerDetailList,
} from "../Views/HomeScreens/Management/index";
import {
  DeviceSubcriptions,
  MainModel,
  SubModel,
  UserSubscription,
} from "../Views/HomeScreens/Others";
import Projectmanage from "../Views/HomeScreens/Management/Projectmanage";
import SubscriptionRequest from "../Views/HomeScreens/Others/SubscriptionRequest";
import MQTTManagement from "../Views/HomeScreens/Management/MQTTManagement";
import MQTTDetailList from "../Views/HomeScreens/Management/MQTTDetailsList";
import DealerManagement from "../Views/HomeScreens/Management/DealerManagement";
import FirmwareLayout from "../Views/HomeScreens/Firmware/FirmwareLayout";

export const ManagementRoute = [
  {
    path: "question_management",
    children: [
      {
        index: true,
        element: <QuestionManagement />,
      },
      {
        path: "master_question_details",
        element: <QuestionManagemenDetails />,
      },
    ],
  },
  {
    path: "partPcpManagement",
    children: [
      {
        index: true,
        element: <PartAndPcbManagement />,
      },
      {
        path: "Part_Management_details",
        element: <PartManagementDetails />,
      },
      {
        path: "borad_batch_details_list",
        element: <BoardBatchDetailsList />,
      },
    ],
  },
  {
    path: "device_management",
    children: [
      {
        index: true,
        element: <DeviceManagement />,
      },
      {
        path: "model_Management_details",
        element: <ModelManagementDetails />,
      },
    ],
  },
  {
    path: "employee_management",
    element: <EmployeeManagement />,
  },
  {
    path: "dealer_management",
    element: <DealerManagement />,
  },
  {
    path: "project_management",
    element: <Projectmanage />,
  },
  {
    path: "mqtt_management",
    children: [
      { index: true, element: <MQTTManagement /> },
      { path: "mqtt_details_list", element: <MQTTDetailList /> },
    ],
  },
  {
    path: "server_management",
    children: [
      {
        index: true,
        element: <ServerManagement />,
      },
      {
        path: "server_detail_list",
        element: <ServerDetailList />,
      },
    ],
  },
  {
    path: "firmware_management",
    element: <FirmwareLayout />,
  },
  {
    path: "main_model",
    element: <MainModel />,
  },
  {
    path: "sub_model",
    element: <SubModel />,
  },
  {
    path: "device_subcription",
    element: <DeviceSubcriptions />,
  },
  {
    path: "user_subcription",
    element: <UserSubscription />,
  },
  {
    path: "subscription_request",
    element: <SubscriptionRequest />,
  },
];
