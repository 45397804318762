import { PayloadAction } from "@reduxjs/toolkit";
import {
  reducerDataType,
  reduceradminDataType,
  reducerAdminDetailType,
} from "../../Utility/UtilTypes";

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  adminData: null,
  token: null,
  deviceBaseUrl: null,
  adminDetails: null,
};

const { reducer, actions } = createSlice({
  name: "login",
  initialState,
  reducers: {
    handleStoreadminData: (
      state: reducerDataType,
      action: PayloadAction<reduceradminDataType>
    ) => {
      state.adminData = action.payload;
    },
    handleStoreToken: (
      state: reducerDataType,
      action: PayloadAction<string>
    ) => {
      state.token = action.payload;
    },
    handleStoreadminDetails: (
      state: reducerDataType,
      action: PayloadAction<reducerAdminDetailType>
    ) => {
      state.adminDetails = action.payload;
    },
  },
});

export const {
  handleStoreadminData,
  handleStoreToken,
  handleStoreadminDetails,
} = actions;

export default reducer;
