import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import * as Yup from "yup";
import { useFormik } from "formik";

import {
  addDevice,
  getDevicebatchdropdown,
  updateDevice,
  viewDevice,
} from "../Services/Services";
import CustomDropDown from "../Components/CustomDropDown";
import { getCatchMsg } from "../Utility/GeneralUtils";
import { Loader, TextInputBox, CustomButton } from "../Components/index";
import { AddDeviceModalProps, ViewDeviceProps } from "./Types";
import classes from "./Modal.module.css";
import ModalHeader from "./ModalHeader";
import UpdateDeleteActions from "../Components/UpdateDeleteActions";
import moment from "moment";
import { openLoader } from "../Store/Reducers/LoaderSlice";
import { useDispatch } from "react-redux";
import { IMEI_NUMBER_REGEX, MACADDRESS_REGEX } from "../Utility/Constants";

function AddDeviceModal({
  onClose,
  editData,
  listDeviceApicall,
  modalClose,
  getview,
  getviewid,
}: AddDeviceModalProps) {
  const dispatch = useDispatch();

  const validationSchema = Yup.object({
    Macaddress: Yup.string()
      .trim("Remove leading and trailing spaces")
      .strict(true)
      .matches(MACADDRESS_REGEX, "Invalid MAC address")
      .test(
        "one of",
        "* Mac address or imei address is required",
        function (value) {
          const { Imeiaddress } = this.parent;
          if (!value && !Imeiaddress) {
            return false;
          }
          return true;
        }
      ),
    Imeiaddress: Yup.string()
      .trim("Remove leading and trailing spaces")
      .matches(IMEI_NUMBER_REGEX, "Invalid IMEI number")
      .strict(true),
    deviceTitle: Yup.string()
      .trim("Remove leading and trailing spaces")
      .strict(true),
    devicecode: Yup.string()
      .trim("Remove leading and trailing spaces")
      .strict(true),
    batchName: Yup.string().required("* Device batch name is required"),
    secret_key: editData
      ? Yup.string().required("* Secret key is required")
      : Yup.string(),
  });
  const [listbatch, setlistbatch] = useState();

  const [ViewDeviceData, setDeviceData] = useState<ViewDeviceProps | null>(
    null
  );

  const {
    handleChange,
    values,
    setFieldValue,
    setFieldTouched,
    setFieldError,
    handleSubmit,
    handleBlur,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      deviceTitle: getview ? "-" : editData?.device_title || "",
      Macaddress: getview ? "-" : editData?.mac_address || "",
      Imeiaddress: getview ? "-" : editData?.imei_number || "",
      batchId: getview ? "-" : editData?.deviceBatchId || "",
      batchName: getview ? "-" : editData?.batch_version || "",
      devicecode: getview ? "-" : editData?.device_code || "",
      deviceId: getview ? "-" : editData?.device_id || "",
      apicallduration: getview ? "-" : editData?.communicationDuration || "",
      firmwarpath: getview ? "-" : editData?.firmwarePath || "",
      modelName: getview ? "-" : editData?.modelName || "",
      validation: getview ? "-" : editData?.validation || "",
      startAt: getview ? "-" : editData?.startAt || "",
      endAt: getview ? "-" : editData?.endAt || "",
      organization_name: getview ? "-" : editData?.organization_name || "",
      version: getview ? "-" : editData?.version || "",
      secret_key: editData?.secretKey ? editData?.secretKey : "",
      hbPeriod: getview ? "-" : editData?.hb_period || "",
      readIntervel: getview ? "-" : editData?.read_interval || "",
      alert_delay: getview ? "-" : editData?.alert_delay || "",
      debounce: editData?.debounce ?? "",
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      if (editData) {
        handleupdateDevice();
      } else {
        handleAddDevice();
      }
    },
  });

  /**
   * Handles the process of adding a new device.
   * Sets the loader to true to indicate that the process is in progress.
   * Creates a new FormData object and appends the necessary data for the device.
   * Calls the addDevice function with the FormData object.
   * If the response status is 1, calls the listDeviceApicall function to update the device list,
   * closes the modal, and displays a success toast message.
   * If the response status is not 1, displays an error toast message.
   * If there is an error during the process, displays an error toast message with the error message.
   * Finally, sets the loader to false to indicate that the process is complete.
   * @returns None
   */
  const handleAddDevice = () => {
    dispatch(openLoader(true));
    let formData = new FormData();
    formData.append(
      "deviceBatchId",
      !values.batchId ? "0" : values.batchId?.toString()
    );
    formData.append("title", values.deviceTitle);
    formData.append("mac_address", values.Macaddress);
    formData.append("imei_address", values.Imeiaddress);
    formData.append("device_code", values.devicecode);
    addDevice(formData)
      .then((response) => {
        if (response.data.status === 1) {
          listDeviceApicall("add");
          modalClose();
          toast.success(response.data.msg);
        } else {
          toast.error(response.data.msg);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        dispatch(openLoader(false));
      });
  };

  /**
   * Handles the update of a device by sending a request to the server with the updated device information.
   * @returns None
   */
  const handleupdateDevice = () => {
    dispatch(openLoader(true));
    let formData = new FormData();
    formData.append("device_id", values?.deviceId?.toString());
    formData.append(
      "deviceBatchId",
      !values.batchId ? "0" : values.batchId?.toString()
    );
    formData.append("title", values.deviceTitle);
    formData.append("mac_address", values.Macaddress);
    formData.append("imei_address", values.Imeiaddress);
    formData.append("device_code", values.devicecode);
    formData.append("secretKey", values.secret_key);
    formData.append("hb_period", values.hbPeriod.toString());
    formData.append("alert_delay", values.alert_delay.toString());
    formData.append("read_interval", values.readIntervel.toString());
    formData.append("debounce", values.debounce);
    formData.append("api_duration", values.apicallduration.toString());
    if (
      (values.hbPeriod && values.hbPeriod !== editData?.hb_period) ||
      (values.alert_delay && values.alert_delay !== editData?.alert_delay) ||
      (values.readIntervel &&
        values.readIntervel !== editData?.read_interval) ||
      (values.apicallduration &&
        values.apicallduration !== editData?.api_call_duration) ||
      values.debounce !== editData?.debounce
    ) {
      formData.append("changeStatus", "1");
    }
    updateDevice(formData)
      .then((response) => {
        if (response.data.status === 1) {
          listDeviceApicall("update");
          modalClose();
          toast.success(response.data.msg);
        } else {
          toast.error(response.data.msg);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        dispatch(openLoader(false));
      });
  };

  /**
   * Handles the retrieval of device batch IDs by making an API call to getDevicebatchdropdown.
   * Updates the list of batch IDs if the API call is successful.
   * @returns None
   */
  const handleGetbatchid = () => {
    getDevicebatchdropdown()
      .then((response) => {
        if (response.data.status === 1) {
          setlistbatch(response.data.data);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      });
  };
  /**
   * useEffect hook that runs once when the component mounts. It checks if `getview` is false,
   * and if so, calls the `handleGetbatchid` function.
   * @returns None
   */
  useEffect(() => {
    if (!getview) {
      handleGetbatchid();
    }
  }, []);

  /**
   * Handles the view device functionality by making an API call to retrieve device information.
   * @returns None
   */
  const handleViewdevice = () => {
    dispatch(openLoader(true));
    let formData = new FormData();
    if (getviewid) {
      formData.append("device_id", getviewid?.toString());
    }
    viewDevice(formData)
      .then((response) => {
        
        setDeviceData(response?.data);
        setFieldValue("batchName", response?.data?.batch_version);
        setFieldValue("deviceTitle", response?.data?.title);
        setFieldValue("devicecode", response?.data?.device_code);
        setFieldValue("Macaddress", response?.data?.mac_address);
        setFieldValue("Imeiaddress", response?.data?.imei_address);
        setFieldValue("secret_key", response?.data?.secretKey);
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        dispatch(openLoader(false));
      });
  };

  /**
   * useEffect hook that triggers the handleViewdevice function when the value of getview is true.
   * @param {function} handleViewdevice - The function to be executed when getview is true.
   * @param {boolean} getview - The value that triggers the useEffect hook.
   * @returns None
   */
  useEffect(() => {
    if (getview === true) {
      handleViewdevice();
    }
  }, [getview]);

  return (
    <>
      <ModalHeader
        onClose={onClose}
        heading={
          getview
            ? "View Device"
            : editData === null
            ? "Add Device"
            : "Edit Device"
        }
      />
      <div className={classes.add_device}>
        {!getview ? (
          <div className="row">
            <div className="col-md-12 col-xl-6 form-group mb-3">
              {getview ? (
                <TextInputBox
                  name="batchName"
                  title="Batch Id"
                  readOnly={getview}
                  value={values.batchName}
                  onChangeText={(e) => {}}
                  inputBoxStyle={{
                    backgroundColor: getview ? "silver" : "white",
                    cursor: getview ? "not-allowed" : "",
                  }}
                />
              ) : (
                <CustomDropDown
                  editName={values.batchName ? values.batchName : "--None--"}
                  items={listbatch}
                  required={"*"}
                  title="Device Batch"
                  // onClick={handleGetbatchid}
                  onSelectedItem={(val, value) => {
                    setFieldValue("batchName", value.label);
                    setFieldValue("batchId", value.id);
                  }}
                  fieldName="batchName"
                  anotherFieldName="batchId"
                  value={values.batchId}
                  errorText={
                    errors.batchName && touched.batchName
                      ? errors?.batchName
                      : ""
                  }
                />
              )}
            </div>

            <div className="col-xl-6 col-md-12 form-group mb-3">
              <TextInputBox
                textLength={50}
                readOnly={getview}
                value={values.deviceTitle}
                placeHolder="Enter title"
                title="Title"
                name="deviceTitle"
                onChangeText={handleChange("deviceTitle")}
                errorText={
                  !getview && touched.deviceTitle && errors.deviceTitle
                    ? errors.deviceTitle
                    : ""
                }
                onBlur={handleBlur("deviceTitle")}
                inputBoxStyle={{
                  backgroundColor: getview ? "silver" : "white",
                  cursor: getview ? "not-allowed" : "",
                }}
              />
            </div>

            <div
              className={`col-md-12 col-xl-6 form-group mb-3 ${classes?.macBorder}`}
            >
              <TextInputBox
                requiredText="*"
                textLength={17}
                infotxt={
                  !errors.Macaddress && !touched.Macaddress && !getview
                    ? "Mac address or IMEI address is required"
                    : ""
                }
                readOnly={getview}
                placeHolder="Enter Mac Address"
                title="Mac Address"
                name="Macaddress"
                onChangeText={handleChange("Macaddress")}
                value={values.Macaddress}
                errorText={
                  !getview && touched.Macaddress && errors.Macaddress
                    ? errors.Macaddress
                    : ""
                }
                onBlur={() => {
                  try {
                    validationSchema.validateSyncAt(
                      "Macaddress",
                      values.Macaddress
                    );
                  } catch (error) {
                    if (error instanceof Error) {
                      setFieldTouched("Macaddress", true);
                      setFieldError("Macaddress", error.message);
                    }
                  }
                }}
                inputBoxStyle={{
                  backgroundColor: getview ? "silver" : "white",
                  cursor: getview ? "not-allowed" : "",
                }}
              />
            </div>

            <div className="col-md-12 col-xl-6 form-group mb-3">
              <TextInputBox
                requiredText="*"
                textLength={15}
                readOnly={getview}
                placeHolder="Enter Imei Address"
                title="Imei Number"
                name="Imeiaddress"
                onChangeText={handleChange("Imeiaddress")}
                value={values.Imeiaddress}
                errorText={
                  !getview && touched.Imeiaddress && errors.Imeiaddress
                    ? errors.Imeiaddress
                    : ""
                }
                onBlur={handleBlur("Imeiaddress")}
                inputBoxStyle={{
                  backgroundColor: getview ? "silver" : "white",
                  cursor: getview ? "not-allowed" : "",
                }}
              />
            </div>

            <div className="col-md-12 col-xl-6 form-group mb-3">
              <TextInputBox
                value={values.devicecode}
                readOnly={getview}
                textLength={50}
                placeHolder="Enter device code"
                title="Device Code"
                name="devicecode"
                onChangeText={handleChange("devicecode")}
                errorText={
                  !getview && touched.devicecode && errors.devicecode
                    ? errors.devicecode
                    : ""
                }
                onBlur={handleBlur("devicecode")}
                inputBoxStyle={{
                  backgroundColor: getview ? "silver" : "white",
                  cursor: getview ? "not-allowed" : "",
                }}
              />
            </div>
            {editData ? (
              <div className="col-md-12 col-xl-6 form-group mb-3">
                <TextInputBox
                  requiredText={"*"}
                  value={values.secret_key}
                  readOnly={getview}
                  textLength={50}
                  placeHolder="Enter secret key"
                  title="Secret Key"
                  name="secret_key"
                  onChangeText={handleChange("secret_key")}
                  errorText={
                    !getview && touched.secret_key && errors.secret_key
                      ? errors.secret_key
                      : ""
                  }
                  onBlur={handleBlur("secret_key")}
                  inputBoxStyle={{
                    backgroundColor: getview ? "silver" : "white",
                    cursor: getview ? "not-allowed" : "",
                  }}
                />
              </div>
            ) : null}
            {editData ? (
              <>
                <div className="col-md-12 col-xl-6 form-group mb-3">
                  <TextInputBox
                    textLength={50}
                    readOnly={getview}
                    placeHolder="Enter Api Call Duration"
                    title="Api Call Duration"
                    name="apicallduration"
                    onChangeText={(text) => {
                      const numericText = text.replace(/[^0-9]/g, "");
                      handleChange("apicallduration")(numericText);
                    }}
                    value={values.apicallduration}
                    // onBlur={handleBlur("apicallduration")}
                    inputBoxStyle={{
                      backgroundColor: getview ? "silver" : "white",
                      cursor: getview ? "not-allowed" : "",
                    }}
                  />
                </div>
                <div className="col-md-12 col-xl-6 form-group mb-3">
                  <TextInputBox
                    textLength={50}
                    readOnly={getview}
                    placeHolder="Enter Hb Period"
                    title="Hb Period"
                    name="hbPeriod"
                    onChangeText={(text) => {
                      const numericText = text.replace(/[^0-9]/g, "");
                      handleChange("hbPeriod")(numericText);
                    }}
                    value={values.hbPeriod}
                    // onBlur={handleBlur("apicallduration")}
                    inputBoxStyle={{
                      backgroundColor: getview ? "silver" : "white",
                      cursor: getview ? "not-allowed" : "",
                    }}
                  />
                </div>
                <div className="col-md-12 col-xl-6 form-group mb-3">
                  <TextInputBox
                    textLength={50}
                    readOnly={getview}
                    placeHolder="Enter Read Interval"
                    title="Read Interval"
                    name="readIntervel"
                    onChangeText={(text) => {
                      const numericText = text.replace(/[^0-9]/g, "");
                      handleChange("readIntervel")(numericText);
                    }}
                    value={values.readIntervel}
                    // onBlur={handleBlur("apicallduration")}
                    inputBoxStyle={{
                      backgroundColor: getview ? "silver" : "white",
                      cursor: getview ? "not-allowed" : "",
                    }}
                  />
                </div>
                <div className="col-md-12 col-xl-6 form-group mb-3">
                  <TextInputBox
                    textLength={50}
                    readOnly={getview}
                    placeHolder="Enter Alert Delay"
                    title="Alert Delay"
                    name="alert_delay"
                    onChangeText={(text) => {
                      const numericText = text.replace(/[^0-9]/g, "");
                      handleChange("alert_delay")(numericText);
                    }}
                    value={values.alert_delay}
                    // onBlur={handleBlur("apicallduration")}
                    inputBoxStyle={{
                      backgroundColor: getview ? "silver" : "white",
                      cursor: getview ? "not-allowed" : "",
                    }}
                  />
                </div>
              </>
            ) : null}
            {editData?.organization_name ? (
              <div className="col-md-12 col-xl-6 form-group mb-3">
                <TextInputBox
                  textLength={50}
                  readOnly={getview}
                  placeHolder="Enter debounce time"
                  title="Debounce Time(milliseconds)"
                  onChangeText={(text) => {
                    const numericText = text.replace(/[^0-9]/g, "");
                    if (numericText.length === 1 && numericText === "0") {
                      handleChange("debounce")("1");
                    } else {
                      handleChange("debounce")(numericText);
                    }
                  }}
                  value={values.debounce}
                />
              </div>
            ) : null}
          </div>
        ) : (
          <div className={classes.add_mainmodel}>
            <ul>
              <div className={classes.heading}>
                <p>Batch name</p>:
                <span>
                  {ViewDeviceData?.deviceBatchName
                    ? ViewDeviceData?.deviceBatchName
                    : "-"}
                </span>
              </div>
              <div className={classes.heading}>
                <p>Device title</p>:
                <span>
                  {ViewDeviceData?.title ? ViewDeviceData?.title : "-"}
                </span>
              </div>
              <div className={classes.heading}>
                <p>Device code</p>:
                <span>
                  {ViewDeviceData?.device_code
                    ? ViewDeviceData?.device_code
                    : "-"}
                </span>
              </div>
              <div className={classes.heading}>
                <p>Mac address</p>:
                <span>
                  {ViewDeviceData?.mac_address
                    ? ViewDeviceData?.mac_address
                    : "-"}
                </span>
              </div>
              <div className={classes.heading}>
                <p>Imei address</p>:
                <span>
                  {ViewDeviceData?.imei_address
                    ? ViewDeviceData?.imei_address
                    : "-"}
                </span>
              </div>
              <div className={classes.heading}>
                <p>Api Call Duration</p>:{/* @ts-ignore */}
                <span>
                  {ViewDeviceData?.api_call_duration
                    ? ViewDeviceData?.api_call_duration
                    : "-"}
                </span>
              </div>
              <div className={classes.heading}>
                <p>Firmwarepath</p>:{/* @ts-ignore */}
                <span>
                  {ViewDeviceData?.firmwarePath
                    ? ViewDeviceData?.firmwarePath
                    : "-"}
                </span>
                {/* <span>{values.firmwarpath ? values.firmwarpath : "-"}</span> */}
              </div>
              <div className={classes.heading}>
                <p>Model Name</p>:{/* @ts-ignore */}
                <span>
                  {ViewDeviceData?.modelName ? ViewDeviceData?.modelName : "-"}
                </span>
              </div>
              <div className={classes.heading}>
                <p>Version</p>:{/* @ts-ignore */}
                <span>
                  {ViewDeviceData?.version ? ViewDeviceData?.version : "-"}
                </span>
              </div>
              <div className={classes.heading}>
                <p>Organization Name</p>:{/* @ts-ignore */}
                <span>
                  {ViewDeviceData?.organization_name
                    ? ViewDeviceData?.organization_name
                    : "-"}
                </span>
              </div>
              <div className={classes.heading}>
                <p>Validation</p>:
                <span>
                  {ViewDeviceData?.validation
                    ? ViewDeviceData?.validation
                    : "-"}
                </span>
              </div>
              <div className={classes.heading}>
                <p>Subscription Start</p>:
                <span>
                  {ViewDeviceData?.startAt
                    ? moment(ViewDeviceData?.startAt).format("YYYY-MM-DD")
                    : "-"}
                </span>
              </div>
              <div className={classes.heading}>
                <p>Subscription End</p>:
                <span>
                  {ViewDeviceData?.endAt
                    ? moment(ViewDeviceData?.endAt).format("YYYY-MM-DD")
                    : "-"}
                </span>
              </div>
              <div className={classes.heading}>
                <p>Secret Key</p>:
                <span>
                  {ViewDeviceData?.secretKey ? ViewDeviceData?.secretKey : "-"}
                </span>
              </div>
            </ul>
          </div>
        )}

        <div className={classes.modalbtn}>
          {getview ? null : (
            <>
              <div className={classes.modalbtn}>
                {editData ? (
                  <UpdateDeleteActions
                    onCancelPress={() => modalClose()}
                    onUpdatePress={handleSubmit}
                  />
                ) : (
                  <div className="col-lg-3">
                    <CustomButton title="Submit" onButtonPress={handleSubmit} />
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default AddDeviceModal;
