import AuthLayout from "../Layout/AuthLayout";
import Signin from "../Views/AuthScreens/Signin";

export const AuthRoute = [
  {
    path: "/",
    element: <AuthLayout />,
    children: [
      {
        index: true,
        element: <Signin />,
      },
    ],
  },
];
