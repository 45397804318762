import React, { useState } from "react";
import { Box, Tab } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { TabContext, TabList } from "@mui/lab";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import classes from "./Manage.module.css";

const useStyles = makeStyles((theme) => ({
  tabColor: {
    "& .MuiTabs-indicator": {
      backgroundColor: "rgb(242, 89, 34)",
      width: "0px !important",
    },
    "& button": {
      color: "rgba(0, 0, 0, 0.6) !important",
    },
  },
  tabColorNone: {
    "& .MuiTabs-indicator": {
      display: "none !important",
    },
    "& button": {
      color: "rgba(0, 0, 0, 0.6) !important",
    },
  },
  activeTab: {
    "& .MuiTabs-indicator": {
      left: "15px !important",
      width: "50% !important",
      bottom: "8px !important",
    },
  },
  tabBtn: {
    "& button": {
      padding: "12px 16px !important",
      color: "rgba(0, 0, 0, 0.6)",
    },
  },
}));

export default function ManagementLayout() {
  const { pathname } = useLocation();
  const styles = useStyles();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [subAnchor, setSubAnchor] = useState(null);
  const [otherOpen, setOtherOpen] = useState(false);
  const [SubscriptionMenu, setSubscriptionMenu] = useState(false);
  const manageSubheadingData = [
    {
      id: 21,
      name: "Question Management",
      pathname: "/dashboard/management/question_management",
      path: "question_management",
      navigationPath: [
        "/dashboard/management/question_management",
        "/dashboard/management/question_management/master_question_details",
      ],
    },
    {
      id: 22,
      name: "Part/Pcb Management",
      pathname: "/dashboard/management/partPcpManagement",
      path: "partPcpManagement",
      navigationPath: [
        "/dashboard/management/partPcpManagement",
        "/dashboard/management/partPcpManagement/Part_Management_details",
        "/dashboard/management/partPcpManagement/borad_batch_details_list",
      ],
    },
    {
      id: 23,
      name: "Device Management",
      pathname: "/dashboard/management/device_management",
      path: "device_management",
      navigationPath: [
        "/dashboard/management/device_management",
        "/dashboard/management/device_management/model_Management_details",
      ],
    },
    {
      id: 24,
      name: "Server Management",
      pathname: "/dashboard/management/server_management",
      path: "server_management",
      navigationPath: [
        "/dashboard/management/server_management",
        "/dashboard/management/server_management/server_detail_list",
      ],
    },
    {
      id: 25,
      name: "Employee Management",
      pathname: "/dashboard/management/employee_management",
      path: "employee_management",
      navigationPath: ["/dashboard/management/employee_management"],
    },
    {
      id: 25,
      name: "Dealer Management",
      pathname: "/dashboard/management/dealer_management",
      path: "dealer_management",
      navigationPath: ["/dashboard/management/dealer_management"],
    },
    {
      id: 26,
      name: "Firmware Management",
      pathname: "/dashboard/management/firmware_management",
      path: "firmware_management",
      navigationPath: ["/dashboard/management/firmware_management"],
    },
    // {
    //   id: 27,
    //   name: "Subcriptions",
    //   pathname: "/dashboard/management/subcription",
    //   path: "subcription",
    //   navigationPath: ["/dashboard/management/subcription"],
    // },
    {
      id: 27,
      name: "Others",
      alternateName: "Others",
      pathname: "/dashboard/management/main_model",

      // path: "project_management" || "board_category",
      // "sub_model" ||
      // "subcription" ||
      // "board_specification_definition" ||
      // "borad_specification_details_list" ||
      // "designation",
      navigationPath: [
        "/dashboard/management/project_management",
        "/dashboard/management/sub_model",
        "/dashboard/management/board_category",
        "/dashboard/management/device_subcription",
        "/dashboard/management/user_subcription",
        "/dashboard/management/subscription_request",
        "/dashboard/management/categorization",
        "/dashboard/management/designation",
        "/dashboard/management/board_specification_definition",
        "/dashboard/management/board_specification_definition/borad_specification_details_list",
        "/dashboard/management/mqtt_management",
      ],
    },
  ];
  const otherSubHead = [
    {
      id: 1,
      name: "Others",
      dropValue: "Project Management",
      pathname: "/dashboard/management/project_management",
      path: "project_management",
    },
    {
      id: 1,
      name: "Others",
      dropValue: "MQTT Management",
      pathname: "/dashboard/management/mqtt_management",
      path: "mqtt_management",
    },
    // {
    //   id: 2,
    //   name: "Others",
    //   dropValue: "Sub Model",
    //   pathname: "/dashboard/management/sub_model",
    //   path: "sub_model",
    // },
    // {
    //   id: 3,
    //   name: "Others",
    //   dropValue: "Board Category",
    //   pathname: "/dashboard/management/board_category",
    //   path: "board_category",
    // },
    // {
    //   id: 7,
    //   name: "Others",
    //   dropValue: "Board Details",
    //   pathname: "/dashboard/management/board_specification_definition",
    //   path: "board_specification_definition",
    // },
    // {
    //   id: 5,
    //   name: "Others",
    //   dropValue: "Categorization",
    //   pathname: "/dashboard/management/categorization",
    //   path: "categorization",
    // },
    // {
    //   id: 6,
    //   name: "Others",
    //   dropValue: "Designation",
    //   pathname: "/dashboard/management/designation",
    //   path: "designation",
    // },
    {
      id: 4,
      name: "Others",
      dropValue: "Subcriptions",
      pathname: "/dashboard/management/subcription",
      path: "subcription",
    },
  ];
  const subscriptionMenus = [
    {
      id: 1,
      name: "Others",
      dropValue: "Device Subcription",
      pathname: "/dashboard/management/device_subcription",
      path: "device_subcription",
    },
    {
      id: 2,
      name: "Others",
      dropValue: "User Subcription",
      pathname: "/dashboard/management/user_subcription",
      path: "user_subcription",
    },
    {
      id: 2,
      name: "Others",
      dropValue: "Subcription Request",
      pathname: "/dashboard/management/subscription_request",
      path: "subscription_request",
    },
  ];

  const [value, setValue] = React.useState("1");

  const handleTabChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: string
  ) => {
    setValue(newValue);
  };

  const handleClick = () => {
    setOtherOpen(true);
  };
  const handleClose = () => {
    setOtherOpen(false);
    setSubscriptionMenu(false);
  };
  return (
    <>
      <Menu
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "10px !important",
            boxShadow: "1px 1px 10px 1px rgba(0,0,0,0.1) !important",
            border: "none !important",
          },
        }}
        id="basic-menu"
        anchorEl={anchorEl}
        open={otherOpen}
        onClose={() => handleClose()}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {otherSubHead.map((ele, index) => (
          <MenuItem
            ref={(ref: any) => {
              if (ele?.id === 4) {
                setSubAnchor(ref);
              }
            }}
            onClick={() => {
              if (ele?.id === 4) {
                setSubscriptionMenu(true);
                return;
              }
              navigate(ele?.path);
              handleClose();
            }}
            key={index}
          >
            {ele?.dropValue}
          </MenuItem>
        ))}
      </Menu>

      <Menu
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "10px !important",
            boxShadow: "1px 1px 10px 1px rgba(0,0,0,0.1) !important",
            border: "none !important",
          },
        }}
        id="basic-menu"
        anchorEl={subAnchor}
        open={SubscriptionMenu}
        onClose={() => handleClose()}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        {subscriptionMenus.map((ele, index) => (
          <MenuItem
            onClick={() => {
              navigate(ele?.path);
              handleClose();
            }}
            key={index}
          >
            {ele?.dropValue}
          </MenuItem>
        ))}
      </Menu>
      <div className={classes.subHead}>
        {manageSubheadingData.map((ele, ind) => {
          return (
            <>
              <TabContext value={value}>
                <Box className={styles.tabBtn}>
                  <TabList
                    onChange={handleTabChange}
                    aria-label="lab API tabs example"
                    // className={ele.name === value ? "activeTab" : ""}
                    className={
                      // ele.name === value || ele?.pathname === pathname
                      ele?.navigationPath?.includes(pathname)
                        ? `${styles.activeTab} ${styles.tabColor}`
                        : ele?.id === 27
                        ? `${styles.tabColorNone}`
                        : `${styles.tabColor}`
                    }
                  >
                    <Tab
                      ref={(ref: any) => {
                        if (ele.id === 27) {
                          setAnchorEl(ref);
                        }
                      }}
                      label={ele.name}
                      value={ele.name}
                      disableRipple={true}
                      className={`${classes.tabContent} ${
                        ele?.navigationPath?.includes(pathname)
                          ? `${classes.activeTab}`
                          : ""
                      }`}
                      // onClick={() => {
                      //   if (ele?.pathname) {
                      //     navigate(ele?.pathname);
                      //   }
                      // }}
                      onClick={() => {
                        if (ele?.id === 27) {
                          handleClick();
                        } else if (ele?.pathname) {
                          navigate(ele?.pathname);
                        }
                      }}
                    />
                  </TabList>
                </Box>
              </TabContext>
            </>
          );
        })}
      </div>
      <Outlet />
    </>
  );
}
