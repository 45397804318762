import React, { useEffect, useState } from "react";
import {
  board_batch,
  board_category,
  muiStyles,
  parts,
} from "../../../Utility/Constants";
import { Grid } from "@material-ui/core";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList } from "@mui/lab";
import classes from "./Management.module.css";
import { useToken } from "../../../Utility/StoreData";
import {
  listBoardBatchDataItemsProps,
  listBoardBatchProps,
  listboardCategoryProps,
  partNumbersListDataItemsProps,
  partNumbersListProps,
} from "./managementTypes";
import {
  DeleteCategory,
  ListBoardBatch,
  ListBoardCategory,
  PartNumberTableList,
  deletePart,
  getlistCategory,
  listPartnumberdropdowm,
} from "../../../Services/Services";
import { getCatchMsg, isFormDirty } from "../../../Utility/GeneralUtils";
import toast from "react-hot-toast";
import { useFormik } from "formik";
import view from "../../../Assets/Icons/Png/view.png";
import Inbox from "../../../Assets/Images/Png/noData.png";
import edit from "../../../Assets/Icons/Svg/edit.svg";
import delete_icon from "../../../Assets/Icons/Svg/delete.svg";
import AllQuestionIcon from "../../../Assets/Icons/Svg/AllquestionsIcon.svg";
import Tooltip from "@mui/material/Tooltip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  CustomDropDown,
  CustomPaginationwithdropdownpagination,
  GlobalModal,
  Loader,
  NodataLoader,
  TableRowsLoader,
  TextInputBox,
} from "../../../Components";
import moment from "moment";
import SearchAndClearbutton from "../../../Components/SearchAndClearbutton";
import PageHeader from "../../MangementLayoutHeader/PageHeader";
import {
  AddBoardBatch,
  AddBoardCategory,
  AddPartNumber,
  ConfirmationModal,
} from "../../../Modals";
import { useLocation, useNavigate } from "react-router-dom";
import { openLoader } from "../../../Store/Reducers/LoaderSlice";
import { useDispatch } from "react-redux";
import AccordionContent from "../../../Components/AccordionContent";
function PartAndPcbManagement() {
  const styles = muiStyles();
  const token = useToken();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [isShowModal, setIsShowModal] = useState({
    status: false,
    data: null,
    viewStatus: false,
    id: null,
    viewdata: null,
  });
  const [deleteModal, setdeleteModal] = useState({
    modal: false,
    id: "",
  });
  const [categoryList, setCategoryList] = useState();
  const [listboardCategory, setlistBoardCategory] =
    useState<listboardCategoryProps | null>(null);
  const [partNumbersList, setpartNumbersList] =
    useState<partNumbersListProps | null>(null);
  const [listBoardBatch, setlistBoardBatch] =
    useState<listBoardBatchProps | null>(null);
  const [tableHead, setTableHead] = useState(parts);
  const [noDataLoader, setnoDataLoader] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [showFilterOption, setShowFilterOption] = useState(false);
  const [dropdownPartNumber, setDropdownPartNumber] = useState([]);
  const { values, resetForm, handleChange, setFieldValue, initialValues } =
    useFormik({
      initialValues: {
        name: "",
        partNumber: "",
        categoryId: "",
        categoryName: "",
        batchname: "",
        part_name: "",
        part_id: "",
      },
      onSubmit: () => {},
    });
  const PartAndPcbManagementSubHead = [
    {
      id: 21,
      name: board_category,
      pathname: "active",
    },
    {
      id: 22,
      name: parts,
      pathname: "previos",
    },
    {
      id: 23,
      name: board_batch,
      pathname: "previos",
    },
  ];
  useEffect(() => {
    if (token) {
      if (tableHead === board_category) {
        handleGetListOfBoardCategory();
      } else if (tableHead === parts) {
        handleGetListCategory();
      } else {
        handleGetListCategory();
        handleGetListPartNumber();
        handleGetListOfBoardBatch();
      }
    }
  }, [token, tableHead, values.categoryId]);

  // useEffect(() => {
  //   if (token && tableHead === board_batch) {
  //     handleGetListPartNumber();
  //   }
  // }, [token, values.categoryId]);

  const handleClick = (param: string) => {
    setShowFilterOption(false);
    resetForm();
    setTableHead(param);
  };

  const handleTableSearch = (data: string) => {
    if (data === "Enter") {
      setPage(1);
      if (tableHead === board_category) {
        handleGetListOfBoardCategory();
      } else if (tableHead === parts) {
        handleGetListCategory();
        handleListOfPartNumbersList();
      } else {
        handleGetListOfBoardBatch();
      }
    }
  };
  const handleTableSearchClear = () => {
    setPage(1);
    resetForm();
    if (tableHead === board_category) {
      handleGetListOfBoardCategory(undefined, undefined, true);
    } else if (tableHead === parts) {
      handleListOfPartNumbersList(undefined, undefined, true);
      handleGetListCategory();
    } else {
      handleGetListOfBoardBatch(undefined, undefined, true);
    }
  };
  const handleGetListOfBoardCategory = (
    page = 1,
    size = 10,
    resetValue?: boolean
  ) => {
    setnoDataLoader(true);
    let formData = new FormData();
    formData.append("name", resetValue ? "" : values.name);
    ListBoardCategory(page, size, formData)
      .then((response) => {
        const { items } = response?.data?.data;
        setPage(response?.data?.data?.page);
        // if (items?.length > 0) {
        setlistBoardCategory(response?.data);
        // }
      })
      .catch((error) => {
        getCatchMsg(error);
      })
      .finally(() => {
        setnoDataLoader(false);
      });
  };

  const handleDeleteCategory = () => {
    dispatch(openLoader(true));
    let formData = new FormData();
    formData.append("categoryId", deleteModal.id);
    DeleteCategory(formData)
      .then((res) => {
        if (res?.data?.status === 1) {
          handleGetListOfBoardCategory(listboardCategory?.data?.page);

          toast.success(res?.data?.msg);
        } else if (res?.data?.status === 0) {
          toast.error(res?.data?.msg);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        dispatch(openLoader(false));
        setdeleteModal((prev) => {
          return {
            ...prev,
            modal: false,
          };
        });
      });
  };

  const handleListOfPartNumbersList = (
    page = 1,
    size = 10,
    resetValue?: boolean,
    id = ""
  ) => {
    setnoDataLoader(true);
    let formData = new FormData();
    formData.append("partNumber", resetValue ? "" : values.partNumber);
    if (id) {
      formData.append("categoryId", id);
    } else {
      formData.append("categoryId", "");
    }
    PartNumberTableList(page, size, formData)
      .then((response) => {
        setPage(response?.data?.data?.page);
        if (response?.data?.status === 1) {
          setpartNumbersList(response?.data);
        }
      })
      .catch((error) => {
        getCatchMsg(error);
      })
      .finally(() => {
        setnoDataLoader(false);
      });
  };

  const handleDleteParts = () => {
    let formData = new FormData();
    dispatch(openLoader(true));
    formData.append("partId", deleteModal.id);
    deletePart(formData)
      .then((response) => {
        if (response?.data?.status === 1) {
          handleListOfPartNumbersList(partNumbersList?.data?.page);
          toast.success(response?.data?.msg);
        }
      })
      .catch((err) => getCatchMsg(err))
      .finally(() => {
        dispatch(openLoader(false));
        setdeleteModal((prev) => {
          return {
            ...prev,
            modal: false,
          };
        });
      });
  };
  const handleGetListOfBoardBatch = (
    page = 1,
    size = 10,
    resetValue?: boolean,
    id = "",
    part_Id = null
  ) => {
    let formData = new FormData();
    formData.append("name", resetValue ? "" : values.batchname);
    if (id === "") {
      formData.append("categoryId", "");
    } else {
      formData.append("categoryId", id);
    }

    if (part_Id) {
      formData?.append("partNumberId", part_Id);
    }
    ListBoardBatch(page, size, formData)
      .then((response) => {
        setPage(response?.data?.data?.page);
        if (response?.data?.data?.items) {
          setlistBoardBatch(response?.data);
        }
      })
      .catch((error) => {
        getCatchMsg(error);
      })
      .finally(() => {
        setnoDataLoader(false);
      });
  };
  const handleGetListCategory = () => {
    getlistCategory()
      .then((response) => {
        if (response?.data?.status === 1) {
          setCategoryList(response?.data?.data);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      });
  };

  const handleGetListPartNumber = () => {
    const formdata = new FormData();
    formdata.append(
      "categoryTypeId",
      values?.categoryId ? values?.categoryId?.toString() : ""
    );

    listPartnumberdropdowm(formdata)
      .then((response) => {
        if (response?.data?.status === 1) {
          setDropdownPartNumber(response?.data?.data);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      });
  };

  useEffect(() => {
    if (token) {
      handleListOfPartNumbersList();
    }
  }, [token]);

  useEffect(() => {
    if (state?.tab === "board") {
      setTableHead(board_batch);
    }
  }, [state]);

  return (
    <div>
      <PageHeader
        heading="Part/Pcb Management"
        modal={() => {
          setIsShowModal((prev) => {
            return {
              ...prev,
              status: true,
              data: null,
              viewStatus: false,
              id: null,
            };
          });
        }}
        onFilterbutton={() => {
          setShowFilterOption((pre) => !pre);
        }}
        Btntitle={
          tableHead === board_category
            ? "Add Category"
            : tableHead === parts
            ? "Add Part"
            : "Add Board Batch"
        }
      />
      {isShowModal?.status && tableHead === board_category && (
        <GlobalModal
          size="lg"
          modalStyle="globalModalmaxWidth logoutmodalminWidth"
          isVisible={isShowModal.status}
          setIsVisible={() => {
            setIsShowModal((prev) => {
              return {
                ...prev,
                status: true,
              };
            });
          }}
        >
          <AddBoardCategory
            getview={isShowModal.viewStatus}
            onClose={() => {
              setIsShowModal((prev) => {
                return {
                  ...prev,
                  status: false,
                };
              });
            }}
            editData={isShowModal?.data || null}
            listBoardCategoryApicall={(value) => {
              if (value === "update") {
                handleGetListOfBoardCategory(page);
                return;
              }
              handleGetListOfBoardCategory();
            }}
            modalClose={() => {
              setIsShowModal((prev) => {
                return {
                  ...prev,
                  status: false,
                };
              });
            }}
          />
        </GlobalModal>
      )}
      {isShowModal?.status && tableHead === parts && (
        <GlobalModal
          size="lg"
          modalStyle="globalModalmaxWidth logoutmodalminWidth"
          isVisible={isShowModal.status}
          setIsVisible={() => {
            setIsShowModal((prev) => {
              return {
                ...prev,
                status: true,
              };
            });
          }}
        >
          <AddPartNumber
            onClose={() => {
              setIsShowModal((prev) => {
                return {
                  ...prev,
                  status: false,
                };
              });
            }}
            editData={isShowModal.data || null}
            listApiCall={(value) => {
              if (value === "update") {
                handleListOfPartNumbersList(page);
                return;
              }
              handleListOfPartNumbersList();
            }}
            modalClose={() => {
              setIsShowModal((prev) => {
                return {
                  ...prev,
                  status: false,
                };
              });
            }}
          />
        </GlobalModal>
      )}
      {isShowModal?.status && tableHead === board_batch && (
        <GlobalModal
          size="sm"
          modalStyle="globalmodalminWidth"
          isVisible={isShowModal.status}
          setIsVisible={() => {
            setIsShowModal((prev) => {
              return {
                ...prev,
                status: true,
              };
            });
          }}
        >
          <AddBoardBatch
            onClose={() => {
              setIsShowModal((prev) => {
                return {
                  ...prev,
                  status: false,
                };
              });
            }}
            editData={isShowModal?.data}
            listBoardBatchApicall={(value) => {
              if (value === "update") {
                handleGetListOfBoardBatch(page);
                return;
              }
              handleGetListOfBoardBatch();
            }}
            modalClose={() => {
              setIsShowModal((prev) => {
                return {
                  ...prev,
                  status: false,
                };
              });
            }}
          />
        </GlobalModal>
      )}
      {deleteModal?.modal && (
        <GlobalModal
          isVisible={deleteModal.modal}
          setIsVisible={() => {
            setdeleteModal((prev) => {
              return {
                ...prev,
                modal: false,
              };
            });
          }}
        >
          <ConfirmationModal
            msg={`Are you sure that you want to permanently delete this ${
              tableHead === board_category ? "category" : "part number"
            }.
            `}
            onClose={() => {
              setdeleteModal((prev) => {
                return {
                  ...prev,
                  modal: false,
                };
              });
            }}
            onPositiveButtonPressed={() => {
              if (tableHead === board_category) {
                handleDeleteCategory();
              } else if (tableHead === parts) {
                handleDleteParts();
              }
            }}
            onNegativeButtonPressed={() => {
              setdeleteModal((prev) => {
                return {
                  ...prev,
                  modal: false,
                };
              });
            }}
          />
        </GlobalModal>
      )}
      <Grid className={classes.tabContainer}>
        <div className={classes.tableChange}>
          {PartAndPcbManagementSubHead.map((ele) => {
            return (
              <>
                <TabContext value={tableHead}>
                  <Box>
                    <TabList
                      aria-label="lab API tabs example"
                      className={
                        ele.name === tableHead
                          ? `${styles.activeTab} ${styles.tabColor}`
                          : `${styles.tabColor}`
                      }
                    >
                      <Tab
                        label={ele.name}
                        value={ele.name}
                        disableRipple={true}
                        className={`${classes.tabContent} ${
                          ele.name === tableHead
                            ? `${classes.activeTab} activeTab`
                            : ""
                        }`}
                        onClick={() => {
                          handleClick(ele?.name);
                        }}
                      />
                    </TabList>
                  </Box>
                </TabContext>
              </>
            );
          })}
        </div>
      </Grid>

      {tableHead === board_category ? (
        <>
          {/* {showFilterOption ? (
            
          ) : null} */}
          <AccordionContent filterStat={showFilterOption}>
            <div className={classes.serachContainer}>
              <div className="row mb-3">
                <Grid item lg={2} md={4} sm={6} xs={12}>
                  <TextInputBox
                    title="Category name"
                    textLength={50}
                    customInputProps={{
                      onKeyDown: (e) => handleTableSearch(e.key),
                    }}
                    name="name"
                    placeHolder="Search category name"
                    value={values.name}
                    onChangeText={handleChange("name")}
                  />
                </Grid>
              </div>
              <SearchAndClearbutton
                handleTableSearch={handleTableSearch}
                handleTableSearchClear={handleTableSearchClear}
                disabled={!isFormDirty(initialValues, values)}
              />
            </div>
          </AccordionContent>
          <div className={`table-responsive ${classes.AllTable}`}>
            <Box>
              <TableContainer>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell>S.No</TableCell>
                      <TableCell>Category name</TableCell>
                      <TableCell>Created at</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {listboardCategory?.data?.items?.length === 0 ? (
                      <>
                        {noDataLoader ? (
                          <TableRow>
                            <TableCell className={classes.noData} colSpan={9}>
                              <NodataLoader />
                            </TableCell>
                          </TableRow>
                        ) : (
                          <TableRow>
                            <TableCell colSpan={9}>
                              <div className={classes.nodatafound}>
                                <img
                                  src={Inbox}
                                  alt="inbox"
                                  width={100}
                                  height={100}
                                />
                                <p>No data found</p>
                              </div>
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    ) : (
                      <>
                        {noDataLoader ? (
                          <TableRowsLoader columnNum={6} rowsNum={10} />
                        ) : listboardCategory?.data?.items?.length === 0 ||
                          !listboardCategory ? (
                          <TableRow>
                            <TableCell colSpan={9}>
                              <div className={classes.nodatafound}>
                                <img
                                  src={Inbox}
                                  alt="inbox"
                                  width={100}
                                  height={100}
                                />
                                <p>No data found</p>
                              </div>
                            </TableCell>
                          </TableRow>
                        ) : (
                          listboardCategory?.data?.items?.map(
                            (boardcategory, index) => (
                              <TableRow key={index}>
                                <TableCell>
                                  {(listboardCategory?.data?.page - 1) *
                                    listboardCategory?.data?.size +
                                    index +
                                    1}
                                </TableCell>
                                <TableCell>
                                  {boardcategory.categoryName === null
                                    ? "-"
                                    : boardcategory.categoryName}
                                </TableCell>

                                <TableCell>
                                  {boardcategory.createdDate === null
                                    ? "-"
                                    : moment(boardcategory.createdDate).format(
                                        "YYYY-MM-DD hh:mm:ss"
                                      )}
                                </TableCell>

                                <TableCell>
                                  <div className={classes.icons}>
                                    <Tooltip title="View" placement="top">
                                      <img
                                        src={view}
                                        alt="View"
                                        onClick={() =>
                                          //@ts-ignore
                                          setIsShowModal((prev) => {
                                            return {
                                              ...prev,
                                              status: true,
                                              viewStatus: true,
                                              data: boardcategory,
                                            };
                                          })
                                        }
                                      />
                                    </Tooltip>
                                    <Tooltip title="Edit" placement="top">
                                      <img
                                        src={edit}
                                        onClick={() =>
                                          //@ts-ignore
                                          setIsShowModal((prev) => {
                                            return {
                                              ...prev,
                                              status: true,
                                              data: boardcategory,
                                              viewStatus: false,
                                            };
                                          })
                                        }
                                        alt="Edit"
                                      />
                                    </Tooltip>
                                    <Tooltip title="Delete" placement="top">
                                      <img
                                        src={delete_icon}
                                        alt="Delete"
                                        onClick={() =>
                                          //@ts-ignore
                                          setdeleteModal((prev) => {
                                            return {
                                              ...prev,
                                              modal: true,
                                              id: boardcategory.categoryId,
                                            };
                                          })
                                        }
                                      />
                                    </Tooltip>
                                  </div>
                                </TableCell>
                              </TableRow>
                            )
                          )
                        )}
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </div>
          {listboardCategory?.data?.items?.length ? (
            <CustomPaginationwithdropdownpagination
              onChangePage={(page: number) => {
                handleGetListOfBoardCategory(page, pageSize);
                setPage(page);
              }}
              onChangePagerow={(PageSize: number) => {
                if (page * PageSize > listboardCategory?.data?.totalCount) {
                  handleGetListOfBoardCategory(1, PageSize);
                  setPageSize(PageSize);
                  return;
                }
                handleGetListOfBoardCategory(page, PageSize);
                setPageSize(PageSize);
              }}
              count={listboardCategory?.data?.totalCount}
              pages={page}
              pageCount={listboardCategory?.data?.total_page}
              handleFunction={(selected: number) => {
                setPage(selected);
                handleGetListOfBoardCategory(selected, pageSize);
              }}
              labelRowsPerPage={"Devices Per Page"}
              rowsperpage={pageSize}
              totalData={listboardCategory?.data}
            />
          ) : null}
        </>
      ) : tableHead === parts ? (
        <>
          {/* {showFilterOption ? (
            
          ) : null} */}
          <AccordionContent filterStat={showFilterOption}>
            <div className={classes.serachContainer}>
              <div className="row mb-3">
                <Grid item lg={2} md={4} sm={6} xs={12}>
                  <TextInputBox
                    title="Part Number"
                    textLength={50}
                    customInputProps={{
                      onKeyDown: (e) => handleTableSearch(e.key),
                    }}
                    name="partNumber"
                    placeHolder="Search part number"
                    value={values.partNumber}
                    onChangeText={handleChange("partNumber")}
                  />
                </Grid>
                <Grid item lg={2} md={4} sm={6} xs={12}>
                  <CustomDropDown
                    title="Part Category"
                    editName={
                      values.categoryName ? values.categoryName : "--None--"
                    }
                    items={categoryList}
                    onSelectedItem={(val, value) => {
                      handleListOfPartNumbersList(
                        page,
                        pageSize,
                        false,
                        value?.id ? value?.id : ""
                      );
                      setFieldValue(
                        "categoryName",
                        value?.label ? value.label : ""
                      );
                      setFieldValue("categoryId", value?.id ? value?.id : "");
                    }}
                    fieldName="categoryName"
                    anotherFieldName="categoryId"
                    value={values.categoryId}
                    disableClearable={!values.categoryId}
                  />
                </Grid>
              </div>
              <SearchAndClearbutton
                handleTableSearch={handleTableSearch}
                handleTableSearchClear={handleTableSearchClear}
                disabled={!isFormDirty(initialValues, values)}
              />
            </div>
          </AccordionContent>
          <div className={`table-responsive ${classes.AllTable}`}>
            <Box>
              <TableContainer>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell>S.No</TableCell>
                      <TableCell>Part Number</TableCell>
                      <TableCell>Part Category</TableCell>
                      <TableCell>Question Set</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {partNumbersList?.data?.items?.length === 0 ? (
                      <>
                        {noDataLoader ? (
                          <TableRow>
                            <TableCell className={classes.noData} colSpan={9}>
                              <NodataLoader />
                            </TableCell>
                          </TableRow>
                        ) : (
                          <TableRow>
                            <TableCell colSpan={9}>
                              <div className={classes.nodatafound}>
                                <img
                                  src={Inbox}
                                  alt="inbox"
                                  width={100}
                                  height={100}
                                />
                                <p>No data found</p>
                              </div>
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    ) : (
                      <>
                        {noDataLoader ? (
                          <TableRowsLoader columnNum={6} rowsNum={10} />
                        ) : partNumbersList?.data?.items?.length === 0 ||
                          !partNumbersList ? (
                          <TableRow>
                            <TableCell colSpan={9}>
                              <div className={classes.nodatafound}>
                                <img
                                  src={Inbox}
                                  alt="inbox"
                                  width={100}
                                  height={100}
                                />
                                <p>No data found</p>
                              </div>
                            </TableCell>
                          </TableRow>
                        ) : (
                          partNumbersList?.data?.items?.map(
                            (
                              categorization: partNumbersListDataItemsProps,
                              index: number
                            ) => (
                              <TableRow key={index}>
                                <TableCell>
                                  {(partNumbersList?.data?.page - 1) *
                                    partNumbersList?.data?.size +
                                    index +
                                    1}
                                </TableCell>
                                <TableCell>
                                  {categorization?.partName === null
                                    ? "-"
                                    : categorization?.partName}
                                </TableCell>
                                <TableCell>
                                  {categorization?.categoryTypeName === null
                                    ? "-"
                                    : categorization?.categoryTypeName}
                                </TableCell>
                                <TableCell>
                                  {categorization?.questionMasterName === null
                                    ? "-"
                                    : categorization?.questionMasterName}
                                </TableCell>
                                <TableCell>
                                  <div className={classes.icons}>
                                    <Tooltip title="Edit" placement="top">
                                      <img
                                        src={edit}
                                        onClick={() => {
                                          setIsShowModal((prev: any) => {
                                            return {
                                              ...prev,
                                              status: true,
                                              data: categorization,
                                            };
                                          });
                                        }}
                                        alt="Edit"
                                      />
                                    </Tooltip>
                                    <Tooltip title="Delete" placement="top">
                                      <img
                                        src={delete_icon}
                                        alt="Delete"
                                        onClick={() =>
                                          setdeleteModal((prev) => {
                                            return {
                                              ...prev,
                                              modal: true,
                                              id: categorization.id.toString(),
                                            };
                                          })
                                        }
                                      />
                                    </Tooltip>
                                    <Tooltip
                                      title="Question Set"
                                      placement="right"
                                    >
                                      <img
                                        src={AllQuestionIcon}
                                        style={{
                                          padding: "6px",
                                        }}
                                        alt="View"
                                        onClick={() => {
                                          navigate(
                                            {
                                              pathname:
                                                "Part_Management_details",
                                            },
                                            {
                                              state: categorization,
                                            }
                                          );
                                        }}
                                      />
                                    </Tooltip>
                                  </div>
                                </TableCell>
                              </TableRow>
                            )
                          )
                        )}
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </div>
          {partNumbersList?.data?.items?.length ? (
            <CustomPaginationwithdropdownpagination
              onChangePage={(page: number) => {
                handleGetListOfBoardCategory(page, pageSize);
                setPage(page);
              }}
              onChangePagerow={(PageSize: number) => {
                if (page * PageSize > partNumbersList?.data?.totalCount) {
                  handleGetListOfBoardCategory(1, PageSize);
                  setPageSize(PageSize);
                  return;
                }
                handleGetListOfBoardCategory(page, PageSize);
                setPageSize(PageSize);
              }}
              count={partNumbersList?.data?.totalCount}
              pages={page}
              pageCount={partNumbersList?.data?.total_page}
              handleFunction={(selected: number) => {
                setPage(selected);
                handleGetListOfBoardCategory(selected, pageSize);
              }}
              labelRowsPerPage={"Devices Per Page"}
              rowsperpage={pageSize}
              totalData={partNumbersList?.data}
            />
          ) : null}
        </>
      ) : (
        <>
          {/* {showFilterOption ? (
           
          ) : null} */}
          <AccordionContent filterStat={showFilterOption}>
            <div className={classes.serachContainer}>
              <div className="row mb-3">
                <Grid item lg={2} md={4} sm={6} xs={12}>
                  <TextInputBox
                    title="Batch name"
                    textLength={50}
                    customInputProps={{
                      onKeyDown: (e) => handleTableSearch(e.key),
                    }}
                    name="batchname"
                    placeHolder="Enter batch name"
                    value={values.batchname}
                    onChangeText={handleChange("batchname")}
                  />
                </Grid>
                <Grid item lg={2} md={4} sm={6} xs={12}>
                  <CustomDropDown
                    title="Part Category"
                    editName={
                      values.categoryName ? values.categoryName : "--None--"
                    }
                    items={categoryList}
                    onSelectedItem={(val, value) => {
                      handleGetListOfBoardBatch(
                        page,
                        pageSize,
                        false,
                        value?.id ? value?.id : ""
                      );
                      setFieldValue(
                        "categoryName",
                        value?.label ? value.label : ""
                      );
                      setFieldValue("categoryId", value?.id ? value.id : "");

                      if (value) {
                        setFieldValue("part_name", "");
                        setFieldValue("part_id", "");
                      }
                    }}
                    fieldName="categoryName"
                    anotherFieldName="categoryId"
                    value={values.categoryId}
                    disableClearable={values.categoryId ? false : true}
                  />
                </Grid>
                <Grid item lg={2} md={4} sm={6} xs={12}>
                  <CustomDropDown
                    title="Part Number"
                    editName={values.part_name ? values.part_name : "--None--"}
                    items={dropdownPartNumber}
                    onSelectedItem={(val, value) => {
                      handleGetListOfBoardBatch(
                        page,
                        pageSize,
                        false,
                        values?.categoryId,
                        value?.id
                      );
                      setFieldValue(
                        "part_name",
                        value?.label ? value.label : ""
                      );
                      setFieldValue("part_id", value?.id ? value.id : "");
                    }}
                    fieldName="part_name"
                    anotherFieldName="id"
                    value={values.part_id}
                    disableClearable={values.part_id ? false : true}
                  />
                </Grid>
              </div>
              <SearchAndClearbutton
                handleTableSearch={handleTableSearch}
                handleTableSearchClear={handleTableSearchClear}
                disabled={!isFormDirty(initialValues, values)}
              />
            </div>
          </AccordionContent>
          <div className={`table-responsive ${classes.AllTable}`}>
            <Box>
              <TableContainer>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell>S.No</TableCell>
                      <TableCell>Batch name</TableCell>
                      <TableCell>Part Category</TableCell>
                      <TableCell>Part Number</TableCell>
                      <TableCell>Quantity</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {listBoardBatch?.data?.items?.length === 0 ? (
                      <>
                        {noDataLoader ? (
                          <TableRow>
                            <TableCell className={classes.noData} colSpan={9}>
                              <NodataLoader />
                            </TableCell>
                          </TableRow>
                        ) : (
                          <TableRow>
                            <TableCell colSpan={9}>
                              <div className={classes.nodatafound}>
                                <img
                                  src={Inbox}
                                  alt="inbox"
                                  width={100}
                                  height={100}
                                />
                                <p>No data found</p>
                              </div>
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    ) : (
                      <>
                        {noDataLoader ? (
                          <TableRowsLoader columnNum={6} rowsNum={10} />
                        ) : listBoardBatch?.data?.items?.length === 0 ||
                          !listBoardBatch ? (
                          <TableRow>
                            <TableCell colSpan={9}>
                              <div className={classes.nodatafound}>
                                <img
                                  src={Inbox}
                                  alt="inbox"
                                  width={100}
                                  height={100}
                                />
                                <p>No data found</p>
                              </div>
                            </TableCell>
                          </TableRow>
                        ) : (
                          listBoardBatch?.data?.items.map(
                            (
                              boardbatch: listBoardBatchDataItemsProps,
                              index: number
                            ) => (
                              <TableRow key={index}>
                                <TableCell>
                                  {(listBoardBatch?.data?.page - 1) *
                                    listBoardBatch?.data?.size +
                                    index +
                                    1}
                                </TableCell>
                                <TableCell>
                                  {boardbatch?.batchName === null
                                    ? "-"
                                    : boardbatch?.batchName}
                                </TableCell>
                                <TableCell>
                                  {boardbatch?.categoryName === null
                                    ? "-"
                                    : boardbatch?.categoryName}
                                </TableCell>
                                <TableCell>
                                  {boardbatch?.partNumberName
                                    ? boardbatch?.partNumberName
                                    : "-"}
                                </TableCell>

                                <TableCell>
                                  {boardbatch?.quantity === null
                                    ? "-"
                                    : boardbatch?.quantity}
                                </TableCell>

                                <TableCell>
                                  <div className={classes.icons}>
                                    <Tooltip title="View" placement="top">
                                      <img
                                        src={view}
                                        alt="View"
                                        onClick={() => {
                                          navigate(
                                            {
                                              pathname:
                                                "borad_batch_details_list",
                                            },
                                            {
                                              state: boardbatch?.boardBatchId,
                                            }
                                          );
                                        }}
                                      />
                                    </Tooltip>
                                    <Tooltip title="Edit" placement="top">
                                      <img
                                        src={edit}
                                        onClick={() =>
                                          //@ts-ignore
                                          setIsShowModal((prev) => {
                                            return {
                                              ...prev,
                                              status: true,
                                              data: boardbatch,
                                              viewStatus: false,
                                            };
                                          })
                                        }
                                        alt="Edit"
                                      />
                                    </Tooltip>
                                  </div>
                                </TableCell>
                              </TableRow>
                            )
                          )
                        )}
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </div>
          {listBoardBatch?.data?.items?.length ? (
            <CustomPaginationwithdropdownpagination
              onChangePage={(page: number) => {
                handleGetListOfBoardBatch(page, pageSize);
                setPage(page);
              }}
              onChangePagerow={(PageSize: number) => {
                if (page * PageSize > listBoardBatch?.data?.totalCount) {
                  handleGetListOfBoardBatch(1, PageSize);
                  setPageSize(PageSize);
                  return;
                }
                handleGetListOfBoardBatch(page, PageSize);
                setPageSize(PageSize);
              }}
              count={listBoardBatch?.data?.totalCount}
              pages={page}
              pageCount={listBoardBatch?.data?.total_page}
              handleFunction={(selected: number) => {
                setPage(selected);
                handleGetListOfBoardBatch(selected, pageSize);
              }}
              labelRowsPerPage={"Devices Per Page"}
              rowsperpage={pageSize}
              totalData={listBoardBatch?.data}
            />
          ) : null}
        </>
      )}
    </div>
  );
}
export default PartAndPcbManagement;
