import React, { useEffect, useState } from "react";
import PageHeader from "../../MangementLayoutHeader/PageHeader";
import {
  modelListPropsType,
  modelListTypeDataProps,
  setIsShowModalType,
  setdeleteModalType,
} from "../Management/managementTypes";
import { ConfirmationModal, CreateMainModel } from "../../../Modals";
import {
  CustomPagination,
  CustomPaginationDropdown,
  GlobalModal,
  NodataLoader,
  TableRowsLoader,
  TextInputBox,
} from "../../../Components";
import { deletemainmodel, mainmodellist } from "../../../Services/Services";
import { useFormik } from "formik";
import { getCatchMsg, isFormDirty } from "../../../Utility/GeneralUtils";
import { Tooltip } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Box } from "@mui/material";
import classes from "../Management/Management.module.css";
import view from "../../../Assets/Icons/Png/view.png";
import edit from "../../../Assets/Icons/Svg/edit.svg";
import delete_icon from "../../../Assets/Icons/Svg/delete.svg";
import Inbox from "../../../Assets/Images/Png/noData.png";
import closeIcon from "../../../Assets/Icons/Png/texboxClose.png";
import { useToken } from "../../../Utility/StoreData";
import { toast } from "react-hot-toast";
import { Grid } from "@material-ui/core";
import SearchAndClearbutton from "../../../Components/SearchAndClearbutton";
import { useDispatch } from "react-redux";
import { openLoader } from "../../../Store/Reducers/LoaderSlice";
import AccordionContent from "../../../Components/AccordionContent";

function MainModel() {
  const token = useToken();
  const dispatch = useDispatch();
  const { values, handleChange, resetForm, setFieldValue, initialValues } =
    useFormik({
      initialValues: {
        name: "",
        submodelname: "",
        description: "",
        mainModelName: "",
        mainModelId: "",
      },
      onSubmit: () => {},
    });
  const [showFilterOption, setShowFilterOption] = useState(false);
  const [isShowModal, setIsShowModal] = useState<setIsShowModalType>({
    status: false,
    data: null,
    viewStatus: false,
    viewId: "",
  });
  const [deleteModal, setdeleteModal] = useState<setdeleteModalType>({
    modal: false,
    id: "",
  });
  const [noDataLoader, setnoDataLoader] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [mainmodelList, setmainmodelList] =
    useState<modelListTypeDataProps | null>(null);
  const [searchOption, setSearchOption] = useState(false);

  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);

  const handleTableSearch = (data: string) => {
    if (data === "Enter") {
      setPage(1);
      handleGetMainModelList();
    }
  };
  const handleTableSearchClear = () => {
    setPage(1);
    resetForm();
    handleGetMainModelList(undefined, undefined, true);
  };

  const handleGetMainModelList = (page = 1, size = 10, resetForm?: boolean) => {
    let formData = new FormData();
    formData.append("name", resetForm ? "" : values.name);
    setnoDataLoader(true);
    mainmodellist(page, size, formData)
      .then((response) => {
        setPage(response?.data?.data?.page);
        setTotalCount(response?.data?.data?.totalCount);
        if (response?.data?.data?.items?.length > 0) {
          setSearchOption(true);
        } else if (values.name) {
          setSearchOption(true);
        } else {
          setSearchOption(false);
        }
        setmainmodelList(response?.data);
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        setnoDataLoader(false);
      });
  };

  const handleDelerteMainModel = () => {
    let formData = new FormData();
    formData.append("mainModelId", deleteModal.id);
    deletemainmodel(formData)
      .then((res) => {
        if (res?.data?.status === 1) {
          if (totalCount % 10 === 1) {
            handleGetMainModelList(page - 1);
          } else {
            handleGetMainModelList(page);
          }
          toast.success(res?.data?.msg);
        } else if (res?.data?.status === 0) {
          toast.error(res?.data?.msg);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        dispatch(openLoader(false));
        setdeleteModal((prev) => {
          return {
            ...prev,
            modal: false,
          };
        });
      });
  };

  useEffect(() => {
    if (token) {
      handleGetMainModelList();
    }
  }, [token]);

  return (
    <>
      <PageHeader
        heading="Main Model"
        Btntitle="Add Model"
        modal={() => {
          setIsShowModal((prev) => {
            return {
              ...prev,
              status: true,
              data: null,
              viewStatus: false,
            };
          });
        }}
        onFilterbutton={() => {
          setShowFilterOption((pre) => !pre);
        }}
      />
      {isShowModal?.status && (
        <GlobalModal
          size="sm"
          modalStyle="logoutmodalminWidth"
          isVisible={isShowModal.status}
          setIsVisible={() => {
            setIsShowModal((prev) => {
              return {
                ...prev,
                status: true,
              };
            });
          }}
        >
          <CreateMainModel
            getView={isShowModal.viewStatus}
            onClose={() => {
              setIsShowModal((prev) => {
                return {
                  ...prev,
                  status: false,
                };
              });
            }}
            /* @ts-ignore */
            editData={isShowModal?.data || null}
            listmainmodelapicall={(value) => {
              if (value === "update") {
                handleGetMainModelList(page);
                return;
              }
              handleGetMainModelList();
            }}
            modalClose={() => {
              setIsShowModal((prev) => {
                return {
                  ...prev,
                  status: false,
                };
              });
            }}
          />
        </GlobalModal>
      )}
      {deleteModal?.modal && (
        <GlobalModal
          isVisible={deleteModal.modal}
          setIsVisible={() => {
            setdeleteModal((prev) => {
              return {
                ...prev,
                modal: false,
              };
            });
          }}
        >
          <ConfirmationModal
            msg={`Are you sure that you want to permanently delete this main model.
            `}
            onClose={() => {
              setdeleteModal((prev) => {
                return {
                  ...prev,
                  modal: false,
                };
              });
            }}
            onPositiveButtonPressed={handleDelerteMainModel}
            onNegativeButtonPressed={() => {
              setdeleteModal((prev) => {
                return {
                  ...prev,
                  modal: false,
                };
              });
            }}
          />
        </GlobalModal>
      )}
      {/* {showFilterOption ? (
       
      ) : null} */}
      <AccordionContent filterStat={showFilterOption}>
        <div className={classes.serachContainer}>
          <div className="row mb-3">
            <Grid item lg={2} md={4} sm={6} xs={12}>
              <TextInputBox
                title="Name"
                textLength={50}
                customInputProps={{
                  onKeyDown: (e) => handleTableSearch(e.key),
                }}
                name="name"
                placeHolder="Search name"
                value={values.name}
                onChangeText={handleChange("name")}
              />
            </Grid>
          </div>
          <SearchAndClearbutton
            handleTableSearch={handleTableSearch}
            handleTableSearchClear={handleTableSearchClear}
            disabled={!isFormDirty(initialValues, values)}
          />
        </div>
      </AccordionContent>
      <div className={`table-responsive ${classes.AllTable}`}>
        <Box>
          <TableContainer>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>S.No</TableCell>
                  <TableCell>Model name</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {mainmodelList?.data?.items?.length === 0 ? (
                  <>
                    {noDataLoader ? (
                      <TableRow>
                        <TableCell className={classes.noData} colSpan={9}>
                          <NodataLoader />
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableRow>
                        <TableCell colSpan={9}>
                          <div className={classes.nodatafound}>
                            <img
                              src={Inbox}
                              alt="inbox"
                              width={100}
                              height={100}
                            />
                            <p>No data found</p>
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                ) : (
                  <>
                    {noDataLoader ? (
                      <TableRowsLoader columnNum={3} rowsNum={10} />
                    ) : mainmodelList?.data?.items?.length === 0 ||
                      !mainmodelList ? (
                      <TableRow>
                        <TableCell colSpan={9}>
                          <div className={classes.nodatafound}>
                            <img
                              src={Inbox}
                              alt="inbox"
                              width={100}
                              height={100}
                            />
                            <p>No data found</p>
                          </div>
                        </TableCell>
                      </TableRow>
                    ) : (
                      mainmodelList?.data?.items?.map(
                        (mainmodel: modelListPropsType, index: number) => (
                          <TableRow key={index}>
                            <TableCell>
                              {(mainmodelList?.data?.page - 1) *
                                mainmodelList?.data?.size +
                                index +
                                1}
                            </TableCell>
                            <TableCell>
                              {mainmodel.name === null ? "-" : mainmodel.name}
                            </TableCell>
                            <TableCell>
                              <div className={classes.icons}>
                                <Tooltip title="View" placement="top">
                                  <img
                                    src={view}
                                    alt="View"
                                    onClick={() =>
                                      setIsShowModal((prev: any) => {
                                        return {
                                          ...prev,
                                          status: true,
                                          viewStatus: true,
                                          data: mainmodel,
                                        };
                                      })
                                    }
                                  />
                                </Tooltip>
                                <Tooltip title="Edit" placement="top">
                                  <img
                                    src={edit}
                                    onClick={() => {
                                      setIsShowModal({
                                        status: true,
                                        data: mainmodel,
                                        viewStatus: false,
                                      });
                                    }}
                                    alt="Edit"
                                  />
                                </Tooltip>
                                <Tooltip title="Delete" placement="top">
                                  <img
                                    src={delete_icon}
                                    alt="Delete"
                                    onClick={() =>
                                      setdeleteModal((prev: any) => {
                                        return {
                                          ...prev,
                                          modal: true,
                                          id: mainmodel.id,
                                        };
                                      })
                                    }
                                  />
                                </Tooltip>
                              </div>
                            </TableCell>
                          </TableRow>
                        )
                      )
                    )}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </div>
      {mainmodelList && (
        <CustomPagination
          page={page}
          pageCount={mainmodelList?.data?.total_page}
          handleFunction={(selected) => {
            setPage(selected);
            handleGetMainModelList(selected);
          }}
        />
      )}
    </>
  );
}

export default MainModel;
