import React, { useRef, useState } from "react";
import {
  Firmware_managemnet,
  Firmware_master,
  muiStyles,
} from "../../../Utility/Constants";
import PageHeader from "../../MangementLayoutHeader/PageHeader";
import { isShowAssignFirmWareModalProps } from "../Types";
import { isShowModalFirmWareProps } from "../Management/managementTypes";
import { FirmwareContext } from "../../../Utility/Context";
import { Box, Grid, Tab } from "@mui/material";
import classes from "../Management/Management.module.css";
import { TabContext, TabList } from "@mui/lab";
import { FirmwareManagement } from "../Management";
import UploadFirmwareManagement from "./UploadFirmwareManagement";

export type filterStatusProps = {
  master: boolean;
  management: boolean;
};

export default function FirmwareLayout() {
  const muistyle = muiStyles();
  const downloadBtnRef = useRef<any>(null);
  const [downloadBtnStatus, setdownloadBtnStatus] = useState(false);
  const [tableHead, setTableHead] = useState(Firmware_master);
  const [isShowAssignFirmWareModal, setisShowAssignFirmWareModal] =
    useState<isShowAssignFirmWareModalProps>({
      modal: false,
      data: null,
    });
  const [isShowModal, setIsShowModal] = useState<isShowModalFirmWareProps>({
    status: false,
    id: null,
    data: null,
  });

  const [filterStatus, setfilterStatus] = useState<filterStatusProps>({
    master: false,
    management: false,
  });
  const FirmwareHead = [
    {
      id: 21,
      name: Firmware_master,
      pathname: "active",
    },
    {
      id: 22,
      name: Firmware_managemnet,
      pathname: "previos",
    },
  ];

  const handleClick = (param: string) => {
    setdownloadBtnStatus(false);
    setTableHead(param);
    setfilterStatus({ management: false, master: false });
  };

  const handleDownloadBtnClick = () => {
    if (downloadBtnRef.current) {
      downloadBtnRef.current.callChildFunction(); // Call the function from child
    }
  };

  return (
    <>
      <FirmwareContext.Provider
        value={{
          filterStatus,
          isShowAssignFirmWareModal,
          isShowModal,
          setfilterStatus,
          setisShowAssignFirmWareModal,
          setIsShowModal,
          setdownloadBtnStatus,
        }}
      >
        <PageHeader
          downloadBtn={downloadBtnStatus}
          onDownloadPress={handleDownloadBtnClick}
          heading={tableHead}
          Btntitle={
            tableHead === Firmware_master ? "Add Firmware" : "Assign Firmware"
          }
          modal={() => {
            if (tableHead === Firmware_master) {
              setIsShowModal((prev) => {
                return {
                  ...prev,
                  status: true,
                  id: null,
                  data: null,
                };
              });
            } else {
              setisShowAssignFirmWareModal((prev) => {
                return {
                  ...prev,
                  data: null,
                  modal: true,
                };
              });
            }
          }}
          onFilterbutton={() => {
            setfilterStatus((prev) => ({
              ...prev,
              master: tableHead === Firmware_master ? !prev.master : false,
              management:
                tableHead === Firmware_master ? false : !prev.management,
            }));
          }}
        />
        <Grid className={classes.tabContainer}>
          <div className={classes.tableChange}>
            {FirmwareHead.map((ele) => {
              return (
                <>
                  <TabContext value={tableHead}>
                    <Box>
                      <TabList
                        aria-label="lab API tabs example"
                        className={
                          ele.name === tableHead
                            ? `${muistyle.activeTab} ${muistyle.tabColor}`
                            : `${muistyle.tabColor}`
                        }
                      >
                        <Tab
                          label={ele.name}
                          value={ele.name}
                          disableRipple={true}
                          className={`${classes.tabContent} ${
                            ele.name === tableHead
                              ? `${classes.activeTab} activeTab`
                              : ""
                          }`}
                          onClick={() => {
                            handleClick(ele?.name);
                          }}
                        />
                      </TabList>
                    </Box>
                  </TabContext>
                </>
              );
            })}
          </div>
        </Grid>
        {tableHead === Firmware_master ? (
          <FirmwareManagement />
        ) : (
          <UploadFirmwareManagement ref={downloadBtnRef} />
        )}
      </FirmwareContext.Provider>
    </>
  );
}
