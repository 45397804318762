import React, { useEffect, useState } from "react";
import {
  DeviceBatchDropdown,
  DeviceListItemProps,
  DeviceListProps,
  activeInactiveModalProps,
} from "./managementTypes";
import classes from "./Management.module.css";
import * as Yup from "yup";
import { Grid, TextField, makeStyles } from "@material-ui/core";
import {
  CustomButton,
  CustomDropDown,
  CustomPaginationwithdropdownpagination,
  CustomSwitch,
  GlobalModal,
  Loader,
  NodataLoader,
  TableRowsLoader,
  TextInputBox,
} from "../../../Components";
import { useFormik } from "formik";
import {
  activeInactiveDevice,
  deleteDevice,
  deviceProtocol,
  getDevicebatchdropdown,
  getSubcriptiondropdown,
  listDeviceMachine,
  removeOrganzation,
} from "../../../Services/Services";
import { getCatchMsg, isFormDirty } from "../../../Utility/GeneralUtils";
import SearchAndClearbutton from "../../../Components/SearchAndClearbutton";
import Tooltip from "@mui/material/Tooltip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Autocomplete, Box } from "@mui/material";
import toast from "react-hot-toast";
import Inbox from "../../../Assets/Images/Png/noData.png";
import edit from "../../../Assets/Icons/Svg/edit.svg";
import view from "../../../Assets/Icons/Png/view.png";
import link_icon from "../../../Assets/Icons/Svg/link_icon.svg";
import delete_icon from "../../../Assets/Icons/Svg/delete.svg";
import {
  AddDeviceModal,
  AddDeviceModalLink,
  ConfirmationModal,
} from "../../../Modals";
import moment from "moment";
import ModalHeader from "../../../Modals/ModalHeader";
import { useToken } from "../../../Utility/StoreData";
import { device } from "../../../Utility/Constants";
import { useLocation } from "react-router-dom";
import PlanConfirmationModal from "../../../Modals/PlanConfirmationModal";
import { openLoader } from "../../../Store/Reducers/LoaderSlice";
import { useDispatch } from "react-redux";
import AccordionContent from "../../../Components/AccordionContent";
import orgremove from "../../../Assets/Icons/Png/remove (1).png";
import removedev from "../../../Assets/Icons/Png/removedev.png";
const validation = Yup.object({
  subscriptionName: Yup.string().required("Subcription is required"),
});

const useStyles = makeStyles(() => ({
  switchContainer: {
    marginRight: "10px",
    display: "flex",
    alignItems: "center",
  },
}));

export type isMQTTProps = {
  id: null | number;
  status: boolean;
  isMqtt: null | number;
};

function DeviceBatch({
  orgid,
  FilterOption,
  addModalStatus,
  head = "",
  DeviceState,
  from = "",
}: {
  orgid?: number;
  FilterOption?: boolean;
  addModalStatus?: any;
  head: string;
  DeviceState?: {
    isStock: string;
  };
  from?: string;
}) {
  const {
    values,
    handleChange,
    resetForm,
    setFieldValue,
    touched,
    errors,
    handleSubmit,
    initialValues,
  } = useFormik({
    initialValues: {
      devicecode: "",
      mac_address: "",
      imei_number: "",
      Batchversion: "",
      Organizationname: "",
      subscriptionId: "",
      subscriptionName: "",
      batchname: "",
      batch_id: "",
    },
    validationSchema: validation,
    onSubmit: () => {
      handleActiveAndInactiveServer();
    },
  });
  const style = useStyles();
  const token = useToken();
  const dispatch = useDispatch();

  const { state } = useLocation();
  const [subcriptionList, setSubcriptionList] = useState();
  const [deviceList, setDeviceList] = useState<DeviceListProps>();
  const [noDataLoader, setnoDataLoader] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [Switches, setSwitches] = useState<boolean[]>([]);
  const [Subscribebtn, setSubscibebtn] = useState([]);
  const [batchList, setBatchList] = useState([]);
  const [removeDevice, setremovedevice] = useState({
    show: false,
    data: {},
  });
  const [isMQTT, setisMQTT] = useState<isMQTTProps>({
    id: null,
    status: false,
    isMqtt: null,
  });
  const [isShowModal, setIsShowModal] = useState<any>({
    status: false,
    data: null,
    viewstatus: false,
    viewid: "",
    linkStatus: false,
    id: null,
    viewdata: null,
  });

  const [deleteModal, setdeleteModal] = useState({
    modal: false,
    id: "",
  });
  const [activeInactiveModal, setActiveInactiveModal] =
    useState<activeInactiveModalProps>({
      activeStatus: false,
      InActiveStatus: false,
      id: "",
      date: "",
      isInactive: false,
    });
  const [SubscribeUnSubscribeModal, setSubscribeUnSubscribeModal] =
    useState<activeInactiveModalProps>({
      activeStatus: false,
      InActiveStatus: false,
      id: "",
      date: "",
      isInactive: false,
    });

  const handleTableSearch = (data: string) => {
    if (data === "Enter") {
      setPage(1);
      handlelistofDevice();
    }
  };
  const handleTableSearchClear = () => {
    setPage(1);
    resetForm();
    handlelistofDevice(undefined, undefined, true);
  };
  const handleActiveAndInactiveServer = (
    id?: string,
    InActive: boolean = false,
    FOrecInActive: boolean = false,
    activeInactive: boolean = false
    // @ts-ignore
  ) => {
    dispatch(openLoader(true));
    let formData = new FormData();
    // if (activeInactiveModal?.activeStatus) {
    //   // formData.append("subscriptionId", values?.subscriptionId?.toString());
    //   formData.append("deviceId", activeInactiveModal?.id?.toString());
    //   formData.append("activeStatus", "1");
    // } else if (SubscribeUnSubscribeModal.activeStatus) {
    //   formData.append("subscriptionId", values?.subscriptionId?.toString());
    //   formData.append("deviceId", SubscribeUnSubscribeModal?.id?.toString());
    //   formData.append("activeStatus", "1");
    // } else if (id) {
    //   formData.append("deviceId", id.toString());
    //   formData.append("activeStatus", "2");
    //   if (FOrecInActive) formData.append("forcedInactive", "1");
    // }
    if (activeInactiveModal?.activeStatus && !InActive) {
      // formData.append("subscriptionId", values?.subscriptionId?.toString());
      formData.append("deviceId", activeInactiveModal?.id?.toString());
      formData.append("activeStatus", "1");
    } else if (SubscribeUnSubscribeModal?.activeStatus && !InActive) {
      !activeInactive &&
        formData.append("subscriptionId", values?.subscriptionId?.toString());
      formData.append("deviceId", SubscribeUnSubscribeModal?.id?.toString());
      formData.append("activeStatus", "1");
    } else if (id && activeInactiveModal?.activeStatus) {
      formData.append("deviceId", id.toString());
      // formData.append("subscriptionId", "1");
      formData.append("activeStatus", "1");
      if (FOrecInActive) formData.append("forcedInactive", "1");
    } else if (id && SubscribeUnSubscribeModal.InActiveStatus) {
      formData.append("deviceId", id.toString());
      !activeInactive && formData.append("subscriptionId", "1");
      formData.append("activeStatus", "2");
      if (FOrecInActive) formData.append("forcedInactive", "1");
    } else if (id && activeInactiveModal.InActiveStatus) {
      formData.append("deviceId", id.toString());
      // formData.append("subscriptionId", "1");
      formData.append("activeStatus", "2");
      if (FOrecInActive) formData.append("forcedInactive", "1");
    } else if (id) {
      formData.append("deviceId", id.toString());
      !activeInactive && formData.append("subscriptionId", "1");
      formData.append("activeStatus", "2");
      if (FOrecInActive) formData.append("forcedInactive", "1");
    }

    activeInactiveDevice(formData)
      .then((response) => {
        if (response?.data?.status === 1) {
          toast.success(response?.data?.msg);
          handlelistofDevice(page);
          setActiveInactiveModal((prev) => {
            return {
              ...prev,
              activeStatus: false,
              InActiveStatus: false,
              isInactive: false,
            };
          });
          setSubscribeUnSubscribeModal((prev) => {
            return {
              ...prev,
              activeStatus: false,
              InActiveStatus: false,
            };
          });
        } else if (response?.data?.status === 0) {
          handlelistofDevice();
          toast.error(response?.data?.msg);
          setActiveInactiveModal((prev) => {
            return {
              ...prev,
              activeStatus: false,
              isInactive: false,
            };
          });
        } else if (response?.data?.status === 2) {
          setActiveInactiveModal((prev) => {
            return {
              ...prev,
              InActiveStatus: true,
              date: response?.data?.date,
              isInactive: false,
            };
          });
        }
      })
      .catch((err) => getCatchMsg(err))
      .finally(() => {
        dispatch(openLoader(false));
        resetForm();
      });
  };
  const handleActiveStatus = (data: DeviceListItemProps[]) => {
    if (data && data?.length > 0) {
      const statusData = data?.map((ele, index: number) => {
        setSwitches((pre) => {
          let temp = [...pre];
          temp[index] = ele?.activeStatus === 1 ? true : false;
          return temp;
        });
        return ele;
      });
    }
  };
  const handleSubscripeStatus = (data: DeviceListItemProps[]) => {
    if (data && data?.length > 0) {
      const statusData = data?.map((ele, index: number) => {
        setSubscibebtn((pre) => {
          let temp = [...pre];
          // @ts-ignore
          temp[index] = ele?.SubscribeStatus === 1 ? true : false;
          return temp;
        });
        return ele;
      });
    }
  };
  const handlelistofDevice = (
    page = 1,
    size = 10,
    resetValue?: boolean,
    batch?: string
  ) => {
    setnoDataLoader(true);
    let formData = new FormData();
    if (orgid) {
      formData.append("organizationId", orgid.toString());
    }
    formData.append("macAddress", resetValue ? "" : values.mac_address);
    formData.append("imeiNumber", resetValue ? "" : values.imei_number);
    formData.append("device_code", resetValue ? "" : values.devicecode);
    formData.append(
      "organization_name",
      resetValue ? "" : values.Organizationname
    );
    formData.append("batch_version", resetValue ? "" : values.Batchversion);
    if (state?.devicedate) {
      formData.append("assignedFromDate", state?.devicedate);
      formData.append("assignedToDate", state?.devicedate);
    }
    if (DeviceState?.isStock) {
      formData?.append("isStock", DeviceState?.isStock);
    }
    if (state?.stacked) {
      formData.append("projectId", state?.stacked?.projectId);
      formData.append("yearData", state?.stacked?.year);
      formData.append("monthData", state?.stacked?.month);
    }
    formData.append("batchId", batch ? batch : "");
    listDeviceMachine(page, size, formData)
      .then((response) => {
        setPage(response?.data?.data?.page);
        if (response.data.status === 1) {
          setDeviceList(response.data);
          handleActiveStatus(response?.data?.data?.items);
          handleSubscripeStatus(response?.data?.data?.items);
        }
      })
      .catch((error) => {
        getCatchMsg(error);
      })
      .finally(() => {
        setnoDataLoader(false);
      });
  };
  const handleGetSubcriptionList = () => {
    getSubcriptiondropdown()
      .then((response) => {
        if (response?.data?.status === 1) {
          setSubcriptionList(response?.data?.data);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      });
  };
  const handleDeviceDelete = () => {
    dispatch(openLoader(true));
    let formData = new FormData();
    formData.append("device_id", deleteModal.id);
    deleteDevice(formData)
      .then((response) => {
        if (response?.data?.status === 1) {
          handlelistofDevice(deviceList?.data?.page);
          toast.success(response?.data?.msg);
        } else if (response?.data?.status === 0) {
          toast.error(response?.data?.msg);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        dispatch(openLoader(false));
        setdeleteModal((prev) => {
          return {
            ...prev,
            modal: false,
          };
        });
      });
  };
  const handleRemoveOrganization = (device: any) => {
    let formData = new FormData();
    formData.append("device_code", device.device_code);
    formData.append("organization_id", device.organization_id);
    removeOrganzation(formData)
      .then((res) => {
        if (res.data.status === 1) {
          toast.success(res.data.msg);
          handlelistofDevice(page);
          setremovedevice((prev) => {
            return {
              ...prev,
              show: false,
              data: {},
            };
          });
        } else {
          toast.error(res.data.msg);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      });
  };
  const getBatchList = () => {
    getDevicebatchdropdown()
      .then((res) => {
        if (res?.data?.status) {
          setBatchList(res?.data?.data);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      });
  };

  useEffect(() => {
    if (token) {
      handlelistofDevice();
    }
  }, [token, DeviceState]);

  useEffect(() => {
    if (token) {
      handleGetSubcriptionList();
      getBatchList();
    }
  }, [token]);

  useEffect(() => {
    if (addModalStatus && head === device) {
      setIsShowModal(addModalStatus);
    }
  }, [addModalStatus]);

  const handleChangeMqtt = () => {
    let formData = new FormData();
    if (isMQTT?.id) {
      formData.append("device_id", isMQTT?.id?.toString());
    }
    if (!isMQTT?.isMqtt) {
      formData.append("is_mqtt", "1");
    }
    deviceProtocol(formData)
      .then((response) => {
        if (response?.data?.status === 1) {
          toast.success(response?.data?.msg);
          handlelistofDevice(page, pageSize);
        } else {
          toast.error(response?.data?.msg);
        }
      })
      .catch((err) => getCatchMsg(err))
      .finally(() =>
        setisMQTT((prev) => {
          return {
            ...prev,
            id: null,
            isMqtt: null,
            status: false,
          };
        })
      );
  };
  return (
    <>
      <GlobalModal
        size="lg"
        modalStyle="logoutmodalminWidth"
        isVisible={isMQTT?.status}
        setIsVisible={() => {
          setisMQTT((prev) => {
            return {
              ...prev,
              status: false,
            };
          });
        }}
      >
        <ConfirmationModal
          msg={`Are you sure you want to change this device protocol to ${
            isMQTT?.isMqtt ? "HTTP" : "MQTT"
          }.
          `}
          onClose={() => {
            setisMQTT((prev) => {
              return {
                ...prev,
                status: false,
              };
            });
          }}
          positiveButtonText="Yes"
          onPositiveButtonPressed={handleChangeMqtt}
          onNegativeButtonPressed={() => {
            setisMQTT((prev) => {
              return {
                ...prev,
                status: false,
              };
            });
          }}
        />
      </GlobalModal>

      {isShowModal?.status && (
        <GlobalModal
          size="lg"
          modalStyle="globalModalmaxWidth logoutmodalminWidth"
          isVisible={isShowModal.status}
          setIsVisible={() => {
            setIsShowModal((prev: any) => {
              return {
                ...prev,
                status: true,
              };
            });
          }}
        >
          <AddDeviceModal
            getview={isShowModal.viewstatus}
            getviewid={isShowModal.viewid}
            onClose={() => {
              setIsShowModal((prev: any) => {
                return {
                  ...prev,
                  status: false,
                };
              });
            }}
            editData={isShowModal.data || null}
            listDeviceApicall={(value) => {
              if (value === "update") {
                handlelistofDevice(page);
                return;
              }
              handlelistofDevice();
            }}
            modalClose={() => {
              setIsShowModal((prev: any) => {
                return {
                  ...prev,
                  status: false,
                };
              });
            }}
          />
        </GlobalModal>
      )}
      {isShowModal?.linkStatus && (
        <GlobalModal
          size="lg"
          modalStyle="globalmodalminWidth"
          isVisible={isShowModal.linkStatus}
          setIsVisible={() => {
            setIsShowModal((prev: any) => {
              return {
                ...prev,
                linkStatus: true,
              };
            });
          }}
        >
          <AddDeviceModalLink
            getviewid={isShowModal.viewid}
            onClose={() => {
              setIsShowModal((prev: any) => {
                return {
                  ...prev,
                  linkStatus: false,
                };
              });
            }}
            listDeviceApicall={(value) => {
              if (value === "update") {
                handlelistofDevice(page);
                return;
              }
              handlelistofDevice();
            }}
            modalClose={() => {
              setIsShowModal((prev: any) => {
                return {
                  ...prev,
                  linkStatus: false,
                };
              });
            }}
            deviceData={isShowModal?.data}
          />
        </GlobalModal>
      )}
      <GlobalModal
        isVisible={deleteModal.modal}
        setIsVisible={() => {
          setdeleteModal((prev) => {
            return {
              ...prev,
              modal: false,
            };
          });
        }}
      >
        <ConfirmationModal
          msg={`Are you sure that you want to permanently delete this 
            device.
          `}
          onClose={() => {
            setdeleteModal((prev) => {
              return {
                ...prev,
                modal: false,
              };
            });
          }}
          onPositiveButtonPressed={handleDeviceDelete}
          onNegativeButtonPressed={() => {
            setdeleteModal((prev) => {
              return {
                ...prev,
                modal: false,
              };
            });
          }}
        />
      </GlobalModal>
      {removeDevice.show && (
        <GlobalModal
          size={"lg"}
          isVisible={removeDevice.show}
          setIsVisible={() => {
            setremovedevice((prev) => {
              return {
                ...prev,
                show: false,
                data: {},
              };
            });
          }}
        >
          <ConfirmationModal
            msg="Are you certain you wish to remove this device?"
            onClose={() => {
              setremovedevice((prev) => {
                return {
                  ...prev,
                  show: false,
                  data: {},
                };
              });
            }}
            positiveButtonText="Remove"
            onPositiveButtonPressed={() =>
              handleRemoveOrganization(removeDevice.data)
            }
            onNegativeButtonPressed={() => {
              setremovedevice((prev) => {
                return {
                  ...prev,
                  show: false,
                  data: {},
                };
              });
            }}
          />
        </GlobalModal>
      )}
      {(activeInactiveModal?.activeStatus ||
        activeInactiveModal?.isInactive) && (
        <GlobalModal
          size="lg"
          modalStyle="logoutmodalminWidth"
          isVisible={
            activeInactiveModal?.activeStatus || activeInactiveModal?.isInactive
          }
          setIsVisible={() => {
            setActiveInactiveModal((prev) => {
              return {
                ...prev,
                activeStatus: true,
              };
            });
          }}
        >
          <PlanConfirmationModal
            onClose={() => {
              handlelistofDevice(page);
              setActiveInactiveModal((prev) => {
                return {
                  ...prev,
                  activeStatus: false,
                  isInactive: false,
                };
              });
            }}
            // deviceInactive={true}
            positiveButtonText={
              activeInactiveModal.isInactive ? "Inactivate" : "Activate"
            }
            msg={
              activeInactiveModal?.activeStatus
                ? "Do you want active"
                : "Do you want Inactive"
            }
            // msg2={moment(SubscribeUnSubscribeModal?.date).format(
            //   "YYYY-MM-DD hh:mm:ss."
            // )}
            // msg3="Do you want Inactive?."
            onPositiveButtonPressed={() => {
              if (activeInactiveModal?.activeStatus) {
                handleActiveAndInactiveServer(
                  activeInactiveModal?.id,
                  true,
                  true
                  // true
                );
              } else {
                handleActiveAndInactiveServer(
                  activeInactiveModal?.id,
                  true,
                  false,
                  true
                );
              }
            }}
            onNegativeButtonPressed={() => {
              handlelistofDevice();
              setActiveInactiveModal((prev) => {
                return {
                  ...prev,
                  activeStatus: false,
                  isInactive: false,
                };
              });
            }}
          />
        </GlobalModal>
      )}
      {activeInactiveModal?.InActiveStatus &&
        SubscribeUnSubscribeModal.InActiveStatus === false && (
          <GlobalModal
            size="lg"
            modalStyle="logoutmodalminWidth"
            isVisible={activeInactiveModal?.InActiveStatus}
            setIsVisible={() => {
              setActiveInactiveModal((prev) => {
                return {
                  ...prev,
                  InActiveStatus: true,
                };
              });
              setSubscribeUnSubscribeModal((prev) => {
                return {
                  ...prev,
                  InActiveStatus: false,
                };
              });
            }}
          >
            <ConfirmationModal
              onClose={() => {
                handlelistofDevice();
                setActiveInactiveModal((prev) => {
                  return {
                    ...prev,
                    InActiveStatus: false,
                  };
                });
                setSubscribeUnSubscribeModal((prev) => {
                  return {
                    ...prev,
                    InActiveStatus: false,
                  };
                });
              }}
              deviceInactive={true}
              msg="Valididty available till"
              msg2={moment(activeInactiveModal?.date).format(
                "YYYY-MM-DD hh:mm:ss."
              )}
              msg3="Do you want Inactive?."
              onPositiveButtonPressed={() => {
                handleActiveAndInactiveServer(
                  activeInactiveModal?.id,
                  true,
                  true
                );
              }}
              onNegativeButtonPressed={() => {
                setActiveInactiveModal((prev) => {
                  return {
                    ...prev,
                    InActiveStatus: false,
                  };
                });
                handlelistofDevice();
              }}
            />
          </GlobalModal>
        )}
      {SubscribeUnSubscribeModal?.activeStatus &&
        activeInactiveModal.InActiveStatus === false && (
          <GlobalModal
            size="lg"
            modalStyle="logoutmodalminWidth"
            isVisible={SubscribeUnSubscribeModal?.activeStatus}
            setIsVisible={() => {
              setSubscribeUnSubscribeModal((prev) => {
                return {
                  ...prev,
                  activeStatus: true,
                };
              });
              setActiveInactiveModal((prev) => {
                return {
                  ...prev,
                  activeStatus: false,
                };
              });
            }}
          >
            <>
              <ModalHeader
                heading={"Subcription"}
                onClose={() => {
                  handlelistofDevice();
                  setSubscribeUnSubscribeModal((prev) => {
                    return {
                      ...prev,
                      activeStatus: false,
                    };
                  });
                }}
              />
              <div
                className="row"
                style={{
                  width: "90%",
                  margin: "auto",
                }}
              >
                <div>
                  <CustomDropDown
                    editName={values?.subscriptionName}
                    value={values?.subscriptionId}
                    title="Subscription"
                    required={"*"}
                    items={subcriptionList}
                    fieldName={"subscriptionName"}
                    anotherFieldName={"subscriptionId"}
                    onSelectedItem={(val, value) => {
                      setFieldValue("subscriptionName", value.label);
                      setFieldValue("subscriptionId", value.id);
                    }}
                    errorText={
                      touched.subscriptionName && errors.subscriptionName
                        ? errors.subscriptionName
                        : ""
                    }
                  />
                </div>
                <div className="my-4 col-lg-4 col-12">
                  <CustomButton title="Submit" onButtonPress={handleSubmit} />
                </div>
              </div>
            </>
          </GlobalModal>
        )}
      {SubscribeUnSubscribeModal?.InActiveStatus && (
        <GlobalModal
          size="lg"
          modalStyle="logoutmodalminWidth"
          isVisible={SubscribeUnSubscribeModal?.InActiveStatus}
          setIsVisible={() => {
            setSubscribeUnSubscribeModal((prev) => {
              return {
                ...prev,
                InActiveStatus: true,
              };
            });
          }}
        >
          <ConfirmationModal
            onClose={() => {
              handlelistofDevice();
              setSubscribeUnSubscribeModal((prev) => {
                return {
                  ...prev,
                  InActiveStatus: false,
                };
              });
              setActiveInactiveModal((prev) => {
                return {
                  ...prev,
                  InActiveStatus: false,
                };
              });
            }}
            deviceInactive={true}
            msg="Valididty available till"
            // msg2={moment(SubscribeUnSubscribeModal?.date).format(
            //   "YYYY-MM-DD hh:mm:ss."
            // )}
            msg2={moment(activeInactiveModal?.date).format(
              "YYYY-MM-DD hh:mm:ss."
            )}
            msg3="Do you want Inactive?."
            onPositiveButtonPressed={() => {
              handleActiveAndInactiveServer(
                SubscribeUnSubscribeModal?.id,
                true,
                true
                // true
              );
            }}
            onNegativeButtonPressed={() => {
              setSubscribeUnSubscribeModal((prev) => {
                return {
                  ...prev,
                  InActiveStatus: false,
                };
              });
              setActiveInactiveModal((prev) => {
                return {
                  ...prev,
                  InActiveStatus: false,
                };
              });
              handlelistofDevice();
            }}
          />
        </GlobalModal>
      )}
      {/* {FilterOption ? ( */}
      <AccordionContent filterStat={FilterOption}>
        <div className={classes.serachContainer}>
          <div className="row mb-3">
            <Grid item lg={2} md={4} sm={6} xs={12}>
              <TextInputBox
                title="Device Code"
                textLength={50}
                customInputProps={{
                  onKeyDown: (e) => handleTableSearch(e.key),
                }}
                name="devicecode"
                placeHolder="Search device code"
                value={values.devicecode}
                onChangeText={handleChange("devicecode")}
              />
            </Grid>
            <Grid item lg={2} md={4} sm={6} xs={12}>
              <TextInputBox
                title="Organizanition Name"
                textLength={50}
                customInputProps={{
                  onKeyDown: (e) => handleTableSearch(e.key),
                }}
                name="Organizationname"
                placeHolder="Search organisation name"
                value={values.Organizationname}
                onChangeText={handleChange("Organizationname")}
              />
            </Grid>
            <Grid item lg={2} md={4} sm={6} xs={12}>
              {/* <TextInputBox
                title="Batch"
                textLength={50}
                customInputProps={{
                  onKeyDown: (e) => handleTableSearch(e.key),
                }}
                name="Batchversion"
                value={values.Batchversion}
                placeHolder="Search batch"
                onChangeText={handleChange("Batchversion")}
              /> */}
              <CustomDropDown
                title="Batch"
                editName={values.batchname ? values.batchname : "--None--"}
                items={batchList}
                onSelectedItem={(val, value) => {
                  handlelistofDevice(page, pageSize, false, value?.id);
                  setFieldValue("batchname", value?.label ? value.label : "");
                  setFieldValue("batch_id", value?.id ? value.id : "");
                }}
                fieldName="batchName"
                anotherFieldName="batchId"
                value={values.batch_id}
                disableClearable={values.batch_id ? false : true}
              />
            </Grid>
            <Grid item lg={2} md={4} sm={6} xs={12}>
              <TextInputBox
                title="Mac Address"
                textLength={50}
                customInputProps={{
                  onKeyDown: (e) => handleTableSearch(e.key),
                }}
                name="mac_address"
                value={values.mac_address}
                placeHolder="Search mac address"
                onChangeText={handleChange("mac_address")}
              />
            </Grid>
            <Grid item lg={2} md={4} sm={6} xs={12}>
              <TextInputBox
                title="IMEI Number"
                textLength={50}
                customInputProps={{
                  onKeyDown: (e) => handleTableSearch(e.key),
                }}
                name="imei_number"
                value={values.imei_number}
                placeHolder="Search IMEI number"
                onChangeText={handleChange("imei_number")}
              />
            </Grid>
          </div>
          <SearchAndClearbutton
            handleTableSearch={handleTableSearch}
            handleTableSearchClear={handleTableSearchClear}
            disabled={!isFormDirty(initialValues, values)}
          />
        </div>
      </AccordionContent>
      {/* ) : null} */}
      <div className={`table-responsive ${classes.AllTable}`}>
        <Box>
          <TableContainer>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>S.No</TableCell>
                  <TableCell>Device Code</TableCell>
                  {from === "device" && <TableCell>Version</TableCell>}
                  {from === "device" && <TableCell>Model Name</TableCell>}
                  <TableCell>Organizanition Name </TableCell>
                  <TableCell>Batch</TableCell>
                  <TableCell>Mac Address</TableCell>
                  <TableCell>IMEI Number</TableCell>
                  {/* <TableCell>Subscription</TableCell> */}
                  <TableCell>Status</TableCell>
                  <TableCell>Protocol</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {deviceList?.data?.items?.length === 0 ? (
                  <>
                    {noDataLoader ? (
                      <TableRow>
                        <TableCell
                          className={classes.noData}
                          colSpan={from === "device" ? 10 : 8}
                        >
                          <NodataLoader />
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableRow>
                        <TableCell colSpan={from === "device" ? 11 : 9}>
                          <div className={classes.nodatafound}>
                            <img
                              src={Inbox}
                              alt="inbox"
                              width={100}
                              height={100}
                            />
                            <p>No data found</p>
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                ) : (
                  <>
                    {noDataLoader ? (
                      <TableRowsLoader
                        columnNum={from === "device" ? 10 : 8}
                        rowsNum={10}
                      />
                    ) : deviceList?.data?.items?.length === 0 || !deviceList ? (
                      <TableRow>
                        <TableCell colSpan={from === "device" ? 10 : 8}>
                          <div className={classes.nodatafound}>
                            <img
                              src={Inbox}
                              alt="inbox"
                              width={100}
                              height={100}
                            />
                            <p>No data found</p>
                          </div>
                        </TableCell>
                      </TableRow>
                    ) : (
                      deviceList?.data?.items?.map(
                        (device: DeviceListItemProps, index: number) => (
                          <TableRow key={index}>
                            <TableCell>
                              {(deviceList?.data?.page - 1) *
                                deviceList?.data?.size +
                                index +
                                1}
                            </TableCell>
                            <TableCell>
                              {device?.device_code === null
                                ? "-"
                                : device?.device_code}
                            </TableCell>
                            {from === "device" && (
                              <TableCell>
                                {device?.version === null
                                  ? "-"
                                  : device?.version}
                              </TableCell>
                            )}
                            {from === "device" && (
                              <TableCell>
                                {device?.model_name === null
                                  ? "-"
                                  : device?.model_name}
                              </TableCell>
                            )}
                            <TableCell>
                              {device?.organization_name === null
                                ? "-"
                                : device?.organization_name}
                            </TableCell>
                            <TableCell>
                              {device?.batch_version === null
                                ? "-"
                                : device?.batch_version}
                            </TableCell>
                            <TableCell>
                              {device?.mac_address === null
                                ? "-"
                                : device?.mac_address}
                            </TableCell>
                            <TableCell>
                              {device?.imei_number === null
                                ? "-"
                                : device?.imei_number}
                            </TableCell>
                            <TableCell>
                              <Grid className={style.switchContainer}>
                                <CustomSwitch
                                  title1="Active"
                                  title2="Inactive"
                                  status={Switches[index]}
                                  setCheck={(state) => {
                                    setSwitches((pre) => {
                                      let temp = [...pre];
                                      temp[index] = state;
                                      return temp;
                                    });
                                    //@ts-ignore
                                    setActiveInactiveModal((prev) => {
                                      if (state) {
                                        return {
                                          ...prev,
                                          activeStatus: true,
                                          id: device?.device_id.toString(),
                                        };
                                      }
                                      return {
                                        ...prev,
                                        activeStatus: false,
                                        id: device?.device_id.toString(),
                                        isInactive: true,
                                        // InActiveStatus: true,
                                      };
                                    });
                                  }}
                                />
                              </Grid>
                            </TableCell>
                            <TableCell
                              style={{
                                width: "100px",
                              }}
                              onClick={() => {
                                setisMQTT((prev) => {
                                  return {
                                    ...prev,
                                    id: device?.device_id,
                                    status: true,
                                    isMqtt: device?.is_mqtt,
                                  };
                                });
                              }}
                            >
                              <div
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                <p
                                  style={{
                                    padding: "5px 10px",
                                    borderRadius: "6px",
                                    backgroundColor: device?.is_mqtt
                                      ? "powderblue"
                                      : "rgb(255 217 104)",
                                    cursor: "pointer",
                                  }}
                                >
                                  {device?.is_mqtt ? "MQTT" : "HTTP"}
                                </p>
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className={classes.icons}>
                                <Tooltip title="View" placement="top">
                                  <img
                                    src={view}
                                    alt="View"
                                    onClick={() =>
                                      setIsShowModal((prev: any) => {
                                        return {
                                          ...prev,
                                          status: true,
                                          data: null,
                                          viewstatus: true,
                                          viewid: device?.device_id,
                                          linkStatus: false,
                                          id: null,
                                          viewdata: null,
                                        };
                                      })
                                    }
                                  />
                                </Tooltip>
                                <Tooltip title="Edit" placement="top">
                                  <img
                                    src={edit}
                                    onClick={() => {
                                      setIsShowModal({
                                        status: true,
                                        data: device,
                                        viewstatus: false,
                                        viewid: "",
                                        linkStatus: false,
                                        id: null,
                                        viewdata: null,
                                      });
                                    }}
                                    alt="Edit"
                                  />
                                </Tooltip>
                                <Tooltip title="Delete" placement="top">
                                  <img
                                    src={delete_icon}
                                    alt="Delete"
                                    onClick={() =>
                                      setdeleteModal((prev) => {
                                        return {
                                          ...prev,
                                          modal: true,
                                          id: device?.device_id?.toString(),
                                        };
                                      })
                                    }
                                  />
                                </Tooltip>
                                <Tooltip title="Link" placement="top">
                                  <img
                                    className={classes.link_icon}
                                    src={link_icon}
                                    alt="Link"
                                    onClick={() =>
                                      setIsShowModal((prev: any) => {
                                        return {
                                          ...prev,
                                          status: false,
                                          data: device,
                                          viewstatus: false,
                                          viewid: device?.device_id,
                                          linkStatus: true,
                                          id: null,
                                          viewdata: null,
                                        };
                                      })
                                    }
                                  />
                                </Tooltip>
                                {device?.organization_name ? (
                                  <Tooltip title="Remove" placement="top">
                                    <img
                                      className={classes.removedevice_icon}
                                      src={removedev}
                                      alt="Remove"
                                      onClick={
                                        () => {
                                          setremovedevice((pre) => ({
                                            ...pre,
                                            show: true,
                                            data: device,
                                          }));
                                          // handleRemoveOrganization(device);
                                        }
                                        // setIsShowModal((prev: any) => {
                                        //   return {
                                        //     ...prev,
                                        //     status: false,
                                        //     data: device,
                                        //     viewstatus: false,
                                        //     viewid: device?.device_id,
                                        //     linkStatus: true,
                                        //     id: null,
                                        //     viewdata: null,
                                        //   };
                                        // })
                                      }
                                    />
                                  </Tooltip>
                                ) : null}
                              </div>
                            </TableCell>
                          </TableRow>
                        )
                      )
                    )}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </div>
      {deviceList?.data?.items?.length ? (
        <CustomPaginationwithdropdownpagination
          onChangePage={(page: number) => {
            handlelistofDevice(page, pageSize);
            setPage(page);
          }}
          onChangePagerow={(PageSize: number) => {
            if (page * PageSize > deviceList?.data?.totalCount) {
              handlelistofDevice(1, PageSize);
              setPageSize(PageSize);
              return;
            }
            handlelistofDevice(page, PageSize);
            setPageSize(PageSize);
          }}
          count={deviceList?.data?.totalCount}
          pages={page}
          pageCount={deviceList?.data?.total_page}
          handleFunction={(selected: number) => {
            setPage(selected);
            handlelistofDevice(selected, pageSize);
          }}
          labelRowsPerPage={"Devices Per Page"}
          rowsperpage={pageSize}
          totalData={deviceList?.data}
        />
      ) : null}
    </>
  );
}

export default DeviceBatch;
