import React, { useEffect, useState } from "react";
import PageHeader from "../../MangementLayoutHeader/PageHeader";
import {
  CustomPaginationwithdropdownpagination,
  GlobalModal,
  Loader,
  NodataLoader,
  TableRowsLoader,
  TextInputBox,
} from "../../../Components";
import classes from "./Management.module.css";
import Tooltip from "@mui/material/Tooltip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Autocomplete, Box, Paper, TextField } from "@mui/material";
import { useFormik } from "formik";
import view from "../../../Assets/Icons/Png/view.png";
import Inbox from "../../../Assets/Images/Png/noData.png";
import download from "../../../Assets/Icons/Svg/download1.svg";
import { firmWareListProps } from "./managementTypes";
import {
  deleteFirmWareManageMent,
  getDeviceModelDropDown,
  listFirmWare,
} from "../../../Services/Services";
import { useToken } from "../../../Utility/StoreData";
import {
  downloadUrl,
  getCatchMsg,
  isFormDirty,
} from "../../../Utility/GeneralUtils";
import toast from "react-hot-toast";
import { AddFirmWare, ConfirmationModal } from "../../../Modals";
import SearchAndClearbutton from "../../../Components/SearchAndClearbutton";
import { Grid } from "@material-ui/core";
import { DeviceModelProps } from "../../../Modals/Types";
import { openLoader } from "../../../Store/Reducers/LoaderSlice";
import { useDispatch } from "react-redux";
import AccordionContent from "../../../Components/AccordionContent";
import { muiStyles } from "../../../Utility/Constants";
import { useFirmwareContext } from "../../../Utility/Context";

type defaultValueProps = {
  firmWareName: string;
  firmwareType: string;
  firmwareTypeName: string;
  model: DeviceModelProps | null;
  version: string;
};
function FirmwareManagement() {
  const token = useToken();
  const dispatch = useDispatch();
  const muistyle = muiStyles();
  const { filterStatus, isShowModal, setIsShowModal } = useFirmwareContext();
  const defaultValues: defaultValueProps = {
    firmWareName: "",
    firmwareType: "",
    firmwareTypeName: "",
    model: null,
    version: "",
  };
  const [deleteModal, setdeleteModal] = useState({
    modal: false,
    id: "",
  });
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [noDataLoader, setnoDataLoader] = useState(false);
  const [firmWareList, setFirmWareList] = useState<firmWareListProps | null>(
    null
  );
  const [modelDropdownList, setmodelDropdownList] = useState<
    DeviceModelProps[]
  >([]);
  const { values, handleChange, resetForm, setFieldValue, initialValues } =
    useFormik({
      initialValues: defaultValues,
      onSubmit: () => {},
    });

  const handleTableSearch = (data: string) => {
    if (data === "Enter") {
      setPage(1);
      handleGetFirmWareTableList();
    }
  };

  const handleTableSearchClear = () => {
    setPage(1);
    resetForm();
    handleGetFirmWareTableList(undefined, undefined, true);
  };

  const handleGetFirmWareTableList = (
    page = 1,
    size = 10,
    resetValue?: boolean,
    firmwareType?: number
  ) => {
    setnoDataLoader(true);
    let formData = new FormData();
    formData.append("firmWareName", resetValue ? "" : values?.firmWareName);
    if (firmwareType) formData.append("firmwareType", firmwareType.toString());
    if (values?.model) {
      formData.append("modelId", values?.model?.deviceModelId?.toString());
    }
    formData.append("version", resetValue ? "" : values?.version);
    listFirmWare(page, size, formData)
      .then((response) => {
        if (response?.data?.status === 1) {
          setPage(response?.data?.data?.page);
          setFirmWareList(response?.data);
          setTotalCount(response?.data?.data?.totalCount);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        setnoDataLoader(false);
      });
  };

  const handleDeleteCategory = () => {
    dispatch(openLoader(true));
    let formData = new FormData();
    formData.append("firmwareId", deleteModal.id);
    deleteFirmWareManageMent(formData)
      .then((res) => {
        if (res?.data?.status === 1) {
          if (totalCount % 10 === 1) {
            handleGetFirmWareTableList(page - 1);
          } else {
            handleGetFirmWareTableList(page);
          }
          toast.success(res?.data?.msg);
        } else if (res?.data?.status === 0) {
          toast.error(res?.data?.msg);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        dispatch(openLoader(false));
        setdeleteModal((prev) => {
          return {
            ...prev,
            modal: false,
          };
        });
      });
  };

  const getModelList = () => {
    getDeviceModelDropDown()
      .then((res) => {
        if (res?.data?.status === 1) {
          setmodelDropdownList(res?.data?.data || []);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      });
  };

  useEffect(() => {
    if (token) {
      handleGetFirmWareTableList();
    }
  }, [token, values.model]);

  useEffect(() => {
    getModelList();
  }, [token]);

  return (
    <>
      {isShowModal?.status && (
        <GlobalModal
          size="sm"
          customStyle={{
            maxHeight: "500px",
          }}
          modalStyle={isShowModal?.data ? "" : "globalModallg"}
          isVisible={isShowModal.status}
          setIsVisible={() => {
            setIsShowModal((prev) => {
              return {
                ...prev,
                status: true,
              };
            });
          }}
        >
          <AddFirmWare
            onClose={() => {
              setIsShowModal((prev) => {
                return {
                  ...prev,
                  status: false,
                };
              });
            }}
            modelDropdownList={modelDropdownList}
            viewId={isShowModal?.id}
            editData={isShowModal?.data}
            listapiCall={(value) => {
              if (value === "update") {
                handleGetFirmWareTableList(page);
                return;
              }
              handleGetFirmWareTableList();
            }}
            modalClose={() => {
              setIsShowModal((prev) => {
                return {
                  ...prev,
                  status: false,
                };
              });
            }}
          />
        </GlobalModal>
      )}

      {deleteModal?.modal && (
        <GlobalModal
          isVisible={deleteModal.modal}
          setIsVisible={() => {
            setdeleteModal((prev) => {
              return {
                ...prev,
                modal: false,
              };
            });
          }}
        >
          <ConfirmationModal
            msg={`Are you sure that you want to permanently delete this firmware.
            `}
            onClose={() => {
              setdeleteModal((prev) => {
                return {
                  ...prev,
                  modal: false,
                };
              });
            }}
            onPositiveButtonPressed={handleDeleteCategory}
            onNegativeButtonPressed={() => {
              setdeleteModal((prev) => {
                return {
                  ...prev,
                  modal: false,
                };
              });
            }}
          />
        </GlobalModal>
      )}

      <AccordionContent filterStat={filterStatus?.master}>
        <div className={classes.serachContainer}>
          <div className="row mb-3">
            <Grid item lg={2} md={4} sm={6} xs={12}>
              <TextInputBox
                title="Firmware Name"
                textLength={50}
                customInputProps={{
                  onKeyDown: (e) => handleTableSearch(e.key),
                }}
                name="firmWareName"
                placeHolder="Search firmware name"
                value={values.firmWareName}
                onChangeText={handleChange("firmWareName")}
              />
            </Grid>

            <Grid item lg={2} md={4} sm={6} xs={12}>
              <TextInputBox
                onChangeText={() => {}}
                title="Select Model"
                textOnly
              />
              <Autocomplete
                className="dropbr8"
                size="small"
                id="multiple-limit-tags"
                onChange={(event, value) => {
                  setFieldValue("model", value);
                }}
                // @ts-ignore
                value={values?.model}
                options={modelDropdownList || []}
                getOptionLabel={(option: DeviceModelProps) =>
                  option.deviceModelName
                }
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select Model" />
                )}
                PaperComponent={(props) => {
                  return <Paper {...props} className={muistyle.customSelect} />;
                }}
              />
            </Grid>
            <Grid item lg={2} md={4} sm={6} xs={12}>
              <TextInputBox
                title="Version"
                textLength={50}
                customInputProps={{
                  onKeyDown: (e) => handleTableSearch(e.key),
                }}
                name="version"
                placeHolder="Search version"
                value={values.version}
                onChangeText={handleChange("version")}
              />
            </Grid>
          </div>
          <SearchAndClearbutton
            handleTableSearch={handleTableSearch}
            handleTableSearchClear={handleTableSearchClear}
            disabled={!isFormDirty(initialValues, values)}
          />
        </div>
      </AccordionContent>
      <div className={`table-responsive ${classes.AllTable}`}>
        <Box>
          <TableContainer>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>S.No</TableCell>
                  <TableCell>Firmware Name</TableCell>
                  <TableCell>Model Name</TableCell>
                  <TableCell>Version</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {firmWareList?.data?.items?.length === 0 ? (
                  <>
                    {noDataLoader ? (
                      <TableRow>
                        <TableCell className={classes.noData} colSpan={5}>
                          <NodataLoader />
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableRow>
                        <TableCell colSpan={5}>
                          <div className={classes.nodatafound}>
                            <img
                              src={Inbox}
                              alt="inbox"
                              width={100}
                              height={100}
                            />
                            <p>No data found</p>
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                ) : (
                  <>
                    {noDataLoader ? (
                      <TableRowsLoader columnNum={5} rowsNum={10} />
                    ) : firmWareList?.data?.items?.length === 0 ||
                      !firmWareList ? (
                      <TableRow>
                        <TableCell colSpan={4}>
                          <div className={classes.nodatafound}>
                            <img
                              src={Inbox}
                              alt="inbox"
                              width={100}
                              height={100}
                            />
                            <p>No data found</p>
                          </div>
                        </TableCell>
                      </TableRow>
                    ) : (
                      firmWareList?.data?.items?.map((device, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            {(firmWareList?.data?.page - 1) *
                              firmWareList?.data?.size +
                              index +
                              1}
                          </TableCell>
                          <TableCell>
                            {device.firmwareName ? device.firmwareName : "-"}
                          </TableCell>
                          <TableCell>
                            {!device.deviceModelName
                              ? "-"
                              : device.deviceModelName}
                          </TableCell>
                          <TableCell>
                            {device.version === null ? "-" : device.version}
                          </TableCell>
                          <TableCell>
                            <div className={classes.icons}>
                              <Tooltip title="View" placement="top">
                                <img
                                  src={view}
                                  alt="View"
                                  onClick={() =>
                                    setIsShowModal((prev) => {
                                      return {
                                        ...prev,
                                        status: true,
                                        id: device.id,
                                        data: device,
                                      };
                                    })
                                  }
                                />
                              </Tooltip>
                              {device?.firmwarePath && (
                                <Tooltip title="Download" placement="top">
                                  <img
                                    src={download}
                                    onClick={() => {
                                      downloadUrl(device?.firmwarePath);
                                    }}
                                    alt="Download"
                                  />
                                </Tooltip>
                              )}
                            </div>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </div>
      {firmWareList?.data?.items?.length ? (
        <CustomPaginationwithdropdownpagination
          onChangePage={(page: number) => {
            handleGetFirmWareTableList(page, pageSize);
            setPage(page);
          }}
          onChangePagerow={(PageSize: number) => {
            if (page * PageSize > firmWareList?.data?.totalCount) {
              handleGetFirmWareTableList(1, PageSize);
              setPageSize(PageSize);
              return;
            }
            handleGetFirmWareTableList(page, PageSize);
            setPageSize(PageSize);
          }}
          count={firmWareList?.data?.totalCount}
          pages={page}
          pageCount={firmWareList?.data?.total_page}
          handleFunction={(selected: number) => {
            setPage(selected);
            handleGetFirmWareTableList(selected, pageSize);
          }}
          labelRowsPerPage={"Devices Per Page"}
          rowsperpage={pageSize}
          totalData={firmWareList?.data}
        />
      ) : null}
    </>
  );
}

export default FirmwareManagement;
