import { useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { muiStyles } from "../Utility/Constants";
import { CustomDatePickerProps } from "./Types";

export default function CustomDatePicker({
  defaultValue,
  selectedDate,
  onSelectDate,
  showDate = true,
  errorText,
  datePickerProps,
  minDate,
  disabled = false,
  title = "",
  required = "",
  disableFuture,
  disableopenPicker = false,
  readOnly = false,
}: CustomDatePickerProps) {
  const classes = muiStyles();
  const [OpenDate, setOpenDate] = useState<string[]>([]);
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={["DatePicker"]}>
        <div
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <div
            draggable="false"
            className={`${classes.dateStyles} ${classes.datePicker}`}
            style={{
              width: "100%",
              borderRadius: "7px",
              position: "relative",
            }}
          >
            {showDate ? (
              <div
                style={{
                  marginBottom: "5px",
                  fontFamily: "var(--fontRegular)",
                  fontSize: "var(--textSm1)",
                  fontWeight: "var(--fontWm)",
                }}
              >
                {title}{" "}
                <span style={{ color: "var(--dangerColor)" }}>{required}</span>
              </div>
            ) : null}
            <DemoItem>
              <DesktopDatePicker
                id="test"
                {...datePickerProps}
                disabled={disabled}
                className={classes.datepickerRadius}
                open={OpenDate?.includes("from")}
                PopperProps={{
                  style: { overflow: "visible" },
                  // Add any other desired Popper props
                }}
                onOpen={() => {
                  setOpenDate(["from"]);
                }}
                readOnly={readOnly}
                disableFuture={disableFuture}
                disableOpenPicker={disableopenPicker}
                onClose={() => {
                  setOpenDate([]);
                }}
                orientation="portrait"
                slotProps={{
                  popper: {
                    style: {
                      marginTop: "-150px",
                    },
                  },
                  textField: {
                    onDragStart: (event) => {
                      event.preventDefault();
                    },
                    onClick: () => {
                      if (!readOnly) {
                        setOpenDate(["from"]);
                      }
                    },
                    onKeyDown: (event) => {
                      event.preventDefault();
                    },

                    inputProps: {
                      style: {
                        cursor: readOnly ? "not-allowed" : "default",
                      },
                    },
                  },
                }}
                minDate={minDate}
                value={dayjs(selectedDate, "YYYY-MM-DD")}
                defaultValue={defaultValue}
                onChange={(e, val) => {
                  const selectedDate = new Date(e as any);
                  onSelectDate(selectedDate, val);
                }}
                format="YYYY-MM-DD"
                autoFocus
              />
            </DemoItem>
          </div>
          {errorText ? (
            <p
              style={{
                color: "red",
                //   position: "absolute",
                //   top: "50px",
                fontSize: "12px",
              }}
            >
              {errorText}
            </p>
          ) : null}
        </div>
      </DemoContainer>
    </LocalizationProvider>
  );
}
