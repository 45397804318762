import React, { useEffect, useState } from "react";
import PageHeader from "../../MangementLayoutHeader/PageHeader";
import { useLocation, useNavigate } from "react-router-dom";
import { PartNumberListQueryData } from "../../../Services/Services";
import { getCatchMsg, getTrimString } from "../../../Utility/GeneralUtils";
import {
  isShowModalPartNumberQuestionProps,
  partNumberQuerListDataItemsProps,
  partNumberQuerListProps,
} from "./managementTypes";
import Tooltip from "@mui/material/Tooltip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Box } from "@mui/material";
import edit from "../../../Assets/Icons/Svg/edit.svg";
import view from "../../../Assets/Icons/Png/view.png";
import Inbox from "../../../Assets/Images/Png/noData.png";
import {
  CustomPaginationwithdropdownpagination,
  GlobalModal,
  NodataLoader,
  TableRowsLoader,
} from "../../../Components";
import classes from "./Management.module.css";
import { AddPartNumberQuestion } from "../../../Modals";

function PartManagementDetails() {
  const [isShowModal, setIsShowModal] =
    useState<isShowModalPartNumberQuestionProps>({
      status: false,
      data: null,
      viewStatus: false,
    });
  const navigate = useNavigate();
  const state = useLocation();
  const [noDataLoader, setnoDataLoader] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [partNumberQuerList, setPartNumberQuerList] =
    useState<partNumberQuerListProps | null>(null);
  const handleGetPartNumberQueryList = (page = 1, size = 10) => {
    setnoDataLoader(true);
    let formData = new FormData();
    if (state?.state) {
      formData.append("partId", state?.state?.id);
      formData.append("questionMasterId", state?.state?.questionId);
    }
    PartNumberListQueryData(page, size, formData)
      .then((response) => {
        setPage(response?.data?.data?.page);
        if (response?.data?.status === 1) {
          setPartNumberQuerList(response?.data);
        }
      })
      .catch((error) => {
        getCatchMsg(error);
      })
      .finally(() => {
        setnoDataLoader(false);
      });
  };

  useEffect(() => {
    if (state) {
      handleGetPartNumberQueryList();
    }
  }, [state]);

  return (
    <>
      <PageHeader
        serachIconStatus={false}
        modal={() => navigate(-1)}
        Btntitle="Back"
        heading="Part Management "
      />
      {isShowModal?.status && (
        <GlobalModal
          size="lg"
          modalStyle="globalModalmaxWidth"
          isVisible={isShowModal.status}
          setIsVisible={() => {
            setIsShowModal((prev) => {
              return {
                ...prev,
                status: true,
              };
            });
          }}
        >
          <AddPartNumberQuestion
            viewStatus={isShowModal?.viewStatus}
            listApiCall={(value) => {
              if (value === "update") {
                handleGetPartNumberQueryList(page);
                return;
              }
              handleGetPartNumberQueryList();
            }}
            editData={isShowModal?.data || null}
            onClose={() => {
              setIsShowModal((prev) => {
                return {
                  ...prev,
                  status: false,
                };
              });
            }}
            modalClose={() => {
              setIsShowModal((prev) => {
                return {
                  ...prev,
                  status: false,
                };
              });
            }}
          />
        </GlobalModal>
      )}
      <div className={`table-responsive ${classes.AllTable}`}>
        <Box>
          <TableContainer>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>S.No</TableCell>
                  <TableCell
                    style={{
                      maxWidth: "300px",
                    }}
                  >
                    Question
                  </TableCell>
                  <TableCell>Answer Set</TableCell>
                  <TableCell>Choosen Answer</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {partNumberQuerList?.data?.items?.length === 0 ? (
                  <>
                    {noDataLoader ? (
                      <TableRow>
                        <TableCell className={classes.noData} colSpan={9}>
                          <NodataLoader />
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableRow>
                        <TableCell colSpan={9}>
                          <div className={classes.nodatafound}>
                            <img
                              src={Inbox}
                              alt="inbox"
                              width={100}
                              height={100}
                            />
                            <p>No data found</p>
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                ) : (
                  <>
                    {noDataLoader ? (
                      <TableRowsLoader columnNum={6} rowsNum={10} />
                    ) : partNumberQuerList?.data?.items?.length === 0 ||
                      !partNumberQuerList ? (
                      <TableRow>
                        <TableCell colSpan={9}>
                          <div className={classes.nodatafound}>
                            <img
                              src={Inbox}
                              alt="inbox"
                              width={100}
                              height={100}
                            />
                            <p>No data found</p>
                          </div>
                        </TableCell>
                      </TableRow>
                    ) : (
                      partNumberQuerList?.data?.items?.map(
                        (
                          categorization: partNumberQuerListDataItemsProps,
                          index: number
                        ) => (
                          <TableRow key={index}>
                            <TableCell>
                              {(partNumberQuerList?.data?.page - 1) *
                                partNumberQuerList?.data?.size +
                                index +
                                1}
                            </TableCell>
                            <TableCell
                              style={{
                                maxWidth: "250px",
                              }}
                            >
                              {
                                categorization?.questionSetName === null
                                  ? "-"
                                  : categorization?.questionSetName.toString()
                                // getTrimString(
                                //     categorization?.questionSetName.toString(),
                                //     40
                                //   )
                              }
                            </TableCell>
                            <TableCell>
                              {categorization?.categorization_name === null
                                ? "-"
                                : categorization?.categorization_name}
                            </TableCell>
                            <TableCell>
                              {categorization?.categorization_field_name ===
                              null
                                ? "-"
                                : categorization?.categorization_field_name}
                            </TableCell>
                            <TableCell>
                              <div className={classes.icons}>
                                <Tooltip title="View" placement="top">
                                  <img
                                    src={view}
                                    alt="View"
                                    onClick={() => {
                                      setIsShowModal((prev) => {
                                        return {
                                          ...prev,
                                          status: true,
                                          data: categorization,
                                          viewStatus: true,
                                        };
                                      });
                                    }}
                                  />
                                </Tooltip>
                                <Tooltip title="Edit" placement="top">
                                  <img
                                    src={edit}
                                    onClick={() => {
                                      setIsShowModal((prev) => {
                                        return {
                                          ...prev,
                                          status: true,
                                          data: categorization,
                                          viewStatus: false,
                                        };
                                      });
                                    }}
                                    alt="Edit"
                                  />
                                </Tooltip>
                              </div>
                            </TableCell>
                          </TableRow>
                        )
                      )
                    )}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </div>
      {partNumberQuerList?.data?.items?.length ? (
        <CustomPaginationwithdropdownpagination
          onChangePage={(page: number) => {
            handleGetPartNumberQueryList(page, pageSize);
            setPage(page);
          }}
          onChangePagerow={(PageSize: number) => {
            if (page * PageSize > partNumberQuerList?.data?.totalCount) {
              handleGetPartNumberQueryList(1, PageSize);
              setPageSize(PageSize);
              return;
            }
            handleGetPartNumberQueryList(page, PageSize);
            setPageSize(PageSize);
          }}
          count={partNumberQuerList?.data?.totalCount}
          pages={page}
          pageCount={partNumberQuerList?.data?.total_page}
          handleFunction={(selected: number) => {
            setPage(selected);
            handleGetPartNumberQueryList(selected, pageSize);
          }}
          labelRowsPerPage={"Devices Per Page"}
          rowsperpage={pageSize}
          totalData={partNumberQuerList?.data}
        />
      ) : null}
    </>
  );
}

export default PartManagementDetails;
