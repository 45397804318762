import { useEffect, useState } from "react";
import defaultProfileImg from "../../../Assets/Icons/Png/man.png";
import classes from "./AdminProfile.module.css";
import {
  Button,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  Zoom,
  useTheme,
} from "@mui/material";

import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import userIdIcon from "../../../Assets/Icons/Svg/user_id.svg";
import organizationIcon from "../../../Assets/Icons/Svg/organization_code.svg";
import mobileIcon from "../../../Assets/Icons/Svg/mobile.svg";
import alterMobileIcon from "../../../Assets/Icons/Svg/alternative_mobile.svg";
import emailIcon from "../../../Assets/Icons/Svg/email.svg";
import designationIcon from "../../../Assets/Icons/Svg/designation.svg";
import { Info, InfoOutlined, Upload } from "@mui/icons-material";
import { AdminData, useToken } from "../../../Utility/StoreData";
import PageHeader from "../../MangementLayoutHeader/PageHeader";
import { GlobalModal } from "../../../Components";
import EditMyProfile from "../../../Modals/EditMyProfile";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  arrow: {
    color: "white !important",
  },
  tooltipContainer: {
    backgroundColor: "white !important",
    boxShadow: "1px 1px 10px 1px rgba(0,0,0,0.1)",
    borderRadius: "8px !important",
    padding: "0 !important",
    maxWidth: "800px !important",
  },
}));
export default function Myprofile() {
  const token = useToken();
  const dispatch = useDispatch();
  const adminData = AdminData();
  const styles = useStyles();
  const theme = useTheme();
  const [editoption, setEditoption] = useState<boolean>(false);
  const [profileIconData, setProfileIconData] = useState([
    // {
    //   icon: userIdIcon,
    //   value: "",
    //   displayName: "Name",
    // },
    // {
    //   icon: designationIcon,
    //   value: "",
    //   displayName: "Designation",
    // },
    {
      icon: mobileIcon,
      value: "",
      displayName: "Mobile Number",
      isLink: true,
    },
    {
      icon: alterMobileIcon,
      value: "",
      displayName: "Alternative Mobile Number",
      isLink: true,
    },
    {
      icon: emailIcon,
      value: "",
      displayName: "Email ID",
      isLink: true,
    },
    {
      icon: organizationIcon,
      value: "",
      displayName: "Organization Name",
    },
  ]);
  const [subscriptionUpdateConfirmation, setSubscriptionUpdateConfirmation] =
    useState(false);

  useEffect(() => {
    if (adminData) {
      setProfileIconData((pre) => [
        // { ...pre[0], value: adminData?.name || "" },
        // { ...pre[1], value: adminData?.designationName || "" },
        { ...pre[0], value: adminData?.mobileNo || "" },
        { ...pre[1], value: adminData?.alternative_mobile_no || "" },
        { ...pre[2], value: adminData?.email || "" },
        { ...pre[3], value: adminData?.OrganizationName || "" },
      ]);
    }
  }, [adminData]);

  const handleClose = () => {
    setSubscriptionUpdateConfirmation(false);
    setEditoption(false);
  };

  //   const handleUpdateSubscription = () => {
  //     handleClose();
  //     dispatch(openLoader(true));
  //     const formdata = new FormData();
  //     formdata.append("token", token);
  //     UpgradeSubscriptionPlanService(formdata)
  //       .then((res) => {
  //         if (res?.data?.status) {
  //           toast.success(res?.data?.msg);
  //         } else {
  //           toast.error(res?.data?.msg);
  //         }
  //       })
  //       .catch((err) => getCatchMsg(err))
  //       .finally(() => {
  //         dispatch(openLoader(false));
  //       });
  //   };
  return (
    <Grid className="appcontainer py-3">
      <Grid className={classes.container}>
        <div className={classes.head}>
          <div className={classes.line}></div>
          <div className={classes.heading}> My Profile</div>
        </div>
      </Grid>
      <Grid className="py-2">
        <Grid
          className={classes.myProfileContainer}
          display={"flex"}
          flexWrap={"wrap"}
        >
          <Grid className={classes.myProfileImg}>
            <img
              src={adminData?.image}
              alt="Image"
              onError={({ currentTarget }) => {
                currentTarget.src = defaultProfileImg;
              }}
            />
          </Grid>
          <Grid display={"flex"} flexWrap={"wrap"}>
            <Grid
              sx={{
                [theme.breakpoints.only("xs")]: {
                  order: 2,
                  width: "100%",
                },
              }}
              className={classes.myProfileAbout}
            >
              <Grid className={classes.myProfileTextContainer}>
                <Typography>{adminData?.name}</Typography>
                <Typography>{adminData?.designation || "ADMIN"}</Typography>
              </Grid>
              <Typography>
                About
                <IconButton
                  sx={{
                    width: 25,
                    height: 25,
                  }}
                >
                  <InfoOutlined
                    sx={{
                      height: "18px !important",
                    }}
                  />
                </IconButton>
              </Typography>
              <Grid paddingTop={1}>
                {profileIconData?.map((aboutUser, aboutIndex) => {
                  return (
                    <Grid
                      className={classes.myProfileAboutTextContainer}
                      display={"flex"}
                      alignItems={"center"}
                    >
                      <Typography>{`${aboutUser?.displayName}`}</Typography>
                      <span className={classes.textContentDivider}>:</span>
                      <Typography
                        onClick={() => {
                          if (aboutUser?.isLink && aboutUser?.value) {
                            navigator.clipboard.writeText(
                              aboutUser?.value || ""
                            );

                            toast.success(`${aboutUser?.value} copied`);
                          }
                        }}
                        sx={
                          aboutUser?.isLink && aboutUser?.value
                            ? {
                                color: "#f25922",
                                textDecoration: "underline",
                                cursor: "pointer",
                                "&:active": {
                                  fontSize: 14,
                                },
                              }
                            : {
                                color: "rgba(0, 0, 0, 0.5)",
                              }
                        }
                        style={{
                          transition: "0.1s",
                          fontWeight: 600,
                          fontSize: "16px",
                        }}
                      >{`${aboutUser?.value || "-"}`}</Typography>
                    </Grid>
                  );
                })}
              </Grid>
              <Grid
                style={{
                  marginLeft: "-8px",
                  padding: "8px 0px",
                }}
              >
                {profileIconData?.map((iconItem, iconIndex) => {
                  return (
                    <Tooltip
                      placement="top"
                      classes={{
                        arrow: styles.arrow,
                        tooltip: styles.tooltipContainer,
                      }}
                      TransitionComponent={Zoom}
                      arrow
                      title={
                        iconItem?.value ? (
                          <Typography
                            style={{
                              padding: "10px",
                              color: "black",
                            }}
                          >
                            {iconItem?.value}
                          </Typography>
                        ) : (
                          ""
                        )
                      }
                    >
                      <IconButton
                        className={classes.bounceInTop}
                        sx={{
                          width: 30,
                          height: 30,
                          animationDelay: `${iconIndex / 5}s`,
                          // transition: "0.5s",

                          // "&:hover": {
                          //   transform: "translateY(-10px)",
                          // },
                        }}
                      >
                        <img
                          style={{
                            height: 15,
                          }}
                          src={iconItem?.icon}
                        />
                      </IconButton>
                    </Tooltip>
                  );
                })}
              </Grid>
            </Grid>
            <Button
              style={{
                background: "#fdfdfd",
                height: "fit-content",
                textTransform: "capitalize",
              }}
              sx={{
                [theme.breakpoints.only("xs")]: {
                  order: 1,
                },
              }}
              onClick={() => {
                setEditoption(true);
              }}
              className={classes.upgradeBtn}
            >
              Edit Profile
            </Button>
          </Grid>
        </Grid>
      </Grid>

      {editoption && (
        <GlobalModal setIsVisible={handleClose} isVisible={editoption}>
          <EditMyProfile setEditoption={handleClose} />
        </GlobalModal>
      )}
    </Grid>
  );
}
