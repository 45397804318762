import React, { useState } from "react";

import visible from "../Assets/Icons/Svg/visibleicon.svg";
import invisible from "../Assets/Icons/Svg/invisibleicon.svg";
import { TextInputBoxProps } from "./Types";
import classes from "./CustomStyle.module.css";
import delete_icon from "../Assets/Icons/Svg/delete.svg";
import Tooltip from "@mui/material/Tooltip";

export default function TextInputBox({
  title = "",
  placeHolder = "",
  value,
  onChangeText,
  customInputProps,
  inputMode,
  onBlur,
  errorText = "",
  name = "",
  id,
  requiredText = "",
  disabled = false,
  titleStyle,
  isSecure = false,
  infotxt = null,
  inputBoxStyle,
  textLength,
  readOnly,
  keyPress,
  categarization = false,
  textOnly = false,
  onPressCategarizationDelete,
}: TextInputBoxProps) {
  const [secure, setsecure] = useState(false);

  const getSecureText = () => {
    return secure ? "text" : "password";
  };

  return (
    <>
      <div className={classes.container}>
        <p className={classes.title} style={titleStyle}>
          {title} <span className={classes.star}>{requiredText}</span>
        </p>
        {!textOnly && (
          <div
            className={classes.inputBox}
            style={{
              opacity: disabled ? "0.8" : "",

              border: errorText ? "1px solid red" : "var(--inputBorder)",
            }}
          >
            <input
              name={name}
              id={id}
              value={value}
              type={isSecure ? getSecureText() : "text"}
              placeholder={placeHolder}
              {...customInputProps}
              style={{
                width: isSecure || categarization ? "87%" : "100%",
                cursor: disabled ? "not-allowed" : "",
                borderRadius: isSecure ? "8px 0px 0px 8px" : "8px",
                height: "40px",
                ...inputBoxStyle,
              }}
              onChange={(e) => {
                //@ts-ignore
                onChangeText(e?.target?.value);
              }}
              readOnly={readOnly}
              // onKeyPress={onKeyPress}
              inputMode={inputMode}
              onBlur={onBlur}
              disabled={disabled}
              onFocus={(event) => {
                event.target.setAttribute("autocomplete", "off");
              }}
              maxLength={textLength}
              // onKeyPress={keyPress}
              autoComplete="new-password"
            />
            {isSecure ? (
              <div className={classes.passwordIcon}>
                <img
                  src={secure ? visible : invisible}
                  alt="visible"
                  onClick={() => setsecure((prev) => !prev)}
                />
              </div>
            ) : null}
            {categarization ? (
              <Tooltip title="Delete" placement="top">
                <div
                  className={classes.categorizationDeleteIcon}
                  onClick={() => {
                    if (onPressCategarizationDelete) {
                      onPressCategarizationDelete();
                    }
                  }}
                >
                  <img src={delete_icon} alt="delete_icon" />
                </div>
              </Tooltip>
            ) : null}
          </div>
        )}
        {infotxt ? (
          <p className={classes.infotext}>
            <span className="material-symbols-outlined">info</span>

            {infotxt}
          </p>
        ) : null}

        {errorText ? (
          <p className={classes.errorTxt}>{errorText.toString()}</p>
        ) : null}
      </div>
    </>
  );
}
