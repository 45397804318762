import React, { useEffect, useState } from "react";
import PageHeader from "../../MangementLayoutHeader/PageHeader";
import {
  isShowModalSubcriptionProps,
  subcriptionListDataItemsProps,
  subcriptionListProps,
} from "../Management/managementTypes";
import {
  deleteSubcription,
  projectdropdownlist,
  subcriptionsList,
  userSubscriptionDropdownSevice,
} from "../../../Services/Services";
import { useFormik } from "formik";
import { getCatchMsg, isFormDirty } from "../../../Utility/GeneralUtils";
import { useToken } from "../../../Utility/StoreData";
import classes from "../Management/Management.module.css";
import {
  CustomPagination,
  CustomPaginationDropdown,
  CustomPaginationwithdropdownpagination,
  GlobalModal,
  Loader,
  NodataLoader,
  TableRowsLoader,
  TextInputBox,
} from "../../../Components";
import Tooltip from "@mui/material/Tooltip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Autocomplete, Box, Paper, TextField } from "@mui/material";
import delete_icon from "../../../Assets/Icons/Svg/delete.svg";
import closeIcon from "../../../Assets/Icons/Png/texboxClose.png";
import view from "../../../Assets/Icons/Png/view.png";
import Inbox from "../../../Assets/Images/Png/noData.png";
import edit from "../../../Assets/Icons/Svg/edit.svg";
import toast from "react-hot-toast";
import { ConfirmationModal } from "../../../Modals";
import AddSubcription from "../../../Modals/AddSubcription";
import { Grid } from "@material-ui/core";
import SearchAndClearbutton from "../../../Components/SearchAndClearbutton";
import { openLoader } from "../../../Store/Reducers/LoaderSlice";
import { useDispatch } from "react-redux";
import {
  ProjectDrodownProps,
  UserSubscriptionDropdownProps,
} from "../../../Modals/Types";
import AccordionContent from "../../../Components/AccordionContent";
import { muiStyles } from "../../../Utility/Constants";

function DeviceSubcriptions() {
  const defaultValues: {
    name: string;
    selected_project: ProjectDrodownProps | null;
    selected_subscription: UserSubscriptionDropdownProps | null;
  } = {
    name: "",
    selected_project: null,
    selected_subscription: null,
  };
  const token = useToken();
  const dispatch = useDispatch();
  const muistyle = muiStyles();
  const { values, resetForm, handleChange, setFieldValue, initialValues } =
    useFormik({
      initialValues: defaultValues,
      onSubmit: () => {},
    });
  const [isShowModal, setIsShowModal] = useState<isShowModalSubcriptionProps>({
    status: false,
    data: null,
    viewStatus: false,
  });
  const [deleteModal, setdeleteModal] = useState({
    modal: false,
    id: "",
  });
  const [noDataLoader, setnoDataLoader] = useState(false);
  const [showFilterOption, setShowFilterOption] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [searchOption, setSearchOption] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [subcriptionList, setSubcriptionList] =
    useState<subcriptionListProps | null>(null);

  const [projectList, setProjectList] = useState<ProjectDrodownProps[]>([]);

  const [userSubscriptionList, setUserSubscriptionList] = useState<
    UserSubscriptionDropdownProps[]
  >([]);

  const getUserSubscriptionList = () => {
    userSubscriptionDropdownSevice()
      .then((res) => {
        if (res?.data?.status) {
          setUserSubscriptionList(res?.data?.data || []);
        }
      })
      .catch((err) => getCatchMsg(err));
  };

  const getProjectList = () => {
    projectdropdownlist()
      .then((res) => {
        if (res?.data?.status) {
          setProjectList(res?.data?.data || []);
        }
      })
      .catch((err) => getCatchMsg(err));
  };

  const handleGetSubcriptionList = (
    page = 1,
    size = 10,
    resetValue?: boolean
  ) => {
    setnoDataLoader(true);
    let formData = new FormData();
    formData.append("name", resetValue ? "" : values?.name);
    formData.append(
      "project_id",
      resetValue || !values.selected_project
        ? ""
        : values?.selected_project?.id?.toString()
    );
    formData.append(
      "user_subscription_id",
      resetValue || !values.selected_subscription
        ? ""
        : values?.selected_subscription?.subscriptionId?.toString()
    );
    subcriptionsList(page, size, formData)
      .then((response) => {
        setPage(response?.data?.data?.page);
        setTotalCount(response?.data?.data?.totalCount);
        if (response?.data?.data?.items?.length > 0) {
          setSearchOption(true);
        } else if (values.name) {
          setSearchOption(true);
        } else {
          setSearchOption(false);
        }
        setSubcriptionList(response?.data);
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        setnoDataLoader(false);
      });
  };

  const handleDeleteSubcription = () => {
    dispatch(openLoader(true));
    let formData = new FormData();
    if (deleteModal?.id) formData.append("subscriptionId", deleteModal?.id);
    deleteSubcription(formData)
      .then((response) => {
        if (response?.data?.status === 1) {
          if (totalCount % 10 === 1) {
            handleGetSubcriptionList(page - 1);
          } else {
            handleGetSubcriptionList(page);
          }
          toast.success(response?.data?.msg);
        } else if (response?.data?.status === 0) {
          toast.error(response?.data?.msg);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        dispatch(openLoader(false));
        setdeleteModal((prev) => {
          return {
            ...prev,
            modal: false,
          };
        });
      });
  };

  const handleTableSearch = (data: string) => {
    if (data === "Enter") {
      setPage(1);
      handleGetSubcriptionList();
    }
  };

  const handleTableSearchClear = () => {
    setPage(1);
    resetForm();
    handleGetSubcriptionList(undefined, undefined, true);
  };

  useEffect(() => {
    if (token) {
      getProjectList();
      // getUserSubscriptionList();
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      handleGetSubcriptionList();
    }
  }, [token, values.selected_project, values.selected_subscription]);

  return (
    <>
      <PageHeader
        heading="Device Subscription"
        Btntitle="Add Device Subscription"
        modal={() => {
          setIsShowModal((prev) => {
            return {
              ...prev,
              status: true,
              data: null,
              viewStatus: false,
            };
          });
        }}
        onFilterbutton={() => {
          setShowFilterOption((pre) => !pre);
        }}
      />
      {isShowModal?.status && (
        <GlobalModal
          size="lg"
          modalStyle="globalModalmaxWidth logoutmodalminWidth"
          isVisible={isShowModal.status}
          setIsVisible={() => {
            setIsShowModal((prev) => {
              return {
                ...prev,
                status: true,
              };
            });
          }}
        >
          <AddSubcription
            viewStatus={isShowModal?.viewStatus}
            listApiCall={(value) => {
              if (value === "update") {
                handleGetSubcriptionList(page);
                return;
              }
              handleGetSubcriptionList();
            }}
            editData={isShowModal?.data || null}
            onClose={() => {
              setIsShowModal((prev) => {
                return {
                  ...prev,
                  status: false,
                };
              });
            }}
            modalClose={() => {
              setIsShowModal((prev) => {
                return {
                  ...prev,
                  status: false,
                };
              });
            }}
            projectList={projectList}
            userSubscriptionList={userSubscriptionList}
            type="device"
          />
        </GlobalModal>
      )}
      {deleteModal?.modal && (
        <GlobalModal
          isVisible={deleteModal.modal}
          setIsVisible={() => {
            setdeleteModal((prev) => {
              return {
                ...prev,
                modal: false,
              };
            });
          }}
        >
          <ConfirmationModal
            msg={`Are you sure that you want to permanently delete this device subcription.
            `}
            onClose={() => {
              setdeleteModal((prev) => {
                return {
                  ...prev,
                  modal: false,
                };
              });
            }}
            onPositiveButtonPressed={handleDeleteSubcription}
            onNegativeButtonPressed={() => {
              setdeleteModal((prev) => {
                return {
                  ...prev,
                  modal: false,
                };
              });
            }}
          />
        </GlobalModal>
      )}
      {/* {showFilterOption ? (
       
      ) : null} */}
      <AccordionContent filterStat={showFilterOption}>
        <div className={classes.serachContainer}>
          <div className="row mb-3">
            <Grid item lg={2} md={4} sm={6} xs={12}>
              <TextInputBox
                title="Plans"
                textLength={50}
                customInputProps={{
                  onKeyDown: (e) => handleTableSearch(e.key),
                }}
                name="name"
                placeHolder="Search plans"
                value={values.name}
                onChangeText={handleChange("name")}
              />
            </Grid>
            <Grid className="fieldBr8" item lg={2} md={4} sm={6} xs={12}>
              <TextInputBox textOnly title="Project" onChangeText={() => {}} />
              <Autocomplete
                options={projectList}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => {
                  return <TextField placeholder="Select project" {...params} />;
                }}
                onChange={(e, val) => {
                  setFieldValue("selected_project", val);
                }}
                value={values?.selected_project}
                PaperComponent={(props) => {
                  return <Paper {...props} className={muistyle.customSelect} />;
                }}
              />
            </Grid>
            {/* <Grid className="fieldBr8" item lg={2} md={4} sm={6} xs={12}>
              <TextInputBox
                textOnly
                title="User Subscription"
                onChangeText={() => {}}
              />
              <Autocomplete
                options={userSubscriptionList}
                getOptionLabel={(option) => option.subscriptionName}
                renderInput={(params) => {
                  return (
                    <TextField
                      placeholder="Select user subscription"
                      {...params}
                    />
                  );
                }}
                onChange={(e, val) => {
                  setFieldValue("selected_subscription", val);
                }}
                value={values?.selected_subscription}
              />
            </Grid> */}
          </div>
          <SearchAndClearbutton
            handleTableSearch={handleTableSearch}
            handleTableSearchClear={handleTableSearchClear}
            disabled={!isFormDirty(initialValues, values)}
          />
        </div>
      </AccordionContent>
      <div className={`table-responsive ${classes.AllTable}`}>
        <Box>
          <TableContainer>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>S.No</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>
                    Expiring <span className={classes.ImMonth}>(In Month)</span>
                  </TableCell>
                  <TableCell>Project</TableCell>
                  <TableCell>User Subscription</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {subcriptionList?.data?.items?.length === 0 ? (
                  <>
                    {noDataLoader ? (
                      <TableRow>
                        <TableCell className={classes.noData} colSpan={9}>
                          <NodataLoader />
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableRow>
                        <TableCell colSpan={9}>
                          <div className={classes.nodatafound}>
                            <img
                              src={Inbox}
                              alt="inbox"
                              width={100}
                              height={100}
                            />
                            <p>No data found</p>
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                ) : (
                  <>
                    {noDataLoader ? (
                      <TableRowsLoader columnNum={7} rowsNum={10} />
                    ) : subcriptionList?.data?.items?.length === 0 ||
                      !subcriptionList ? (
                      <TableRow>
                        <TableCell colSpan={7}>
                          <div className={classes.nodatafound}>
                            <img
                              src={Inbox}
                              alt="inbox"
                              width={100}
                              height={100}
                            />
                            <p>No data found</p>
                          </div>
                        </TableCell>
                      </TableRow>
                    ) : (
                      subcriptionList?.data?.items?.map(
                        (
                          subcription: subcriptionListDataItemsProps,
                          index: number
                        ) => (
                          <TableRow key={index}>
                            <TableCell>
                              {(subcriptionList?.data?.page - 1) *
                                subcriptionList?.data?.size +
                                index +
                                1}
                            </TableCell>
                            <TableCell>
                              {subcription?.name === null
                                ? "-"
                                : subcription?.name}
                            </TableCell>
                            <TableCell>
                              {subcription?.price === null
                                ? "-"
                                : subcription?.price}
                            </TableCell>
                            <TableCell>
                              {subcription?.validation === null
                                ? "-"
                                : subcription?.validation}
                            </TableCell>
                            <TableCell>
                              {subcription?.projectName
                                ? subcription?.projectName
                                : "-"}
                            </TableCell>
                            <TableCell>
                              {subcription?.user_subscriptionName
                                ? subcription?.user_subscriptionName
                                : "-"}
                            </TableCell>
                            <TableCell>
                              <div className={classes.icons}>
                                <Tooltip title="View" placement="top">
                                  <img
                                    src={view}
                                    alt="View"
                                    onClick={() =>
                                      setIsShowModal((prev) => {
                                        return {
                                          ...prev,
                                          status: true,
                                          data: subcription,
                                          viewStatus: true,
                                        };
                                      })
                                    }
                                  />
                                </Tooltip>
                                <Tooltip title="Edit" placement="top">
                                  <img
                                    src={edit}
                                    onClick={() => {
                                      setIsShowModal((prev) => {
                                        return {
                                          ...prev,
                                          status: true,
                                          data: subcription,
                                          viewStatus: false,
                                        };
                                      });
                                    }}
                                    alt="Edit"
                                  />
                                </Tooltip>
                                <Tooltip title="Delete" placement="top">
                                  <img
                                    src={delete_icon}
                                    alt="Delete"
                                    onClick={() =>
                                      setdeleteModal((prev) => {
                                        return {
                                          ...prev,
                                          modal: true,
                                          id: subcription.subscriptionId.toString(),
                                        };
                                      })
                                    }
                                  />
                                </Tooltip>
                              </div>
                            </TableCell>
                          </TableRow>
                        )
                      )
                    )}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </div>
      {subcriptionList?.data?.items?.length ? (
        <CustomPaginationwithdropdownpagination
          onChangePage={(page: number) => {
            handleGetSubcriptionList(page, pageSize);
            setPage(page);
          }}
          onChangePagerow={(PageSize: number) => {
            if (page * PageSize > subcriptionList?.data?.totalCount) {
              handleGetSubcriptionList(1, PageSize);
              setPageSize(PageSize);
              return;
            }
            handleGetSubcriptionList(page, PageSize);
            setPageSize(PageSize);
          }}
          count={subcriptionList?.data?.totalCount}
          pages={page}
          pageCount={subcriptionList?.data?.total_page}
          handleFunction={(selected: number) => {
            setPage(selected);
            handleGetSubcriptionList(selected, pageSize);
          }}
          labelRowsPerPage={"Devices Per Page"}
          rowsperpage={pageSize}
          totalData={subcriptionList?.data}
        />
      ) : null}
    </>
  );
}

export default DeviceSubcriptions;
