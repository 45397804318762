import {
  Autocomplete,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PageHeader from "../MangementLayoutHeader/PageHeader";
import {
  CustomPaginationwithdropdownpagination,
  GlobalModal,
  TableRowsLoader,
  TextInputBox,
} from "../../Components";
import edit from "../../Assets/Icons/Svg/edit.svg";
import delete_icon from "../../Assets/Icons/Svg/delete.svg";
import view from "../../Assets/Icons/Png/view.png";
import reloadIcon from "../../Assets/Icons/Svg/reload.svg";
import Inbox from "../../Assets/Images/Png/noData.png";
import managementStyles from "../HomeScreens/Management/Management.module.css";
import {
  OrganizationDropdownProps,
  ServiceHistoryDataProps,
  ServiceHistoryProps,
  ServiceRequestDataProps,
  ServiceRequestFilterProps,
  ServiceRequestProps,
} from "./Types";
import {
  deleteServiceRequestServices,
  getserviceRequestListService,
  organizationDropDown,
} from "../../Services/Services";
import { getCatchMsg } from "../../Utility/GeneralUtils";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import toast from "react-hot-toast";
import { ConfirmationModal } from "../../Modals";
import SearchAndClearbutton from "../../Components/SearchAndClearbutton";
import { useFormik } from "formik";
import AccordionContent from "../../Components/AccordionContent";
import { muiStyles } from "../../Utility/Constants";

export default function ServiceRequest() {
  const navigate = useNavigate();
  const muistyle = muiStyles();
  const DefaultValues: ServiceRequestFilterProps = {
    organization: null,
  };
  const {
    handleChange,
    setFieldValue,
    values,
    errors,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: DefaultValues,
    onSubmit: () => {},
  });

  const [noDataLoader, setNoDataLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [serviceRequestList, setServiceRequestList] =
    useState<ServiceRequestProps | null>(null);
  const [deleteModal, setdeleteModal] = useState({
    status: false,
    id: "",
  });
  const [showFilters, setShowFilters] = useState(false);
  const [organizationList, setOrganizationList] = useState<
    OrganizationDropdownProps[]
  >([]);

  const getserviceRequestList = (page = 1, size = 10, reset?: boolean) => {
    setNoDataLoader(true);
    const formdata = new FormData();
    formdata.append(
      "organizationId",
      reset || !values.organization
        ? ""
        : values.organization?.orgId?.toString()
    );
    getserviceRequestListService(page, size, formdata)
      .then((res) => {
        if (res?.data?.status) {
          setServiceRequestList(res?.data?.data);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        setNoDataLoader(false);
      });
  };

  const getOrganizations = () => {
    organizationDropDown()
      .then((res) => {
        if (res?.data?.status === 1) {
          setOrganizationList(res?.data?.data);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      });
  };

  const deleteServiceRequest = () => {
    const formdata = new FormData();
    formdata.append("serviceRequestId", deleteModal.id);
    deleteServiceRequestServices(formdata)
      .then((res) => {
        if (res?.data?.status) {
          toast.success(res?.data?.msg);
          setdeleteModal({
            status: false,
            id: "",
          });
          if (
            serviceRequestList &&
            (serviceRequestList?.items?.length - 1) % 10 === 0
          ) {
            getserviceRequestList(page - 1, pageSize);
          } else {
            getserviceRequestList(page, pageSize);
          }
        } else {
          toast.error(res?.data?.msg);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      });
  };

  /**
   * Handles the table search functionality.
   * If the data parameter is equal to "Enter", it resets the page to 0 and calls the
   * handleListofOrganization function to fetch the list of organizations.
   * @param {string | number} data - The data to be searched.
   * @returns None
   */

  const handleTableSearch = (data: string | number) => {
    if (data === "Enter") {
      getserviceRequestList(1, pageSize);
    }
  };

  /**
   * Clears the table search by resetting the page number, resetting the form, and
   * triggering a new search for the list of organizations.
   * @returns None
   */
  const handleTableSearchClear = () => {
    resetForm();
    getserviceRequestList(undefined, undefined, true);
  };

  useEffect(() => {
    getOrganizations();
  }, []);

  useEffect(() => {
    getserviceRequestList();
  }, [values?.organization]);
  return (
    <Grid className="appcontainer">
      <Grid className="py-3">
        {deleteModal.status && (
          <GlobalModal
            isVisible={deleteModal.status}
            setIsVisible={() => {
              setdeleteModal((prev) => {
                return {
                  ...prev,
                  status: false,
                };
              });
            }}
          >
            <ConfirmationModal
              msg={
                "Are you sure that you want to permanently delete this service request"
              }
              onClose={() => {
                setdeleteModal((prev) => {
                  return {
                    ...prev,
                    status: false,
                  };
                });
              }}
              onPositiveButtonPressed={deleteServiceRequest}
              onNegativeButtonPressed={() => {
                setdeleteModal((prev) => {
                  return {
                    ...prev,
                    status: false,
                  };
                });
              }}
            />
          </GlobalModal>
        )}
        <PageHeader
          heading="Service Request"
          Btntitle="Add Service Request"
          modal={() => {
            navigate("/service_request/create_service_request");
          }}
          onFilterbutton={() => {
            setShowFilters((pre) => !pre);
            //
          }}
        />

        {/* {showFilters && (
          
        )} */}
        <AccordionContent filterStat={showFilters}>
          <Grid>
            <Grid className={managementStyles.serachContainer}>
              <Grid container spacing={2}>
                <Grid className="fieldBr8" item lg={2} md={4} sm={6} xs={12}>
                  <TextInputBox
                    title="Organization"
                    onChangeText={() => {}}
                    textOnly
                  />
                  <Autocomplete
                    // disableCloseOnSelect
                    options={organizationList}
                    value={values.organization}
                    // className={styles.placeHolder}
                    onChange={(e, val) => {
                      setFieldValue("organization", val);
                    }}
                    getOptionLabel={(option) => option.orgName}
                    isOptionEqualToValue={(option, value) =>
                      option.orgId === value.orgId
                    }
                    renderInput={(params) => {
                      return (
                        <TextField
                          placeholder={values?.organization ? "" : "none"}
                          {...params}
                        />
                      );
                    }}
                    PaperComponent={(props) => {
                      return (
                        <Paper {...props} className={muistyle.customSelect} />
                      );
                    }}
                  />
                </Grid>
              </Grid>
              {/* <Grid paddingTop={1}>
                <SearchAndClearbutton
                  handleTableSearch={handleTableSearch}
                  handleTableSearchClear={handleTableSearchClear}
                />
              </Grid> */}
            </Grid>
          </Grid>
        </AccordionContent>

        <Grid>
          <Grid>
            <div className={`table-responsive py-2 px-3`}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>S.No</TableCell>
                      <TableCell>Service Number</TableCell>
                      <TableCell>Organization</TableCell>
                      <TableCell>Service Type</TableCell>
                      <TableCell>Error Date</TableCell>
                      <TableCell>Requested Date</TableCell>
                      <TableCell>Completed Date</TableCell>
                      <TableCell>Total Duration</TableCell>
                      <TableCell>Priority</TableCell>
                      <TableCell>Request Status</TableCell>

                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <>
                      {noDataLoader ? (
                        <TableRowsLoader columnNum={11} rowsNum={10} />
                      ) : serviceRequestList?.items?.length === 0 ||
                        !serviceRequestList ? (
                        <TableRow>
                          <TableCell colSpan={11}>
                            <div className={managementStyles.nodatafound}>
                              <img
                                src={Inbox}
                                alt="inbox"
                                width={100}
                                height={100}
                              />
                              <p>No data found</p>
                            </div>
                          </TableCell>
                        </TableRow>
                      ) : (
                        serviceRequestList?.items?.map(
                          (history: ServiceRequestDataProps, index: number) => (
                            <TableRow key={index}>
                              <TableCell>
                                {(serviceRequestList?.page - 1) *
                                  serviceRequestList?.size +
                                  index +
                                  1}
                              </TableCell>

                              <TableCell>
                                {history.serviceNumber
                                  ? history.serviceNumber
                                  : "-"}
                              </TableCell>
                              <TableCell>
                                {history.organizationName
                                  ? history.organizationName
                                  : "-"}
                              </TableCell>
                              <TableCell>
                                {history?.serviceType
                                  ? history?.serviceType
                                  : "-"}
                              </TableCell>

                              <TableCell>
                                {history.errorOccurred
                                  ? moment(history.errorOccurred).format(
                                      "YYYY-MM-DD hh:mm A"
                                    )
                                  : "-"}
                              </TableCell>
                              <TableCell>
                                {history?.requestReceived
                                  ? moment(history?.requestReceived).format(
                                      "YYYY-MM-DD hh:mm A"
                                    )
                                  : "-"}
                              </TableCell>
                              <TableCell>
                                {history?.completedDate
                                  ? moment(history?.completedDate).format(
                                      "YYYY-MM-DD hh:mm A"
                                    )
                                  : "-"}
                              </TableCell>
                              <TableCell>
                                {history?.totalTime ? history?.totalTime : "-"}
                              </TableCell>
                              <TableCell>
                                {history?.priority ? history?.priority : "-"}
                              </TableCell>
                              <TableCell>
                                {history.requestStatus
                                  ? history.requestStatus
                                  : "-"}
                              </TableCell>

                              <TableCell>
                                <div className={managementStyles.icons}>
                                  <Tooltip title="View" placement="top">
                                    <img
                                      onClick={() => {
                                        navigate(
                                          "/service_request/create_service_request",
                                          {
                                            state: {
                                              event: "view",
                                              id: history.serviceId,
                                            },
                                          }
                                        );
                                      }}
                                      src={view}
                                      alt="View"
                                    />
                                  </Tooltip>
                                  <Tooltip title="Update" placement="top">
                                    <img
                                      onClick={() => {
                                        navigate(
                                          "/service_request/create_service_request",
                                          {
                                            state: {
                                              event: "update",
                                              id: history.serviceId,
                                            },
                                          }
                                        );
                                      }}
                                      src={reloadIcon}
                                      alt="update"
                                    />
                                  </Tooltip>
                                  <Tooltip title="Edit" placement="top">
                                    <img
                                      onClick={() => {
                                        navigate(
                                          "/service_request/create_service_request",
                                          {
                                            state: {
                                              event: "edit",
                                              id: history.serviceId,
                                            },
                                          }
                                        );
                                      }}
                                      src={edit}
                                      alt="Edit"
                                    />
                                  </Tooltip>

                                  <Tooltip title="Delete" placement="top">
                                    <img
                                      onClick={() => {
                                        setdeleteModal((pre) => ({
                                          ...pre,
                                          status: true,
                                          id: history.serviceId?.toString(),
                                        }));
                                      }}
                                      src={delete_icon}
                                      alt="Delete"
                                    />
                                  </Tooltip>
                                </div>
                              </TableCell>
                            </TableRow>
                          )
                        )
                      )}
                    </>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Grid>
        </Grid>

        {serviceRequestList?.items?.length ? (
          <CustomPaginationwithdropdownpagination
            onChangePage={(page: number) => {
              getserviceRequestList(page, pageSize);
              setPage(page);
            }}
            onChangePagerow={(PageSize: number) => {
              if (page * PageSize > serviceRequestList?.total_count) {
                getserviceRequestList(1, PageSize);
                setPageSize(PageSize);
                return;
              }
              getserviceRequestList(page, PageSize);
              setPageSize(PageSize);
            }}
            count={serviceRequestList?.total_count}
            pages={page}
            pageCount={serviceRequestList?.total_page}
            handleFunction={(selected: number) => {
              setPage(selected);
              getserviceRequestList(selected, pageSize);
            }}
            labelRowsPerPage={"Requests Per Page"}
            rowsperpage={pageSize}
            totalData={{
              totalCount: serviceRequestList.total_count,
              size: serviceRequestList.size,
              page: serviceRequestList.page,
            }}
          />
        ) : null}
      </Grid>
    </Grid>
  );
}
