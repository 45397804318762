import { Navigate, Outlet } from "react-router-dom";
import { getCookie } from "../../Utility/Cookies";

/**
 * A higher-order component that acts as a private route for authenticated users.
 * If the "mconnect_admin_data" cookie is not present, it renders the child components.
 * Otherwise, it redirects the user to the "/dashboard" route.
 * @returns {JSX.Element} - The rendered component or the redirect component.
 */
export const AuthPrivateRoute = () => {
  return getCookie("mconnect_admin_data") === "" ? (
    <Outlet />
  ) : (
    <Navigate to={"/dashboard"} />
  );
};

/**
 * A private route component for the dashboard that checks if the user is authenticated.
 * If the user is authenticated, it renders the child components wrapped in an Outlet component.
 * If the user is not authenticated, it redirects to the home page.
 * @returns {JSX.Element} - The rendered JSX element.
 */
export const DashboardPrivateRoute = () => {
  return getCookie("mconnect_admin_data") !== "" ? (
    <Outlet />
  ) : (
    <Navigate to={"/"} />
  );
};
