import { Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import styles from "../Views/HomeScreens/ServiceRequest.module.css";
import delete_icon from "../Assets/Icons/Svg/image_del.svg";
import view from "../Assets/Icons/Svg/image_view.svg";
import download from "../Assets/Icons/Svg/download1.svg";
import file from "../Assets/Icons/Svg/file.svg";
import { downloadUrl } from "../Utility/GeneralUtils";
import classes from "./CustomStyle.module.css";

export default function ImageItem({
  ele,
  onView,
  onDelete,
  disable,
  height,
}: {
  ele: { file: Blob | string; filename: string };
  onView: () => void;
  onDelete: () => void;
  disable?: boolean;
  height?: number;
}) {
  const [visible, setVisible] = useState(false);

  return (
    <Grid
      className={`primarycard me-3 mb-3 position-relative ${styles.imageContainer}`}
      onMouseOver={() => {
        setVisible(true);
      }}
      onMouseLeave={() => {
        setVisible(false);
      }}
    >
      <img
        style={{
          height: height ? `${height}px` : "100px",
        }}
        src={
          typeof ele.file === "string"
            ? ele.file
            : URL.createObjectURL(ele.file)
        }
        alt="imagesf"
        onError={({ currentTarget }) => {
          currentTarget.src = file;
        }}
      />
      <Typography
        style={{
          wordBreak: "break-word",
          maxWidth: "150px",
        }}
        className="pt-2"
      >
        {ele?.filename}
      </Typography>
      {visible && (
        <Grid className={`${styles.actionBtn} ${classes.actionBtn}`}>
          <img
            src={view}
            style={{
              marginRight: "20px",
            }}
            onClick={() => {
              onView();
            }}
          />
          {!disable && (
            <img
              onClick={() => {
                onDelete();
              }}
              src={delete_icon}
              style={{
                animationDelay: "0.2s",
              }}
            />
          )}
          {disable && (
            <img
              onClick={() => {
                if (typeof ele?.file === "string") downloadUrl(ele.file);
              }}
              src={download}
              style={{
                animationDelay: "0.2s",
              }}
            />
          )}
        </Grid>
      )}
    </Grid>
  );
}
