import React, { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";

import img from "../../Assets/Images/Png/final_logo-01.png";
import CustomButton from "../../Components/CustomButton";
import TextInputBox from "../../Components/TextInputBox";
import { getCatchMsg } from "../../Utility/GeneralUtils";
import { setCookie } from "../../Utility/Cookies";
import { loginService } from "../../Services/Services";
import { handleStoreToken } from "../../Store/Reducers/LoginReducer";
import Loader from "../../Components/Loader";
import classess from "./AuthScreens.module.css";
import { openLoader } from "../../Store/Reducers/LoaderSlice";

interface InitialValueProps {
  Username: string;
  Password: string;
  devicetype: number;
}
function Signin() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const validationSchema = Yup.object({
    Username: Yup.string()
      .required("Username is Required")
      .test(
        "trim spaces",
        "Remove leading and trailing spaces",
        function (value) {
          if (value?.trim()?.length === 0) {
            return false;
          }
          return true;
        }
      ),
    Password: Yup.string()
      .required("Password is Required")
      .test(
        "trim spaces",
        "Remove leading and trailing spaces",
        function (value) {
          if (value?.trim()?.length === 0) {
            return false;
          }
          return true;
        }
      )
      .strict(true),
  });

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setFieldError,
    setFieldTouched,
  } = useFormik<InitialValueProps>({
    initialValues: {
      Username: "",
      Password: "",
      devicetype: 3,
    },
    validateOnBlur: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleLogin(values);
    },
  });

  /**
   * Handles the login process by sending a request to the server with the provided
   * username and password. If the login is successful, the user's access token is stored
   * and they are redirected to the dashboard. Otherwise, an error message is displayed.
   * @param {InitialValueProps} values - An object containing the username and password values.
   * @returns None
   */
  const handleLogin = (values: InitialValueProps) => {
    dispatch(openLoader(true));
    let formData = new FormData();
    formData.append("username", values.Username);
    formData.append("password", values.Password);
    formData.append("device_type", values.devicetype?.toString());
    loginService(formData)
      .then((res) => {
        if (res.data.status === 1) {
          setCookie("mconnect_admin_data", JSON.stringify(res.data));
          dispatch(handleStoreToken(res.data.access_token));
          navigate("/dashboard");
          toast.success(res.data.msg);
        } else {
          toast.error(res.data.msg);
        }
      })
      .catch((error) => {
        getCatchMsg(error);
      })
      .finally(() => {
        dispatch(openLoader(false));
      });
  };

  /**
   * Adds an event listener to all input fields on the page to handle the "Enter" key press.
   * When the "Enter" key is pressed, the handleSubmit function is called.
   * @returns None
   */
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        handleSubmit();
      }
    };
    const inputFields = document.querySelectorAll("input");
    inputFields.forEach((input) => {
      input.addEventListener("keydown", handleKeyPress);
    });
    return () => {
      inputFields.forEach((input) => {
        input.removeEventListener("keydown", handleKeyPress);
      });
    };
  }, []);

  return (
    <div className={`${classess.container1}`}>
      <div className={`ZoomAnimation ${classess.container2}`}>
        <div
          className={`col-lg-6 offset-lg-1 col-md-6 ${classess.rightcontent}`}
        >
          <div className={classess.logo}>
            <img src={img}></img>
          </div>
          <div className={`mb-3 ${classess.inputsection}`}>
            <TextInputBox
              title="Username"
              value={values.Username}
              placeHolder="Enter Username"
              onChangeText={handleChange("Username")}
              name="Username"
              onBlur={() => {
                try {
                  validationSchema.validateSyncAt("Username", values.Username);
                } catch (error) {
                  if (error instanceof Error) {
                    setFieldTouched("Username", true);
                    setFieldError("Username", error.message);
                  }
                }
              }}
              type={"text"}
              requiredText="*"
              textLength={50}
              errorText={
                touched.Username && errors.Username ? errors.Username : ""
              }
            />
          </div>
          <div className={`mb-3 ${classess.inputsection}`}>
            <TextInputBox
              title="Password"
              isSecure={true}
              value={values.Password}
              placeHolder="Enter password"
              onChangeText={handleChange("Password")}
              onBlur={() => {
                try {
                  validationSchema.validateSyncAt("password", values.Password);
                } catch (error) {
                  if (error instanceof Error) {
                    setFieldTouched("password", true);
                    setFieldError("password", error.message);
                  }
                }
              }}
              name="password"
              type={"password"}
              requiredText="*"
              textLength={50}
              errorText={
                errors.Password && touched.Password ? errors.Password : ""
              }
            />
          </div>

          <div>
            <CustomButton title="Sign In" onButtonPress={handleSubmit} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signin;
