import React, { useEffect, useState } from "react";
import { TabContext, TabList } from "@mui/lab";
import { makeStyles } from "@material-ui/core";
import { Tab, Drawer, Menu, MenuItem, Grid, Avatar } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import logo from "../../Assets/Images/Svg/logo.svg";
import MenuIcon from "../../Assets/Icons/Png/menuIcon.png";
import dropdownarrow from "../../Assets/Icons/Svg/dropdownarrow.svg";
import dropdownUparrow from "../../Assets/Icons/Svg/dropdownUparrow.svg";
import dash from "../../Assets/Icons/Svg/Dashboard.svg";
import manage from "../../Assets/Icons/Svg/manage.svg";
import workorder from "../../Assets/Icons/Svg/workorder.svg";
import changepass from "../../Assets/Icons/Svg/changepass.svg";
import logout2 from "../../Assets/Icons/Svg/logout2.svg";
import { setCookie } from "../../Utility/Cookies";
import GlobalModal from "../../Components/GlobalModal";
import OTHER_ICON from "../../Assets/Icons/Svg/others-1.svg";
import organization from "../../Assets/Icons/Svg/organization.svg";
import report from "../../Assets/Icons/Svg/report.svg";
import Service_Request from "../../Assets/Icons/Svg/Service_Request.svg";
import LogoutConfirmationModal from "../../Modals/LogoutConfirmationmodal";
import logout from "../../Assets/Icons/Png/logout.png";
import changepassword from "../../Assets/Icons/Svg/changePassword.svg";
import person from "../../Assets/Icons/Svg/person.svg";
import defaultProfileImg from "../../Assets/Icons/Png/man.png";
import { handleStoreadminDetails } from "../../Store/Reducers/LoginReducer";
import { AdminData } from "../../Utility/StoreData";
import classes from "./header.module.css";

const useStyles = makeStyles((theme) => ({
  tabColor: {
    "& .MuiTabs-indicator": {
      backgroundColor: "rgb(242, 89, 34)",
    },
  },
  DropdownArrow: {
    "& .dropdown-toggle::after": {
      display: "none",
    },
    "&>div": {
      padding: "1px 0px",
    },
  },
  Drawer: {
    "&>div:nth-child(3)": {
      backgroundColor: "var(--menuBg) !important",
      color: "#fff !important",
      padding: "15px 12px",
      minWidth: "265px",
    },
  },
  Menu: {
    "&>div:nth-child(3)": {
      top: "47px !important",
      left: "0 !important",
      right: "37px",
      width: "200px",
      marginLeft: "auto",
      border: "1px solid #0000003d",
    },
    "& ul": {
      padding: "0px !important",
    },
  },
  menuList: {
    paddingTop: "12px !important",
    paddingBottom: "12px !important",
  },
  tabColorNone: {
    "& .MuiTabs-indicator": {
      display: "none !important",
    },
  },
  activeTab: {
    "& .MuiTabs-indicator": {
      left: "18px !important",
      width: "60% !important",
      bottom: "8px !important",
    },
  },
  // notactiveTab: {
  //   "& .MuiTabs-indicator": {
  //     left: "18px !important",
  //     width: "50% !important",
  //     bottom: "0px !important",
  //   },
  // },
}));

export default function Header() {
  const styles = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const AdminDatas = AdminData();
  const { pathname } = useLocation();
  const [menuBarActiveId, setMenuBarActiveId] = useState<number | null>(null);
  const [deleteModal, setdeleteModal] = useState({
    modal: false,
    id: "",
  });

  const managementAndOtherNavigationPath = [
    "/dashboard/management/question_management",
    "/dashboard/management/model_management",
    "/dashboard/management/batch_management",
    "/dashboard/management/server_management",
    "/dashboard/management/employee_management",
    "/dashboard/management/main_model",
    "/dashboard/management/sub_model",
    "/dashboard/management/board_category",
    "/dashboard/management/categorization",
    "/dashboard/management/designation",
    "/dashboard/management/subcription",
  ];
  const menuData = [
    {
      id: 1,
      name: "Dashboard",
      pathname: "dashboard",
      naviagationPath: "/dashboard",
    },
    {
      id: 2,
      name: "Management",
      pathname: "management",
      naviagationPath: "/dashboard/management/question_management",
      downarrow: dropdownarrow,
      uparrow: dropdownUparrow,
    },
    {
      id: 3,
      name: "Organization",
      pathname: "organization",
      naviagationPath: "/organization",
    },
    {
      id: 4,
      name: "Suppliers",
      pathname: "suppliers",
      naviagationPath: "/suppliers",
    },
    // {
    //   id: 6,
    //   name: "Service History",
    //   pathname: "service_history",
    //   naviagationPath: "/service_history",
    // },
    {
      id: 7,
      name: "Service Request",
      pathname: "service_request",
      naviagationPath: "/service_request",
    },
    {
      id: 8,
      name: "Report",
      pathname: "report",
      naviagationPath: "/report",
    },
    // {
    //   id: 5,
    //   name: "Board Details",
    //   pathname: "board_specification_definition",
    //   naviagationPath: "/board_specification_definition",
    // },
  ];

  const hamburgerData = [
    {
      id: 1,
      name: "Dashboard",
      naviagationPath: "/dashboard",
      icon: dash,
    },
    {
      id: 2,
      name: "Management",
      naviagationPath: "/dashboard/management/device_management",
      downarrow: dropdownarrow,
      uparrow: dropdownUparrow,
      icon: manage,
      manageSubheadingData: [
        {
          id: 21,
          name: "Question Management",
          pathname: "/dashboard/management/question_management",
          path: "question_management",
          navigationPath: [
            "/dashboard/management/question_management",
            "/dashboard/management/question_management/master_question_details",
          ],
        },
        {
          id: 22,
          name: "Part/Pcb Management",
          pathname: "/dashboard/management/partPcpManagement",
          path: "partPcpManagement",
          navigationPath: [
            "/dashboard/management/partPcpManagement",
            "/dashboard/management/partPcpManagement/Part_Management_details",
            "/dashboard/management/partPcpManagement/borad_batch_details_list",
          ],
        },
        {
          id: 23,
          name: "Device Management",
          pathname: "/dashboard/management/device_management",
          path: "device_management",
          navigationPath: [
            "/dashboard/management/device_management",
            "/dashboard/management/device_management/model_Management_details",
          ],
        },
        {
          id: 24,
          name: "Server Management",
          pathname: "/dashboard/management/server_management",
          path: "server_management",
          navigationPath: [
            "/dashboard/management/server_management",
            "/dashboard/management/server_management/server_detail_list",
          ],
        },
        {
          id: 25,
          name: "Employee Management",
          pathname: "/dashboard/management/employee_management",
          path: "employee_management",
          navigationPath: ["/dashboard/management/employee_management"],
        },
        {
          id: 25,
          name: "Dealer Management",
          pathname: "/dashboard/management/dealer_management",
          path: "dealer_management",
          navigationPath: ["/dashboard/management/dealer_management"],
        },
        {
          id: 26,
          name: "Firmware Management",
          pathname: "/dashboard/management/firmware_management",
          path: "firmware_management",
          navigationPath: ["/dashboard/management/firmware_management"],
        },
        // {
        //   id: 21,
        //   name: "Device Management",
        //   pathname: "/dashboard/management/device_management",
        // },
        // {
        //   id: 22,
        //   name: "Model Management",
        //   pathname: "/dashboard/management/model_management",
        // },
        // {
        //   id: 23,
        //   name: "Batch Management",
        //   pathname: "/dashboard/management/batch_management",
        // },

        // {
        //   id: 24,
        //   name: "Server Management",
        //   pathname: "/dashboard/management/server_management",
        // },
        // {
        //   id: 25,
        //   name: "Employee Management",
        //   pathname: "/dashboard/management/employee_management",
        // },
        // {
        //   id: 26,
        //   name: "Firmware Management",
        //   pathname: "/dashboard/management/firmware_management",
        // },
      ],
    },
    {
      id: 7,
      name: "Others",
      naviagationPath: "/dashboard/management/main_model",
      downarrow: dropdownarrow,
      uparrow: dropdownUparrow,
      icon: OTHER_ICON,
      manageSubheadingData: [
        // {
        //   id: 71,
        //   name: "Main Model",
        //   pathname: "/dashboard/management/main_model",
        // },
        // {
        //   id: 72,
        //   name: "Sub Model",
        //   pathname: "/dashboard/management/sub_model",
        // },
        {
          id: 73,
          name: "Project Management",
          pathname: "/dashboard/management/project_management",
        },

        {
          id: 74,
          name: "MQTT Management",
          pathname: "/dashboard/management/mqtt_management",
        },
        {
          id: 75,
          name: "Device Subcription",
          pathname: "/dashboard/management/device_subcription",
        },
        // {
        //   id: 73,
        //   name: "Board Categry",
        //   pathname: "/dashboard/management/board_category",
        // },

        // {
        //   id: 74,
        //   name: "Categorization",
        //   pathname: "/dashboard/management/categorization",
        // },
        // {
        //   id: 75,
        //   name: "Designation",
        //   pathname: "/dashboard/management/designation",
        // },
        {
          id: 76,
          name: "User Subcription",
          pathname: "/dashboard/management/user_subcription",
        },
        {
          id: 78,
          name: "Subcription Request",
          pathname: "/dashboard/management/subscription_request",
        },
      ],
    },

    {
      id: 3,
      name: "Organization",
      pathname: "organization",
      naviagationPath: "/organization",
      icon: organization,
    },
    {
      id: 4,
      name: "Suppliers",
      pathname: "suppliers",
      naviagationPath: "/suppliers",
      icon: workorder,
    },
    {
      id: 77,
      name: "Service Request",
      pathname: "service_request",
      naviagationPath: "/service_request",
      icon: Service_Request,
    },
    {
      id: 8,
      name: "Report",
      pathname: "report",
      naviagationPath: "/report",
      icon: report,
    },
    {
      id: 5,
      name: "Change password",
      naviagationPath: "/dashboard/profile/change_password",
      icon: changepass,
    },
    {
      id: 6,
      name: "Logout",
      icon: logout2,
    },
  ];

  const [value, setValue] = React.useState("1");
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const [show, setShow] = useState(false);
  const [Arrow, setArrow] = useState(false);

  /**
   * Toggles the value of the "show" state variable.
   * @returns None
   */
  const toggleShow = () => {
    setShow((prevShow) => !prevShow);
  };

  /**
   * Handles the logout functionality.
   * If the "Remember" flag is not set in the local storage, it removes the "mconnectLoginData" item from the local storage
   * and dispatches the handleStoreadminDetails action with null as the payload.
   * It also sets the "mconnect_admin_data" and "mconnectLoginData" cookies to null.
   * Additionally, it sets the "mconnect_admin_data" cookie to expire immediately.
   * Finally, it navigates to the home page ("/").
   * @returns None
   */
  const handleLogout = () => {
    if (!localStorage.getItem("Remember")) {
      localStorage.removeItem("mconnectLoginData");
      dispatch(handleStoreadminDetails(null));
    }

    setCookie("mconnect_admin_data", "");
    // setCookie("mconnectLoginData", null);

    document.cookie =
      "mconnect_admin_data" + "=; expires=Thu, 01-Jan-70 00:00:01 GMT;";
    navigate("/");
  };

  /**
   * useEffect hook that updates the active tab based on the current pathname.
   * @param {string} pathname - the current pathname of the page
   * @returns None
   */
  useEffect(() => {
    let findActiveTab = menuData.map((ele) => {
      if (pathname.includes(ele.pathname)) {
        if (pathname.includes("management")) {
          return { name: "Management" };
        } else if (pathname.includes("Organization")) {
          return { name: "Organization" };
        }
        return ele;
      } else {
        return {};
      }
    });
    findActiveTab = findActiveTab.filter((ele) => Object.keys(ele)?.length > 0);
    if (findActiveTab?.length > 0) {
      setValue(findActiveTab?.[0]?.name || value);
    }
  }, [pathname]);

  return (
    <>
      <div className={classes.header}>
        <div className={classes.menu}>
          <div onClick={toggleShow} className={classes.menuIcon}>
            <img src={MenuIcon} alt="MenuIcon"></img>
          </div>
          <div>
            <img src={logo} alt="logo" />
          </div>
          <div>
            <Drawer open={show} onClose={toggleShow} className={styles?.Drawer}>
              <div className={classes.child3}>
                <img
                  onError={({ currentTarget }) => {
                    currentTarget.src = defaultProfileImg;
                  }}
                  src={AdminDatas?.image}
                  alt="your image"
                  className={classes.user_image}
                  onClick={() => {
                    navigate("/dashboard/profile/my_profile");
                    toggleShow();
                  }}
                />

                <div>
                  <p
                    className={classes.user_name}
                    onClick={() => {
                      navigate("/dashboard/profile/my_profile");
                      toggleShow();
                    }}
                  >
                    {AdminDatas?.name}
                  </p>
                  <p
                    className={classes.user_id}
                    onClick={() => {
                      navigate("/dashboard/profile/my_profile");
                      toggleShow();
                    }}
                  >
                    ID: {AdminDatas?.designationId}
                  </p>
                </div>
                <button className="btn-close" onClick={toggleShow}></button>
              </div>

              <div className={classes.child2} style={{ cursor: "pointer" }}>
                {hamburgerData.map((ele, i) => {
                  return (
                    <div key={ele.id.toString()}>
                      <div
                        onClick={() => {
                          if (ele?.id === 2) {
                            setMenuBarActiveId(ele?.id);
                          } else if (ele?.id === 7) {
                            setMenuBarActiveId(ele?.id);
                          } else {
                            setMenuBarActiveId(null);
                            if (ele?.naviagationPath) {
                              navigate(ele?.naviagationPath);
                              toggleShow();
                            }
                          }
                        }}
                        // className={classes.header_name}
                      >
                        <div className={classes.menubar_content}>
                          <div className={classes.menubar_icon}>
                            <img
                              src={ele.icon}
                              alt="icon"
                              style={{
                                fill: ele?.id === 7 ? "white" : "",
                              }}
                            />
                          </div>
                          <p
                            onClick={() =>
                              ele.name == "Logout" &&
                              setdeleteModal((prev) => {
                                return {
                                  ...prev,
                                  modal: true,
                                  id: "",
                                };
                              })
                            }
                          >
                            {ele.name}
                          </p>

                          <div className={classes.menubar_dropdown}>
                            {ele.id === 2 || ele.id === 7 ? (
                              <img src={ele?.downarrow} alt={ele.downarrow} />
                            ) : null}
                          </div>
                        </div>

                        <div
                          style={{
                            width: "35px",
                            marginLeft: "38px",
                            borderBottom:
                              pathname === ele.naviagationPath ||
                              (ele.id === menuBarActiveId &&
                                managementAndOtherNavigationPath.includes(
                                  pathname
                                ))
                                ? "2px solid #F25922"
                                : "",
                          }}
                        ></div>

                        {menuBarActiveId === ele?.id &&
                          ele?.id &&
                          ele?.manageSubheadingData?.map((element, i) => {
                            return (
                              <div
                                key={element.id.toString()}
                                style={{
                                  cursor: "pointer",
                                  color:
                                    pathname === element.pathname
                                      ? "#fff"
                                      : "rgba(255, 255, 255, 0.5)",
                                  marginLeft: "50px",
                                }}
                                onClick={() => {
                                  navigate(element.pathname);
                                  toggleShow();
                                }}
                              >
                                {element.name}
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </Drawer>
          </div>
        </div>
        <div className={classes.content}>
          <img src={logo} alt="logo" />
          <div className={classes.child2}>
            {menuData.map((ele, i) => {
              return (
                <TabContext value={value}>
                  <div className={"headerTab"}>
                    <TabList
                      onChange={handleTabChange}
                      aria-label="lab API tabs example"
                      className={
                        ele.name === value
                          ? `${styles.activeTab} ${styles.tabColor}`
                          : `${styles.tabColor}`
                      }
                      classes={{
                        indicator: "rgb(242, 89, 34) !important",
                      }}
                    >
                      <Tab
                        label={ele.name}
                        value={ele.name}
                        disableRipple={true}
                        className={`${classes.tabContent} ${
                          ele.name === value ? `${classes.activeTab}` : ""
                        }`}
                        onClick={() => {
                          navigate(ele?.naviagationPath);
                        }}
                      />
                    </TabList>
                  </div>
                </TabContext>
              );
            })}
          </div>

          <GlobalModal
            isVisible={deleteModal.modal}
            modalStyle="logoutmodalminWidth"
            setIsVisible={() => {
              setdeleteModal((prev) => {
                return {
                  ...prev,
                  modal: false,
                };
              });
            }}
          >
            <LogoutConfirmationModal
              msg={"Are you sure do you want to Logout."}
              onClose={() => {
                setdeleteModal((prev) => {
                  return {
                    ...prev,
                    modal: false,
                  };
                });
              }}
              onPositiveButtonPressed={() => handleLogout()}
              onNegativeButtonPressed={() => {
                setdeleteModal((prev) => {
                  return {
                    ...prev,
                    modal: false,
                  };
                });
              }}
            />
          </GlobalModal>

          <Grid
            onClick={() => {
              setArrow((pre) => !pre);
            }}
            style={{ padding: "5px 12px" }}
            className={classes.profiledropdown}
          >
            <div style={{ display: "flex" }}>
              <img
                onError={({ currentTarget }) => {
                  currentTarget.src = defaultProfileImg;
                }}
                src={AdminDatas?.image}
                alt="your image"
                className={classes.user_image}
              />

              <div className={classes.nameContainer}>
                <p className={classes.user_name}>{AdminDatas?.name}</p>
                <p className={classes.user_id}>{AdminDatas?.designationName}</p>
              </div>
              <ArrowDropDownIcon
                style={{
                  transition: "0.3s",
                  transform: `rotate(${Arrow ? 180 : 0}deg)`,
                  marginTop: "9px",
                  fill: "white",
                }}
              />
            </div>
            {Arrow && (
              <Menu
                sx={{
                  "& .MuiPaper-root": {
                    borderRadius: "10px !important",
                    boxShadow: "1px 1px 10px 1px rgba(0,0,0,0.1) !important",
                    border: "none !important",
                  },
                }}
                className={styles.Menu}
                open={Arrow}
              >
                <MenuItem
                  onClick={() => {
                    navigate("/dashboard/profile/my_profile");
                  }}
                  className={styles.menuList}
                  // style={{ opacity: "0.5" }}
                >
                  <div className={classes.perofile_icons}>
                    <img src={person} alt="person" />
                    <p>Profile</p>
                  </div>
                  <div className={classes.borderBottom} />
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigate("/dashboard/profile/change_password");
                  }}
                  className={styles.menuList}
                >
                  <div className={classes.perofile_icons}>
                    <img src={changepassword} alt="changepassword" />
                    <p>Change Password</p>
                  </div>
                  <div className={classes.borderBottom} />
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setdeleteModal({
                      modal: true,
                      id: "",
                    });
                  }}
                  className={styles.menuList}
                >
                  <div className={classes.profileLogout}>
                    <img
                      src={logout}
                      width="17px"
                      height="17px"
                      alt="password"
                      style={{ marginRight: "8px" }}
                    />
                    <p style={{ marginTop: "-1px" }}>Logout</p>
                  </div>
                </MenuItem>
              </Menu>
            )}
          </Grid>
        </div>
      </div>
    </>
  );
}
