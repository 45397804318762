import React, { useEffect, useState } from "react";
import {
  ServiceHistoryDataProps,
  ServiceTaskDataProps,
  ShowTaskProps,
} from "../Views/HomeScreens/Types";
import { Grid, MenuItem, TextField } from "@mui/material";
import PageHeader from "../Views/MangementLayoutHeader/PageHeader";
import ModalHeader from "./ModalHeader";
import { CustomButton, Loader, TextInputBox } from "../Components";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Close } from "@mui/icons-material";
import moment from "moment";
import dayjs from "dayjs";
import {
  createServiceTaskServices,
  updateServiceTaskServices,
} from "../Services/Services";
import { getCatchMsg } from "../Utility/GeneralUtils";
import toast from "react-hot-toast";
import UpdateDeleteActions from "../Components/UpdateDeleteActions";
import classes from "./Modal.module.css";
import { openLoader } from "../Store/Reducers/LoaderSlice";
import { useDispatch } from "react-redux";

export default function AddServiceTaskModal({
  view,
  editdata,
  onClose,
  listapi,
  seviceId,
}: {
  view: boolean;
  editdata: ServiceTaskDataProps | null;
  onClose: () => void;
  listapi: (val?: "add" | "update") => void;
  seviceId: string;
}) {
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    start_date: Yup.string().required("* Start date is required"),
    end_date: Yup.string().required("* End date is required"),
    device_before: Yup.mixed()
      .transform((val) => (val === "-1" ? undefined : val))
      .required("* Device before status is required"),
    device_after: Yup.mixed()
      .transform((val) => (val === "-1" ? undefined : val))
      .required("* Device after status is required"),
  });
  const { errors, values, touched, setFieldValue, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        start_date: editdata?.startDatetime
          ? dayjs(editdata?.startDatetime)
          : null,
        end_date: editdata?.endDatetime ? dayjs(editdata?.endDatetime) : null,
        total_hours: editdata?.totalDuration
          ? `${editdata?.totalDuration} hours`
          : "",
        device_before: editdata?.deviceStatusBeforeId
          ? editdata?.deviceStatusBeforeId?.toString()
          : "-1",
        device_after: editdata?.deviceStatusAfterId
          ? editdata?.deviceStatusAfterId?.toString()
          : "-1",
        comment: editdata?.comment ? editdata?.comment : "",
        description: editdata?.description ? editdata?.description : "",
      },
      validationSchema: validationSchema,
      onSubmit: () => {
        if (!editdata) {
          addServiceTask();
        } else {
          editServiceTask();
        }
      },
    });

  const addServiceTask = () => {
    dispatch(openLoader(true));
    const formdata = new FormData();
    formdata.append("service_request_id", seviceId);
    formdata.append(
      "started_at",
      values.start_date
        ? dayjs(values.start_date).format("YYYY-MM-DD HH:mm")
        : ""
    );
    formdata.append(
      "end_at",
      values.end_date ? dayjs(values.end_date).format("YYYY-MM-DD HH:mm") : ""
    );
    formdata.append("deviceBefore", values.device_before);
    formdata.append("deviceAfter", values.device_after);
    formdata.append("comment", values.comment);
    formdata.append("description", values.description);
    formdata.append(
      "totalHours",
      values.total_hours ? values.total_hours?.split(" hours")?.[0] : ""
    );

    createServiceTaskServices(formdata)
      .then((res) => {
        if (res?.data?.status) {
          toast.success(res?.data?.msg);
          listapi("add");
          onClose();
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        dispatch(openLoader(false));
      });
  };

  const editServiceTask = () => {
    dispatch(openLoader(true));
    const formdata = new FormData();
    formdata.append(
      "serviceTaskId",
      editdata?.taskId ? editdata?.taskId?.toString() : ""
    );
    formdata.append("service_request_id", seviceId);
    formdata.append(
      "started_at",
      values.start_date
        ? dayjs(values.start_date).format("YYYY-MM-DD HH:mm")
        : ""
    );
    formdata.append(
      "end_at",
      values.end_date ? dayjs(values.end_date).format("YYYY-MM-DD HH:mm") : ""
    );
    formdata.append("deviceBefore", values.device_before);
    formdata.append("deviceAfter", values.device_after);
    formdata.append("comment", values.comment);
    formdata.append("description", values.description);
    formdata.append(
      "totalHours",
      values.total_hours ? values.total_hours?.split(" hours")?.[0] : ""
    );

    updateServiceTaskServices(formdata)
      .then((res) => {
        if (res?.data?.status) {
          toast.success(res?.data?.msg);
          listapi("update");
          onClose();
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        dispatch(openLoader(false));
      });
  };

  useEffect(() => {
    if (values.start_date && values.end_date) {
      setFieldValue(
        "total_hours",
        `${dayjs(values.end_date).diff(
          dayjs(values.start_date),
          "hours"
        )} hours`
      );
    } else {
      setFieldValue("total_hours", "0 hours");
    }
  }, [values.start_date, values.end_date]);
  return (
    <Grid>
      <ModalHeader
        onClose={onClose}
        heading={
          view
            ? "View Service Task"
            : editdata === null
              ? "Add Service Task"
              : "Edit Service Task"
        }
      />

      <Grid className="px-4 pb-3">
        {view ? (
          <Grid paddingTop={1}>
            <div className={classes.add_mainmodel}>
              <ul>
                <div className={classes.heading}>
                  <p>Start Date</p>:
                  <span>
                    {editdata?.startDatetime
                      ? moment(editdata.startDatetime).format(
                          "YYYY-MM-DD hh:mm A"
                        )
                      : "-"}
                  </span>
                </div>
                <div className={classes.heading}>
                  <p>End Date</p>:
                  <span>
                    {editdata?.endDatetime
                      ? moment(editdata.endDatetime).format(
                          "YYYY-MM-DD hh:mm A"
                        )
                      : "-"}
                  </span>
                </div>
                <div className={classes.heading}>
                  <p>Total Hours</p>:
                  <span>
                    {editdata?.totalDuration
                      ? `${editdata?.totalDuration} hours`
                      : "-"}
                  </span>
                </div>
                <div className={classes.heading}>
                  <p>Device Status Before</p>:
                  <span>
                    {editdata?.deviceStatusBefore
                      ? editdata.deviceStatusBefore
                      : "-"}
                  </span>
                </div>
                <div className={classes.heading}>
                  <p>Device Status After</p>:
                  <span>
                    {editdata?.deviceStatusAfter
                      ? editdata?.deviceStatusAfter
                      : "-"}
                  </span>
                </div>
                <div className={classes.heading}>
                  <p>Description</p>:
                  <span>
                    {editdata?.description ? editdata?.description : "-"}
                  </span>
                </div>
                <div className={classes.heading}>
                  <p>Comments</p>:
                  <span>{editdata?.comment ? editdata?.comment : "-"}</span>
                </div>
              </ul>
            </div>
          </Grid>
        ) : (
          <Grid className="px-2">
            <Grid container spacing={2}>
              <Grid className="fieldBr8" item lg={4} sm={6} xs={12}>
                <TextInputBox
                  onChangeText={() => {}}
                  title="Start Date"
                  textOnly
                  requiredText={"*"}
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    slotProps={{
                      textField: {
                        fullWidth: true,
                      },
                    }}
                    maxDate={values.end_date}
                    value={values.start_date}
                    onChange={(value) => {
                      setFieldValue("start_date", value);
                    }}
                    readOnly={view}
                  />
                </LocalizationProvider>
                <TextInputBox
                  errorText={
                    errors?.start_date && touched?.start_date
                      ? errors?.start_date
                      : ""
                  }
                  onChangeText={() => {}}
                  textOnly
                />
              </Grid>

              <Grid className="fieldBr8" item lg={4} sm={6} xs={12}>
                <TextInputBox
                  onChangeText={() => {}}
                  title="End Date"
                  textOnly
                  requiredText={"*"}
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    slotProps={{
                      textField: {
                        fullWidth: true,
                      },
                    }}
                    minDate={values.start_date}
                    value={values.end_date}
                    onChange={(value) => {
                      setFieldValue("end_date", value);
                    }}
                    readOnly={view}
                  />
                </LocalizationProvider>
                <TextInputBox
                  errorText={
                    errors?.end_date && touched?.end_date
                      ? errors?.end_date
                      : ""
                  }
                  onChangeText={() => {}}
                  textOnly
                />
              </Grid>
              <Grid item lg={4} sm={6} xs={12}>
                <TextInputBox
                  title="Total Hours"
                  placeHolder="Total Hours"
                  onChangeText={(e) => {
                    const numericValue = e?.replace(/[^0-9]/g, "");
                    handleChange("total_hours")(numericValue);
                  }}
                  // customInputProps={{
                  //   maxLength: 10,
                  // }}
                  textLength={10}
                  value={values.total_hours}
                  readOnly
                />
              </Grid>
              <Grid className="fieldBr8" item lg={4} sm={6} xs={12}>
                <TextInputBox
                  onChangeText={() => {}}
                  title="Device Status Before"
                  textOnly
                  requiredText={"*"}
                />

                <TextField
                  fullWidth
                  size="small"
                  select
                  value={values.device_before}
                  onChange={(e) => {
                    handleChange("device_before")(e.target.value);
                  }}
                  defaultValue={"-1"}
                  sx={{
                    "& .MuiSelect-select": {
                      color:
                        values.device_before === "-1" ? "rgba(0,0,0,0.5)" : "",
                    },
                  }}
                  placeholder="Device Status Before"
                  InputProps={{
                    readOnly: view,
                    endAdornment: (
                      <Close
                        style={{
                          marginRight: "17px",
                          fontSize: "16px",
                          cursor: "pointer",
                          display:
                            values.device_before === "-1" || view
                              ? "none"
                              : "block",
                        }}
                        onClick={() => {
                          setFieldValue("device_before", "-1");
                        }}
                      />
                    ),
                  }}
                >
                  <MenuItem
                    value={"-1"}
                    style={{ visibility: "hidden", height: 0, padding: 0 }}
                  >
                    Select Device Status
                  </MenuItem>
                  <MenuItem value={"1"}>Operational</MenuItem>
                  <MenuItem value={"2"}>Partially Operational</MenuItem>
                  <MenuItem value={"3"}>Not Operational</MenuItem>
                </TextField>
                <TextInputBox
                  title=""
                  onChangeText={() => {}}
                  textOnly
                  errorText={
                    errors.device_before && touched.device_before
                      ? errors.device_before
                      : ""
                  }
                />
              </Grid>
              <Grid className="fieldBr8" item lg={4} sm={6} xs={12}>
                <TextInputBox
                  onChangeText={() => {}}
                  title="Device Status After"
                  textOnly
                  requiredText={"*"}
                />

                <TextField
                  fullWidth
                  size="small"
                  select
                  value={values.device_after}
                  onChange={(e) => {
                    handleChange("device_after")(e.target.value);
                  }}
                  defaultValue={"-1"}
                  sx={{
                    "& .MuiSelect-select": {
                      color:
                        values.device_after === "-1" ? "rgba(0,0,0,0.5)" : "",
                    },
                  }}
                  placeholder="Device Status After"
                  InputProps={{
                    readOnly: view,
                    endAdornment: (
                      <Close
                        style={{
                          marginRight: "17px",
                          fontSize: "16px",
                          cursor: "pointer",
                          display:
                            values.device_after === "-1" || view
                              ? "none"
                              : "block",
                        }}
                        onClick={() => {
                          setFieldValue("device_after", "-1");
                        }}
                      />
                    ),
                  }}
                >
                  <MenuItem
                    value={"-1"}
                    style={{ visibility: "hidden", height: 0, padding: 0 }}
                  >
                    Select Device Status
                  </MenuItem>
                  <MenuItem value={"1"}>Operational</MenuItem>
                  <MenuItem value={"2"}>Partially Operational</MenuItem>
                  <MenuItem value={"3"}>Not Operational</MenuItem>
                </TextField>
                <TextInputBox
                  title=""
                  onChangeText={() => {}}
                  textOnly
                  errorText={
                    errors.device_after && touched.device_after
                      ? errors.device_after
                      : ""
                  }
                />
              </Grid>
              <Grid item lg={6} xs={12}>
                <TextInputBox
                  title="Description"
                  onChangeText={() => {}}
                  textOnly
                />
                <textarea
                  className="textarea"
                  placeholder="Description"
                  value={values.description}
                  onChange={(e) => {
                    setFieldValue("description", e.target.value);
                  }}
                  readOnly={view}
                ></textarea>
              </Grid>
              <Grid item lg={6} xs={12}>
                <TextInputBox
                  title="Comments"
                  onChangeText={() => {}}
                  textOnly
                />
                <textarea
                  className="textarea"
                  placeholder="Comments"
                  value={values.comment}
                  onChange={(e) => {
                    setFieldValue("comment", e.target.value);
                  }}
                  readOnly={view}
                ></textarea>
              </Grid>
            </Grid>
            <Grid>
              {!editdata && (
                <CustomButton
                  onButtonPress={() => {
                    handleSubmit();
                  }}
                  title="Submit"
                  customButtonStyle={{
                    maxWidth: "150px",
                  }}
                />
              )}
              {!view && editdata && (
                <UpdateDeleteActions
                  onCancelPress={() => onClose()}
                  onUpdatePress={() => {
                    handleSubmit();
                  }}
                />
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
