import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { EditBatchModalTypes } from "../../../Modals/Types";
import { OrganizationDropdownProps, uploadFirmwareListProps } from "../Types";
import { useFormik } from "formik";
import { useToken } from "../../../Utility/StoreData";
import {
  getModelDropDown,
  listFirmWareUpload,
  organizationDropDown,
} from "../../../Services/Services";
import { getCatchMsg, isFormDirty } from "../../../Utility/GeneralUtils";
import AccordionContent from "../../../Components/AccordionContent";
import classes from "../Management/Management.module.css";
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import {
  CustomDropDown,
  CustomPaginationwithdropdownpagination,
  GlobalModal,
  NodataLoader,
  TableRowsLoader,
  TextInputBox,
} from "../../../Components";
import { Typography } from "antd";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import SearchAndClearbutton from "../../../Components/SearchAndClearbutton";
import { useFirmwareContext } from "../../../Utility/Context";
import FirmwareUpload from "../../../Modals/FirmwareUpload";
import view from "../../../Assets/Icons/Png/view.png";
import Inbox from "../../../Assets/Images/Png/noData.png";

const UploadFirmwareManagement = forwardRef((props, ref) => {
  const token = useToken();
  const {
    isShowAssignFirmWareModal,
    setisShowAssignFirmWareModal,
    filterStatus,
    setdownloadBtnStatus,
  } = useFirmwareContext();
  const [modelList, setmodelList] = useState<EditBatchModalTypes[]>([]);
  const [organizationList, setOrganizationList] = useState<
    OrganizationDropdownProps[]
  >([]);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [noDataLoader, setnoDataLoader] = useState(false);
  const [OpenDate, setOpenDate] = useState<string[]>([]);
  const [uploadFirmwareList, setuploadFirmwareList] =
    useState<uploadFirmwareListProps | null>(null);
  const {
    values,
    initialValues,
    setFieldValue,
    setValues,
    resetForm,
    handleChange,
  } = useFormik({
    initialValues: {
      model_no: "",
      model_id: "",
      selectOrgId: "",
      selectOrgName: "",
      fromdate: dayjs(new Date()).subtract(1, "month"),
      todate: dayjs(new Date()),
      version: "",
    },
    onSubmit(value) {
      // handleAddFirmwareUpload(value);
    },
  });

  useImperativeHandle(
    ref,
    () => ({
      callChildFunction() {
        handleGetFirmWareTableList(1, 10, false, true);
      },
    }),
    []
  );

  useEffect(() => {
    if (token) {
      handleGetModelDropDown();
      getOrganizations();
      handleGetFirmWareTableList();
    }
  }, [token]);

  const handleGetModelDropDown = () => {
    getModelDropDown()
      .then((response) => {
        if (response?.data?.status === 1) {
          setmodelList([...response?.data?.data]);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      });
  };

  const getOrganizations = () => {
    organizationDropDown()
      .then((res) => {
        if (res?.data?.status === 1) {
          setOrganizationList(res?.data?.data);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      });
  };

  const handleTableSearch = (data: string) => {
    if (data === "Enter") {
      handleGetFirmWareTableList(1);
    }
  };

  const handleTableSearchClear = () => {
    resetForm();
    handleGetFirmWareTableList(1, 10, true);
  };

  const handleGetFirmWareTableList = (
    page = 1,
    size = 10,
    resetValue?: boolean,
    is_download = false
  ) => {
    setnoDataLoader(true);
    let formData = new FormData();
    if (values?.model_id && !resetValue) {
      formData.append("modelId", values?.model_id);
    }
    formData.append("version", resetValue ? "" : values?.version);
    formData.append("organization_id", resetValue ? "" : values?.selectOrgId);
    formData.append(
      "from_datetime",
      dayjs(values?.fromdate).format("YYYY-MM-DD HH:mm:ss")
    );
    formData.append(
      "todatetime",
      dayjs(values?.todate).format("YYYY-MM-DD HH:mm:ss")
    );

    if (is_download) {
      formData.append("is_download", "1");
    }
    listFirmWareUpload(page, size, formData)
      .then((response) => {
        if (response?.data?.status === 1) {
          if (is_download) {
            const { data } = response?.data;
            const linkSource = data;
            const downloadLink = document.createElement("a");
            downloadLink.href = linkSource;
            downloadLink.click();
          } else {
            const { items } = response?.data?.data;
            setdownloadBtnStatus(items?.length);
            setPage(response?.data?.data?.page);
            setPageSize(response?.data?.data?.size);
            setuploadFirmwareList(response?.data?.data);
          }
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        if (is_download) {
          setdownloadBtnStatus(false);
        }
        setnoDataLoader(false);
      });
  };

  const handleCloseFirmWareAssign = () => {
    setisShowAssignFirmWareModal((prev) => {
      return {
        ...prev,
        data: null,
        modal: false,
      };
    });
  };

  return (
    <>
      {/* assign frirmware  */}
      {isShowAssignFirmWareModal?.modal && (
        <GlobalModal
          size="sm"
          customStyle={{
            maxHeight: "500px",
          }}
          modalStyle={isShowAssignFirmWareModal?.data ? "" : "globalModallg"}
          isVisible={isShowAssignFirmWareModal.modal}
          setIsVisible={() => {
            setisShowAssignFirmWareModal((prev) => {
              return {
                ...prev,
                modal: true,
              };
            });
          }}
        >
          <FirmwareUpload
            onClose={handleCloseFirmWareAssign}
            editData={isShowAssignFirmWareModal?.data}
            listapiCall={(value) => {
              if (value === "update") {
                handleGetFirmWareTableList(page);
                return;
              }
              handleGetFirmWareTableList();
            }}
            modalClose={handleCloseFirmWareAssign}
          />
        </GlobalModal>
      )}
      <AccordionContent filterStat={filterStatus?.management}>
        <div className={classes.serachContainer}>
          <Grid container spacing={2}>
            <Grid item lg={2} md={4} sm={6} xs={12}>
              <CustomDropDown
                editName={values.model_no ? values.model_no : "--None--"}
                items={modelList}
                title="Model"
                onSelectedItem={(val, value) => {
                  setFieldValue("model_no", value?.label ? value?.label : "");
                  setFieldValue("model_id", value?.id ? value?.id : "");
                }}
                fieldName="model_no"
                anotherFieldName="model_id"
                value={values.model_id}
              />
            </Grid>
            <Grid item lg={2} md={4} sm={6} xs={12}>
              <CustomDropDown
                title="Organizations"
                editName={values?.selectOrgName}
                items={organizationList}
                onSelectedItem={(val, value) => {
                  setValues({
                    ...values,
                    selectOrgName: value?.label,
                    selectOrgId: value?.id?.toString(),
                  });
                }}
                fieldName={"orgName"}
                anotherFieldName={"orgId"}
                value={values?.selectOrgId}
              />
            </Grid>
            <Grid item lg={2} md={4} sm={6} xs={12} className="dropbr8">
              <Typography className={classes.title}>From Date</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  open={OpenDate?.includes("from")}
                  disableFuture
                  maxDate={dayjs(values.todate).subtract(0, "day")}
                  onOpen={() => {
                    setOpenDate(["from"]);
                  }}
                  onClose={() => {
                    setOpenDate([]);
                  }}
                  onChange={(e) => {
                    setFieldValue("fromdate", e);
                  }}
                  format="DD-MM-YYYY"
                  defaultValue={null}
                  value={values.fromdate}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      onClick: () => {
                        setOpenDate(["from"]);
                      },
                      onKeyDown: (e) => {
                        e.preventDefault();
                      },
                      onDragStart: (e) => {
                        e.preventDefault();
                      },
                    },
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item lg={2} md={4} sm={6} xs={12} className="dropbr8">
              <Typography className={classes.title}>To Date</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  disableFuture
                  open={OpenDate?.includes("to")}
                  minDate={dayjs(values.fromdate).add(1, "day")}
                  onOpen={() => {
                    setOpenDate(["to"]);
                  }}
                  onClose={() => {
                    setOpenDate([]);
                  }}
                  onChange={(e) => {
                    setFieldValue("todate", e);
                  }}
                  format="DD-MM-YYYY"
                  value={values.todate}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      onClick: () => {
                        setOpenDate(["to"]);
                      },
                      onKeyDown: (e) => {
                        e.preventDefault();
                      },
                      onDragStart: (e) => {
                        e.preventDefault();
                      },
                    },
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item lg={2} md={4} sm={6} xs={12}>
              <TextInputBox
                title="Version"
                textLength={50}
                customInputProps={{
                  onKeyDown: (e) => handleTableSearch(e.key),
                }}
                name="version"
                placeHolder="Search version"
                value={values.version}
                onChangeText={handleChange("version")}
              />
            </Grid>
          </Grid>
          <div className="my-2">
            <SearchAndClearbutton
              handleTableSearch={handleTableSearch}
              handleTableSearchClear={handleTableSearchClear}
              disabled={!isFormDirty(initialValues, values)}
            />
          </div>
        </div>
      </AccordionContent>
      <div className={`table-responsive ${classes.AllTable}`}>
        <Box>
          <TableContainer>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>S.No</TableCell>
                  <TableCell>Organization Name</TableCell>
                  <TableCell>Firmware Name</TableCell>
                  <TableCell>Model Name</TableCell>
                  <TableCell>Version</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {uploadFirmwareList?.items?.length === 0 ? (
                  <>
                    {noDataLoader ? (
                      <TableRow>
                        <TableCell className={classes.noData} colSpan={6}>
                          <NodataLoader />
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableRow>
                        <TableCell colSpan={6}>
                          <div className={classes.nodatafound}>
                            <img
                              src={Inbox}
                              alt="inbox"
                              width={100}
                              height={100}
                            />
                            <p>No data found</p>
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                ) : (
                  <>
                    {noDataLoader ? (
                      <TableRowsLoader columnNum={6} rowsNum={10} />
                    ) : uploadFirmwareList?.items?.length === 0 ||
                      !uploadFirmwareList ? (
                      <TableRow>
                        <TableCell colSpan={7}>
                          <div className={classes.nodatafound}>
                            <img
                              src={Inbox}
                              alt="inbox"
                              width={100}
                              height={100}
                            />
                            <p>No data found</p>
                          </div>
                        </TableCell>
                      </TableRow>
                    ) : (
                      uploadFirmwareList?.items?.map((device, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            {(uploadFirmwareList?.page - 1) *
                              uploadFirmwareList?.size +
                              index +
                              1}
                          </TableCell>
                          <TableCell>
                            {device.organization_name
                              ? device.organization_name
                              : "-"}
                          </TableCell>
                          <TableCell>
                            {device.firmware_name ? device.firmware_name : "-"}
                          </TableCell>
                          <TableCell>
                            {device.model_name ? device.model_name : "-"}
                          </TableCell>
                          <TableCell>
                            {device.version === null ? "-" : device.version}
                          </TableCell>
                          <TableCell>
                            <div className={classes.icons}>
                              <Tooltip title="View" placement="top">
                                <img
                                  src={view}
                                  alt="View"
                                  onClick={() =>
                                    setisShowAssignFirmWareModal((prev) => {
                                      return {
                                        ...prev,
                                        modal: true,
                                        data: device,
                                      };
                                    })
                                  }
                                />
                              </Tooltip>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </div>
      {uploadFirmwareList?.items?.length ? (
        <CustomPaginationwithdropdownpagination
          onChangePage={(page: number) => {
            handleGetFirmWareTableList(page, pageSize);
            setPage(page);
          }}
          onChangePagerow={(PageSize: number) => {
            if (page * PageSize > uploadFirmwareList?.totalCount) {
              handleGetFirmWareTableList(1, PageSize);
              setPageSize(PageSize);
              return;
            }
            handleGetFirmWareTableList(page, PageSize);
            setPageSize(PageSize);
          }}
          count={uploadFirmwareList?.totalCount}
          pages={page}
          pageCount={uploadFirmwareList?.total_page}
          handleFunction={(selected: number) => {
            setPage(selected);
            handleGetFirmWareTableList(selected, pageSize);
          }}
          labelRowsPerPage={"Devices Per Page"}
          rowsperpage={pageSize}
          totalData={uploadFirmwareList}
        />
      ) : null}
    </>
  );
});

export default UploadFirmwareManagement;
