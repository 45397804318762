import { AxiosError } from "axios";
import { toast } from "react-hot-toast";
import { getCatchMsgType } from "./UtilTypes";

/**
 * Retrieves the error message from the given error object.
 * @param {getCatchMsgType} error - The error object.
 * @returns The error message.
 */
export function getCatchMsg(error: getCatchMsgType) {
  if (error?.response?.data?.detail) {
    if (Array.isArray(error?.response?.data?.detail)) {
      toast.error(error?.response?.data?.detail?.[0]?.msg);
    } else {
      toast.error(error?.response?.data?.detail?.msg);
    }
  } else if (error.response) {
    if (error.response.status === 404) {
      toast.error("The requested resource does not exist or has been deleted");
    } else if (error.response.status === 401) {
      toast.error("Please login to access this resource!");
    } else if (error.response.status === 500) {
      toast.error("Internal Server Error !");
    } else {
      toast.error("An error occurred");
    }
  } else if (error.request) {
    toast.error("Unable to connect to the server !");
  } else {
    toast.error("Something went wrong!");
  }
}
export const getFileName = (url: string) => {
  let name = url.split("/").pop();
  return name;
};
export const getTrimString = (str: string, length: number = 20) => {
  if (str) {
    return str.length > length ? str.substring(0, length) + "..." : str;
  }
  return;
};
/**
 * Converts a given number of seconds into a formatted time string.
 * @param {number} seconds - The number of seconds to convert.
 * @returns {string} - The formatted time string in the format "X hours X minutes X seconds".
 */
export function getTimeFormat(seconds: number) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const hoursText = hours > 0 ? `${hours} hour${hours > 1 ? "s" : ""}` : "";
  const minutesText =
    minutes > 0 ? `${minutes} minute${minutes > 1 ? "s" : ""}` : "";
  const secondsText =
    remainingSeconds > 0
      ? `${remainingSeconds} second${remainingSeconds > 1 ? "s" : ""}`
      : "";

  const parts = [hoursText, minutesText, secondsText].filter(Boolean);
  return parts.join(" ");
}

export const year = [
  "Januray",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const downloadUrl = (url: string) => {
  const inputelement = document.createElement("a");
  inputelement.href = url;
  inputelement.click();
};

export const monthOrder = {
  January: 1,
  February: 2,
  March: 3,
  April: 4,
  May: 5,
  June: 6,
  July: 7,
  August: 8,
  September: 9,
  October: 10,
  November: 11,
  December: 12,
};

export const isFormDirty = (initialValues: any, values: any) => {
  return !Object.keys(initialValues).every(
    // @ts-ignore
    (key: string) =>
      JSON.stringify(initialValues[key]) === JSON.stringify(values[key])
  );
};
