import React, { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { toast } from "react-hot-toast";
import { useFormik } from "formik";
import delete_icon from "../../../Assets/Icons/Svg/delete.svg";
import view from "../../../Assets/Icons/Png/view.png";
import { Grid, makeStyles } from "@material-ui/core";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList } from "@mui/lab";
import Inbox from "../../../Assets/Images/Png/noData.png";
import edit from "../../../Assets/Icons/Svg/edit.svg";
import AllQuestionIcon from "../../../Assets/Icons/Svg/AllquestionsIcon.svg";
import {
  ListDeviceBoardBatch,
  activeInactiveDeviceBoardBatch,
  DeleteDeviceBatch,
  modelManagementTableList,
  getModelDropDown,
  questionDropDown,
  DeleteNewModel,
} from "../../../Services/Services";
import { useToken } from "../../../Utility/StoreData";
import { getCatchMsg, isFormDirty } from "../../../Utility/GeneralUtils";
import PageHeader from "../../MangementLayoutHeader/PageHeader";
import {
  Loader,
  NodataLoader,
  TextInputBox,
  GlobalModal,
  TableRowsLoader,
  CustomSwitch,
  CustomDropDown,
  CustomPaginationwithdropdownpagination,
} from "../../../Components/index";
import {
  DeviceListItemProps,
  listDeviceBoardBatchDataItemsProps,
  listDeviceBoardBatchProps,
  modelTableListApiProps,
  modelTableListDataItemsProps,
} from "./managementTypes";
import classes from "./Management.module.css";
import { AddDeviceBatch, AddModel, ConfirmationModal } from "../../../Modals";
import SearchAndClearbutton from "../../../Components/SearchAndClearbutton";
import {
  device,
  device_batch,
  model,
  muiStyles,
} from "../../../Utility/Constants";
import { useNavigate, useLocation } from "react-router-dom";
import DeviceBatch from "./DeviceBatch";
import { openLoader } from "../../../Store/Reducers/LoaderSlice";
import { useDispatch } from "react-redux";
import AccordionContent from "../../../Components/AccordionContent";

const useStyles = makeStyles(() => ({
  switchContainer: {
    marginRight: "10px",
    display: "flex",
    alignItems: "center",
  },
}));

function DeviceManagement() {
  const styles = muiStyles();
  const style = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = useLocation();
  const [tableHead, setTableHead] = useState(device);
  const [Switches, setSwitches] = useState<boolean[]>([]);
  const [showFilterOption, setShowFilterOption] = useState(false);
  const [isShowModal, setIsShowModal] = useState<any>({
    status: false,
    data: null,
    viewstatus: false,
    viewid: "",
    linkStatus: false,
    id: null,
    viewdata: null,
  });
  const [deleteModal, setdeleteModal] = useState({
    modal: false,
    id: "",
  });

  const [noDataLoader, setnoDataLoader] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [questionList, setquestionList] = useState();
  const [listBatch, setlistBatch] = useState();
  const [listDeviceBoardBatch, setListDeviceBoardBatch] =
    useState<listDeviceBoardBatchProps | null>(null);
  const token = useToken();
  const [modelTableList, setModelList] =
    useState<modelTableListApiProps | null>(null);
  const { values, handleChange, resetForm, setFieldValue, initialValues } =
    useFormik({
      initialValues: {
        model_no: "",
        model_id: "",
        batchId: "",
        questionId: "",
        questionName: "",
        deviceBatchName: "",
        firmwareId: "",
        firmWareName: "",
        deviceId: "",
      },
      onSubmit: () => {},
    });

  const PartAndPcbManagementSubHead = [
    {
      id: 21,
      name: model,
      pathname: "active",
    },
    {
      id: 22,
      name: device_batch,
      pathname: "previos",
    },
    {
      id: 23,
      name: device,
      pathname: "previos",
    },
  ];

  const handleClick = (param: string) => {
    setIsShowModal((prev: any) => {
      return {
        ...prev,
        status: false,
      };
    });
    setShowFilterOption(false);
    resetForm();
    setTableHead(param);
  };

  const handleTableSearch = (data: string) => {
    if (data === "Enter") {
      setPage(1);
      if (tableHead === device_batch) {
        handleGetListOfDeviceBatch();
      } else {
        handleGetListOfModel();
      }
    }
  };

  const handleTableSearchClear = () => {
    setPage(1);
    resetForm();
    if (tableHead === device_batch) {
      handleGetListOfDeviceBatch(undefined, undefined, true);
    } else {
      handleGetListOfModel(undefined, undefined, true);
    }
  };

  useEffect(() => {
    if (token) {
      if (tableHead === device_batch) {
        handleGetListOfDeviceBatch();
      } else if (tableHead === model) {
        handleGetListOfModel();
      }
    }
  }, [token, tableHead, values.model_id, values.questionId]);

  useEffect(() => {
    if (tableHead === model) {
      handleGetBatchList();
      handlegetQuestionlist();
    }
  }, [tableHead]);

  useEffect(() => {
    if (state?.tab === "Model") {
      setTableHead(model);
    }
  }, [state]);

  const handlegetQuestionlist = () => {
    questionDropDown()
      .then((response) => {
        if (response?.data?.status === 1) {
          setquestionList(response?.data?.data);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      });
  };
  // const handlelistofDevice = (page = 1, size = 10, resetValue?: boolean) => {
  //   setnoDataLoader(true);
  //   let formData = new FormData();
  //   if (orgid) {
  //     formData.append("organizationId", orgid.toString());
  //   }
  //   formData.append("macAddress", resetValue ? "" : values.mac_address);
  //   formData.append("imeiNumber", resetValue ? "" : values.imei_number);
  //   formData.append("device_code", resetValue ? "" : values.devicecode);
  //   formData.append(
  //     "organization_name",
  //     resetValue ? "" : values.Organizationname
  //   );
  //   formData.append("batch_version", resetValue ? "" : values.Batchversion);
  //   if (state?.devicedate) {
  //     formData.append("assignedDate", state?.devicedate);
  //   }
  //   listDeviceMachine(page, size, formData)
  //     .then((response) => {
  //       setPage(response?.data?.data?.page);
  //       if (response.data.status === 1) {
  //         setDeviceList(response.data);
  //         handleActiveStatus(response?.data?.data?.items);
  //       }
  //     })
  //     .catch((error) => {
  //       getCatchMsg(error);
  //     })
  //     .finally(() => {
  //       setnoDataLoader(false);
  //     });
  // };

  const handleGetListOfModel = (page = 1, size = 10, resetValue?: boolean) => {
    setnoDataLoader(true);
    let formData = new FormData();
    formData.append("modelId", resetValue ? "" : values?.model_id);
    formData.append("QuestionId", resetValue ? "" : values?.questionId);
    modelManagementTableList(page, size, formData)
      .then((response) => {
        setPage(response?.data?.data?.page);
        if (response?.data?.status === 1) {
          setModelList(response?.data);
        }
      })
      .catch((error) => {
        getCatchMsg(error);
      })
      .finally(() => {
        setnoDataLoader(false);
      });
  };

  const handleGetListOfDeviceBatch = (
    page = 1,
    size = 10,
    resetValue?: boolean,
    firmWareId?: string
  ) => {
    let formData = new FormData();
    if (firmWareId) {
      formData.append("firmwareId", resetValue ? "" : firmWareId);
    }
    formData.append(
      "deviceBatchName",
      resetValue ? "" : values.deviceBatchName
    );

    ListDeviceBoardBatch(page, size, formData)
      .then((response) => {
        setPage(response?.data?.data?.page);
        const { items } = response?.data?.data;
        setListDeviceBoardBatch(response?.data);
        if (items?.length > 0) {
          handleActiveStatus(response?.data?.data?.items);
        }
      })
      .catch((error) => {
        getCatchMsg(error);
      })
      .finally(() => {
        setnoDataLoader(false);
      });
  };

  const handleGetBatchList = () => {
    getModelDropDown()
      .then((response) => {
        if (response?.data?.status === 1) {
          setlistBatch(response?.data?.data);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      });
  };
  const handleActiveStatus = (data: DeviceListItemProps[]) => {
    if (data && data?.length > 0) {
      const statusData = data?.map((ele, index: number) => {
        setSwitches((pre) => {
          let temp = [...pre];
          temp[index] = ele?.activeStatus === 1 ? true : false;
          return temp;
        });
        return ele;
      });
    }
  };

  const handleDeleteDeviceBatch = () => {
    dispatch(openLoader(true));
    let formData = new FormData();
    formData.append("deviceBatchId", deleteModal.id);
    DeleteDeviceBatch(formData)
      .then((res) => {
        if (res?.data?.status === 1) {
          handleGetListOfDeviceBatch(listDeviceBoardBatch?.data?.page);
          toast.success(res?.data?.msg);
        } else if (res?.data?.status === 0) {
          toast.error(res?.data?.msg);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        dispatch(openLoader(false));
        setdeleteModal((prev) => {
          return {
            ...prev,
            modal: false,
          };
        });
      });
  };

  const handleDeleteModel = () => {
    let formData = new FormData();
    dispatch(openLoader(true));
    formData.append("modelId", deleteModal.id);
    DeleteNewModel(formData)
      .then((response) => {
        if (response?.data?.status === 1) {
          handleGetListOfModel(modelTableList?.data?.page);
          toast.success(response?.data?.msg);
        }
      })
      .catch((err) => getCatchMsg(err))
      .finally(() => {
        dispatch(openLoader(false));
        setdeleteModal((prev) => {
          return {
            ...prev,
            modal: false,
          };
        });
      });
  };

  const handleActiveAndInactiveDeviceBatch = (batchId: number, e: boolean) => {
    let formData = new FormData();
    if (batchId) {
      //@ts-ignore
      formData.append("deviceBatchId", batchId);
      if (e == true) {
        //@ts-ignore
        formData.append("activeStatus", 1);
      }
      if (e == false) {
        //@ts-ignore
        formData.append("activeStatus", 2);
      }

      activeInactiveDeviceBoardBatch(formData)
        .then((response) => {
          if (response?.data?.status === 1) {
            toast.success(response?.data?.msg);
            handleGetListOfDeviceBatch(page);
          }
        })
        .catch((err) => getCatchMsg(err));
    }
  };

  return (
    <>
      <PageHeader
        modal={() => {
          setIsShowModal((prev: any) => {
            return {
              ...prev,
              status: true,
              data: null,
              viewstatus: false,
              viewid: "",
              linkStatus: false,
              id: null,
              viewdata: null,
            };
          });
        }}
        Btntitle={`Add ${tableHead}`}
        heading="Device Management"
        onFilterbutton={() => {
          setShowFilterOption((pre) => !pre);
        }}
      />

      {isShowModal?.status && tableHead === model && (
        <GlobalModal
          size="lg"
          modalStyle="globalModalmaxWidth logoutmodalminWidth"
          isVisible={isShowModal.status}
          setIsVisible={() => {
            setIsShowModal((prev: any) => {
              return {
                ...prev,
                status: true,
              };
            });
          }}
        >
          <AddModel
            onClose={() => {
              setIsShowModal((prev: any) => {
                return {
                  ...prev,
                  status: false,
                };
              });
            }}
            modelStatus={isShowModal?.modelStatus}
            editData={isShowModal.data || null}
            listApiCall={(value) => {
              if (value === "update") {
                handleGetListOfModel(page);
                return;
              }
              handleGetListOfModel();
            }}
            modalClose={() => {
              setIsShowModal((prev: any) => {
                return {
                  ...prev,
                  status: false,
                };
              });
            }}
          />
        </GlobalModal>
      )}
      {isShowModal?.status && tableHead === device_batch && (
        <GlobalModal
          size="xl"
          modalStyle="globalmodalminWidth"
          isVisible={isShowModal.status}
          setIsVisible={() => {
            setIsShowModal((prev: any) => {
              return {
                ...prev,
                status: true,
              };
            });
          }}
        >
          <AddDeviceBatch
            onClose={() => {
              setIsShowModal((prev: any) => {
                return {
                  ...prev,
                  status: false,
                };
              });
            }}
            viewStatus={isShowModal?.viewstatus}
            id={isShowModal?.id}
            editData={isShowModal?.data}
            listModelApicall={(value) => {
              if (value === "update") {
                handleGetListOfDeviceBatch(page);
                return;
              }
              handleGetListOfDeviceBatch();
            }}
            modalClose={() => {
              setIsShowModal((prev: any) => {
                return {
                  ...prev,
                  status: false,
                };
              });
            }}
          />
        </GlobalModal>
      )}
      <GlobalModal
        isVisible={deleteModal.modal}
        setIsVisible={() => {
          setdeleteModal((prev) => {
            return {
              ...prev,
              modal: false,
            };
          });
        }}
      >
        <ConfirmationModal
          msg={`Are you sure that you want to permanently delete this ${
            tableHead === model ? "model." : "device."
          }`}
          onClose={() => {
            setdeleteModal((prev) => {
              return {
                ...prev,
                modal: false,
              };
            });
          }}
          onPositiveButtonPressed={() => {
            if (tableHead === model) {
              handleDeleteModel();
            } else {
              handleDeleteDeviceBatch();
            }
          }}
          onNegativeButtonPressed={() => {
            setdeleteModal((prev) => {
              return {
                ...prev,
                modal: false,
              };
            });
          }}
        />
      </GlobalModal>

      <Grid className={classes.tabContainer}>
        <div className={classes.tableChange}>
          {PartAndPcbManagementSubHead.map((ele) => {
            return (
              <>
                <TabContext value={tableHead}>
                  <Box>
                    <TabList
                      aria-label="lab API tabs example"
                      className={
                        ele.name === tableHead
                          ? `${styles.activeTab} ${styles.tabColor}`
                          : `${styles.tabColor}`
                      }
                    >
                      <Tab
                        label={ele.name}
                        value={ele.name}
                        disableRipple={true}
                        className={`${classes.tabContent} ${
                          ele.name === tableHead
                            ? `${classes.activeTab} activeTab`
                            : ""
                        }`}
                        onClick={() => {
                          handleClick(ele?.name);
                        }}
                      />
                    </TabList>
                  </Box>
                </TabContext>
              </>
            );
          })}
        </div>
      </Grid>
      {tableHead === model ? (
        <>
          <AccordionContent filterStat={showFilterOption}>
            <div className={classes.serachContainer}>
              <div className="row mb-3">
                <Grid item lg={2} md={4} sm={6} xs={12}>
                  <CustomDropDown
                    editName={values.model_no ? values.model_no : "--None--"}
                    items={listBatch}
                    title="Model"
                    onSelectedItem={(val, value) => {
                      setFieldValue(
                        "model_no",
                        value?.label ? value?.label : ""
                      );
                      setFieldValue("model_id", value?.id ? value?.id : "");
                    }}
                    fieldName="model_no"
                    anotherFieldName="model_id"
                    value={values.model_id}
                    disableClearable={!values.model_id}
                  />
                </Grid>
                <Grid item lg={2} md={4} sm={6} xs={12}>
                  <CustomDropDown
                    title="Question"
                    editName={
                      values.questionName ? values.questionName : "--None--"
                    }
                    items={questionList}
                    onSelectedItem={(val, value) => {
                      setFieldValue(
                        "questionName",
                        value?.label ? value?.label : ""
                      );
                      setFieldValue("questionId", value?.id ? value?.id : "");
                    }}
                    fieldName="questionName"
                    anotherFieldName="questionId"
                    value={values.questionId}
                    disableClearable={!values.questionId}
                  />
                </Grid>
              </div>
              {/* <SearchAndClearbutton
                handleTableSearch={handleTableSearch}
                handleTableSearchClear={handleTableSearchClear}
              /> */}
            </div>
          </AccordionContent>
          <div className={`table-responsive ${classes.AllTable}`}>
            <Box>
              <TableContainer>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell>S.No</TableCell>
                      <TableCell>Model Name</TableCell>
                      {/* <TableCell>Category Type</TableCell> */}
                      <TableCell>Question</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {modelTableList?.data?.items?.length === 0 ? (
                      <>
                        {noDataLoader ? (
                          <TableRow>
                            <TableCell className={classes.noData} colSpan={9}>
                              <NodataLoader />
                            </TableCell>
                          </TableRow>
                        ) : (
                          <TableRow>
                            <TableCell colSpan={9}>
                              <div className={classes.nodatafound}>
                                <img
                                  src={Inbox}
                                  alt="inbox"
                                  width={100}
                                  height={100}
                                />
                                <p>No data found</p>
                              </div>
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    ) : (
                      <>
                        {noDataLoader ? (
                          <TableRowsLoader columnNum={6} rowsNum={10} />
                        ) : modelTableList?.data?.items?.length === 0 ||
                          !modelTableList ? (
                          <TableRow>
                            <TableCell colSpan={9}>
                              <div className={classes.nodatafound}>
                                <img
                                  src={Inbox}
                                  alt="inbox"
                                  width={100}
                                  height={100}
                                />
                                <p>No data found</p>
                              </div>
                            </TableCell>
                          </TableRow>
                        ) : (
                          modelTableList?.data?.items?.map(
                            (
                              model: modelTableListDataItemsProps,
                              index: number
                            ) => (
                              <TableRow key={index}>
                                <TableCell>
                                  {(modelTableList?.data?.page - 1) *
                                    modelTableList?.data?.size +
                                    index +
                                    1}
                                </TableCell>
                                <TableCell>
                                  {model?.modelName === null
                                    ? "-"
                                    : model?.modelName}
                                </TableCell>
                                {/* <TableCell>
                                  {model?.subModelName === null
                                    ? "-"
                                    : model?.subModelName}
                                </TableCell> */}
                                <TableCell>
                                  {model?.questionMasterName === null
                                    ? "-"
                                    : model?.questionMasterName}
                                </TableCell>
                                <TableCell>
                                  <div className={classes.icons}>
                                    <Tooltip title="Edit" placement="top">
                                      <img
                                        src={edit}
                                        onClick={() => {
                                          setIsShowModal((prev: any) => {
                                            return {
                                              ...prev,
                                              status: true,
                                              data: model,
                                              viewstatus: false,
                                              viewid: "",
                                              linkStatus: false,
                                              id: null,
                                              viewdata: null,
                                            };
                                          });
                                        }}
                                        alt="Edit"
                                      />
                                    </Tooltip>
                                    <Tooltip title="Delete" placement="top">
                                      <img
                                        src={delete_icon}
                                        alt="Delete"
                                        onClick={() =>
                                          setdeleteModal((prev) => {
                                            return {
                                              ...prev,
                                              modal: true,
                                              id: model.id.toString(),
                                            };
                                          })
                                        }
                                      />
                                    </Tooltip>

                                    <Tooltip
                                      title="Question Set"
                                      placement="top"
                                    >
                                      <img
                                        style={{
                                          padding: "6px",
                                        }}
                                        src={AllQuestionIcon}
                                        alt="Question Set"
                                        onClick={() => {
                                          navigate(
                                            {
                                              pathname:
                                                "model_Management_details",
                                            },
                                            {
                                              state: model,
                                            }
                                          );
                                        }}
                                      />
                                    </Tooltip>
                                  </div>
                                </TableCell>
                              </TableRow>
                            )
                          )
                        )}
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </div>
          {modelTableList?.data?.items?.length ? (
            <CustomPaginationwithdropdownpagination
              onChangePage={(page: number) => {
                handleGetListOfModel(page, pageSize);
                setPage(page);
              }}
              onChangePagerow={(PageSize: number) => {
                if (page * PageSize > modelTableList?.data?.totalCount) {
                  handleGetListOfModel(1, PageSize);
                  setPageSize(PageSize);
                  return;
                }
                handleGetListOfModel(page, PageSize);
                setPageSize(PageSize);
              }}
              count={modelTableList?.data?.totalCount}
              pages={page}
              pageCount={modelTableList?.data?.total_page}
              handleFunction={(selected: number) => {
                setPage(selected);
                handleGetListOfModel(selected, pageSize);
              }}
              labelRowsPerPage={"Models Per Page"}
              rowsperpage={pageSize}
              totalData={modelTableList?.data}
            />
          ) : null}
        </>
      ) : tableHead === device_batch ? (
        <>
          {/* {showFilterOption ? (
           
          ) : null} */}
          <AccordionContent filterStat={showFilterOption}>
            <div className={classes.serachContainer}>
              <div className="row mb-3">
                <Grid item lg={2} md={4} sm={6} xs={12}>
                  <TextInputBox
                    title="Batch Name"
                    textLength={50}
                    customInputProps={{
                      onKeyDown: (e) => handleTableSearch(e.key),
                    }}
                    name="deviceBatchName"
                    placeHolder="Search batch name"
                    value={values?.deviceBatchName}
                    onChangeText={handleChange("deviceBatchName")}
                  />
                </Grid>
              </div>
              <SearchAndClearbutton
                handleTableSearch={handleTableSearch}
                handleTableSearchClear={handleTableSearchClear}
                disabled={!isFormDirty(initialValues, values)}
              />
            </div>
          </AccordionContent>
          <div className={`table-responsive ${classes.AllTable}`}>
            <Box>
              <TableContainer>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell>S.No</TableCell>
                      <TableCell>Batch name</TableCell>
                      <TableCell>Firmware name</TableCell>
                      <TableCell>Model Name</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {listDeviceBoardBatch?.data?.items?.length === 0 ? (
                      <>
                        {noDataLoader ? (
                          <TableRow>
                            <TableCell className={classes.noData} colSpan={9}>
                              <NodataLoader />
                            </TableCell>
                          </TableRow>
                        ) : (
                          <TableRow>
                            <TableCell colSpan={4}>
                              <div className={classes.nodatafound}>
                                <img
                                  src={Inbox}
                                  alt="inbox"
                                  width={100}
                                  height={100}
                                />
                                <p>No data found</p>
                              </div>
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    ) : (
                      <>
                        {noDataLoader ? (
                          <TableRowsLoader columnNum={6} rowsNum={10} />
                        ) : listDeviceBoardBatch?.data?.items?.length === 0 ||
                          !listDeviceBoardBatch ? (
                          <TableRow>
                            <TableCell colSpan={4}>
                              <div className={classes.nodatafound}>
                                <img
                                  src={Inbox}
                                  alt="inbox"
                                  width={100}
                                  height={100}
                                />
                                <p>No data found</p>
                              </div>
                            </TableCell>
                          </TableRow>
                        ) : (
                          listDeviceBoardBatch?.data?.items?.map(
                            (
                              boardDevicebatch: listDeviceBoardBatchDataItemsProps,
                              index: number
                            ) => (
                              <TableRow key={index}>
                                <TableCell>
                                  {(listDeviceBoardBatch?.data?.page - 1) *
                                    listDeviceBoardBatch?.data?.size +
                                    index +
                                    1}
                                </TableCell>
                                <TableCell>
                                  {boardDevicebatch?.deviceBatchName === null
                                    ? "-"
                                    : boardDevicebatch?.deviceBatchName}
                                </TableCell>
                                <TableCell>
                                  {boardDevicebatch?.firmwareName === null
                                    ? "-"
                                    : boardDevicebatch?.firmwareName}
                                </TableCell>
                                <TableCell>
                                  {boardDevicebatch?.modelName === null
                                    ? "-"
                                    : boardDevicebatch?.modelName}
                                </TableCell>

                                <TableCell>
                                  <div className={classes.icons}>
                                    <Grid className={style.switchContainer}>
                                      <CustomSwitch
                                        title1="Active"
                                        title2="inactive"
                                        status={Switches[index]}
                                        setCheck={(state) => {
                                          setSwitches((pre) => {
                                            let temp = [...pre];
                                            temp[index] = state;
                                            return temp;
                                          });
                                          handleActiveAndInactiveDeviceBatch(
                                            boardDevicebatch?.deviceBatchId,
                                            state
                                          );
                                        }}
                                      />
                                    </Grid>
                                    <Tooltip title="View" placement="top">
                                      <img
                                        src={view}
                                        alt="View"
                                        onClick={() => {
                                          setIsShowModal((prev: any) => {
                                            return {
                                              ...prev,
                                              status: true,
                                              data: null,
                                              viewstatus: true,
                                              id: boardDevicebatch?.deviceBatchId,
                                              viewid: "",
                                              linkStatus: false,
                                              viewdata: null,
                                            };
                                          });
                                        }}
                                      />
                                    </Tooltip>
                                    <Tooltip title="Delete" placement="top">
                                      <img
                                        src={delete_icon}
                                        alt="Delete"
                                        onClick={() =>
                                          setdeleteModal((prev: any) => {
                                            return {
                                              ...prev,
                                              modal: true,
                                              id: boardDevicebatch?.deviceBatchId,
                                            };
                                          })
                                        }
                                      />
                                    </Tooltip>
                                  </div>
                                </TableCell>
                              </TableRow>
                            )
                          )
                        )}
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </div>
          {listDeviceBoardBatch?.data?.items?.length ? (
            <CustomPaginationwithdropdownpagination
              onChangePage={(page: number) => {
                handleGetListOfDeviceBatch(page, pageSize);
                setPage(page);
              }}
              onChangePagerow={(PageSize: number) => {
                if (page * PageSize > listDeviceBoardBatch?.data?.totalCount) {
                  handleGetListOfDeviceBatch(1, PageSize);
                  setPageSize(PageSize);
                  return;
                }
                handleGetListOfDeviceBatch(page, PageSize);
                setPageSize(PageSize);
              }}
              count={listDeviceBoardBatch?.data?.totalCount}
              pages={page}
              pageCount={listDeviceBoardBatch?.data?.total_page}
              handleFunction={(selected: number) => {
                setPage(selected);
                handleGetListOfDeviceBatch(selected, pageSize);
              }}
              labelRowsPerPage={"Devices Per Page"}
              rowsperpage={pageSize}
              totalData={listDeviceBoardBatch?.data}
            />
          ) : null}
        </>
      ) : (
        <DeviceBatch
          addModalStatus={isShowModal}
          FilterOption={showFilterOption}
          head={tableHead}
          DeviceState={state}
          from="device"
        />
      )}
    </>
  );
}

export default DeviceManagement;
