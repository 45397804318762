import React, { useState } from "react";
import { toast } from "react-hot-toast";
import * as Yup from "yup";
import { useFormik } from "formik";

import { CreateCategory, UpdateCategory } from "../Services/Services";
import { getCatchMsg } from "../Utility/GeneralUtils";
import { Loader, TextInputBox, CustomButton } from "../Components/index";
import classes from "./Modal.module.css";
import ModalHeader from "./ModalHeader";
import { AddBoardCategoryProps } from "./Types";
import moment from "moment";
import { openLoader } from "../Store/Reducers/LoaderSlice";
import { useDispatch } from "react-redux";

const validationSchema = Yup.object({
  Category_name: Yup.string()
    .required("Category name is required")
    .trim("Remove leading and trailing spaces")
    .strict(true),
});

export default function AddBoardCategory({
  onClose,
  editData,
  listBoardCategoryApicall,
  modalClose,
  getview,
}: AddBoardCategoryProps) {
  const dispatch = useDispatch();

  const { handleChange, values, handleSubmit, errors, touched } = useFormik({
    initialValues: {
      Category_name: editData?.categoryName || "",
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      if (editData) {
        handleUpdateCategory();
      } else {
        handleCreateCategory();
      }
    },
  });

  const handleCreateCategory = () => {
    dispatch(openLoader(true));
    let formData = new FormData();
    formData.append("name", values.Category_name);
    CreateCategory(formData)
      .then((res) => {
        if (res.data.status === 1) {
          listBoardCategoryApicall("add");
          modalClose();
          toast.success(res.data.msg);
        } else {
          toast.error(res.data.msg);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        dispatch(openLoader(false));
      });
  };

  const handleUpdateCategory = () => {
    dispatch(openLoader(true));
    let formData = new FormData();
    formData.append("name", values.Category_name);
    if (editData) {
      formData.append("categoryId", editData?.categoryId.toString());
    }
    UpdateCategory(formData)
      .then((res) => {
        if (res.data.status === 1) {
          listBoardCategoryApicall("update");
          modalClose();
          toast.success(res.data.msg);
        } else {
          toast.error(res.data.msg);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        dispatch(openLoader(false));
      });
  };

  return (
    <>
      <ModalHeader
        onClose={onClose}
        heading={
          getview
            ? "Board part category"
            : editData
              ? "Edit part category"
              : "Add part category"
        }
      />
      {getview ? (
        <div className={classes.add_device}>
          <div className={classes.viewContainer}>
            <div className={classes.viewContent}>
              <p>Category Name</p>
              <strong>:</strong>
              <span>{editData.categoryName ? editData.categoryName : "-"}</span>
            </div>
            <div className={classes.viewContent}>
              <p>Created By</p>
              <strong>:</strong>
              <span>{editData.createdBy ? editData.createdBy : "-"}</span>
            </div>
            <div className={classes.viewContent}>
              <p>Created Date</p>
              <strong>:</strong>
              <span>
                {editData.createdDate
                  ? moment(editData.createdDate).format("YYYY-MM-DD hh:mm:ss")
                  : "-"}
              </span>
            </div>
          </div>
        </div>
      ) : (
        <div className={classes.add_device}>
          <div className="col-lg-12 mb-3">
            <TextInputBox
              requiredText="*"
              textLength={30}
              placeHolder="Enter category name"
              title="Category name"
              name="Category_name"
              onChangeText={handleChange("Category_name")}
              value={values.Category_name}
              errorText={
                touched.Category_name && errors.Category_name
                  ? errors.Category_name
                  : ""
              }
            />
          </div>
          {editData ? (
            <>
              <div className="row my-4">
                <div className="col-lg-6 col-12 col-md-6 mb-4">
                  <CustomButton title="Update" onButtonPress={handleSubmit} />
                </div>
                <div className=" col-lg-6 col-12 col-md-6 ">
                  <CustomButton
                    title="Cancel"
                    customButtonStyle={{
                      backgroundColor: "rgba(0, 0, 0, 0.08)",
                      color: "black",
                    }}
                    onButtonPress={() => modalClose()}
                  />
                </div>
              </div>
            </>
          ) : (
            <div className="col-lg-3 col-12 col-md-6 my-3">
              <CustomButton title="Submit" onButtonPress={handleSubmit} />
            </div>
          )}
        </div>
      )}
    </>
  );
}
