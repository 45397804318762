import { useFormik } from "formik";
import * as Yup from "yup";

import classes from "../../../Views/HomeScreens/UserProfile/AdminProfile.module.css";
import { CustomButton, TextInputBox } from "../../../Components/index";

export default function ChangePassword() {
  const validationSchema = Yup.object().shape({
    old_password: Yup.string().required("Old password is required"),
    new_password: Yup.string().required("New password is required"),
    confirm_password: Yup.string()
      .required("Confirm password is required")
      .test("match password", "Password must match", function (value) {
        const { new_password } = this.parent;
        if (new_password === value) {
          return true;
        }
        return false;
      }),
  });
  const { handleChange, handleSubmit, errors, touched, values, resetForm } =
    useFormik({
      initialValues: {
        old_password: "",
        new_password: "",
        confirm_password: "",
      },
      validationSchema: validationSchema,
      onSubmit: () => {
        handleGetChangePassword();
      },
    });
  const handleGetChangePassword = () => {
    resetForm();
  };
  return (
    <>
      <div className={`pe-sm-2 ps-sm-4 pt-4 ${classes.container}`}>
        <div className={classes.head}>
          <div className={classes.line}></div>
          <div className={classes.heading}>Change Password</div>
        </div>
        <div className={`${classes.content} ${classes.containerLayer}`}>
          <div className="row">
            <div className="col-lg-6 mb-3">
              <TextInputBox
                value={values.old_password}
                textLength={50}
                placeHolder="Enter Current Password"
                onChangeText={handleChange("old_password")}
                requiredText="*"
                isSecure={true}
                title="Current Password"
                errorText={
                  errors.old_password && touched.old_password
                    ? errors.old_password
                    : ""
                }
              />
            </div>
            <div className="col-lg-6 mb-3">
              <TextInputBox
                value={values.new_password}
                onChangeText={handleChange("new_password")}
                textLength={50}
                placeHolder="Enter New Password"
                requiredText="*"
                isSecure={true}
                title="New Pasword"
                errorText={
                  errors.new_password && touched.new_password
                    ? errors.new_password
                    : ""
                }
              />
            </div>
            <div className="col-lg-6 mb-3">
              <TextInputBox
                value={values.confirm_password}
                requiredText="*"
                onChangeText={handleChange("confirm_password")}
                placeHolder="Enter Confirm Password"
                isSecure={true}
                title="Confirm Pasword"
                textLength={50}
                errorText={
                  errors.confirm_password && touched.confirm_password
                    ? errors.confirm_password
                    : ""
                }
              />
            </div>
            <div className="row">
              <div className={`col-lg-2 mb-3 ${classes.savebtn}`}>
                <CustomButton onButtonPress={handleSubmit} title="Save" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
