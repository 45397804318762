import React, { useEffect, useState } from "react";
import { AddPartNumberQuestionProps } from "./Types";
import {
  addnewPartNumberQuery,
  categarizationDropDown,
  categarizationFieldDropDown,
} from "../Services/Services";
import { getCatchMsg } from "../Utility/GeneralUtils";
import {
  CustomButton,
  CustomDropDown,
  Loader,
  TextInputBox,
} from "../Components";
import ModalHeader from "./ModalHeader";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import classes from "./Modal.module.css";
import CustomDatePicker from "../Components/CustomDatePicker";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { openLoader } from "../Store/Reducers/LoaderSlice";

function AddPartNumberQuestion({
  onClose,
  modalClose,
  listApiCall,
  viewStatus,
  editData,
}: AddPartNumberQuestionProps) {
  const dispatch = useDispatch();

  const { pathname } = useLocation();
  const [categoryList, setCategoryList] = useState();
  const [catgarizationField, setCatgarizationField] = useState();
  const [categarizationName, setCategarizationName] = useState(
    editData?.categorization_name ? editData?.categorization_name : null
  );
  const validation = Yup.object({
    categoryId: Yup.string().required("* Categarization is required"),
    otherName:
      categarizationName === "Other" || categarizationName === "Qty"
        ? Yup.string()
            .required("* Other is required")
            .trim("Remove leading and trailing spaces")
            .strict(true)
        : categarizationName === "Date"
        ? Yup.string().required("* Date is required")
        : Yup.string(),
    categoryFieldId:
      categarizationName === "Other" ||
      categarizationName === "Qty" ||
      categarizationName === "Date"
        ? Yup.string()
        : Yup.string().required("* Categarization field is required"),
  });
  const { values, handleChange, handleSubmit, setFieldValue, errors, touched } =
    useFormik({
      initialValues: {
        queryDataId: editData?.id ? editData?.id : "",
        categoryId: editData?.categorization_id
          ? editData?.categorization_id
          : "",
        categoryName: editData?.categorization_name
          ? editData?.categorization_name
          : "",
        categoryFieldId: editData?.categorization_field_id
          ? editData?.categorization_field_id
          : "",
        categoryFieldName: editData?.categorization_field_name
          ? editData?.categorization_field_name
          : "",
        otherName: editData?.categorization_field_name
          ? editData?.categorization_field_name
          : "",
      },
      validationSchema: validation,
      onSubmit: () => {
        handleCreateCategory(values);
      },
    });

  const handleCreateCategory = (data: any) => {
    dispatch(openLoader(true));
    let formData = new FormData();
    formData.append("queryDataId", data?.queryDataId);
    formData.append("categorization_id", data?.categoryId);
    if (
      (categarizationName && categarizationName !== "Other") ||
      categarizationName !== "Qty"
    )
      formData.append("categorization_field_id", data?.categoryFieldId);
    if (
      values?.categoryName === "Other" ||
      values?.categoryName === "Qty" ||
      values?.categoryName === "Date"
    )
      formData.append("otherName", data?.otherName);
    addnewPartNumberQuery(formData)
      .then((res) => {
        if (res.data.status === 1) {
          listApiCall("add");
          modalClose();
          setCategarizationName(null);
          toast.success(res.data.msg);
        } else {
          toast.error(res.data.msg);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        dispatch(openLoader(false));
      });
  };

  const handleGetListCategory = () => {
    categarizationDropDown()
      .then((response) => {
        if (response?.data?.status === 1) {
          setCategoryList(response?.data?.data);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      });
  };

  const hendleGetCatgarizationFiledDropDown = (id: string) => {
    let formData = new FormData();
    formData.append("categorizationId", id ? id.toString() : "");
    categarizationFieldDropDown(formData)
      .then((response) => {
        if (response?.data?.status === 1) {
          setCatgarizationField(response?.data?.data);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      });
  };

  useEffect(() => {
    handleGetListCategory();
  }, []);

  useEffect(() => {
    if (editData) {
      hendleGetCatgarizationFiledDropDown(
        editData?.categorization_id?.toString()
      );
    }
  }, [editData]);

  return (
    <>
      <ModalHeader
        onClose={onClose}
        heading={
          viewStatus
            ? `View ${
                pathname ===
                "/dashboard/management/model_management/model_Management_details"
                  ? "Model"
                  : ""
              }`
            : `Edit ${
                pathname ===
                "/dashboard/management/model_management/model_Management_details"
                  ? "Model"
                  : "Answers"
              }`
        }
      />
      <div className={`row ${classes.AddPartNumberQuestion}`}>
        {viewStatus && editData ? (
          <div className={`mb-3 ${classes.viewContainer}`}>
            <div className={classes.viewContent}>
              <p
                className="mb-3"
                style={{
                  width: "100%",
                  textAlign: "center",
                  fontSize: "20px",
                  fontFamily: "var(--fontMedium)",
                }}
              >
                {editData.questionSetName ? editData.questionSetName : "-"}
              </p>
            </div>
            <div className={classes.viewContent}>
              <p
                style={{
                  width: "220px",
                }}
              >
                Categorization Name
              </p>
              <strong>:</strong>
              <span>
                {editData.categorization_name
                  ? editData.categorization_name
                  : "-"}
              </span>
            </div>
            <div className={classes.viewContent}>
              <p
                style={{
                  width: "220px",
                }}
              >
                Categorization Field Name
              </p>
              <strong>:</strong>
              <span>
                {editData.categorization_field_name
                  ? editData.categorization_field_name
                  : "-"}
              </span>
            </div>
          </div>
        ) : (
          <>
            <div className="col-12 mb-3">
              <CustomDropDown
                editName={values?.categoryName}
                items={categoryList}
                required={"*"}
                title="Answer Set"
                onSelectedItem={(val, value) => {
                  setCategarizationName(value?.label);
                  if (value?.id) {
                    hendleGetCatgarizationFiledDropDown(value?.id);
                  }
                  setFieldValue("otherName", "");
                  setFieldValue("categoryFieldName", "");
                  setFieldValue("categoryFieldId", "");
                  setFieldValue("categoryName", value?.label);
                  setFieldValue("categoryId", value?.id);
                }}
                fieldName={"categoryName"}
                anotherFieldName={"categoryId"}
                value={values?.categoryId}
                errorText={
                  errors?.categoryId && touched.categoryId
                    ? errors?.categoryId
                    : ""
                }
              />
            </div>
            <div className="col-12 mb-3">
              {values?.categoryName === "Other" ||
              values?.categoryName === "Qty" ? (
                <TextInputBox
                  requiredText={"*"}
                  textLength={50}
                  placeHolder="Enter name"
                  title="Other Name"
                  name="otherName"
                  onChangeText={(val) => {
                    if (values.categoryId === 1) {
                      const formattedText = val.replace(/[^0-9]/g, "");
                      handleChange("otherName")(formattedText);
                    } else {
                      handleChange("otherName")(val);
                    }
                  }}
                  value={values.otherName}
                  errorText={
                    errors?.otherName && touched.otherName
                      ? errors?.otherName
                      : ""
                  }
                />
              ) : values?.categoryName === "Date" ? (
                <CustomDatePicker
                  title="Date"
                  required="*"
                  selectedDate={values.otherName}
                  onSelectDate={(val) => {
                    setFieldValue(
                      "otherName",
                      moment(val).format("YYYY-MM-DD")
                    );
                  }}
                  errorText={
                    errors?.otherName && touched.otherName
                      ? errors?.otherName
                      : ""
                  }
                />
              ) : (
                <CustomDropDown
                  editName={values?.categoryFieldName}
                  items={catgarizationField}
                  required={"*"}
                  title="Option"
                  onSelectedItem={(val, value) => {
                    // hendleGetCatgarizationFiledDropDown(value?.id);
                    setFieldValue("categoryFieldName", value?.label);
                    setFieldValue("categoryFieldId", value?.id);
                  }}
                  fieldName={"categoryFieldName"}
                  anotherFieldName={"categoryFieldId"}
                  value={values?.categoryFieldId}
                  errorText={
                    errors?.categoryFieldId && touched.categoryFieldId
                      ? errors?.categoryFieldId
                      : ""
                  }
                />
              )}
            </div>
            <div className="col-lg-12 col-xl-6 my-4">
              <CustomButton title="Update" onButtonPress={handleSubmit} />
            </div>

            <div className="col-lg-12 col-xl-6 my-4">
              <CustomButton
                title="Cancel"
                customButtonStyle={{
                  backgroundColor: "rgba(0, 0, 0, 0.08)",
                  color: "black",
                }}
                onButtonPress={() => modalClose()}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default AddPartNumberQuestion;
