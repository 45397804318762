import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import * as Yup from "yup";
import { useFormik } from "formik";

import {
  createServer,
  viewServer,
  updateServer,
  projectdropdownlist,
} from "../Services/Services";
import { NUMBER, muiStyles } from "../Utility/Constants";
import { getCatchMsg } from "../Utility/GeneralUtils";
import { Loader, TextInputBox, CustomButton } from "../Components/index";
import ModalHeader from "./ModalHeader";
import classes from "./Modal.module.css";
import { createServerModalType, serverDetailsType } from "./Types";
import UpdateDeleteActions from "../Components/UpdateDeleteActions";
import { useDispatch } from "react-redux";
import { openLoader } from "../Store/Reducers/LoaderSlice";
import { Autocomplete, Paper, TextField } from "@mui/material";

const validationSchema = Yup.object({
  Base_Url: Yup.string()
    .url("Please enter a valid url")
    .required("* Base Url is required")
    .test(
      "trim spaces",
      "Remove leading and trailing spaces",
      function (value) {
        if (value?.trim()?.length === 0) {
          return false;
        }
        return true;
      }
    ),
  Server_Name: Yup.string()
    .required("* Server Name is required")
    .test(
      "trim spaces",
      "Remove leading and trailing spaces",
      function (value) {
        if (value?.trim()?.length === 0) {
          return false;
        }
        return true;
      }
    ),
  Maximum_Org: Yup.string()
    .required("* Maximum Device is required")
    .matches(NUMBER, "enter valid number")
    .test(
      "trim spaces",
      "Remove leading and trailing spaces",
      function (value) {
        if (value?.trim()?.length === 0) {
          return false;
        }
        return true;
      }
    ),
  Description: Yup.string().test(
    "trim spaces",
    "Remove leading and trailing spaces",
    function (value) {
      if (value?.trim()?.length === 0) {
        return false;
      }
      return true;
    }
  ),
  project: Yup.mixed()
    .transform((val) => (val ? val : undefined))
    .required("* Project is required"),
});

function CreateServerModal({
  onClose,
  editData,
  listServerApicall,
  modalClose,
  getview,
  getviewid,
  projectList,
}: createServerModalType) {
  const dispatch = useDispatch();
  const muistyle = muiStyles();
  const {
    handleChange,
    values,
    setFieldValue,
    handleSubmit,
    setFieldTouched,
    setFieldError,
    errors,
    touched,
    handleBlur,
  } = useFormik({
    initialValues: {
      Base_Url: editData?.base_url || "",
      Server_Name: editData?.server_name || "",
      Description: editData?.description || "",
      Maximum_Org: editData?.maximumOrg || 1,
      serverId: editData?.server_id || "",
      project: editData?.projectId
        ? projectList?.find((ele) => ele?.id === editData?.projectId)
        : null,
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      if (editData) {
        handleupdateServer();
      } else {
        handleCreateserver();
      }
    },
  });

  /**
   * Handles the creation of a server by sending a POST request to the server API.
   * @returns None
   */
  const handleCreateserver = () => {
    dispatch(openLoader(true));
    let formData = new FormData();
    formData.append("base_url", values.Base_Url);
    formData.append("name", values.Server_Name);
    // @ts-ignore
    formData.append("projectId", values.project?.id);
    formData.append("description", values.Description);
    formData.append("maximumOrg", values.Maximum_Org.toString());

    createServer(formData)
      .then((response) => {
        if (response?.data?.status === 1) {
          listServerApicall("add");
          modalClose();
          toast.success(response?.data?.msg);
        } else {
          toast.error(response?.data?.msg);
        }
      })
      .catch((err) => getCatchMsg(err))
      .finally(() => {
        dispatch(openLoader(false));
      });
  };

  /**
   * Handles the view server functionality by sending a request to the server to retrieve
   * details about a specific server.
   * @returns None
   */
  const handleViewserver = () => {
    dispatch(openLoader(true));
    let formData = new FormData();
    formData.append("server_id", getviewid);
    viewServer(formData)
      .then((response) => {
        if (response?.data?.status === 1) {
          response?.data?.data?.map((details: serverDetailsType) => {
            return (
              setFieldValue("Base_Url", details?.base_url),
              setFieldValue("Server_Name", details?.server_name),
              setFieldValue("Description", details?.description),
              setFieldValue("Maximum_Org", details?.maximumOrg)
            );
          });
        }
      })
      .catch((err) => getCatchMsg(err))
      .finally(() => {
        dispatch(openLoader(false));
      });
  };

  /**
   * Executes the handleViewserver function when the value of getview is true.
   * @returns None
   */
  useEffect(() => {
    if (getview === true) {
      handleViewserver();
    }
  }, [getview]);

  /**
   * Handles the update of a server by sending a request to the server API with the updated server information.
   * @returns None
   */
  const handleupdateServer = () => {
    dispatch(openLoader(true));
    let formData = new FormData();
    formData.append("server_id", values.serverId.toString());
    formData.append("base_url", values.Base_Url);
    formData.append("name", values.Server_Name);
    formData.append("description", values.Description);
    formData.append("maximumOrg", values.Maximum_Org.toString());
    formData.append(
      "projectId",
      values.project ? values.project?.id?.toString() : ""
    );

    updateServer(formData)
      .then((response) => {
        if (response?.data?.status === 1) {
          listServerApicall("update");
          modalClose();
          toast.success(response?.data?.msg);
        } else {
          toast.error(response?.data?.msg);
        }
      })
      .catch((err) => getCatchMsg(err))
      .finally(() => {
        dispatch(openLoader(false));
      });
  };

  return (
    <>
      <ModalHeader
        onClose={onClose}
        heading={
          getview
            ? "View Server"
            : editData === null
              ? "Add Server"
              : "Edit Server"
        }
      />
      <div className={classes.add_device}>
        <div className="row">
          <div className="col-md-12 col-xl-6 form-group mb-3">
            <TextInputBox
              requiredText={getview ? null : "*"}
              textLength={50}
              title="Base Url"
              name="Base_Url"
              value={values.Base_Url}
              readOnly={getview}
              placeHolder="Enter base Url"
              onChangeText={handleChange("Base_Url")}
              onBlur={() => {
                try {
                  validationSchema.validateSyncAt("Base_Url", values.Base_Url);
                } catch (error) {
                  if (error instanceof Error) {
                    setFieldTouched("Base_Url", true);
                    setFieldError("Base_Url", error.message);
                  }
                }
              }}
              errorText={
                touched.Base_Url && errors.Base_Url ? errors.Base_Url : ""
              }
            />
          </div>
          <div className="col-xl-6 col-md-12 form-group mb-3">
            <TextInputBox
              requiredText={getview ? null : "*"}
              textLength={50}
              readOnly={getview}
              name="Server_Name"
              value={values.Server_Name}
              placeHolder="Enter server name"
              title="Server Name"
              onChangeText={handleChange("Server_Name")}
              onBlur={() => {
                try {
                  validationSchema.validateSyncAt(
                    "Server_Name",
                    values.Server_Name
                  );
                } catch (error) {
                  if (error instanceof Error) {
                    setFieldTouched("Server_Name", true);
                    setFieldError("Server_Name", error.message);
                  }
                }
              }}
              errorText={
                touched.Server_Name && errors.Server_Name
                  ? errors.Server_Name
                  : ""
              }
            />
          </div>

          <div className="col-md-12 col-xl-6 form-group mb-3">
            <TextInputBox
              textLength={50}
              customInputProps={{
                onKeyDown: (e) => {
                  if (
                    !(
                      e.key === "Backspace" ||
                      e.key === "Delete" ||
                      e.key === "ArrowLeft" ||
                      e.key === "ArrowRight"
                    )
                  ) {
                    if (!(e.key >= "0" && e.key <= "9")) {
                      e.preventDefault();
                    }
                  }
                },
              }}
              requiredText={getview ? null : "*"}
              infotxt={getview ? null : errors.Maximum_Org ? "" : "number only"}
              readOnly={getview}
              name="Maximum_Org"
              value={values.Maximum_Org}
              placeHolder="Enter maximum organization"
              title="Maximum Organization"
              onChangeText={handleChange("Maximum_Org")}
              onBlur={() => {
                try {
                  validationSchema.validateSyncAt(
                    "Maximum_Org",
                    values.Maximum_Org
                  );
                } catch (error) {
                  if (error instanceof Error) {
                    setFieldTouched("Maximum_Org", true);
                    setFieldError("Maximum_Org", error.message);
                  }
                }
              }}
              errorText={
                touched.Maximum_Org && errors.Maximum_Org
                  ? errors.Maximum_Org
                  : ""
              }
            />
          </div>
          <div className="fieldBr8 col-md-12 col-xl-6 form-group mb-3">
            <TextInputBox
              title="Project"
              requiredText={"*"}
              onChangeText={() => {}}
              textOnly
            />
            <Autocomplete
              // className={styles.placeHolder}
              options={projectList}
              value={values.project}
              onChange={(e, val) => {
                setFieldValue("project", val);
              }}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => {
                return (
                  <TextField
                    placeholder={values?.project ? "" : "none"}
                    {...params}
                  />
                );
              }}
              PaperComponent={(props) => {
                return <Paper {...props} className={muistyle.customSelect} />;
              }}
            />
            <TextInputBox
              title=""
              onChangeText={() => {}}
              textOnly
              errorText={
                errors?.project && touched.project ? errors?.project : ""
              }
            />
          </div>
          <div
            style={{ position: "relative" }}
            className={`col-md-12 col-xl-6 form-group mb-3 ${classes.textareacontainer}`}
          >
            <p>Description</p>
            <textarea
              style={{ padding: "5px" }}
              name="Description"
              className={`${classes.text_area}`}
              maxLength={250}
              onChange={handleChange("Description")}
              value={values.Description}
              placeholder="Enter the description"
              readOnly={getview}
              onBlur={handleBlur("Description")}
            ></textarea>
            {errors?.Description && touched.Description && (
              <p className={classes.text_areaError}>{errors?.Description}</p>
            )}
          </div>
        </div>

        {getview ? null : (
          <>
            <div className={classes.modalbtn}>
              {editData ? (
                <UpdateDeleteActions
                  onCancelPress={() => modalClose()}
                  onUpdatePress={handleSubmit}
                />
              ) : (
                <div className="col-lg-3">
                  <CustomButton title="Submit" onButtonPress={handleSubmit} />
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default CreateServerModal;
