import React, { useState } from "react";
import { AddDesigantionProps } from "./Types";
import { createDesiganation, updateDesignation } from "../Services/Services";
import { toast } from "react-hot-toast";
import * as Yup from "yup";
import { useFormik } from "formik";
import { getCatchMsg } from "../Utility/GeneralUtils";
import { Loader, TextInputBox, CustomButton } from "../Components/index";
import classes from "./Modal.module.css";
import ModalHeader from "./ModalHeader";
import { useDispatch } from "react-redux";
import { openLoader } from "../Store/Reducers/LoaderSlice";

const validationSchema = Yup.object({
  name: Yup.string()
    .required("Designation is required")
    .trim("Remove leading and trailing spaces")
    .strict(true),
});

function AddDesigantion({
  editData,
  listApiCall,
  modalClose,
  onClose,
}: AddDesigantionProps) {
  const dispatch = useDispatch();

  const { handleChange, values, handleSubmit, errors, touched } = useFormik({
    initialValues: {
      name: editData?.name || "",
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      if (editData) {
        handleUpdateDesigntion();
      } else {
        handleAddDesigantion();
      }
    },
  });

  const handleAddDesigantion = () => {
    dispatch(openLoader(true));
    let formData = new FormData();
    formData.append("name", values.name);
    createDesiganation(formData)
      .then((res) => {
        if (res.data.status === 1) {
          listApiCall("add");
          modalClose();
          toast.success(res.data.msg);
        } else {
          toast.error(res.data.msg);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        dispatch(openLoader(false));
      });
  };

  const handleUpdateDesigntion = () => {
    dispatch(openLoader(true));
    let formData = new FormData();
    formData.append("name", values.name);
    if (editData?.id) {
      //@ts-ignore
      formData.append("designationId", editData?.id);
    }
    updateDesignation(formData)
      .then((res) => {
        if (res.data.status === 1) {
          listApiCall("update");
          modalClose();
          toast.success(res.data.msg);
        } else {
          toast.error(res.data.msg);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        dispatch(openLoader(false));
      });
  };

  return (
    <>
      <ModalHeader
        onClose={onClose}
        heading={editData ? "Edit designation" : "Add designation"}
      />
      <div className={classes.add_device}>
        <div className="col-lg-12 mb-3">
          <TextInputBox
            requiredText="*"
            textLength={15}
            placeHolder="Desigantion"
            title="Designation"
            name="name"
            onChangeText={handleChange("name")}
            value={values.name}
            errorText={touched.name && errors.name ? errors.name : ""}
          />
        </div>
        {editData ? (
          <>
            <div className="row my-4">
              <div className="col-lg-6 col-12 col-md-6 mb-4">
                <CustomButton title="Update" onButtonPress={handleSubmit} />
              </div>
              <div className=" col-lg-6 col-12 col-md-6 ">
                <CustomButton
                  title="Cancel"
                  customButtonStyle={{
                    backgroundColor: "rgba(0, 0, 0, 0.08)",
                    color: "black",
                  }}
                  onButtonPress={() => modalClose()}
                />
              </div>
            </div>
          </>
        ) : (
          <div className="col-lg-3 col-12 col-md-6 my-3">
            <CustomButton title="Submit" onButtonPress={handleSubmit} />
          </div>
        )}
      </div>
    </>
  );
}

export default AddDesigantion;
