import React, { useEffect, useState } from "react";
import ModalHeader from "./ModalHeader";
import { AddDeviceBoradBatchProps, viewDeviceListProps } from "./Types";
import { useFormik } from "formik";
import Tooltip from "@mui/material/Tooltip";
import {
  CustomButton,
  CustomDropDown,
  Loader,
  TextInputBox,
} from "../Components";
import classes from "./Modal.module.css";
import {
  addDeviceBoradBatch,
  firmWareDropDown,
  getDeviceModelDropdown,
  getlistCategory,
  listPartnumberdropdowm,
  viewDeviceBoradBatch,
} from "../Services/Services";
import { getCatchMsg } from "../Utility/GeneralUtils";
import UpdateDeleteActions from "../Components/UpdateDeleteActions";
import toast from "react-hot-toast";
import * as Yup from "yup";
import delete_icon from "../Assets/Icons/Svg/delete.svg";
import { openLoader } from "../Store/Reducers/LoaderSlice";
import { useDispatch } from "react-redux";

const validation = Yup.object({
  name: Yup.string()
    .required("* Name is required")
    .trim("Remove leading and trailing spaces")
    .strict(true),
  firmwareId: Yup.string().required("* Firmware name is required"),
  modelId: Yup.string().required("* Model is required"),
  data: Yup.array().of(
    Yup.object().shape({
      categoryId: Yup.string().required("* Category is required"),
      partNumberId: Yup.string().required("* Part number is required"),
    })
  ),
});

function AddDeviceBatch({
  onClose,
  listModelApicall,
  editData,
  modalClose,
  viewStatus,
  id,
}: AddDeviceBoradBatchProps) {
  const dispatch = useDispatch();

  const [categorylist, setCategorylist] = useState();
  const [firmwareList, setFirmWareList] = useState<any>();
  const [viewDeviceList, setViewDeviceList] =
    useState<viewDeviceListProps | null>(null);
  const [AddValidation, setAddValidation] = useState(false);
  const [partNumberdropdownList, setpartNumberdropdownList] = useState();

  const [devicemodelDropList, setDeviceModelDropList] = useState();
  const {
    values,
    handleChange,
    handleSubmit,
    setValues,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      name: "",
      firmwareId: "",
      firmWareName: "",
      modelId: "",
      modelName: "",
      data: [
        {
          categoryId: "",
          categoryName: "",
          boardBatchId: "",
          boardBatchName: "",
          partNumberId: "",
          partNumberName: "",
        },
      ],
    },
    validationSchema: validation,
    onSubmit: () => {
      if (AddValidation) {
        setValues({
          ...values,
          data: [
            ...values.data,
            {
              categoryId: "",
              categoryName: "",
              boardBatchId: "",
              boardBatchName: "",
              partNumberId: "",
              partNumberName: "",
            },
          ],
        });
      } else {
        handleAddDeviceBoardBatch(values);
      }
    },
  });

  const handleAddDeviceBoardBatch = (data: any) => {
    dispatch(openLoader(true));
    let tempData = [...data.data];
    const finalData = tempData.map((ele, index) => {
      return {
        categoryId: ele?.categoryId,
        partNumberId: ele?.partNumberId,
      };
    });
    let tempsendData = {
      name: data?.name,
      modelId: data?.modelId,
      // firmwareId: data?.firmwareId,
      data: finalData,
    };
    const sendData = data?.firmwareId
      ? { ...tempsendData, firmwareId: data?.firmwareId }
      : { ...tempsendData };

    addDeviceBoradBatch(sendData)
      .then((response) => {
        if (response?.data?.status === 1) {
          modalClose();
          listModelApicall("add");
          toast.success(response?.data?.msg);
        } else if (response?.data?.status === 0) {
          toast.error(response?.data?.msg);
        }
      })
      .catch((error) => {
        getCatchMsg(error);
      })
      .finally(() => {
        dispatch(openLoader(false));
      });
  };

  const handlegetcategorylist = () => {
    getlistCategory()
      .then((res) => {
        if (res.data.status === 1) {
          setCategorylist(res?.data?.data);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      });
  };

  const handlegetDeviceModellist = () => {
    getDeviceModelDropdown()
      .then((res) => {
        if (res.data.status === 1) {
          setDeviceModelDropList(res?.data?.data);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      });
  };

  const handlePartNumberList = (id: any) => {
    let formdata = new FormData();
    formdata.append("categoryTypeId", id);
    listPartnumberdropdowm(formdata)
      .then((res) => {
        if (res.data.status === 1) {
          setpartNumberdropdownList(res?.data?.data);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      });
  };

  const handlegetFirmWarelist = (id: string) => {
    const formdata = new FormData();
    formdata.append("modelId", id);
    firmWareDropDown(formdata)
      .then((res) => {
        if (res.data.status === 1) {
          setFirmWareList(res?.data?.data);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      });
  };

  const handleGetViewDeviceBatch = () => {
    dispatch(openLoader(true));
    let formData = new FormData();
    //@ts-ignore
    formData.append("deviceBatchId", id);
    viewDeviceBoradBatch(formData)
      .then((response) => {
        if (response?.data?.status === 1) {
          setViewDeviceList(response?.data?.data);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        dispatch(openLoader(false));
      });
  };

  useEffect(() => {
    if (viewStatus) {
      handleGetViewDeviceBatch();
    }
  }, [viewStatus]);

  useEffect(() => {
    if (!viewStatus) {
      handlegetcategorylist();

      handlegetDeviceModellist();
    }
  }, []);

  return (
    <>
      <ModalHeader
        onClose={onClose}
        heading={`${viewStatus ? "View" : "Add"} device batch`}
      />
      <div className={classes.addDeviceBatch}>
        {viewStatus ? (
          <div className={classes.viewContainer}>
            <div className={classes.viewContent}>
              <p>Device Batch Name</p>
              <strong>:</strong>
              <span>
                {viewDeviceList?.deviceBatchName
                  ? viewDeviceList?.deviceBatchName
                  : "-"}
              </span>
            </div>
            <div className={classes.viewContent}>
              <p>Firmware Name</p>
              <strong>:</strong>
              <span>
                {viewDeviceList?.firmware ? viewDeviceList?.firmware : "-"}
              </span>
            </div>
            <div className={classes.viewContent}>
              <p>Created By</p>
              <strong>:</strong>
              <span>
                {viewDeviceList?.createdBy ? viewDeviceList?.createdBy : "-"}
              </span>
            </div>
            <div className={classes.viewContent}>
              <p>Model Name</p>
              <strong>:</strong>
              <span>
                {viewDeviceList?.modelName ? viewDeviceList?.modelName : "-"}
              </span>
            </div>
            {viewDeviceList?.components.map((compo, index) => (
              <div className="col-md-12 col-xl-12 form-group my-3">
                <div className={`d-flex ${classes.viewContent}`}>
                  <p>Category Name</p>
                  <strong>:</strong>
                  <span>
                    {compo?.componentTypeName ? compo?.componentTypeName : "-"}
                  </span>
                </div>
                <div className={`d-flex ${classes.viewContent}`}>
                  <p>Part Number</p>
                  <strong>:</strong>
                  <span>
                    {compo?.partNumberName ? compo?.partNumberName : "-"}
                  </span>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <>
            <div className="row">
              <div className="col-md-6 col-lg-4 form-group mb-3">
                <TextInputBox
                  requiredText={"*"}
                  textLength={50}
                  placeHolder="Enter name"
                  title="Name"
                  name="name"
                  onChangeText={handleChange("name")}
                  value={values.name}
                  errorText={errors?.name && touched.name ? errors?.name : ""}
                />
              </div>

              <div className="col-md-6 col-lg-4 form-group mb-3">
                <CustomDropDown
                  editName={values?.modelName}
                  items={devicemodelDropList}
                  required={"*"}
                  title="Model"
                  onSelectedItem={(val, value) => {
                    setValues({
                      ...values,
                      modelName: value?.label,
                      modelId: value?.id,
                      firmwareId: "",
                      firmWareName: "",
                    });
                    setFirmWareList(null);
                    if (value?.id) {
                      handlegetFirmWarelist(value?.id);
                    }
                  }}
                  fieldName={"deviceModelName"}
                  anotherFieldName={"deviceModelId"}
                  value={values?.modelId}
                  errorText={
                    errors?.modelId && touched.modelId ? errors?.modelId : ""
                  }
                />
              </div>
              <div className="col-md-6 col-lg-4 form-group mb-3">
                <CustomDropDown
                  editName={values?.firmWareName}
                  items={firmwareList}
                  required={"*"}
                  title="Firmware Name"
                  onSelectedItem={(val, value) => {
                    setFieldValue("firmWareName", value?.label);
                    setFieldValue("firmwareId", value?.id);
                  }}
                  disabled={!values?.modelId}
                  fieldName={"name"}
                  anotherFieldName={"id"}
                  value={values?.firmwareId}
                  errorText={
                    errors?.firmwareId && touched.firmwareId
                      ? errors?.firmwareId
                      : ""
                  }
                />
              </div>
            </div>
            <div className={classes.AddDeviceMulitpleContent}>
              <div className={classes.multipleContent}>
                {[...values?.data]?.map((batch, index) => (
                  <div className="row">
                    <div className="col-md-6 col-lg-5 form-group mb-3">
                      <CustomDropDown
                        disabled={
                          values?.data[index]?.categoryName !== ""
                            ? true
                            : false
                        }
                        required={"*"}
                        title="Category"
                        editName={
                          values?.data[index]?.categoryName
                            ? values?.data[index]?.categoryName
                            : "-- None --"
                        }
                        items={categorylist}
                        onSelectedItem={(val, value) => {
                          setFieldValue(
                            `data[${index}].categoryName`,
                            value.label
                          );
                          setFieldValue(`data[${index}].categoryId`, value.id);
                          if (value?.label !== "-- None --")
                            handlePartNumberList(value?.id);
                        }}
                        fieldName="categoryName"
                        anotherFieldName="categoryId"
                        value={values?.data[index].categoryId}
                        errorText={
                          //@ts-ignore
                          errors?.data?.[index]?.categoryId &&
                          touched?.data?.[index]?.categoryId
                            ? //@ts-ignore
                              errors?.data?.[index]?.categoryId
                            : ""
                        }
                      />
                    </div>
                    <div className="col-md-6 col-lg-5 form-group mb-3">
                      <CustomDropDown
                        required={"*"}
                        disabled={
                          values?.data[index]?.categoryName === "" ||
                          values?.data[index]?.partNumberName !== "" ||
                          values?.data[index]?.categoryName === "-- None --"
                            ? true
                            : false
                        }
                        title="Part number"
                        editName={
                          values?.data[index]?.partNumberName
                            ? values?.data[index]?.partNumberName
                            : "-- None --"
                        }
                        items={partNumberdropdownList}
                        onSelectedItem={(val, value) => {
                          setFieldValue(
                            `data[${index}].partNumberName`,
                            value.label
                          );
                          setFieldValue(
                            `data[${index}].partNumberId`,
                            value.id
                          );
                        }}
                        fieldName="part_name"
                        anotherFieldName="id"
                        value={values?.data[index].partNumberId}
                        errorText={
                          //@ts-ignore
                          errors?.data?.[index]?.partNumberId &&
                          touched?.data?.[index]?.partNumberId
                            ? //@ts-ignore
                              errors?.data?.[index]?.partNumberId
                            : ""
                        }
                      />
                    </div>
                    {[...values?.data].length > 1 ? (
                      <div className={`col-2 ${classes.icons}`}>
                        <Tooltip title="Delete" placement="top">
                          <img
                            src={delete_icon}
                            alt="Delete"
                            onClick={() => {
                              let tempData = [...values?.data];
                              let tempFildNames = tempData;
                              tempFildNames = tempData.filter(
                                (ele, tempIndex) => tempIndex != index
                              );
                              setFieldValue("data", tempFildNames);
                            }}
                          />
                        </Tooltip>
                      </div>
                    ) : null}
                  </div>
                ))}
              </div>
              <div className="col-lg-2 mx-auto  mt-1">
                {[...values?.data].length < 4 ? (
                  <CustomButton
                    title="+"
                    onButtonPress={() => {
                      handleSubmit();
                      setAddValidation(true);
                    }}
                    customButtonStyle={{
                      backgroundColor: "rgba(0, 0, 0, 0.08)",
                      color: "black",
                    }}
                  />
                ) : null}
              </div>
            </div>
            <div className={classes.modalbtn}>
              {editData ? (
                <>
                  <UpdateDeleteActions
                    onCancelPress={() => modalClose()}
                    onUpdatePress={() => {
                      setAddValidation(false);
                      handleSubmit();
                    }}
                  />
                </>
              ) : (
                <div className="col-lg-3">
                  <CustomButton
                    title="Submit"
                    onButtonPress={() => {
                      setAddValidation(false);
                      handleSubmit();
                    }}
                  />
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default AddDeviceBatch;
