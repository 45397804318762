import React, { Fragment } from "react";
import { Skeleton } from "@mui/material";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

import { TableRowsLoaderProps } from "./Types";

const TableRowsLoader = ({ rowsNum, columnNum = 6 }: TableRowsLoaderProps) => {
  return (
    <Fragment>
      {[...Array(rowsNum)].map((row, rowIndex) => (
        <TableRow key={`row-${rowIndex}`}>
          {[...Array(columnNum)].map((ele, columnIndex) => (
            <TableCell
              key={`cell-${rowIndex}-${columnIndex}`}
              component="th"
              scope="row"
            >
              <Skeleton animation="wave" variant="text" />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </Fragment>
  );
};

export default TableRowsLoader;
