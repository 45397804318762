import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import CustomButton from "../../Components/CustomButton";
import classes from "./PageHeader.module.css";

import { PageHeaderProps } from "./Types";
import { MenuItem, Select, TextField } from "@mui/material";
import { Tooltip, makeStyles } from "@material-ui/core";
import { CustomDropDown } from "../../Components";
import searchicon from "../../Assets/Icons/Png/searchbaricon.png";
import warning_icon from "../../Assets/Icons/Png/warning.png";
import { Badge } from "antd";

const useStyles = makeStyles((theme) => ({
  pageSelect: {
    height: "42px !important",
    borderRadius: "4px",
    marginRight: "10px",
    marginTop: "10px",
    "& fieldset:hover": {
      // borderColor: "inherit !important",
      // border: "none  !important",
    },

    "& fieldset": {
      // borderColor: "inherit !important",
      // border: "none  !important",
    },
  },
}));
function PageHeader({
  modal,
  heading = "",
  Btntitle = "",
  BtntitleOne = "",
  onPressOvertime,
  BtnTrue = false,
  dropdown = false,
  dropdownValue,
  dropdownChange,
  firmware = false,
  firmwareDropDownValue,
  dropdownSelectedItem,
  FirmWareEditName,
  serachIconStatus = true,
  onFilterbutton,
  onDownloadPress,
  downloadBtn,
  addbtn = true,
  onWarningIcon,
  SubscriptionUpgarde,
}: PageHeaderProps) {
  const { pathname } = useLocation();
  const styles = useStyles();
  const [firmwareType, setfirmwareType] = useState([
    {
      id: 1,
      name: "Major Change",
    },
    {
      id: 2,
      name: "Minor Change",
    },
    {
      id: 3,
      name: "Bug Fixes",
    },
  ]);

  return (
    <div>
      <div className={classes.header}>
        <div className={classes.header_left}>
          <div className={classes.line}></div>
          <div className={classes.heading}>{heading}</div>
        </div>
        {BtnTrue ? null : (
          <div className={classes.header_right}>
            {serachIconStatus && (
              <Tooltip title="search" placement="top">
                <div
                  className={classes.searchimagebox}
                  onClick={onFilterbutton}
                >
                  <img className={classes.searchimage} src={searchicon} />
                </div>
              </Tooltip>
            )}
            {SubscriptionUpgarde ? (
              <Tooltip title="SubScription">
                <Badge
                  count={SubscriptionUpgarde}
                  overflowCount={999}
                  color="#727187"
                  style={{ marginRight: "26px", marginTop: "2px" }}
                >
                  <img
                    onClick={onWarningIcon}
                    className={classes.warning_icon}
                    src={warning_icon}
                    alt="search"
                  />
                </Badge>
              </Tooltip>
            ) : null}
            {firmware && (
              <div className={classes.dropDown}>
                <CustomDropDown
                  editName={FirmWareEditName}
                  items={firmwareType}
                  fieldName={"name"}
                  anotherFieldName={"id"}
                  value={firmwareDropDownValue}
                  onSelectedItem={(val, value) => {
                    if (dropdownSelectedItem) {
                      dropdownSelectedItem(value);
                    }
                  }}
                  disableClearable={firmwareDropDownValue ? false : true}
                />
              </div>
            )}
            {dropdown && (
              <TextField
                size="small"
                select
                label="Select Status"
                value={dropdownValue}
                onChange={(e) => {
                  if (dropdownChange) {
                    dropdownChange(e.target.value);
                  }
                }}
                defaultValue={"-1"}
                style={{
                  minWidth: "100px",
                  color: dropdownValue === "-1" ? "grey" : "black",
                  marginRight: "10px",
                }}
                placeholder="Status"
                className={styles.pageSelect}
              >
                <MenuItem
                  style={{ visibility: "hidden", height: 0, padding: 0 }}
                  value={"-1"}
                >
                  Select
                </MenuItem>
                <MenuItem value={"-2"}>All</MenuItem>
                <MenuItem value={"1"}>Verified</MenuItem>
                <MenuItem value={"2"}>Un Verified</MenuItem>
                <MenuItem value={"3"}>Deactivated</MenuItem>
              </TextField>
            )}

            {downloadBtn && (
              <CustomButton
                download
                onButtonPress={onDownloadPress}
                title="Download"
                customButtonStyle={{
                  backgroundColor: "#262626",
                  fontSize: "13px",
                  fontWeight: "500",
                  padding: "10px 15px",
                  boxShadow: "0px 1.89921px 3.79843px rgba(0, 0, 0, 0.06)",
                  // marginTop: dropdown ? "10px" : "0px",
                }}
              />
            )}
            {addbtn && (
              <CustomButton
                onButtonPress={modal}
                title={Btntitle}
                customButtonStyle={{
                  backgroundColor: "#262626",
                  fontSize: "13px",
                  fontWeight: "500",
                  padding: "10px 15px",
                  boxShadow: "0px 1.89921px 3.79843px rgba(0, 0, 0, 0.06)",
                  // marginTop: dropdown ? "10px" : "0px",
                }}
              />
            )}

            {pathname === "/dashboard/management/work_shedule" ||
            pathname ===
              "/dashboard/management/board_specification_definition/borad_specification_details_list" ||
            pathname === "/dashboard/management/employee_management" ||
            // pathname === "/dashboard/management/dealer_management" ||
            pathname ===
              "/dashboard/management/question_management/master_question_details" ? (
              <div style={{ marginLeft: "23px" }}>
                <CustomButton
                  onButtonPress={onPressOvertime}
                  title={BtntitleOne}
                  customButtonStyle={{
                    backgroundColor: "#262626",
                    fontSize: "13px",
                    fontWeight: "500",
                    padding: "10px 15px",
                    boxShadow: "0px 1.89921px 3.79843px rgba(0, 0, 0, 0.06)",
                  }}
                />
              </div>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
}

export default PageHeader;
