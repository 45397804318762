import React, { useEffect, useState } from "react";
import ModalHeader from "./ModalHeader";
import { AddDeviceModalLinkProps } from "./Types";
import { CustomButton, CustomDropDown, Loader } from "../Components";
import { useFormik } from "formik";
import {
  createBoardMapComponent,
  getComponentdropdown,
  viewDeviceBoardMap,
} from "../Services/Services";
import * as Yup from "yup";
import { getCatchMsg } from "../Utility/GeneralUtils";
import classes from "./Modal.module.css";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { openLoader } from "../Store/Reducers/LoaderSlice";

const validation = Yup.object({
  data: Yup.array().of(
    Yup.object().shape({
      componentId: Yup.string()
        .required("* Serial number is required")
        .test("trim spaces", "Serial number is required", function (value) {
          if (value === "-- None --") {
            return false;
          }
          return true;
        }),
    })
  ),
});

function AddDeviceModalLink({
  onClose,
  modalClose,
  getviewid,
  listDeviceApicall,
  deviceData,
}: AddDeviceModalLinkProps) {
  const dispatch = useDispatch();

  const [tempStatus, setTempStatus] = useState(false);
  const { values, setFieldValue, handleSubmit, errors, touched } = useFormik({
    initialValues: {
      deviceId: getviewid,
      data: [
        {
          partId: "",
          categoryTypeId: "",
          componentId: "",
          componentName: "",
          categoryTypeName: "",
          partNumberName: "",
          dropDonwList: [],
          boardMapId: "",
        },
      ],
    },
    validationSchema: validation,
    onSubmit: () => {
      handleAddDeviceBoardMap(values);
    },
  });

  const handleAddDeviceBoardMap = (data: any) => {
    dispatch(openLoader(true));
    let tempData = [...data?.data];
    const finalData = tempData.map((ele) => {
      return {
        partId: ele?.partId,
        categoryTypeId: ele?.categoryTypeId,
        componentId: ele?.componentId,
        boardMapId: ele?.boardMapId,
      };
    });
    let sendData = {
      deviceId: data?.deviceId,
      data: finalData,
    };
    createBoardMapComponent(sendData)
      .then((response) => {
        dispatch(openLoader(true));
        if (response?.data?.status === 1) {
          toast.success(response?.data?.msg);
          listDeviceApicall("add");
          modalClose();
        } else if (response?.data?.status === 0)
          toast.error(response?.data?.msg);
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        dispatch(openLoader(false));
      });
  };

  const handleGetViewBoardMap = () => {
    dispatch(openLoader(true));
    let formData = new FormData();
    if (getviewid) formData.append("deviceId", getviewid.toString());
    viewDeviceBoardMap(formData)
      .then((response) => {
        if (response?.data?.status === 1) {
          let tempData = [...response?.data?.data];
          const tempSetData = tempData.map(async (ele, index) => {
            let dropdownList = await handleGetComponentsDropDown(
              ele?.partNumberId
            );
            setTempStatus(true);
            const finalVal = {
              partId: ele?.partNumberId,
              categoryTypeId: ele?.categoryTypeId,
              categoryTypeName: ele?.categoryTypeName,
              partNumberName: ele?.partNumberName,
              componentId: ele?.componentSerialId,
              componentName: ele?.componentSerial,
              dropDonwList: [...dropdownList],
              boardMapId: ele?.boardMapId,
            };
            return finalVal;
          });
          Promise.all(tempSetData).then((res) => {
            setFieldValue("data", res);
          });
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        dispatch(openLoader(false));
      });
  };

  const handleGetComponentsDropDown = async (dataId: string) => {
    let formData = new FormData();
    formData.append("partNumberId", dataId);
    try {
      const response = await getComponentdropdown(formData);
      return response.data.data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  useEffect(() => {
    if (getviewid) {
      handleGetViewBoardMap();
    }
  }, [getviewid]);

  return (
    <div>
      <ModalHeader onClose={onClose} heading={deviceData?.device_code} />
      <div className={classes.AddDeviceModalLink}>
        <div className={`row mb-4 ${classes.AddBoardHead}`}>
          <p className="col-4">Board Category</p>
          <p className="col-4">Part Number</p>
          <p className="col-4">Serial Number</p>
        </div>
        {tempStatus
          ? [...values?.data].map((ele, index) => (
              <div className={`row my-2 ${classes.rowContainer}`} key={index}>
                <div
                  style={{
                    fontFamily: "var(--fontRegular)",
                  }}
                  className="col-4"
                >
                  {ele?.categoryTypeName}
                </div>
                <div
                  style={{
                    fontFamily: "var(--fontRegular)",
                  }}
                  className="col-4"
                >
                  {ele?.partNumberName}
                </div>
                <div className="col-4 mb-3">
                  <CustomDropDown
                    editName={
                      values?.data[index]?.componentName
                        ? values?.data[index]?.componentName
                        : "-- None --"
                    }
                    items={ele?.dropDonwList}
                    onSelectedItem={(val, value) => {
                      setFieldValue(
                        `data[${index}].componentName`,
                        value.label
                      );
                      setFieldValue(`data[${index}].componentId`, value.id);
                    }}
                    fieldName="serialNo"
                    anotherFieldName="componentId"
                    value={values?.data[index].componentId}
                    errorText={
                      //@ts-ignore
                      errors?.data?.[index]?.componentId &&
                      touched?.data?.[index]?.componentId
                        ? //@ts-ignore
                          errors?.data?.[index]?.componentId
                        : ""
                    }
                  />
                </div>
              </div>
            ))
          : null}
        <div className="col-lg-3 col-12 my-4">
          <CustomButton title="Submit" onButtonPress={handleSubmit} />
        </div>
      </div>
    </div>
  );
}

export default AddDeviceModalLink;
