import React, { memo } from "react";
import { MTTRProps } from "./report";
import LineChart from "../../../Charts/LineChart";
import { Grid } from "@mui/material";

function MtbfReportApp({ reportData }: { reportData: MTTRProps | null }) {
  return (
    <Grid className="px-5 py-4">
      <LineChart
        series={reportData?.data?.map((ele) => ele?.average) || []}
        labels={reportData?.data?.map((ele) => ele?.month) || []}
        lineValue={reportData?.line_value || 0}
        count={reportData?.data?.map((ele) => ele?.request_count) || []}
        ismax={reportData?.data?.map((ele) => ele?.is_max) || []}
      />
    </Grid>
  );
}

export default memo(MtbfReportApp);
