import React from "react";
import CustomButton from "../Components/CustomButton";
import confirm_delete from "../Assets/Icons/Svg/confirmDelete.svg";
import classes from "./Modal.module.css";

interface LogoutConfirmationModalType {
  msg: string;
  onClose: () => void;
  onPositiveButtonPressed?: () => void;
  onNegativeButtonPressed?: () => void;
  positiveButtonText?: string;
  negativeButtonText?: string;
}

function LogoutConfirmationModal({
  msg,
  onClose,
  onPositiveButtonPressed,
  onNegativeButtonPressed,
  positiveButtonText = "Logout",
  negativeButtonText = "Cancel",
}: LogoutConfirmationModalType) {
  return (
    <>
      <div className={classes.confirmModal}>
        <div onClick={onClose} className={classes.deleteIcon}>
          <img src={confirm_delete} alt="Delete" />
        </div>
        <p className={classes.text}>{msg}</p>
        <div className={classes.confirm_Btns}>
          <CustomButton
            customButtonStyle={{
              padding: "10px 20px",
              backgroundColor: "rgba(0, 0, 0, 0.08)",
              color: "black",
            }}
            onButtonPress={onNegativeButtonPressed}
            title={negativeButtonText}
          />
          <CustomButton
            customButtonStyle={{
              padding: "10px 20px",
            }}
            onButtonPress={onPositiveButtonPressed}
            title={positiveButtonText}
          />
        </div>
      </div>
    </>
  );
}

export default LogoutConfirmationModal;
