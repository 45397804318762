import React, { useEffect, useState } from "react";
import { answer, muiStyles, question } from "../../../Utility/Constants";
import { Grid } from "@material-ui/core";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList } from "@mui/lab";
import classes from "./Management.module.css";
import PageHeader from "../../MangementLayoutHeader/PageHeader";
import {
  categorizationTableListDataProps,
  categorizationTableListItemsProps,
  isShowModalCategorzationProps,
  questionsListTableListDataProps,
} from "./managementTypes";
import Tooltip from "@mui/material/Tooltip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  CustomDropDown,
  CustomPaginationwithdropdownpagination,
  GlobalModal,
  Loader,
  NodataLoader,
  TableRowsLoader,
  TextInputBox,
} from "../../../Components";
import view from "../../../Assets/Icons/Png/view.png";
import Inbox from "../../../Assets/Images/Png/noData.png";
import edit from "../../../Assets/Icons/Svg/edit.svg";
import questionListIcon from "../../../Assets/Icons/Svg/questionListIcon.svg";
import toast from "react-hot-toast";
import delete_icon from "../../../Assets/Icons/Svg/delete.svg";
import { useToken } from "../../../Utility/StoreData";
import { useFormik } from "formik";
import {
  CategorizationList,
  deleteCategorization,
  deleteQuestionMaster,
  questionDropDown,
  questionList,
} from "../../../Services/Services";
import { getCatchMsg, isFormDirty } from "../../../Utility/GeneralUtils";
import SearchAndClearbutton from "../../../Components/SearchAndClearbutton";
import { AddCategorization, ConfirmationModal } from "../../../Modals";
import AddOuterQuestion from "../../../Modals/AddOuterQuestion";
import { useNavigate } from "react-router-dom";
import { openLoader } from "../../../Store/Reducers/LoaderSlice";
import { useDispatch } from "react-redux";
import AccordionContent from "../../../Components/AccordionContent";

function QuestionManagement() {
  const styles = muiStyles();
  const token = useToken();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const categarizationNameId = [1, 2, 3];
  const { values, resetForm, handleChange, setFieldValue, initialValues } =
    useFormik({
      initialValues: {
        name: "",
        questionId: "",
        questionName: "",
      },
      onSubmit: () => {},
    });
  const [isShowModal, setIsShowModal] = useState<isShowModalCategorzationProps>(
    {
      status: false,
      data: false,
      viewStatus: false,
      viewId: null,
    }
  );
  const [deleteModal, setdeleteModal] = useState({
    modal: false,
    id: "",
  });
  const [tableHead, setTableHead] = useState(question);
  const [noDataLoader, setnoDataLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [questions, setQuestion] = useState();
  const [showFilterOption, setShowFilterOption] = useState(false);
  const [categorizationTableList, setCategorizationTableList] =
    useState<categorizationTableListDataProps | null>(null);
  const [questionsList, setQuestionsList] =
    useState<questionsListTableListDataProps | null>(null);
  const QuestionSubHeading = [
    {
      id: 27,
      name: question,
      pathname: "active",
    },
    {
      id: 28,
      name: answer,
      pathname: "previos",
    },
  ];

  const handleClick = (param: string) => {
    setShowFilterOption(false);
    resetForm();
    setTableHead(param);
  };

  const handleTableSearch = (data: string) => {
    if (data === "Enter") {
      setPage(1);
      if (tableHead === question) {
        handleGetQuestionList();
      } else {
        handleGetCategorizationList();
      }
    }
  };
  const handleTableSearchClear = () => {
    setPage(1);
    resetForm();
    if (tableHead === question) {
      handleGetQuestionList(undefined, undefined, true);
    } else {
      handleGetCategorizationList(undefined, undefined, true);
    }
  };

  const handleGetCategorizationList = (
    page = 1,
    size = 10,
    resetValue?: boolean
  ) => {
    let formData = new FormData();
    setnoDataLoader(true);
    formData.append("name", resetValue ? "" : values?.name);
    CategorizationList(page, size, formData)
      .then((response) => {
        const { items } = response?.data?.data;
        setPage(response?.data?.data?.page);
        if (items?.length > 0) {
          setCategorizationTableList(response?.data);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        setnoDataLoader(false);
      });
  };

  const handleGetQuestionList = (page = 1, size = 10, resetValue?: boolean) => {
    let formData = new FormData();
    setnoDataLoader(true);
    formData.append("QuestionSetId", resetValue ? "" : values?.questionId);
    questionList(page, size, formData)
      .then((response) => {
        const { items } = response?.data?.data;
        setPage(response?.data?.data?.page);
        if (items?.length > 0) {
          setQuestionsList(response?.data);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        setnoDataLoader(false);
      });
  };

  const handleDeleteCatagarization = () => {
    dispatch(openLoader(true));
    let formData = new FormData();
    formData.append("categorizationId", deleteModal?.id);
    deleteCategorization(formData)
      .then((response) => {
        if (response?.data?.status === 1) {
          handleGetCategorizationList(categorizationTableList?.data?.page);

          toast.success(response?.data?.msg);
        } else if (response?.data?.status === 0) {
          toast.error(response?.data?.msg);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        dispatch(openLoader(false));
        setdeleteModal((prev) => {
          return {
            ...prev,
            modal: false,
          };
        });
      });
  };

  const handleDeleteMasterQuestion = () => {
    dispatch(openLoader(true));
    let formData = new FormData();
    formData.append("questionSetId", deleteModal?.id);
    deleteQuestionMaster(formData)
      .then((response) => {
        if (response?.data?.status === 1) {
          handleGetQuestionList(categorizationTableList?.data?.page);
          toast.success(response?.data?.msg);
        } else if (response?.data?.status === 0) {
          toast.error(response?.data?.msg);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        dispatch(openLoader(false));
        setdeleteModal((prev) => {
          return {
            ...prev,
            modal: false,
          };
        });
      });
  };

  const handlegetQuestionlist = () => {
    questionDropDown()
      .then((res) => {
        if (res.data.status === 1) {
          setQuestion(res?.data?.data);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      });
  };

  useEffect(() => {
    if (token) {
      if (tableHead === question) {
        handleGetQuestionList();
        handlegetQuestionlist();
      } else {
        handleGetCategorizationList();
      }
    }
  }, [token, tableHead, values.questionId]);

  return (
    <div>
      {tableHead === question ? (
        <PageHeader
          modal={() => {
            setIsShowModal((prev) => {
              return {
                ...prev,
                status: true,
                data: null,
                viewId: null,
                viewStatus: false,
              };
            });
          }}
          Btntitle={`Add Qustion Set`}
          heading="Question Management"
          onFilterbutton={() => {
            setShowFilterOption((pre) => !pre);
            //
          }}
        />
      ) : (
        <PageHeader
          modal={() => {
            setIsShowModal((prev) => {
              return {
                ...prev,
                status: true,
                data: null,
                viewId: null,
                viewStatus: false,
              };
            });
          }}
          Btntitle={`Add Answer`}
          heading="Question Management"
          onFilterbutton={() => {
            setShowFilterOption((pre) => !pre);
            //
          }}
        />
      )}
      <Grid className={classes.tabContainer}>
        <div className={classes.tableChange}>
          {QuestionSubHeading.map((ele) => {
            return (
              <>
                <TabContext value={tableHead}>
                  <Box>
                    <TabList
                      aria-label="lab API tabs example"
                      className={
                        ele.name === tableHead
                          ? `${styles.activeTab} ${styles.tabColor}`
                          : `${styles.tabColor}`
                      }
                    >
                      <Tab
                        label={ele.name}
                        value={ele.name}
                        disableRipple={true}
                        className={`${classes.tabContent} ${
                          ele.name === tableHead
                            ? `${classes.activeTab} activeTab`
                            : ""
                        }`}
                        onClick={() => {
                          handleClick(ele?.name);
                        }}
                      />
                    </TabList>
                  </Box>
                </TabContext>
              </>
            );
          })}
        </div>
      </Grid>

      {isShowModal?.status && tableHead === answer && (
        <GlobalModal
          size="lg"
          modalStyle="globalModalmaxWidth logoutmodalminWidth"
          isVisible={isShowModal.status}
          setIsVisible={() => {
            setIsShowModal((prev) => {
              return {
                ...prev,
                status: true,
              };
            });
          }}
        >
          <AddCategorization
            viewId={isShowModal?.viewId}
            viewStatus={isShowModal?.viewStatus}
            listApiCall={(value) => {
              if (value === "update") {
                handleGetCategorizationList(page);
                return;
              }
              handleGetCategorizationList();
            }}
            editData={isShowModal?.data || null}
            onClose={() => {
              setIsShowModal((prev) => {
                return {
                  ...prev,
                  status: false,
                };
              });
            }}
            modalClose={() => {
              setIsShowModal((prev) => {
                return {
                  ...prev,
                  status: false,
                };
              });
            }}
          />
        </GlobalModal>
      )}
      {isShowModal?.status && tableHead === question && (
        <GlobalModal
          size="lg"
          modalStyle="globalModalmaxWidth logoutmodalminWidth"
          isVisible={isShowModal.status}
          setIsVisible={() => {
            setIsShowModal((prev) => {
              return {
                ...prev,
                status: true,
              };
            });
          }}
        >
          <AddOuterQuestion
            editData={isShowModal?.data}
            listApiCall={(value) => {
              if (value === "update") {
                handleGetQuestionList(page);
                return;
              }
              handleGetQuestionList();
            }}
            onClose={() => {
              setIsShowModal((prev) => {
                return {
                  ...prev,
                  status: false,
                };
              });
            }}
            modalClose={() => {
              setIsShowModal((prev) => {
                return {
                  ...prev,
                  status: false,
                };
              });
            }}
          />
        </GlobalModal>
      )}
      {deleteModal?.modal && (
        <GlobalModal
          isVisible={deleteModal.modal}
          setIsVisible={() => {
            setdeleteModal((prev) => {
              return {
                ...prev,
                modal: false,
              };
            });
          }}
        >
          <ConfirmationModal
            msg={`Are you sure that you want to permanently delete this ${
              tableHead === question ? "question set" : "answer set"
            }.
            `}
            onClose={() => {
              setdeleteModal((prev) => {
                return {
                  ...prev,
                  modal: false,
                };
              });
            }}
            onPositiveButtonPressed={() => {
              if (tableHead === question) {
                handleDeleteMasterQuestion();
              } else {
                handleDeleteCatagarization();
              }
            }}
            onNegativeButtonPressed={() => {
              setdeleteModal((prev) => {
                return {
                  ...prev,
                  modal: false,
                };
              });
            }}
          />
        </GlobalModal>
      )}
      {tableHead === question ? (
        <>
          {/* {showFilterOption ? (
           
          ) : null} */}
          <AccordionContent filterStat={showFilterOption}>
            <div className={classes.serachContainer}>
              <div className="row mb-3">
                <Grid item lg={2} md={4} sm={6} xs={12}>
                  <CustomDropDown
                    editName={values.questionName}
                    title="Question set"
                    //@ts-ignore
                    items={questions}
                    onSelectedItem={(val, value) => {
                      setFieldValue("questionId", value?.id ? value?.id : "");
                      setFieldValue(
                        "questionName",
                        value?.label ? value?.label : ""
                      );
                    }}
                    fieldName="questionName"
                    anotherFieldName="questionId"
                    value={values.questionId}
                    disableClearable={values.questionId ? false : true}
                  />
                </Grid>
              </div>
              {tableHead !== question && (
                <SearchAndClearbutton
                  handleTableSearch={handleTableSearch}
                  handleTableSearchClear={handleTableSearchClear}
                  disabled={!isFormDirty(initialValues, values)}
                />
              )}
            </div>
          </AccordionContent>
          <div className={`table-responsive ${classes.AllTable}`}>
            <Box>
              <TableContainer>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell>S.No</TableCell>
                      <TableCell>Question</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {questionsList?.data?.items?.length === 0 ? (
                      <>
                        {noDataLoader ? (
                          <TableRow>
                            <TableCell className={classes.noData} colSpan={9}>
                              <NodataLoader />
                            </TableCell>
                          </TableRow>
                        ) : (
                          <TableRow>
                            <TableCell colSpan={9}>
                              <div className={classes.nodatafound}>
                                <img
                                  src={Inbox}
                                  alt="inbox"
                                  width={100}
                                  height={100}
                                />
                                <p>No data found</p>
                              </div>
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    ) : (
                      <>
                        {noDataLoader ? (
                          <TableRowsLoader columnNum={3} rowsNum={10} />
                        ) : questionsList?.data?.items?.length === 0 ||
                          !questionsList ? (
                          <TableRow>
                            <TableCell colSpan={9}>
                              <div className={classes.nodatafound}>
                                <img
                                  src={Inbox}
                                  alt="inbox"
                                  width={100}
                                  height={100}
                                />
                                <p>No data found</p>
                              </div>
                            </TableCell>
                          </TableRow>
                        ) : (
                          questionsList?.data?.items?.map(
                            (categorization: any, index: number) => (
                              <TableRow key={index}>
                                <TableCell>
                                  {(questionsList?.data?.page - 1) *
                                    questionsList?.data?.size +
                                    index +
                                    1}
                                </TableCell>
                                <TableCell>
                                  {categorization?.QuestionMasterName === null
                                    ? "-"
                                    : categorization?.QuestionMasterName}
                                </TableCell>
                                <TableCell>
                                  <div className={classes.icons}>
                                    <Tooltip title="Edit" placement="top">
                                      <img
                                        src={edit}
                                        onClick={() => {
                                          setIsShowModal((prev) => {
                                            return {
                                              ...prev,
                                              status: true,
                                              viewId:
                                                categorization.questionMasterId.toString(),
                                              data: categorization,
                                              viewStatus: false,
                                            };
                                          });
                                        }}
                                        alt="Edit"
                                      />
                                    </Tooltip>
                                    <Tooltip title="Delete" placement="top">
                                      <img
                                        src={delete_icon}
                                        alt="Delete"
                                        onClick={() =>
                                          setdeleteModal((prev) => {
                                            return {
                                              ...prev,
                                              modal: true,
                                              id: categorization.questionMasterId.toString(),
                                            };
                                          })
                                        }
                                      />
                                    </Tooltip>
                                    <Tooltip
                                      title="Question List"
                                      placement="right"
                                    >
                                      <img
                                        style={{
                                          padding: "5px",
                                        }}
                                        src={questionListIcon}
                                        alt="View"
                                        onClick={() =>
                                          navigate(
                                            {
                                              pathname:
                                                "master_question_details",
                                            },
                                            {
                                              state: categorization,
                                            }
                                          )
                                        }
                                      />
                                    </Tooltip>
                                  </div>
                                </TableCell>
                              </TableRow>
                            )
                          )
                        )}
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </div>
          {questionsList?.data?.items?.length ? (
            <CustomPaginationwithdropdownpagination
              onChangePage={(page: number) => {
                handleGetCategorizationList(page, pageSize);
                setPage(page);
              }}
              onChangePagerow={(PageSize: number) => {
                if (page * PageSize > questionsList?.data?.totalCount) {
                  handleGetCategorizationList(1, PageSize);
                  setPageSize(PageSize);
                  return;
                }
                handleGetCategorizationList(page, PageSize);
                setPageSize(PageSize);
              }}
              count={questionsList?.data?.totalCount}
              pages={page}
              pageCount={questionsList?.data?.total_page}
              handleFunction={(selected: number) => {
                setPage(selected);
                handleGetCategorizationList(selected, pageSize);
              }}
              labelRowsPerPage={"Devices Per Page"}
              rowsperpage={pageSize}
              totalData={questionsList?.data}
            />
          ) : null}
        </>
      ) : (
        <>
          {/* {showFilterOption ? (
           
          ) : null} */}
          <AccordionContent filterStat={showFilterOption}>
            <div className={classes.serachContainer}>
              <div className="row mb-3">
                <Grid item lg={2} md={4} sm={6} xs={12}>
                  <TextInputBox
                    title="Answer Set"
                    textLength={50}
                    customInputProps={{
                      onKeyDown: (e) => handleTableSearch(e.key),
                    }}
                    name="name"
                    placeHolder="Search answer set"
                    value={values.name}
                    onChangeText={handleChange("name")}
                  />
                </Grid>
              </div>
              <SearchAndClearbutton
                handleTableSearch={handleTableSearch}
                handleTableSearchClear={handleTableSearchClear}
                disabled={!isFormDirty(initialValues, values)}
              />
            </div>
          </AccordionContent>
          <div className={`table-responsive ${classes.AllTable}`}>
            <Box>
              <TableContainer>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell>S.No</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Option Count</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {categorizationTableList?.data?.items?.length === 0 ? (
                      <>
                        {noDataLoader ? (
                          <TableRow>
                            <TableCell className={classes.noData} colSpan={9}>
                              <NodataLoader />
                            </TableCell>
                          </TableRow>
                        ) : (
                          <TableRow>
                            <TableCell colSpan={9}>
                              <div className={classes.nodatafound}>
                                <img
                                  src={Inbox}
                                  alt="inbox"
                                  width={100}
                                  height={100}
                                />
                                <p>No data found</p>
                              </div>
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    ) : (
                      <>
                        {noDataLoader ? (
                          <TableRowsLoader columnNum={6} rowsNum={10} />
                        ) : categorizationTableList?.data?.items?.length ===
                            0 || !categorizationTableList ? (
                          <TableRow>
                            <TableCell colSpan={9}>
                              <div className={classes.nodatafound}>
                                <img
                                  src={Inbox}
                                  alt="inbox"
                                  width={100}
                                  height={100}
                                />
                                <p>No data found</p>
                              </div>
                            </TableCell>
                          </TableRow>
                        ) : (
                          categorizationTableList?.data?.items?.map(
                            (
                              categorization: categorizationTableListItemsProps,
                              index: number
                            ) => (
                              <TableRow key={index}>
                                <TableCell>
                                  {(categorizationTableList?.data?.page - 1) *
                                    categorizationTableList?.data?.size +
                                    index +
                                    1}
                                </TableCell>
                                <TableCell>
                                  {categorization?.categoriesName === null
                                    ? "-"
                                    : categorization?.categoriesName}
                                </TableCell>
                                <TableCell>
                                  {categorization?.count === null
                                    ? "-"
                                    : categorization?.count}
                                </TableCell>
                                <TableCell>
                                  {categarizationNameId.includes(
                                    categorization?.categoriesId
                                  ) ? null : (
                                    <div className={classes.icons}>
                                      <Tooltip title="View" placement="top">
                                        <img
                                          src={view}
                                          alt="View"
                                          onClick={() =>
                                            setIsShowModal((prev) => {
                                              return {
                                                ...prev,
                                                status: true,
                                                viewId:
                                                  categorization.categoriesId.toString(),
                                                viewStatus: true,
                                              };
                                            })
                                          }
                                        />
                                      </Tooltip>
                                      <Tooltip title="Edit" placement="top">
                                        <img
                                          src={edit}
                                          onClick={() => {
                                            setIsShowModal((prev) => {
                                              return {
                                                ...prev,
                                                status: true,
                                                viewId:
                                                  categorization.categoriesId.toString(),
                                                data: true,
                                                viewStatus: false,
                                              };
                                            });
                                          }}
                                          alt="Edit"
                                        />
                                      </Tooltip>
                                      <Tooltip title="Delete" placement="top">
                                        <img
                                          src={delete_icon}
                                          alt="Delete"
                                          onClick={() =>
                                            setdeleteModal((prev) => {
                                              return {
                                                ...prev,
                                                modal: true,
                                                id: categorization.categoriesId.toString(),
                                              };
                                            })
                                          }
                                        />
                                      </Tooltip>
                                    </div>
                                  )}
                                </TableCell>
                              </TableRow>
                            )
                          )
                        )}
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </div>
          {categorizationTableList?.data?.items?.length ? (
            <CustomPaginationwithdropdownpagination
              onChangePage={(page: number) => {
                handleGetCategorizationList(page, pageSize);
                setPage(page);
              }}
              onChangePagerow={(PageSize: number) => {
                if (
                  page * PageSize >
                  categorizationTableList?.data?.totalCount
                ) {
                  handleGetCategorizationList(1, PageSize);
                  setPageSize(PageSize);
                  return;
                }
                handleGetCategorizationList(page, PageSize);
                setPageSize(PageSize);
              }}
              count={categorizationTableList?.data?.totalCount}
              pages={page}
              pageCount={categorizationTableList?.data?.total_page}
              handleFunction={(selected: number) => {
                setPage(selected);
                handleGetCategorizationList(selected, pageSize);
              }}
              labelRowsPerPage={"Devices Per Page"}
              rowsperpage={pageSize}
              totalData={categorizationTableList?.data}
            />
          ) : null}
        </>
      )}
    </div>
  );
}

export default QuestionManagement;
