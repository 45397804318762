import React, { useState } from "react";
import { addInnerQuestionProps } from "./Types";
import { CustomButton, Loader, TextInputBox } from "../Components";
import ModalHeader from "./ModalHeader";
import classes from "./Modal.module.css";
import { useFormik } from "formik";
import {
  createInnerQuestion,
  updateInnerQuestionMaster,
} from "../Services/Services";
import { getCatchMsg } from "../Utility/GeneralUtils";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { openLoader } from "../Store/Reducers/LoaderSlice";

function AddInnerQuestion({
  onClose,
  modalClose,
  listApiCall,
  editData,
  QuestionMasterId,
}: addInnerQuestionProps) {
  const dispatch = useDispatch();

  const validation = Yup.object({
    QuestionSetName: !editData
      ? Yup.string()
      : Yup.string().required("* Question name is required"),
    data: editData
      ? Yup.array()
      : Yup.array().of(
          Yup.object().shape({
            question: Yup.string()
              .required("* Question is required")
              .trim("Remove leading and trailing spaces")
              .strict(true),
          })
        ),
  });

  const [AddValidation, setAddValidation] = useState(false);
  const {
    values,
    handleChange,
    setFieldValue,
    errors,
    touched,
    setValues,
    handleSubmit,
  } = useFormik({
    initialValues: {
      innerQuestionId: editData?.questionSetId ? editData?.questionSetId : "",
      QuestionSetName: editData?.QuestionSetName
        ? editData?.QuestionSetName
        : "",
      QuestionMasterId: QuestionMasterId ? QuestionMasterId : "",
      data: [
        {
          question: "",
        },
      ],
    },
    validationSchema: validation,
    onSubmit: () => {
      if (AddValidation) {
        setValues({
          ...values,
          data: [
            ...values.data,
            {
              question: "",
            },
          ],
        });
      } else if (!editData) {
        handleAddQuestion(values);
      } else {
        handleUpdateInnerQuestion(values);
      }
    },
  });

  const handleAddQuestion = (data: any) => {
    dispatch(openLoader(true));
    let tempData = [...data.data];
    const finalData = tempData.map((ele, index) => {
      return {
        name: ele?.question,
      };
    });
    let sendData = {
      QuestionMasterId: data?.QuestionMasterId,
      SubQuestion: finalData,
    };

    createInnerQuestion(sendData)
      .then((response) => {
        if (response?.data?.status === 1) {
          modalClose();
          listApiCall("add");
          toast.success(response?.data?.msg);
        } else if (response?.data?.status === 0) {
          toast.error(response?.data?.msg);
        }
      })
      .catch((error) => {
        getCatchMsg(error);
      })
      .finally(() => {
        dispatch(openLoader(false));
      });
  };
  const handleUpdateInnerQuestion = (data: any) => {
    dispatch(openLoader(true));
    let formData = new FormData();
    formData.append("innerQuestionId", data.innerQuestionId);
    formData.append("name", data.QuestionSetName);
    updateInnerQuestionMaster(formData)
      .then((res) => {
        if (res.data.status === 1) {
          listApiCall("update");
          modalClose();
          toast.success(res.data.msg);
        } else {
          toast.error(res.data.msg);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        dispatch(openLoader(false));
      });
  };

  return (
    <>
      <ModalHeader
        onClose={onClose}
        heading={`${editData ? "Edit" : "Add"} Question`}
      />
      <div className={classes.AddBoardSpecification}>
        {editData ? (
          <div
            className={classes.catgoryDropDown}
            style={{
              border: "none",
            }}
          >
            <TextInputBox
              requiredText={"*"}
              textLength={200}
              placeHolder="Enter question name"
              title="Qustion Name"
              name="QuestionSetName"
              onChangeText={handleChange("QuestionSetName")}
              value={values.QuestionSetName}
              errorText={
                errors?.QuestionSetName && touched.QuestionSetName
                  ? errors?.QuestionSetName
                  : ""
              }
            />
          </div>
        ) : (
          <>
            <div className={classes.AddDeviceMulitpleContent}>
              <div
                className={classes.multipleContent}
                style={{
                  border: "none",
                }}
              >
                {[...values.data].map((category, index) => (
                  <div className="col-12 form-group mb-3">
                    <TextInputBox
                      categarization={
                        [...values?.data].length > 1 ? true : false
                      }
                      onPressCategarizationDelete={() => {
                        let tempData = [...values?.data];
                        let tempFildNames = tempData;
                        tempFildNames = tempData.filter(
                          (ele, tempIndex) => tempIndex != index
                        );
                        setFieldValue("data", tempFildNames);
                      }}
                      requiredText={"*"}
                      textLength={30}
                      placeHolder="Enter question"
                      value={values?.data[index]?.question}
                      title="Question"
                      name={`data[${index}].question`}
                      onChangeText={handleChange(`data[${index}].question`)}
                      errorText={
                        //@ts-ignore
                        errors?.data?.[index]?.question &&
                        touched?.data?.[index]?.question
                          ? //@ts-ignore
                            errors?.data?.[index]?.question
                          : ""
                      }
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="col-lg-2 mx-auto  mt-1">
              <CustomButton
                title="+"
                onButtonPress={() => {
                  handleSubmit();
                  setAddValidation(true);
                }}
                customButtonStyle={{
                  backgroundColor: "rgba(0, 0, 0, 0.08)",
                  color: "black",
                }}
              />
            </div>
          </>
        )}

        {editData ? (
          <>
            <div className="row my-4">
              <div className="col-lg-6 col-12 col-md-6 mb-4">
                <CustomButton title="Update" onButtonPress={handleSubmit} />
              </div>
              <div className=" col-lg-6 col-12 col-md-6 ">
                <CustomButton
                  title="Cancel"
                  customButtonStyle={{
                    backgroundColor: "rgba(0, 0, 0, 0.08)",
                    color: "black",
                  }}
                  onButtonPress={() => modalClose()}
                />
              </div>
            </div>
          </>
        ) : (
          <div className="col-lg-3 col-12 col-md-6 my-3">
            <CustomButton
              title="Submit"
              onButtonPress={() => {
                handleSubmit();
                setAddValidation(false);
              }}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default AddInnerQuestion;
