import React, { useEffect, useState } from "react";
import PageHeader from "../../MangementLayoutHeader/PageHeader";
import { useLocation, useNavigate } from "react-router-dom";
import {
  BoardBatchComponentsList,
  BoardBatchDetails,
  DeleteComponentsList,
  activeInactiveComponent,
  printBatchComponent,
} from "../../../Services/Services";
import {
  boradBatchDetailsProps,
  componentsIsShowmodalProps,
  componentsListItemsProps,
  componentsListProps,
  partNumberQuerListDataItemsProps,
} from "./managementTypes";
import toast from "react-hot-toast";
import { getCatchMsg, getTrimString } from "../../../Utility/GeneralUtils";
import classes from "./Management.module.css";
import moment from "moment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Box, Tooltip } from "@mui/material";
import {
  CustomPaginationwithdropdownpagination,
  CustomSwitch,
  GlobalModal,
  Loader,
  TableRowsLoader,
} from "../../../Components";
import Inbox from "../../../Assets/Images/Png/noData.png";
import edit from "../../../Assets/Icons/Svg/edit.svg";
import printer from "../../../Assets/Icons/Png/printer.png";
import { AddBoradBatchComponents, ConfirmationModal } from "../../../Modals";
import { Grid, makeStyles } from "@material-ui/core";
import { Button } from "antd";
import { openLoader } from "../../../Store/Reducers/LoaderSlice";
import { useDispatch } from "react-redux";

const useStyles = makeStyles(() => ({
  switchContainer: {
    marginRight: "10px",
    display: "flex",
    alignItems: "center",
  },
}));
function BoardBatchDetailsList() {
  const style = useStyles();
  const state = useLocation();
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [Switches, setSwitches] = useState<boolean[]>([]);
  const [noDataLoader, setnoDataLoader] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [isShowModal, setIsShowModal] = useState<componentsIsShowmodalProps>({
    status: false,
    data: null,
  });
  const [deleteModal, setdeleteModal] = useState({
    modal: false,
    id: null,
    msg: "",
    activeInactiveStatus: false,
  });
  const [boradBatchDetails, setBoradBatchDetails] =
    useState<boradBatchDetailsProps | null>();
  const [componentsList, setComponentsList] =
    useState<componentsListProps | null>();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);

  const handleGetBoardBatchDetailsList = () => {
    setnoDataLoader(true);
    let formData = new FormData();
    formData.append("boardBatchId", state?.state);
    BoardBatchDetails(formData)
      .then((response) => {
        if (response?.data?.status === 1) {
          setBoradBatchDetails(response?.data);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        setnoDataLoader(false);
      });
  };

  const handleGetBordBatchComponentsList = (page = 1, size = 10) => {
    let formData = new FormData();
    formData.append("boardBatchId", state?.state);
    BoardBatchComponentsList(page, size, formData)
      .then((response) => {
        if (response?.data?.status === 1) {
          handleActiveStatus(response?.data?.data?.items);
          setComponentsList(response?.data);
          setPage(response?.data?.data?.page);
          setTotalCount(response?.data?.data?.totalCount);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        setnoDataLoader(false);
      });
  };
  const handleActiveStatus = (data: partNumberQuerListDataItemsProps[]) => {
    if (data && data?.length > 0) {
      const statusData = data?.map((ele, index: number) => {
        setSwitches((pre) => {
          let temp = [...pre];
          temp[index] = ele?.activeStatus?.toString() === "1" ? true : false;
          return temp;
        });
        return ele;
      });
    }
  };
  const handleActiveInactiveComponent = () => {
    dispatch(openLoader(true));
    let formData = new FormData();
    if (deleteModal?.id) {
      formData.append("componentId", deleteModal?.id);
      if (deleteModal?.activeInactiveStatus) {
        formData.append("activeStatus", "1");
      } else if (!deleteModal?.activeInactiveStatus) {
        formData.append("activeStatus", "0");
      }
    }
    activeInactiveComponent(formData)
      .then((res) => {
        if (res?.data?.status === 1) {
          toast.success(res?.data?.msg);
          handleGetBordBatchComponentsList(page);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        dispatch(openLoader(false));
        setdeleteModal((prev: any) => {
          return {
            ...prev,
            activeInactiveStatus: false,
            id: null,
            modal: false,
            msg: "",
          };
        });
      });
  };

  const handlePrintBatchComponent = (
    PrinId: number,
    printallstatus: boolean
  ) => {
    dispatch(openLoader(true));
    let formData = new FormData();
    if (PrinId) {
      if (!printallstatus) {
        formData.append("componentId", PrinId.toString());
      } else {
        formData.append("boardBatchId", PrinId.toString());
      }
    }
    printBatchComponent(formData)
      .then((response) => {
        if (response?.data?.status === 1) {
          const tempUrl = response?.data?.msg;
          window.open(tempUrl);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => dispatch(openLoader(false)));
  };
  useEffect(() => {
    handleGetBoardBatchDetailsList();
    handleGetBordBatchComponentsList();
  }, []);

  return (
    <>
      <PageHeader
        serachIconStatus={false}
        heading={
          boradBatchDetails?.data?.name
            ? boradBatchDetails?.data?.name
            : "Batch Management"
        }
        Btntitle="Back"
        modal={() =>
          navigate("/dashboard/management/partPcpManagement", {
            state: {
              tab: "board",
            },
          })
        }
      />
      {isShowModal?.status && (
        <GlobalModal
          isVisible={isShowModal?.status}
          setIsVisible={() => {
            setIsShowModal((prev) => {
              return {
                ...prev,
                status: false,
              };
            });
          }}
        >
          <AddBoradBatchComponents
            onClose={() => {
              setIsShowModal((prev) => {
                return {
                  ...prev,
                  status: false,
                };
              });
            }}
            //@ts-ignore
            editData={isShowModal?.data}
            listModelApicall={(value) => {
              if (value === "update") {
                handleGetBordBatchComponentsList(page);
                return;
              }
              handleGetBordBatchComponentsList();
            }}
            modalClose={() => {
              setIsShowModal((prev) => {
                return {
                  ...prev,
                  status: false,
                };
              });
            }}
          />
        </GlobalModal>
      )}
      <GlobalModal
        isVisible={deleteModal.modal}
        setIsVisible={() => {
          setdeleteModal((prev) => {
            return {
              ...prev,
              modal: false,
            };
          });
        }}
      >
        <ConfirmationModal
          deviceInactive={true}
          msg={`Do you want ${deleteModal?.msg} this serial number.`}
          onClose={() => {
            handleGetBordBatchComponentsList(page);
            setdeleteModal((prev) => {
              return {
                ...prev,
                modal: false,
              };
            });
          }}
          onPositiveButtonPressed={handleActiveInactiveComponent}
          onNegativeButtonPressed={() => {
            handleGetBordBatchComponentsList(page);
            setdeleteModal((prev) => {
              return {
                ...prev,
                modal: false,
              };
            });
          }}
        />
      </GlobalModal>
      <div className={classes.boradBatchDetails}>
        <div id={classes.machine_Detail} className="row">
          <div className={` col-lg-4 col-md-6 ${classes.details}`}>
            <p>Part category</p>
            <strong>:</strong>
            <span>
              {boradBatchDetails?.data?.categoryName
                ? boradBatchDetails?.data?.categoryName
                : "-"}
            </span>
          </div>
          <div className={` col-lg-4 col-md-6 ${classes.details}`}>
            <p>Pcb Manufacturer</p>
            <strong>:</strong>
            <span>
              {boradBatchDetails?.data?.pcbManufacturerName
                ? getTrimString(
                    boradBatchDetails?.data?.pcbManufacturerName,
                    15
                  )
                : "-"}
            </span>
          </div>

          <div className={`col-lg-4 col-md-6 ${classes.details}`}>
            <p>Pcb Designer</p>
            <strong>:</strong>
            <span>
              {boradBatchDetails?.data?.pcbDesignerName
                ? getTrimString(boradBatchDetails?.data?.pcbDesignerName, 15)
                : "-"}
            </span>
          </div>

          <div className={` col-lg-4 col-md-6 ${classes.details}`}>
            <p>H.Designer Name</p>
            <strong>:</strong>
            <span>
              {boradBatchDetails?.data?.hardwareDesignerName
                ? getTrimString(
                    boradBatchDetails?.data?.hardwareDesignerName,
                    15
                  )
                : "-"}
            </span>
          </div>
          <div className={`col-lg-4 col-md-6 ${classes.details}`}>
            <p>Assembler Name</p>
            <strong>:</strong>
            <span>
              {boradBatchDetails?.data?.pcbAssemblerName
                ? getTrimString(boradBatchDetails?.data?.pcbAssemblerName, 15)
                : "-"}
            </span>
          </div>
          <div className={` col-lg-4 col-md-6 ${classes.details}`}>
            <p>Manufacture Date</p>
            <strong>:</strong>
            <span>
              {boradBatchDetails?.data?.manufacture_date
                ? moment(boradBatchDetails?.data?.manufacture_date).format(
                    "YYYY-MM-DD"
                  )
                : "-"}
            </span>
          </div>
          <div className={`col-lg-4 col-md-6  ${classes.details}`}>
            <p>Quantity</p>
            <strong>:</strong>
            <span>
              {boradBatchDetails?.data?.qty
                ? boradBatchDetails?.data?.qty
                : "-"}
            </span>
          </div>
          <div className={`col-lg-4 col-md-6  ${classes.details}`}>
            <p>Created By</p>
            <strong>:</strong>
            <span>
              {boradBatchDetails?.data?.created_by
                ? getTrimString(boradBatchDetails?.data?.created_by, 15)
                : "-"}
            </span>
          </div>
        </div>
      </div>
      <div className={`table-responsive ${classes.AllTable}`}>
        <Box>
          <TableContainer>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>S.No</TableCell>
                  <TableCell>Part Category</TableCell>
                  <TableCell>Part Number</TableCell>
                  <TableCell>Serial Number</TableCell>
                  <TableCell>Action</TableCell>
                  <TableCell>
                    <Button
                      onClick={() => {
                        handlePrintBatchComponent(
                          // @ts-ignore
                          boradBatchDetails?.data?.boardBatchId,
                          true
                        );
                      }}
                    >
                      PrintAll
                    </Button>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <>
                  {noDataLoader ? (
                    <TableRowsLoader columnNum={4} rowsNum={10} />
                  ) : componentsList?.data?.items?.length === 0 ||
                    !componentsList ? (
                    <TableRow>
                      <TableCell colSpan={7}>
                        <div className={classes.nodatafound}>
                          <img
                            src={Inbox}
                            alt="inbox"
                            width={100}
                            height={100}
                          />
                          <p>No data found</p>
                        </div>
                      </TableCell>
                    </TableRow>
                  ) : (
                    componentsList?.data?.items?.map(
                      (components: componentsListItemsProps, index: number) => (
                        <TableRow key={index}>
                          <TableCell>
                            {(componentsList?.data?.page - 1) *
                              componentsList?.data?.size +
                              index +
                              1}
                          </TableCell>
                          <TableCell>
                            {components?.categoryName
                              ? components?.categoryName
                              : "-"}
                          </TableCell>
                          <TableCell>
                            {components?.partNumberName
                              ? components?.partNumberName
                              : "-"}
                          </TableCell>

                          <TableCell>
                            {components?.serial_no
                              ? components?.serial_no
                              : "-"}
                          </TableCell>
                          <TableCell>
                            <div className={classes.icons}>
                              <Grid className={style.switchContainer}>
                                <CustomSwitch
                                  title1="Active"
                                  title2="inactive"
                                  status={Switches[index]}
                                  setCheck={(state) => {
                                    setSwitches((pre) => {
                                      let temp = [...pre];
                                      temp[index] = state;
                                      return temp;
                                    });
                                    if (state) {
                                      setdeleteModal((prev: any) => {
                                        return {
                                          ...prev,
                                          modal: true,
                                          id: components?.componentId,
                                          activeInactiveStatus: true,
                                          msg: "Active",
                                        };
                                      });
                                    } else {
                                      setdeleteModal((prev: any) => {
                                        return {
                                          ...prev,
                                          modal: true,
                                          id: components?.componentId,
                                          activeInactiveStatus: false,
                                          msg: "Inactive",
                                        };
                                      });
                                    }
                                  }}
                                />
                              </Grid>
                              {/* <Tooltip title="Edit" placement="top">
                                <img
                                  src={edit}
                                  onClick={() =>
                                    //@ts-ignore
                                    setIsShowModal((prev) => {
                                      return {
                                        ...prev,
                                        data: components,
                                        status: true,
                                      };
                                    })
                                  }
                                  alt="Edit"
                                />
                              </Tooltip> */}

                              <Tooltip title="Print" placement="top">
                                <img
                                  src={printer}
                                  alt="printer"
                                  className={classes.printer}
                                  onClick={() => {
                                    const tempId = components?.componentId;
                                    handlePrintBatchComponent(tempId, false);
                                  }}
                                />
                              </Tooltip>
                            </div>
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      )
                    )
                  )}
                </>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </div>
      {componentsList?.data?.items?.length ? (
        <CustomPaginationwithdropdownpagination
          onChangePage={(page: number) => {
            handleGetBordBatchComponentsList(page, pageSize);
            setPage(page);
          }}
          onChangePagerow={(PageSize: number) => {
            if (page * PageSize > componentsList?.data?.totalCount) {
              handleGetBordBatchComponentsList(1, PageSize);
              setPageSize(PageSize);
              return;
            }
            handleGetBordBatchComponentsList(page, PageSize);
            setPageSize(PageSize);
          }}
          count={componentsList?.data?.totalCount}
          pages={page}
          pageCount={componentsList?.data?.total_page}
          handleFunction={(selected: number) => {
            setPage(selected);
            handleGetBordBatchComponentsList(selected, pageSize);
          }}
          labelRowsPerPage={"Devices Per Page"}
          rowsperpage={pageSize}
          totalData={componentsList?.data}
        />
      ) : null}
    </>
  );
}

export default BoardBatchDetailsList;
