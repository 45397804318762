import React, { useEffect, useState } from "react";
import { AddSubcriptionProps, UserSubscriptionDropdownProps } from "./Types";
import {
  CustomButton,
  CustomDropDown,
  Loader,
  TextInputBox,
} from "../Components";
import ModalHeader from "./ModalHeader";
import classes from "./Modal.module.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import UpdateDeleteActions from "../Components/UpdateDeleteActions";
import {
  createSubcription,
  createUserSubscriptionService,
  updateSubcription,
  updateUserSubscriptionService,
  userSubscriptionDropdown,
} from "../Services/Services";
import toast from "react-hot-toast";
import { getCatchMsg } from "../Utility/GeneralUtils";
import { useDispatch } from "react-redux";
import { openLoader } from "../Store/Reducers/LoaderSlice";
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Paper,
  TextField,
} from "@mui/material";
import CustomCheckbox from "../Components/CustomCheckBox";
import { muiStyles } from "../Utility/Constants";

function AddSubcription({
  listApiCall,
  editData,
  onClose,
  modalClose,
  viewStatus,
  projectList,
  type,
  showUserDropdown = true,
}: AddSubcriptionProps) {
  const validation = Yup.object({
    name: Yup.string().required("* Name is required"),
    // @ts-ignore
    validity:
      type === "device"
        ? Yup.number().required("* Duration is required")
        : null,
    price: Yup.number().required("*Monthly Amount is required"),
    // @ts-ignore
    yearlyAmount:
      type === "user"
        ? Yup.number().required("*Yearly Amount is required")
        : "",
    description: Yup.string().required("* Description is required"),
    selected_project: Yup.mixed()
      .transform((val) => (val ? val : undefined))
      .required("* Project is required"),
    selected_subScriptionId: Yup.mixed().test(
      "selected_subscription",
      "* User subscription is required",
      function (value) {
        const { selected_project } = this.parent;
        if (selected_project && selected_project?.id === 1 && !value) {
          return false;
        }
        return true;
      }
    ),
    //     .when("selected_project", {
    //       is: (selected_project: any) =>
    //         selected_project?.id === 1 ? true : false,
    //       then: () => Yup.mixed().required("* User subscription is required"),
    //     otherwise: () => Yup.mixed().notRequired(),
    //   }
    // ),
    // selected_subscription: userSubscriptionList
    //   ? Yup.mixed()
    //       .transform((val) => (val ? val : undefined))
    //       .required("* User subscription is required")
    //   : Yup.mixed().nullable(),
  });
  const dispatch = useDispatch();
  const muistyle = muiStyles();
  const [userSubscriptionList, setUserSubscriptionList] = useState<
    UserSubscriptionDropdownProps[]
  >([]);
  const [userSubscriptionAlert, setuserSubscriptionAlert] = useState(false);

  const { values, handleSubmit, handleChange, errors, touched, setFieldValue } =
    useFormik({
      initialValues: {
        subscriptionId:
          type === "user"
            ? editData?.id
              ? editData?.id
              : ""
            : editData?.subscriptionId
            ? editData?.subscriptionId
            : "",
        name: editData?.name ? editData?.name : "",
        validity:
          type === "user"
            ? editData?.duration
              ? editData?.duration
              : ""
            : editData?.validation
            ? editData?.validation
            : "",
        price:
          type === "user"
            ? editData?.monthCost
              ? editData?.monthCost
              : ""
            : editData?.price
            ? editData?.price
            : "",
        yearlyAmount: editData?.yearCost ? editData?.yearCost : "",
        description: editData?.description ? editData?.description : "",
        selected_project:
          type === "device"
            ? editData?.project_id && projectList
              ? projectList?.find((ele) => ele?.id === editData?.project_id)
              : null
            : editData?.projectId
            ? { id: editData?.projectId, name: editData?.projectName }
            : null,
        // selected_subscription:
        //   userSubscriptionList && editData?.user_subscription_id
        //     ? userSubscriptionList?.find(
        //         (ele) => ele?.subscriptionId === editData?.user_subscription_id
        //       )
        //     : null,
        subname: editData?.sub_title ? editData?.sub_title : "",
        isDemo: editData?.is_demo ? 1 : 0,
        isMaintenance: editData?.is_maintenance ? 1 : 0,
        dealarsPrice: editData?.delarPrice ? editData?.delarPrice : "",
        selected_subscription: editData?.user_subscriptionName || "",
        selected_subScriptionId: editData?.user_subscription_id || "",
      },
      validationSchema: validation,
      onSubmit: () => {
        if (editData === null) {
          handleAddSubcribtion(values);
        } else {
          handleUpdateSubcripbtion(values);
        }
      },
    });
  const getUserSubscriptionList = (projectId: any) => {
    let formData = new FormData();
    formData.append("project_id", projectId);
    userSubscriptionDropdown(formData)
      .then((res) => {
        if (res?.data?.status) {
          setUserSubscriptionList(res?.data?.data || []);
        }
      })
      .catch((err) => getCatchMsg(err));
  };
  const handleAddSubcribtion = (data: any) => {
    dispatch(openLoader(true));
    let formData = new FormData();
    formData.append("name", data?.name);
    formData.append(type === "user" ? "duration" : "validity", data?.validity);
    formData.append("price", data?.price);
    formData.append("description", data?.description);
    formData.append("yearPrice", data.yearlyAmount);
    if (values.isDemo) {
      formData.append("is_demo", "1");
    }
    if (values.isMaintenance) {
      formData.append("is_maintenance", "1");
    }
    if (values.dealarsPrice) {
      formData.append("delarPrice", data.dealarsPrice);
    }
    formData.append("project_id", data?.selected_project?.id);
    if (type === "user") {
      formData.append("sub_title", data?.subname);
    }
    if (values.selected_subScriptionId) {
      formData.append("user_subscription_id", values.selected_subScriptionId);
    }

    const create =
      type == "device"
        ? createSubcription(formData)
        : createUserSubscriptionService(formData);
    create
      .then((response) => {
        if (response?.data?.status === 1) {
          modalClose();
          listApiCall("add");
          toast.success(response?.data?.msg);
        } else if (response?.data?.status === 0) {
          toast.error(response?.data?.msg);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        dispatch(openLoader(false));
      });
  };

  const handleUpdateSubcripbtion = (data: any) => {
    dispatch(openLoader(true));
    let formData = new FormData();
    formData.append(
      type === "user" ? "userSubscriptionId" : "subscriptionId",
      data?.subscriptionId
    );
    formData.append("name", data?.name);
    formData.append(type === "user" ? "duration" : "validity", data?.validity);
    formData.append("price", data?.price);
    formData.append("yearPrice", data.yearlyAmount);
    if (values.isDemo) {
      formData.append("is_demo", "1");
    }
    if (values.isMaintenance) {
      formData.append("is_maintenance", "1");
    }
    if (values.dealarsPrice) {
      formData.append("delarPrice", data.dealarsPrice);
    }
    formData.append("description", data?.description);
    formData.append("project_id", data?.selected_project?.id);
    if (type === "user") {
      formData.append("sub_title", data?.subname);
    }
    if (values.selected_subScriptionId) {
      formData.append("user_subscription_id", values.selected_subScriptionId);
    }
    const update =
      type == "device"
        ? updateSubcription(formData)
        : updateUserSubscriptionService(formData);
    update
      .then((response) => {
        if (response?.data?.status === 1) {
          modalClose();
          listApiCall("update");
          toast.success(response?.data?.msg);
        } else if (response?.data?.status === 0) {
          toast.error(response?.data?.msg);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        dispatch(openLoader(false));
      });
  };
  useEffect(() => {
    if (editData?.project_id) {
      getUserSubscriptionList(editData?.project_id);
    }
  }, []);

  return (
    <>
      <ModalHeader
        onClose={onClose}
        heading={
          type === "device"
            ? viewStatus
              ? "View Device Subcription"
              : editData === null
              ? "Add Device Subcription"
              : "Edit Device Subcription"
            : viewStatus
            ? "View User Subcription"
            : editData === null
            ? "Add User Subcription"
            : "Edit User Subcription"
        }
      />

      <div className={classes.Addsubcription}>
        {viewStatus ? (
          <div className={classes.viewContainer}>
            <div className={classes.viewContent}>
              <p>Name</p>
              <strong>:</strong>
              <span>{editData?.name ? editData?.name : "-"}</span>
            </div>
            {type === "user" && (
              <div className={classes.viewContent}>
                <p>Sub Name</p>
                <strong>:</strong>
                <span>{editData?.sub_title ? editData?.sub_title : "-"}</span>
              </div>
            )}
            <div className={classes.viewContent}>
              <p>Project</p>
              <strong>:</strong>
              <span>
                {values.selected_project?.name
                  ? values.selected_project?.name
                  : editData?.projectName
                  ? editData?.projectName
                  : "-"}
              </span>
            </div>
            {type !== "user" && (
              <div className={classes.viewContent}>
                <p>User Subscription</p>
                <strong>:</strong>
                <span>{editData?.name ? editData?.name : "-"}</span>
              </div>
            )}
            <div className={classes.viewContent}>
              <p>Amount</p>
              <strong>:</strong>
              {type === "device" ? (
                <span>{editData?.price ? editData?.price : "-"}</span>
              ) : (
                <span>{editData?.cost ? editData?.cost : "-"}</span>
              )}
            </div>
            <div className={classes.viewContent}>
              <p>
                Duration
                <span
                  style={{
                    fontSize: "13px",
                  }}
                >
                  (In Month)
                </span>
              </p>
              <strong>:</strong>
              {type === "device" ? (
                <span>{editData?.validation ? editData?.validation : "-"}</span>
              ) : (
                <span>{editData?.duration ? editData?.duration : "-"}</span>
              )}
            </div>
            <div className={classes.viewContent}>
              <p>Description</p>
              <strong>:</strong>
              <span>{editData?.description ? editData?.description : "-"}</span>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-md-6 col-lg-6 form-group mb-3">
              <TextInputBox
                requiredText={"*"}
                title="Name"
                placeHolder="Enter name"
                value={values?.name}
                textLength={50}
                onChangeText={handleChange("name")}
                name="name"
                errorText={touched.name && errors.name ? errors.name : ""}
              />
            </div>
            {type === "user" && (
              <div className="col-md-6 col-lg-6 form-group mb-3">
                <TextInputBox
                  title="Sub Name"
                  placeHolder="Enter sub name"
                  value={values?.subname}
                  textLength={50}
                  onChangeText={handleChange("subname")}
                  name="subname"
                  errorText={
                    touched.subname && errors.subname ? errors.subname : ""
                  }
                />
              </div>
            )}
            {type === "device" ? (
              <div className="col-md-6 col-lg-6 form-group mb-3">
                <TextInputBox
                  title="Dealer Price"
                  placeHolder="Dealer Price"
                  textLength={20}
                  value={values?.dealarsPrice}
                  onChangeText={(text) => {
                    const numericText = text.replace(/[^0-9.]/g, "");
                    handleChange("dealarsPrice")(numericText);
                  }}
                  name="dealarsPrice"
                  // errorText={touched.price && errors.price ? errors.price : ""}
                />
              </div>
            ) : null}
            <div className="col-md-6 col-lg-6 form-group mb-3 fieldBr8">
              <TextInputBox
                textOnly
                title="Project"
                requiredText={"*"}
                onChangeText={() => {}}
              />
              <Autocomplete
                options={projectList}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => {
                  return <TextField placeholder="Select project" {...params} />;
                }}
                onChange={(e, val) => {
                  getUserSubscriptionList(val?.id);
                  setFieldValue("selected_project", val);
                }}
                value={values?.selected_project}
                sx={
                  errors.selected_project && touched.selected_project
                    ? {
                        "& .MuiInputBase-root": {
                          border: "1px solid red",
                        },
                        "& fieldset": {
                          border: "none !important",
                        },
                      }
                    : {}
                }
                PaperComponent={(props) => {
                  return <Paper {...props} className={muistyle.customSelect} />;
                }}
              />
              <TextInputBox
                textOnly
                onChangeText={() => {}}
                errorText={
                  errors?.selected_project && touched.selected_project
                    ? errors?.selected_project
                    : ""
                }
              />
            </div>

            {type === "device" && userSubscriptionList && (
              <div className="col-md-6 col-lg-6 form-group mb-3 fieldBr8">
                <CustomDropDown
                  required={values.selected_project?.id === 1 ? "*" : ""}
                  editName={
                    values.selected_subscription
                      ? values.selected_subscription
                      : ""
                  }
                  //@ts-ignore
                  items={userSubscriptionList}
                  // disabled={editData ? true : false}
                  title="User Subscription"
                  onSelectedItem={(val, value) => {
                    // if(value.id === 4){

                    // }
                    setuserSubscriptionAlert(true);
                    setFieldValue("selected_subscription", value.label);
                    setFieldValue("selected_subScriptionId", value.id);
                  }}
                  fieldName="subscriptionName"
                  anotherFieldName="subscriptionId"
                  value={values.selected_subscription}
                  errorText={
                    errors.selected_subScriptionId
                      ? errors.selected_subScriptionId
                      : ""
                  }
                />
              </div>
            )}
            {type === "device" ? (
              <div className="col-md-6 col-lg-6 form-group mb-3">
                <TextInputBox
                  requiredText={"*"}
                  title="Duration(In Month)"
                  placeHolder="Enter duration"
                  value={values?.validity}
                  onChangeText={(text) => {
                    const numericText = text.replace(/[^0-9]/g, "");
                    handleChange("validity")(numericText);
                  }}
                  name="validity"
                  textLength={2}
                  errorText={
                    touched.validity && errors.validity ? errors.validity : ""
                  }
                />
              </div>
            ) : null}
            <div className="col-md-6 col-lg-6 form-group mb-3">
              <TextInputBox
                title={type === "device" ? "Total Amount" : "Monthly Amount"}
                requiredText={"*"}
                placeHolder="Enter amount"
                textLength={20}
                value={values?.price}
                onChangeText={(text) => {
                  const numericText = text.replace(/[^0-9.]/g, "");
                  handleChange("price")(numericText);
                }}
                name="price"
                errorText={touched.price && errors.price ? errors.price : ""}
              />
            </div>

            {type === "user" ? (
              <div className="col-md-6 col-lg-6 form-group mb-3">
                <TextInputBox
                  title="Yearly Amount"
                  requiredText={"*"}
                  placeHolder="Enter amount"
                  textLength={20}
                  value={values?.yearlyAmount}
                  onChangeText={(text) => {
                    const numericText = text.replace(/[^0-9.]/g, "");
                    handleChange("yearlyAmount")(numericText);
                  }}
                  name="yearlyAmount"
                  errorText={
                    touched.yearlyAmount && errors.yearlyAmount
                      ? errors.yearlyAmount
                      : ""
                  }
                />
              </div>
            ) : null}

            <div className="col-md-6 col-lg-6 form-group mb-3 position-relative">
              <p className={classes.title}>
                Description
                <span
                  style={{
                    color: "#f14a45",
                  }}
                >
                  *
                </span>
              </p>
              <textarea
                className={classes.text_area}
                // maxLength={255}
                name="description"
                value={values?.description}
                onChange={handleChange("description")}
              ></textarea>

              {errors?.description && touched?.description ? (
                <p
                  style={{
                    position: "absolute",
                    bottom: "-8px",
                  }}
                  className="errTxt"
                >
                  {errors?.description}
                </p>
              ) : null}
            </div>
            <div className={classes.isCheckboxdisplay}>
              {type === "user" ? (
                <FormGroup>
                  <FormControlLabel
                    onChange={(e) => {
                      // @ts-ignore
                      setFieldValue("isDemo", e.target.checked);
                    }}
                    value={values.isDemo}
                    // required
                    control={
                      <Checkbox
                        defaultChecked={values.isDemo === 1 ? true : false}
                      />
                    }
                    label="Is Demo"
                  />
                </FormGroup>
              ) : null}
              <FormGroup>
                <FormControlLabel
                  onChange={(e) => {
                    // @ts-ignore
                    setFieldValue("isMaintenance", e.target.checked);
                  }}
                  value={values.isDemo}
                  // required
                  control={
                    <Checkbox
                      defaultChecked={values.isMaintenance === 1 ? true : false}
                    />
                  }
                  label="Maintenance"
                />
              </FormGroup>
            </div>
          </div>
        )}
        {viewStatus ? null : (
          <div className={classes.modalbtn}>
            {editData ? (
              <>
                <UpdateDeleteActions
                  onCancelPress={() => modalClose()}
                  onUpdatePress={handleSubmit}
                />
              </>
            ) : (
              <div className="col-lg-3">
                <CustomButton title="Submit" onButtonPress={handleSubmit} />
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default AddSubcription;
