import React, { useEffect, useState } from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";

import { CustomSwitchPropsType } from "./Types";

const useStyles = makeStyles((theme) => ({
  DeactivateTrack: { width: "105px !important" },
  SwitchTrack: {
    width: "90px",
    height: "28px",
    borderRadius: "25px",
    // background: "#0080004a",
    display: "flex",
    alignItems: "center",
    padding: "0px 6px",
    position: "relative",
    cursor: "pointer",
    "&:active": {
      "&>div": {
        width: "20px",
        height: "18px",
      },
    },

    "&:hover": {
      "&>div": {
        boxShadow: "0px 0px 5px 2px rgba(0,0,0,0.1)",
      },
    },
  },
  ActiveTrack: {
    "&:hover": {
      // border: "1px solid rgb(0 128 0 / 55%)",
      boxShadow: "0px 0px 5px 4px rgb(0 128 0 / 10%)",
    },
    "&:active": {
      // border: "1px solid rgb(0 128 0 / 55%)",
      boxShadow: "0px 0px 5px 4px rgb(0 128 0 / 25%)",
    },
  },
  InActiveTrack: {
    "&:hover": {
      // border: "1px solid rgb(255 0 0 / 55%)",
      boxShadow: "0px 0px 5px 4px rgb(255 0 0 / 10%)",
    },
    "&:active": {
      // border: "1px solid rgb(255 0 0 / 55%)",
      boxShadow: "0px 0px 5px 4px rgb(255 0 0 / 25%)",
    },
  },
  SwitchThumb: {
    width: "18px",
    height: "18px",
    background: "white",
    position: "absolute",
    boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.1)",
    borderRadius: "50%",
    transition: "0.2s",
    left: "4px",
  },
  label: {
    fontSize: "12px",
    color: "black",
    fontWeight: 600,
  },
}));
export default function CustomSwitch({
  title1,
  title2,
  deactivate = false,
  status = false,
  Subscribe = false,
  setCheck,
}: CustomSwitchPropsType) {
  const [checked, setChecked] = useState(status);
  const classes = useStyles();

  useEffect(() => {
    setChecked(status);
  }, [status, deactivate]);
  return (
    <>
      {Subscribe ? (
        <Grid
          onClick={() => {
            setCheck(!checked);
          }}
          style={{
            justifyContent: checked || deactivate ? "start" : "end",
            background: deactivate
              ? "orange"
              : checked
                ? "rgb(0 128 0 / 55%)"
                : "rgb(255 0 0 / 55%)",
          }}
          className={
            deactivate
              ? `${classes.SwitchTrack} ${classes.DeactivateTrack}`
              : checked
                ? `${classes.SwitchTrack} ${classes.ActiveTrack}`
                : `${classes.SwitchTrack} ${classes.InActiveTrack}`
          }
        >
          <Typography
            style={{
              color: "var(--menuBg)",
              marginLeft: checked && !deactivate ? "7px" : "2px",
              marginRight: !checked && !deactivate ? "15px" : "0px",
            }}
            className={classes.label}
          >
            {deactivate ? "deactivated" : checked ? title1 : title2}
          </Typography>
          {/* <Grid
            style={
              deactivate
                ? { transform: "translateX(75px)" }
                : checked
                ? { transform: "translateX(63px)" }
                : { transform: "translateX(0px)" }
            }
            className={classes.SwitchThumb}
          /> */}
        </Grid>
      ) : (
        <Grid
          onClick={() => {
            setCheck(!checked);
          }}
          style={{
            justifyContent: checked || deactivate ? "start" : "end",
            background: deactivate
              ? "orange"
              : checked
                ? "rgb(0 128 0 / 55%)"
                : "rgb(255 0 0 / 55%)",
          }}
          className={
            deactivate
              ? `${classes.SwitchTrack} ${classes.DeactivateTrack}`
              : checked
                ? `${classes.SwitchTrack} ${classes.ActiveTrack}`
                : `${classes.SwitchTrack} ${classes.InActiveTrack}`
          }
        >
          <Typography
            style={{
              color: "var(--menuBg)",
              marginLeft: checked && !deactivate ? "13px" : "2px",
              marginRight: !checked && !deactivate ? "7px" : "0px",
            }}
            className={classes.label}
          >
            {deactivate ? "deactivated" : checked ? title1 : title2}
          </Typography>
          <Grid
            style={
              deactivate
                ? { transform: "translateX(75px)" }
                : checked
                  ? { transform: "translateX(63px)" }
                  : { transform: "translateX(0px)" }
            }
            className={classes.SwitchThumb}
          />
        </Grid>
      )}
    </>
  );
}
