import React, { useState } from "react";
import { AddSpecificationQuestionProps } from "./Types";
import { CustomButton, Loader, TextInputBox } from "../Components";
import ModalHeader from "./ModalHeader";
import { useFormik } from "formik";
import * as Yup from "yup";
import classes from "./Modal.module.css";
import {
  createBoardSpecification,
  updateInnerQuestion,
} from "../Services/Services";
import toast from "react-hot-toast";
import { getCatchMsg } from "../Utility/GeneralUtils";
import { openLoader } from "../Store/Reducers/LoaderSlice";
import { useDispatch } from "react-redux";

const validation = Yup.object({
  question: Yup.string().required("* Name is required"),
});

function AddSpecificationQuestion({
  onClose,
  editData,
  modalClose,
  listModelApicall,
  categoryTypeId,
}: AddSpecificationQuestionProps) {
  const dispatch = useDispatch();
  const { values, handleChange, handleSubmit, errors, touched } = useFormik({
    initialValues: {
      queryId: editData?.queryId ? editData?.queryId : "",
      question: editData?.question ? editData?.question : "",
    },
    validationSchema: validation,
    onSubmit: () => {
      if (editData === null) {
        handleAddQuestion(values);
      } else {
        handleUpadetQuestion(values);
      }
    },
  });

  const handleAddQuestion = (data: any) => {
    dispatch(openLoader(true));
    let sendData = {
      categoryTypeId: categoryTypeId?.state?.CategoryId,
      updateStatus: 1,
      data: [
        {
          question: data?.question,
        },
      ],
    };
    createBoardSpecification(sendData)
      .then((response) => {
        if (response?.data?.status === 1) {
          modalClose();
          listModelApicall("add");
          toast.success(response?.data?.msg);
        } else if (response?.data?.status === 0) {
          toast.error(response?.data?.msg);
        }
      })
      .catch((error) => {
        getCatchMsg(error);
      })
      .finally(() => {
        dispatch(openLoader(false));
      });
  };

  const handleUpadetQuestion = (data: any) => {
    dispatch(openLoader(true));
    let formData = new FormData();
    formData.append("queryId", data?.queryId);
    formData.append("question", data?.question);
    updateInnerQuestion(formData)
      .then((response) => {
        if (response?.data?.status === 1) {
          toast.success(response?.data?.msg);
          modalClose();
          listModelApicall("update");
        } else if (response?.data?.Status === 0) {
          toast.error(response?.data?.msg);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      })
      .finally(() => {
        dispatch(openLoader(false));
      });
  };

  return (
    <>
      <ModalHeader
        onClose={onClose}
        heading={`${editData === null ? "Add" : "Edit"} Question`}
      />
      <div className={classes.AddSpecificationQuestion}>
        <TextInputBox
          requiredText={"*"}
          placeHolder="Enter question"
          textLength={30}
          title="Question"
          value={values.question}
          name="question"
          onChangeText={handleChange("question")}
          errorText={touched.question && errors.question ? errors.question : ""}
        />
        <div className={classes.modalbtn}>
          {editData ? (
            <>
              <div className="row my-3">
                <div className="col-lg-6">
                  <CustomButton title="Update" onButtonPress={handleSubmit} />
                </div>
                <div className="col-lg-6">
                  <CustomButton
                    title="Cancel"
                    onButtonPress={() => modalClose()}
                    customButtonStyle={{
                      backgroundColor: "rgba(0, 0, 0, 0.08)",
                      color: "black",
                    }}
                  />
                </div>
              </div>
            </>
          ) : (
            <div className="col-lg-6 my-3">
              <CustomButton title="Submit" onButtonPress={handleSubmit} />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default AddSpecificationQuestion;
